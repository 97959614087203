import React from "react";
import { connect } from "react-redux";
import { withStyles } from "@mui/styles";
import PropTypes from "prop-types";
import { Box, Typography, Table, TableHead, TableRow, TableCell, TableBody, IconButton, Tooltip, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, Pagination, Card, CardContent } from "@mui/material";
import {
    Delete as DeleteIcon, Check as CheckIcon, Search as SearchIcon,
} from "@mui/icons-material";
import axios from "axios";
import { setNotification } from "../../../actions/global";
import { GlobalTypes } from "../../../action_types";
import clsx from "clsx";
import { AxiosClient } from "../../../global/axios";


const styles = theme => ({
    table: {
        border: '2px solid #f0f0f0',
        userSelect: 'text !important',
    },
    tCell: {
        border: 0,
        padding: 32,
        verticalAlign: 'top',
    },
    cellHeader: {
        fontFamily: 'Roboto Slab',
        width: 180,
        fontWeight: 700,
    },
    cellContent: {
        color: '#6b7280 !important',
        fontFamily: 'Roboto Slab !important',
    },
});

class RegistrationApproval extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            search: '',
            page: 1,
            size: 10,
            registrations: [],
            totalRegistrations: 0,
            approveRegistration: null,
            deleteRegistration: null,
        }

    }

    componentDidMount() {
        this.listRegistrationRequests();
    }

    listRegistrationRequests = async () => {
        try {
            const { search, page, size } = this.state;
            const resp = await AxiosClient().get(`${process.env.REACT_APP_API_URL}/v1/admin/users/registration.requests?search=${search}&page=${page}&size=${size}`);

            this.setState({ registrations: resp.data.data ?? [], totalRegistrations: resp.data.total ?? 0 });

        } catch (err) {
            console.error(err);
            this.props.setNotification({ message: 'Bir hata oluştu. Lütfen tekrar deneyiniz.', severity: GlobalTypes.NOTIFY_ERR });
        }
    }

    componentDidUpdate(prevProps) {
    }

    handleDelete = (r) => {
        this.setState({ deleteRegistration: r });
    }

    handleApprove = (r) => {
        this.setState({ approveRegistration: r });
    }

    deleteRegistration = async (r) => {
        try {
            const resp = await AxiosClient().delete(`${process.env.REACT_APP_API_URL}/v1/admin/users/registration.requests/${r.id}`);

            if (resp.data?.ok) {
                this.props.setNotification({ message: 'Kayıt isteği silindi.', severity: GlobalTypes.NOTIFY_SUCC });
            }

        } catch (err) {
            console.error(err);
            this.props.setNotification({ message: 'Bir hata oluştu. Lütfen tekrar deneyiniz.', severity: GlobalTypes.NOTIFY_ERR });
        } finally {
            this.setState({ deleteRegistration: null }, () => this.listRegistrationRequests());
        }
    }

    approveRegistration = async (r) => {
        try {
            const resp = await AxiosClient().post(`${process.env.REACT_APP_API_URL}/v1/admin/users/registration.requests/${r.id}`, {});

            if (resp.data?.ok) {
                this.props.setNotification({ message: 'Kayıt isteği onaylandı.', severity: GlobalTypes.NOTIFY_SUCC });
            }

        } catch (err) {
            console.error(err);
            this.props.setNotification({ message: 'Bir hata oluştu. Lütfen tekrar deneyiniz.', severity: GlobalTypes.NOTIFY_ERR });
        } finally {
            this.setState({ approveRegistration: null }, () => this.listRegistrationRequests());
        }
    }

    queryById = async (r) => {
        try {
            const resp = await AxiosClient().get(`${process.env.REACT_APP_API_URL}/v1/admin/users/registration.requests/${r.id}?by=ip`);

            if (resp.data?.ok) {
                this.setState({ query: `Aynı IP adresine sahip ${resp.data.total} kayıtlı kullanıcı bulundu.` });
            }

        } catch (err) {
            console.error(err);
            this.props.setNotification({ message: 'Bir hata oluştu. Lütfen tekrar deneyiniz.', severity: GlobalTypes.NOTIFY_ERR });
        } finally {
        }
    }

    // UI PART

    deleteRegistrationDialog = () => {
        const { deleteRegistration } = this.state;

        return (
            <Dialog open={!!deleteRegistration} onClose={() => this.setState({ deleteRegistration: null })}>
                <DialogTitle>Kayıt İsteği Sil</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Kayıt isteği silinecek, emin misiniz?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => this.setState({ deleteRegistration: null })} variant="contained" color="error">
                        İptal
                    </Button>
                    <Button onClick={() => this.deleteRegistration(deleteRegistration)} variant="contained" color="primary" autoFocus className="!text-white">
                        Sil
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }

    approveRegistrationDialog = () => {
        const { approveRegistration } = this.state;

        return (
            <Dialog open={!!approveRegistration} onClose={() => this.setState({ approveRegistration: null })}>
                <DialogTitle>Kayıt İsteği Onayla</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Kayıt isteği onaylanacak, emin misiniz?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => this.setState({ approveRegistration: null })} variant="contained" color="error">
                        İptal
                    </Button>
                    <Button onClick={() => this.approveRegistration(approveRegistration)} variant="contained" color="primary" autoFocus className="!text-white">
                        Onayla
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }

    queryResultDialog = () => {
        const { query } = this.state;

        return (
            <Dialog open={!!query} onClose={() => this.setState({ query: null })}>
                <DialogTitle>Sorgulama Sonucu</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {query}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => this.setState({ query: null })} variant="contained" color="primary" autoFocus className="!text-white">
                        Tamam
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }

    tableView = () => {
        const { classes } = this.props;
        const { registrations } = this.state;

        if (registrations.length === 0) {
            return (
                <Box className="!hidden lg:!flex justify-center items-center h-[300px]">
                    <Typography className={"!text-xs md:!text-sm lg:!text-base"}>Sonuç bulunamadı.</Typography>
                </Box>
            );
        }

        return (
            <Table className={clsx(
                classes.table,
                "!hidden lg:!table",
            )}>
                <TableHead>
                    <TableRow>
                        <TableCell className={classes.tCell} sx={{ borderBottom: '2px solid #f0f0f0', minWidth: 0, maxWidth: 0 }}>
                            <Typography className={clsx(classes.cellHeader, "!text-xs md:!text-sm lg:!text-base")}>
                                Nick
                            </Typography>
                        </TableCell>
                        <TableCell className={classes.tCell} sx={{ borderBottom: '2px solid #f0f0f0', minWidth: 0, maxWidth: 0 }}>
                            <Typography className={clsx(classes.cellHeader, "!text-xs md:!text-sm lg:!text-base")}>
                                Mail Adresi
                            </Typography>
                        </TableCell>
                        <TableCell className={classes.tCell} sx={{ borderBottom: '2px solid #f0f0f0', minWidth: 0, maxWidth: 0 }}>
                            <Typography className={clsx(classes.cellHeader, "!text-xs md:!text-sm lg:!text-base")}>
                                Cinsiyeti
                            </Typography>
                        </TableCell>
                        <TableCell className={classes.tCell} sx={{ borderBottom: '2px solid #f0f0f0', minWidth: 0, maxWidth: 0 }}>
                            <Typography className={clsx(classes.cellHeader, "!text-xs md:!text-sm lg:!text-base")}>
                                IP Adresi
                            </Typography>
                        </TableCell>
                        <TableCell className={classes.tCell} sx={{ borderBottom: '2px solid #f0f0f0', minWidth: 0, maxWidth: 0 }}>
                            <Typography className={clsx(classes.cellHeader, "!text-xs md:!text-sm lg:!text-base")}>
                                Kayıt Tarihi
                            </Typography>
                        </TableCell>
                        <TableCell className={classes.tCell} sx={{ borderBottom: '2px solid #f0f0f0', minWidth: 0, maxWidth: 0 }}>
                            <Typography className={clsx(classes.cellHeader, "!text-xs md:!text-sm lg:!text-base")}>
                                Aksiyon
                            </Typography>
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>

                    {registrations.map(r => {
                        return (
                            <TableRow key={r.id}>
                                <TableCell>
                                    <Typography className={clsx(classes.cellContent, "!text-xs md:!text-sm lg:!text-base")}>
                                        {r.username}
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography className={clsx(classes.cellContent, "!text-xs md:!text-sm lg:!text-base")}>
                                        {r.email}
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography className={clsx(classes.cellContent, "!text-xs md:!text-sm lg:!text-base")}>
                                        {r.gender === 'male' ? 'Bay' : 'Bayan'}
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography className={clsx(classes.cellContent, "!text-xs md:!text-sm lg:!text-base")}>
                                        {r.ip}
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography className={clsx(classes.cellContent, "!text-xs md:!text-sm lg:!text-base")}>
                                        {new Date(r.created_at).toLocaleDateString()}
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Tooltip title="Sil" placement="top">
                                        <IconButton onClick={() => this.handleDelete(r)}>
                                            <DeleteIcon fontSize="small" />
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip title="Onayla" placement="top">
                                        <IconButton onClick={() => this.handleApprove(r)}>
                                            <CheckIcon fontSize="small" />
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip title="IP Sorgula" placement="top">
                                        <IconButton onClick={() => this.queryById(r)}>
                                            <SearchIcon fontSize="small" />
                                        </IconButton>
                                    </Tooltip>
                                </TableCell>
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
        );
    }

    mobileView = () => {
        const { classes } = this.props;
        const { registrations } = this.state;

        if (registrations.length === 0) {
            return (
                <Box className="flex lg:hidden justify-center items-center">
                    <Typography className={"!text-xs md:!text-sm lg:!text-base"}>Sonuç bulunamadı.</Typography>
                </Box>
            );
        }

        return (
            <Box className="flex flex-col gap-y-2 lg:hidden">
                {registrations.map(r => {
                    return (

                        <Card key={r.id} sx={{ borderRadius: 4, border: '1px solid #c4c4c4', boxShadow: 0 }}>
                            <CardContent>
                                <Table>
                                    <TableRow>
                                        <TableCell className="!p-2">
                                            <Typography className={clsx(classes.cellContent, "!text-xs md:!text-sm lg:!text-base")}>
                                                Nick
                                            </Typography>
                                        </TableCell>
                                        <TableCell className="!p-2">
                                            <Typography className={clsx(classes.cellContent, "!text-xs md:!text-sm lg:!text-base")}>
                                                {r.username}
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell className="!p-2">
                                            <Typography className={clsx(classes.cellContent, "!text-xs md:!text-sm lg:!text-base")}>
                                                Mail Adresi
                                            </Typography>
                                        </TableCell>
                                        <TableCell className="!p-2">
                                            <Typography className={clsx(classes.cellContent, "!text-xs md:!text-sm lg:!text-base")}>
                                                {r.email}
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell className="!p-2">
                                            <Typography className={clsx(classes.cellContent, "!text-xs md:!text-sm lg:!text-base")}>
                                                Cinsiyeti
                                            </Typography>
                                        </TableCell>
                                        <TableCell className="!p-2">
                                            <Typography className={clsx(classes.cellContent, "!text-xs md:!text-sm lg:!text-base")}>
                                                {r.gender === 'male' ? 'Bay' : 'Bayan'}
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell className="!p-2">
                                            <Typography className={clsx(classes.cellContent, "!text-xs md:!text-sm lg:!text-base")}>
                                                IP Adresi
                                            </Typography>
                                        </TableCell>
                                        <TableCell className="!p-2">
                                            <Typography className={clsx(classes.cellContent, "!text-xs md:!text-sm lg:!text-base")}>
                                                {r.ip}
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell className="!p-2">
                                            <Typography className={clsx(classes.cellContent, "!text-xs md:!text-sm lg:!text-base")}>
                                                Kayıt Tarihi
                                            </Typography>
                                        </TableCell>
                                        <TableCell className="!p-2">
                                            <Typography className={clsx(classes.cellContent, "!text-xs md:!text-sm lg:!text-base")}>
                                                {new Date(r.created_at).toLocaleDateString()}
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell className="!p-2">
                                            <Typography className={clsx(classes.cellContent, "!text-xs md:!text-sm lg:!text-base")}>
                                                Aksiyon
                                            </Typography>
                                        </TableCell>
                                        <TableCell className="!p-2">
                                            <Tooltip title="Sil" placement="top">
                                                <IconButton className="!p-[2px] !mr-2" onClick={() => this.handleDelete(r)}>
                                                    <DeleteIcon className="!text-[16px]" />
                                                </IconButton>
                                            </Tooltip>
                                            <Tooltip title="Onayla" placement="top">
                                                <IconButton className="!p-[2px] !mr-2" onClick={() => this.handleApprove(r)}>
                                                    <CheckIcon className="!text-[16px]" />
                                                </IconButton>
                                            </Tooltip>
                                            <Tooltip title="IP Sorgula" placement="top">
                                                <IconButton className="!p-[2px] !mr-2" onClick={() => this.queryById(r)}>
                                                    <SearchIcon className="!text-[16px]" />
                                                </IconButton>
                                            </Tooltip>
                                        </TableCell>
                                    </TableRow>
                                </Table>
                            </CardContent>
                        </Card>
                    );
                })}
            </Box>
        );
    }

    render() {
        return (
            <Box margin={1} display={"flex"} flexDirection={"column"} rowGap={1}>

                {this.tableView()}
                {this.mobileView()}

                <Box className="flex justify-end">
                    <Pagination count={parseInt(Math.ceil(this.state.totalRegistrations / this.state.size))} page={this.state.page}
                        variant="outlined" shape="rounded" color="primary"
                        onChange={(e, p) => this.setState({ page: p }, () => this.listRegistrationRequests())}
                    />
                </Box>

                {this.approveRegistrationDialog()}
                {this.deleteRegistrationDialog()}
                {this.queryResultDialog()}
            </Box>
        );
    }
}

RegistrationApproval.propTypes = {
    classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
    return {
    }
};

export default withStyles(styles)(connect(mapStateToProps, {
    setNotification,
})(RegistrationApproval));
