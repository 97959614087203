import React from "react";
import { connect } from "react-redux";
import { withStyles } from "@mui/styles";
import PropTypes from "prop-types";
import { Box, Button, Card, CardContent, Checkbox, CircularProgress, Dialog, FormControlLabel, FormGroup, IconButton, InputAdornment, MenuItem, SvgIcon, Tab, Tabs, TextField, Tooltip, Typography } from "@mui/material";
import { mdiAccount, mdiEarth, mdiEmail, mdiEye, mdiEyeOff, mdiGenderMaleFemale, mdiLock, mdiRefresh, mdiSecurity } from '@mdi/js';
import { countries } from "../../global/countries";
import { AxiosError } from "axios";
import forge from 'node-forge';
import { generateKey } from "../../global/pbkdf2";
import { setNotification, setDisconnected } from "../../actions/global";
import { GlobalTypes } from "../../action_types";
import { setSocketHandler } from "../../global/socket";
import { ERROR_CODES } from "../../global/error";
import TermsOfUse from "../Header/TermsOfUse";
import PrivacyPolicy from "../Header/PrivacyPolicy";
import clsx from "clsx";
import queryString from "query-string";
import { getPath } from "../../global/route";
import { AxiosClient } from "../../global/axios";
import { generateRequestId } from "../../global/session";


const styles = theme => ({
    root: {
        backgroundColor: '#624799',
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        overflowY: 'auto',
    },
    title: {
        fontWeight: 'bold !important',
        fontFamily: 'Roboto Slab',
        //color: 'white',
    },
    text: {
        fontWeight: 'medium !important',
        color: '#1E2C5F !important',
    },
    textField: {
        fontFamily: 'Roboto Slab',
        fontWeight: 'bold !important',
    },
    inputIcon: {
        marginRight: '8px',
        color: '#1E2C5F !important'
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        rowGap: 12,
        padding: 24,
        color: 'white !important',
    },
    header: {
        //backgroundImage: "linear-gradient(to right, #624799 50%, #1D9AB6)",
        backgroundColor: '#624799',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        columnGap: 12
    },
    headerTitle: {
        color: '#E6E1FF',
    },
    linkButton: {
        fontFamily: 'Roboto Slab',
        fontWeight: 'bold !important',
        color: '#FF9559',
        cursor: 'pointer',
        display: 'inline',

        '&:hover': {
            textDecoration: 'underline',
        }
    }
});

const tabs = {
    LOGIN: 0,
    REGISTER: 1,
}

class Login extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            username: '',
            password: '',
            code: '',
            rememberMe: false,
            passwordAgain: '',
            email: '',
            country: 'TR',
            gender: '_',
            accepted: false,
            loading: false,
            tab: tabs.LOGIN,
            captchaId: null,
            captchaQuery: "",
            captchaSrc: null,
            reveal: false,
        }
    }

    componentDidMount = () => {

        this.loadCaptcha();

        const params = queryString.parse(this.props.history.location?.search);
        if (params?.t) {
            this.setState({ tab: parseInt(params.t) });
        }

        const sid = sessionStorage.getItem('sid') ?? localStorage.getItem('sid');
        const profile = sessionStorage.getItem('profile') ?? localStorage.getItem('profile');

        if (sid && profile) {
            setTimeout(() => {
                this.props.history.push(getPath('/salon'), { loggedIn: true });
            }, 0);
        }
    }

    loadCaptcha = async () => {
        try {
            const resp = await AxiosClient().get(`${process.env.REACT_APP_API_URL}/v1/auth/captcha`);
            const captchaId = resp.data;
            this.setState({ captchaId }, () => this.getCaptchaImage());

            //const captchaResp = await axios.get(`${process.env.REACT_APP_API_URL}/v1/auth/captcha/${id}.png`);
            //this.setState({ captcha: captchaResp.data });
        }
        catch (e) {
            console.error(e);
            let message = e.toString();

            if (e instanceof AxiosError) {
                const code = e.response?.data?.error?.code;
                if (code in ERROR_CODES) {
                    message = ERROR_CODES[code];
                } else {
                    message = e.message;
                }
            }

            this.props.setNotification({ message: message, severity: GlobalTypes.NOTIFY_ERR, traceId: e.response?.data?.trace_id });
        }
    }

    refreshCaptcha = async () => {
        await this.loadCaptcha();
        this.setState({ captchaQuery: `?reload=${new Date().getTime()}` })
    }

    getCaptchaImage = async () => {
        const { captchaId, captchaQuery } = this.state;
        if (!captchaId) {
            return;
        }

        const requestIdQuery = `${captchaQuery ? '&' : '?'}k-request-id=${generateRequestId()}`;
        const src = `${process.env.REACT_APP_API_URL}/v1/auth/captcha/${captchaId}.png${captchaQuery}${requestIdQuery}`;

        const response = await AxiosClient().get(src, { responseType: 'blob' });
        const url = URL.createObjectURL(response.data);
        this.setState({ captchaSrc: url });
    }

    loggedIn = async (data) => {
        sessionStorage.setItem('rememberMe', this.state.rememberMe);
        if (this.state.rememberMe) {
            sessionStorage.removeItem('sid');
            sessionStorage.removeItem('profile');
            localStorage.setItem('sid', data.token);
            localStorage.setItem('profile', JSON.stringify(data.profile));
        }
        else {
            sessionStorage.setItem('sid', data.token);
            sessionStorage.setItem('profile', JSON.stringify(data.profile));
        }

        this.props.setDisconnected(false);
        this.props.history.push(getPath('/salon'), { loggedIn: true });
    }

    login = async () => {
        try {
            const { username, password } = this.state;

            this.setState({ loading: true });

            const resp = await AxiosClient().get(`${process.env.REACT_APP_API_URL}/v1/user/login.challenge?user=${username}`);

            if (resp.status !== 200) {
                return;
            }

            const { email, enc_key, enc_private_key, iv, nonce, salt } = resp.data;

            const cipheredPrivate = forge.util.hexToBytes(enc_private_key);
            const bSalt = forge.util.hexToBytes(salt);
            const bIV = forge.util.hexToBytes(iv);

            const key = generateKey(email, password, bSalt);

            var cipher = forge.aes.createDecryptionCipher(key);
            cipher.start(bIV);
            cipher.update(forge.util.createBuffer(cipheredPrivate));
            cipher.finish();

            const privPem = cipher.output;

            const privateKey = forge.pki.privateKeyFromPem(privPem);
            const secret = privateKey.decrypt(forge.util.hexToBytes(enc_key));
            const hexSecret = forge.util.bytesToHex(secret);

            const md = forge.md.sha256.create();
            md.update(hexSecret);
            const signature = forge.util.bytesToHex(privateKey.sign(md));

            const req = {
                signature,
                nonce,
                user: username,
                captcha_id: this.state.captchaId,
                captcha: this.state.code,
            };

            this.setState({ loading: false });

            const loginResp = await AxiosClient().post(`${process.env.REACT_APP_API_URL}/v1/user/login`, req, { withCredentials: true });
            this.loggedIn(loginResp.data);
        }
        catch (e) {
            console.error(e);
            let message = ERROR_CODES[104];

            if (e instanceof AxiosError) {
                const code = e.response?.data?.error?.code;
                if (code in ERROR_CODES) {
                    message = ERROR_CODES[code];
                } else {
                    message = e.message;
                }
            }

            this.loadCaptcha();
            this.props.setNotification({ message: message, severity: GlobalTypes.NOTIFY_ERR, traceId: e.response?.data?.trace_id });
            this.setState({ loading: false });
        }
    }

    register = () => {
        const { username, email, password, passwordAgain, country, gender } = this.state;

        if (password !== passwordAgain) {
            return;
        }

        this.setState({ loading: true });

        let that = this;

        forge.pki.rsa.generateKeyPair({ bits: 1024 }, async (err, pair) => {
            try {

                const privatePem = forge.pki.privateKeyToPem(pair.privateKey);
                const public_key = forge.pki.publicKeyToPem(pair.publicKey);

                const iv = forge.random.getBytesSync(16);
                const salt = forge.random.getBytesSync(16);

                const key = generateKey(email, password, salt);

                var cipher = forge.aes.createEncryptionCipher(key);
                cipher.start(iv);
                cipher.update(forge.util.createBuffer(privatePem));
                cipher.finish();

                const enc_private_key = forge.util.bytesToHex(cipher.output.getBytes());

                const req = {
                    username: username.trim(),
                    email,
                    country,
                    gender,
                    enc_private_key,
                    public_key,
                    salt: forge.util.bytesToHex(salt),
                    iv: forge.util.bytesToHex(iv),
                };

                const resp = await AxiosClient().post(`${process.env.REACT_APP_API_URL}/v1/user/register`, req);
                const message = 'Kayıt talebiniz başarıyla alındı. Talebiniz yöneticiler tarafından onaylandıktan sonra giriş yapabilirsiniz.';
                that.props.setNotification({ message, severity: GlobalTypes.NOTIFY_SUCC });
                that.setState({ tab: tabs.LOGIN });

                that.setState({ loading: false });
            }
            catch (e) {
                console.error(e);
                let message = ERROR_CODES[2];

                if (e instanceof AxiosError) {
                    const code = e.response?.data?.error?.code;
                    if (code in ERROR_CODES) {
                        message = ERROR_CODES[code];
                    } else {
                        message = e.message;
                    }
                }

                this.props.setNotification({ message: message, severity: GlobalTypes.NOTIFY_ERR, traceId: e.response?.data?.trace_id });
                this.setState({ loading: false });
            }
        })
    }

    loginForm = () => {
        const { classes } = this.props;

        return (
            <Box className={classes.content}>
                <form>
                    <Box display={"flex"} flexDirection={"column"} rowGap={'6px'}>
                        <Box>
                            <Typography className={classes.text}>Kullanıcı Adı</Typography>
                            <TextField className={classes.textField}
                                autoComplete='new-password'
                                autoFocus
                                fullWidth
                                //placeholder='Kullanıcı Adı'
                                margin="dense"
                                variant="outlined"
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment className={classes.inputIcon} position="start">
                                            <SvgIcon><path d={mdiAccount} /></SvgIcon>
                                        </InputAdornment>
                                    ),
                                    classes: {
                                        input: "!text-sm md:!text-base"
                                    }
                                }}
                                value={this.state.username}
                                onChange={(e) => {
                                    //e.target.value = e.target.value.charAt(0).toLowerCase() + e.target.value.slice(1).toLocaleLowerCase("tr-TR");
                                    this.setState({ username: e.target.value })
                                }
                                }
                            />
                        </Box>

                        <Box>
                            <Typography className={classes.text}>Şifre</Typography>
                            <TextField className={classes.textField}
                                autoComplete='new-password'
                                fullWidth
                                //placeholder='Şifre'
                                margin="dense"
                                variant="outlined"
                                type={this.state.reveal ? 'text' : 'password'}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment className={classes.inputIcon} position="start">
                                            <SvgIcon><path d={mdiLock} /></SvgIcon>
                                        </InputAdornment>
                                    ),
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton style={{ width: 24, height: 24, padding: 8 }} tabIndex={-1}
                                                onClick={() => this.setState({ reveal: !this.state.reveal })}>
                                                <Tooltip title={this.state.reveal ? 'Şifreyi gizle' : 'Şifreyi göster'}
                                                    placement='top'
                                                >
                                                    <SvgIcon fontSize='small'>
                                                        <path d={this.state.reveal ? mdiEyeOff : mdiEye} />
                                                    </SvgIcon>
                                                </Tooltip>
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                    classes: {
                                        input: "!text-sm md:!text-base"
                                    }
                                }}
                                value={this.state.password}
                                onChange={(e) => this.setState({ password: e.target.value })}
                            />
                        </Box>

                        <Box>
                            <Typography className={classes.text}>Güvenlik Kodu</Typography>
                            <Box display={"flex"} columnGap={2}>
                                <TextField className={classes.textField}
                                    autoComplete='new-password'
                                    fullWidth
                                    //placeholder='Güvenlik Kodu'
                                    margin="dense"
                                    variant="outlined"
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment className={classes.inputIcon} position="start">
                                                <SvgIcon><path d={mdiSecurity} /></SvgIcon>
                                            </InputAdornment>
                                        ),
                                        classes: {
                                            input: "!text-sm md:!text-base"
                                        }
                                    }}
                                    value={this.state.code}
                                    onChange={(e) => {
                                        // only digits
                                        e.target.value = e.target.value.replace(/\D/g, '');
                                        this.setState({ code: e.target.value })
                                    }}
                                />

                                <Box px={2} marginTop={"8px"} marginBottom={"4px"} bgcolor={"#FFF"} border={"1px solid #A0A0A0"}
                                    className="flex justify-center items-center w-24 rounded-3xl"
                                >
                                    {this.state.captchaSrc && <img src={this.state.captchaSrc} alt={"captcha"} className="h-min w-auto scale-125" />}
                                </Box>

                                <Tooltip title="Yenile" placement="top">
                                    <IconButton sx={{ backgroundColor: '#35A6CA' }} onClick={() => this.refreshCaptcha()}
                                        disableFocusRipple disableRipple disableTouchRipple
                                        className="!w-16 !h-10 !p-0 !text-white !rounded-3xl self-center"
                                    >
                                        <SvgIcon fontSize="small"><path d={mdiRefresh} /></SvgIcon>
                                    </IconButton>
                                </Tooltip>
                            </Box>
                        </Box>

                        <Box display={"flex"} justifyContent={"space-between"}>
                            <FormGroup>
                                <FormControlLabel control={
                                    <Checkbox checked={this.state.rememberMe}
                                        onChange={e => this.setState({ rememberMe: e.target.checked })}
                                        sx={{ '& .MuiSvgIcon-root': { color: '#D9D9D9' } }}
                                    />
                                } label={
                                    <Typography className={clsx(classes.text, "!text-sm md:!text-base")}>
                                        Beni Hatırla
                                    </Typography>
                                }
                                />
                            </FormGroup>

                            <Button variant='text' sx={{ color: 'white' }}
                                onClick={() => { this.props.history.push(getPath('/sifremi.unuttum')) }}
                                disableElevation disableFocusRipple disableRipple disableTouchRipple
                                className={clsx(classes.text, "!text-sm md:!text-base")}
                            >
                                Şifremi Unuttum?
                            </Button>
                        </Box>
                    </Box>
                    <Button variant='contained' type="submit" fullWidth sx={{ mt: 2, color: 'var(--white)', backgroundColor: '#FF9559 !important' }}
                        className="!text-sm md:!text-base !min-h-10"
                        disabled={!this.state.username || !this.state.password || !this.state.code || this.state.loading}
                        onClick={this.login}>
                        {this.state.loading ? <CircularProgress size={20} sx={{ margin: '4px 0px', color: '#FF9559' }} thickness={8} /> : 'Giriş Yap'}
                    </Button>

                    <Button variant='contained' type="submit" fullWidth sx={{ mt: 2, color: 'var(--white)', backgroundColor: '#FF9559 !important' }}
                        className="!text-sm md:!text-base !min-h-10"
                        onClick={() => this.props.history.push('/')}>
                        Geri Dön
                    </Button>
                </form>
            </Box>
        );
    }

    termsOfUse = (e) => {
        e.stopPropagation();
        e.preventDefault();
        this.setState({ termsOfUse: true });
    }

    privacyPolicy = (e) => {
        e.stopPropagation();
        e.preventDefault();
        this.setState({ privacyPolicy: true });
    }

    registerForm = () => {
        const { classes } = this.props;

        return (
            <Box className={classes.content}>
                <form>
                    <Box display={"flex"} flexDirection={"column"} rowGap={'6px'}>
                        <Box>
                            <Typography className={classes.text}>Kullanıcı Adı</Typography>
                            <TextField
                                autoComplete='new-password'
                                autoFocus
                                fullWidth
                                margin="dense"
                                variant="outlined"
                                //placeholder="Kullanıcı Adı"
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment className={classes.inputIcon} position="start">
                                            <SvgIcon><path d={mdiAccount} /></SvgIcon>
                                        </InputAdornment>
                                    ),
                                    classes: {
                                        input: "!text-sm md:!text-base"
                                    }
                                }}
                                value={this.state.username}
                                onChange={(e) => {
                                    //e.target.value = e.target.value.charAt(0).toLowerCase() + e.target.value.slice(1).toLocaleLowerCase("tr-TR");
                                    this.setState({ username: e.target.value })
                                }
                                }
                            />
                        </Box>

                        <Box>
                            <Typography className={classes.text}>E-Posta</Typography>
                            <TextField
                                autoComplete='new-password'
                                fullWidth
                                margin="dense"
                                variant="outlined"
                                type="email"
                                //placeholder="E-Posta"
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment className={classes.inputIcon} position="start">
                                            <SvgIcon><path d={mdiEmail} /></SvgIcon>
                                        </InputAdornment>
                                    ),
                                    classes: {
                                        input: "!text-sm md:!text-base"
                                    }
                                }}
                                value={this.state.email}
                                onChange={(e) => {
                                    //e.target.value = e.target.value.charAt(0).toLowerCase() + e.target.value.slice(1).toLocaleLowerCase("tr-TR");
                                    this.setState({ email: e.target.value })
                                }
                                }
                            />
                        </Box>

                        <Box className="flex gap-x-4">
                            <Box className="flex-1">
                                <Typography className={classes.text}>Şifre</Typography>
                                <TextField
                                    autoComplete='new-password'
                                    fullWidth
                                    margin="dense"
                                    variant="outlined"
                                    type={this.state.reveal ? 'text' : 'password'}
                                    //placeholder="Şifre"
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment className={classes.inputIcon} position="start">
                                                <SvgIcon><path d={mdiLock} /></SvgIcon>
                                            </InputAdornment>
                                        ),
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton style={{ width: 24, height: 24, padding: 8 }} tabIndex={-1}
                                                    onClick={() => this.setState({ reveal: !this.state.reveal })}>
                                                    <Tooltip title={this.state.reveal ? 'Şifreyi gizle' : 'Şifreyi göster'}
                                                        placement='top'
                                                    >
                                                        <SvgIcon fontSize='small'>
                                                            <path d={this.state.reveal ? mdiEyeOff : mdiEye} />
                                                        </SvgIcon>
                                                    </Tooltip>
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                        classes: {
                                            input: "!text-sm md:!text-base"
                                        }
                                    }}
                                    value={this.state.password}
                                    onChange={(e) => this.setState({ password: e.target.value })}
                                />
                            </Box>

                            <Box className="flex-1">
                                <Typography className={classes.text}>Şifre (Tekrar)</Typography>
                                <TextField
                                    autoComplete='new-password'
                                    fullWidth
                                    margin="dense"
                                    variant="outlined"
                                    type={this.state.reveal ? 'text' : 'password'}
                                    //placeholder="Şifre (Tekrar)"
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment className={classes.inputIcon} position="start">
                                                <SvgIcon><path d={mdiLock} /></SvgIcon>
                                            </InputAdornment>
                                        ),
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton style={{ width: 24, height: 24, padding: 8 }} tabIndex={-1}
                                                    onClick={() => this.setState({ reveal: !this.state.reveal })}>
                                                    <Tooltip title={this.state.reveal ? 'Şifreyi gizle' : 'Şifreyi göster'}
                                                        placement='top'
                                                    >
                                                        <SvgIcon fontSize='small'>
                                                            <path d={this.state.reveal ? mdiEyeOff : mdiEye} />
                                                        </SvgIcon>
                                                    </Tooltip>
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                        classes: {
                                            input: "!text-sm md:!text-base"
                                        }
                                    }}
                                    value={this.state.passwordAgain}
                                    onChange={(e) => this.setState({ passwordAgain: e.target.value })}
                                />
                            </Box>
                        </Box>

                        <Box className="flex gap-x-4">
                            <Box className="flex-1">
                                <Typography className={classes.text}>Ülke</Typography>
                                <TextField
                                    autoComplete='new-password'
                                    fullWidth
                                    select
                                    margin="dense"
                                    variant="outlined"
                                    //placeholder="Ülke"
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment className={classes.inputIcon} position="start">
                                                <SvgIcon><path d={mdiEarth} /></SvgIcon>
                                            </InputAdornment>
                                        ),
                                        classes: {
                                            input: "!text-sm md:!text-base"
                                        }
                                    }}
                                    value={this.state.country}
                                    onChange={(e) => this.setState({ country: e.target.value })}
                                >
                                    {Object.keys(countries).map(code => {
                                        return (
                                            <MenuItem key={code} value={code}>
                                                {countries[code]}
                                            </MenuItem>
                                        );
                                    })}
                                </TextField>
                            </Box>

                            <Box className="flex-1">
                                <Typography className={classes.text}>Cinsiyet</Typography>
                                <TextField
                                    autoComplete='new-password'
                                    fullWidth
                                    select
                                    margin="dense"
                                    variant="outlined"
                                    //placeholder="Cinsiyet"
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment className={classes.inputIcon} position="start">
                                                <SvgIcon><path d={mdiGenderMaleFemale} /></SvgIcon>
                                            </InputAdornment>
                                        ),
                                        classes: {
                                            input: "!text-sm md:!text-base"
                                        }
                                    }}
                                    value={this.state.gender}
                                    onChange={(e) => this.setState({ gender: e.target.value })}
                                >
                                    <MenuItem value={"_"} key={"_"}>Cinsiyet</MenuItem>
                                    <MenuItem value={"male"} key={"male"}>Bay</MenuItem>
                                    <MenuItem value={"female"} key={"female"}>Bayan</MenuItem>
                                </TextField>
                            </Box>
                        </Box>

                        <Box>
                            <FormGroup>
                                <FormControlLabel control={
                                    <Checkbox checked={this.state.accepted}
                                        onChange={e => this.setState({ accepted: e.target.checked })}
                                        sx={{ '& .MuiSvgIcon-root': { color: '#D9D9D9' } }}
                                    />
                                } label={(<Typography className={clsx(classes.text, "!text-sm md:!text-base")}>
                                    <Typography className={clsx(classes.linkButton, "!text-sm md:!text-base")} onClick={this.termsOfUse}>
                                        Kullanım şartları
                                    </Typography>
                                    nı ve&nbsp;
                                    <Typography className={clsx(classes.linkButton, "!text-sm md:!text-base")} onClick={this.privacyPolicy}>
                                        Gizlilik Politikası
                                    </Typography>
                                    nı okudum, kabul ediyorum.
                                </Typography>)} />
                            </FormGroup>
                        </Box>
                    </Box>
                    <Button variant='contained' sx={{ mt: 2, color: 'var(--white)', backgroundColor: '#FF9559 !important' }} fullWidth
                        disabled={!this.state.username || !this.state.email || !this.state.password || !this.state.passwordAgain ||
                            !this.state.country || !this.state.gender || this.state.gender === '_' || !this.state.accepted || this.state.loading ||
                            this.state.password !== this.state.passwordAgain || !this.state.email.includes('@')
                        }
                        className="!text-sm md:!text-base !min-h-10"
                        onClick={this.register}>
                        {this.state.loading ? <CircularProgress size={20} sx={{ margin: '4px 0px', color: '#FF9559' }} thickness={8} /> : 'Kayıt Ol'}
                    </Button>

                    <Button variant='contained' type="submit" fullWidth sx={{ mt: 2, color: 'var(--white)', backgroundColor: '#FF9559 !important' }}
                        className="!text-sm md:!text-base !min-h-10"
                        onClick={() => this.props.history.push('/')}>
                        Geri Dön
                    </Button>
                </form>
            </Box>
        );
    }

    content = () => {
        if (this.state.tab === tabs.LOGIN) {
            return this.loginForm();
        }

        return this.registerForm();
    }

    header = () => {
        const { classes } = this.props;
        const imgPath = getPath('/img');

        return (
            <Box height={60} px={4}
                className={clsx(classes.header, "flex")}
            >
                <img src={`${imgPath}/logo.svg`} alt="logo" />
                <Box>
                    <Typography className={clsx("!font-['Roboto Slab'] !text-white !text-sm md:!text-base !font-bold")}>{process.env.REACT_APP_HEADER_TITLE}</Typography>
                    <Typography className={clsx("!font-['Roboto Slab'] !text-white !text-sm md:!text-base !font-bold")}>{process.env.REACT_APP_HEADER_SUBTITLE}</Typography>
                </Box>
            </Box>
        );
    }

    render() {
        const { classes } = this.props;

        return (
            <div className={classes.root}>

                {this.header()}

                <TermsOfUse open={this.state.termsOfUse} onClose={e => this.setState({ termsOfUse: false })} />
                <PrivacyPolicy open={this.state.privacyPolicy} onClose={e => this.setState({ privacyPolicy: false })} />

                <div className="w-full flex-1 self-center max-w-md mt-[4vh]">
                    <Card sx={{ backgroundColor: '#FFFFFF', borderRadius: 4, border: '1px solid #E8E8E8' }}>
                        <CardContent sx={{ padding: 0 }}>

                            <Tabs
                                sx={{
                                    mb: 2,
                                    '& .MuiTabs-flexContainer': { columnGap: 0 },
                                    '& .MuiTabs-indicator': { backgroundColor: "#7A51ED", height: '4px' },
                                    '& .MuiTab-root': { color: '#1E2C5F !important', flex: 1, borderBottom: '1px solid #E8E8E8' },
                                    '& .Mui-selected': { color: '#7A51ED !important' },
                                }}
                                value={this.state.tab}
                                textColor="primary"
                                className={classes.tabs}
                                onChange={(e, value) => this.setState({
                                    tab: value, reveal: false, password: '', passwordAgain: '',
                                    username: '', email: '', country: 'TR', gender: '_', accepted: false,
                                })}
                            >

                                <Tab value={tabs.LOGIN}
                                    label={
                                        <Box display={"flex"} columnGap={2}>
                                            <Typography className={clsx(
                                                classes.title, "!text-base md:!text-lg"
                                            )}
                                            >
                                                Giriş Yap
                                            </Typography>
                                        </Box>
                                    }
                                    sx={{ textTransform: 'none', padding: 3 }} />

                                <Tab value={tabs.REGISTER}
                                    label={
                                        <Box display={"flex"} columnGap={2}>
                                            <Typography className={clsx(
                                                classes.title, "!text-base md:!text-lg"
                                            )}
                                            >
                                                Kayıt Ol
                                            </Typography>
                                        </Box>}
                                    sx={{ textTransform: 'none', padding: 3 }} />
                            </Tabs>

                            {this.content()}

                        </CardContent>
                    </Card>
                </div>

                <div className="w-full flex flex-col gap-y-2 justify-center items-center mt-4">
                    <Typography sx={{ color: '#FFFFFF' }} className="!text-xs sm:!text-sm md:!text-base">
                        Tüm hakları saklıdır. © 2024 kerizim.com
                    </Typography>
                </div>
            </div>
        );
    }
}

Login.propTypes = {
    classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
    return {
    }
};

export default withStyles(styles)(connect(mapStateToProps, {
    setNotification, setSocketHandler, setDisconnected,
})(Login));
