import React from "react";
import { connect } from "react-redux";
import { withStyles } from "@mui/styles";
import PropTypes from "prop-types";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, FormLabel, Menu, MenuItem, Select, TextField, Typography } from "@mui/material";
import { setNotification, addBlockedUser, removeBlockedUser, addFriend, removeFriend, addChatTab, setReportDialog } from "../../actions/global";
import { GlobalTypes } from "../../action_types";
import axios, { AxiosError } from "axios";
import { NestedMenuItem } from "mui-nested-menu";
import { ArrowRightIcon } from "@mui/x-date-pickers";
import EditChatBan from "../Admin/UserSettings/EditChatBan";
import EditPremiumUser from "../Admin/PremiumSettings/EditPremiumUser";
import EditUserBan from "../Admin/UserSettings/EditUserBan";
import EditIPBan from "../Admin/UserSettings/EditIPBan";
import { ERROR_CODES } from "../../global/error";
import Presents from "../Chatbox/Presents";
import { presentNames } from "../Chatbox/present_details";
import { roles } from "../../global/role";
import clsx from "clsx";
import { AxiosClient } from "../../global/axios";
import { getPath } from "../../global/route";


const styles = theme => ({
});

class UserActions extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            sendPtsDialog: false,
            pts: 0,

            reportReason: "",
            reportReasonText: "",
            reportDescription: "",
            removeFriendConfirm: false,
            removingFriend: false,
        }
    }

    componentDidMount() {
    }

    componentDidUpdate(prevProps) {
        if (!prevProps.user && this.props.user) {
            this.getUserProfile();
        }
        if (!prevProps.reportPlayerDialog && this.props.reportPlayerDialog) {
            this.openReportPlayerDialog();
        } else if (prevProps.reportPlayerDialog && !this.props.reportPlayerDialog) {
            this.setState({ showReportPlayerDialog: false });
        }
    }

    getUserProfile = async () => {
        if (this.props.user.bot) {
            return;
        }

        const resp = await AxiosClient().get(`${process.env.REACT_APP_API_URL}/v1/user/profile/${this.props.user?.id}`);

        this.setState({ profile: resp.data.user });
    }

    onClose = () => {
        this.props.onClose();
    }

    doBlock = async () => {
        const { user } = this.props;
        if (!user) {
            return;
        }

        this.setState({ blocking: true });

        try {
            const resp = await AxiosClient().post(`${process.env.REACT_APP_API_URL}/v1/user/block/${user.id}`, null);

            this.props.setNotification({ message: 'Kullanıcı engellendi.', severity: GlobalTypes.NOTIFY_SUCC });
            if (resp.data.blocked_user) {
                this.props.addBlockedUser(resp.data.blocked_user);
            }
        }
        catch (err) {
            console.error(err);
            this.props.setNotification({ message: 'Bir hata oluştu. Lütfen tekrar deneyiniz.', severity: GlobalTypes.NOTIFY_ERR });
        }
        finally {
            this.setState({ blockConfirm: false, blocking: false });
            this.onClose();
        }
    }

    doUnblock = async () => {
        const { user } = this.props;
        if (!user) {
            return;
        }

        this.setState({ unblocking: true });

        try {
            const resp = await AxiosClient().delete(`${process.env.REACT_APP_API_URL}/v1/user/block/${user.id}`);

            this.props.setNotification({ message: 'Kullanıcının engeli kaldırıldı.', severity: GlobalTypes.NOTIFY_SUCC });
            if (resp.data.id) {
                this.props.removeBlockedUser(resp.data.id);
            }
        }
        catch (err) {
            console.error(err);
            this.props.setNotification({ message: 'Bir hata oluştu. Lütfen tekrar deneyiniz.', severity: GlobalTypes.NOTIFY_ERR });
        }
        finally {
            this.setState({ unblockConfirm: false, unblocking: false });
            this.onClose();
        }
    }

    doAddFriend = async () => {
        const { user } = this.props;
        if (!user) {
            return;
        }

        this.onClose();
        this.setState({ addingFriend: true });

        try {
            const resp = await AxiosClient().put(`${process.env.REACT_APP_API_URL}/v1/user/friend.request`, {
                id: user.id,
            });

            this.props.setNotification({ message: 'Arkadaşlık isteği gönderildi.', severity: GlobalTypes.NOTIFY_SUCC });
            if (resp.data.friend) {
                this.props.addFriend(resp.data.friend);
            }
        }
        catch (err) {
            console.error(err);
            this.props.setNotification({ message: 'Bir hata oluştu. Lütfen tekrar deneyiniz.', severity: GlobalTypes.NOTIFY_ERR });
        }
        finally {
            this.setState({ addFriendConfirm: false, addingFriend: false });
        }
    }

    onFriendRemove = () => {
        this.setState({ removeFriendConfirm: true });
    }

    doRemoveFriend = async () => {
        const { user } = this.props;
        if (!user) {
            return;
        }

        this.onClose();
        this.setState({ removingFriend: true });

        try {
            const resp = await AxiosClient().delete(`${process.env.REACT_APP_API_URL}/v1/user/friend/${user.id}`);

            this.props.setNotification({ message: 'Arkadaşlıktan çıkarıldı.', severity: GlobalTypes.NOTIFY_SUCC });
            if (resp.data.id) {
                this.props.removeFriend(resp.data.id);
            }
        }
        catch (err) {
            console.error(err);
            this.props.setNotification({ message: 'Bir hata oluştu. Lütfen tekrar deneyiniz.', severity: GlobalTypes.NOTIFY_ERR });
        }
        finally {
            this.setState({ removeFriendConfirm: false, removingFriend: false });
        }
    }

    onPrivateMessage = (userId, username) => {
        this.props.addChatTab(userId, username, true);
        this.props.history.push(getPath("/sohbet"), { userId, username });
    }

    onTabUser = (username) => {
        this.props.onTabUser?.(username);
        this.onClose();
    }

    onSendPresent = (user) => {
        this.setState({ sendPresentTo: user });
    }

    doSendPresent = () => {
        const { socket } = this.props;
        const { sendPresentTo, sendingPresent } = this.state;
        socket?.emit('send_message', { room: 'salon', present: sendingPresent, pm: false, presentTo: sendPresentTo?.username });

        this.setState({ sendPresentTo: null, sendingPresent: null });
        this.onClose();
    }

    onSendPts = () => {
        this.setState({ sendPtsDialog: true });
    }

    spectateTable = (id) => {
        const { socket } = this.props;
        socket.emit('spectate_table', { tableId: id });
    }

    doSendPts = async () => {
        const { user } = this.props;
        if (!user) {
            return;
        }

        try {
            this.setState({ sendingPts: true });

            const body = {
                user_id: user.id,
                amount: parseInt(this.state.pts),
            };

            await AxiosClient().post(`${process.env.REACT_APP_API_URL}/v1/user/send.points`, body);

            this.props.setNotification({ message: 'Puan başarıyla gönderildi.', severity: GlobalTypes.NOTIFY_SUCC });
        }
        catch (err) {
            console.error(err);
            this.props.setNotification({ message: 'Bir hata oluştu. Lütfen tekrar deneyiniz.', severity: GlobalTypes.NOTIFY_ERR });
        }
        finally {
            this.setState({ sendPtsDialog: false, sendingPts: false, pts: 0 });
            this.onClose();
        }
    }

    onUserReport = () => {
        if (this.props?.onReport) {
            this.props.onReport({ user: this.props.user, active: true });
            this.onClose();
            return;
        }

        this.props.setReportDialog({
            active: true,
            reportedUserId: this.props.user?.id,
        })

        this.onClose();
    }

    onTableKick = async () => {
        const { user, socket, selectedTable, myTable } = this.props;
        if (!user) {
            return;
        }

        const tableId = selectedTable ?? user?.tableId ?? myTable;

        this.onClose();
        socket.emit("table_kick", { userId: user.id, tableId: tableId });
    }

    onServerKick = async () => {
        const { user, socket } = this.props;
        if (!user) {
            return;
        }

        this.onClose();
        socket.emit("server_kick", { userId: user.id });
    }

    getProfile = () => {
        let profile = null;

        try {
            profile = JSON.parse(sessionStorage.getItem('profile') ?? localStorage.getItem('profile'));
        } catch (e) {
            console.error(e);
        }

        return profile;
    }

    onChatBan = (unlimitedChatBan) => {
        this.setState({ openChatBan: true, unlimitedChatBan });
    }

    onPremium = (unlimitedPremium) => {
        this.setState({ openPremium: true, unlimitedPremium });
    }

    onBan = async (unlimited, ipBan) => {
        if (ipBan) {
            this.setState({ openIPBan: true, unlimitedIPBan: unlimited });
        }
        else {
            this.setState({ openUserBan: true, unlimitedUserBan: unlimited });
        }
    }

    showIP = () => {
        this.setState({ showIP: true });
    }

    deleteChatBan = async () => {
        try {
            const { user } = this.props;

            if (!user) return;

            await AxiosClient().delete(`${process.env.REACT_APP_API_URL}/v1/admin/users/${user.id}/chat.ban`);

            this.props.setNotification({ message: 'Susturma başarıyla silindi.', severity: GlobalTypes.NOTIFY_SUCC });

        } catch (err) {
            console.error(err);
            this.props.setNotification({ message: 'Bir hata oluştu. Lütfen tekrar deneyiniz.', severity: GlobalTypes.NOTIFY_ERR });

        } finally {
            this.onClose();
        }
    }

    deletePremiumUser = async () => {
        try {
            const { user } = this.props;

            if (!user) return;

            await AxiosClient().delete(`${process.env.REACT_APP_API_URL}/v1/admin/premium?id=${user.id}`);

            this.props.setNotification({ message: 'Premium kullanıcı başarıyla silindi.', severity: GlobalTypes.NOTIFY_SUCC });
            this.setState({ deletePremium: false });

        } catch (err) {
            console.error(err);
            this.props.setNotification({ message: 'Bir hata oluştu. Lütfen tekrar deneyiniz.', severity: GlobalTypes.NOTIFY_ERR });

        } finally {
            this.onClose();
        }
    }

    queryIPById = async () => {
        try {
            const resp = await AxiosClient().get(`${process.env.REACT_APP_API_URL}/v1/admin/users/ip.query?ip=${this.state.profile?.ip}`);

            if (resp.data?.ok) {
                this.setState({ ipQuery: `Aynı IP adresine sahip ${resp.data.total} kayıtlı kullanıcı bulundu.` });
            }

        } catch (err) {
            console.error(err);
            this.props.setNotification({ message: 'Bir hata oluştu. Lütfen tekrar deneyiniz.', severity: GlobalTypes.NOTIFY_ERR });
        } finally {
        }
    }

    getUserAgent = async () => {
        try {
            const resp = await AxiosClient().get(`${process.env.REACT_APP_API_URL}/v1/admin/users/${this.props.user?.id}/user.agent`);

            if (resp.data?.ok) {
                this.setState({ userAgent: resp.data.user_agent });
            }

        } catch (err) {
            console.error(err);
            this.props.setNotification({ message: 'Bir hata oluştu. Lütfen tekrar deneyiniz.', severity: GlobalTypes.NOTIFY_ERR });
        } finally {
        }
    }

    resetUserPoints = async () => {
        try {
            const resp = await AxiosClient().post(`${process.env.REACT_APP_API_URL}/v1/admin/users/${this.props.user?.id}/points.reset`, null);

            if (resp.data?.ok) {
                this.props.setNotification({ message: 'Kullanıcının puanı başarıyla sıfırlandı.', severity: GlobalTypes.NOTIFY_SUCC });
            }

        } catch (err) {
            console.error(err);
            this.props.setNotification({ message: 'Bir hata oluştu. Lütfen tekrar deneyiniz.', severity: GlobalTypes.NOTIFY_ERR });
        } finally {
            this.onClose();
            this.setState({ resetPoints: false });
        }

    }

    renderRemoveFriendDialog = () => {
        const { classes } = this.props;
        const { removeFriendConfirm, removingFriend } = this.state;

        return (
            <Dialog open={removeFriendConfirm} onClose={() => { this.setState({ removeFriendConfirm: false }) }}>
                <DialogTitle>
                    <Typography className={clsx(classes.title, "!text-xs md:!text-sm lg:!text-base")} sx={{ color: '#413067' }}>
                        Arkadaşlıktan Çıkar
                    </Typography>
                </DialogTitle>
                <DialogContent>
                    <Typography className={clsx(classes.text, "!text-xs md:!text-sm lg:!text-base")} sx={{ color: '#413067' }}>
                        Bu kullanıcıyı arkadaş listenizden çıkarmak istediğinize emin misiniz?
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => { this.setState({ removeFriendConfirm: false }) }} variant="contained" color="error">
                        İptal
                    </Button>
                    <Button onClick={this.doRemoveFriend} sx={{ color: 'white' }} variant="contained" disabled={removingFriend}>
                        Çıkar
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }

    renderSendPtsDialog = () => {
        const { user } = this.props;
        if (!user) {
            return null;
        }

        const close = () => {
            this.setState({ sendPtsDialog: false, pts: 0 })
            this.onClose();
        };

        return (
            <Dialog open={this.state.sendPtsDialog} onClose={close}>
                <DialogTitle>Puan Gönder</DialogTitle>
                <DialogContent>
                    <Box className="flex pt-4 gap-x-2 items-center">
                        <Typography variant="body1">Puan</Typography>
                        <TextField type="number" value={this.state.pts} onChange={e => this.setState({ pts: e.target.value })}
                            InputProps={{ inputProps: { min: 0 } }}
                        />
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={close} variant="contained" color="error">İptal</Button>
                    <Button onClick={this.doSendPts} variant="contained" color="primary"
                        className="!text-white" disabled={this.state.pts <= 0 || this.state.sendingPts}
                    >
                        Gönder
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }

    renderReportPlayerDialog = () => {
        const { onReportClose } = this.props;
        const { showReportPlayerDialog } = this.state;

        const reportReasonList = [
            "Diğer oyunculara yönelik küfürlü veya saldırgan dil kullanımı.",
            "Oyunun içerisindeki hile veya oyunu manipüle etme girişimleri.",
            "Diğer oyunculara yönelik kişisel taciz, tehdit veya saldırılar.",
            "Oyun içi reklam veya spam içeren mesajlar.",
            "Takım oyunlarındaki işbirliğini olumsuz etkileyen kötü niyetli davranışlar.",
            "Bunların hiçbiri değil."
        ];

        const close = () => {
            this.setState({ reportReason: "", reportReasonText: "", reportDescription: "" })
            this.onClose();
            onReportClose();
        };

        return (
            <Dialog open={showReportPlayerDialog} onClose={close}>
                <DialogTitle>Kullanıcıyı Şikayet Et</DialogTitle>
                <DialogContent sx={{ width: "400px" }}>
                    <Box className="pt-4 gap-x-2">
                        <FormControl fullWidth>
                            <FormLabel>Şikayet Nedeni</FormLabel>
                            <Select
                                value={this.state.reportReason}
                                onChange={e => this.setState({ reportReason: e.target.value, reportReasonText: "" })}
                                displayEmpty
                                variant="outlined"
                                renderValue={(value) => {
                                    if (!value) {
                                        return <Typography color="textSecondary">Lütfen Seçim Yapınız</Typography>
                                    }
                                    return value;
                                }}
                            >
                                {reportReasonList.map((reason, index) => <MenuItem key={index} value={reason}>{reason}</MenuItem>)}
                            </Select>
                        </FormControl>

                        {this.state.reportReason === reportReasonList[reportReasonList.length - 1] && <FormControl sx={{ mt: 2 }} fullWidth>
                            <FormLabel>Diğer</FormLabel>
                            <TextField
                                value={this.state.reportReasonText}
                                onChange={e => this.setState({ reportReasonText: e.target.value })}
                                variant="outlined"
                            />
                        </FormControl>}

                        <FormControl sx={{ mt: 2 }} fullWidth>
                            <FormLabel>Açıklama</FormLabel>
                            <TextField
                                value={this.state.reportDescription}
                                onChange={e => this.setState({ reportDescription: e.target.value })}
                                multiline
                                rows={4}
                                variant="outlined"
                            />
                        </FormControl>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={close} variant="contained" color="error">İptal</Button>
                    <Button onClick={this.doSendReport} variant="contained" color="primary"
                        className="!text-white" disabled={this.state.reportReason === "" || this.state.reportDescription === ""}
                    >
                        Gönder
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }

    deletePremiumUserDialog = () => {
        const { deletePremium } = this.state;

        const onClose = () => {
            this.onClose();
            this.setState({ deletePremium: false });
        }

        return (
            <Dialog open={Boolean(deletePremium)} onClose={onClose}>
                <DialogTitle>Premium Kullanıcı Kaldır</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Premium kullanıcıyı kaldırmak istediğinize emin misiniz?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onClose} variant="contained" color="error">
                        İptal
                    </Button>
                    <Button onClick={() => this.deletePremiumUser()} variant="contained" color="primary" autoFocus className="!text-white">
                        Kaldır
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }

    showIPDialog = () => {
        const { user } = this.props;
        const { showIP, profile } = this.state;

        const onClose = () => {
            this.onClose();
            this.setState({ showIP: false });
        }

        return (
            <Dialog open={Boolean(showIP)} onClose={onClose}>
                <DialogTitle>{this.props.user?.username ?? ''} IP Adresi</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {profile?.ip}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onClose} variant="contained" color="primary" autoFocus className="!text-white">
                        Kapat
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }

    queryIPDialog = () => {
        const { ipQuery, profile } = this.state;

        const onClose = () => {
            this.onClose();
            this.setState({ ipQuery: null });
        }

        return (
            <Dialog open={Boolean(ipQuery)} onClose={onClose}>
                <DialogTitle>{profile?.ip} IP Sorgusu</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {ipQuery}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onClose} variant="contained" color="primary" autoFocus className="!text-white">
                        Kapat
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }

    userAgentDialog = () => {
        const { userAgent } = this.state;

        const onClose = () => {
            this.onClose();
            this.setState({ userAgent: null });
        }

        return (
            <Dialog open={Boolean(userAgent)} onClose={onClose}>
                <DialogTitle>Kullanıcı Tarayıcı Bilgisi</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {userAgent}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onClose} variant="contained" color="primary" autoFocus className="!text-white">
                        Kapat
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }

    resetPointsDialog = () => {
        const onClose = () => {
            this.onClose();
            this.setState({ resetPoints: false });
        }

        return (
            <Dialog open={Boolean(this.state.resetPoints)} onClose={onClose}>
                <DialogTitle>Kullanıcının Puanını Sıfırla</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Kullanıcının puanını 1000 yapmak istediğinize emin misiniz?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onClose} variant="contained" color="error">
                        İptal
                    </Button>
                    <Button onClick={() => this.resetUserPoints()} variant="contained" color="primary" autoFocus className="!text-white">
                        Sıfırla
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }

    presentsDialog = () => {
        const onClose = () => {
            this.onClose();
            this.setState({ sendPresentTo: null, sendingPresent: null });
        }

        return (
            <Dialog open={Boolean(this.state.sendPresentTo)} onClose={onClose}>
                <DialogTitle>Lobide Ismarla</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        <Typography className="!my-2 !text-xs md:!text-sm lg:!text-base">
                            {this.state.sendPresentTo?.username} kullanıcısına ısmarlamak istediğiniz hediyeyi seçin.
                        </Typography>
                    </DialogContentText>

                    <Box className="flex justify-center">
                        <Presents onSelected={(e, present) => this.setState({ sendingPresent: present })} />
                    </Box>
                </DialogContent>
            </Dialog>
        );
    }

    sendPresentConfirmationDialog = () => {
        const { sendPresentTo, sendingPresent } = this.state;

        const onClose = () => {
            this.setState({ sendingPresent: null });
        }

        return (
            <Dialog open={Boolean(sendPresentTo && sendingPresent)} onClose={onClose}>
                <DialogTitle>Hediye Gönder</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        <Typography className="!my-2 !text-xs md:!text-sm lg:!text-base">
                            {`${sendPresentTo?.username} kullanıcısına ${presentNames[sendingPresent] ?? sendingPresent} hediyesini göndermek istediğinize emin misiniz?`}
                        </Typography>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onClose} variant="contained" color="error">
                        İptal
                    </Button>
                    <Button onClick={this.doSendPresent} variant="contained" color="primary" autoFocus className="!text-white">
                        Gönder
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }

    render() {
        const { friendsById, blocksById, user, myUser, myTable, selectedTable, bot } = this.props;

        if (!user) {
            return null;
        }

        const tableId = selectedTable ?? user?.tableId ?? myTable;
        const table = this.props.tables[tableId];

        const onPrivateMessage = this.props.onPrivateMessage ?? this.onPrivateMessage;
        const profile = this.getProfile();
        const disabled = user?.id === myUser?.id || bot;
        const menuOpen = Boolean(this.props.anchor) || Boolean(this.props.anchorPos);

        return (
            <>
                <Menu open={menuOpen}
                    anchorEl={this.props.anchor}
                    anchorPosition={this.props.anchorPos}
                    anchorReference={this.props.anchorPos ? "anchorPosition" : "anchorEl"}
                    onClose={this.onClose}
                    anchorOrigin={{ horizontal: "center", vertical: "center" }} transformOrigin={{ horizontal: "center", vertical: "top" }}
                >
                    {user?.id in friendsById ?
                        <MenuItem onClick={this.onFriendRemove} disabled={disabled} className="!text-xs sm:!text-sm md:!text-base">Arkadaş Sil</MenuItem> :
                        <MenuItem onClick={this.doAddFriend} disabled={disabled} className="!text-xs sm:!text-sm md:!text-base">
                            Arkadaş Ekle
                        </MenuItem>
                    }
                    {user?.id in blocksById ?
                        <MenuItem onClick={this.doUnblock} disabled={disabled} className="!text-xs sm:!text-sm md:!text-base">Engeli Kaldır</MenuItem> :
                        <MenuItem onClick={this.doBlock} disabled={disabled} className="!text-xs sm:!text-sm md:!text-base">
                            Engelle
                        </MenuItem>
                    }

                    <MenuItem onClick={() => onPrivateMessage(this.props.user?.id, this.props.user?.username)} disabled={disabled}
                        className="!text-xs sm:!text-sm md:!text-base">
                        Özel Mesaj
                    </MenuItem>

                    {this.props.onTabUser && (
                        <MenuItem onClick={() => this.onTabUser(this.props.user?.username)} disabled={disabled}
                            className="!text-xs sm:!text-sm md:!text-base">
                            Nick Tab
                        </MenuItem>
                    )}

                    <MenuItem onClick={() => this.onSendPresent(user)} disabled={disabled} className="!text-xs sm:!text-sm md:!text-base">
                        Lobide Ismarla
                    </MenuItem>
                    <MenuItem onClick={this.onUserReport} disabled={disabled} className="!text-xs sm:!text-sm md:!text-base">
                        Şikayet Et
                    </MenuItem>
                    {user?.tableId && <MenuItem onClick={() => this.spectateTable(user.tableId)} disabled={disabled}
                        className="!text-xs sm:!text-sm md:!text-base">
                        Masasını İzle
                    </MenuItem>}
                    {(
                        (user?.tableId && table?.id === user.tableId) ||
                        (table?.spectators.find(s => s === user?.id)) ||
                        (profile?.role >= roles.Admin)
                    ) &&
                        <MenuItem disabled={user.id === myUser.id} onClick={this.onTableKick}
                            className="!text-xs sm:!text-sm md:!text-base">
                            Masadan At
                        </MenuItem>
                    }

                    {/* Admin actions */}
                    {/*profile?.role >= roles.Moderator && (
                        <MenuItem onClick={this.onSendPts} disabled={disabled} className="!text-xs sm:!text-sm md:!text-base">
                            Puan Gönder
                        </MenuItem>
                    )*/}

                    {(profile?.perm & Number(1n << 10n)) > 0 && <MenuItem onClick={this.onServerKick} disabled={disabled}
                        className="!text-xs sm:!text-sm md:!text-base"
                    >
                        Sunucudan At
                    </MenuItem>
                    }
                    {(profile?.perm & Number(1n << 19n)) > 0 && (
                        <NestedMenuItem label={
                            <Typography className="!text-xs sm:!text-sm md:!text-base">
                                Sustur
                            </Typography>
                        }
                            rightIcon={<ArrowRightIcon className="!text-base sm:!text-ld md:!text-xl" />} disabled={disabled}
                            parentMenuOpen={menuOpen && !disabled}
                            sx={{ '& .MuiTypography-root': { p: 0 } }}
                        >
                            <MenuItem disabled={disabled} onClick={() => this.onChatBan(false)}
                                className="!text-xs sm:!text-sm md:!text-base"
                            >
                                Süreli Susturma
                            </MenuItem>
                            <MenuItem disabled={disabled} onClick={() => this.onChatBan(true)}
                                className="!text-xs sm:!text-sm md:!text-base"
                            >
                                Süresiz Susturma
                            </MenuItem>
                            <MenuItem disabled={disabled} onClick={() => this.deleteChatBan()}
                                className="!text-xs sm:!text-sm md:!text-base"
                            >
                                Susturmayı Kaldır
                            </MenuItem>
                        </NestedMenuItem>
                    )}
                    {(profile?.perm & Number(1n << 9n)) > 0 && (
                        <NestedMenuItem label={
                            <Typography className="!text-xs sm:!text-sm md:!text-base">
                                Premium
                            </Typography>
                        }
                            rightIcon={<ArrowRightIcon className="!text-base sm:!text-ld md:!text-xl" />} disabled={disabled}
                            parentMenuOpen={menuOpen && !disabled}
                            sx={{ '& .MuiTypography-root': { p: 0 } }}
                        >
                            <MenuItem disabled={disabled} onClick={() => this.onPremium(false)}
                                className="!text-xs sm:!text-sm md:!text-base"
                            >
                                Süreli Premium
                            </MenuItem>
                            <MenuItem disabled={disabled} onClick={() => this.onPremium(true)}
                                className="!text-xs sm:!text-sm md:!text-base"
                            >
                                Süresiz Premium
                            </MenuItem>
                            <MenuItem disabled={disabled} onClick={() => this.setState({ deletePremium: true })}
                                className="!text-xs sm:!text-sm md:!text-base"
                            >
                                Premium Kaldır
                            </MenuItem>
                        </NestedMenuItem>
                    )}
                    {((profile?.perm & Number(1n << 11n)) > 0 || (profile?.perm & Number(1n << 12n)) > 0) && (
                        <NestedMenuItem label={
                            <Typography className="!text-xs sm:!text-sm md:!text-base">
                                Ban
                            </Typography>
                        }
                            rightIcon={<ArrowRightIcon className="!text-base sm:!text-ld md:!text-xl" />} disabled={disabled}
                            parentMenuOpen={menuOpen && !disabled}
                            sx={{ '& .MuiTypography-root': { p: 0 } }}
                        >
                            {(profile?.perm & Number(1n << 11n)) > 0 && (
                                <MenuItem disabled={disabled} onClick={() => this.onBan(false, false)}
                                    className="!text-xs sm:!text-sm md:!text-base"
                                >
                                    Süreli Ban
                                </MenuItem>
                            )}
                            {(profile?.perm & Number(1n << 12n)) > 0 && (
                                <MenuItem disabled={disabled} onClick={() => this.onBan(true, false)}
                                    className="!text-xs sm:!text-sm md:!text-base"
                                >
                                    Süresiz Ban
                                </MenuItem>
                            )}
                            {(profile?.perm & Number(1n << 11n)) > 0 && (
                                <MenuItem disabled={disabled} onClick={() => this.onBan(false, true)}
                                    className="!text-xs sm:!text-sm md:!text-base"
                                >
                                    Süreli IP Ban
                                </MenuItem>
                            )}
                            {(profile?.perm & Number(1n << 12n)) > 0 && (
                                <MenuItem disabled={disabled} onClick={() => this.onBan(true, true)}
                                    className="!text-xs sm:!text-sm md:!text-base"
                                >
                                    Süresiz IP Ban
                                </MenuItem>
                            )}
                        </NestedMenuItem>
                    )}
                    {((profile?.perm & Number(1n << 17n)) > 0 || (profile?.perm & Number(1n << 21n)) > 0 || (profile?.perm & Number(1n << 22n)) > 0) && (
                        <NestedMenuItem label={
                            <Typography className="!text-xs sm:!text-sm md:!text-base">
                                Diğer
                            </Typography>
                        }
                            rightIcon={<ArrowRightIcon className="!text-base sm:!text-ld md:!text-xl" />} disabled={disabled}
                            parentMenuOpen={menuOpen && !disabled}
                            sx={{ '& .MuiTypography-root': { p: 0 } }}
                        >
                            {(profile?.perm & Number(1n << 22n)) > 0 && (
                                <MenuItem disabled={disabled} onClick={() => this.showIP()}
                                    className="!text-xs sm:!text-sm md:!text-base"
                                >
                                    IP Adresine Bak
                                </MenuItem>
                            )}
                            {(profile?.perm & Number(1n << 22n)) > 0 && (
                                <MenuItem disabled={disabled} onClick={() => this.queryIPById()}
                                    className="!text-xs sm:!text-sm md:!text-base"
                                >
                                    IP Sorgula
                                </MenuItem>
                            )}
                            {(profile?.perm & Number(1n << 21n)) > 0 && (
                                <MenuItem disabled={disabled} onClick={() => this.getUserAgent()}
                                    className="!text-xs sm:!text-sm md:!text-base"
                                >
                                    Tarayıcı Bilgisi
                                </MenuItem>
                            )}
                            {(profile?.perm & Number(1n << 17n)) > 0 && (
                                <MenuItem disabled={disabled} onClick={() => this.setState({ resetPoints: true })}
                                    className="!text-xs sm:!text-sm md:!text-base"
                                >
                                    Puanı 1000 Yap
                                </MenuItem>
                            )}
                        </NestedMenuItem>
                    )}

                </Menu >

                {this.renderSendPtsDialog()}
                {this.renderReportPlayerDialog()}
                {this.deletePremiumUserDialog()}
                {this.showIPDialog()}
                {this.queryIPDialog()}
                {this.resetPointsDialog()}
                {this.userAgentDialog()}
                {this.presentsDialog()}
                {this.sendPresentConfirmationDialog()}
                {this.renderRemoveFriendDialog()}

                <EditChatBan open={this.state.openChatBan} username={user?.username} unlimited={this.state.unlimitedChatBan}
                    onClose={() => this.setState({ openChatBan: false })}
                />

                <EditPremiumUser open={this.state.openPremium} username={user?.username} unlimited={this.state.unlimitedPremium}
                    onClose={() => this.setState({ openPremium: false })}
                />

                <EditUserBan open={this.state.openUserBan} username={user?.username} unlimited={this.state.unlimitedUserBan}
                    onClose={() => this.setState({ openUserBan: false })}
                />

                <EditIPBan open={this.state.openIPBan} ip={this.state.profile?.ip} unlimited={this.state.unlimitedIPBan}
                    onClose={() => this.setState({ openIPBan: false })}
                />

            </>
        );
    }
}

UserActions.propTypes = {
    classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
    return {
        chatTabs: state.app.global.chatTabs,
        socket: state.app.global.socket,
        friendsById: state.app.global.friendsById,
        blocksById: state.app.global.blocksById,
        myUser: state.app.global.user,
        selectedTable: state.app.global.selectedTable,
        myTable: state.app.global.myTable,
        tables: state.app.lobby.tables,
    }
};

export default withStyles(styles)(connect(mapStateToProps, {
    setNotification, addBlockedUser, removeBlockedUser, addFriend, removeFriend,
    addChatTab, setReportDialog
})(UserActions));
