import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import configureStore from './store';
import { CookiesProvider } from 'react-cookie';
import { createTheme, ThemeProvider } from '@mui/material';

export const store = configureStore();

const theme = createTheme({
  palette: {
    primary: {
      main: '#37BA46'
    },
    secondary: {
      main: '#DBCFB0',
    },
    error: {
      main: '#D83C3C',
    }
  },
  components: {
    MuiDialog: {
      styleOverrides: {
        root: {
          minWidth: '320px',
          minHeight: '100px',
        },
        paper: {
          minWidth: '320px',
          minHeight: '100px',
        },
      }
    }
  }
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
    <CookiesProvider>
      <ThemeProvider theme={theme}>
        <App store={store} />
      </ThemeProvider>
    </CookiesProvider>
  </Provider>
);


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
