import React from "react";
import { connect } from "react-redux";
import { withStyles } from "@mui/styles";
import PropTypes from "prop-types";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { setRejoinTableId } from "../../actions/lobby";


const styles = theme => ({
});

class RejoinTable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        }

    }

    componentDidMount() {
    }

    componentDidUpdate(prevProps) {
    }

    rejoin = () => {
        const { socket, rejoinTable } = this.props;
        socket.emit('join_table', { tableId: rejoinTable?.id, index: rejoinTable?.index, password: rejoinTable?.password });
        this.props.setRejoinTableId(null);
    }

    leaveTable = () => {
        const { socket, rejoinTable } = this.props;
        socket.emit('leave_table', { tableId: rejoinTable?.id });
        this.props.setRejoinTableId(null);
    }

    render() {
        const { classes } = this.props;

        return (
            <Dialog
                maxWidth={"lg"}
                //fullWidth
                open={Boolean(this.props.rejoinTable)}
            >
                <DialogTitle>
                    Tekrar Otur
                </DialogTitle>

                <DialogContent>
                    Devam eden bir oyununuz mevcut. Masaya tekrar oturmak ister misiniz?
                </DialogContent>

                <DialogActions>
                    <Button variant="contained" disabled={false} color="error" sx={{ color: 'var(--white)' }}
                        onClick={this.leaveTable}>
                        Hayır
                    </Button>
                    <Button variant="contained" disabled={false} sx={{ color: 'var(--white)' }}
                        onClick={this.rejoin}>
                        Evet
                    </Button>
                </DialogActions>

            </Dialog>
        );
    }
}

RejoinTable.propTypes = {
    classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
    return {
        rejoinTable: state.app.lobby.rejoinTable,
        socket: state.app.global.socket,
    }
};

export default withStyles(styles)(connect(mapStateToProps, {
    setRejoinTableId
})(RejoinTable));
