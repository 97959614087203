import React from "react";
import { connect } from "react-redux";
import { withStyles } from "@mui/styles";
import PropTypes from "prop-types";
import { Box, Button, Card, CardContent, Typography } from "@mui/material";
import { setNotification } from "../../actions/global";
import { GlobalTypes } from "../../action_types";
import qs from 'query-string';
import { ERROR_CODES } from "../../global/error";
import clsx from "clsx";
import { getPath } from "../../global/route";
import { AxiosClient } from "../../global/axios";
import { AxiosError } from "axios";

const styles = theme => ({
    root: {
        backgroundColor: '#624799',
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        overflowY: 'auto',
    },
    title: {
        fontWeight: 'bold !important',
        fontFamily: 'Roboto Slab',
        color: '#1E2C5F !important',
    },
    text: {
        fontWeight: 'medium !important',
        color: '#1E2C5F !important',
    },
    textField: {
        fontFamily: 'Roboto Slab',
        fontWeight: 'bold !important',
    },
    inputIcon: {
        marginRight: '8px',
        color: '#1E2C5F !important'
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        rowGap: 12,
        padding: 24,
        color: 'white !important',
    },
    header: {
        //backgroundImage: "linear-gradient(to right, #624799 50%, #1D9AB6)",
        backgroundColor: '#624799',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        columnGap: 12
    },
    headerTitle: {
        color: '#E6E1FF',
    },
    button: {
    }
});

class DeleteAccount extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            deleting: false,
            deleted: false,
        }

        this.email = '';
        this.token = '';
    }

    componentDidMount = () => {
        const search = this.props.history?.location?.search ?? '';
        const q = qs.parse(search);

        this.id = q.i;
        this.token = q.t;

        if (!this.id || !this.token) {
            const message = 'Parametreler hatalı, anasayfaya yönlendiriliyorsunuz.'
            this.props.setNotification({ message: message, severity: GlobalTypes.NOTIFY_ERR });
            setTimeout(() => {
                this.props.history.push(getPath('/'));
            }, 1000);
        }
    }

    delete = async () => {
        const req = {
            id: this.id,
            token: this.token,
        }

        try {
            this.setState({ deleting: true });
            await AxiosClient().post(`${process.env.REACT_APP_API_URL}/v1/user/delete`, req);
            this.setState({ deleted: true });

            const message = 'Hesap başarıyla silindi. Yönlendiriliyorsunuz.';
            this.props.setNotification({ message: message, severity: GlobalTypes.NOTIFY_SUCC });

            setTimeout(() => {
                this.props.history.push(getPath('/'));
            }, 2000);
        }
        catch (e) {
            console.error(e);
            let message = e.toString();

            if (e instanceof AxiosError) {
                const code = e.response?.data?.error?.code;
                if (code in ERROR_CODES) {
                    message = ERROR_CODES[code];
                } else {
                    message = e.message;
                }
            }

            this.props.setNotification({ message: message, severity: GlobalTypes.NOTIFY_ERR, traceId: e.response?.data?.trace_id });
        }
        finally {
            this.setState({ deleting: false });
        }
    }

    header = () => {
        const { classes } = this.props;
        const imgPath = getPath('/img');

        return (
            <Box height={60} px={4}
                className={clsx(classes.header, "flex")}
            >
                <img src={`${imgPath}/logo.svg`} alt="logo" />
                <Box>
                    <Typography className={clsx("!font-['Roboto Slab'] !text-white !text-sm md:!text-base !font-bold")}>{process.env.REACT_APP_HEADER_TITLE}</Typography>
                    <Typography className={clsx("!font-['Roboto Slab'] !text-white !text-sm md:!text-base !font-bold")}>{process.env.REACT_APP_HEADER_SUBTITLE}</Typography>
                </Box>
            </Box>
        );
    }

    render() {
        const { classes } = this.props;
        const { deleting, deleted } = this.state;
        let disabled = deleting || deleted;

        return (
            <div className={classes.root}>

                {this.header()}

                <div className="w-full flex-1 self-center max-w-md mt-[4vh]">
                    <Card sx={{ backgroundColor: '#FFFFFF', borderRadius: 4, border: '1px solid #E8E8E8' }}>
                        <CardContent sx={{ padding: 0 }}>

                            <div className="w-full flex flex-col gap-y-4 justify-center items-center mt-4">
                                <Typography sx={{ color: '#1E2C5F' }} className="!text-xs sm:!text-sm md:!text-base">
                                    Hesabınızı silmek istediğinize emin misiniz?
                                </Typography>

                                <Box className="flex gap-x-2">
                                    <Button
                                        isableFocusRipple disableRipple disableTouchRipple color="error"
                                        sx={{
                                            color: '#FFFFFF !important',
                                            backgroundImage: "linear-gradient(to bottom, #2FB16B 50%, #168549)"
                                        }}
                                        className="!text-xs sm:!text-sm md:!text-base"
                                        onClick={() => this.props.history.push(getPath('/'))}
                                    >
                                        Geri Dön
                                    </Button>

                                    <Button variant="contained" disabled={disabled} onClick={() => this.delete()}
                                        disableFocusRipple disableRipple disableTouchRipple color="error"
                                        sx={{
                                            color: '#FFFFFF',
                                        }}
                                        className="!text-xs sm:!text-sm md:!text-base"
                                    >
                                        Sil
                                    </Button>
                                </Box>
                            </div>

                        </CardContent>
                    </Card>
                </div>

                <div className="w-full flex flex-col gap-y-2 justify-center items-center mt-4">
                    <Typography sx={{ color: '#FFFFFF' }} className="!text-xs sm:!text-sm md:!text-base">
                        Tüm hakları saklıdır. © 2024 kerizim.com
                    </Typography>
                </div>

            </div>
        );
    }
}

DeleteAccount.propTypes = {
    classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
    return {
    }
};

export default withStyles(styles)(connect(mapStateToProps, {
    setNotification,
})(DeleteAccount));
