import React from "react";
import { connect } from "react-redux";
import { withStyles } from "@mui/styles";
import PropTypes from "prop-types";
import { Avatar, Box, Dialog, DialogContent, DialogTitle, Divider, IconButton, SvgIcon, Tooltip, Typography } from "@mui/material";
import { mdiAccount, mdiRobot } from "@mdi/js";
import {
    Close as CloseIcon
} from '@mui/icons-material';
import clsx from "clsx";

const styles = theme => ({
    title: {
        fontFamily: 'Roboto Slab !important',
        fontWeight: '500 !important',
    },
    text: {
        fontFamily: 'Roboto Slab !important',
        fontWeight: '400 !important',
    },
});

class Indicator extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
        }

    }

    componentDidMount() {
    }

    componentDidUpdate(prevProps) {
        const { selectedTable } = this.props;

        if (selectedTable && prevProps.tables[selectedTable]?.showIndicatorAt !== this.props.tables[selectedTable]?.showIndicatorAt && this.props.tables[selectedTable]?.indicatorUser) {
            this.setState({ open: true });
        }
    }

    onClose = () => {
        this.setState({ open: false });
    }

    render() {
        const { classes } = this.props;
        const lobbyUser = this.props.usersById[this.props.indicatorUser];

        return (
            <Dialog open={this.state.open} maxWidth="lg">
                <DialogTitle>
                    <Box padding={0} display={"flex"} justifyContent={"space-between"}>
                        <Typography className={clsx(classes.title, "!text-sm sm:!text-base md:!text-lg")}>GÖSTERGE</Typography>
                        <Tooltip title="Kapat" placement="top">
                            <IconButton size="small" onClick={this.onClose} sx={{ backgroundColor: '#624799 !important' }}>
                                <CloseIcon htmlColor="var(--white)" fontSize="small" />
                            </IconButton>
                        </Tooltip>
                    </Box>
                </DialogTitle>
                <DialogContent>
                    <Box display={"flex"} flexDirection={"column"} rowGap={1} p={1}>
                        <Typography className={clsx(classes.text, "!text-xs sm:!text-sm md:!text-base")}>
                            {lobbyUser?.username} gösterge yaptı.
                        </Typography>
                    </Box>
                </DialogContent>
            </Dialog >
        );
    }
}

Indicator.propTypes = {
    classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
    return {
        tables: state.app.table.tables,
        selectedTable: state.app.global.selectedTable,
        usersById: state.app.lobby.usersById,
    }
};

export default withStyles(styles)(connect(mapStateToProps, {
})(Indicator));
