import React from "react";
import { connect } from "react-redux";
import { withStyles } from "@mui/styles";
import PropTypes from "prop-types";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, InputAdornment, MenuItem, SvgIcon, Table, TableBody, TableCell, TableRow, TextField, Tooltip, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { Close } from "@mui/icons-material";
import { setNotification } from "../../actions/global";
import { GlobalTypes } from "../../action_types";
import { AxiosClient } from "../../global/axios";
import { logout } from "../../global/connection";


const styles = theme => ({
    inputIcon: {
        marginRight: '8px',
        color: '#31204A !important'
    },
});

class DeleteAccount extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            deleting: false,
        }

    }

    componentDidMount() {
    }

    componentDidUpdate(prevProps) {
    }

    onClose = () => {
        this.setState({ deleting: false });
        this.props.onClose();
    }

    doDelete = () => {
        this.setState({ deleting: true });

        AxiosClient().delete(`${process.env.REACT_APP_API_URL}/v1/user`).then((resp) => {
            this.props.setNotification({ message: 'Hesap silme isteği oluşturuldu. Lütfen e-posta adresinizi kontrol ediniz.', severity: GlobalTypes.NOTIFY_SUCC });
            this.onClose();

            setTimeout(() => {
                logout(true, this.props.socket)
            }, 1000);
        }).catch((err) => {
            console.error(err);
            this.props.setNotification({ message: 'Bir hata oluştu. Lütfen tekrar deneyiniz.', severity: GlobalTypes.NOTIFY_ERR });
        }).finally(() => {
            this.setState({ deleting: false });
        });
    }

    render() {
        const { classes } = this.props;
        const { deleting } = this.state;

        return (
            <Dialog
                maxWidth={"xs"}
                fullWidth
                open={this.props.open}
            >
                <DialogTitle>
                    <Box padding={0} display={"flex"} justifyContent={"space-between"}>
                        <Typography className={"!text-xs sm:!text-sm md:!text-base"}>
                            Hesabı Sil
                        </Typography>

                        <Tooltip title="Kapat" placement="top">
                            <IconButton size="small" onClick={this.onClose} sx={{ backgroundColor: '#624799 !important' }} tabIndex={-1}>
                                <Close htmlColor="var(--white)" fontSize="small" />
                            </IconButton>
                        </Tooltip>
                    </Box>
                </DialogTitle>

                <DialogContent>
                    <Box
                        className="flex flex-col py-4 gap-y-2"
                    >
                        <Typography className={"!text-xs md:!text-sm"}>
                            Hesabınızı silmek istediğinize emin misiniz?
                        </Typography>
                        <Typography className={"!text-xs md:!text-sm"}>
                            Eğer silerseniz, hesabınız ve tüm verileriniz kalıcı olarak silinecektir.
                        </Typography>
                        <Typography className={"!text-xs md:!text-sm"}>
                            Silme işlemini tamamlamak için e-posta adresinize bir onay e-postası gönderilecektir.
                        </Typography>
                    </Box>
                </DialogContent>

                <DialogActions sx={{ p: 2 }}>
                    <Button variant="contained" disabled={false} onClick={() => this.onClose()}
                        disableFocusRipple disableRipple disableTouchRipple color="error"
                        sx={{
                            color: '#FFFFFF',
                        }}
                        className="!text-xs sm:!text-sm md:!text-base"
                    >
                        İptal
                    </Button>

                    <Button variant="contained" disabled={deleting} onClick={() => this.doDelete()}
                        disableFocusRipple disableRipple disableTouchRipple color="error"
                        sx={{
                            color: '#FFFFFF !important', backgroundColor: deleting ? '#939393 !important' : null,
                            backgroundImage: deleting ? null : "linear-gradient(to bottom, #2FB16B 50%, #168549)"
                        }}
                        className="!text-xs sm:!text-sm md:!text-base"
                    >
                        Sil
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

DeleteAccount.propTypes = {
    classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
    return {
        socket: state.app.global.socket,
    }
};

export default withStyles(styles)(connect(mapStateToProps, {
    setNotification,
})(DeleteAccount));
