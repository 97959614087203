import { Buffer } from "buffer";
/* global BigInt */

export const generateRequestId = () => {
    const ts = Date.now();
    const tsHex = new Buffer(8);
    tsHex.writeBigUInt64BE(BigInt(ts), 0);

    const requestIdHex = new Buffer(16);
    for (let i = 0; i < 8; i++) {
        requestIdHex[i * 2 + 1] = tsHex[i];
    }

    for (let i = 0; i < 8; i++) {
        requestIdHex[i * 2] = Math.floor(Math.random() * 256);
    }

    const requestId = requestIdHex.toString('hex');

    // convert requestIdId to uuid format
    const uuid = requestId.slice(0, 8) + '-' + requestId.slice(8, 12) + '-' + requestId.slice(12, 16) + '-' + requestId.slice(16, 20) + '-' + requestId.slice(20, 32);
    return uuid;
}