import React from "react";
import { connect } from "react-redux";
import { withStyles } from "@mui/styles";
import PropTypes from "prop-types";
import { Box, Button, MenuItem, Tab, Table, TableBody, TableCell, TableRow, Tabs, TextField, Typography } from "@mui/material";
import axios from "axios";
import { setAdminTab, setNotification } from "../../../actions/global";
import { GlobalTypes } from "../../../action_types";
import clsx from "clsx";
import { AxiosClient } from "../../../global/axios";


const styles = theme => ({
    table: {
        border: '2px solid #f0f0f0',
        userSelect: 'text !important',
    },
    tCell: {
        border: 0,
        padding: '4px 16px !important',
        verticalAlign: 'top',
    },
});

const tabs = {
    CONFIG: 0,
}

class RoomSettings extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            tab: tabs.CONFIG,
            config: null,
        }

    }

    componentDidMount() {
        this.getRoomConfig();
        this.setState({ tab: this.props.adminTab });
    }

    componentDidUpdate(prevProps) {
    }

    getRoomConfig = async () => {
        try {
            const { search, page, size } = this.state;
            const resp = await AxiosClient().get(`${process.env.REACT_APP_API_URL}/v1/admin/room/config`);

            this.setState({ config: resp.data });

        } catch (err) {
            console.error(err);
            this.props.setNotification({ message: 'Bir hata oluştu. Lütfen tekrar deneyiniz.', severity: GlobalTypes.NOTIFY_ERR });
        }
    }

    editRoomConfig = async () => {
        try {
            const { config } = this.state;
            const resp = await AxiosClient().post(`${process.env.REACT_APP_API_URL}/v1/admin/room/config`, config);

            this.props.setNotification({ message: 'Oda configi kaydedildi.', severity: GlobalTypes.NOTIFY_SUCC });


        } catch (err) {
            console.error(err);
            this.props.setNotification({ message: 'Bir hata oluştu. Lütfen tekrar deneyiniz.', severity: GlobalTypes.NOTIFY_ERR });
        } finally {
            this.setState({ approveRegistration: null }, () => this.getRoomConfig());
        }
    }

    onTabChange = (e, value) => {
        this.props.setAdminTab(value);
        this.setState({ tab: value });
    }

    configTab = () => {
        const { classes } = this.props;
        const { config } = this.state;

        return (
            <Box margin={1} display={"flex"} flexDirection={"column"} rowGap={1}>
                <Table className={clsx(classes.table, "w-full")}>
                    <TableBody>

                        <TableRow>
                            <TableCell className={classes.tCell}>
                                <Typography className="!text-xs md:!text-sm lg:!text-base">Oda Adı</Typography>
                            </TableCell>
                            <TableCell className={classes.tCell}>
                                <TextField margin="dense" fullWidth
                                    value={config?.name} sx={{
                                        borderTopRightRadius: '0 !important',
                                        borderBottomRightRadius: '0 !important',
                                        margin: 0,
                                    }}
                                    InputProps={{
                                        classes: {
                                            input: "h-8 sm:h-12 !text-xs md:!text-sm lg:!text-base",
                                            root: "h-8 sm:h-12",
                                        }
                                    }}
                                    onChange={(e) => this.setState({ config: { ...this.state.config, name: e.target.value } })}
                                />
                            </TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell className={classes.tCell}>
                                <Typography className="!text-xs md:!text-sm lg:!text-base">Radyo URL</Typography>
                            </TableCell>
                            <TableCell className={classes.tCell}>
                                <TextField margin="dense" fullWidth
                                    value={config?.radio_url} sx={{
                                        borderTopRightRadius: '0 !important',
                                        borderBottomRightRadius: '0 !important',
                                        margin: 0,
                                    }}
                                    InputProps={{
                                        classes: {
                                            input: "h-8 sm:h-12 !text-xs md:!text-sm lg:!text-base",
                                            root: "h-8 sm:h-12",
                                        }
                                    }}
                                    onChange={(e) => this.setState({ config: { ...this.state.config, radio_url: e.target.value } })}
                                />
                            </TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell className={classes.tCell}>
                                <Typography className="!text-xs md:!text-sm lg:!text-base">Oda Başlığı</Typography>
                            </TableCell>
                            <TableCell className={classes.tCell}>
                                <TextField margin="dense" fullWidth
                                    value={config?.title} sx={{
                                        borderTopRightRadius: '0 !important',
                                        borderBottomRightRadius: '0 !important',
                                        margin: 0,
                                    }}
                                    InputProps={{
                                        classes: {
                                            input: "h-8 sm:h-12 !text-xs md:!text-sm lg:!text-base",
                                            root: "h-8 sm:h-12",
                                        }
                                    }}
                                    onChange={(e) => this.setState({ config: { ...this.state.config, title: e.target.value } })}
                                />
                            </TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell className={classes.tCell}>
                                <Typography className="!text-xs md:!text-sm lg:!text-base">SEO Linki</Typography>
                            </TableCell>
                            <TableCell className={classes.tCell}>
                                <TextField margin="dense" fullWidth
                                    value={config?.seo_url} sx={{
                                        borderTopRightRadius: '0 !important',
                                        borderBottomRightRadius: '0 !important',
                                        margin: 0,
                                    }}
                                    InputProps={{
                                        classes: {
                                            input: "h-8 sm:h-12 !text-xs md:!text-sm lg:!text-base",
                                            root: "h-8 sm:h-12",
                                        }
                                    }}
                                    onChange={(e) => this.setState({ config: { ...this.state.config, seo_url: e.target.value } })}
                                />
                            </TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell className={classes.tCell}>
                                <Typography className="!text-xs md:!text-sm lg:!text-base">Lobide Giriş Çıkışları Göster</Typography>
                            </TableCell>
                            <TableCell className={classes.tCell}>
                                <TextField margin="dense" fullWidth select
                                    value={config?.showLogins ?? false} sx={{
                                        borderTopRightRadius: '0 !important',
                                        borderBottomRightRadius: '0 !important',
                                        margin: 0,
                                    }}
                                    InputProps={{
                                        classes: {
                                            input: "h-8 sm:h-12 !text-xs md:!text-sm lg:!text-base",
                                            root: "h-8 sm:h-12",
                                        }
                                    }}
                                    onChange={(e) => this.setState({ config: { ...this.state.config, showLogins: e.target.value } })}
                                >
                                    <MenuItem value={true}>Açık</MenuItem>
                                    <MenuItem value={false}>Kapalı</MenuItem>
                                </TextField>
                            </TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell className={classes.tCell}>
                                <Typography className="!text-xs md:!text-sm lg:!text-base">Lobide Sadece Premium Üyeler Konuşabilir</Typography>
                            </TableCell>
                            <TableCell className={classes.tCell}>
                                <TextField margin="dense" fullWidth select
                                    value={config?.allowOnlyPremiumChatInLobby ?? false} sx={{
                                        borderTopRightRadius: '0 !important',
                                        borderBottomRightRadius: '0 !important',
                                        margin: 0,
                                    }}
                                    InputProps={{
                                        classes: {
                                            input: "h-8 sm:h-12 !text-xs md:!text-sm lg:!text-base",
                                            root: "h-8 sm:h-12",
                                        }
                                    }}
                                    onChange={(e) => this.setState({ config: { ...this.state.config, allowOnlyPremiumChatInLobby: e.target.value } })}
                                >
                                    <MenuItem value={true}>Açık</MenuItem>
                                    <MenuItem value={false}>Kapalı</MenuItem>
                                </TextField>
                            </TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell className={classes.tCell}>
                                <Typography className="!text-xs md:!text-sm lg:!text-base">Özelde Sadece Premium Üyeler Konuşabilir</Typography>
                            </TableCell>
                            <TableCell className={classes.tCell}>
                                <TextField margin="dense" fullWidth select
                                    value={config?.allowOnlyPremiumChatInPrivate ?? false} sx={{
                                        borderTopRightRadius: '0 !important',
                                        borderBottomRightRadius: '0 !important',
                                        margin: 0,
                                    }}
                                    InputProps={{
                                        classes: {
                                            input: "h-8 sm:h-12 !text-xs md:!text-sm lg:!text-base",
                                            root: "h-8 sm:h-12",
                                        }
                                    }}
                                    onChange={(e) => this.setState({ config: { ...this.state.config, allowOnlyPremiumChatInPrivate: e.target.value } })}
                                >
                                    <MenuItem value={true}>Açık</MenuItem>
                                    <MenuItem value={false}>Kapalı</MenuItem>
                                </TextField>
                            </TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell className={classes.tCell}>
                                <Typography className="!text-xs md:!text-sm lg:!text-base">Masada Sadece Premium Üyeler Konuşabilir</Typography>
                            </TableCell>
                            <TableCell className={classes.tCell}>
                                <TextField margin="dense" fullWidth select
                                    value={config?.allowOnlyPremiumChatInTable ?? false} sx={{
                                        borderTopRightRadius: '0 !important',
                                        borderBottomRightRadius: '0 !important',
                                        margin: 0,
                                    }}
                                    InputProps={{
                                        classes: {
                                            input: "h-8 sm:h-12 !text-xs md:!text-sm lg:!text-base",
                                            root: "h-8 sm:h-12",
                                        }
                                    }}
                                    onChange={(e) => this.setState({ config: { ...this.state.config, allowOnlyPremiumChatInTable: e.target.value } })}
                                >
                                    <MenuItem value={true}>Açık</MenuItem>
                                    <MenuItem value={false}>Kapalı</MenuItem>
                                </TextField>
                            </TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell className={classes.tCell}>
                                <Typography className="!text-xs md:!text-sm lg:!text-base">Lobi Sohbeti</Typography>
                            </TableCell>
                            <TableCell className={classes.tCell}>
                                <TextField margin="dense" fullWidth select
                                    value={config?.allowLobbyChat ?? false} sx={{
                                        borderTopRightRadius: '0 !important',
                                        borderBottomRightRadius: '0 !important',
                                        margin: 0,
                                    }}
                                    InputProps={{
                                        classes: {
                                            input: "h-8 sm:h-12 !text-xs md:!text-sm lg:!text-base",
                                            root: "h-8 sm:h-12",
                                        }
                                    }}
                                    onChange={(e) => this.setState({ config: { ...this.state.config, allowLobbyChat: e.target.value } })}
                                >
                                    <MenuItem value={true}>Açık</MenuItem>
                                    <MenuItem value={false}>Kapalı</MenuItem>
                                </TextField>
                            </TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell className={classes.tCell}>
                                <Typography className="!text-xs md:!text-sm lg:!text-base">Odaya Giriş Puanı</Typography>
                            </TableCell>
                            <TableCell className={classes.tCell}>
                                <TextField margin="dense" fullWidth
                                    value={config?.minLobbyPoints ?? 0} sx={{
                                        borderTopRightRadius: '0 !important',
                                        borderBottomRightRadius: '0 !important',
                                        margin: 0,
                                    }}
                                    InputProps={{
                                        classes: {
                                            input: "h-8 sm:h-12 !text-xs md:!text-sm lg:!text-base",
                                            root: "h-8 sm:h-12",
                                        }
                                    }}
                                    onChange={(e) => this.setState({ config: { ...this.state.config, minLobbyPoints: e.target.value ? parseInt(e.target.value) : 0 } })}
                                />
                            </TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell className={classes.tCell}>
                                <Typography className="!text-xs md:!text-sm lg:!text-base">Lobide Minimum Konuşma Puanı</Typography>
                            </TableCell>
                            <TableCell className={classes.tCell}>
                                <TextField margin="dense" fullWidth
                                    value={config?.minLobbyChatPoints ?? 0} sx={{
                                        borderTopRightRadius: '0 !important',
                                        borderBottomRightRadius: '0 !important',
                                        margin: 0,
                                    }}
                                    InputProps={{
                                        classes: {
                                            input: "h-8 sm:h-12 !text-xs md:!text-sm lg:!text-base",
                                            root: "h-8 sm:h-12",
                                        }
                                    }}
                                    onChange={(e) => this.setState({ config: { ...this.state.config, minLobbyChatPoints: e.target.value ? parseInt(e.target.value) : 0 } })}
                                />
                            </TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell className={classes.tCell}>
                                <Typography className="!text-xs md:!text-sm lg:!text-base">Özelde Minimum Konuşma Puanı</Typography>
                            </TableCell>
                            <TableCell className={classes.tCell}>
                                <TextField margin="dense" fullWidth
                                    value={config?.minPrivateChatPoints ?? 0} sx={{
                                        borderTopRightRadius: '0 !important',
                                        borderBottomRightRadius: '0 !important',
                                        margin: 0,
                                    }}
                                    InputProps={{
                                        classes: {
                                            input: "h-8 sm:h-12 !text-xs md:!text-sm lg:!text-base",
                                            root: "h-8 sm:h-12",
                                        }
                                    }}
                                    onChange={(e) => this.setState({ config: { ...this.state.config, minPrivateChatPoints: e.target.value ? parseInt(e.target.value) : 0 } })}
                                />
                            </TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell className={classes.tCell}>
                                <Typography className="!text-xs md:!text-sm lg:!text-base">Masada Minimum Konuşma Puanı</Typography>
                            </TableCell>
                            <TableCell className={classes.tCell}>
                                <TextField margin="dense" fullWidth
                                    value={config?.minTableChatPoints ?? 0} sx={{
                                        borderTopRightRadius: '0 !important',
                                        borderBottomRightRadius: '0 !important',
                                        margin: 0,
                                    }}
                                    InputProps={{
                                        classes: {
                                            input: "h-8 sm:h-12 !text-xs md:!text-sm lg:!text-base",
                                            root: "h-8 sm:h-12",
                                        }
                                    }}
                                    onChange={(e) => this.setState({ config: { ...this.state.config, minTableChatPoints: e.target.value ? parseInt(e.target.value) : 0 } })}
                                />
                            </TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell className={classes.tCell}>
                                <Typography className="!text-xs md:!text-sm lg:!text-base">Minimum Masa Açma Puanı</Typography>
                            </TableCell>
                            <TableCell className={classes.tCell}>
                                <TextField margin="dense" fullWidth
                                    value={config?.minCreateTablePoints ?? 0} sx={{
                                        borderTopRightRadius: '0 !important',
                                        borderBottomRightRadius: '0 !important',
                                        margin: 0,
                                    }}
                                    InputProps={{
                                        classes: {
                                            input: "h-8 sm:h-12 !text-xs md:!text-sm lg:!text-base",
                                            root: "h-8 sm:h-12",
                                        }
                                    }}
                                    onChange={(e) => this.setState({ config: { ...this.state.config, minCreateTablePoints: e.target.value ? parseInt(e.target.value) : 0 } })}
                                />
                            </TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell className={classes.tCell}>
                                <Typography className="!text-xs md:!text-sm lg:!text-base">Minimum Masa Oturma Puanı</Typography>
                            </TableCell>
                            <TableCell className={classes.tCell}>
                                <TextField margin="dense" fullWidth
                                    value={config?.minJoinTablePoints ?? 0} sx={{
                                        borderTopRightRadius: '0 !important',
                                        borderBottomRightRadius: '0 !important',
                                        margin: 0,
                                    }}
                                    InputProps={{
                                        classes: {
                                            input: "h-8 sm:h-12 !text-xs md:!text-sm lg:!text-base",
                                            root: "h-8 sm:h-12",
                                        }
                                    }}
                                    onChange={(e) => this.setState({ config: { ...this.state.config, minJoinTablePoints: e.target.value ? parseInt(e.target.value) : 0 } })}
                                />
                            </TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell className={classes.tCell}>
                                <Typography className="!text-xs md:!text-sm lg:!text-base">Minimum Masa İzleme Puanı</Typography>
                            </TableCell>
                            <TableCell className={classes.tCell}>
                                <TextField margin="dense" fullWidth
                                    value={config?.minSpectateTablePoints ?? 0} sx={{
                                        borderTopRightRadius: '0 !important',
                                        borderBottomRightRadius: '0 !important',
                                        margin: 0,
                                    }}
                                    InputProps={{
                                        classes: {
                                            input: "h-8 sm:h-12 !text-xs md:!text-sm lg:!text-base",
                                            root: "h-8 sm:h-12",
                                        }
                                    }}
                                    onChange={(e) => this.setState({ config: { ...this.state.config, minSpectateTablePoints: e.target.value ? parseInt(e.target.value) : 0 } })}
                                />
                            </TableCell>
                        </TableRow>

                    </TableBody>
                </Table>

                <Box display={"flex"} justifyContent={"flex-end"} my={2}>
                    <Button variant={"contained"} color={"primary"} className="!text-white !text-xs md:!text-sm lg:!text-base" onClick={this.editRoomConfig}>
                        Kaydet
                    </Button>
                </Box>
            </Box>
        );
    }

    content = () => {
        switch (this.state.tab) {
            case tabs.CONFIG:
                return this.configTab();
        }
    }

    render() {
        const { classes } = this.props;

        return (
            <Box className="w-full overflow-auto">
                <Tabs
                    sx={{
                        width: 'fit-content',
                        '& .MuiTabs-flexContainer': { columnGap: 0 },
                        '& .MuiTabs-indicator': { backgroundColor: "#FF9559", height: '4px' },
                        '& .MuiTab-root': { color: '#505050 !important', flex: 1, borderBottom: '1px solid #E3E3E3', padding: 0 },
                        '& .Mui-selected': { color: '#505050 !important', backgroundColor: '#FFF3EC' },
                    }}
                    value={this.state.tab}
                    textColor="primary"
                    className={classes.tabs}
                    onChange={this.onTabChange}>

                    <Tab value={tabs.CONFIG}
                        label={
                            <Box display={"flex"} columnGap={2} flex={1} p={2} justifyContent={"space-between"}>
                                <Typography className={"!text-xs md:!text-sm lg:!text-base"}>Oda Config</Typography>
                            </Box>
                        }
                        sx={{ textTransform: 'none', padding: 3, flexDirection: 'row', borderRight: '1px solid #E3E3E3' }} />
                </Tabs>

                {this.content()}
            </Box>
        );
    }
}

RoomSettings.propTypes = {
    classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
    return {
        adminTab: state.app.global.adminTab,
    }
};

export default withStyles(styles)(connect(mapStateToProps, {
    setNotification, setAdminTab,
})(RoomSettings));
