import { connectRouter } from 'connected-react-router';
import { combineReducers } from 'redux';
import app from './app';
//import requests from './requests';

const createRootReducer = (history) => combineReducers({
    router: connectRouter(history),
    app: app(history),
    //requests: requests(history)
});

export default createRootReducer;
