import React from "react";
import { connect } from "react-redux";
import { withStyles } from "@mui/styles";
import PropTypes from "prop-types";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Table, TableBody, TableCell, TableRow, TextField, Tooltip, Typography } from "@mui/material";
import { Close } from "@mui/icons-material";
import clsx from "clsx";
import { setNotification } from "../../actions/global";
import { GlobalTypes } from "../../action_types";
import axios, { AxiosError } from "axios";
import { ERROR_CODES } from "../../global/error";
import { AxiosClient } from "../../global/axios";

const styles = theme => ({
});

class NickChangeRequest extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            newUsername: '',
            loading: false,
        }

    }

    componentDidUpdate(prevProps) {
    }

    onClose = () => {
        this.setState({ newUsername: '' });
        this.props.onClose();
    }

    doSave = async () => {
        let { newUsername } = this.state;
        newUsername = newUsername.trim();

        if (!newUsername) {
            this.props.setNotification({ message: 'Lütfen yeni kullanıcı adınızı giriniz.', severity: GlobalTypes.NOTIFY_ERR });
            return;
        }

        this.setState({ loading: true });

        try {
            const req = {
                new_username: newUsername,
            };

            await AxiosClient().post(`${process.env.REACT_APP_API_URL}/v1/user/username.change`, req);

            const message = 'Kullanıcı adı değişim talebiniz alınmıştır.';
            this.props.setNotification({ message, severity: GlobalTypes.NOTIFY_SUCC });
            this.onClose();
        }
        catch (e) {
            console.error(e);
            let message = ERROR_CODES[2];

            if (e instanceof AxiosError) {
                const code = e.response?.data?.error?.code;
                if (code in ERROR_CODES) {
                    message = ERROR_CODES[code];
                } else {
                    message = e.message;
                }
            }

            this.props.setNotification({ message: message, severity: GlobalTypes.NOTIFY_ERR, traceId: e.response?.data?.trace_id });
        }
        finally {
            this.setState({ loading: false });
        }
    }

    render() {
        const { classes } = this.props;
        const disabled = !this.state.newUsername || this.state.loading;

        return (
            <Dialog
                maxWidth={"xs"}
                fullWidth
                open={this.props.open}
            >
                <DialogTitle>
                    <Box padding={0} display={"flex"} justifyContent={"space-between"}>
                        <Typography className={"!text-xs sm:!text-sm md:!text-base"}>
                            Nick Değişim Talebi
                        </Typography>

                        <Tooltip title="Kapat" placement="top">
                            <IconButton size="small" onClick={this.onClose} sx={{ backgroundColor: '#624799 !important' }} tabIndex={-1}>
                                <Close htmlColor="var(--white)" fontSize="small" />
                            </IconButton>
                        </Tooltip>
                    </Box>
                </DialogTitle>

                <DialogContent sx={{ p: 0 }}>
                    <form>
                        <Table>
                            <TableBody>
                                <TableRow>
                                    <TableCell className="w-36">
                                        <Typography className={clsx("!text-xs sm:!text-sm md:!text-base")} sx={{ color: '#828282' }}>
                                            Yeni Nick
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <TextField margin="dense" fullWidth tabIndex={0} autoFocus
                                            type="text"
                                            value={this.state.newUsername}
                                            sx={{
                                                borderTopRightRadius: '0 !important',
                                                borderBottomRightRadius: '0 !important',
                                                margin: 0,
                                            }}
                                            onChange={(e) => this.setState({ newUsername: e.target.value })}
                                        />
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </form>
                </DialogContent>

                <DialogActions sx={{ p: 2 }}>
                    <Button variant="contained" disabled={false} onClick={() => this.onClose()}
                        disableFocusRipple disableRipple disableTouchRipple color="error"
                        sx={{
                            color: '#FFFFFF',
                        }}
                        className="!text-xs sm:!text-sm md:!text-base"
                    >
                        İptal
                    </Button>

                    <Button variant="contained" disabled={!!disabled} onClick={() => this.doSave()}
                        disableFocusRipple disableRipple disableTouchRipple
                        sx={{
                            color: '#FFFFFF !important', backgroundColor: disabled ? '#939393 !important' : null,
                            backgroundImage: disabled ? null : "linear-gradient(to bottom, #2FB16B 50%, #168549)"
                        }}
                        className="!text-xs sm:!text-sm md:!text-base"
                    >
                        Kaydet
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

NickChangeRequest.propTypes = {
    classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
    return {
    }
};

export default withStyles(styles)(connect(mapStateToProps, {
    setNotification,
})(NickChangeRequest));
