import { LobbyTypes } from '../action_types';

export function setLobbyUsers(users) {
    return {
        type: LobbyTypes.SET_LOBBY_USERS,
        users
    };
}

export function setLobbyTables(tables) {
    return {
        type: LobbyTypes.SET_LOBBY_TABLES,
        tables
    };
}

export function addChatMessage(message) {
    return {
        type: LobbyTypes.ADD_CHAT_MESSAGE,
        message
    };
}

export function addChatHistory(id, messages) {
    return {
        type: LobbyTypes.ADD_CHAT_HISTORY,
        id,
        messages,
    };
}

export function clearChat(room) {
    return {
        type: LobbyTypes.CLEAR_CHAT,
        room,
    };
}

export function setRejoinTableId(id, index, password) {
    return {
        type: LobbyTypes.SET_REJOIN_TABLE,
        id,
        index,
        password
    };
}

export function setUserStatus(id, status) {
    return {
        type: LobbyTypes.SET_LOBBY_USER_STATUS,
        id,
        status
    };
}

export function addUnreadChatMessage(room) {
    return {
        type: LobbyTypes.ADD_UNREAD_CHAT_MESSAGE,
        room
    };
}

export function clearUnreadChatMessages(room) {
    return {
        type: LobbyTypes.CLEAR_UNREAD_CHAT_MESSAGES,
        room
    };
}

export function updateUserInfo(user) {
    return {
        type: LobbyTypes.UPDATE_USER_INFO,
        user
    };
}

export function updateUserInfoKV(id, key, value) {
    return {
        type: LobbyTypes.UPDATE_USER_INFO_KV,
        id,
        key,
        value
    };
}

export function updateTableInfo(table) {
    return {
        type: LobbyTypes.UPDATE_TABLE_INFO,
        table
    };
}

export function updateTableInfoKV(id, key, value) {
    return {
        type: LobbyTypes.UPDATE_TABLE_INFO_KV,
        id,
        key,
        value
    };
}

export function removeTable(id) {
    return {
        type: LobbyTypes.REMOVE_TABLE,
        id
    };
}

export function userConnected(user) {
    return {
        type: LobbyTypes.USER_CONNECTED,
        user
    };
}

export function userDisconnected(id) {
    return {
        type: LobbyTypes.USER_DISCONNECTED,
        id
    };
}