import { store } from '../..';
import { GlobalTypes, LobbyTypes } from '../../action_types';
import { clearUnreadChatMessages } from '../../actions/lobby';

const initialState = {
    locale: {
        languageId: 'turkish',
        locale: 'tr',
        name: 'Turkish',
        icon: 'tr'
    },
    socket: null,
    user: null,
    allowReconnect: true,
    disconnected: false,
    leavingTable: null,
    error: null,
    notification: null,
    ping: 0,
    selectedTab: 'salon',
    selectedTable: null,
    infoDialog: null,
    invitationDialog: null,
    chatTabs: [],
    myTable: null,
    spectatingTables: [],
    notifications: [],

    friends: [],
    friendsById: {},

    blocks: [],
    blocksById: {},

    roomConfig: {},
    maintenance: false,
    adminPath: '/admin',
    adminTab: 0,

    role: 0,
    permission: 0,

    generalSettings: {
        in_game_double_click_closed: false,
        msg_time: false,
        play_radio_at_startup: false,
        max_message_count: null,
    },

    reportDialog: {
        active: false,
        reportedUserId: null,
        reportReason: null,
        reportReasonText: '',
        reportDescription: '',
        room: null,
        isPm: false,
        startDate: null,
        endDate: null,
    },

    loadingState: null,
    streamInfo: {},
}

const settings = (state = initialState, action) => {
    switch (action.type) {
        case GlobalTypes.SET_SOCKET: {
            return {
                ...state,
                socket: action.socket,
            };
        }

        case GlobalTypes.SET_SOCKET_USER: {
            return {
                ...state,
                user: action.user,
            };
        }

        case GlobalTypes.SET_DISCONNECTED: {
            return {
                ...state,
                allowReconnect: action.allowReconnect,
                disconnected: action.disconnected,
            };
        }

        case GlobalTypes.SET_ERROR: {
            return {
                ...state,
                error: action.error,
            };
        }

        case GlobalTypes.SET_NOTIFICATION: {
            return {
                ...state,
                notification: action.notification ? {
                    ...action.notification,
                    createdAt: Date.now(),
                } : null,
            };
        }

        case GlobalTypes.SET_PING: {
            return {
                ...state,
                ping: action.ping,
            };
        }

        case GlobalTypes.SELECT_TAB: {
            setTimeout(() => {
                const room = action.id;
                store.dispatch(clearUnreadChatMessages(room));
            }, 100);

            return {
                ...state,
                selectedTab: action.id,
                selectedTable: action.id?.startsWith('table') ? parseInt(action.id.split('_').pop()) : null,
            };
        }

        case GlobalTypes.SET_MY_TABLE: {
            return {
                ...state,
                myTable: action.myTable,
            };
        }

        case GlobalTypes.ADD_SPECTATING_TABLE: {
            return {
                ...state,
                spectatingTables: [
                    ...state.spectatingTables,
                    action.tableId,
                ],
            };
        }

        case GlobalTypes.REMOVE_SPECTATING_TABLE: {
            return {
                ...state,
                spectatingTables: state.spectatingTables.filter(x => x !== action.tableId),
            };
        }

        case GlobalTypes.SET_INFO_DIALOG: {
            return {
                ...state,
                infoDialog: {
                    title: action.title,
                    message: action.message,
                },
            };
        }

        case GlobalTypes.CLOSE_INFO_DIALOG: {
            return {
                ...state,
                infoDialog: null,
            };
        }

        case GlobalTypes.SET_LEAVING_TABLE: {
            return {
                ...state,
                leavingTable: action.leavingTable,
            };
        }

        case GlobalTypes.SET_USER_STATUS: {
            return {
                ...state,
                user: {
                    ...state.user,
                    status: action.status,
                },
            };
        }

        case GlobalTypes.SET_USER_AUTOPILOT: {
            return {
                ...state,
                user: {
                    ...state.user,
                    autoPilot: action.autoPilot,
                },
            };
        }

        case LobbyTypes.UPDATE_USER_INFO_KV: {
            if (action.id === state.user?.id) {
                return {
                    ...state,
                    user: {
                        ...state.user,
                        [action.key]: action.value,
                    },
                };
            }

            if (action.id in state.friendsById) {
                return {
                    ...state,
                    friendsById: {
                        ...state.friendsById,
                        [action.id]: {
                            ...state.friendsById[action.id],
                            user: {
                                ...state.friendsById[action.id].user,
                                [action.key]: action.value,
                            },
                        },
                    },
                };
            }

            return state;
        }

        case LobbyTypes.UPDATE_USER_INFO: {
            if (!action.user)
                return state;


            if (action.user.id === state.user?.id) {
                return {
                    ...state,
                    user: action.user,
                };
            }

            if (action.user.id in state.friendsById) {
                return {
                    ...state,
                    friendsById: {
                        ...state.friendsById,
                        [action.user.id]: {
                            ...state.friendsById[action.user.id],
                            user: action.user,
                        },
                    },
                };
            }

            return state;
        }

        case GlobalTypes.ADD_CHAT_TAB: {
            if (state.chatTabs.find(x => x.id === action.id))
                return {
                    ...state,
                    selectedTab: action.switchTo ? action.id : state.selectedTab,
                    selectedTable: action.switchTo ? null : state.selectedTable,
                };

            if (state.chatTabs.length >= 5)
                return state;

            return {
                ...state,
                selectedTab: action.switchTo ? action.id : state.selectedTab,
                selectedTable: action.switchTo ? null : state.selectedTable,
                chatTabs: [...state.chatTabs, {
                    id: action.id,
                    name: action.name,
                }],
            };
        }

        case GlobalTypes.REMOVE_CHAT_TAB: {
            return {
                ...state,
                chatTabs: state.chatTabs.filter(x => x.id !== action.id),
            };
        }

        case GlobalTypes.ADD_NOTIFICATIONS: {
            return {
                ...state,
                notifications: [
                    ...action.notifications,
                    ...state.notifications,
                ],
            };
        }

        case GlobalTypes.UPDATE_NOTIFICATION: {
            return {
                ...state,
                notifications: state.notifications.map(x => x.id === action.notification.id ? action.notification : x),
            };
        }

        case GlobalTypes.READ_NOTIFICATIONS: {
            return {
                ...state,
                notifications: state.notifications.map(n => {
                    n.read_at = Date.now();
                    return n;
                })
            };
        }

        case GlobalTypes.SET_NOTIFICATIONS: {
            return {
                ...state,
                notifications: action.notifications,
            };
        }

        case GlobalTypes.ADD_FRIEND: {
            return {
                ...state,
                friends: [
                    ...state.friends,
                    action.friend.friend_id
                ],
                friendsById: {
                    ...state.friendsById,
                    [action.friend.friend_id]: action.friend
                },
            };
        }

        case GlobalTypes.UPDATE_FRIEND: {
            return {
                ...state,
                //friends: state.friends.map(x => x.id === action.friend.id ? action.friend : x),
                friendsById: {
                    ...state.friendsById,
                    [action.friend.friend_id]: action.friend
                },
            };
        }

        case GlobalTypes.SET_FRIENDS: {
            return {
                ...state,
                friends: action.friends.map(x => x.friend_id),
                friendsById: action.friends.reduce((acc, x) => {
                    acc[x.friend_id] = x;
                    return acc;
                }, {}),
            };
        }

        case GlobalTypes.REMOVE_FRIEND: {
            return {
                ...state,
                friends: state.friends.filter(x => x !== action.id),
                friendsById: Object.keys(state.friendsById).reduce((acc, x) => {
                    if (x !== action.id)
                        acc[x] = state.friendsById[x];
                    return acc;
                }, {}),
            };
        }

        case GlobalTypes.ADD_BLOCKED_USER: {
            return {
                ...state,
                blocks: [
                    ...state.blocks,
                    action.user.blocked_id
                ],
                blocksById: {
                    ...state.blocksById,
                    [action.user.blocked_id]: action.user
                },
            };
        }

        case GlobalTypes.REMOVE_BLOCKED_USER: {
            return {
                ...state,
                blocks: state.blocks.filter(x => x !== action.id),
                blocksById: Object.keys(state.blocksById).reduce((acc, x) => {
                    if (x !== action.id)
                        acc[x] = state.blocksById[x];
                    return acc;
                }, {}),
            };
        }

        case GlobalTypes.SET_BLOCKED_USERS: {
            return {
                ...state,
                blocks: action.users.map(x => x.blocked_id),
                blocksById: action.users.reduce((acc, x) => {
                    acc[x.blocked_id] = x;
                    return acc;
                }, {}),
            };
        }

        case GlobalTypes.SET_ROOM_CONFIG: {
            return {
                ...state,
                roomConfig: action.config,
            };
        }

        case GlobalTypes.SET_ROOM_MAINTENANCE: {
            return {
                ...state,
                maintenance: action.maintenance,
            };
        }

        case GlobalTypes.SET_ADMIN_PATH: {
            return {
                ...state,
                adminPath: action.path,
                adminTab: 0,
            };
        }

        case GlobalTypes.SET_ADMIN_TAB: {
            return {
                ...state,
                adminTab: action.tab,
            };
        }

        case GlobalTypes.SET_INVITATION_DIALOG: {
            return {
                ...state,
                invitationDialog: action.invitationDialog,
            };
        }

        case GlobalTypes.SET_ROLE_PERMISSON: {
            return {
                ...state,
                role: action.role,
                permission: action.permission,
            };
        }

        case GlobalTypes.SET_GENERAL_SETTINGS: {
            return {
                ...state,
                generalSettings: action.generalSettings,
            };
        }

        case GlobalTypes.SET_REPORT_DIALOG: {
            return {
                ...state,
                reportDialog: action.reportDialog,
            };
        }

        case GlobalTypes.SET_LOADING_STATE: {
            return {
                ...state,
                loadingState: action.loadingState,
            };
        }

        case GlobalTypes.SET_STREAM_INFO: {
            return {
                ...state,
                streamInfo: action.streamInfo,
            };
        }

        default:
            return state;
    }
};

export default settings;
