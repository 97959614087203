import React from "react";
import { connect } from "react-redux";
import { withStyles } from "@mui/styles";
import PropTypes from "prop-types";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, InputAdornment, MenuItem, SvgIcon, Table, TableBody, TableCell, TableRow, TextField, Tooltip, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { Close } from "@mui/icons-material";
import clsx from "clsx";
import { mdiEarth, mdiEmail, mdiGenderMaleFemale } from "@mdi/js";
import { countries } from "../../global/countries";
import axios from "axios";
import { setNotification } from "../../actions/global";
import { GlobalTypes } from "../../action_types";
import { AxiosClient } from "../../global/axios";


const styles = theme => ({
    inputIcon: {
        marginRight: '8px',
        color: '#31204A !important'
    },
});

class UpdateProfile extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            profile: null,
            email: '',
            country: '',
            gender: '_',
            loading: false,
        }

    }

    componentDidMount() {
    }

    componentDidUpdate(prevProps) {
        if (!prevProps.open && this.props.open) {
            this.setState({ loading: true });
            this.getUserProfile();
        }

    }

    getUserProfile = async () => {
        try {
            const resp = await AxiosClient().get(`${process.env.REACT_APP_API_URL}/v1/user/profile`);

            const { email, country, gender } = resp.data.user;
            this.setState({ email, country, gender, loading: false, profile: resp.data.user });
        }
        catch (err) {
            console.error(err);
            this.props.setNotification({ message: 'Bir hata oluştu. Lütfen tekrar deneyiniz.', severity: GlobalTypes.NOTIFY_ERR });
        }
    }

    onClose = () => {
        this.setState({ email: '', country: '', gender: '_', loading: false, profile: null });
        this.props.onClose();
    }

    doSave = () => {
        this.setState({ loading: true });

        AxiosClient().post(`${process.env.REACT_APP_API_URL}/v1/user/profile`, {
            email: this.state.email,
            country: this.state.country,
            gender: this.state.gender,
        }).then((resp) => {
            this.props.setNotification({ message: 'Profil bilgileriniz başarıyla güncellendi.', severity: GlobalTypes.NOTIFY_SUCC });
            this.onClose();
        }).catch((err) => {
            console.error(err);
            this.props.setNotification({ message: 'Bir hata oluştu. Lütfen tekrar deneyiniz.', severity: GlobalTypes.NOTIFY_ERR });
        }).finally(() => {
            this.setState({ loading: false });
        });
    }

    render() {
        const { classes } = this.props;
        const { profile, email, country, gender, loading } = this.state;

        let disabled = loading;
        disabled |= profile?.email === email && profile?.country === country && profile?.gender === gender;

        return (
            <Dialog
                maxWidth={"xs"}
                fullWidth
                open={this.props.open}
            >
                <DialogTitle>
                    <Box padding={0} display={"flex"} justifyContent={"space-between"}>
                        <Typography className={"!text-xs sm:!text-sm md:!text-base"}>
                            Profil Bilgilerim
                        </Typography>

                        <Tooltip title="Kapat" placement="top">
                            <IconButton size="small" onClick={this.onClose} sx={{ backgroundColor: '#624799 !important' }} tabIndex={-1}>
                                <Close htmlColor="var(--white)" fontSize="small" />
                            </IconButton>
                        </Tooltip>
                    </Box>
                </DialogTitle>

                <DialogContent sx={{ p: 0 }}>
                    <form>
                        <Table>
                            <TableBody>
                                <TableRow>
                                    <TableCell className="w-36">
                                        <Typography className={clsx("!text-xs md:!text-sm lg:!text-base")} sx={{ color: '#828282' }}>
                                            E-Posta
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <TextField margin="dense" fullWidth tabIndex={0} autoFocus
                                            value={this.state.email} disabled={this.state.loading}
                                            sx={{
                                                borderTopRightRadius: '0 !important',
                                                borderBottomRightRadius: '0 !important',
                                                margin: 0,
                                            }}
                                            InputProps={{
                                                classes: {
                                                    input: "h-8 sm:h-12 !text-xs sm:!text-sm md:!text-base",
                                                    root: "h-8 sm:h-12",
                                                },
                                                startAdornment: (
                                                    <InputAdornment className={classes.inputIcon} position="start">
                                                        <SvgIcon><path d={mdiEmail} /></SvgIcon>
                                                    </InputAdornment>
                                                )
                                            }}
                                            onChange={(e) => this.setState({ email: e.target.value })}
                                        />
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell className="w-36">
                                        <Typography className={clsx("!text-xs md:!text-sm lg:!text-base")} sx={{ color: '#828282' }}>
                                            Ülke
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <TextField
                                            autoComplete='new-password'
                                            fullWidth
                                            select
                                            margin="dense"
                                            variant="outlined"
                                            //placeholder="Ülke"
                                            InputProps={{
                                                classes: {
                                                    input: "h-8 sm:h-12 !text-xs sm:!text-sm md:!text-base",
                                                    root: "h-8 sm:h-12",
                                                },
                                                startAdornment: (
                                                    <InputAdornment className={classes.inputIcon} position="start">
                                                        <SvgIcon><path d={mdiEarth} /></SvgIcon>
                                                    </InputAdornment>
                                                )
                                            }}
                                            value={this.state.country}
                                            disabled={this.state.loading}
                                            onChange={(e) => this.setState({ country: e.target.value })}
                                        >
                                            {Object.keys(countries).map(code => {
                                                return (
                                                    <MenuItem key={code} value={code}>
                                                        <Typography className="!text-xs sm:!text-sm md:!text-base">
                                                            {countries[code]}
                                                        </Typography>
                                                    </MenuItem>
                                                );
                                            })}
                                        </TextField>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell className="w-36">
                                        <Typography className={clsx("!text-xs md:!text-sm lg:!text-base")} sx={{ color: '#828282' }}>
                                            Cinsiyet
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <TextField
                                            autoComplete='new-password'
                                            fullWidth
                                            select
                                            margin="dense"
                                            variant="outlined"
                                            //placeholder="Cinsiyet"
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment className={classes.inputIcon} position="start">
                                                        <SvgIcon><path d={mdiGenderMaleFemale} /></SvgIcon>
                                                    </InputAdornment>
                                                ),
                                                classes: {
                                                    input: "h-8 sm:h-12 !text-xs sm:!text-sm md:!text-base",
                                                    root: "h-8 sm:h-12",
                                                },
                                            }}
                                            value={this.state.gender}
                                            disabled={this.state.loading}
                                            onChange={(e) => this.setState({ gender: e.target.value })}
                                        >
                                            <MenuItem value={"_"} key={"_"}>
                                                <Typography className="!text-xs sm:!text-sm md:!text-base">Cinsiyet</Typography>
                                            </MenuItem>
                                            <MenuItem value={"male"} key={"male"}>
                                                <Typography className="!text-xs sm:!text-sm md:!text-base">Bay</Typography>
                                            </MenuItem>
                                            <MenuItem value={"female"} key={"female"}>
                                                <Typography className="!text-xs sm:!text-sm md:!text-base">Bayan</Typography>
                                            </MenuItem>
                                        </TextField>
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </form>
                </DialogContent>

                <DialogActions sx={{ p: 2 }}>
                    <Button variant="contained" disabled={false} onClick={() => this.onClose()}
                        disableFocusRipple disableRipple disableTouchRipple color="error"
                        sx={{
                            color: '#FFFFFF',
                        }}
                        className="!text-xs sm:!text-sm md:!text-base"
                    >
                        İptal
                    </Button>

                    <Button variant="contained" disabled={!!disabled} onClick={() => this.doSave()}
                        disableFocusRipple disableRipple disableTouchRipple
                        sx={{
                            color: '#FFFFFF !important', backgroundColor: disabled ? '#939393 !important' : null,
                            backgroundImage: disabled ? null : "linear-gradient(to bottom, #2FB16B 50%, #168549)"
                        }}
                        className="!text-xs sm:!text-sm md:!text-base"
                    >
                        Kaydet
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

UpdateProfile.propTypes = {
    classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
    return {
    }
};

export default withStyles(styles)(connect(mapStateToProps, {
    setNotification,
})(UpdateProfile));
