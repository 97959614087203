import { LinearProgress, linearProgressClasses } from "@mui/material";
import { styled } from "@mui/styles";
import clsx from "clsx";
import React from "react";
import { connect } from "react-redux";

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 10,
    borderRadius: 12,
    [`&.${linearProgressClasses.colorPrimary}`]: {
        backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
        borderRadius: 12,
        backgroundColor: theme.palette.mode === 'light' ? '#1a90ff' : '#308fe8',
    },
}));

class Container extends React.Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
    }

    render() {
        const { loadingState } = this.props;

        if (loadingState === null) {
            return null;
        }

        return (
            <div className="absolute w-full bg-gray-100 flex flex-col items-center justify-center h-screen gap-y-2" style={{ zIndex: 9999 }}>
                <BorderLinearProgress
                    className={
                        clsx(
                            "w-10/12 md:w-8/12 lg:w-6/12 xl:w-4/12",
                            "!h-2 md:!h-3 lg:!h-4 xl:!h-6",
                        )
                    }
                    variant="determinate" value={loadingState}
                />

                <span className="text-gray-500 text-sm md:text-base lg:text-lg">Yükleniyor... {loadingState ?? 0}%</span>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        socket: state.app.global.socket,
        loadingState: state.app.global.loadingState,
    };
};

Container.propTypes = {
};

export default connect(mapStateToProps, {})(Container);