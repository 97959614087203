export const presentNames = {
    "armut.png": "Armut",
    "karpuz.png": "Karpuz",
    "sahlep.png": "Sahlep",
    "ananas.png": "Ananas",
    "araba.png": "Araba",
    "baklava.png": "Baklava",
    "cay.png": "Çay",
    "cerez.png": "Çerez",
    "cilek.png": "Çilek",
    "dondurma.png": "Dondurma",
    "elma.png": "Elma",
    "hurma.png": "Hurma",
    "jelibon.png": "Jelibon",
    "kahve.png": "Kahve",
    "kek.png": "Kek",
    "limonata.png": "Limonata",
    "lokum.png": "Lokum",
    "nazar.gif": "Nazar Boncuğu",
    "ozelkokteyl.png": "Özel Kokteyl",
    "pasta.png": "Pasta",
    "patatescipsi.png": "Patates Cipsi",
    "pizza.png": "Pizza",
    "portakalsuyu.png": "Portakal Suyu",
    "sandvic.png": "Sandviç",
    "serpmekahvalti.png": "Serpme Kahvaltı",
    "simit.png": "Simit",
    "sucukekmek.png": "Sucuk Ekmek",
    "sut.png": "Süt",
    "tektas.png": "Tek Taş",
    "yat.png": "Yat",
};

export const presentPts = {
    "armut.png": 1,
    "karpuz.png": 2,
    "sahlep.png": 2,
    "ananas.png": 2,
    "araba.png": 1000,
    "baklava.png": 5,
    "cay.png": 1,
    "cerez.png": 1,
    "cilek.png": 2,
    "dondurma.png": 2,
    "elma.png": 1,
    "hurma.png": 2,
    "jelibon.png": 1,
    "kahve.png": 2,
    "kek.png": 2,
    "limonata.png": 2,
    "lokum.png": 2,
    "nazar.gif": 5,
    "ozelkokteyl.png": 10,
    "pasta.png": 5,
    "patatescipsi.png": 2,
    "pizza.png": 2,
    "portakalsuyu.png": 2,
    "sandvic.png": 2,
    "serpmekahvalti.png": 10,
    "simit.png": 1,
    "sucukekmek.png": 2,
    "sut.png": 2,
    "tektas.png": 50,
    "yat.png": 5000,
}