import React from "react";
import { connect } from "react-redux";
import { withStyles } from "@mui/styles";
import PropTypes from "prop-types";
import { Avatar, Box, Dialog, DialogContent, DialogTitle, Divider, IconButton, SvgIcon, Tooltip, Typography } from "@mui/material";
import { mdiAccount, mdiRobot } from "@mdi/js";
import {
    Close as CloseIcon
} from '@mui/icons-material';
import clsx from "clsx";
import UserAvatar from "../Lobby/UserAvatar";

const styles = theme => ({
    title: {
        fontFamily: 'Roboto Slab !important',
        fontWeight: '500 !important',
    },
    text: {
        fontFamily: 'Roboto Slab !important',
        fontWeight: '400 !important',
    },
});

class Scoreboard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        }

    }

    componentDidMount() {
    }

    componentDidUpdate(prevProps) {
    }

    render() {
        const { classes, selectedTableUsers } = this.props;

        return (
            <Dialog open={this.props.open} maxWidth="lg">
                <DialogTitle>
                    <Box padding={0} display={"flex"} justifyContent={"space-between"}>
                        <Typography className={clsx(classes.title, "!text-sm sm:!text-base md:!text-lg")}>SKOR TABLOSU</Typography>
                        <Tooltip title="Kapat" placement="top">
                            <IconButton size="small" onClick={this.props.onClose} sx={{ backgroundColor: '#624799 !important' }}>
                                <CloseIcon htmlColor="var(--white)" fontSize="small" />
                            </IconButton>
                        </Tooltip>
                    </Box>
                </DialogTitle>
                <DialogContent>
                    <Box display={"flex"} flexDirection={"column"} rowGap={1} p={1}>
                        {selectedTableUsers?.map((user, index) => {
                            if (!user)
                                return null;

                            return (
                                <Box key={user?.id} display={"flex"} flexDirection={"column"} rowGap={1}>
                                    <Box display={"flex"} position={"relative"} columnGap={2} alignItems={"center"} p={[2, 1]}>
                                        <UserAvatar user={user} />
                                        <Typography className={clsx(classes.text, "!text-xs sm:!text-sm md:!text-base")}>
                                            {user?.username}
                                        </Typography>
                                        <span style={{ flex: 1 }} />
                                        <Typography className={clsx(classes.text, "!text-xs sm:!text-sm md:!text-base")}>
                                            {user?.pts}
                                        </Typography>
                                    </Box>
                                    <Divider />
                                </Box>
                            );
                        })}
                    </Box>
                </DialogContent>
            </Dialog >
        );
    }
}

Scoreboard.propTypes = {
    classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
    const selectedTable = state.app.global.selectedTable;

    return {
        selectedTableUsers: state.app.lobby.tables[selectedTable]?.users,
        usersById: state.app.lobby.usersById,
    }
};

export default withStyles(styles)(connect(mapStateToProps, {
})(Scoreboard));
