import React from "react";
import { connect } from "react-redux";
import { withStyles } from "@mui/styles";
import PropTypes from "prop-types";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, SvgIcon, Table, TableCell, TableRow, TextField, Tooltip, Typography } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { Add, AllInclusive, Remove } from "@mui/icons-material";
import dayjs from "dayjs";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import clsx from "clsx";
import axios from "axios";
import { GlobalTypes } from "../../../action_types";
import { setNotification } from "../../../actions/global";
import { AxiosClient } from "../../../global/axios";


const styles = theme => ({
});

class EditMailForbid extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            pattern: props.mailForbid?.pattern || '',
            reason: props.mailForbid?.reason || '',
            saving: false,
        }

    }

    componentDidMount() {
    }

    componentDidUpdate(prevProps) {
        if (this.props.mailForbid && !prevProps.mailForbid) {
            this.setState({
                pattern: this.props.mailForbid.pattern,
                reason: this.props.mailForbid.reason,
            });
        }

        if (!this.props.mailForbid && prevProps.mailForbid) {
            this.setState({
                pattern: '',
                reason: '',
            });
        }
    }

    onSave = async () => {
        this.setState({ saving: true });

        const mailForbid = {
            pattern: this.state.pattern,
            reason: this.state.reason,
        };

        if (this.props.mailForbid) {
            mailForbid.id = this.props.mailForbid.id;
        }

        try {
            await AxiosClient().post(`${process.env.REACT_APP_API_URL}/v1/admin/server/mail.forbids`, mailForbid);

            this.props.setNotification({ message: `Mail yasaklama ${this.props.mailForbid ? 'düzenlendi.' : 'eklendi.'}`, severity: GlobalTypes.NOTIFY_SUCC });
            this.props.onClose(true);

        } catch (error) {
            console.error(error);
            this.props.setNotification({ message: 'Bir hata oluştu. Lütfen tekrar deneyin.', severity: GlobalTypes.NOTIFY_ERR });
        } finally {
            this.setState({ saving: false, pattern: '', date: dayjs(new Date()), reason: '' });
        }
    }

    render() {
        const { classes } = this.props;

        return (
            <Dialog open={this.props.open} onClose={() => this.props.onClose()}>
                <DialogTitle>
                    <Typography className={classes.title} sx={{ color: '#413067' }}>
                        {`Mail Yasakla ${this.props.mailForbid ? 'Düzenle' : 'Ekle'}`}
                    </Typography>
                </DialogTitle>
                <DialogContent>
                    <Table>
                        <TableRow>
                            <TableCell className="!border-0 !p-2 min-w-32">
                                <Typography className={classes.title} sx={{ color: '#413067' }}>
                                    Kalıp
                                </Typography>
                            </TableCell>
                            <TableCell className="!border-0 !p-2">
                                <TextField margin="dense" fullWidth
                                    value={this.state.pattern}
                                    sx={{
                                        borderTopRightRadius: '0 !important',
                                        borderBottomRightRadius: '0 !important',
                                        margin: 0,
                                    }}
                                    InputProps={{
                                        classes: {
                                            input: "h-8 sm:h-12",
                                            root: "h-8 sm:h-12",
                                        }
                                    }}
                                    disabled={Boolean(this.props.mailForbid)}
                                    onChange={(e) => this.setState({ pattern: e.target.value })}
                                />
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className="!border-0 !p-2">
                                <Typography sx={{ color: '#413067' }}>
                                    Sebep
                                </Typography>
                            </TableCell>
                            <TableCell className="!border-0 !p-2">
                                <TextField margin="dense" fullWidth
                                    value={this.state.reason}
                                    sx={{
                                        borderTopRightRadius: '0 !important',
                                        borderBottomRightRadius: '0 !important',
                                        margin: 0,
                                    }}
                                    InputProps={{
                                        classes: {
                                            input: "h-8 sm:h-12",
                                            root: "h-8 sm:h-12",
                                        }
                                    }}
                                    onChange={(e) => this.setState({ reason: e.target.value })}
                                />
                            </TableCell>
                        </TableRow>
                    </Table>
                </DialogContent>

                <DialogActions>
                    <Button onClick={() => this.props.onClose()} variant="contained" color="error">
                        İptal
                    </Button>
                    <Button onClick={this.onSave} variant="contained" color="primary"
                        className="!text-white"
                        disabled={!this.state.pattern || this.state.saving}
                    >
                        Kaydet
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

EditMailForbid.propTypes = {
    classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
    return {
    }
};

export default withStyles(styles)(connect(mapStateToProps, {
    setNotification,
})(EditMailForbid));
