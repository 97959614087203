import React from "react";
import { connect } from "react-redux";
import { withStyles } from "@mui/styles";
import PropTypes from "prop-types";
import { Box, Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, IconButton, SvgIcon, Table, TableCell, TableRow, TextField, Tooltip, Typography } from "@mui/material";
import dayjs from "dayjs";
import { GlobalTypes } from "../../../action_types";
import { setNotification } from "../../../actions/global";
import { AxiosClient } from "../../../global/axios";


const styles = theme => ({
});

class EditSpam extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            pattern: props.spam?.pattern || '',
            reason: props.spam?.reason || '',
            lobby: props.spam?.lobby || false,
            pm: props.spam?.pm || false,
            table: props.spam?.table || false,
            kill: props.spam?.kill || false,
            warning: props.spam?.warning || false,
            disable: props.spam?.disable || false,
            ban_user: props.spam?.ban_user || false,
            ban_ip: props.spam?.ban_ip || false,
            saving: false,
        }

    }

    componentDidMount() {
    }

    componentDidUpdate(prevProps) {
        if (this.props.spam && !prevProps.spam) {
            this.setState({
                pattern: this.props.spam.pattern,
                reason: this.props.spam.reason,
                lobby: this.props.spam.lobby,
                pm: this.props.spam.pm,
                table: this.props.spam.table,
                kill: this.props.spam.kill,
                warning: this.props.spam.warning,
                disable: this.props.spam.disable,
                ban_user: this.props.spam.ban_user,
                ban_ip: this.props.spam.ban_ip,
            });
        }

        if (!this.props.spam && prevProps.spam) {
            this.setState({
                pattern: '',
                reason: '',
                lobby: false,
                pm: false,
                table: false,
                kill: false,
                warning: false,
                disable: false,
                ban_user: false,
                ban_ip: false,
            });
        }
    }

    onSave = async () => {
        this.setState({ saving: true });

        const spam = {
            pattern: this.state.pattern,
            reason: this.state.reason,
            lobby: this.state.lobby,
            pm: this.state.pm,
            table: this.state.table,
            kill: this.state.kill,
            warning: this.state.warning,
            disable: this.state.disable,
            ban_user: this.state.ban_user,
            ban_ip: this.state.ban_ip,
        };

        if (this.props.spam) {
            spam.id = this.props.spam.id;
        }

        try {
            await AxiosClient().post(`${process.env.REACT_APP_API_URL}/v1/admin/chat/message.forbids`, spam);

            this.props.setNotification({ message: `Spam ${this.props.spam ? 'düzenlendi.' : 'eklendi.'}`, severity: GlobalTypes.NOTIFY_SUCC });
            this.props.onClose(true);

        } catch (error) {
            console.error(error);
            this.props.setNotification({ message: 'Bir hata oluştu. Lütfen tekrar deneyin.', severity: GlobalTypes.NOTIFY_ERR });
        } finally {
            this.setState({ saving: false, pattern: '', date: dayjs(new Date()), reason: '' });
        }
    }

    render() {
        const { classes } = this.props;

        return (
            <Dialog open={this.props.open} onClose={() => this.props.onClose()}>
                <DialogTitle>
                    <Typography className={classes.title} sx={{ color: '#413067' }}>
                        {`Spam Yasakla ${this.props.spam ? 'Düzenle' : 'Ekle'}`}
                    </Typography>
                </DialogTitle>
                <DialogContent>
                    <Table>
                        <TableRow>
                            <TableCell className="!border-0 !p-2 min-w-32">
                                <Typography className={classes.title} sx={{ color: '#413067' }}>
                                    Kalıp
                                </Typography>
                            </TableCell>
                            <TableCell className="!border-0 !p-2">
                                <TextField margin="dense" fullWidth
                                    value={this.state.pattern}
                                    sx={{
                                        borderTopRightRadius: '0 !important',
                                        borderBottomRightRadius: '0 !important',
                                        margin: 0,
                                    }}
                                    InputProps={{
                                        classes: {
                                            input: "h-8 sm:h-12",
                                            root: "h-8 sm:h-12",
                                        }
                                    }}
                                    disabled={Boolean(this.props.spam)}
                                    onChange={(e) => this.setState({ pattern: e.target.value })}
                                />
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className="!border-0 !p-2">
                                <Typography sx={{ color: '#413067' }}>
                                    Sebep
                                </Typography>
                            </TableCell>
                            <TableCell className="!border-0 !p-2">
                                <TextField margin="dense" fullWidth
                                    value={this.state.reason}
                                    sx={{
                                        borderTopRightRadius: '0 !important',
                                        borderBottomRightRadius: '0 !important',
                                        margin: 0,
                                    }}
                                    InputProps={{
                                        classes: {
                                            input: "h-8 sm:h-12",
                                            root: "h-8 sm:h-12",
                                        }
                                    }}
                                    onChange={(e) => this.setState({ reason: e.target.value })}
                                />
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className="!border-0 !p-2 !flex">
                                <Typography sx={{ color: '#413067' }}>
                                    Aktif
                                </Typography>
                            </TableCell>
                            <TableCell className="!border-0 !p-2">
                                <Box>
                                    <FormControlLabel control={
                                        <Checkbox onChange={(e, checked) => this.setState({ lobby: checked })}
                                            checked={this.state.lobby}
                                        />
                                    } label={"Lobi"} />
                                </Box>
                                <Box>
                                    <FormControlLabel control={
                                        <Checkbox onChange={(e, checked) => this.setState({ pm: checked })}
                                            checked={this.state.pm}
                                        />
                                    } label={"Özel Mesaj"} />
                                </Box>
                                <Box>
                                    <FormControlLabel control={
                                        <Checkbox onChange={(e, checked) => this.setState({ table: checked })}
                                            checked={this.state.table}
                                        />
                                    } label={"Masa"} />
                                </Box>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className="!border-0 !p-2 !flex">
                                <Typography sx={{ color: '#413067' }}>
                                    Aksiyon
                                </Typography>
                            </TableCell>
                            <TableCell className="!border-0 !p-2">
                                <Box>
                                    <FormControlLabel control={
                                        <Checkbox onChange={(e, checked) => this.setState({ kill: checked })}
                                            checked={this.state.kill}
                                        />
                                    } label={"Kill At"} />
                                </Box>
                                <Box>
                                    <FormControlLabel control={
                                        <Checkbox onChange={(e, checked) => this.setState({ warning: checked })}
                                            checked={this.state.warning}
                                        />
                                    } label={"Uyarı Göster"} />
                                </Box>
                                <Box>
                                    <FormControlLabel control={
                                        <Checkbox onChange={(e, checked) => this.setState({ disable: checked })}
                                            checked={this.state.disable}
                                        />
                                    } label={"Gönderiyi Engelle"} />
                                </Box>
                                <Box>
                                    <FormControlLabel control={
                                        <Checkbox onChange={(e, checked) => this.setState({ ban_user: checked })}
                                            checked={this.state.ban_user}
                                        />
                                    } label={"Kullanıcıyı Banla"} />
                                </Box>
                                <Box>
                                    <FormControlLabel control={
                                        <Checkbox onChange={(e, checked) => this.setState({ ban_ip: checked })}
                                            checked={this.state.ban_ip}
                                        />
                                    } label={"IP'yi Banla"} />
                                </Box>
                            </TableCell>
                        </TableRow>
                    </Table>
                </DialogContent>

                <DialogActions>
                    <Button onClick={() => this.props.onClose()} variant="contained" color="error">
                        İptal
                    </Button>
                    <Button onClick={this.onSave} variant="contained" color="primary"
                        className="!text-white"
                        disabled={!this.state.pattern || this.state.saving}
                    >
                        Kaydet
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

EditSpam.propTypes = {
    classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
    return {
    }
};

export default withStyles(styles)(connect(mapStateToProps, {
    setNotification,
})(EditSpam));
