import { SvgIcon } from "@mui/material";
import clsx from "clsx";
import React from "react";

const Svg = (props) => (
    <SvgIcon className={clsx("MuiSvgIcon-root MuiSvgIcon-fontSizeMedium", props.className)} viewBox="0 0 24 24">
        {props.children}
    </SvgIcon>
);

const Computer = (props) => (
    <Svg {...props}>
        <path style={{ stroke: "none", fillRule: "nonzero", fill: "rgb(24.313725%,34.901961%,34.901961%)", fillOpacity: 1 }} d="M 12.046875 16.6875 L 17.671875 16.6875 L 17.671875 19.96875 L 12.046875 19.96875 Z M 12.046875 16.6875 " />
        <path style={{ stroke: "none", fillRule: "nonzero", fill: "rgb(16.078431%,22.352941%,22.352941%)", fillOpacity: 1 }} d="M 14.859375 16.6875 L 17.671875 16.6875 L 17.671875 19.96875 L 14.859375 19.96875 Z M 14.859375 16.6875 " />
        <path style={{ stroke: "none", fillRule: "nonzero", fill: "rgb(24.313725%,34.901961%,34.901961%)", fillOpacity: 1 }} d="M 7.734375 21.140625 L 0.703125 21.140625 C 0.316406 21.140625 0 20.824219 0 20.4375 L 0 4.96875 C 0 4.582031 0.316406 4.265625 0.703125 4.265625 L 7.734375 4.265625 C 8.121094 4.265625 8.4375 4.582031 8.4375 4.96875 L 8.4375 20.4375 C 8.4375 20.824219 8.121094 21.140625 7.734375 21.140625 Z M 7.734375 21.140625 " />
        <path style={{ stroke: "none", fillRule: "nonzero", fill: "rgb(16.078431%,22.352941%,22.352941%)", fillOpacity: 1 }} d="M 8.4375 4.96875 L 8.4375 20.4375 C 8.4375 20.824219 8.121094 21.140625 7.734375 21.140625 L 4.21875 21.140625 L 4.21875 4.265625 L 7.734375 4.265625 C 8.121094 4.265625 8.4375 4.582031 8.4375 4.96875 Z M 8.4375 4.96875 " />
        <path style={{ stroke: "none", fillRule: "nonzero", fill: "rgb(32.941176%,47.45098%,51.372549%)", fillOpacity: 1 }} d="M 0 7.78125 L 8.4375 7.78125 L 8.4375 10.59375 L 0 10.59375 Z M 0 7.78125 " />
        <path style={{ stroke: "none", fillRule: "nonzero", fill: "rgb(24.313725%,34.901961%,34.901961%)", fillOpacity: 1 }} d="M 4.21875 7.78125 L 8.4375 7.78125 L 8.4375 10.59375 L 4.21875 10.59375 Z M 4.21875 7.78125 " />
        <path style={{ stroke: "none", fillRule: "nonzero", fill: "rgb(50.588235%,90.980392%,52.156863%)", fillOpacity: 1 }} d="M 4.921875 19.171875 C 4.921875 19.558594 4.605469 19.875 4.21875 19.875 C 3.832031 19.875 3.515625 19.558594 3.515625 19.171875 C 3.515625 18.785156 3.832031 18.46875 4.21875 18.46875 C 4.605469 18.46875 4.921875 18.785156 4.921875 19.171875 Z M 4.921875 19.171875 " />
        <path style={{ stroke: "none", fillRule: "nonzero", fill: "rgb(36.470588%,78.823529%,51.372549%)", fillOpacity: 1 }} d="M 4.921875 19.171875 C 4.921875 19.558594 4.605469 19.875 4.21875 19.875 L 4.21875 18.46875 C 4.605469 18.46875 4.921875 18.785156 4.921875 19.171875 Z M 4.921875 19.171875 " />
        <path style={{ stroke: "none", fillRule: "nonzero", fill: "rgb(32.941176%,47.45098%,51.372549%)", fillOpacity: 1 }} d="M 22.59375 16.921875 L 7.125 16.921875 C 6.347656 16.921875 5.71875 16.292969 5.71875 15.515625 L 5.71875 4.265625 C 5.71875 3.488281 6.347656 2.859375 7.125 2.859375 L 22.59375 2.859375 C 23.371094 2.859375 24 3.488281 24 4.265625 L 24 15.515625 C 24 16.292969 23.371094 16.921875 22.59375 16.921875 Z M 22.59375 16.921875 " />
        <path style={{ stroke: "none", fillRule: "nonzero", fill: "rgb(24.313725%,34.901961%,34.901961%)", fillOpacity: 1 }} d="M 24 4.265625 L 24 15.515625 C 24 16.289062 23.367188 16.921875 22.59375 16.921875 L 14.859375 16.921875 L 14.859375 2.859375 L 22.59375 2.859375 C 23.367188 2.859375 24 3.492188 24 4.265625 Z M 24 4.265625 " />
        <path style={{ stroke: "none", fillRule: "nonzero", fill: "rgb(9.803922%,75.294118%,93.72549%)", fillOpacity: 1 }} d="M 7.125 4.265625 L 22.59375 4.265625 L 22.59375 15.515625 L 7.125 15.515625 Z M 7.125 4.265625 " />
        <path style={{ stroke: "none", fillRule: "nonzero", fill: "rgb(8.235294%,60.784314%,88.235294%)", fillOpacity: 1 }} d="M 14.859375 4.265625 L 22.59375 4.265625 L 22.59375 15.515625 L 14.859375 15.515625 Z M 14.859375 4.265625 " />
        <path style={{ stroke: "none", fillRule: "nonzero", fill: "rgb(32.941176%,47.45098%,51.372549%)", fillOpacity: 1 }} d="M 19.078125 21.140625 L 10.640625 21.140625 C 10.253906 21.140625 9.9375 20.824219 9.9375 20.4375 C 9.9375 20.050781 10.253906 19.734375 10.640625 19.734375 L 19.078125 19.734375 C 19.464844 19.734375 19.78125 20.050781 19.78125 20.4375 C 19.78125 20.824219 19.464844 21.140625 19.078125 21.140625 Z M 19.078125 21.140625 " />
        <path style={{ stroke: "none", fillRule: "nonzero", fill: "rgb(24.313725%,34.901961%,34.901961%)", fillOpacity: 1 }} d="M 19.78125 20.4375 C 19.78125 20.824219 19.464844 21.140625 19.078125 21.140625 L 14.859375 21.140625 L 14.859375 19.734375 L 19.078125 19.734375 C 19.464844 19.734375 19.78125 20.050781 19.78125 20.4375 Z M 19.78125 20.4375 " />
    </Svg>
);

const Mobile = (props) => (
    <Svg {...props}>
        <path style={{ stroke: "none", fillRule: "evenodd", fill: "rgb(24.313725%,28.627451%,35.294118%)", fillOpacity: 1 }} d="M 16.816406 24 L 7.171875 24 C 5.90625 24 4.878906 22.960938 4.878906 21.695312 L 4.878906 2.292969 C 4.878906 1.027344 5.90625 0 7.171875 0 L 16.816406 0 C 18.09375 0 19.121094 1.027344 19.121094 2.292969 L 19.121094 21.695312 C 19.121094 22.960938 18.09375 24 16.816406 24 Z M 16.816406 24 " />
        <path style={{ stroke: "none", fillRule: "evenodd", fill: "rgb(71.764706%,84.313725%,99.607843%)", fillOpacity: 1 }} d="M 18.558594 3.597656 L 5.441406 3.597656 L 5.441406 20.402344 L 18.558594 20.402344 C 18.558594 14.707031 18.558594 9.390625 18.558594 3.597656 Z M 18.558594 3.597656 " />
        <path style={{ stroke: "none", fillRule: "evenodd", fill: "rgb(45.098039%,50.588235%,56.862745%)", fillOpacity: 1 }} d="M 8.40625 0 L 15.59375 0 L 14.761719 2.023438 C 14.71875 2.121094 14.621094 2.191406 14.496094 2.191406 L 9.492188 2.191406 C 9.378906 2.191406 9.28125 2.121094 9.238281 2.023438 Z M 8.40625 0 " />
        <path style={{ stroke: "none", fillRule: "evenodd", fill: "rgb(45.098039%,50.588235%,56.862745%)", fillOpacity: 1 }} d="M 14.226562 22.480469 L 9.773438 22.480469 C 9.40625 22.480469 9.40625 21.917969 9.773438 21.917969 L 14.226562 21.917969 C 14.59375 21.917969 14.59375 22.480469 14.226562 22.480469 Z M 14.226562 22.480469 " />
        <path style={{ stroke: "none", fillRule: "evenodd", fill: "rgb(65.882353%,81.176471%,100%)", fillOpacity: 1 }} d="M 5.441406 20.402344 L 18.558594 3.597656 L 5.441406 3.597656 Z M 5.441406 20.402344 " />
    </Svg>
);

const Android = (props) => (
    <Svg {...props}>
        <path style={{ stroke: "none", fillRule: "nonzero", fill: "rgb(29.803922%,68.627451%,31.372549%)", fillOpacity: 1 }} d="M 3.503906 8.003906 C 2.671875 8.003906 2.003906 8.675781 2.003906 9.503906 L 2.003906 16.5 C 2.003906 17.328125 2.671875 18 3.503906 18 C 4.332031 18 5 17.328125 5 16.5 L 5 9.503906 C 5 8.675781 4.332031 8.003906 3.503906 8.003906 Z M 3.503906 8.003906 " />
        <path style={{ stroke: "none", fillRule: "nonzero", fill: "rgb(29.803922%,68.627451%,31.372549%)", fillOpacity: 1 }} d="M 20.496094 8.003906 C 19.667969 8.003906 19 8.675781 19 9.503906 L 19 16.5 C 19 17.328125 19.667969 18 20.496094 18 C 21.328125 18 21.996094 17.328125 21.996094 16.5 L 21.996094 9.503906 C 21.996094 8.675781 21.328125 8.003906 20.496094 8.003906 Z M 20.496094 8.003906 " />
        <path style={{ stroke: "none", fillRule: "nonzero", fill: "rgb(29.803922%,68.627451%,31.372549%)", fillOpacity: 1 }} d="M 17.5 8.003906 L 6.5 8.003906 C 6.226562 8.003906 6 8.226562 6 8.503906 L 6 16.5 C 6 17.691406 6.835938 18.714844 8 18.953125 L 8 22.5 C 8 23.328125 8.671875 24 9.5 24 C 10.328125 24 11 23.328125 11 22.5 L 11 19 L 13 19 L 13 22.5 C 13 23.328125 13.671875 24 14.5 24 C 15.328125 24 16 23.328125 16 22.5 L 16 18.953125 C 17.164062 18.714844 18 17.691406 18 16.5 L 18 8.503906 C 18 8.226562 17.773438 8.003906 17.5 8.003906 Z M 17.5 8.003906 " />
        <path style={{ stroke: "none", fillRule: "nonzero", fill: "rgb(29.803922%,68.627451%,31.372549%)", fillOpacity: 1 }} d="M 15.632812 2.078125 L 16.851562 0.859375 C 17.050781 0.667969 17.058594 0.351562 16.863281 0.152344 C 16.671875 -0.046875 16.355469 -0.0507812 16.160156 0.140625 C 16.152344 0.144531 16.148438 0.148438 16.144531 0.152344 L 14.726562 1.574219 C 12.988281 0.816406 11.011719 0.816406 9.273438 1.574219 L 7.855469 0.152344 C 7.65625 -0.0390625 7.339844 -0.0351562 7.148438 0.164062 C 6.960938 0.359375 6.960938 0.664062 7.148438 0.859375 L 8.367188 2.078125 C 6.882812 3.058594 5.992188 4.722656 6 6.503906 C 6 6.78125 6.226562 7.003906 6.5 7.003906 L 17.5 7.003906 C 17.773438 7.003906 18 6.78125 18 6.503906 C 18.007812 4.722656 17.117188 3.058594 15.632812 2.078125 Z M 15.632812 2.078125 " />
        <path style={{ stroke: "none", fillRule: "nonzero", fill: "rgb(98.039216%,98.039216%,98.039216%)", fillOpacity: 1 }} d="M 10 4.503906 C 10 4.78125 9.777344 5.003906 9.5 5.003906 C 9.222656 5.003906 9 4.78125 9 4.503906 C 9 4.226562 9.222656 4.003906 9.5 4.003906 C 9.777344 4.003906 10 4.226562 10 4.503906 Z M 10 4.503906 " />
        <path style={{ stroke: "none", fillRule: "nonzero", fill: "rgb(98.039216%,98.039216%,98.039216%)", fillOpacity: 1 }} d="M 15 4.503906 C 15 4.78125 14.777344 5.003906 14.5 5.003906 C 14.222656 5.003906 14 4.78125 14 4.503906 C 14 4.226562 14.222656 4.003906 14.5 4.003906 C 14.777344 4.003906 15 4.226562 15 4.503906 Z M 15 4.503906 " />
    </Svg>
);

const Apple = (props) => (
    <Svg {...props}>
        <path style={{ stroke: "none", fillRule: "nonzero", fill: "rgb(0%,0%,0%)", fillOpacity: 1 }} d="M 15.335938 3.894531 C 16.121094 2.886719 16.710938 1.457031 16.5 0 C 15.21875 0.0898438 13.722656 0.90625 12.851562 1.972656 C 12.054688 2.941406 11.402344 4.378906 11.65625 5.773438 C 13.054688 5.816406 14.5 4.980469 15.335938 3.894531 Z M 15.335938 3.894531 " />
        <path style={{ stroke: "none", fillRule: "nonzero", fill: "rgb(10.588235%,32.941176%,41.960784%)", fillOpacity: 1 }} d="M 21.554688 8.050781 C 20.328125 6.511719 18.601562 5.617188 16.96875 5.617188 C 14.816406 5.617188 13.90625 6.648438 12.410156 6.648438 C 10.871094 6.648438 9.699219 5.621094 7.835938 5.621094 C 6.007812 5.621094 4.0625 6.738281 2.828125 8.648438 C 1.089844 11.339844 1.386719 16.398438 4.199219 20.707031 C 5.207031 22.25 6.550781 23.984375 8.308594 24 C 9.871094 24.015625 10.3125 22.996094 12.433594 22.984375 C 14.554688 22.972656 14.957031 24.011719 16.515625 23.996094 C 18.277344 23.980469 19.695312 22.058594 20.699219 20.519531 C 21.421875 19.414062 21.691406 18.855469 22.25 17.609375 C 18.179688 16.058594 17.527344 10.273438 21.554688 8.050781 Z M 21.554688 8.050781 " />
        <path style={{ stroke: "none", fillRule: "nonzero", fill: "rgb(0%,0%,0%)", fillOpacity: 1 }} d="M 19.085938 11.027344 C 16.007812 13.519531 11.660156 15.074219 6.832031 15.074219 C 5.101562 15.074219 3.433594 14.875 1.863281 14.503906 C 2.128906 16.523438 2.886719 18.695312 4.203125 20.707031 C 5.207031 22.25 6.550781 23.984375 8.308594 24 C 9.875 24.015625 10.3125 22.996094 12.433594 22.984375 C 14.554688 22.972656 14.957031 24.011719 16.515625 23.996094 C 18.277344 23.980469 19.695312 22.058594 20.699219 20.519531 C 21.421875 19.414062 21.691406 18.855469 22.25 17.609375 C 19.480469 16.554688 18.296875 13.546875 19.085938 11.027344 Z M 19.085938 11.027344 " />
    </Svg>
);

export const TableIcon = (props) => (
    <Svg {...props}>
        <path style={{ stroke: "none", fillRule: "nonzero", fill: "rgb(34.901961%,42.352941%,46.27451%)", fillOpacity: 1 }} d="M 3.351562 8.957031 L 3.351562 19.257812 C 3.351562 19.667969 3.6875 20.003906 4.097656 20.003906 C 4.492188 20.003906 4.820312 19.695312 4.84375 19.304688 L 5.492188 8.957031 Z M 3.351562 8.957031 " />
        <path style={{ stroke: "none", fillRule: "nonzero", fill: "rgb(34.901961%,42.352941%,46.27451%)", fillOpacity: 1 }} d="M 18.507812 8.957031 L 19.15625 19.304688 C 19.179688 19.695312 19.507812 20.003906 19.902344 20.003906 C 20.3125 20.003906 20.648438 19.667969 20.648438 19.257812 L 20.648438 8.957031 Z M 18.507812 8.957031 " />
        <path style={{ stroke: "none", fillRule: "nonzero", fill: "rgb(66.666667%,41.568627%,31.764706%)", fillOpacity: 1 }} d="M 1.328125 6.199219 L 1.328125 8.085938 C 1.328125 8.566406 1.71875 8.957031 2.195312 8.957031 L 21.804688 8.957031 C 22.28125 8.957031 22.671875 8.566406 22.671875 8.085938 L 22.671875 6.199219 Z M 1.328125 6.199219 " />
        <path style={{ stroke: "none", fillRule: "nonzero", fill: "rgb(73.72549%,48.627451%,38.823529%)", fillOpacity: 1 }} d="M 23.421875 3.996094 L 0.578125 3.996094 C 0.257812 3.996094 0 4.253906 0 4.574219 L 0 5.621094 C 0 5.941406 0.257812 6.199219 0.578125 6.199219 L 23.421875 6.199219 C 23.742188 6.199219 24 5.941406 24 5.621094 L 24 4.574219 C 24 4.253906 23.742188 3.996094 23.421875 3.996094 Z M 23.421875 3.996094 " />
        <path style={{ stroke: "none", fillRule: "nonzero", fill: "rgb(66.666667%,41.568627%,31.764706%)", fillOpacity: 1 }} d="M 23.421875 3.996094 L 21.503906 3.996094 C 21.824219 3.996094 22.082031 4.253906 22.082031 4.574219 L 22.082031 5.621094 C 22.082031 5.941406 21.824219 6.199219 21.503906 6.199219 L 23.421875 6.199219 C 23.742188 6.199219 24 5.941406 24 5.621094 L 24 4.574219 C 24 4.253906 23.742188 3.996094 23.421875 3.996094 Z M 23.421875 3.996094 " />
        <path style={{ stroke: "none", fillRule: "nonzero", fill: "rgb(57.647059%,35.686275%,26.666667%)", fillOpacity: 1 }} d="M 20.753906 6.199219 L 20.753906 8.085938 C 20.753906 8.566406 20.367188 8.957031 19.886719 8.957031 L 21.804688 8.957031 C 22.28125 8.957031 22.671875 8.566406 22.671875 8.085938 L 22.671875 6.199219 Z M 20.753906 6.199219 " />
        <path style={{ stroke: "none", fillRule: "nonzero", fill: "rgb(27.45098%,35.294118%,38.039216%)", fillOpacity: 1 }} d="M 3.351562 10.324219 L 5.40625 10.324219 L 5.492188 8.957031 L 3.351562 8.957031 Z M 3.351562 10.324219 " />
        <path style={{ stroke: "none", fillRule: "nonzero", fill: "rgb(27.45098%,35.294118%,38.039216%)", fillOpacity: 1 }} d="M 18.59375 10.324219 L 20.648438 10.324219 L 20.648438 8.957031 L 18.507812 8.957031 Z M 18.59375 10.324219 " />
    </Svg>
);

export const deviceIcons = {
    [null]: Computer,
    0: Mobile,
    1: Android,
    2: Apple,
}