import React from "react";
import { connect } from "react-redux";
import { withStyles } from "@mui/styles";
import PropTypes from "prop-types";
import { Box, Button, Card, CardContent, CircularProgress, InputAdornment, SvgIcon, TextField, Typography } from "@mui/material";
import { mdiLock } from '@mdi/js';
import axios, { AxiosError } from "axios";
import { setNotification } from "../../actions/global";
import { GlobalTypes } from "../../action_types";
import forge from 'node-forge';
import { generateKey } from "../../global/pbkdf2";
import qs from 'query-string';
import { ERROR_CODES } from "../../global/error";
import clsx from "clsx";
import { getPath } from "../../global/route";
import { AxiosClient } from "../../global/axios";

const styles = theme => ({
    root: {
        backgroundColor: '#624799',
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        overflowY: 'auto',
    },
    title: {
        fontWeight: 'bold !important',
        fontFamily: 'Roboto Slab',
        color: '#1E2C5F !important',
    },
    text: {
        fontWeight: 'medium !important',
        color: '#1E2C5F !important',
    },
    textField: {
        fontFamily: 'Roboto Slab',
        fontWeight: 'bold !important',
    },
    inputIcon: {
        marginRight: '8px',
        color: '#1E2C5F !important'
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        rowGap: 12,
        padding: 24,
        color: 'white !important',
    },
    header: {
        //backgroundImage: "linear-gradient(to right, #624799 50%, #1D9AB6)",
        backgroundColor: '#624799',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        columnGap: 12
    },
    headerTitle: {
        color: '#E6E1FF',
    },
    button: {
    }
});

class Reset extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            password: '',
            passwordAgain: '',
        }

        this.email = '';
        this.token = '';
    }

    componentDidMount = () => {
        const search = this.props.history?.location?.search ?? '';
        const q = qs.parse(search);

        this.email = q.email;
        this.token = q.token;

        if (!this.email || !this.token) {
            const message = 'Parametreler hatalı, anasayfaya yönlendiriliyorsunuz.'
            this.props.setNotification({ message: message, severity: GlobalTypes.NOTIFY_ERR });
            setTimeout(() => {
                this.props.history.push(getPath('/'));
            }, 1000);
        }
    }

    reset = async () => {
        let that = this;

        forge.pki.rsa.generateKeyPair({ bits: 1024 }, async (err, pair) => {
            try {

                const privatePem = forge.pki.privateKeyToPem(pair.privateKey);
                const public_key = forge.pki.publicKeyToPem(pair.publicKey);

                const iv = forge.random.getBytesSync(16);
                const salt = forge.random.getBytesSync(16);

                const key = generateKey(that.email, that.state.password, salt);

                let cipher = forge.aes.createEncryptionCipher(key);
                cipher.start(iv);
                cipher.update(forge.util.createBuffer(privatePem));
                cipher.finish();

                const enc_private_key = forge.util.bytesToHex(cipher.output.getBytes());

                const req = {
                    user: this.email,
                    secret: this.token,
                    enc_private_key,
                    public_key,
                    salt: forge.util.bytesToHex(salt),
                    iv: forge.util.bytesToHex(iv),
                };

                await AxiosClient().post(`${process.env.REACT_APP_API_URL}/v1/user/reset.password`, req);
                this.setState({ loading: false });

                let message = 'Şifreniz başarıyla değiştirildi.';
                let severity = GlobalTypes.NOTIFY_SUCC;

                this.props.setNotification({ message, severity });
                this.setState({ password: '', passwordAgain: '' });

                setTimeout(() => {
                    this.props.history.push(getPath('/'));
                }, 1000);
            }
            catch (e) {
                console.error(e);
                let message = ERROR_CODES[2];

                if (e instanceof AxiosError) {
                    const code = e.response?.data?.error?.code;
                    if (code in ERROR_CODES) {
                        message = ERROR_CODES[code];
                    } else {
                        message = e.message;
                    }
                }

                this.props.setNotification({ message: message, severity: GlobalTypes.NOTIFY_ERR, traceId: e.response?.data?.trace_id });
                this.setState({ loading: false });
            }
        })
    }

    resetForm = () => {
        const { classes } = this.props;

        return (
            <Box className={classes.content}>
                <form onSubmit={(e) => e.preventDefault()}>
                    <Box display={"flex"} flexDirection={"column"} rowGap={'6px'}>
                        <Typography className={clsx(classes.title, "!text-base !mb-4")}>Şifremi Sıfırla</Typography>
                        <Box>
                            <Typography className={classes.text}>Şifre</Typography>
                            <TextField className={classes.textField}
                                autoComplete='new-password'
                                fullWidth
                                //placeholder='Şifre'
                                margin="dense"
                                variant="outlined"
                                type="password"
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment className={classes.inputIcon} position="start">
                                            <SvgIcon><path d={mdiLock} /></SvgIcon>
                                        </InputAdornment>
                                    ),
                                    classes: {
                                        input: "!text-xs sm:!text-sm md:!text-base",
                                    }
                                }}
                                value={this.state.password}
                                onChange={(e) => this.setState({ password: e.target.value })}
                            />
                        </Box>

                        <Box>
                            <Typography className={classes.text}>Şifre (Tekrar)</Typography>
                            <TextField className={classes.textField}
                                autoComplete='new-password'
                                fullWidth
                                //placeholder='Şifre Tekrar'
                                margin="dense"
                                variant="outlined"
                                type="password"
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment className={classes.inputIcon} position="start">
                                            <SvgIcon><path d={mdiLock} /></SvgIcon>
                                        </InputAdornment>
                                    ),
                                    classes: {
                                        input: "!text-xs sm:!text-sm md:!text-base",
                                    }
                                }}
                                value={this.state.passwordAgain}
                                onChange={(e) => this.setState({ passwordAgain: e.target.value })}
                            />
                        </Box>
                    </Box>

                    <Button variant='contained' type="submit" fullWidth sx={{ mt: 2, color: 'var(--white)', backgroundColor: '#FF9559 !important' }}
                        className="!text-sm md:!text-base !min-h-10"
                        disabled={!this.state.password || this.state.password !== this.state.passwordAgain || this.state.loading}
                        onClick={this.reset}
                    >
                        {this.state.loading ? <CircularProgress size={20} sx={{ margin: '4px 0px', color: '#FF9559' }} thickness={8} /> : 'Şifremi Değiştir'}
                    </Button>
                </form>
            </Box>
        );
    }

    header = () => {
        const { classes } = this.props;
        const imgPath = getPath('/img');

        return (
            <Box height={60} px={4}
                className={clsx(classes.header, "flex")}
            >
                <img src={`${imgPath}/logo.svg`} alt="logo" />
                <Box>
                    <Typography className={clsx("!font-['Roboto Slab'] !text-white !text-sm md:!text-base !font-bold")}>{process.env.REACT_APP_HEADER_TITLE}</Typography>
                    <Typography className={clsx("!font-['Roboto Slab'] !text-white !text-sm md:!text-base !font-bold")}>{process.env.REACT_APP_HEADER_SUBTITLE}</Typography>
                </Box>
            </Box>
        );
    }

    render() {
        const { classes } = this.props;

        return (
            <div className={classes.root}>

                {this.header()}

                <div className="w-full flex-1 self-center max-w-md mt-[4vh]">
                    <Card sx={{ backgroundColor: '#FFFFFF', borderRadius: 4, border: '1px solid #E8E8E8' }}>
                        <CardContent sx={{ padding: 0 }}>

                            {this.resetForm()}

                        </CardContent>
                    </Card>
                </div>

                <div className="w-full flex flex-col gap-y-2 justify-center items-center mt-4">
                    <Typography sx={{ color: '#FFFFFF' }} className="!text-xs sm:!text-sm md:!text-base">
                        Tüm hakları saklıdır. © 2024 kerizim.com
                    </Typography>
                </div>

            </div>
        );
    }
}

Reset.propTypes = {
    classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
    return {
    }
};

export default withStyles(styles)(connect(mapStateToProps, {
    setNotification,
})(Reset));
