import { LobbyTypes, TableTypes } from '../../action_types';

/*
const initialState = {
    table: null,
    indicator: 0,
    piecesLeft: 0,
    round: 0,
    turn: null,
    turnIndex: null,
    defaultTurnTime: 0,
    turnTimeLeft: null,
    pieces: [],
    pots: [],
    endRound: null,
    infoAt: Date.now(),
    potAt: Date.now(),
    indicatorUser: null,
    showIndicatorAt: Date.now(),
    double: false,
    returnOkey: false,
}
*/



const initialState = {
    tables: {},
    tableIds: [],
};

const table = (state = initialState, action) => {
    switch (action.type) {
        case TableTypes.ADD_TABLE: {
            if (state.tableIds.includes(action.tableId)) {
                return state;
            }

            return {
                ...state,
                tableIds: [...state.tableIds, action.tableId],
                tables: {
                    ...state.tables,
                    [action.tableId]: {
                        mobileChatAt: Date.now(),
                    }
                }
            };
        }

        case TableTypes.LEAVE_TABLE: {
            const tables = { ...state.tables };
            delete tables[action.tableId];

            return {
                ...state,
                tables,
                tableIds: state.tableIds.filter((id) => id !== action.tableId),
            };
        }

        case TableTypes.SET_PIECES: {
            return {
                ...state,
                tables: {
                    ...state.tables,
                    [action.tableId]: {
                        ...state.tables[action.tableId],
                        pieces: action.pieces,
                    },
                },
            };
        }

        case TableTypes.SET_GAME_INFO: {
            return {
                ...state,
                tables: {
                    ...state.tables,
                    [action.tableId]: {
                        ...state.tables[action.tableId],
                        indicator: action.info?.indicator ?? initialState.indicator,
                        piecesLeft: action.info?.piecesLeft ?? initialState.piecesLeft,
                        round: action.info?.round ?? initialState.round,
                        turn: action.info?.turn ?? initialState.turn,
                        defaultTurnTime: action.info?.defaultTurnTime ?? initialState.defaultTurnTime,
                        infoAt: Date.now(),
                        endRound: null,
                    },
                },
            };
        }

        case TableTypes.SET_TURN_TIME: {
            return {
                ...state,
                tables: {
                    ...state.tables,
                    [action.tableId]: {
                        ...state.tables[action.tableId],
                        turn: action.data?.turn,
                        turnIndex: action.data?.turnIndex,
                        turnTimeLeft: action.data?.timeLeft,
                    },
                },
            };
        }

        case TableTypes.SET_POTS: {
            return {
                ...state,
                tables: {
                    ...state.tables,
                    [action.tableId]: {
                        ...state.tables[action.tableId],
                        pots: action.pots,
                        potAt: Date.now(),
                    },
                },
            };
        }

        case TableTypes.SET_GROUND: {
            return {
                ...state,
                tables: {
                    ...state.tables,
                    [action.tableId]: {
                        ...state.tables[action.tableId],
                        piecesLeft: action.piecesLeft,
                    },
                },
            };
        }

        case TableTypes.SET_END_ROUND: {
            return {
                ...state,
                tables: {
                    ...state.tables,
                    [action.tableId]: {
                        ...state.tables[action.tableId],
                        endRound: action.endRound,
                    },
                },
            };
        }

        case TableTypes.SHOW_INDICATOR: {
            return {
                ...state,
                tables: {
                    ...state.tables,
                    [action.tableId]: {
                        ...state.tables[action.tableId],
                        indicatorUser: action.userId,
                        showIndicatorAt: Date.now(),
                    },
                },
            };
        }

        case TableTypes.SET_DOUBLE: {
            return {
                ...state,
                tables: {
                    ...state.tables,
                    [action.tableId]: {
                        ...state.tables[action.tableId],
                        double: action.double,
                    },
                },
            };
        }

        case TableTypes.SET_RETURN_OKEY: {
            return {
                ...state,
                tables: {
                    ...state.tables,
                    [action.tableId]: {
                        ...state.tables[action.tableId],
                        returnOkey: action.returnOkey,
                    },
                },
            };
        }

        case TableTypes.SET_MOBILE_CHAT_AT: {
            return {
                ...state,
                tables: {
                    ...state.tables,
                    [action.tableId]: {
                        ...state.tables[action.tableId],
                        mobileChatAt: action.at,
                    },
                },
            };
        }

        default:
            return state;
    }
};

export default table;
