export const getUserImage = (user_id, profile_photo) => {
    return new Promise(async (resolve, reject) => {
        try {
            const src = `${process.env.REACT_APP_STORAGE_URL}/users/${user_id}/${profile_photo}`;

            const image = await fetch(src);

            if (!image.ok) {
                reject('Failed to fetch image');
            }

            image.body.getReader().read().then(({ value, done }) => {
                const blob = new Blob([value], { type: image.headers.get('Content-Type') });
                const reader = new FileReader();
                reader.onload = async () => {
                    const base64 = reader.result;
                    resolve(base64);
                };
                reader.readAsDataURL(blob);
            });
        }
        catch (e) {
            reject(e);
        }
    });
}
