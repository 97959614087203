import { imgResources, loadedResources } from "./resources";

export async function loadResources(setLoadingState) {
    return new Promise((resolve) => {
        console.log("Loading resources...");
        setLoadingState(0);

        let loaded = 0;

        const doFetch = async (src) => {
            try {
                const img = new Image();
                const response = await fetch(src);
                if (!response.ok) {
                    throw new Error(`${src} failed to load. Status: ${response.status} ${response.statusText}`);
                }
                const blob = await response.blob();
                //img.src = URL.createObjectURL(blob);

                const reader = new FileReader();
                reader.readAsDataURL(blob);
                reader.onloadend = function () {
                    loadedResources[src] = reader.result;
                    loaded++;
                };
            }
            catch (error) {
                console.error(error);
                setTimeout(() => doFetch(src), 1000);
            }
        };

        imgResources.forEach((src) => {
            doFetch(src);
        });

        let prePercentage = 0;
        const interval = setInterval(() => {
            let percentage = (loaded / imgResources.length) * 100;
            if (percentage >= 100) percentage = 99; // Prevent 100% to show because loading screen will be removed later

            if (percentage > prePercentage) {
                setLoadingState(parseInt(percentage));
                prePercentage = percentage;
            }

            if (loaded === imgResources.length) {
                clearInterval(interval);
                resolve();
            }
        }, 100);
    });
}