import React from "react";
import { connect } from "react-redux";
import { setSocketHandler } from "./global/socket";
import { AxiosError } from "axios";
import {
    setRoomConfig, setRoomMaintenance, setRolePermission, selectTab, setLoadingState, setDisconnected,
    setInvitationDialog, setLeavingTable, closeInfoDialog, setNotification, setReportDialog
} from "./actions/global";
import { Action } from "history";
import ErrorDialog from './components/Error';
import { loadResources } from "./global/loading";
import LoadingScreen from "./LoadingScreen";
import { AxiosClient } from "./global/axios";
import { getPath } from "./global/route";
import { mdiPowerPlugOff } from "@mdi/js";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormLabel, MenuItem, Select, SvgIcon, TextField, Typography } from "@mui/material";
import { logout } from "./global/connection";
import RejoinTable from "./components/Lobby/RejoinTable";
import { GlobalTypes } from "./action_types";
import { ERROR_CODES } from "./global/error";
import { roles } from "./global/role";

const reportReasonList = [
    "Diğer oyunculara yönelik küfürlü veya saldırgan dil kullanımı.",
    "Oyunun içerisindeki hile veya oyunu manipüle etme girişimleri.",
    "Diğer oyunculara yönelik kişisel taciz, tehdit veya saldırılar.",
    "Oyun içi reklam veya spam içeren mesajlar.",
    "Takım oyunlarındaki işbirliğini olumsuz etkileyen kötü niyetli davranışlar.",
    "Bunların hiçbiri değil."
];

class Protected extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            reportDialog: null,
        }
    }

    componentDidMount() {
        this.listenHistory();
        const sid = sessionStorage.getItem('sid') ?? localStorage.getItem('sid');
        const profile = sessionStorage.getItem('profile') ?? localStorage.getItem('profile');

        if (!sid || !profile) {
            setTimeout(() => {
                sessionStorage.removeItem('rememberMe');
                sessionStorage.removeItem('sid');
                sessionStorage.removeItem('profile');
                localStorage.removeItem('rememberMe');
                localStorage.removeItem('sid');
                localStorage.removeItem('profile');
                this.props.history.push(getPath('/'));
            }, 0);
            return;
        }

        if (!sessionStorage.getItem('rememberMe') && localStorage.getItem('sid')) {
            sessionStorage.setItem('rememberMe', true);
        }

        window.dispatchEvent(new Event('login'));

        this.loadAndInit();
    }

    loadAndInit = async () => {
        const { socket } = this.props;

        await loadResources(this.props.setLoadingState);

        if (!socket) {
            if (this.props.history.location?.pathname === '/login') {
                this.props.history.push(getPath('/salon'));
            }

            this.loggedIn();
        }

        await this.init();
        this.props.setLoadingState(null);

        const that = this;
        const beforeUnload = (e) => {
            const sid = sessionStorage.getItem('sid') ?? localStorage.getItem('sid');

            if (that.props.disconnected || !sid) {
                delete e['returnValue'];
                return;
            }

            e.returnValue = true;
        };

        window.addEventListener('beforeunload', (e) => beforeUnload(e));
    }

    listenHistory = () => {
        const { history } = this.props;

        this.backListener = history?.listen?.((e) => {
            if (e.action === Action.Pop) {
                const path = e.location.pathname.split("/")[1];
                switch (path) {
                    case "":
                    case "salon":
                        this.props.selectTab("salon");
                        break;
                    case "admin":
                        this.props.selectTab("admin");
                        break;
                    case "sohbet":
                        this.props.selectTab(e.location.state?.userId);
                        break;
                    case "masa":
                        const id = e.location.pathname.split("/")[2];
                        this.props.selectTab(`table_${id}`);
                        break;
                    default:
                        break;
                }
            }
        });
    }

    componentWillUnmount() {
        this.backListener?.();
    }

    loggedIn = async () => {
        const rememberMe = sessionStorage.getItem('rememberMe') === 'true';
        let url = `${process.env.REACT_APP_CORE_URL}`;

        if (rememberMe) {
            url += '?sid=' + localStorage.getItem('sid');
        }
        else {
            url += '?sid=' + sessionStorage.getItem('sid');
        }

        const socket = new WebSocket(url);

        socket.onerror = (e) => {
            this.props.setDisconnected(true, false);
        }

        socket.onopen = async () => {
            await this.props.setSocketHandler(socket, this.props.history);

            if (!socket.login) {
                socket.login = true;
                socket.emit('login');
                socket.emit('get_room');
            }
        }
    }

    init = async () => {
        try {
            const resp = await AxiosClient().get(`${process.env.REACT_APP_API_URL}/v1/room/config`);

            this.props.setRoomConfig(resp.data);

            const roleResp = await AxiosClient().get(`${process.env.REACT_APP_API_URL}/v1/user/role`);

            this.props.setRolePermission(roleResp.data.role, roleResp.data.perm);

            if (roleResp.data.role > roles.Streamer) {
                const maintenanceResp = await AxiosClient().get(`${process.env.REACT_APP_API_URL}/v1/admin/server/maintenance`);

                this.props.setRoomMaintenance(maintenanceResp.data.enabled);
            }
        }
        catch (err) {
            console.error(err);

            if (err instanceof AxiosError) {
                if (err.response?.status === 401) {
                    sessionStorage.removeItem('rememberMe');
                    sessionStorage.removeItem('sid');
                    sessionStorage.removeItem('profile');
                    localStorage.removeItem('rememberMe');
                    localStorage.removeItem('sid');
                    localStorage.removeItem('profile');
                    this.props.history.push(getPath('/login'));
                }
            }
        }
    }

    disconnectedPopup = () => {
        const { disconnected, allowReconnect } = this.props;

        return (
            <Dialog open={disconnected}>
                <DialogTitle>
                    <Box display={"flex"} columnGap={1}>
                        <SvgIcon><path d={mdiPowerPlugOff} /></SvgIcon>
                        <Typography className="!font['Roboto Slab'] !text-[0.8em] md:!text-[0.9em]">Bağlantı Sorunu</Typography>
                    </Box>
                </DialogTitle>

                <DialogContent>
                    <Typography className="!font['Roboto Slab'] !py-4 !text-[0.8em] md:!text-[0.9em] lg:!text-[1em]">
                        Bağlantınız koptu. Devam etmek için lütfen tekrar bağlantı kurun.
                    </Typography>
                </DialogContent>

                <DialogActions>
                    <Button className="!font['Roboto Slab'] !text-[0.8em] md:!text-[0.9em] lg:!text-[1em]"
                        variant='contained' sx={{ color: 'var(--white)' }}
                        color='error' onClick={() => logout(true, this.props.socket)}
                    >
                        Anasayfaya Dön
                    </Button>
                    {allowReconnect && (
                        <Button className="!font['Roboto Slab'] !text-[0.8em] md:!text-[0.9em] lg:!text-[1em]"
                            variant='contained' sx={{ color: 'var(--white)' }}
                            onClick={() => {
                                window.location = '/salon';
                            }}
                        >
                            Tekrar Bağlan
                        </Button>
                    )}
                </DialogActions>
            </Dialog>
        );
    }

    infoDialog = () => {
        const { infoDialog } = this.props;

        if (!infoDialog)
            return;

        return (
            <Dialog open={true}>
                <DialogTitle>
                    <Box display={"flex"} columnGap={1}>
                        <Typography>{infoDialog.title}</Typography>
                    </Box>
                </DialogTitle>

                <DialogContent>
                    <Typography>{infoDialog.message}</Typography>
                </DialogContent>

                <DialogActions>
                    <Button variant='contained' sx={{ color: 'var(--white)' }}
                        onClick={() => {
                            this.props.closeInfoDialog();
                        }}>Tamam</Button>
                </DialogActions>
            </Dialog>
        );
    }

    invitationDialog = () => {
        const { invitationDialog } = this.props;

        const reject = () => {
            this.props.setInvitationDialog(null);
        }

        const accept = () => {
            this.props.setInvitationDialog(null);
            this.props.socket.emit('join_table', { tableId: invitationDialog?.table_id, password: invitationDialog?.password });
        }

        return (
            <Dialog open={invitationDialog} onClose={reject}>
                <DialogTitle>
                    <Typography className="!font['Roboto Slab'] !text-[0.8em] md:!text-[0.9em]">
                        Masa Daveti
                    </Typography>
                </DialogTitle>
                <DialogContent>
                    <Typography className="!font['Roboto Slab'] !py-4 !text-[0.8em] md:!text-[0.9em] lg:!text-[1em]">
                        {invitationDialog?.username} sizi Masa {invitationDialog?.table_id}'e davet ediyor.
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={reject}
                        className="!font['Roboto Slab'] !text-[0.8em] md:!text-[0.9em] lg:!text-[1em]"
                        variant="contained"
                        sx={{ color: 'var(--white)' }} color='error'
                    >
                        Reddet
                    </Button>
                    <Button onClick={accept} variant="contained" autoFocus
                        className="!font['Roboto Slab'] !text-[0.8em] md:!text-[0.9em] lg:!text-[1em]"
                        sx={{ color: 'var(--white)' }}
                    >
                        Kabul Et
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }

    leaveDialog = () => {
        const { leavingTable, selectedTab } = this.props;

        const onLeft = () => {
            this.props.setLeavingTable(null);
            this.props.history.push(getPath('/salon'));

            this.props.socket.emit('leave_table', { tableId: leavingTable });
        }

        const onClose = () => {
            this.props.setLeavingTable(null);
        }

        return (
            <Dialog open={Boolean(leavingTable)} onClose={onClose}>
                <DialogTitle>
                    <Typography className="!font['Roboto Slab'] !text-xs sm:!text-sm md:!text-base">
                        Masadan Ayrıl
                    </Typography>
                </DialogTitle>
                <DialogContent>
                    <Typography className="!font['Roboto Slab'] !py-4 !text-xs sm:!text-sm md:!text-base">
                        Masadan ayrılmak istediğinize emin misiniz?
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onClose}
                        className="!font['Roboto Slab'] !text-xs sm:!text-sm md:!text-base"
                        variant="contained"
                        sx={{ color: 'var(--white)' }} color='error'
                    >
                        Vazgeç
                    </Button>
                    <Button onClick={onLeft} variant="contained" autoFocus
                        className="!font['Roboto Slab'] !text-xs sm:!text-sm md:!text-base"
                        sx={{ color: 'var(--white)' }}
                    >
                        Ayrıl
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }

    reportPlayerDialog = () => {
        const { reportDialog } = this.props;

        const close = () => {
            this.props.setReportDialog({ active: false });
            this.setState({ reportDialog: null });
        };

        const doSendReport = async () => {
            const { reportReasonText, reportReason, reportDescription } = this.state.reportDialog;
            const { reportedMessages, room, pm, reportedUserId } = reportDialog;

            try {
                let body = {
                    reported_user_id: reportedUserId,
                    report_reason: reportReasonText ? reportReasonText : reportReason,
                    report_description: reportDescription,
                };

                if (room && reportedMessages.length > 0) {
                    body.room = room;
                    body.start_date = reportedMessages[0].ts;
                    body.end_date = reportedMessages[reportedMessages.length - 1].ts;
                    body.is_pm = pm;
                }

                await AxiosClient().post(`${process.env.REACT_APP_API_URL}/v1/user/report.user`, body);

                this.props.setNotification({ message: 'Şikayet başarıyla gönderildi.', severity: GlobalTypes.NOTIFY_SUCC });

                close();
            }
            catch (e) {
                console.error(e);
                let message = ERROR_CODES[2];

                if (e instanceof AxiosError) {
                    const code = e.response?.data?.error?.code;

                    if (code in ERROR_CODES) {
                        message = ERROR_CODES[code];
                    } else {
                        message = e.message;
                    }
                }
                this.props.setNotification({ message: message, severity: GlobalTypes.NOTIFY_ERR, traceId: e.response?.data?.trace_id });
            }
            finally {
                close();
            }
        }

        return (
            <Dialog open={reportDialog?.active} onClose={close}>
                <DialogTitle>Kullanıcıyı Şikayet Et</DialogTitle>
                <DialogContent sx={{ width: "400px" }}>
                    <Box className="pt-4 gap-x-2">
                        <FormControl fullWidth>
                            <FormLabel>Şikayet Nedeni</FormLabel>
                            <Select
                                value={this.state.reportDialog?.reportReason}
                                onChange={e => { this.setState({ reportDialog: { ...this.state.reportDialog, reportReason: e.target.value, reportReasonText: "" } }) }}
                                displayEmpty
                                variant="outlined"
                                renderValue={(value) => {
                                    if (!value) {
                                        return <Typography color="textSecondary">Lütfen Seçim Yapınız</Typography>
                                    }
                                    return value;
                                }}
                            >
                                {reportReasonList.map((reason, index) => <MenuItem key={index} value={reason}>{reason}</MenuItem>)}
                            </Select>
                        </FormControl>

                        {this.state.reportDialog?.reportReason === reportReasonList[reportReasonList.length - 1] && <FormControl sx={{ mt: 2 }} fullWidth>
                            <FormLabel>Diğer</FormLabel>
                            <TextField
                                value={this.state.reportDialog?.reportReasonText}
                                onChange={e => this.setState({ reportDialog: { ...this.state.reportDialog, reportReasonText: e.target.value } })}
                                variant="outlined"
                            />
                        </FormControl>}

                        <FormControl sx={{ mt: 2 }} fullWidth>
                            <FormLabel>Açıklama</FormLabel>
                            <TextField
                                value={this.state.reportDialog?.reportDescription}
                                onChange={e => this.setState({ reportDialog: { ...this.state.reportDialog, reportDescription: e.target.value } })}
                                multiline
                                rows={4}
                                variant="outlined"
                            />
                        </FormControl>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={close} variant="contained" color="error">İptal</Button>
                    <Button onClick={doSendReport} variant="contained" color="primary"
                        className="!text-white" disabled={!this.state.reportDialog?.reportReason || !this.state.reportDialog?.reportDescription}
                    >
                        Gönder
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }

    render() {
        return (
            <>
                <LoadingScreen />
                <RejoinTable />
                <ErrorDialog />

                {this.disconnectedPopup()}
                {this.infoDialog()}
                {this.invitationDialog()}
                {this.leaveDialog()}
                {this.reportPlayerDialog()}
                {this.props.children}
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        socket: state.app.global.socket,
        disconnected: state.app.global.disconnected,
        allowReconnect: state.app.global.allowReconnect,
        invitationDialog: state.app.global.invitationDialog,
        leavingTable: state.app.global.leavingTable,
        reportDialog: state.app.global.reportDialog,
        infoDialog: state.app.global.infoDialog,
    };
};

Protected.propTypes = {
};

WebSocket.prototype.reconnect = function (callback) {
    try {
        const rememberMe = sessionStorage.getItem('rememberMe') === 'true';
        let url = `${process.env.REACT_APP_CORE_URL}`;

        if (rememberMe) {
            url += '?sid=' + localStorage.getItem('sid');
        }
        else {
            url += '?sid=' + sessionStorage.getItem('sid');
        }

        const ws = new WebSocket(url);
        ws.onopen = () => {
            ws.login = true;
            ws.reconnected = true;
            callback(ws);
        }
    }
    catch (e) {
        console.error(e);
        setTimeout(() => {
            this.reconnect(callback);
        }, 1000);
    }
}


WebSocket.prototype.emit = function (event, data) {
    try {
        this.send(JSON.stringify({ e: event, d: data }));
    }
    catch (e) {
        console.error(e);
    }
}

export default connect(mapStateToProps, {
    setSocketHandler, setRoomConfig, setRolePermission, selectTab,
    setLoadingState, setRoomMaintenance, setDisconnected, setInvitationDialog,
    setLeavingTable, closeInfoDialog, setNotification, setReportDialog,
})(Protected);