import React from "react";
import { connect } from "react-redux";
import { withStyles } from "@mui/styles";
import PropTypes from "prop-types";
import { Box } from "@mui/system";
import { mod } from "../../utils/math";
import Pot from "./Pot";
import { Avatar, Button, SvgIcon, Typography } from "@mui/material";
import { mdiRobot } from "@mdi/js";
import clsx from "clsx";
import PiecesLeft from "./PiecesLeft";
import TurnTimer from "./TurnTimer";
import Tile from "../../models/tile";
import TableUser from "./TableUser";


const styles = theme => ({
});

class SecondRow extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        }

    }

    componentDidMount() {
    }

    componentDidUpdate(prevProps) {
    }

    render() {
        const {
            isLandscape, user, selectedTableUsers, selectedTableSpectators,
            selectedTableTurn: turn,
            selectedTableIndicator: indicator, selectedTableInfoAt: infoAt,
        } = this.props;

        let myIndex = selectedTableUsers?.findIndex(u => u?.id === user.id);
        if (myIndex === -1) {
            myIndex = selectedTableSpectators?.findIndex(s => s === user.id);
        }

        if (myIndex === -1) {
            return null;
        }

        const myTurn = turn === myIndex;

        const usersById = this.props.store.getState().app.lobby.usersById;
        const leftUser = selectedTableUsers?.[mod(myIndex - 1, 4)];
        const rightUser = selectedTableUsers?.[mod(myIndex + 1, 4)];

        const leftLobbyUser = leftUser?.bot || !(leftUser?.id in usersById) ? leftUser : usersById[leftUser?.id];
        const rightLobbyUser = rightUser?.bot || !(rightUser?.id in usersById) ? rightUser : usersById[rightUser?.id];

        return (
            <Box width={"100%"} display={"flex"} justifyContent={"space-between"}
                className={clsx("lg:p-0", isLandscape ? "" : "p-2")}
            >
                <Box className="flex flex-1 justify-center xl:justify-end">
                    <TableUser
                        index={mod(myIndex - 1, selectedTableUsers?.length)}
                        user={leftLobbyUser}
                        addBot={this.props.addBot}
                        userContextMenuHandler={this.props.userContextMenuHandler}
                        onPrivateMessage={this.props.onPrivateMessage}
                    />
                </Box>

                <Box position={"relative"} display={"flex"} justifyContent={"center"}
                    className="w-fit flex-1"
                >
                    <Box className="hidden min-[312px]:flex flex-1 justify-center items-center">

                        <Box position={"relative"}>
                            <Box className={clsx(
                                "absolute flex justify-center items-center h-full top-0",
                                "right-0",
                                isLandscape ? `right-[calc(55.2vw/13+12px)]` : `min-[312px]:right-[calc(92vw/13+12px)]`,
                                `md:right-[calc(55.2vw/13+12px)]`,
                                `lg:right-[42px]`,
                                `xl:right-[56px]`,
                            )}>
                                <TurnTimer />
                            </Box>

                            <Tile value={indicator ?? 0} key={`indicator_${infoAt}`} index={29}
                                isLandscape={isLandscape}
                            />
                            <Box className={clsx(
                                `w-0 left-0`,
                                "absolute top-0",
                                isLandscape ? `left-[calc(55.2vw/13)]` : `min-[312px]:left-[calc(92vw/13)]`,
                                `md:left-[calc(55.2vw/13)]`,
                                `lg:left-[34px]`,
                                `xl:left-[45px]`,
                                isLandscape ? `w-[calc(55.2vw/13)]` : `min-[312px]:w-[calc(92vw/13)]`,
                                `md:w-[calc(55.2vw/13)]`,
                                `lg:w-[34px]`,
                                `xl:w-[45px]`,
                            )}>
                                <PiecesLeft
                                    groundRef={this.props.groundRef}
                                    isLandscape={isLandscape}
                                    onDragStart={myTurn ? this.props.onDragStart : null}
                                    onDragEnd={myTurn ? this.props.onDragEnd : null}
                                    onMovePiece={myTurn ? this.props.onMovePiece : null}
                                    onDoubleClick={myTurn ? this.props.onDoubleClick : null}
                                />
                            </Box>
                        </Box>
                    </Box>
                </Box>

                <Box className="flex flex-1 justify-center xl:justify-start">
                    <TableUser
                        index={mod(myIndex + 1, selectedTableUsers?.length)}
                        user={rightLobbyUser}
                        addBot={this.props.addBot}
                        userContextMenuHandler={this.props.userContextMenuHandler}
                        onPrivateMessage={this.props.onPrivateMessage}
                    />
                </Box>

            </Box>
        );
    }
}

SecondRow.propTypes = {
    classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
    const selectedTable = state.app.global.selectedTable;

    return {
        user: state.app.global.user,
        selectedTableTurn: state.app.table.tables[selectedTable]?.turn,
        selectedTableIndicator: state.app.table.tables[selectedTable]?.indicator,
        selectedTableInfoAt: state.app.table.tables[selectedTable]?.infoAt,
        selectedTableUsers: state.app.lobby.tables[selectedTable]?.users,
        selectedTableSpectators: state.app.lobby.tables[selectedTable]?.spectators,
    }
};

export default withStyles(styles)(connect(mapStateToProps, {
})(SecondRow));
