import React from "react";
import { connect } from "react-redux";
import { withStyles } from "@mui/styles";
import PropTypes from "prop-types";
import { Box } from "@mui/system";
import { mod } from "../../utils/math";
import Pot from "./Pot";
import clsx from "clsx";
import TableUser from "./TableUser";


const styles = theme => ({
});

class FirstRow extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        }

    }

    componentDidMount() {
    }

    componentDidUpdate(prevProps) {
    }

    render() {
        const {
            isLandscape, table, user,
            selectedTableUsers, selectedTableSpectators,
        } = this.props;

        let myIndex = selectedTableUsers?.findIndex(u => u?.id === user.id);
        if (myIndex === -1) {
            myIndex = selectedTableSpectators?.findIndex(s => s === user.id);
        }

        if (myIndex === -1) {
            return null;
        }

        const usersById = this.props.store.getState().app.lobby.usersById;
        const tableUser = selectedTableUsers?.[mod(myIndex + 2, 4)];
        const lobbyUser = tableUser?.bot || !(tableUser?.id in usersById) ? tableUser : usersById[tableUser?.id];

        return (
            <Box width={"100%"} display={"flex"} justifyContent={"space-around"}
                className={clsx("lg:p-0", isLandscape ? "" : "p-2")}
            >
                <Pot
                    className="justify-center xl:justify-end"
                    isLandscape={isLandscape}
                    potIndex={mod(myIndex - 2, selectedTableUsers?.length)}
                    tileIndex={31}
                    onInspect={(e, pot) => this.props.onInspect(pot)}
                />

                <Box position={"relative"} display={"flex"} justifyContent={"center"}
                    className="w-fit flex-1"
                >
                    <TableUser
                        index={mod(myIndex + 2, selectedTableUsers?.length)}
                        user={lobbyUser}
                        addBot={this.props.addBot}
                        userContextMenuHandler={this.props.userContextMenuHandler}
                        onPrivateMessage={this.props.onPrivateMessage}
                    />
                </Box>

                <Pot
                    className="justify-center xl:justify-start"
                    isLandscape={isLandscape}
                    potIndex={mod(myIndex + 1, selectedTableUsers?.length)}
                    tileIndex={30}
                    onInspect={(e, pot) => this.props.onInspect(pot)}
                />
            </Box>
        );
    }
}

FirstRow.propTypes = {
    classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
    const selectedTable = state.app.global.selectedTable;

    return {
        selectedTable,
        user: state.app.global.user,
        selectedTableUsers: state.app.lobby.tables[selectedTable]?.users,
        selectedTableSpectators: state.app.lobby.tables[selectedTable]?.spectators,
    }
};

export default withStyles(styles)(connect(mapStateToProps, {
})(FirstRow));
