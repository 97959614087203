import React from "react";
import { connect } from "react-redux";
import { withStyles } from "@mui/styles";
import PropTypes from "prop-types";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, IconButton, TableBody, TableCell, TableContainer, TableRow, Tooltip, Typography } from "@mui/material";
import { Close } from "@mui/icons-material";
import { setNotification } from "../../actions/global";
import axios from "axios";
import { GlobalTypes } from "../../action_types";
import { AxiosClient } from "../../global/axios";
import UserAvatar from "../Lobby/UserAvatar";
import { getPath } from "../../global/route";
import clsx from "clsx";
import { loadedResources } from "../../global/resources";

const humanize = require('humanize-number');

const styles = theme => ({
});

class Top10 extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            topUsers: [],
        }
    }

    onClose = () => {
        this.setState({});
        this.props.onClose();
    }

    componentDidMount() {
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.open !== this.props.open && this.props.open) {
            this.getTop10Users();
        }
    }

    getTop10Users = async () => {
        try {
            const resp = await AxiosClient().get(`${process.env.REACT_APP_API_URL}/v1/user/list.top?size=10`);
            this.setState({ topUsers: resp.data ?? [] });

        } catch (err) {
            console.error(err);
            this.props.setNotification({ message: 'Bir hata oluştu. Lütfen tekrar deneyiniz.', severity: GlobalTypes.NOTIFY_ERR });
        }
    }

    render() {
        const { classes } = this.props;

        return (
            <Dialog
                maxWidth={"xs"}
                fullWidth
                open={this.props.open}
            >
                <DialogTitle>
                    <Box padding={0} display={"flex"} justifyContent={"space-between"}>
                        <Typography className={"!text-sm sm:!text-base md:!text-lg"}>
                            Top 10
                        </Typography>

                        <Tooltip title="Kapat" placement="top">
                            <IconButton size="small" onClick={this.onClose} sx={{ backgroundColor: '#624799 !important' }} tabIndex={-1}>
                                <Close htmlColor="var(--white)" fontSize="small" />
                            </IconButton>
                        </Tooltip>
                    </Box>
                </DialogTitle>

                <DialogContent sx={{ p: 0 }}>
                    <Box className="flex flex-col items-start justify-center min-h-64 !p-4">
                        {this.state.topUsers.length === 0 ? (null) : (
                            this.state.topUsers.map((user, index) => {
                                let outlineColor = null;
                                if (user.gender === 'male') outlineColor = "#39ccff";
                                else if (user.gender === 'female') outlineColor = "#ff79fc";

                                return (
                                    <Box className="flex w-full gap-x-4 py-2 border-b-[1px] border-b-gray-300 last:border-b-0">

                                        <Typography className={"self-center min-w-6 text-right !font-bold !text-sm sm:!text-base md:!text-lg"}>{index + 1}.</Typography>
                                        <UserAvatar user={user}
                                            sx={{ boxShadow: !!outlineColor ? `0 0 0 2px ${outlineColor}` : null }}
                                        />

                                        <Box className="flex flex-col justify-between">
                                            <Box className="flex gap-x-1 items-center">
                                                {user.icon ?
                                                    <img src={loadedResources[`${process.env.REACT_APP_CDN_URL}/web/img/${user.icon}`]} className="w-[16px] h-[16px] sm:w-[20px] sm:h-[20px] md:w-[24px] md:h-[24px]" /> :
                                                    (user.premium ?
                                                        <img src={loadedResources[`${process.env.REACT_APP_CDN_URL}/web/img/vip.png`]} className="w-[16px] h-[16px] sm:w-[20px] sm:h-[20px] md:w-[24px] md:h-[24px]" /> :
                                                        null
                                                    )
                                                }

                                                <Box className={clsx(
                                                    "!text-xs sm:!text-sm md:!text-base !font-bold whitespace-nowrap",
                                                    user?.username_color ? `NickRenk${user.username_color}` : 'text-black'
                                                )}
                                                >
                                                    {user.username}
                                                </Box>
                                            </Box>

                                            <Box className="flex items-center gap-x-[2px] md:gap-x-2">
                                                <Box className={"text-[0.9em] sm:text-[0.95em] md:text-[1em]"} sx={{ color: 'black' }}>
                                                    {humanize(user?.points ?? 0, { delimiter: '.', separator: ',' })}
                                                </Box>
                                            </Box>
                                        </Box>
                                    </Box>
                                );
                            })
                        )}
                    </Box>
                </DialogContent>

                <DialogActions sx={{ p: 2 }}>
                    <Button variant="contained" disabled={false} onClick={() => this.onClose()}
                        disableFocusRipple disableRipple disableTouchRipple color="error"
                        sx={{
                            color: '#FFFFFF',
                        }}
                        className="!text-xs sm:!text-sm md:!text-base"
                    >
                        Kapat
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

Top10.propTypes = {
    classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
    return {
    }
};

export default withStyles(styles)(connect(mapStateToProps, {
    setNotification,
})(Top10));
