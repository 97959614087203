import React from "react";
import { connect } from "react-redux";
import { withStyles } from "@mui/styles";
import PropTypes from "prop-types";
import { Avatar, Box, Button, Chip, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, Icon, IconButton, MenuItem, Popover, SvgIcon, Tooltip, Typography } from "@mui/material";
import {
    Settings as SettingsIcon,
    Logout as LogoutIcon,
    Notifications as NotificationIcon,
    Close as CloseIcon,
    Fullscreen as FullscreenIcon,
    FullscreenExit as FullscreenExitIcon,
    Palette,
} from '@mui/icons-material';
import { mdiChevronDown, mdiChevronLeft, mdiChevronRight, mdiChevronUp, mdiMenu, mdiTrashCan } from "@mdi/js";
import { logout } from "../../global/connection";
import { selectTab, removeChatTab, setNotification, setLeavingTable, setAdminPath, setAdminTab, setGeneralSettings } from "../../actions/global";
import { mod } from "../../utils/math";
import UploadPhoto from "./UploadPhoto";
import { GlobalTypes } from "../../action_types";
import clsx from "clsx";
import Radio from "./Radio";
import { perms } from "../../global/permission";
import NotificationSettings from "./NotificationSettings";
import ChangePassword from "./ChangePassword";
import GeneralSettings from "./GeneralSettings";
import PlanedEvents from "./PlanedEvents";
import EventRules from "./EventRules";
import JoinTournament from "./JoinTournament";
import TournamentInfo from "./TournamentInfo";
import LastRandomScores from "./LastRandomScores";
import NickChangeReguest from "./NickChangeReguest";
import NickChangeRequestTracker from "./NickChangeRequestTracker";
import TermsOfUse from "./TermsOfUse";
import PrivacyPolicy from "./PrivacyPolicy";
import UpdateProfile from "./UpdateProfile";
import UserLatency from "./UserLatency";
import EditUserColor from "../Admin/UserSettings/EditUserColor";
import { AxiosClient } from "../../global/axios";
import { getPath } from "../../global/route";
import { generateRequestId } from "../../global/session";
import UserAvatar from "../Lobby/UserAvatar";
import Top10 from "./Top10";
import UpdateDescription from "./UpdateDescription";
import { tabs } from "../Admin/UserSettings";
import { loadedResources } from "../../global/resources";
import DeleteAccount from "./DeleteAccount";


const styles = theme => ({
    header: {
        backgroundImage: "linear-gradient(to right, #624799 50%, #1D9AB6)",
        borderBottom: '1px solid #a097b3',
        alignItems: 'center',
        columnGap: 0,
    },
    subText: {
        fontWeight: '400 !important',
        color: 'var(--white)',
    },
});

class Header extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            profileMenuAnchor: null,
            genericMenuAnchor: null,
            settingsMenuAnchor: null,
            notificationsMenuAnchor: null,
            uploadingPhoto: false,
            termsOfUse: false,
            privacyPolicy: false,
            notificationSettings: false,
            changePassword: false,
            generalSettings: false,
            planedEvents: false,
            eventRules: false,
            lastRandomScores: false,
            joinTournament: false,
            tournamentInfo: false,
            nickChangeRequest: false,
            nickChangeRequestTracker: false,
            fullscreen: false,
            top10Preview: false,
            updateDescription: false,
        }

        this.tabHandlers = {};
    }

    componentDidMount() {
        let settings = localStorage.getItem('general_settings');
        if (!settings) {
            settings = JSON.stringify({
                in_game_double_click_closed: false,
                play_radio_at_startup: true,
                msg_time: false,
                max_message_count: 100,
            });

            localStorage.setItem('general_settings', settings);
        }

        settings = JSON.parse(settings);

        this.props.setGeneralSettings(settings);
    }

    componentDidUpdate(prevProps) {
    }

    handleMenuClose = () => {
        this.setState({
            profileMenuAnchor: null, genericMenuAnchor: null,
            settingsMenuAnchor: null, notificationsMenuAnchor: null
        });
    }

    handleTermsOfUse = () => {
        this.setState({ termsOfUse: true });
        this.handleMenuClose();
    }

    handlePrivacyPolicy = () => {
        this.setState({ privacyPolicy: true });
        this.handleMenuClose();
    }

    handleNotificationSettings = () => {
        this.setState({ notificationSettings: true });
        this.handleMenuClose();
    }

    closeNotificationSettings = () => {
        this.setState({ notificationSettings: false });
    }

    handleChangePassword = () => {
        this.setState({ changePassword: true });
        this.handleMenuClose();
    }

    handleProfileUpdate = () => {
        this.setState({ updateProfile: true });
        this.handleMenuClose();
    }

    handleProfileDescriptionUpdate = () => {
        this.setState({ updateDescription: true });
        this.handleMenuClose();
    }

    deleteAccount = () => {
        this.setState({ deleteAccount: true });
        this.handleMenuClose();
    }

    closeProfileUpdate = () => {
        this.setState({ updateProfile: false });
    }

    closeChangePassword = () => {
        this.setState({ changePassword: false });
    }

    handleGeneralSettings = () => {
        this.setState({ generalSettings: true });
        this.handleMenuClose();
    }

    closeGeneralSettings = () => {
        this.setState({ generalSettings: false });
    }

    closeProfileDescriptionUpdate = () => {
        this.setState({ updateDescription: false });
    }

    closeDeleteAccount = () => {
        this.setState({ deleteAccount: false });
    }

    handlePlanedEvents = () => {
        this.setState({ planedEvents: true });
        this.handleMenuClose();
    }

    closePlanedEvents = () => {
        this.setState({ planedEvents: false });
    }

    handleEventRules = () => {
        this.setState({ eventRules: true });
        this.handleMenuClose();
    }

    closeEventRules = () => {
        this.setState({ eventRules: false });
    }

    handleLastRandomScores = () => {
        this.setState({ lastRandomScores: true });
        this.handleMenuClose();
    }

    closeLastRandomScores = () => {
        this.setState({ lastRandomScores: false });
    }

    handleJoinTournament = () => {
        this.setState({ joinTournament: true });
        this.handleMenuClose();
    }

    closeJoinTournament = () => {
        this.setState({ joinTournament: false });
    }

    handleTournamentInfo = () => {
        this.setState({ tournamentInfo: true });
        this.handleMenuClose();
    }

    closeTournamentInfo = () => {
        this.setState({ tournamentInfo: false });
    }

    handleNickChangeRequest = () => {
        this.setState({ nickChangeRequest: true });
        this.handleMenuClose();
    }

    closeNickChangeRequest = () => {
        this.setState({ nickChangeRequest: false });
    }

    handleNickChangeRequestTracker = () => {
        this.setState({ nickChangeRequestTracker: true });
        this.handleMenuClose();
    }

    closeNickChangeRequestTracker = () => {
        this.setState({ nickChangeRequestTracker: false });
    }

    /**
     * 
     * @param {number} d index offset 
     */
    switchTab = (d) => {
        const { selectedTab, tableIds } = this.props;
        const profile = this.getProfile();

        const arr = ['salon'];
        if (profile?.perm > 0) {
            arr.push('admin');
        }

        if (profile?.perm >= perms.PermCmdLogsOps) {
            arr.push('log');
        }

        for (let i = 0; i < tableIds.length; i++) {
            arr.push(`table_${tableIds[i]}`);
        }

        arr.push(...this.props.chatTabs.map(t => t.id));

        let index = arr.indexOf(selectedTab);
        index = mod(index + d, arr.length);

        const id = arr[index];
        this.props.selectTab(id);
        this.tabHandlers[id]?.();
    }

    setStatus = (status) => {
        const { socket } = this.props;
        socket.emit('set_status', { status });
        this.handleMenuClose();
    }

    openUploadPhoto = () => {
        this.setState({ uploadingPhoto: true });
        this.handleMenuClose();
    }

    closeUploadPhoto = () => {
        this.setState({ uploadingPhoto: false });
    }

    profilePopover = () => {
        const { classes, user } = this.props;

        return (
            <Popover open={Boolean(this.state.profileMenuAnchor)} anchorEl={this.state.profileMenuAnchor}
                onClose={e => this.setState({ profileMenuAnchor: null })} marginThreshold={0}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
            >
                <Box display={"flex"} className="gap-x-1 md:gap-x-2 pl-2 lg:gap-x-4 lg:pl-4 h-[3rem] md:h-[3.8rem]"
                    sx={{ cursor: 'pointer' }} bgcolor={"#FFFFFF"}
                    onClick={e => this.setState({ profileMenuAnchor: null })}
                >
                    <Box position={"relative"} margin={"auto"}>
                        {/** Avatar */}
                        <UserAvatar user={user} />

                        {/** Status Color */}
                        {(() => {
                            const colors = ["", "#30B26C", "#DA4E4E", "#EFAE2F"];
                            const status = Math.abs(user?.status ?? 1);

                            return (
                                <div style={{
                                    borderRadius: '50%', position: 'absolute',
                                    backgroundColor: colors[status] ?? colors[0],
                                    border: '2px solid #FFFFFF'
                                }}
                                    className={clsx(
                                        "!w-[0.6em] !h-[0.6em] sm:!w-[0.7em] sm:!h-[0.7em] md:!w-[0.8em] md:!h-[0.8em] lg:!w-[0.9em] lg:!h-[0.9em]",
                                        "bottom-[-0.2em] left-[-0.2em]"
                                    )}
                                />
                            );
                        })()}
                    </Box>

                    <Box display={"flex"} flexDirection={"column"} justifyContent={"center"}>
                        <Typography className={clsx("!text-xs text-white sm:!text-sm md:!text-base")} sx={{ color: '#57549D' }}>{user?.username ?? '-'}</Typography>
                        <UserLatency className={"!text-[#57549D]"} />
                    </Box>

                    <Box margin={"auto"}>
                        <SvgIcon htmlColor="#a097b3" sx={{ margin: 'auto', fontSize: '1.8rem' }}>
                            <path d={mdiChevronUp} />
                        </SvgIcon>
                    </Box>
                </Box>

                {/*
                <Divider />
                <MenuItem onClick={this.openUploadPhoto}>
                    <Typography className="!text-xs sm:!text-sm md:!text-base">
                        Profil Fotoğrafım
                    </Typography>
                </MenuItem>
                */}
                <Divider />
                <MenuItem onClick={() => this.setStatus((user?.status ?? 1) * -1)}>
                    <Typography className="!text-xs sm:!text-sm md:!text-base">
                        {user?.status <= 0 ? 'Durumumu Göster' : 'Durumumu Gizle'}
                    </Typography>
                </MenuItem>
                <Divider />
                <MenuItem onClick={() => this.setStatus(user.status <= 0 ? -1 : 1)}>
                    <Box className="flex flex-1 justify-between gap-x-2">
                        <Typography className="!text-xs sm:!text-sm md:!text-base">
                            <span>Durum Çevrimiçi</span>
                        </Typography>
                        <div style={{ borderRadius: '50%', width: 10, height: 10, backgroundColor: '#30B26C', margin: 'auto 0' }} />
                    </Box>
                </MenuItem>
                <Divider />
                <MenuItem onClick={() => this.setStatus(user.status <= 0 ? -2 : 2)}>
                    <Box className="flex flex-1 justify-between gap-x-2">
                        <Typography className="!text-xs sm:!text-sm md:!text-base">
                            <span>Durum Meşgul</span>
                        </Typography>
                        <div style={{ borderRadius: '50%', width: 10, height: 10, backgroundColor: '#DA4E4E', margin: 'auto 0' }} />
                    </Box>
                </MenuItem>
                <Divider />
                <MenuItem onClick={() => this.setStatus(user.status <= 0 ? -3 : 3)}>
                    <Box className="flex flex-1 justify-between gap-x-2">
                        <Typography className="!text-xs sm:!text-sm md:!text-base">
                            <span>Durum Uzakta</span>
                        </Typography>
                        <div style={{ borderRadius: '50%', width: 10, height: 10, backgroundColor: '#EFAE2F', margin: 'auto 0' }} />
                    </Box>
                </MenuItem>
                <Divider />
                <MenuItem onClick={this.handleTermsOfUse}>
                    <Typography className="!text-xs sm:!text-sm md:!text-base">
                        Kullanım Şartları
                    </Typography>
                </MenuItem>
                <Divider />
                <MenuItem onClick={this.handlePrivacyPolicy}>
                    <Typography className="!text-xs sm:!text-sm md:!text-base">
                        Gizlilik Politikası
                    </Typography>
                </MenuItem>

            </Popover>
        );
    }

    genericMenuPopover = () => {
        const { classes } = this.props;

        return (
            <Popover open={Boolean(this.state.genericMenuAnchor)} anchorEl={this.state.genericMenuAnchor}
                onClose={e => this.setState({ genericMenuAnchor: null })} marginThreshold={0}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
            >
                <Box display={"flex"} sx={{ cursor: 'pointer' }} px={2} bgcolor={"#FFFFFF"}
                    className="h-[3rem] md:h-[3.8rem]"
                    onClick={e => this.setState({ profileMenuAnchor: null })}
                >
                    <Box sx={{ cursor: 'pointer' }} display={"flex"} columnGap={2} onClick={e => this.setState({ genericMenuAnchor: null })}>
                        <SvgIcon htmlColor="#57549D" sx={{ margin: 'auto' }} className="!text-xs sm:!text-sm md:!text-base">
                            <path d={mdiMenu} />
                        </SvgIcon>
                        <Typography className={clsx(classes.subText, "!text-xs sm:!text-sm md:!text-base")}
                            sx={{ color: '#57549D', margin: 'auto' }}
                        >
                            GENEL MENÜ
                        </Typography>
                    </Box>
                </Box>

                <MenuItem onClick={this.handlePlanedEvents}>
                    <Typography className="!text-xs sm:!text-sm md:!text-base">
                        Planlı Etkinlikler
                    </Typography>
                </MenuItem>
                <Divider />
                <MenuItem onClick={this.handleEventRules}>
                    <Typography className="!text-xs sm:!text-sm md:!text-base">
                        Etkinlik Kuralları
                    </Typography>
                </MenuItem>
                <Divider />
                <MenuItem onClick={this.handleLastRandomScores}>
                    <Typography className="!text-xs sm:!text-sm md:!text-base">
                        Son Rastgele Puanlar
                    </Typography>
                </MenuItem>
                <Divider />
                <MenuItem onClick={this.handleNickChangeRequest}>
                    <Typography className="!text-xs sm:!text-sm md:!text-base">
                        Nick Değişim Talebi
                    </Typography>
                </MenuItem>
                <Divider />
                <MenuItem onClick={this.handleNickChangeRequestTracker}>
                    <Typography className="!text-xs sm:!text-sm md:!text-base">
                        Değişim Talebi Sorgula
                    </Typography>
                </MenuItem>
                <Divider />
                <MenuItem onClick={this.handleJoinTournament}>
                    <Typography className="!text-xs sm:!text-sm md:!text-base">
                        Turnuvaya Katıl
                    </Typography>
                </MenuItem>
                <Divider />
                <MenuItem onClick={this.handleTournamentInfo}>
                    <Typography className="!text-xs sm:!text-sm md:!text-base">
                        Turnuva Bilgileri
                    </Typography>
                </MenuItem>
            </Popover>
        );
    }

    settingsMenuPopover = () => {
        const { classes } = this.props;

        return (
            <Popover open={Boolean(this.state.settingsMenuAnchor)} anchorEl={this.state.settingsMenuAnchor}
                onClose={e => this.setState({ settingsMenuAnchor: null })} marginThreshold={0}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
            >
                <Box display={"flex"} sx={{ cursor: 'pointer' }} px={2} bgcolor={"#FFFFFF"}
                    className="h-[3rem] md:h-[3.8rem]"
                    onClick={e => this.setState({ profileMenuAnchor: null })}
                >
                    <Box sx={{ cursor: 'pointer' }} display={"flex"} columnGap={2} onClick={e => this.setState({ settingsMenuAnchor: null })}>
                        <SettingsIcon fontSize="small" htmlColor="#57549D" sx={{ margin: 'auto' }} />
                        <Typography className={clsx(classes.subText, "!text-xs sm:!text-sm md:!text-base")}
                            sx={{ color: '#57549D', margin: 'auto' }}
                        >
                            AYARLAR
                        </Typography>
                    </Box>
                </Box>

                <MenuItem onClick={this.handleGeneralSettings}>
                    <Typography className="!text-xs sm:!text-sm md:!text-base">
                        Genel Ayarlarım
                    </Typography>
                </MenuItem>
                <Divider />
                <MenuItem onClick={this.handleNotificationSettings}>
                    <Typography className="!text-xs sm:!text-sm md:!text-base">
                        Bildirim Ayarlarım
                    </Typography>
                </MenuItem>
                <Divider />
                <MenuItem onClick={this.openUploadPhoto}>
                    <Typography className="!text-xs sm:!text-sm md:!text-base">
                        Avatar İşlemlerim
                    </Typography>
                </MenuItem>
                <Divider />
                <MenuItem onClick={this.handleChangePassword}>
                    <Typography className="!text-xs sm:!text-sm md:!text-base">
                        Şifremi Değiştir
                    </Typography>
                </MenuItem>
                <Divider />
                <MenuItem onClick={this.handleProfileUpdate}>
                    <Typography className="!text-xs sm:!text-sm md:!text-base">
                        Profil Bilgilerim
                    </Typography>
                </MenuItem>
                <Divider />
                <MenuItem onClick={this.handleProfileDescriptionUpdate}>
                    <Typography className="!text-xs sm:!text-sm md:!text-base">
                        Hakkımda Düzenle
                    </Typography>
                </MenuItem>
                <Divider />
                <MenuItem onClick={this.deleteAccount}>
                    <Typography className="!text-xs !text-red-500 sm:!text-sm md:!text-base">
                        Hesabı Sil
                    </Typography>
                </MenuItem>
                <Divider />
            </Popover>
        );
    }

    openNotificationsMenu = (e) => {
        this.setState({ notificationsMenuAnchor: e.currentTarget });

        setTimeout(() => {
            const { socket } = this.props;
            socket.emit('read_notifications');
        }, 500);
    }

    openNotificationDetails = (notification) => {
        const { socket } = this.props;

        if (notification.type === 'friend_request') {
            return;
        }

        if (!notification.read_at) {
            socket.emit('read_notification', { id: notification.id });
        }
    }

    renderPlainNotification = (n) => {
        const { classes } = this.props;

        return (
            <>
                <span className={clsx(classes.subText, "!text-[0.6em] sm:!text-xs md:!text-sm")} style={{ color: '#888' }}>
                    {new Date(Date.parse(n.created_at)).toLocaleString()}
                </span>
                <span className="!text-xs sm:!text-sm md:!text-base">
                    {n.content?.title}
                </span>
            </>
        );
    }

    renderRegistrationApprovalNotification = (n) => {
        const { classes } = this.props;

        const check = async (e) => {
            await this.props.setAdminPath('/admin/user');
            await this.props.setAdminTab(tabs.APPROVAL);
            this.props.history.push(getPath('/admin/user'));
            this.setState({ notificationsMenuAnchor: null });
        }

        return (
            <Box display={"flex"} flexDirection={"column"} onClick={check}>
                <span className={clsx(classes.subText, "!text-[0.6em] sm:!text-xs md:!text-sm")} style={{ color: '#888' }}>
                    {new Date(Date.parse(n.created_at)).toLocaleString()}
                </span>
                <span className="!text-xs sm:!text-sm md:!text-base">
                    {n.content?.title}
                </span>
            </Box>
        );

    }

    renderDescriptionApprovalNotification = (n) => {
        const { classes } = this.props;

        const check = async (e) => {
            await this.props.setAdminPath('/admin/user');
            await this.props.setAdminTab(tabs.DESCRIPTION_APPROVAL);
            this.props.history.push(getPath('/admin/user'));
            this.setState({ notificationsMenuAnchor: null });
        }

        return (
            <Box display={"flex"} flexDirection={"column"} onClick={check}>
                <span className={clsx(classes.subText, "!text-[0.6em] sm:!text-xs md:!text-sm")} style={{ color: '#888' }}>
                    {new Date(Date.parse(n.created_at)).toLocaleString()}
                </span>
                <span className="!text-xs sm:!text-sm md:!text-base">
                    {n.content?.title}
                </span>
            </Box>
        );

    }

    renderAvatarApprovalNotification = (n) => {
        const { classes } = this.props;

        const check = async (e) => {
            await this.props.setAdminPath('/admin/user');
            await this.props.setAdminTab(tabs.AVATAR_APPROVAL);
            this.props.history.push(getPath('/admin/user'));
            this.setState({ notificationsMenuAnchor: null });
        }

        return (
            <Box display={"flex"} flexDirection={"column"} onClick={check}>
                <span className={clsx(classes.subText, "!text-[0.6em] sm:!text-xs md:!text-sm")} style={{ color: '#888' }}>
                    {new Date(Date.parse(n.created_at)).toLocaleString()}
                </span>
                <span className="!text-xs sm:!text-sm md:!text-base">
                    {n.content?.title}
                </span>
            </Box>
        );
    }

    renderFriendRequestNotification = (n) => {
        const { classes, socket } = this.props;

        const reject = async (e) => {
            if (n.replied_at) return;

            try {
                await AxiosClient().post(`${process.env.REACT_APP_API_URL}/v1/user/friend.request`, {
                    id: n.id,
                    status: -1
                });

                this.props.setNotification({ message: 'Arkadaşlık isteği reddedildi.', severity: GlobalTypes.NOTIFY_SUCC });
            }
            catch (e) {
                console.error(e);
                this.props.setNotification({ message: 'Bir hata oluştu. Lütfen tekrar deneyiniz.', severity: GlobalTypes.NOTIFY_ERR });
            }
        }

        const accept = async (e) => {
            if (n.replied_at) return;

            try {
                await AxiosClient().post(`${process.env.REACT_APP_API_URL}/v1/user/friend.request`, {
                    id: n.id,
                    status: 1
                });

                this.props.setNotification({ message: 'Arkadaşlık isteği kabul edildi.', severity: GlobalTypes.NOTIFY_SUCC });
            }
            catch (e) {
                console.error(e);
                this.props.setNotification({ message: 'Bir hata oluştu. Lütfen tekrar deneyiniz.', severity: GlobalTypes.NOTIFY_ERR });
            }
        }

        return (
            <Box display={"flex"} flexDirection={"column"} >
                <span className={clsx(classes.subText, "!text-[0.6em] sm:!text-xs md:!text-sm")} style={{ color: '#888' }}>
                    {new Date(Date.parse(n.created_at)).toLocaleString()}
                </span>
                <span className="!text-xs sm:!text-sm md:!text-base">
                    {n.content?.title}
                </span>

                <Box justifyContent={"start"} columnGap={1} mt={1}
                    display={n.replied_at ? "none" : "flex"}
                >
                    <Button variant="contained" disableFocusRipple disableRipple disableTouchRipple
                        className="!text-xs sm:!text-sm md:!text-base"
                        sx={{ color: 'var(--white)' }} color='error'
                        onClick={reject}
                    >
                        Reddet
                    </Button>
                    <Button variant="contained" color="primary" disableFocusRipple disableRipple disableTouchRipple
                        className="!text-xs sm:!text-sm md:!text-base"
                        sx={{ color: 'var(--white)' }}
                        onClick={accept}
                    >
                        Kabul Et
                    </Button>
                </Box>
            </Box>
        );
    }

    renderUserReportNotification = (n) => {
        const { classes } = this.props;

        const check = async (e) => {
            await this.props.setAdminPath('/admin/user');
            await this.props.setAdminTab(tabs.REPORTED_USERS);
            this.props.history.push(getPath('/admin/user'));
            this.setState({ notificationsMenuAnchor: null });
        }

        return (
            <Box display={"flex"} flexDirection={"column"} onClick={check}>
                <span className={clsx(classes.subText, "!text-[0.6em] sm:!text-xs md:!text-sm")} style={{ color: '#888' }}>
                    {new Date(Date.parse(n.created_at)).toLocaleString()}
                </span>
                <span className="!text-xs sm:!text-sm md:!text-base">
                    {n.content?.title}
                </span>
            </Box>
        );
    }

    renderTableReportNotification = (n) => {
        const { classes, socket } = this.props;

        const check = async (e) => {
            const id = n.content?.table_id;
            socket.emit('spectate_table', { tableId: id });
            //this.props.history.push(getPath(`/masa/${id}`);
            //this.props.selectTab(`table_${id}`);
            this.setState({ notificationsMenuAnchor: null });
        }

        return (
            <Box display={"flex"} flexDirection={"column"} onClick={check}>
                <span className={clsx(classes.subText, "!text-[0.6em] sm:!text-xs md:!text-sm")} style={{ color: '#888' }}>
                    {new Date(Date.parse(n.created_at)).toLocaleString()}
                </span>
                <span className="!text-xs sm:!text-sm md:!text-base">
                    {n.content?.title}
                </span>
            </Box>
        );
    }

    renderNotification = (n) => {
        switch (n.type) {
            case 'mention':
                return this.renderPlainNotification(n);
            case 'friend_request':
                return this.renderFriendRequestNotification(n);
            case 'friend_response':
                return this.renderPlainNotification(n);
            case 'avatar_approval':
                return this.renderAvatarApprovalNotification(n);
            case 'registration_approval':
                return this.renderRegistrationApprovalNotification(n);
            case 'description_approval':
                return this.renderDescriptionApprovalNotification(n);
            case 'user_report':
                return this.renderUserReportNotification(n);
            case 'table_report':
                return this.renderTableReportNotification(n);
            default:
                return null;
        }
    }

    notificationsMenuPopover = () => {
        const { classes, notifications } = this.props;

        const onClose = () => {
            this.setState({ notificationsMenuAnchor: null });
        }

        return (
            <Popover open={Boolean(this.state.notificationsMenuAnchor)} anchorEl={this.state.notificationsMenuAnchor}
                onClose={onClose} marginThreshold={0}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
            >
                <Box display={"flex"} justifyContent={"space-between"} sx={{ cursor: 'pointer' }} px={2}
                    className="h-[3rem] md:h-[3.8rem]"
                    bgcolor={"#FFFFFF"} position={"sticky"} top={0} zIndex={10} borderBottom={"1px solid #a097b3"}
                    onClick={e => this.setState({ profileMenuAnchor: null })}
                >
                    <Box sx={{ cursor: 'pointer' }} display={"flex"} columnGap={2} onClick={e => this.setState({ notificationsMenuAnchor: null })}>
                        <NotificationIcon fontSize="small" htmlColor="#57549D" sx={{ margin: 'auto' }} />
                        <Typography className={clsx(classes.subText, "!text-xs sm:!text-sm md:!text-base")}
                            sx={{ color: '#57549D', margin: 'auto' }}
                        >
                            BİLDİRİMLER
                        </Typography>
                    </Box>

                    <Box display={"flex"} alignContent={"center"}>
                        <Tooltip title="Bildirimleri Temizle">
                            <IconButton sx={{ width: 32, height: 32, alignSelf: 'center' }}
                                onClick={() => this.props.socket.emit('clear_notifications')}
                            >
                                <SvgIcon fontSize="small" htmlColor="#57549D"><path d={mdiTrashCan} /></SvgIcon>
                            </IconButton>
                        </Tooltip>
                    </Box>
                </Box>

                <Box width={400} maxHeight={400}>
                    {notifications.length > 0 ? notifications.map((n, index) => (
                        <MenuItem key={n.id} onClick={(e) => this.openNotificationDetails(n)}
                            sx={{
                                backgroundColor: n.read_at ? 'transparent' : '#FF5757',
                                color: n.read_at ? null : 'white',
                                borderBottom: '1px solid #a097b3',
                                '&:hover': {
                                    backgroundColor: n.read_at ? 'transparent' : '#FF5757',
                                }
                            }}
                        >
                            <Box width={"100%"} display={"flex"} flexDirection={"column"}
                                sx={{ whiteSpace: 'pre-wrap', overflowWrap: 'break-word' }}
                            >
                                {this.renderNotification(n)}
                            </Box>
                        </MenuItem>
                    )) :
                        <MenuItem disabled>Henüz bildirim bulunmuyor.</MenuItem>
                    }
                </Box>

            </Popover>
        );
    }

    toggleFullscreen = () => {
        const { fullscreen } = this.state;

        if (fullscreen) {
            document.exitFullscreen?.();
        }
        else {
            document.documentElement.requestFullscreen?.();
        }

        this.setState({ fullscreen: !fullscreen });
    }

    header = () => {
        const { classes, user, notifications, isLandscape } = this.props;
        const unreadNotifications = notifications.filter(n => !n.read_at);
        const imgPath = `${process.env.REACT_APP_CDN_URL}/web/img`;

        return (
            <Box width={"100%"}
                className={clsx(
                    "h-[3rem] md:h-[3.8rem] overflow-hidden",
                    classes.header, isLandscape ? "hidden" : "flex"
                )}
                sx={{
                    overflowX: 'auto', overscrollBehavior: 'none', scrollbarWidth: 'none',
                    '&::-webkit-scrollbar': {
                        width: 0,
                        height: 0,
                    }
                }}
            >

                {this.profilePopover()}
                {this.genericMenuPopover()}
                {this.settingsMenuPopover()}
                {this.notificationsMenuPopover()}

                <Box display={"flex"} className="gap-x-1 md:gap-x-2 pl-2 lg:gap-x-4 lg:pl-4 h-[3rem] md:h-[3.8rem]"
                    sx={{ cursor: 'pointer' }}
                    onClick={e => this.setState({ profileMenuAnchor: e.currentTarget })}
                >
                    <Box position={"relative"} margin={"auto"}>
                        {/** Avatar */}
                        <UserAvatar user={user} />

                        {/** Status Color */}
                        {(() => {
                            const colors = ["", "#30B26C", "#DA4E4E", "#EFAE2F"];
                            const status = Math.abs(user?.status ?? 1);

                            return (
                                <div style={{
                                    borderRadius: '50%', position: 'absolute',
                                    backgroundColor: colors[status] ?? colors[0],
                                    border: '2px solid #FFFFFF'
                                }}
                                    className={clsx(
                                        "!w-[0.6em] !h-[0.6em] sm:!w-[0.7em] sm:!h-[0.7em] md:!w-[0.8em] md:!h-[0.8em] lg:!w-[0.9em] lg:!h-[0.9em]",
                                        "bottom-[-0.2em] left-[-0.2em]"
                                    )}
                                />
                            );
                        })()}
                    </Box>

                    <Box className="hidden md:flex flex-col justify-center">
                        <Typography className={clsx(
                            "!text-xs text-white sm:!text-sm md:!text-base",
                        )}
                            sx={{ whiteSpace: 'nowrap' }}
                        >
                            {user?.username ?? '-'}
                        </Typography>
                        <UserLatency />
                    </Box>

                    <Box margin={"auto"}>
                        <SvgIcon htmlColor="#a097b3" sx={{ margin: 'auto', fontSize: '1.8rem' }} className="!w-6">
                            <path d={mdiChevronDown} />
                        </SvgIcon>
                    </Box>
                </Box>

                <Box className="max-w-full flex flex-1 gap-x-1 md:gap-x-2 justify-between items-center h-full overflow-hidden">

                    <Box className="flex flex-1 gap-x-1 md:gap-x-2 items-center h-full overflow-x-scroll"
                        sx={{
                            overscrollBehavior: 'none',
                            '&::-webkit-scrollbar': {
                                width: '0.4em',
                                height: 0,
                            },
                        }}
                    >
                        <Box className="flex gap-x-1 md:gap-x-2 items-center h-full pl-1 md:pl-2 py-1.5 md:py-3">
                            <Tooltip title="Çıkış Yap" placement="bottom">
                                <Box sx={{ cursor: 'pointer', boxShadow: '0 0 0 1px #444' }} display={"flex"} justifyContent={"center"}
                                    onClick={() => logout(false, this.props.socket)}
                                    className={clsx(
                                        "relative items-center h-full w-auto aspect-square",
                                        "text-white bg-gradient-to-r from-red-400 via-red-500 to-red-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 shadow-lg shadow-red-500/50 dark:shadow-lg dark:shadow-red-800/80 font-medium rounded-lg text-sm text-center",
                                    )}
                                >
                                    <img src={loadedResources[`${imgPath}/left-arrow.svg`]} alt="Ayarlar"
                                        className="w-full h-auto p-2"
                                    />
                                </Box>
                            </Tooltip>

                            <Tooltip title="Çevrimiçi Oyuncular" placement="bottom">
                                <Chip size="small"
                                    sx={{ boxShadow: '0 0 0 1px #444' }}
                                    className="!rounded-md bg-gradient-to-r from-[#F48C52] via-[#F3864B] to-[#f16f28] !h-full aspect-square"
                                    classes={{ label: "!p-0" }}
                                    label={(
                                        <Typography className={clsx("!text-base !font-bold")} sx={{ color: '#FFFFFF' }}>
                                            {this.props.users?.length ?? 0}
                                        </Typography>
                                    )}
                                />
                            </Tooltip>

                            <Tooltip title="Genel Menü" placement="bottom">
                                <Box sx={{ cursor: 'pointer', boxShadow: '0 0 0 1px #444' }} display={"flex"} justifyContent={"center"}
                                    onClick={e => this.setState({ genericMenuAnchor: e.currentTarget })}
                                    className={clsx(
                                        "relative items-center h-full w-auto aspect-square",
                                        "text-white bg-gradient-to-r from-teal-400 via-teal-500 to-teal-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-teal-300 dark:focus:ring-teal-800 shadow-lg shadow-teal-500/50 dark:shadow-lg dark:shadow-teal-800/80 font-medium rounded-lg text-sm text-center",
                                    )}
                                >
                                    <img src={loadedResources[`${imgPath}/hamburger.svg`]} alt="Genel menü"
                                        className="w-full h-auto p-2"
                                    />
                                </Box>
                            </Tooltip>

                            <Tooltip title="Ayarlar" placement="bottom">
                                <Box sx={{ cursor: 'pointer', boxShadow: '0 0 0 1px #444' }} display={"flex"} justifyContent={"center"}
                                    onClick={e => this.setState({ settingsMenuAnchor: e.currentTarget })}
                                    className={clsx(
                                        "relative items-center h-full w-auto aspect-square",
                                        "text-white bg-gradient-to-r from-teal-400 via-teal-500 to-teal-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-teal-300 dark:focus:ring-teal-800 shadow-lg shadow-teal-500/50 dark:shadow-lg dark:shadow-teal-800/80 font-medium rounded-lg text-sm text-center",
                                    )}
                                >
                                    <img src={loadedResources[`${imgPath}/gear.svg`]} alt="Ayarlar"
                                        className="w-full h-auto p-2"
                                    />
                                </Box>
                            </Tooltip>

                            <Tooltip title="Bildirimler" placement="bottom">
                                <Box sx={{ cursor: 'pointer', boxShadow: '0 0 0 1px #444' }} display={"flex"} justifyContent={"center"}
                                    onClick={this.openNotificationsMenu}
                                    className={clsx(
                                        "relative items-center h-full w-auto aspect-square",
                                        "text-white bg-gradient-to-r from-teal-400 via-teal-500 to-teal-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-teal-300 dark:focus:ring-teal-800 shadow-lg shadow-teal-500/50 dark:shadow-lg dark:shadow-teal-800/80 font-medium rounded-lg text-sm text-center",
                                    )}
                                >
                                    <img src={loadedResources[`${imgPath}/bell.svg`]} alt="Bildirimler"
                                        className="w-full h-auto p-2"
                                    />

                                    <div className="animate-bounce w-3 h-3 md:w-4 md:h-4 top-[-0.1rem] right-[-0.2rem] md:right-[-0.4rem]" style={{
                                        borderRadius: '50%', position: 'absolute',
                                        display: unreadNotifications.length === 0 ? 'none' : 'block', backgroundColor: '#FF5757',
                                        boxShadow: '0 0 0 1px #444'
                                    }}>
                                        <Typography className={clsx(classes.subText, "!text-[0.5rem] !leading-3 lh md:!text-xs")}
                                            sx={{ margin: 'auto', textAlign: 'center' }}
                                        >
                                            {unreadNotifications.length}
                                        </Typography>
                                    </div>
                                </Box>
                            </Tooltip>

                            <Tooltip title="Renkler ve Masa Görselleri" placement="bottom">
                                <Box sx={{ cursor: 'pointer', boxShadow: '0 0 0 1px #444' }} display={"flex"} justifyContent={"center"}
                                    onClick={e => this.setState({ colorPreview: true })}
                                    className={clsx(
                                        "relative items-center h-full w-auto aspect-square",
                                        "text-white bg-gradient-to-r from-teal-400 via-teal-500 to-teal-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-teal-300 dark:focus:ring-teal-800 shadow-lg shadow-teal-500/50 dark:shadow-lg dark:shadow-teal-800/80 font-medium rounded-lg text-sm text-center",
                                    )}
                                >
                                    <img src={loadedResources[`${imgPath}/palette.svg`]} alt="Renkler"
                                        className="w-full h-auto p-2"
                                    />
                                </Box>
                            </Tooltip>

                            <Tooltip title="Top 10" placement="bottom">
                                <Box sx={{ cursor: 'pointer', boxShadow: '0 0 0 1px #444' }} display={"flex"} justifyContent={"center"}
                                    onClick={e => this.setState({ top10Preview: true })}
                                    className={clsx(
                                        "relative items-center h-full w-auto aspect-square",
                                        "text-white bg-gradient-to-r from-teal-400 via-teal-500 to-teal-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-teal-300 dark:focus:ring-teal-800 shadow-lg shadow-teal-500/50 dark:shadow-lg dark:shadow-teal-800/80 font-medium rounded-lg text-sm text-center",
                                    )}
                                >
                                    <img src={loadedResources[`${imgPath}/ranking.svg`]} alt="Top10"
                                        className="w-full h-auto p-2"
                                    />
                                </Box>
                            </Tooltip>

                            {document.documentElement.requestFullscreen && (
                                <Tooltip title={this.state.fullscreen ? "Tam Ekrandan Çık" : "Tam Ekran Yap"} placement="bottom">
                                    <Box sx={{ cursor: 'pointer', boxShadow: '0 0 0 1px #444' }} display={"flex"} justifyContent={"center"}
                                        onClick={this.toggleFullscreen}
                                        className={clsx(
                                            "relative items-center h-full w-auto aspect-square",
                                            "text-white bg-gradient-to-r from-teal-400 via-teal-500 to-teal-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-teal-300 dark:focus:ring-teal-800 shadow-lg shadow-teal-500/50 dark:shadow-lg dark:shadow-teal-800/80 font-medium rounded-lg text-sm text-center",
                                        )}
                                    >
                                        {(() => {
                                            const src = this.state.fullscreen ? loadedResources[`${imgPath}/minimize.svg`] : loadedResources[`${imgPath}/expand.svg`];
                                            return (
                                                <img src={src} alt="Tam Ekran"
                                                    className="w-full h-auto p-2"
                                                />
                                            );
                                        })()}
                                    </Box>
                                </Tooltip>
                            )}
                        </Box>
                    </Box>

                    <Radio />

                </Box>
            </Box >
        );
    }

    /**
     * 
     * @param {string} id 
     * @param {string} text 
     */
    buildTab = (id, text, onClicked, onClosed, status) => {
        const { selectedTab, unreadMessages } = this.props;
        const selected = selectedTab === id;
        this.tabHandlers[id] = onClicked;

        const statusColor = () => {
            if (status === undefined) return null;

            const colors = ["#ABABAB", "#30B26C", "#DA4E4E", "#EFAE2F"];
            const hide = status < 0;

            return (
                <div style={{
                    borderRadius: '50%', justifySelf: 'center', alignSelf: 'center',
                    backgroundColor: colors[status] ?? colors[0],
                    border: '2px solid #FFFFFF', display: hide ? 'none' : 'block'
                }}
                    className={clsx(
                        "!w-[0.6em] !h-[0.6em] sm:!w-[0.7em] sm:!h-[0.7em] lg:!w-[0.8em] lg:!h-[0.8em]",
                        "mr-1 lg:mr-2"
                    )}
                />
            );
        }

        return (
            <Box key={id} display={"flex"} justifyContent={"center"} borderRight={"1px solid #a097b3"} position={"relative"}
                className="px-2 h-full"
                onClick={(e) => {
                    if (selected) return;
                    this.props.selectTab(id);
                    onClicked?.();
                }}
                sx={{ cursor: selected ? null : 'pointer' }}
            >
                <Box className="flex px-1">
                    {statusColor()}
                    <Typography className="!text-xs md:!text-sm lg:!text-base"
                        sx={{
                            margin: 'auto', color: selected ? '#FF9559' : 'white',
                            overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis',
                            '&:after': {
                                content: '""',
                                display: 'block',
                                position: 'absolute',
                                top: 0, right: 0, bottom: 0, left: 0,
                                borderBottom: selected ? '4px solid #FF9559' : null,
                            }
                        }}
                    >
                        {text}
                    </Typography>
                </Box>

                {unreadMessages[id] > 0 && (
                    <div className="w-4 h-4" style={{
                        borderRadius: '50%', backgroundColor: '#FF5757',
                        margin: 'auto', marginLeft: 4, display: "flex", justifyContent: "center",
                        alignItems: "center"
                    }}>
                        <Typography className="!text-xs" sx={{ color: 'white' }}>
                            {unreadMessages[id] < 10 ? unreadMessages[id] : '9+'}
                        </Typography>
                    </div>
                )}

                {onClosed && (
                    <Tooltip title="Kapat" placement="top">
                        <Box position={"relative"} className={"flex self-center ml-1"}>
                            <IconButton disableFocusRipple disableRipple disableTouchRipple sx={{ p: 0 }}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    onClosed();
                                }}
                            >
                                <CloseIcon htmlColor="#FFFFFF80" className="!text-sm md:!text-base lg:!text-lg" />
                            </IconButton>
                        </Box>
                    </Tooltip>
                )}
            </Box>
        );
    }

    lobbyTab = () => {
        const tabId = "salon";
        const onClicked = () => {
            this.props.history.push(getPath("/salon"));
        }

        return this.buildTab(tabId, "Lobi", onClicked);
    }

    adminTab = () => {
        const tabId = "admin";
        const onClicked = () => {
            this.props.history.push(getPath("/admin"));
        }

        return this.buildTab(tabId, "Admin", onClicked);
    }

    logsTab = () => {
        const tabId = "log";
        const onClicked = () => {
            this.props.history.push(getPath("/admin/log/loginout"));
        }

        return this.buildTab(tabId, "Log", onClicked);
    }

    tableTabs = () => {
        const { tableIds } = this.props;

        return tableIds?.map((tableId, index) => {
            const tabId = `table_${tableId}`;
            const onClicked = () => {
                this.props.history.push(getPath(`/masa/${tableId}`));
            }

            const onClosed = () => {
                this.props.setLeavingTable(tableId);
            }

            return this.buildTab(tabId, `Masa ${tableId}`, onClicked, onClosed);
        });
    }

    chatTabs = () => {
        const { chatTabs, usersById } = this.props;

        return chatTabs?.map((tab, index) => {
            const tabId = tab.id;
            const onClicked = () => {
                this.props.history.push(getPath("/sohbet"), { userId: tabId, username: tab.name });
            }

            const onClosed = () => {
                this.props.removeChatTab(tabId);
                if (this.props.selectedTab === tabId) {
                    this.props.selectTab('salon');
                    this.props.history.push(getPath('/salon'));
                }
            }

            const status = !tabId ? 1 : (usersById[tabId]?.status ?? 0);
            return this.buildTab(tabId, tab.name, onClicked, onClosed, status);
        });
    }

    getProfile = () => {
        let profile = null;

        try {
            profile = JSON.parse(sessionStorage.getItem('profile') ?? localStorage.getItem('profile'));
        } catch (e) {
            console.error(e);
        }

        return profile;
    }

    subHeader = () => {
        const profile = this.getProfile();
        const classHeight = this.props.isLandscape ? "h-[2rem]" : "h-[2rem] md:h-[3rem]";

        return (
            <Box display={"flex"} bgcolor={"#5B4C7A"}
                className={classHeight}
                borderBottom={"1px solid #a097b3"} sx={{ overscrollBehavior: 'none' }}
            >
                {/* Left Arrow */}
                <Box display={"flex"} justifyContent={"center"} px={"6px"} borderRight={"1px solid #a097b3"}
                    onClick={(e) => this.switchTab(-1)}
                    sx={{ cursor: 'pointer' }}
                    className={clsx(classHeight)}
                >
                    <SvgIcon htmlColor="#a097b3" sx={{ margin: 'auto', fontSize: '2rem' }}>
                        <path d={mdiChevronLeft} />
                    </SvgIcon>
                </Box>

                <Box
                    className="flex h-full"
                    sx={{
                        overflow: 'hidden', overflowX: 'auto', overscrollBehavior: 'none',
                        '&::-webkit-scrollbar': {
                            display: 'none',
                        }
                    }}
                >

                    {this.lobbyTab()}
                    {profile?.perm > 0 ? this.adminTab() : null}
                    {profile?.perm >= perms.PermCmdLogsOps ? this.logsTab() : null}
                    {this.tableTabs()}
                    {this.chatTabs()}

                </Box>

                <Box flex={1}></Box>

                {/* Right Arrow */}
                <Box display={"flex"} justifyContent={"center"} px={"6px"} borderLeft={"1px solid #a097b3"}
                    onClick={(e) => this.switchTab(1)}
                    sx={{ cursor: 'pointer' }}
                    className={clsx(classHeight)}
                >
                    <SvgIcon htmlColor="#a097b3" sx={{ margin: 'auto', fontSize: '2rem' }}>
                        <path d={mdiChevronRight} />
                    </SvgIcon>
                </Box>
            </Box>
        );
    }

    render() {
        const { user } = this.props;

        return (
            <Box display={this.props.hide ? "none" : "block"}>
                <UploadPhoto open={this.state.uploadingPhoto} onClose={this.closeUploadPhoto} />
                <NotificationSettings open={this.state.notificationSettings} onClose={this.closeNotificationSettings} />
                <ChangePassword open={this.state.changePassword} onClose={this.closeChangePassword} />
                <GeneralSettings open={this.state.generalSettings} onClose={this.closeGeneralSettings} />
                <UpdateProfile open={this.state.updateProfile} onClose={this.closeProfileUpdate} />
                <UpdateDescription open={this.state.updateDescription} onClose={this.closeProfileDescriptionUpdate} />
                <DeleteAccount open={this.state.deleteAccount} onClose={this.closeDeleteAccount} />

                <EditUserColor open={this.state.colorPreview} preview
                    userColor={{ username_color: user?.username_color, text_color: user?.text_color }}
                    onClose={() => this.setState({ colorPreview: false })}
                />

                <Top10 open={this.state.top10Preview} onClose={() => this.setState({ top10Preview: false })} />

                {/* General Menu */}
                <PlanedEvents open={this.state.planedEvents} onClose={this.closePlanedEvents} />
                <EventRules open={this.state.eventRules} onClose={this.closeEventRules} />
                <JoinTournament open={this.state.joinTournament} onClose={this.closeJoinTournament} />
                <TournamentInfo open={this.state.tournamentInfo} onClose={this.closeTournamentInfo} />
                <LastRandomScores open={this.state.lastRandomScores} onClose={this.closeLastRandomScores} />
                <NickChangeReguest open={this.state.nickChangeRequest} onClose={this.closeNickChangeRequest} />
                <NickChangeRequestTracker open={this.state.nickChangeRequestTracker} onClose={this.closeNickChangeRequestTracker} />

                {this.header()}
                {this.subHeader()}

                <TermsOfUse open={this.state.termsOfUse} onClose={e => this.setState({ termsOfUse: false })} />
                <PrivacyPolicy open={this.state.privacyPolicy} onClose={e => this.setState({ privacyPolicy: false })} />

            </Box>
        );
    }
}

Header.propTypes = {
    classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
    return {
        selectedTab: state.app.global.selectedTab,
        tableIds: state.app.table.tableIds,
        socket: state.app.global.socket,
        user: state.app.global.user,
        chatTabs: state.app.global.chatTabs,
        unreadMessages: state.app.lobby.unreadMessages,
        notifications: state.app.global.notifications,
        usersById: state.app.lobby.usersById,
        spectatingTables: state.app.global.spectatingTables,
        users: state.app.lobby.users,
    }
};

export default withStyles(styles)(connect(mapStateToProps, {
    selectTab, removeChatTab, setNotification, setLeavingTable,
    setAdminPath, setAdminTab, setGeneralSettings
})(Header));
