export const smileys = [
    "smile.gif",
    "laugh.gif",
    "wink.gif",
    "tongueout.gif",
    "hi.gif",
    "angry.gif",
    "cry.gif",
    "sad.gif",
    "surprised.gif",
    "loudlycrying.gif",
    "blush.gif",
    "dream.gif",
    "ttm.gif",
    "slap.gif",
    "tired.gif",
    "ill.gif",
    "selfie.gif",
    "kiss.gif",
    "worry.gif",
    "cool.gif",
    "shake.gif",
    "nod.gif",
    "wonder.gif",
    "cwl.gif",
    "lipssealed.gif",
    "headbang.gif",
    "inlove.gif",
    "devil.gif",
    "melek.gif",
    "sarcastic.gif",
    "clap.gif",
    "donttalktome.gif",
    "unamused.gif",
    "rock.gif",
    "highfive.gif",
    "swear.gif",
    "puke.gif",
    "rofl.gif",
    "punch.gif",
    "hungover.gif",
    "makeup.gif",
    "fikir.gif",
    "facepalm.gif",
    "xd.gif",
    "wait.gif",
    "eg.gif",
    "nerdy.gif",
    "drunk.gif",
    "sasi.gif",
    "sasi2.gif",
    "call.gif",
    "headphones.gif",
    "ciglik.gif",
    "gipta.gif",
    "usudum.gif",
    "tersil.gif",
    "gormeyeyim.gif",
    "whistle.gif",
    "maske.gif",
    "maske2.gif",
    "bebek.gif",
    "trip.gif",
    "bvampir.gif",
    "kizgin.gif",
    "cakmakyak.gif",
    "hey2.gif",
    "dans33.gif",
    "dans34.gif",
    "face2.gif",
    "hmm.gif",
    "sapka.gif",
    "sarki.gif",
    "star.gif",
    "yildiz.gif",
    "ates.gif",
    "cat.gif",
    "penguin.gif",
    "penguinkiss.gif",
    "monkeygiggle.gif",
    "monkey.gif",
    "cay.gif",
    "coffee.gif",
    "cake.gif",
    "lips.gif",
    "heart.gif",
    "brokenheart.gif",
    "gottarun.gif",
    "skipping.gif",
    "yoga.gif",
    "bhangra.gif",
    "sturridge15.gif",
    "discodancer.gif",
    "dance.gif",
    "lang.gif",
    "henderson14.gif",
    "stop.gif",
    "ring.gif",
    "computerrage.gif",
    "muscle.gif",
    "handsinair.gif",
    "yes.gif",
    "no.gif",
    "dropthemic.gif",
    "clappinghands.gif",
    "poke.gif",
    "fist.gif",
    "hug.gif",
    "goodluck.gif",
    "flower.gif",
    "diya.gif",
    "fireworks.gif",
    "whatsgoingon.gif",
    "dance2.gif",
    "hashas.gif",
    "para.gif",
    "ask.gif",
    "dans2.gif",
    "dans5.gif",
    "oyna3.gif",
    "oyna4.gif",
    "oyna5.gif",
    "oyna6.gif",
    "horon.gif",
    "salla2.gif",
    "tey2.gif",
    "teklif.gif",
    "azerbaycan.gif",
    "tr1.gif",
    "tr2.gif",
    "dur2.gif",
    "tava.gif",
    "umu.gif",
    "hoho.gif",
    "saklannn.gif",
    "oyun.gif",
    "oyun1.gif",
    "oyun2.gif",
    "oyun3.gif",
    "misir.gif",
    "yagmur1.gif",
    "kelebek1.gif",
    "oyna14.gif",
    "kahkaha1.gif",
    "gitar.gif",
    "panda1.gif",
    "panda2.gif",
    "davul.gif",
    "tatli.gif",
    "muzik2.gif",
    "hiphop.gif",
    "superhero.gif",
    "supermum.gif",
    "ipatla.gif",
    "futbol.gif",
    "ninja.gif",
    "kurbaga.gif",
    "muz.gif",
    "uzgun.gif",
    "kahkaha2.gif",
    "palamut.gif",
    "gran.gif",
    "butterfly.gif",
    "hearteyesmonkey.gif",
    "octopus.gif",
    "dil.gif",
    "dil2.gif",
    "dil3.gif",
    "giris.gif",
    "kalp.gif",
    "onay.gif",
    "red.gif",
    "onay2.gif",
    "love.gif",
    "pcask.gif",
    "ipatla2.gif",
    "noeldans.gif",
    "zipla.gif",
    "kazdans.gif",
    "kalp2.gif",
    "heyecan.gif",
    "pika1.gif",
    "pika2.gif",
    "gozdon.gif",
    "muck.gif",
    "muck2.gif",
    "kedid.gif",
    "seytan2.gif",
    "gozkirp.gif",
    "kufur2.gif",
    "copadam.gif",
    "manblowkiss.gif",
    "manshrug.gif",
    "womanblowkiss.gif",
    "womanshrug.gif",
    "shopping.gif",
    "cash.gif",
    "time.gif",
    "loveletter.gif",
    "lovegift.gif",
    "dans3.gif",
    "civciv.gif",
    "hulahop.gif",
    "dondon.gif",
    "gulver.gif",
    "patla.gif",
    "yanak.gif",
    "muzik.gif",
    "cekic.gif",
    "tilki1.gif",
    "tilki2.gif",
    "cool2.gif",
    "deli.gif",
    "sus.gif",
    "op.gif",
    "kitap.gif",
    "huzun.gif",
    "agla.gif",
    "kart.gif",
    "sustum.gif",
    "picak.gif",
    "dolar.gif",
    "gozluk.gif",
    "futbol2.gif",
    "sakin.gif",
    "tr3.gif",
    "alev1.gif",
    "alev2.gif",
    "alev3.gif",
    "alev4.gif",
    "alev5.gif",
    "alev6.gif",
    "alev7.gif",
    "dov.gif",
    "kurbaga2.gif",
    "muz2.gif",
    "cop2.gif",
    "poo.gif",
    "salla.gif",
    "buyur.gif",
    "fincan.gif",
    "fincan2.gif",
    "kahve2.gif",
    "dikkat.gif",
    "yukle.gif",
    "kusak.gif",
    "kalp3.gif",
    "kalp4.gif",
    "kalp5.gif",
    "mincir.gif",
    "saril.gif",
    "dikiz.gif",
    "gitar2.gif",
    "paytak.gif",
    "horoz.gif",
    "kurt.gif",
    "mavlana.gif",
    "hiphop2.gif",
    "casper.gif",
    "uzayli.gif",
    "kafa.gif",
    "supurge.gif",
    "sigara.gif",
    "sigara2.gif",
];