import { AxiosClient } from "./axios";
import { getPath } from "./route";

async function logout(confirm = false, socket = null) {
    if (!confirm) {
        const ok = window.confirm("Çıkış yapmak istediğinize emin misiniz?")
        if (!ok) {
            return;
        }
    }

    try {
        socket?.close();
        await AxiosClient().post(`${process.env.REACT_APP_API_URL}/v1/user/logout`, {});
    } catch (e) {
        console.error(e);
    } finally {
        sessionStorage.removeItem('rememberMe');
        sessionStorage.removeItem('sid');
        sessionStorage.removeItem('profile');
        localStorage.removeItem('rememberMe');
        localStorage.removeItem('sid');
        localStorage.removeItem('profile');
        window.location = getPath("/login");
    }
}

export {
    logout,
}