import { getPath } from "./route";

export const fontResources = [
    `${process.env.REACT_APP_CDN_URL}/web/fonts/DancingScript-Bold.ttf`,
    `${process.env.REACT_APP_CDN_URL}/web/fonts/RobotoSlab-Bold.ttf`,
    `${process.env.REACT_APP_CDN_URL}/web/fonts/RobotoSlab-Regular.ttf`,
];

export const imgResources = [
    `${process.env.REACT_APP_CDN_URL}/web/img/1numara.png`,
    `${process.env.REACT_APP_CDN_URL}/web/img/1yildiz2.jpg`,
    `${process.env.REACT_APP_CDN_URL}/web/img/06.gif`,
    `${process.env.REACT_APP_CDN_URL}/web/img/10numara.png`,
    `${process.env.REACT_APP_CDN_URL}/web/img/100numara.png`,
    `${process.env.REACT_APP_CDN_URL}/web/img/1938.png`,
    `${process.env.REACT_APP_CDN_URL}/web/img/afas.png`,
    `${process.env.REACT_APP_CDN_URL}/web/img/asil.gif`,
    `${process.env.REACT_APP_CDN_URL}/web/img/axii.gif`,
    `${process.env.REACT_APP_CDN_URL}/web/img/aycicek.png`,
    `${process.env.REACT_APP_CDN_URL}/web/img/bass.png`,
    `${process.env.REACT_APP_CDN_URL}/web/img/besiktas.png`,
    `${process.env.REACT_APP_CDN_URL}/web/img/beyz2.gif`,
    `${process.env.REACT_APP_CDN_URL}/web/img/biryildiz.png`,
    `${process.env.REACT_APP_CDN_URL}/web/img/bjk.png`,
    `${process.env.REACT_APP_CDN_URL}/web/img/bjkmc2.png`,
    `${process.env.REACT_APP_CDN_URL}/web/img/cadi.gif`,
    `${process.env.REACT_APP_CDN_URL}/web/img/cicek.png`,
    `${process.env.REACT_APP_CDN_URL}/web/img/civciv.png`,
    `${process.env.REACT_APP_CDN_URL}/web/img/coffee.png`,
    `${process.env.REACT_APP_CDN_URL}/web/img/crown.svg`,
    `${process.env.REACT_APP_CDN_URL}/web/img/cs1.gif`,
    `${process.env.REACT_APP_CDN_URL}/web/img/cs4.gif`,
    `${process.env.REACT_APP_CDN_URL}/web/img/cute-devil.gif`,
    `${process.env.REACT_APP_CDN_URL}/web/img/double_pieces.png`,
    `${process.env.REACT_APP_CDN_URL}/web/img/dudakizi.png`,
    `${process.env.REACT_APP_CDN_URL}/web/img/efsn2.gif`,
    `${process.env.REACT_APP_CDN_URL}/web/img/eg.gif`,
    `${process.env.REACT_APP_CDN_URL}/web/img/elmas.gif`,
    `${process.env.REACT_APP_CDN_URL}/web/img/esmer.webp`,
    `${process.env.REACT_APP_CDN_URL}/web/img/fb.png`,
    `${process.env.REACT_APP_CDN_URL}/web/img/fbmc2.png`,
    `${process.env.REACT_APP_CDN_URL}/web/img/fdghg55.png`,
    `${process.env.REACT_APP_CDN_URL}/web/img/fenerbahce.png`,
    `${process.env.REACT_APP_CDN_URL}/web/img/firtinatac.png`,
    `${process.env.REACT_APP_CDN_URL}/web/img/galatasaray.png`,
    `${process.env.REACT_APP_CDN_URL}/web/img/gitbasimdan.gif`,
    `${process.env.REACT_APP_CDN_URL}/web/img/gs.png`,
    `${process.env.REACT_APP_CDN_URL}/web/img/gsmc3.png`,
    `${process.env.REACT_APP_CDN_URL}/web/img/gulenkalp1.png`,
    `${process.env.REACT_APP_CDN_URL}/web/img/gulenkalp2.png`,
    `${process.env.REACT_APP_CDN_URL}/web/img/hummel.gif`,
    `${process.env.REACT_APP_CDN_URL}/web/img/hv.gif`,
    `${process.env.REACT_APP_CDN_URL}/web/img/icon_temizle.png`,
    `${process.env.REACT_APP_CDN_URL}/web/img/ilone.png`,
    `${process.env.REACT_APP_CDN_URL}/web/img/images.png`,
    `${process.env.REACT_APP_CDN_URL}/web/img/images(1).png`,
    `${process.env.REACT_APP_CDN_URL}/web/img/images(2).png`,
    `${process.env.REACT_APP_CDN_URL}/web/img/images(3).png`,
    `${process.env.REACT_APP_CDN_URL}/web/img/images(5).png`,
    `${process.env.REACT_APP_CDN_URL}/web/img/images(7).png`,
    `${process.env.REACT_APP_CDN_URL}/web/img/images(8).png`,
    `${process.env.REACT_APP_CDN_URL}/web/img/images(9).png`,
    `${process.env.REACT_APP_CDN_URL}/web/img/images(10).png`,
    `${process.env.REACT_APP_CDN_URL}/web/img/images(12).png`,
    `${process.env.REACT_APP_CDN_URL}/web/img/images(13).png`,
    `${process.env.REACT_APP_CDN_URL}/web/img/images(14).png`,
    `${process.env.REACT_APP_CDN_URL}/web/img/images535.png`,
    `${process.env.REACT_APP_CDN_URL}/web/img/indir.png`,
    `${process.env.REACT_APP_CDN_URL}/web/img/indir(4).png`,
    `${process.env.REACT_APP_CDN_URL}/web/img/isil.gif`,
    `${process.env.REACT_APP_CDN_URL}/web/img/isil2.gif`,
    `${process.env.REACT_APP_CDN_URL}/web/img/iskambil.png`,
    `${process.env.REACT_APP_CDN_URL}/web/img/iyetki01.png`,
    `${process.env.REACT_APP_CDN_URL}/web/img/iyetki02.png`,
    `${process.env.REACT_APP_CDN_URL}/web/img/iyetki03.png`,
    `${process.env.REACT_APP_CDN_URL}/web/img/iyetki04.png`,
    `${process.env.REACT_APP_CDN_URL}/web/img/iyetki05.png`,
    `${process.env.REACT_APP_CDN_URL}/web/img/iyetki06.png`,
    `${process.env.REACT_APP_CDN_URL}/web/img/iyetki07.png`,
    `${process.env.REACT_APP_CDN_URL}/web/img/iyetki08.webp`,
    `${process.env.REACT_APP_CDN_URL}/web/img/kalp.png`,
    `${process.env.REACT_APP_CDN_URL}/web/img/kalp2.png`,
    `${process.env.REACT_APP_CDN_URL}/web/img/kalpbayrak.png`,
    `${process.env.REACT_APP_CDN_URL}/web/img/kalpgozemoji.png`,
    `${process.env.REACT_APP_CDN_URL}/web/img/kalpgozkedi.png`,
    `${process.env.REACT_APP_CDN_URL}/web/img/karakalp.png`,
    `${process.env.REACT_APP_CDN_URL}/web/img/kelebek.png`,
    `${process.env.REACT_APP_CDN_URL}/web/img/kelebek1.jpg`,
    `${process.env.REACT_APP_CDN_URL}/web/img/kiss.gif`,
    `${process.env.REACT_APP_CDN_URL}/web/img/klj485.png`,
    `${process.env.REACT_APP_CDN_URL}/web/img/kugu.png`,
    `${process.env.REACT_APP_CDN_URL}/web/img/kurt.png`,
    `${process.env.REACT_APP_CDN_URL}/web/img/ljhhg55.png`,
    `${process.env.REACT_APP_CDN_URL}/web/img/maral.gif`,
    `${process.env.REACT_APP_CDN_URL}/web/img/masa12.png`,
    `${process.env.REACT_APP_CDN_URL}/web/img/masa13.png`,
    `${process.env.REACT_APP_CDN_URL}/web/img/masa14.png`,
    `${process.env.REACT_APP_CDN_URL}/web/img/masa345.png`,
    `${process.env.REACT_APP_CDN_URL}/web/img/melekemoji1.png`,
    `${process.env.REACT_APP_CDN_URL}/web/img/melekemoji2.png`,
    `${process.env.REACT_APP_CDN_URL}/web/img/melekkalp1.png`,
    `${process.env.REACT_APP_CDN_URL}/web/img/melekkalp2.png`,
    `${process.env.REACT_APP_CDN_URL}/web/img/mihri.gif`,
    `${process.env.REACT_APP_CDN_URL}/web/img/mng.gif`,
    `${process.env.REACT_APP_CDN_URL}/web/img/nazarboncugu.png`,
    `${process.env.REACT_APP_CDN_URL}/web/img/nt.gif`,
    `${process.env.REACT_APP_CDN_URL}/web/img/nurls.png`,
    `${process.env.REACT_APP_CDN_URL}/web/img/papatyasari.png`,
    `${process.env.REACT_APP_CDN_URL}/web/img/pikachu.png`,
    `${process.env.REACT_APP_CDN_URL}/web/img/pikachury.png`,
    `${process.env.REACT_APP_CDN_URL}/web/img/qoobee.gif`,
    `${process.env.REACT_APP_CDN_URL}/web/img/redroses.gif`,
    `${process.env.REACT_APP_CDN_URL}/web/img/robot.png`,
    `${process.env.REACT_APP_CDN_URL}/web/img/robot.svg`,
    `${process.env.REACT_APP_CDN_URL}/web/img/samsunspor.png`,
    `${process.env.REACT_APP_CDN_URL}/web/img/serial_pieces.png`,
    `${process.env.REACT_APP_CDN_URL}/web/img/seytan1.png`,
    `${process.env.REACT_APP_CDN_URL}/web/img/sirinbaba.png`,
    `${process.env.REACT_APP_CDN_URL}/web/img/slightly_smile.png`,
    `${process.env.REACT_APP_CDN_URL}/web/img/smileyyz.jpg`,
    `${process.env.REACT_APP_CDN_URL}/web/img/sonszluk.png`,
    `${process.env.REACT_APP_CDN_URL}/web/img/spade.svg`,
    `${process.env.REACT_APP_CDN_URL}/web/img/star.png`,
    `${process.env.REACT_APP_CDN_URL}/web/img/tac_kral_taci.gif`,
    `${process.env.REACT_APP_CDN_URL}/web/img/takoz.png`,
    `${process.env.REACT_APP_CDN_URL}/web/img/takoz2.png`,
    `${process.env.REACT_APP_CDN_URL}/web/img/trabzonspor.png`,
    `${process.env.REACT_APP_CDN_URL}/web/img/trl.png`,
    `${process.env.REACT_APP_CDN_URL}/web/img/tsmc2.png`,
    `${process.env.REACT_APP_CDN_URL}/web/img/turkiyebayragi.png`,
    `${process.env.REACT_APP_CDN_URL}/web/img/turks.png`,
    `${process.env.REACT_APP_CDN_URL}/web/img/ugurbocegi.png`,
    `${process.env.REACT_APP_CDN_URL}/web/img/unlem.gif`,
    `${process.env.REACT_APP_CDN_URL}/web/img/vip.png`,
    `${process.env.REACT_APP_CDN_URL}/web/img/ysmn.gif`,
    `${process.env.REACT_APP_CDN_URL}/web/img/zar.png`,
    `${process.env.REACT_APP_CDN_URL}/web/pieces/0_1.png`,
    `${process.env.REACT_APP_CDN_URL}/web/pieces/0_2.png`,
    `${process.env.REACT_APP_CDN_URL}/web/pieces/0_3.png`,
    `${process.env.REACT_APP_CDN_URL}/web/pieces/0_4.png`,
    `${process.env.REACT_APP_CDN_URL}/web/pieces/0_5.png`,
    `${process.env.REACT_APP_CDN_URL}/web/pieces/0_6.png`,
    `${process.env.REACT_APP_CDN_URL}/web/pieces/0_7.png`,
    `${process.env.REACT_APP_CDN_URL}/web/pieces/0_8.png`,
    `${process.env.REACT_APP_CDN_URL}/web/pieces/0_9.png`,
    `${process.env.REACT_APP_CDN_URL}/web/pieces/0_10.png`,
    `${process.env.REACT_APP_CDN_URL}/web/pieces/0_11.png`,
    `${process.env.REACT_APP_CDN_URL}/web/pieces/0_12.png`,
    `${process.env.REACT_APP_CDN_URL}/web/pieces/0_13.png`,
    `${process.env.REACT_APP_CDN_URL}/web/pieces/0.png`,
    `${process.env.REACT_APP_CDN_URL}/web/pieces/1_1.png`,
    `${process.env.REACT_APP_CDN_URL}/web/pieces/1_2.png`,
    `${process.env.REACT_APP_CDN_URL}/web/pieces/1_3.png`,
    `${process.env.REACT_APP_CDN_URL}/web/pieces/1_4.png`,
    `${process.env.REACT_APP_CDN_URL}/web/pieces/1_5.png`,
    `${process.env.REACT_APP_CDN_URL}/web/pieces/1_6.png`,
    `${process.env.REACT_APP_CDN_URL}/web/pieces/1_7.png`,
    `${process.env.REACT_APP_CDN_URL}/web/pieces/1_8.png`,
    `${process.env.REACT_APP_CDN_URL}/web/pieces/1_9.png`,
    `${process.env.REACT_APP_CDN_URL}/web/pieces/1_10.png`,
    `${process.env.REACT_APP_CDN_URL}/web/pieces/1_11.png`,
    `${process.env.REACT_APP_CDN_URL}/web/pieces/1_12.png`,
    `${process.env.REACT_APP_CDN_URL}/web/pieces/1_13.png`,
    `${process.env.REACT_APP_CDN_URL}/web/pieces/2_1.png`,
    `${process.env.REACT_APP_CDN_URL}/web/pieces/2_2.png`,
    `${process.env.REACT_APP_CDN_URL}/web/pieces/2_3.png`,
    `${process.env.REACT_APP_CDN_URL}/web/pieces/2_4.png`,
    `${process.env.REACT_APP_CDN_URL}/web/pieces/2_5.png`,
    `${process.env.REACT_APP_CDN_URL}/web/pieces/2_6.png`,
    `${process.env.REACT_APP_CDN_URL}/web/pieces/2_7.png`,
    `${process.env.REACT_APP_CDN_URL}/web/pieces/2_8.png`,
    `${process.env.REACT_APP_CDN_URL}/web/pieces/2_9.png`,
    `${process.env.REACT_APP_CDN_URL}/web/pieces/2_10.png`,
    `${process.env.REACT_APP_CDN_URL}/web/pieces/2_11.png`,
    `${process.env.REACT_APP_CDN_URL}/web/pieces/2_12.png`,
    `${process.env.REACT_APP_CDN_URL}/web/pieces/2_13.png`,
    `${process.env.REACT_APP_CDN_URL}/web/pieces/3_1.png`,
    `${process.env.REACT_APP_CDN_URL}/web/pieces/3_2.png`,
    `${process.env.REACT_APP_CDN_URL}/web/pieces/3_3.png`,
    `${process.env.REACT_APP_CDN_URL}/web/pieces/3_4.png`,
    `${process.env.REACT_APP_CDN_URL}/web/pieces/3_5.png`,
    `${process.env.REACT_APP_CDN_URL}/web/pieces/3_6.png`,
    `${process.env.REACT_APP_CDN_URL}/web/pieces/3_7.png`,
    `${process.env.REACT_APP_CDN_URL}/web/pieces/3_8.png`,
    `${process.env.REACT_APP_CDN_URL}/web/pieces/3_9.png`,
    `${process.env.REACT_APP_CDN_URL}/web/pieces/3_10.png`,
    `${process.env.REACT_APP_CDN_URL}/web/pieces/3_11.png`,
    `${process.env.REACT_APP_CDN_URL}/web/pieces/3_12.png`,
    `${process.env.REACT_APP_CDN_URL}/web/pieces/3_13.png`,
    `${process.env.REACT_APP_CDN_URL}/web/pieces/4.png`,
    //`${process.env.REACT_APP_CDN_URL}/web/icons/tab.svg`,
    `${process.env.REACT_APP_CDN_URL}/web/presents/ananas.png`,
    `${process.env.REACT_APP_CDN_URL}/web/presents/araba.png`,
    `${process.env.REACT_APP_CDN_URL}/web/presents/armut.png`,
    `${process.env.REACT_APP_CDN_URL}/web/presents/baklava.png`,
    `${process.env.REACT_APP_CDN_URL}/web/presents/cay.png`,
    `${process.env.REACT_APP_CDN_URL}/web/presents/cerez.png`,
    `${process.env.REACT_APP_CDN_URL}/web/presents/cilek.png`,
    `${process.env.REACT_APP_CDN_URL}/web/presents/dondurma.png`,
    `${process.env.REACT_APP_CDN_URL}/web/presents/elma.png`,
    `${process.env.REACT_APP_CDN_URL}/web/presents/hurma.png`,
    `${process.env.REACT_APP_CDN_URL}/web/presents/index.jsx`,
    `${process.env.REACT_APP_CDN_URL}/web/presents/jelibon.png`,
    `${process.env.REACT_APP_CDN_URL}/web/presents/kahve.png`,
    `${process.env.REACT_APP_CDN_URL}/web/presents/karpuz.png`,
    `${process.env.REACT_APP_CDN_URL}/web/presents/kek.png`,
    `${process.env.REACT_APP_CDN_URL}/web/presents/limonata.png`,
    `${process.env.REACT_APP_CDN_URL}/web/presents/lokum.png`,
    `${process.env.REACT_APP_CDN_URL}/web/presents/nazar.gif`,
    `${process.env.REACT_APP_CDN_URL}/web/presents/ozelkokteyl.png`,
    `${process.env.REACT_APP_CDN_URL}/web/presents/pasta.png`,
    `${process.env.REACT_APP_CDN_URL}/web/presents/patatescipsi.png`,
    `${process.env.REACT_APP_CDN_URL}/web/presents/pizza.png`,
    `${process.env.REACT_APP_CDN_URL}/web/presents/portakalsuyu.png`,
    `${process.env.REACT_APP_CDN_URL}/web/presents/sahlep.png`,
    `${process.env.REACT_APP_CDN_URL}/web/presents/sandvic.png`,
    `${process.env.REACT_APP_CDN_URL}/web/presents/serpmekahvalti.png`,
    `${process.env.REACT_APP_CDN_URL}/web/presents/simit.png`,
    `${process.env.REACT_APP_CDN_URL}/web/presents/sucukekmek.png`,
    `${process.env.REACT_APP_CDN_URL}/web/presents/sut.png`,
    `${process.env.REACT_APP_CDN_URL}/web/presents/tektas.png`,
    `${process.env.REACT_APP_CDN_URL}/web/presents/yat.png`,
    `${process.env.REACT_APP_CDN_URL}/web/img/expand.svg`,
    `${process.env.REACT_APP_CDN_URL}/web/img/minimize.svg`,
    `${process.env.REACT_APP_CDN_URL}/web/img/left-arrow.svg`,
    `${process.env.REACT_APP_CDN_URL}/web/img/hamburger.svg`,
    `${process.env.REACT_APP_CDN_URL}/web/img/radio.svg`,
    `${process.env.REACT_APP_CDN_URL}/web/img/gear.svg`,
    `${process.env.REACT_APP_CDN_URL}/web/img/bell.svg`,
    `${process.env.REACT_APP_CDN_URL}/web/img/palette.svg`,
    `${process.env.REACT_APP_CDN_URL}/web/img/users.svg`,
    `${process.env.REACT_APP_CDN_URL}/web/img/friends.svg`,
    `${process.env.REACT_APP_CDN_URL}/web/img/ranking.svg`,
    `${process.env.REACT_APP_CDN_URL}/web/smileys/smile.gif`,
    `${process.env.REACT_APP_CDN_URL}/web/smileys/laugh.gif`,
    `${process.env.REACT_APP_CDN_URL}/web/smileys/wink.gif`,
    `${process.env.REACT_APP_CDN_URL}/web/smileys/tongueout.gif`,
    `${process.env.REACT_APP_CDN_URL}/web/smileys/hi.gif`,
    `${process.env.REACT_APP_CDN_URL}/web/smileys/angry.gif`,
    `${process.env.REACT_APP_CDN_URL}/web/smileys/cry.gif`,
    `${process.env.REACT_APP_CDN_URL}/web/smileys/sad.gif`,
    `${process.env.REACT_APP_CDN_URL}/web/smileys/surprised.gif`,
    `${process.env.REACT_APP_CDN_URL}/web/smileys/loudlycrying.gif`,
    `${process.env.REACT_APP_CDN_URL}/web/smileys/blush.gif`,
    `${process.env.REACT_APP_CDN_URL}/web/smileys/dream.gif`,
    `${process.env.REACT_APP_CDN_URL}/web/smileys/ttm.gif`,
    `${process.env.REACT_APP_CDN_URL}/web/smileys/slap.gif`,
    `${process.env.REACT_APP_CDN_URL}/web/smileys/tired.gif`,
    `${process.env.REACT_APP_CDN_URL}/web/smileys/ill.gif`,
    `${process.env.REACT_APP_CDN_URL}/web/smileys/selfie.gif`,
    `${process.env.REACT_APP_CDN_URL}/web/smileys/kiss.gif`,
    `${process.env.REACT_APP_CDN_URL}/web/smileys/worry.gif`,
    `${process.env.REACT_APP_CDN_URL}/web/smileys/cool.gif`,
    `${process.env.REACT_APP_CDN_URL}/web/smileys/shake.gif`,
    `${process.env.REACT_APP_CDN_URL}/web/smileys/nod.gif`,
    `${process.env.REACT_APP_CDN_URL}/web/smileys/wonder.gif`,
    `${process.env.REACT_APP_CDN_URL}/web/smileys/cwl.gif`,
    `${process.env.REACT_APP_CDN_URL}/web/smileys/lipssealed.gif`,
    `${process.env.REACT_APP_CDN_URL}/web/smileys/headbang.gif`,
    `${process.env.REACT_APP_CDN_URL}/web/smileys/inlove.gif`,
    `${process.env.REACT_APP_CDN_URL}/web/smileys/devil.gif`,
    `${process.env.REACT_APP_CDN_URL}/web/smileys/melek.gif`,
    `${process.env.REACT_APP_CDN_URL}/web/smileys/sarcastic.gif`,
    `${process.env.REACT_APP_CDN_URL}/web/smileys/clap.gif`,
    `${process.env.REACT_APP_CDN_URL}/web/smileys/donttalktome.gif`,
    `${process.env.REACT_APP_CDN_URL}/web/smileys/unamused.gif`,
    `${process.env.REACT_APP_CDN_URL}/web/smileys/rock.gif`,
    `${process.env.REACT_APP_CDN_URL}/web/smileys/highfive.gif`,
    `${process.env.REACT_APP_CDN_URL}/web/smileys/swear.gif`,
    `${process.env.REACT_APP_CDN_URL}/web/smileys/puke.gif`,
    `${process.env.REACT_APP_CDN_URL}/web/smileys/rofl.gif`,
    `${process.env.REACT_APP_CDN_URL}/web/smileys/punch.gif`,
    `${process.env.REACT_APP_CDN_URL}/web/smileys/hungover.gif`,
    `${process.env.REACT_APP_CDN_URL}/web/smileys/makeup.gif`,
    `${process.env.REACT_APP_CDN_URL}/web/smileys/fikir.gif`,
    `${process.env.REACT_APP_CDN_URL}/web/smileys/facepalm.gif`,
    `${process.env.REACT_APP_CDN_URL}/web/smileys/xd.gif`,
    `${process.env.REACT_APP_CDN_URL}/web/smileys/wait.gif`,
    `${process.env.REACT_APP_CDN_URL}/web/smileys/eg.gif`,
    `${process.env.REACT_APP_CDN_URL}/web/smileys/nerdy.gif`,
    `${process.env.REACT_APP_CDN_URL}/web/smileys/drunk.gif`,
    `${process.env.REACT_APP_CDN_URL}/web/smileys/sasi.gif`,
    `${process.env.REACT_APP_CDN_URL}/web/smileys/sasi2.gif`,
    `${process.env.REACT_APP_CDN_URL}/web/smileys/call.gif`,
    `${process.env.REACT_APP_CDN_URL}/web/smileys/headphones.gif`,
    `${process.env.REACT_APP_CDN_URL}/web/smileys/ciglik.gif`,
    `${process.env.REACT_APP_CDN_URL}/web/smileys/gipta.gif`,
    `${process.env.REACT_APP_CDN_URL}/web/smileys/usudum.gif`,
    `${process.env.REACT_APP_CDN_URL}/web/smileys/tersil.gif`,
    `${process.env.REACT_APP_CDN_URL}/web/smileys/gormeyeyim.gif`,
    `${process.env.REACT_APP_CDN_URL}/web/smileys/whistle.gif`,
    `${process.env.REACT_APP_CDN_URL}/web/smileys/maske.gif`,
    `${process.env.REACT_APP_CDN_URL}/web/smileys/maske2.gif`,
    `${process.env.REACT_APP_CDN_URL}/web/smileys/bebek.gif`,
    `${process.env.REACT_APP_CDN_URL}/web/smileys/trip.gif`,
    `${process.env.REACT_APP_CDN_URL}/web/smileys/bvampir.gif`,
    `${process.env.REACT_APP_CDN_URL}/web/smileys/kizgin.gif`,
    `${process.env.REACT_APP_CDN_URL}/web/smileys/cakmakyak.gif`,
    `${process.env.REACT_APP_CDN_URL}/web/smileys/hey2.gif`,
    `${process.env.REACT_APP_CDN_URL}/web/smileys/dans33.gif`,
    `${process.env.REACT_APP_CDN_URL}/web/smileys/dans34.gif`,
    `${process.env.REACT_APP_CDN_URL}/web/smileys/face2.gif`,
    `${process.env.REACT_APP_CDN_URL}/web/smileys/hmm.gif`,
    `${process.env.REACT_APP_CDN_URL}/web/smileys/sapka.gif`,
    `${process.env.REACT_APP_CDN_URL}/web/smileys/sarki.gif`,
    `${process.env.REACT_APP_CDN_URL}/web/smileys/star.gif`,
    `${process.env.REACT_APP_CDN_URL}/web/smileys/yildiz.gif`,
    `${process.env.REACT_APP_CDN_URL}/web/smileys/ates.gif`,
    `${process.env.REACT_APP_CDN_URL}/web/smileys/cat.gif`,
    `${process.env.REACT_APP_CDN_URL}/web/smileys/penguin.gif`,
    `${process.env.REACT_APP_CDN_URL}/web/smileys/penguinkiss.gif`,
    `${process.env.REACT_APP_CDN_URL}/web/smileys/monkeygiggle.gif`,
    `${process.env.REACT_APP_CDN_URL}/web/smileys/monkey.gif`,
    `${process.env.REACT_APP_CDN_URL}/web/smileys/cay.gif`,
    `${process.env.REACT_APP_CDN_URL}/web/smileys/coffee.gif`,
    `${process.env.REACT_APP_CDN_URL}/web/smileys/cake.gif`,
    `${process.env.REACT_APP_CDN_URL}/web/smileys/lips.gif`,
    `${process.env.REACT_APP_CDN_URL}/web/smileys/heart.gif`,
    `${process.env.REACT_APP_CDN_URL}/web/smileys/brokenheart.gif`,
    `${process.env.REACT_APP_CDN_URL}/web/smileys/gottarun.gif`,
    `${process.env.REACT_APP_CDN_URL}/web/smileys/skipping.gif`,
    `${process.env.REACT_APP_CDN_URL}/web/smileys/yoga.gif`,
    `${process.env.REACT_APP_CDN_URL}/web/smileys/bhangra.gif`,
    `${process.env.REACT_APP_CDN_URL}/web/smileys/sturridge15.gif`,
    `${process.env.REACT_APP_CDN_URL}/web/smileys/discodancer.gif`,
    `${process.env.REACT_APP_CDN_URL}/web/smileys/dance.gif`,
    `${process.env.REACT_APP_CDN_URL}/web/smileys/lang.gif`,
    `${process.env.REACT_APP_CDN_URL}/web/smileys/henderson14.gif`,
    `${process.env.REACT_APP_CDN_URL}/web/smileys/stop.gif`,
    `${process.env.REACT_APP_CDN_URL}/web/smileys/ring.gif`,
    `${process.env.REACT_APP_CDN_URL}/web/smileys/computerrage.gif`,
    `${process.env.REACT_APP_CDN_URL}/web/smileys/muscle.gif`,
    `${process.env.REACT_APP_CDN_URL}/web/smileys/handsinair.gif`,
    `${process.env.REACT_APP_CDN_URL}/web/smileys/yes.gif`,
    `${process.env.REACT_APP_CDN_URL}/web/smileys/no.gif`,
    `${process.env.REACT_APP_CDN_URL}/web/smileys/dropthemic.gif`,
    `${process.env.REACT_APP_CDN_URL}/web/smileys/clappinghands.gif`,
    `${process.env.REACT_APP_CDN_URL}/web/smileys/poke.gif`,
    `${process.env.REACT_APP_CDN_URL}/web/smileys/fist.gif`,
    `${process.env.REACT_APP_CDN_URL}/web/smileys/hug.gif`,
    `${process.env.REACT_APP_CDN_URL}/web/smileys/goodluck.gif`,
    `${process.env.REACT_APP_CDN_URL}/web/smileys/flower.gif`,
    `${process.env.REACT_APP_CDN_URL}/web/smileys/diya.gif`,
    `${process.env.REACT_APP_CDN_URL}/web/smileys/fireworks.gif`,
    `${process.env.REACT_APP_CDN_URL}/web/smileys/whatsgoingon.gif`,
    `${process.env.REACT_APP_CDN_URL}/web/smileys/dance2.gif`,
    `${process.env.REACT_APP_CDN_URL}/web/smileys/hashas.gif`,
    `${process.env.REACT_APP_CDN_URL}/web/smileys/para.gif`,
    `${process.env.REACT_APP_CDN_URL}/web/smileys/ask.gif`,
    `${process.env.REACT_APP_CDN_URL}/web/smileys/dans2.gif`,
    `${process.env.REACT_APP_CDN_URL}/web/smileys/dans5.gif`,
    `${process.env.REACT_APP_CDN_URL}/web/smileys/oyna3.gif`,
    `${process.env.REACT_APP_CDN_URL}/web/smileys/oyna4.gif`,
    `${process.env.REACT_APP_CDN_URL}/web/smileys/oyna5.gif`,
    `${process.env.REACT_APP_CDN_URL}/web/smileys/oyna6.gif`,
    `${process.env.REACT_APP_CDN_URL}/web/smileys/horon.gif`,
    `${process.env.REACT_APP_CDN_URL}/web/smileys/salla2.gif`,
    `${process.env.REACT_APP_CDN_URL}/web/smileys/tey2.gif`,
    `${process.env.REACT_APP_CDN_URL}/web/smileys/teklif.gif`,
    `${process.env.REACT_APP_CDN_URL}/web/smileys/azerbaycan.gif`,
    `${process.env.REACT_APP_CDN_URL}/web/smileys/tr1.gif`,
    `${process.env.REACT_APP_CDN_URL}/web/smileys/tr2.gif`,
    `${process.env.REACT_APP_CDN_URL}/web/smileys/dur2.gif`,
    `${process.env.REACT_APP_CDN_URL}/web/smileys/tava.gif`,
    `${process.env.REACT_APP_CDN_URL}/web/smileys/umu.gif`,
    `${process.env.REACT_APP_CDN_URL}/web/smileys/hoho.gif`,
    `${process.env.REACT_APP_CDN_URL}/web/smileys/saklannn.gif`,
    `${process.env.REACT_APP_CDN_URL}/web/smileys/oyun.gif`,
    `${process.env.REACT_APP_CDN_URL}/web/smileys/oyun1.gif`,
    `${process.env.REACT_APP_CDN_URL}/web/smileys/oyun2.gif`,
    `${process.env.REACT_APP_CDN_URL}/web/smileys/oyun3.gif`,
    `${process.env.REACT_APP_CDN_URL}/web/smileys/misir.gif`,
    `${process.env.REACT_APP_CDN_URL}/web/smileys/yagmur1.gif`,
    `${process.env.REACT_APP_CDN_URL}/web/smileys/kelebek1.gif`,
    `${process.env.REACT_APP_CDN_URL}/web/smileys/oyna14.gif`,
    `${process.env.REACT_APP_CDN_URL}/web/smileys/kahkaha1.gif`,
    `${process.env.REACT_APP_CDN_URL}/web/smileys/gitar.gif`,
    `${process.env.REACT_APP_CDN_URL}/web/smileys/panda1.gif`,
    `${process.env.REACT_APP_CDN_URL}/web/smileys/panda2.gif`,
    `${process.env.REACT_APP_CDN_URL}/web/smileys/davul.gif`,
    `${process.env.REACT_APP_CDN_URL}/web/smileys/tatli.gif`,
    `${process.env.REACT_APP_CDN_URL}/web/smileys/muzik2.gif`,
    `${process.env.REACT_APP_CDN_URL}/web/smileys/hiphop.gif`,
    `${process.env.REACT_APP_CDN_URL}/web/smileys/superhero.gif`,
    `${process.env.REACT_APP_CDN_URL}/web/smileys/supermum.gif`,
    `${process.env.REACT_APP_CDN_URL}/web/smileys/ipatla.gif`,
    `${process.env.REACT_APP_CDN_URL}/web/smileys/futbol.gif`,
    `${process.env.REACT_APP_CDN_URL}/web/smileys/ninja.gif`,
    `${process.env.REACT_APP_CDN_URL}/web/smileys/kurbaga.gif`,
    `${process.env.REACT_APP_CDN_URL}/web/smileys/muz.gif`,
    `${process.env.REACT_APP_CDN_URL}/web/smileys/uzgun.gif`,
    `${process.env.REACT_APP_CDN_URL}/web/smileys/kahkaha2.gif`,
    `${process.env.REACT_APP_CDN_URL}/web/smileys/palamut.gif`,
    `${process.env.REACT_APP_CDN_URL}/web/smileys/gran.gif`,
    `${process.env.REACT_APP_CDN_URL}/web/smileys/butterfly.gif`,
    `${process.env.REACT_APP_CDN_URL}/web/smileys/hearteyesmonkey.gif`,
    `${process.env.REACT_APP_CDN_URL}/web/smileys/octopus.gif`,
    `${process.env.REACT_APP_CDN_URL}/web/smileys/dil.gif`,
    `${process.env.REACT_APP_CDN_URL}/web/smileys/dil2.gif`,
    `${process.env.REACT_APP_CDN_URL}/web/smileys/dil3.gif`,
    `${process.env.REACT_APP_CDN_URL}/web/smileys/giris.gif`,
    `${process.env.REACT_APP_CDN_URL}/web/smileys/kalp.gif`,
    `${process.env.REACT_APP_CDN_URL}/web/smileys/onay.gif`,
    `${process.env.REACT_APP_CDN_URL}/web/smileys/red.gif`,
    `${process.env.REACT_APP_CDN_URL}/web/smileys/onay2.gif`,
    `${process.env.REACT_APP_CDN_URL}/web/smileys/love.gif`,
    `${process.env.REACT_APP_CDN_URL}/web/smileys/pcask.gif`,
    `${process.env.REACT_APP_CDN_URL}/web/smileys/ipatla2.gif`,
    `${process.env.REACT_APP_CDN_URL}/web/smileys/noeldans.gif`,
    `${process.env.REACT_APP_CDN_URL}/web/smileys/zipla.gif`,
    `${process.env.REACT_APP_CDN_URL}/web/smileys/kazdans.gif`,
    `${process.env.REACT_APP_CDN_URL}/web/smileys/kalp2.gif`,
    `${process.env.REACT_APP_CDN_URL}/web/smileys/heyecan.gif`,
    `${process.env.REACT_APP_CDN_URL}/web/smileys/pika1.gif`,
    `${process.env.REACT_APP_CDN_URL}/web/smileys/pika2.gif`,
    `${process.env.REACT_APP_CDN_URL}/web/smileys/gozdon.gif`,
    `${process.env.REACT_APP_CDN_URL}/web/smileys/muck.gif`,
    `${process.env.REACT_APP_CDN_URL}/web/smileys/muck2.gif`,
    `${process.env.REACT_APP_CDN_URL}/web/smileys/kedid.gif`,
    `${process.env.REACT_APP_CDN_URL}/web/smileys/seytan2.gif`,
    `${process.env.REACT_APP_CDN_URL}/web/smileys/gozkirp.gif`,
    `${process.env.REACT_APP_CDN_URL}/web/smileys/kufur2.gif`,
    `${process.env.REACT_APP_CDN_URL}/web/smileys/copadam.gif`,
    `${process.env.REACT_APP_CDN_URL}/web/smileys/manblowkiss.gif`,
    `${process.env.REACT_APP_CDN_URL}/web/smileys/manshrug.gif`,
    `${process.env.REACT_APP_CDN_URL}/web/smileys/womanblowkiss.gif`,
    `${process.env.REACT_APP_CDN_URL}/web/smileys/womanshrug.gif`,
    `${process.env.REACT_APP_CDN_URL}/web/smileys/shopping.gif`,
    `${process.env.REACT_APP_CDN_URL}/web/smileys/cash.gif`,
    `${process.env.REACT_APP_CDN_URL}/web/smileys/time.gif`,
    `${process.env.REACT_APP_CDN_URL}/web/smileys/loveletter.gif`,
    `${process.env.REACT_APP_CDN_URL}/web/smileys/lovegift.gif`,
    `${process.env.REACT_APP_CDN_URL}/web/smileys/dans3.gif`,
    `${process.env.REACT_APP_CDN_URL}/web/smileys/civciv.gif`,
    `${process.env.REACT_APP_CDN_URL}/web/smileys/hulahop.gif`,
    `${process.env.REACT_APP_CDN_URL}/web/smileys/dondon.gif`,
    `${process.env.REACT_APP_CDN_URL}/web/smileys/gulver.gif`,
    `${process.env.REACT_APP_CDN_URL}/web/smileys/patla.gif`,
    `${process.env.REACT_APP_CDN_URL}/web/smileys/yanak.gif`,
    `${process.env.REACT_APP_CDN_URL}/web/smileys/muzik.gif`,
    `${process.env.REACT_APP_CDN_URL}/web/smileys/cekic.gif`,
    `${process.env.REACT_APP_CDN_URL}/web/smileys/tilki1.gif`,
    `${process.env.REACT_APP_CDN_URL}/web/smileys/tilki2.gif`,
    `${process.env.REACT_APP_CDN_URL}/web/smileys/cool2.gif`,
    `${process.env.REACT_APP_CDN_URL}/web/smileys/deli.gif`,
    `${process.env.REACT_APP_CDN_URL}/web/smileys/sus.gif`,
    `${process.env.REACT_APP_CDN_URL}/web/smileys/op.gif`,
    `${process.env.REACT_APP_CDN_URL}/web/smileys/kitap.gif`,
    `${process.env.REACT_APP_CDN_URL}/web/smileys/huzun.gif`,
    `${process.env.REACT_APP_CDN_URL}/web/smileys/agla.gif`,
    `${process.env.REACT_APP_CDN_URL}/web/smileys/kart.gif`,
    `${process.env.REACT_APP_CDN_URL}/web/smileys/sustum.gif`,
    `${process.env.REACT_APP_CDN_URL}/web/smileys/picak.gif`,
    `${process.env.REACT_APP_CDN_URL}/web/smileys/dolar.gif`,
    `${process.env.REACT_APP_CDN_URL}/web/smileys/gozluk.gif`,
    `${process.env.REACT_APP_CDN_URL}/web/smileys/futbol2.gif`,
    `${process.env.REACT_APP_CDN_URL}/web/smileys/sakin.gif`,
    `${process.env.REACT_APP_CDN_URL}/web/smileys/tr3.gif`,
    `${process.env.REACT_APP_CDN_URL}/web/smileys/alev1.gif`,
    `${process.env.REACT_APP_CDN_URL}/web/smileys/alev2.gif`,
    `${process.env.REACT_APP_CDN_URL}/web/smileys/alev3.gif`,
    `${process.env.REACT_APP_CDN_URL}/web/smileys/alev4.gif`,
    `${process.env.REACT_APP_CDN_URL}/web/smileys/alev5.gif`,
    `${process.env.REACT_APP_CDN_URL}/web/smileys/alev6.gif`,
    `${process.env.REACT_APP_CDN_URL}/web/smileys/alev7.gif`,
    `${process.env.REACT_APP_CDN_URL}/web/smileys/dov.gif`,
    `${process.env.REACT_APP_CDN_URL}/web/smileys/kurbaga2.gif`,
    `${process.env.REACT_APP_CDN_URL}/web/smileys/muz2.gif`,
    `${process.env.REACT_APP_CDN_URL}/web/smileys/cop2.gif`,
    `${process.env.REACT_APP_CDN_URL}/web/smileys/poo.gif`,
    `${process.env.REACT_APP_CDN_URL}/web/smileys/salla.gif`,
    `${process.env.REACT_APP_CDN_URL}/web/smileys/buyur.gif`,
    `${process.env.REACT_APP_CDN_URL}/web/smileys/fincan.gif`,
    `${process.env.REACT_APP_CDN_URL}/web/smileys/fincan2.gif`,
    `${process.env.REACT_APP_CDN_URL}/web/smileys/kahve2.gif`,
    `${process.env.REACT_APP_CDN_URL}/web/smileys/dikkat.gif`,
    `${process.env.REACT_APP_CDN_URL}/web/smileys/yukle.gif`,
    `${process.env.REACT_APP_CDN_URL}/web/smileys/kusak.gif`,
    `${process.env.REACT_APP_CDN_URL}/web/smileys/kalp3.gif`,
    `${process.env.REACT_APP_CDN_URL}/web/smileys/kalp4.gif`,
    `${process.env.REACT_APP_CDN_URL}/web/smileys/kalp5.gif`,
    `${process.env.REACT_APP_CDN_URL}/web/smileys/mincir.gif`,
    `${process.env.REACT_APP_CDN_URL}/web/smileys/saril.gif`,
    `${process.env.REACT_APP_CDN_URL}/web/smileys/dikiz.gif`,
    `${process.env.REACT_APP_CDN_URL}/web/smileys/gitar2.gif`,
    `${process.env.REACT_APP_CDN_URL}/web/smileys/paytak.gif`,
    `${process.env.REACT_APP_CDN_URL}/web/smileys/horoz.gif`,
    `${process.env.REACT_APP_CDN_URL}/web/smileys/kurt.gif`,
    `${process.env.REACT_APP_CDN_URL}/web/smileys/mavlana.gif`,
    `${process.env.REACT_APP_CDN_URL}/web/smileys/hiphop2.gif`,
    `${process.env.REACT_APP_CDN_URL}/web/smileys/casper.gif`,
    `${process.env.REACT_APP_CDN_URL}/web/smileys/uzayli.gif`,
    `${process.env.REACT_APP_CDN_URL}/web/smileys/kafa.gif`,
    `${process.env.REACT_APP_CDN_URL}/web/smileys/supurge.gif`,
    `${process.env.REACT_APP_CDN_URL}/web/smileys/sigara.gif`,
    `${process.env.REACT_APP_CDN_URL}/web/smileys/sigara2.gif`,

];

export const loadedResources = {};