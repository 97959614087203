import React from "react";
import { connect } from "react-redux";
import { withStyles } from "@mui/styles";
import PropTypes from "prop-types";
import { Box } from "@mui/material";
import { samePieces } from "../utils/piece";
import clsx from "clsx";
import { setNotification } from "../actions/global";
import { getPath } from "../global/route";
import { loadedResources } from "../global/resources";

const styles = theme => ({
});

const COLORS = {
    BLACK: "#5F505C",
    BLUE: "#18568C",
    RED: "#D43B34",
    YELLOW: "#D9E361"
}

const lefts = [
    "min-[312px]:left-0", "min-[312px]:left-[calc(100%/13)]", "min-[312px]:left-[calc(200%/13)]", "min-[312px]:left-[calc(300%/13)]", "min-[312px]:left-[calc(400%/13)]", "min-[312px]:left-[calc(500%/13)]", "min-[312px]:left-[calc(600%/13)]", "min-[312px]:left-[calc(700%/13)]", "min-[312px]:left-[calc(800%/13)]", "min-[312px]:left-[calc(900%/13)]", "min-[312px]:left-[calc(1000%/13)]", "min-[312px]:left-[calc(1100%/13)]", "min-[312px]:left-[calc(1200%/13)]",
];

class Tile extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dragging: false,
        }

        this.tileRef = React.createRef();
        this.lastX = 0;
        this.lastY = 0;
        this.dragX = 0;
        this.dragY = 0;
        this.offsetX = 0;
        this.offsetY = 0;
    }

    componentDidMount() {
        this.props.onRef?.(this);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.onDragEnd && !this.props.onDragEnd && this.state.dragging) {
            this.onDragEnd({ clientX: 0, clientY: 0 });
        }
    }

    shouldComponentUpdate(nextProps, nextState) {

        if (nextProps.isLandscape !== this.props.isLandscape)
            return true;

        if (nextState.dragging !== this.state.dragging)
            return true;

        if (nextProps.value !== this.props.value)
            return true;

        if (nextProps.selectedTableIndicator !== this.props.selectedTableIndicator)
            return true;

        if (nextProps.text !== this.props.text)
            return true;

        if (nextProps.preview !== this.props.preview)
            return true;

        return false;
    }

    left = () => {
        if (this.props.position)
            return '';

        if (this.props.index >= 26 && !this.state.dragging)
            return '';

        if (this.state.dragging) {
            return '';
        }

        const index = this.props.index;
        const k = index % 13;

        return clsx(
            lefts[k],
            `lg:left-[${34 * k}px]`,
            `xl:left-[${45 * k}px]`,
        );
    }

    width = () => {
        const { isLandscape } = this.props;

        if (this.props.index >= 26) {
            return clsx(
                `w-0`,
                isLandscape ? `w-[calc(55.2vw/13)]` : `min-[312px]:w-[calc(92vw/13)]`,
                `md:w-[calc(55.2vw/13)]`,
                `lg:w-[34px]`,
                `xl:w-[45px]`,
            );
        }

        return clsx(
            `w-0`,
            isLandscape ? `w-[calc(55.2vw/13)]` : `min-[312px]:w-[calc(100%/13)]`,
            `md:w-[calc(55.2vw/13)]`,
            `lg:w-[34px]`,
            `xl:w-[45px]`,
        );
    }

    bottom = () => {
        if (this.props.position)
            return '';

        if (this.props.index >= 26 && !this.state.dragging)
            return '';

        if (this.state.dragging) {
            return '';
        }

        const index = this.props.index;
        const bottom = 1 - (~~(index / 13));

        if (bottom === 0) {
            return clsx(
                `min-[312px]:bottom-[4%]`,
                `lg:bottom-[4px]`,
                `xl:bottom-[6px]`,
            );
        }

        return clsx(
            `min-[312px]:bottom-[54%]`,
            `lg:bottom-[56px]`,
            `xl:bottom-[74px]`,
        );
    }

    height = () => {
        return `h-auto`
    }

    img = () => {
        const { value, selectedTableIndicator: indicator } = this.props;

        if (!this.props.value) {
            return "0.png";
        }

        const ci = value > 104 ? ~~((indicator - 1) / 26) : ~~((value - 1) / 26);
        const cix = ~~((value - 1) / 26);

        const d = value > 104 ? ((indicator) % 13) + 1 : ((value - 1) % 13) + 1;

        const isOkey = samePieces(value, (indicator % 13) === 0 ? indicator - 12 : indicator + 1) && !this.props.pot;

        const image = isOkey ? '0.png' : cix === 4 ? '4.png' : `${ci}_${d}.png`;
        return image;
    }

    pos = () => {
        if (this.props.position)
            return this.props.position;
        if (this.state.dragging)
            return 'fixed';
        if (this.props.index >= 26)
            return 'relative';
        return 'absolute';
    }

    text = () => {
        const { text, isLandscape } = this.props;
        if (!text || this.state.dragging)
            return null;

        return (
            <Box top={0} left={0} width={"100%"} height={"100%"} sx={{ pointerEvents: 'none' }}
                className="hidden min-[312px]:flex absolute"
            >
                <Box
                    className={clsx(
                        `w-0 h-0`,
                        isLandscape ? `w-[20px] h-[20px] text-[12px]` : `min-[312px]:w-[16px] min-[312px]:h-[16px] min-[312px]:text-[10px]`,
                        `min-[482px]:w-[20px] min-[482px]:h-[20px] min-[482px]:text-[12px]`,
                        `min-[637px]:w-[26px] min-[637px]:h-[26px] min-[637px]:text-[16px]`,
                        `lg:w-[20px] lg:h-[20px] lg:text-[12px]`,
                        `xl:w-[26px] xl:h-[26px] xl:text-[16px]`
                    )}
                    alignSelf={"center"} justifySelf={"center"} m={"auto"} textAlign={"center"}
                    sx={{
                        userSelect: 'none', backgroundColor: '#D9D9D9',
                        borderRadius: '50%', color: '#444',
                        textAlign: 'center',
                    }}
                >
                    {text}
                </Box>
            </Box>
        );
    }

    movePiece = (e) => {
        if (this.tileRef.current) {
            if (this.lastX === 0 && this.lastY === 0) {
                const rect = this.tileRef.current.getBoundingClientRect();
                this.offsetX = rect.left + rect.width / 2;
                this.offsetY = rect.top + rect.height / 2;
            }

            const deltaX = this.lastX - (e.clientX);
            const deltaY = this.lastY - (e.clientY);
            this.lastX = e.clientX;
            this.lastY = e.clientY;
            this.dragX -= deltaX;
            this.dragY -= deltaY;

            this.tileRef.current.style.transform = `translate3d(${this.dragX - this.offsetX}px, ${this.dragY - this.offsetY}px, 0)`;
        }
    }

    resetDragging = () => {
        if (this.tileRef.current) {
            this.tileRef.current.style.transform = '';
            this.lastX = 0;
            this.lastY = 0;
            this.dragX = 0;
            this.dragY = 0;
            this.offsetX = 0;
            this.offsetY = 0;
        }
    }

    onMouseUp = (e) => {
        this.setState({ dragging: false });
    }

    touchMove = (e) => {
        this.movePiece({ clientX: e.touches[0].clientX, clientY: e.touches[0].clientY });
    }

    onDragStart = (e, isMobile) => {
        if (!this.props.onDragStart)
            return;

        if (!isMobile && e.button !== 0)
            return;

        window.addEventListener('mousemove', this.movePiece);
        window.addEventListener('mouseup', this.onDragEnd);
        window.addEventListener('touchmove', this.touchMove);
        window.addEventListener('touchend', this.onTouchEnd);

        this.setState({ dragging: true }, () => {
            this.movePiece({ clientX: isMobile ? e.touches[0].clientX : e.clientX, clientY: isMobile ? e.touches[0].clientY : e.clientY })
            this.props.onDragStart?.(this.props.value, this.props.index);
        });
    }

    onDragEnd = (e) => {
        window.removeEventListener('mousemove', this.movePiece);
        window.removeEventListener('mouseup', this.onDragEnd);
        window.removeEventListener('touchmove', this.touchMove);
        window.removeEventListener('touchend', this.onTouchEnd);

        this.props.onDragEnd?.(e.clientX, e.clientY, this.props.index);
        this.onMouseUp();
        this.resetDragging();
    }

    onTouchEnd = (e) => {
        window.removeEventListener('mousemove', this.movePiece);
        window.removeEventListener('mouseup', this.onDragEnd);
        window.removeEventListener('touchmove', this.touchMove);
        window.removeEventListener('touchend', this.onTouchEnd);


        this.props.onDragEnd?.(this.lastX, this.lastY, this.props.index);
        this.onMouseUp();
        this.resetDragging();
    }

    onDoubleClick = (e) => {
        e.preventDefault();
        e.stopPropagation();
        this.props.onDoubleClick?.(e, this.props.index);
    }

    render() {
        const { value, preview } = this.props;
        const hasValue = Number.isInteger(value);

        return (
            <Box draggable={false} ref={this.tileRef}
                zIndex={this.state.dragging ? 10 : 1}
                onMouseDown={hasValue && !preview ? (e) => this.onDragStart(e, false) : null}
                onMouseUp={hasValue && !preview ? this.onDragEnd : null}
                onDoubleClick={hasValue && !preview ? this.onDoubleClick : null}
                //onContextMenu={e => e.preventDefault()}
                onTouchStart={hasValue && !preview ? (e) => this.onDragStart(e, true) : null}
                onTouchEnd={hasValue && !preview ? this.onTouchEnd : null}
                sx={{
                    padding: 0, touchAction: 'none',
                    cursor: hasValue && !preview ? 'pointer !important' : 'default !important',
                    //outline: this.props.dragging && !hasValue ? '1px solid #3581B8' : 'none',
                    userSelect: 'none', //transition: 'all 0.1s ease-in-out',
                }}
                className={clsx(
                    this.pos(), this.height(), this.width(),
                    this.left(), this.bottom(), !hasValue ? '!h-1/2' : ''
                )}
            >
                <img draggable={false} alt="tile" src={loadedResources[`${process.env.REACT_APP_CDN_URL}/web/pieces/${this.img()}`]}
                    style={{
                        height: '100%', width: '100%', touchAction: 'none', userSelect: 'none',
                        visibility: hasValue ? 'visible' : 'hidden',
                        pointerEvents: 'none',
                    }}
                    onContextMenu={e => e.preventDefault()}
                />
                {this.text()}
            </Box>
        );
    }
}

Tile.propTypes = {
    classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
    const selectedTable = state.app.global.selectedTable;

    return {
        selectedTableIndicator: state.app.table.tables[selectedTable]?.indicator,
    }
};

export default withStyles(styles)(connect(mapStateToProps, {
    setNotification
})(Tile));
