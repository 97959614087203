export const shortcuts = {
    "smile.gif": ":)",
    "laugh.gif": ":D",
    "wink.gif": ";)",
    "tongueout.gif": ":P",
    "hi.gif": "(bye)",
    "angry.gif": ">:(",
    "cry.gif": ":'(",
    "sad.gif": ":(",
    "surprised.gif": ":O",
    "loudlycrying.gif": ":((",
    "blush.gif": "(utanma)",
    "dream.gif": "(hayal)",
    "ttm.gif": "(cene)",
    "slap.gif": "(slap)",
    "tired.gif": "(uyku)",
    "ill.gif": "(hasta)",
    "selfie.gif": "(selfie)",
    "kiss.gif": ":*",
    "worry.gif": ":S",
    "cool.gif": "(cool)",
    "shake.gif": "(n)",
    "nod.gif": "(y)",
    "wonder.gif": ":-)",
    "cwl.gif": "(kahkaha)",
    "lipssealed.gif": "(agzibagli)",
    "headbang.gif": "(duvara)",
    "inlove.gif": "(asik)",
    "devil.gif": "(seytan)",
    "melek.gif": "(melek)",
    "sarcastic.gif": "(alay)",
    "clap.gif": "(alkis)",
    "donttalktome.gif": "(hih)",
    "unamused.gif": "(peh)",
    "rock.gif": "(rock)",
    "highfive.gif": "(cakbes)",
    "swear.gif": "(kufur)",
    "puke.gif": "(boe)",
    "rofl.gif": "(zuha)",
    "punch.gif": "(boks)",
    "hungover.gif": ":l",
    "makeup.gif": "(ruj)",
    "fikir.gif": "(fikir)",
    "facepalm.gif": "(bezdim)",
    "xd.gif": "(xd)",
    "wait.gif": "(bekle)",
    "eg.gif": "(cakal)",
    "nerdy.gif": "(capkin)",
    "drunk.gif": "(sarhos)",
    "sasi.gif": "(i)",
    "sasi2.gif": "(sasi2)",
    "call.gif": "(ara)",
    "headphones.gif": "(m)",
    "ciglik.gif": "(ciglik)",
    "gipta.gif": "(gipta)",
    "usudum.gif": "(usudum)",
    "tersil.gif": "(tersil)",
    "gormeyeyim.gif": "(gormeyeyim)",
    "whistle.gif": "(islik)",
    "maske.gif": "(maske)",
    "maske2.gif": "(maske2)",
    "bebek.gif": "(bebek)",
    "trip.gif": "(trip)",
    "bvampir.gif": "(bvampir)",
    "kizgin.gif": "(kizgin)",
    "cakmakyak.gif": "(cakmakyak)",
    "hey2.gif": "(hey2)",
    "dans33.gif": "(dans33)",
    "dans34.gif": "(dans34)",
    "face2.gif": "(face2)",
    "hmm.gif": "(hmm)",
    "sapka.gif": "(sapka)",
    "sarki.gif": "(sarki)",
    "star.gif": "(star)",
    "yildiz.gif": "(yildiz)",
    "ates.gif": "(ates)",
    "cat.gif": "(kedi)",
    "penguin.gif": "(pen)",
    "penguinkiss.gif": "(kiss)",
    "monkeygiggle.gif": "(haha)",
    "monkey.gif": "(maymun)",
    "cay.gif": "(cay)",
    "coffee.gif": "(kahve)",
    "cake.gif": "(pasta)",
    "lips.gif": "(opucuk)",
    "heart.gif": "<3",
    "brokenheart.gif": "(kirik)",
    "gottarun.gif": "(kac)",
    "skipping.gif": "(s)",
    "yoga.gif": "(yoga)",
    "bhangra.gif": "(zeybek)",
    "sturridge15.gif": "(kopkop)",
    "discodancer.gif": "(disco)",
    "dance.gif": "(dans)",
    "lang.gif": "(tey)",
    "henderson14.gif": "(gol)",
    "stop.gif": "(dur)",
    "ring.gif": "(yuzuk)",
    "computerrage.gif": "(pc)",
    "muscle.gif": "(guc)",
    "handsinair.gif": "(hop)",
    "yes.gif": "(yes)",
    "no.gif": "(no)",
    "dropthemic.gif": "(mikbirak)",
    "clappinghands.gif": "(alkis2)",
    "poke.gif": "(heysen)",
    "fist.gif": "(yumruk)",
    "hug.gif": "(kucak)",
    "goodluck.gif": "(sans)",
    "flower.gif": "(cicek)",
    "diya.gif": "(mum)",
    "fireworks.gif": "(pat)",
    "whatsgoingon.gif": "(soru)",
    "dance2.gif": "(d1)",
    "hashas.gif": "(d2)",
    "para.gif": "(d3)",
    "ask.gif": "(ask)",
    "dans2.gif": "(dans2)",
    "dans5.gif": "(dans5)",
    "oyna3.gif": "(oyna3)",
    "oyna4.gif": "(oyna4)",
    "oyna5.gif": "(oyna5)",
    "oyna6.gif": "(oyna6)",
    "horon.gif": "(horon)",
    "salla2.gif": "(salla2)",
    "tey2.gif": "(tey2)",
    "teklif.gif": "(teklif)",
    "azerbaycan.gif": "(azerbaycan)",
    "tr1.gif": "(tr)",
    "tr2.gif": "(tr2)",
    "dur2.gif": "(dur2)",
    "tava.gif": "(tava)",
    "umu.gif": "(umu)",
    "hoho.gif": "(hoho)",
    "saklannn.gif": "(saklan)",
    "oyun.gif": "(hımm)",
    "oyun1.gif": "(kafaaa)",
    "oyun2.gif": "(kivirr)",
    "oyun3.gif": "(oynabenle)",
    "misir.gif": "(mısır)",
    "yagmur1.gif": "(kalpyagmur)",
    "kelebek1.gif": "(kelebek)",
    "oyna14.gif": "(dans6)",
    "kahkaha1.gif": "(kahkaha1)",
    "gitar.gif": "(gitar)",
    "panda1.gif": "(heyhey)",
    "panda2.gif": "(hoyhoy)",
    "davul.gif": "(davul)",
    "tatli.gif": "(tatli)",
    "muzik2.gif": "(muzik2)",
    "hiphop.gif": "(hiphop)",
    "superhero.gif": "(superhero)",
    "supermum.gif": "(supermum)",
    "ipatla.gif": "(ipatla)",
    "futbol.gif": "(futbol)",
    "ninja.gif": "(ninja)",
    "kurbaga.gif": "(kurbaga)",
    "muz.gif": "(muz)",
    "uzgun.gif": "(uzgun)",
    "kahkaha2.gif": "(kahkaha2)",
    "palamut.gif": "(palamut)",
    "gran.gif": "(gran)",
    "butterfly.gif": "(kelebek2)",
    "hearteyesmonkey.gif": "(maymuna)",
    "octopus.gif": "(ahtapot)",
    "dil.gif": "(dil)",
    "dil2.gif": "(dil2)",
    "dil3.gif": "(dil3)",
    "giris.gif": "(giris)",
    "kalp.gif": "(kalp)",
    "onay.gif": "(onay)",
    "red.gif": "(red)",
    "onay2.gif": "(onay2)",
    "love.gif": "(love)",
    "pcask.gif": "(pcask)",
    "ipatla2.gif": "(ipatla2)",
    "noeldans.gif": "(noeldans)",
    "zipla.gif": "(zipla)",
    "kazdans.gif": "(kazdans)",
    "kalp2.gif": "(kalp2)",
    "heyecan.gif": "(heyecan)",
    "pika1.gif": "(pika1)",
    "pika2.gif": "(pika2)",
    "gozdon.gif": "(gozdon)",
    "muck.gif": "(muck)",
    "muck2.gif": "(muck2)",
    "kedid.gif": "(kedid)",
    "seytan2.gif": "(seytan2)",
    "gozkirp.gif": "(gozkirp)",
    "kufur2.gif": "(kufur2)",
    "copadam.gif": "(copadam)",
    "manblowkiss.gif": "(manblowkiss)",
    "manshrug.gif": "(manshrug)",
    "womanblowkiss.gif": "(womanblowkiss)",
    "womanshrug.gif": "(womanshrug)",
    "shopping.gif": "(shopping)",
    "cash.gif": "(cash)",
    "time.gif": "(time)",
    "loveletter.gif": "(loveletter)",
    "lovegift.gif": "(lovegift)",
    "dans3.gif": "(dans3)",
    "civciv.gif": "(civciv)",
    "hulahop.gif": "(hulahop)",
    "dondon.gif": "(dondon)",
    "gulver.gif": "(gulver)",
    "patla.gif": "(patla)",
    "yanak.gif": "(yanak)",
    "muzik.gif": "(muzik)",
    "cekic.gif": "(cekic)",
    "tilki1.gif": "(tilki1)",
    "tilki2.gif": "(tilki2)",
    "cool2.gif": "(cool2)",
    "deli.gif": "(deli)",
    "sus.gif": "(sus)",
    "op.gif": "(öp)",
    "kitap.gif": "(kitap)",
    "huzun.gif": "(hüzün)",
    "agla.gif": "(ağla)",
    "kart.gif": "(kart)",
    "sustum.gif": "(sustum)",
    "picak.gif": "(pıçak)",
    "dolar.gif": "(dolar)",
    "gozluk.gif": "(gözlük)",
    "futbol2.gif": "(futbol2)",
    "sakin.gif": "(sakın)",
    "tr3.gif": "(tr3)",
    "alev1.gif": "(alev1)",
    "alev2.gif": "(alev2)",
    "alev3.gif": "(alev3)",
    "alev4.gif": "(alev4)",
    "alev5.gif": "(alev5)",
    "alev6.gif": "(alev6)",
    "alev7.gif": "(alev7)",
    "dov.gif": "(döv)",
    "kurbaga2.gif": "(kurgaba2)",
    "muz2.gif": "(muz2)",
    "cop2.gif": "(çöp2)",
    "poo.gif": "(poo)",
    "salla.gif": "(salla)",
    "buyur.gif": "(buyur)",
    "fincan.gif": "(fincan)",
    "fincan2.gif": "(fincan2)",
    "kahve2.gif": "(kahve2)",
    "dikkat.gif": "(dikkat)",
    "yukle.gif": "(yükle)",
    "kusak.gif": "(kuşak)",
    "kalp3.gif": "(kalp3)",
    "kalp4.gif": "(kalp4)",
    "kalp5.gif": "(kalp5)",
    "mincir.gif": "(mıncır)",
    "saril.gif": "(sarıl)",
    "dikiz.gif": "(dikiz)",
    "gitar2.gif": "(gitar2)",
    "paytak.gif": "(paytak)",
    "horoz.gif": "(horoz)",
    "kurt.gif": "(kurt)",
    "mavlana.gif": "(mavlana)",
    "hiphop2.gif": "(hiphop2)",
    "casper.gif": "(casper)",
    "uzayli.gif": "(uzaylı)",
    "kafa.gif": "(kafa)",
    "supurge.gif": "(supurge)",
    "sigara.gif": "(sigara)",
    "sigara2.gif": "(sigara2)",
}

export const smileyMap = {
    ":)": "smile.gif",
    ":D": "laugh.gif",
    ";)": "wink.gif",
    ":P": "tongueout.gif",
    "(bye)": "hi.gif",
    ">:(": "angry.gif",
    ":'(": "cry.gif",
    ":(": "sad.gif",
    ":O": "surprised.gif",
    ":((": "loudlycrying.gif",
    "(utanma)": "blush.gif",
    "(hayal)": "dream.gif",
    "(cene)": "ttm.gif",
    "(slap)": "slap.gif",
    "(uyku)": "tired.gif",
    "(hasta)": "ill.gif",
    "(selfie)": "selfie.gif",
    ":*": "kiss.gif",
    ":S": "worry.gif",
    "(cool)": "cool.gif",
    "(n)": "shake.gif",
    "(y)": "nod.gif",
    ":-)": "wonder.gif",
    "(kahkaha)": "cwl.gif",
    "(agzibagli)": "lipssealed.gif",
    "(duvara)": "headbang.gif",
    "(asik)": "inlove.gif",
    "(seytan)": "devil.gif",
    "(melek)": "melek.gif",
    "(alay)": "sarcastic.gif",
    "(alkis)": "clap.gif",
    "(hih)": "donttalktome.gif",
    "(peh)": "unamused.gif",
    "(rock)": "rock.gif",
    "(cakbes)": "highfive.gif",
    "(kufur)": "swear.gif",
    "(boe)": "puke.gif",
    "(zuha)": "rofl.gif",
    "(boks)": "punch.gif",
    ":l": "hungover.gif",
    "(ruj)": "makeup.gif",
    "(fikir)": "fikir.gif",
    "(bezdim)": "facepalm.gif",
    "(xd)": "xd.gif",
    "(bekle)": "wait.gif",
    "(cakal)": "eg.gif",
    "(capkin)": "nerdy.gif",
    "(sarhos)": "drunk.gif",
    "(i)": "sasi.gif",
    "(sasi2)": "sasi2.gif",
    "(ara)": "call.gif",
    "(m)": "headphones.gif",
    "(ciglik)": "ciglik.gif",
    "(gipta)": "gipta.gif",
    "(usudum)": "usudum.gif",
    "(tersil)": "tersil.gif",
    "(gormeyeyim)": "gormeyeyim.gif",
    "(islik)": "whistle.gif",
    "(maske)": "maske.gif",
    "(maske2)": "maske2.gif",
    "(bebek)": "bebek.gif",
    "(trip)": "trip.gif",
    "(bvampir)": "bvampir.gif",
    "(kizgin)": "kizgin.gif",
    "(cakmakyak)": "cakmakyak.gif",
    "(hey2)": "hey2.gif",
    "(dans33)": "dans33.gif",
    "(dans34)": "dans34.gif",
    "(face2)": "face2.gif",
    "(hmm)": "hmm.gif",
    "(sapka)": "sapka.gif",
    "(sarki)": "sarki.gif",
    "(star)": "star.gif",
    "(yildiz)": "yildiz.gif",
    "(ates)": "ates.gif",
    "(kedi)": "cat.gif",
    "(pen)": "penguin.gif",
    "(kiss)": "penguinkiss.gif",
    "(haha)": "monkeygiggle.gif",
    "(maymun)": "monkey.gif",
    "(cay)": "cay.gif",
    "(kahve)": "coffee.gif",
    "(pasta)": "cake.gif",
    "(opucuk)": "lips.gif",
    "<3": "heart.gif",
    "(kirik)": "brokenheart.gif",
    "(kac)": "gottarun.gif",
    "(s)": "skipping.gif",
    "(yoga)": "yoga.gif",
    "(zeybek)": "bhangra.gif",
    "(kopkop)": "sturridge15.gif",
    "(disco)": "discodancer.gif",
    "(dans)": "dance.gif",
    "(tey)": "lang.gif",
    "(gol)": "henderson14.gif",
    "(dur)": "stop.gif",
    "(yuzuk)": "ring.gif",
    "(pc)": "computerrage.gif",
    "(guc)": "muscle.gif",
    "(hop)": "handsinair.gif",
    "(yes)": "yes.gif",
    "(no)": "no.gif",
    "(mikbirak)": "dropthemic.gif",
    "(alkis2)": "clappinghands.gif",
    "(heysen)": "poke.gif",
    "(yumruk)": "fist.gif",
    "(kucak)": "hug.gif",
    "(sans)": "goodluck.gif",
    "(cicek)": "flower.gif",
    "(mum)": "diya.gif",
    "(pat)": "fireworks.gif",
    "(soru)": "whatsgoingon.gif",
    "(d1)": "dance2.gif",
    "(d2)": "hashas.gif",
    "(d3)": "para.gif",
    "(ask)": "ask.gif",
    "(dans2)": "dans2.gif",
    "(dans5)": "dans5.gif",
    "(oyna3)": "oyna3.gif",
    "(oyna4)": "oyna4.gif",
    "(oyna5)": "oyna5.gif",
    "(oyna6)": "oyna6.gif",
    "(horon)": "horon.gif",
    "(salla2)": "salla2.gif",
    "(tey2)": "tey2.gif",
    "(teklif)": "teklif.gif",
    "(azerbaycan)": "azerbaycan.gif",
    "(tr)": "tr1.gif",
    "(tr2)": "tr2.gif",
    "(dur2)": "dur2.gif",
    "(tava)": "tava.gif",
    "(umu)": "umu.gif",
    "(hoho)": "hoho.gif",
    "(saklan)": "saklannn.gif",
    "(hımm)": "oyun.gif",
    "(kafaaa)": "oyun1.gif",
    "(kivirr)": "oyun2.gif",
    "(oynabenle)": "oyun3.gif",
    "(mısır)": "misir.gif",
    "(kalpyagmur)": "yagmur1.gif",
    "(kelebek)": "kelebek1.gif",
    "(dans6)": "oyna14.gif",
    "(kahkaha1)": "kahkaha1.gif",
    "(gitar)": "gitar.gif",
    "(heyhey)": "panda1.gif",
    "(hoyhoy)": "panda2.gif",
    "(davul)": "davul.gif",
    "(tatli)": "tatli.gif",
    "(muzik2)": "muzik2.gif",
    "(hiphop)": "hiphop.gif",
    "(superhero)": "superhero.gif",
    "(supermum)": "supermum.gif",
    "(ipatla)": "ipatla.gif",
    "(futbol)": "futbol.gif",
    "(ninja)": "ninja.gif",
    "(kurbaga)": "kurbaga.gif",
    "(muz)": "muz.gif",
    "(uzgun)": "uzgun.gif",
    "(kahkaha2)": "kahkaha2.gif",
    "(palamut)": "palamut.gif",
    "(gran)": "gran.gif",
    "(kelebek2)": "butterfly.gif",
    "(maymuna)": "hearteyesmonkey.gif",
    "(ahtapot)": "octopus.gif",
    "(dil)": "dil.gif",
    "(dil2)": "dil2.gif",
    "(dil3)": "dil3.gif",
    "(giris)": "giris.gif",
    "(kalp)": "kalp.gif",
    "(onay)": "onay.gif",
    "(red)": "red.gif",
    "(onay2)": "onay2.gif",
    "(love)": "love.gif",
    "(pcask)": "pcask.gif",
    "(ipatla2)": "ipatla2.gif",
    "(noeldans)": "noeldans.gif",
    "(zipla)": "zipla.gif",
    "(kazdans)": "kazdans.gif",
    "(kalp2)": "kalp2.gif",
    "(heyecan)": "heyecan.gif",
    "(pika1)": "pika1.gif",
    "(pika2)": "pika2.gif",
    "(gozdon)": "gozdon.gif",
    "(muck)": "muck.gif",
    "(muck2)": "muck2.gif",
    "(kedid)": "kedid.gif",
    "(seytan2)": "seytan2.gif",
    "(gozkirp)": "gozkirp.gif",
    "(kufur2)": "kufur2.gif",
    "(copadam)": "copadam.gif",
    "(manblowkiss)": "manblowkiss.gif",
    "(manshrug)": "manshrug.gif",
    "(womanblowkiss)": "womanblowkiss.gif",
    "(womanshrug)": "womanshrug.gif",
    "(shopping)": "shopping.gif",
    "(cash)": "cash.gif",
    "(time)": "time.gif",
    "(loveletter)": "loveletter.gif",
    "(lovegift)": "lovegift.gif",
    "(dans3)": "dans3.gif",
    "(civciv)": "civciv.gif",
    "(hulahop)": "hulahop.gif",
    "(dondon)": "dondon.gif",
    "(gulver)": "gulver.gif",
    "(patla)": "patla.gif",
    "(yanak)": "yanak.gif",
    "(muzik)": "muzik.gif",
    "(cekic)": "cekic.gif",
    "(tilki1)": "tilki1.gif",
    "(tilki2)": "tilki2.gif",
    "(cool2)": "cool2.gif",
    "(deli)": "deli.gif",
    "(sus)": "sus.gif",
    "(öp)": "op.gif",
    "(kitap)": "kitap.gif",
    "(hüzün)": "huzun.gif",
    "(ağla)": "agla.gif",
    "(kart)": "kart.gif",
    "(sustum)": "sustum.gif",
    "(pıçak)": "picak.gif",
    "(dolar)": "dolar.gif",
    "(gözlük)": "gozluk.gif",
    "(futbol2)": "futbol2.gif",
    "(sakın)": "sakin.gif",
    "(tr3)": "tr3.gif",
    "(alev1)": "alev1.gif",
    "(alev2)": "alev2.gif",
    "(alev3)": "alev3.gif",
    "(alev4)": "alev4.gif",
    "(alev5)": "alev5.gif",
    "(alev6)": "alev6.gif",
    "(alev7)": "alev7.gif",
    "(döv)": "dov.gif",
    "(kurgaba2)": "kurbaga2.gif",
    "(muz2)": "muz2.gif",
    "(çöp2)": "cop2.gif",
    "(poo)": "poo.gif",
    "(salla)": "salla.gif",
    "(buyur)": "buyur.gif",
    "(fincan)": "fincan.gif",
    "(fincan2)": "fincan2.gif",
    "(kahve2)": "kahve2.gif",
    "(dikkat)": "dikkat.gif",
    "(yükle)": "yukle.gif",
    "(kuşak)": "kusak.gif",
    "(kalp3)": "kalp3.gif",
    "(kalp4)": "kalp4.gif",
    "(kalp5)": "kalp5.gif",
    "(mıncır)": "mincir.gif",
    "(sarıl)": "saril.gif",
    "(dikiz)": "dikiz.gif",
    "(gitar2)": "gitar2.gif",
    "(paytak)": "paytak.gif",
    "(horoz)": "horoz.gif",
    "(kurt)": "kurt.gif",
    "(mavlana)": "mavlana.gif",
    "(hiphop2)": "hiphop2.gif",
    "(casper)": "casper.gif",
    "(uzaylı)": "uzayli.gif",
    "(kafa)": "kafa.gif",
    "(supurge)": "supurge.gif",
    "(sigara)": "sigara.gif",
    "(sigara2)": "sigara2.gif",
}
