import React from "react";
import { connect } from "react-redux";
import { withStyles } from "@mui/styles";
import PropTypes from "prop-types";
import { presents } from "./presents/index";
import { Grid, Tooltip } from "@mui/material";
import { presentNames, presentPts } from "./present_details";
import clsx from "clsx";
import { getPath } from "../../global/route";
import { loadedResources } from "../../global/resources";

const humanize = require('humanize-number');


const styles = theme => ({
});

class Presents extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            presents: [],
        }

    }

    componentDidMount() {
        this.loadPresents();
    }

    loadPresents = () => {
        this.setState({ presents: presents });
    }

    componentDidUpdate(prevProps) {
    }

    render() {
        return (
            <Grid container
                className={clsx(
                    "max-h-[75vh]",
                    "!w-[204px] sm:!w-[216px] md:!w-[228px] lg:!w-[240px] xl:!w-[252px]"
                )}
            >
                {this.state.presents.map((present) => {
                    return (
                        <Tooltip placement="top" title={`${presentNames[present]} 
                                (${humanize(presentPts[present], { delimiter: '.', separator: ',' })} puan)`}
                        >
                            <Grid item key={present} xs={2} onClick={(e) => this.props.onSelected?.(e, present)}
                                sx={{ cursor: 'pointer', border: '1px solid #cecece', '&:hover': { backgroundColor: '#F8F8F8' } }}
                            >
                                {(() => {
                                    let loadedResource = loadedResources[`${process.env.REACT_APP_CDN_URL}/web/presents/${present}`];
                                    return (
                                        <img src={loadedResource} alt={present}
                                            className={clsx(
                                                "!w-[32px] sm:!w-[34px] md:!w-[36px] lg:!w-[38px] xl:!w-[40px]",
                                                "!h-[32px] sm:!h-[34px] md:!h-[36px] lg:!h-[38px] xl:!h-[40px]"
                                            )}
                                        />
                                    );
                                })()}
                            </Grid>
                        </Tooltip>
                    );
                })}
            </Grid>
        );
    }
}

Presents.propTypes = {
    classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
    return {
    }
};

export default withStyles(styles)(connect(mapStateToProps, {
})(Presents));
