export const countries = {
    'A': 'Avusturya',
    'AFG': 'Afganistan',
    'AG': 'Antigua ve Barbuda',
    'AL': 'Arnavutluk',
    'AND': 'Andorra',
    'ANG': 'Angola',
    'ARK*': 'Antarktika',
    'AM': 'Ermenistan',
    'ARU*': 'Aruba',
    'AUS': 'Avustralya',
    'AXA': 'Anguilla',
    'AZ': 'Azerbaycan',
    'B': 'Belçika',
    'BD': 'Bangladeş',
    'BDS': 'Barbados',
    'BF': 'Burkina Faso',
    'BG': 'Bulgaristan',
    'BHT': 'Butan',
    'BIH': 'Bosna-Hersek',
    'BOL': 'Bolivya',
    'BR': 'Brezilya',
    'BRN': 'Bahreyn',
    'BRU': 'Brunei',
    'BS': 'Bahama',
    'BU': 'Burundi',
    'BY': 'Beyaz Rusya',
    'BZ': 'Belize',
    'C': 'Küba',
    'CAM': 'Kamerun',
    'CDN': 'Kanada',
    'CGO': 'Demokratik Kongo Cumhuriyeti',
    'CH': 'İsviçre',
    'CI': 'Fildişi Sahili',
    'CL': 'Sri Lanka',
    'CN': 'Çin Halk Cumhuriyeti',
    'CO': 'Kolombiya',
    'COM': 'Komor',
    'CR': 'Kosta Rika',
    'CV': 'Cape Verde',
    'CY': 'Kıbrıs Cumhuriyeti',
    'CYM*': 'Galler',
    'CZ': 'Çek Cumhuriyeti',
    'D': 'Almanya',
    'DJI': 'Cibuti',
    'DK': 'Danimarka',
    'DOM': 'Dominik Cumhuriyeti',
    'DY': 'Benin',
    'DZ': 'Cezayir',
    'E': 'İspanya',
    'EAK': 'Kenya',
    'EAT': 'Tanzanya',
    'EAU': 'Uganda',
    'EC': 'Ekvador',
    'ENG*': 'İngiltere',
    'ER': 'Eritre',
    'ES': 'El Salvador',
    'EST': 'Estonya',
    'ET': 'Mısır',
    'ETH': 'Etiyopya',
    'F': 'Fransa',
    'FIN': 'Finlandiya',
    'FJI': 'Fiji',
    'FL': 'Lihtenştayn',
    'FO': 'Faroe Adaları',
    'FSM': 'Mikronezya',
    'G': 'Gabon',
    'GB': 'Birleşik Krallık',
    'GBA': 'Alderney',
    'GBG': 'Guernsey',
    'GBJ': 'Jersey',
    'GBM': 'Man Adası',
    'GBZ': 'Cebelitarık',
    'GCA': 'Guatamala',
    'GE': 'Gürcistan',
    'GH': 'Gana',
    'GQ*': 'Ekvator Ginesi',
    'GR': 'Yunanistan',
    'GUY': 'Guyana',
    'H': 'Macaristan',
    'HK': 'Hong Kong',
    'HKJ': 'Ürdün',
    'HN': 'Honduras',
    'HR': 'Hırvatistan',
    'I': 'İtalya',
    'IL': 'İsrail',
    'IND': 'Hindistan',
    'IR': 'İran',
    'IRL': 'İrlanda',
    'IRQ': 'Irak',
    'IS': 'İzlanda',
    'J': 'Japonya',
    'JA': 'Jamaika',
    'K': 'Kamboçya',
    'KAN*': 'Saint Kitts ve Nevis',
    'KN*': 'Grönland',
    'KS': 'Kırgızistan',
    'KIR': 'Kiribati',
    'KP': 'Kuzey Kore',
    'KSA': 'Suudi Arabistan',
    'KWT': 'Kuveyt',
    'KZ': 'Kazakistan',
    'L': 'Lüksemburg',
    'LAO': 'Laos',
    'LAR*': 'Libya',
    'LB*': 'Liberya',
    'LS': 'Lesoto',
    'LT': 'Litvanya',
    'LV': 'Letonya',
    'M': 'Malta',
    'MA': 'Fas',
    'MAL': 'Malezya',
    'MC': 'Monako',
    'MD': 'Moldova',
    'MEX': 'Meksika',
    'MGL': 'Moğolistan',
    'MH': 'Marshall Adaları',
    'MK': 'Makedonya',
    'MNE': 'Karadağ',
    'MO': 'Makao',
    'MOC': 'Mozambik',
    'MS': 'Mauritius',
    'MV*': 'Maldivler',
    'MW': 'Malavi',
    'MYA': 'Myanmar',
    'N': 'Norveç',
    'NA': 'Hollanda Antilleri',
    'NAM': 'Namibya',
    'NAU': 'Nauru',
    'NC': 'Yeni Kaledonya',
    'NEP*': 'Nepal',
    'NIC': 'Nikaragua',
    'NI*': 'Kuzey İrlanda',
    'NL': 'Hollanda',
    'NZ': 'Yeni Zelanda',
    'OM': 'Umman',
    'P': 'Portekiz',
    'PA': 'Panama',
    'PAK': 'Pakistan',
    'PAL': 'Palau',
    'PE': 'Peru',
    'PL': 'Polonya',
    'PNG*': 'Papua Yeni Gine',
    'PR': 'Porto Riko',
    'PS': 'Filistin',
    'PY': 'Paraguay',
    'Q': 'Katar',
    'RA': 'Arjantin',
    'RB': 'Botsvana',
    'RC': 'Çin Cumhuriyeti (Tayvan)',
    'RCA': 'Orta Afrika Cumhuriyeti',
    'RCB': 'Kongo Cumhuriyeti',
    'RCH': 'Şili',
    'RG': 'Gine',
    'RH': 'Haiti',
    'RI': 'Endonezya',
    'RIM': 'Moritanya',
    'RKS': 'Kosova',
    'RL': 'Lübnan',
    'RM': 'Madagaskar',
    'RMM': 'Mali',
    'RN': 'Nijer',
    'RO': 'Romanya',
    'ROK': 'Güney Kore',
    'ROU': 'Uruguay',
    'RP': 'Filipinler',
    'RSM': 'San Marino',
    'RT': 'Togo',
    'RUS': 'Rusya',
    'RWA': 'Ruanda',
    'S': 'İsveç',
    'SA': 'Suudi Arabistan',
    'SCO*': 'İskoçya',
    'SD': 'Svaziland',
    'SGP': 'Singapur',
    'SK': 'Slovakya',
    'SLE*': 'Sierra Leone',
    'SLO': 'Slovenya',
    'SME': 'Surinam',
    'SMOM': 'Malta Askeri Hükümdarlığı',
    'SN': 'Senegal',
    'SO': 'Somali',
    'SOL': 'Solomon Adaları',
    'SRB': 'Sırbistan',
    'STP': 'Sao Tome ve Principe',
    'SUD*': 'Sudan',
    'SY': 'Seyşeller',
    'SYR': 'Suriye',
    'T': 'Tayland',
    'TCH*': 'Çad',
    'TG*': 'Togo',
    'TJ': 'Tacikistan',
    'TL': 'Doğu Timor',
    'TM': 'Türkmenistan',
    'TN': 'Tunus',
    'TO': 'Tonga',
    'TR': 'Türkiye',
    'TT': 'Togo',
    'TUV': 'Tuvalu',
    'UA': 'Ukrayna',
    'UAE': 'Birleşik Arap Emirlikleri',
    'USA': 'Amerika Birleşik Devletleri',
    'UZ': 'Özbekistan',
    'V': 'Vatikan',
    'VN': 'Vietnam',
    'VU': 'Vanuatu',
    'WAG': 'Gambiya',
    'WAL': 'Sierra Leone',
    'WAN': 'Nijerya',
    'WD': 'Dominika',
    'WG': 'Grenada',
    'WL': 'Saint Lucia',
    'WS': 'Samoa',
    'WSA*': 'Batı Sahra',
    'WV': 'Saint Vincent ve Grenadines',
    'YEM': 'Yemen',
    'YV': 'Venezuela',
    'Z': 'Zambiya',
    'ZA': 'Güney Afrika Cumhuriyeti',
    'ZW': 'Zimbabve',
}