import React from "react";
import { connect } from "react-redux";
import { withStyles } from "@mui/styles";
import PropTypes from "prop-types";
import { Avatar, Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@mui/material";
import axios from "axios";
import { setNotification } from "../../actions/global";
import { GlobalTypes } from "../../action_types";
import dayjs from "dayjs";
import { AxiosClient } from "../../global/axios";

const styles = theme => ({
});

class LogDetails extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            chatLogs: [],
            totalChatLogs: 0,
            page: 1,
            size: 10,
            search: '',
        }

    }

    componentDidMount() {
    }

    componentDidUpdate(prevProps) {
        if (prevProps.log !== this.props.log && this.props.log) {
            this.getLogs();
        }
    }

    getLogs = async () => {
        try {
            const { log, report } = this.props;
            const { page, size, search } = this.state;
            const { startDate, endDate } = this.props;

            let uri = report ?
                `${process.env.REACT_APP_API_URL}/v1/logs/chat.messages?page=${page}&size=${size}&search=${search}&report_id=${log.id}` :
                `${process.env.REACT_APP_API_URL}/v1/logs/chat.messages-7d9cd987-8eca-45f9-bd3c-e46a7c8245f1?page=${page}&size=${size}&search=${search}&from=${log.user_id}&to=${log.room}&pm=${Boolean(log.dm_username)}`

            if (startDate && endDate && !report) {
                uri += `&start_date=${startDate}&end_date=${endDate}`;
            }

            const { data } = await AxiosClient().get(uri);

            this.setState({ chatLogs: data.data, totalChatLogs: data.total });
        }
        catch (err) {
            console.log(err);
            this.props.setNotification({ message: 'Bir hata oluştu. Lütfen tekrar deneyiniz.', severity: GlobalTypes.NOTIFY_ERR });
        }
    }

    onClose = () => {
        this.setState({ chatLogs: [], totalChatLogs: 0, page: 1, size: 10, search: '' });
        this.props.onClose();
    }

    renderMessages = () => {
        const { classes, log } = this.props;
        const { chatLogs } = this.state;

        if (!log || !chatLogs) {
            return null;
        }

        return (
            <Box display={"flex"} flexDirection={"column-reverse"} overflow={"auto"}>
                {chatLogs.map((chatLog, index) => {
                    return (
                        <Box key={index} className="!flex !flex-row !items-center !justify-between !w-full !h-full !p-2 !border-b-2">
                            <Box className="!flex !flex-row !items-center">
                                <Box className="!flex !flex-row !items-center !justify-center !w-12 !h-12 !rounded-full">
                                    <Avatar className="!h-6 !w-6 md:!h-8 md:!w-8 lg:!h-10 lg:!w-10"
                                    //src={`${process.env.REACT_APP_STORAGE_URL}/users/${chatLog.user_id}/photo`}
                                    />
                                </Box>
                                <Box className="!flex !flex-col !ml-2">
                                    <Typography className="!font['Roboto Slab'] !text-[0.8em] md:!text-[0.9em] lg:!text-[1em] !font-bold !text-[#484848]">
                                        {chatLog.username}
                                    </Typography>
                                    <Typography className="!font['Roboto Slab'] !text-[0.7em] md:!text-[0.8em] lg:!text-[0.9em] !text-[#484848]">
                                        {chatLog.message}
                                    </Typography>
                                </Box>
                            </Box>
                            <Box className="!flex !flex-row !items-end h-full">
                                <Typography className="!font['Roboto Slab'] !text-[0.5em] md:!text-[0.6em] lg:!text-[0.7em] !text-[#888]">
                                    {dayjs(chatLog.created_at).format('DD.MM.YYYY HH:mm:ss')}
                                </Typography>
                            </Box>
                        </Box>
                    );
                })}
            </Box>
        );
    }

    render() {
        const { classes, log } = this.props;

        if (!log) {
            return null;
        }

        let title = log.username + ' -> ' + log.room;
        if (log.dm_username) {
            title = log.username + ' <-> ' + log.dm_username;
        }

        return (
            <Dialog open={Boolean(log)} maxWidth="xl" fullWidth>
                <DialogTitle>
                    <Box display={"flex"} columnGap={1}>
                        <Typography className="!font['Roboto Slab'] !text-[0.8em] md:!text-[0.9em]">
                            Sohbet Logları ({title})
                        </Typography>
                    </Box>
                </DialogTitle>

                <DialogContent>
                    <Box overflow={"hidden"} p={2}>
                        {this.state.totalChatLogs > this.state.size && (
                            <Box className="flex justify-center">
                                <Button className="!font['Roboto Slab'] !text-[0.8em] md:!text-[0.9em] lg:!text-[1em]"
                                    variant='contained' sx={{ color: 'var(--white)' }}
                                    onClick={() => this.setState({ size: this.state.size + 10 }, () => this.getLogs())}
                                >
                                    Daha Fazla
                                </Button>
                            </Box>
                        )}
                        {this.renderMessages()}
                    </Box>
                </DialogContent>

                <DialogActions>
                    <Button className="!font['Roboto Slab'] !text-[0.8em] md:!text-[0.9em] lg:!text-[1em]"
                        variant='contained' sx={{ color: 'var(--white)' }} color="error"

                        onClick={this.onClose}
                    >
                        Kapat
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

LogDetails.propTypes = {
    classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
    return {
    }
};

export default withStyles(styles)(connect(mapStateToProps, {
    setNotification,
})(LogDetails));
