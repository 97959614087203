import React from "react";
import { connect } from "react-redux";
import { withStyles } from "@mui/styles";
import PropTypes from "prop-types";
import { Box } from "@mui/system";
import clsx from "clsx";
import { List } from "@mui/material";
import Tile from "../../models/tile";
import { getPath } from "../../global/route";
import { loadedResources } from "../../global/resources";


const styles = theme => ({
});

class Takoz extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        }

    }

    componentDidMount() {
    }

    componentDidUpdate(prevProps) {
    }

    getPieces = () => {
        const { myTablePieces: pieces } = this.props;

        if (!pieces || pieces.length === 0) {
            return [];
        }

        const arr = Array.from(pieces);
        while (arr.length < 26) {
            arr.push(null);
        }

        return arr;
    }

    render() {
        const { isLandscape } = this.props;

        return (
            <Box position={"relative"} justifyContent={"center"}
                className={clsx(
                    "w-full hidden min-[312px]:flex",
                    isLandscape ? "max-w-[60vw]" : "min-[312px]:max-w-full",
                    "md:max-w-[60vw]",
                    "lg:max-w-[482px] lg:h-[104px]",
                    "xl:max-w-[637px] xl:h-[136px]",
                )}
            >
                <img alt="takoz" draggable="false" src={loadedResources[`${process.env.REACT_APP_CDN_URL}/web/img/takoz.png`]}
                    style={{ width: '100%', height: 'auto', userSelect: "none", pointerEvents: "none" }}
                />

                <div className={clsx(
                    "flex w-full absolute top-0 bottom-0 right-0",
                    isLandscape ? "max-w-[92%] left-[4%]" : "min-[312px]:max-w-[92%] min-[312px]:left-[4%]",
                    "md:max-w-[92%] md:left-[4%]",
                    "lg:max-w-[442px] lg:left-[20px]",
                    "xl:max-w-[585px] xl:left-[26px]",
                )}
                >
                    <List component="div" ref={this.props.listRef}
                        sx={{
                            padding: 0, margin: '0 auto', flex: 1,
                        }}
                    >
                        {this.getPieces().map((val, i) => {
                            return (
                                <Tile id={`Piece ${i + 1}`} key={`Piece${i + 1}`} isLandscape={isLandscape}
                                    value={val} index={i} parent={this.props.listRef}
                                    onDragStart={this.props.onDragStart} onDragEnd={this.props.onDragEnd} onMovePiece={this.props.onMovePiece}
                                    onDoubleClick={this.props.onDoubleClick} onRef={(ref) => this.props.tileRefs[i] = ref}
                                />
                            );
                        })}
                    </List>
                </div>
            </Box>
        );
    }
}

Takoz.propTypes = {
    classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
    const myTable = state.app.global.myTable;

    return {
        myTablePieces: state.app.table.tables[myTable]?.pieces,
    }
};

export default withStyles(styles)(connect(mapStateToProps, {
})(Takoz));
