import { TableTypes } from '../action_types';

export function addTable(tableId) {
    return {
        type: TableTypes.ADD_TABLE,
        tableId,
    };
}

export function leaveTable(tableId) {
    return {
        type: TableTypes.LEAVE_TABLE,
        tableId,
    };
}

export function setPieces(tableId, pieces) {
    return {
        type: TableTypes.SET_PIECES,
        tableId,
        pieces
    };
}

export function setGameInfo(tableId, info) {
    return {
        type: TableTypes.SET_GAME_INFO,
        tableId,
        info
    };
}

export function setTurnTime(tableId, data) {
    return {
        type: TableTypes.SET_TURN_TIME,
        tableId,
        data
    };
}

export function setPots(tableId, pots) {
    return {
        type: TableTypes.SET_POTS,
        tableId,
        pots
    };
}

export function setGround(tableId, piecesLeft) {
    return {
        type: TableTypes.SET_GROUND,
        tableId,
        piecesLeft
    };
}

export function setEndRound(tableId, endRound) {
    return {
        type: TableTypes.SET_END_ROUND,
        tableId,
        endRound
    };
}

export function showIndicator(tableId, userId) {
    return {
        type: TableTypes.SHOW_INDICATOR,
        tableId,
        userId
    };
}

export function setDouble(tableId, double) {
    return {
        type: TableTypes.SET_DOUBLE,
        tableId,
        double
    };
}

export function setReturnOkey(tableId, returnOkey) {
    return {
        type: TableTypes.SET_RETURN_OKEY,
        tableId,
        returnOkey
    };
}

export function setMobileChatAt(tableId, at) {
    return {
        type: TableTypes.SET_MOBILE_CHAT_AT,
        tableId,
        at
    };
}