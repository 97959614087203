import React from "react";
import { connect } from "react-redux";
import { withStyles } from "@mui/styles";
import PropTypes from "prop-types";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, MenuItem, SvgIcon, Table, TableCell, TableRow, TextField, Tooltip, Typography } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { Add, AllInclusive, Remove } from "@mui/icons-material";
import dayjs from "dayjs";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import clsx from "clsx";
import axios from "axios";
import { GlobalTypes } from "../../../action_types";
import { setNotification } from "../../../actions/global";
import { AxiosClient } from "../../../global/axios";


const styles = theme => ({
});

class EditPremiumUser extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            username: props.username || props.premiumUser?.username || '',
            description: props.premiumUser?.description || '',
            date: props.unlimited ? null : (props.premiumUser?.valid_until ? dayjs(props.premiumUser.valid_until) : dayjs(new Date()).add(1, 'day')),
            usernameColor: props.premiumUser?.username_color || 501,
            textColor: props.premiumUser?.text_color || 501,
            saving: false,
        }

        this.usernameColors = Array(99).fill(501).map((x, y) => x + y);
        this.textColors = Array(99).fill(501).map((x, y) => x + y);
    }

    componentDidMount() {
    }

    componentDidUpdate(prevProps) {
        if (!prevProps.open && this.props.open) {
            this.setState({
                username: this.props.username || this.props.premiumUser?.username || '',
                description: this.props.premiumUser?.description || '',
                date: this.props.unlimited ? null : (this.props.premiumUser?.valid_until ? dayjs(this.props.premiumUser.valid_until) : dayjs(new Date()).add(1, 'day')),
                usernameColor: this.props.premiumUser?.username_color || 501,
                textColor: this.props.premiumUser?.text_color || 501,
                saving: false,
            });
        }
    }

    onSave = async () => {
        this.setState({ saving: true });

        const premiumUser = {
            username: this.state.username,
            description: this.state.description,
            valid_until: this.state.date ? this.state.date.unix() : null,
            username_color: this.state.usernameColor.toString(),
            text_color: this.state.textColor.toString(),
        };

        if (this.props.premiumUser) {
            premiumUser.id = this.props.premiumUser.id;
        }

        try {
            await AxiosClient().post(`${process.env.REACT_APP_API_URL}/v1/admin/premium`, premiumUser);

            this.props.setNotification({ message: `Premium kullanıcı ${this.props.premiumUser ? 'düzenlendi.' : 'eklendi.'}`, severity: GlobalTypes.NOTIFY_SUCC });
            this.props.onClose(true);

        } catch (error) {
            console.error(error);
            this.props.setNotification({ message: 'Bir hata oluştu. Lütfen tekrar deneyin.', severity: GlobalTypes.NOTIFY_ERR });
        } finally {
            this.setState({ saving: false, username: '', date: dayjs(new Date()), reason: '' });
        }
    }

    render() {
        const { classes } = this.props;

        return (
            <Dialog open={this.props.open} onClose={() => this.props.onClose()}>
                <DialogTitle>
                    <Typography className={classes.title} sx={{ color: '#413067' }}>
                        {`Premium Kullanıcı ${this.props.premiumUser ? 'Düzenle' : 'Ekle'}`}
                    </Typography>
                </DialogTitle>
                <DialogContent>
                    <Table>
                        <TableRow>
                            <TableCell className="!border-0 !p-2 min-w-32">
                                <Typography className={classes.title} sx={{ color: '#413067' }}>
                                    Kullanıcı Adı
                                </Typography>
                            </TableCell>
                            <TableCell className="!border-0 !p-2">
                                <TextField margin="dense" fullWidth
                                    value={this.state.username}
                                    sx={{
                                        borderTopRightRadius: '0 !important',
                                        borderBottomRightRadius: '0 !important',
                                        margin: 0,
                                    }}
                                    InputProps={{
                                        classes: {
                                            input: "h-8 sm:h-12",
                                            root: "h-8 sm:h-12",
                                        }
                                    }}
                                    disabled={Boolean(this.props.premiumUser)}
                                    onChange={(e) => this.setState({ username: e.target.value })}
                                />
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className="!border-0 !p-2 min-w-32">
                                <Typography className={classes.title} sx={{ color: '#413067' }}>
                                    Not
                                </Typography>
                            </TableCell>
                            <TableCell className="!border-0 !p-2">
                                <TextField margin="dense" fullWidth
                                    value={this.state.description}
                                    sx={{
                                        borderTopRightRadius: '0 !important',
                                        borderBottomRightRadius: '0 !important',
                                        margin: 0,
                                    }}
                                    InputProps={{
                                        classes: {
                                            input: "h-8 sm:h-12",
                                            root: "h-8 sm:h-12",
                                        }
                                    }}
                                    onChange={(e) => this.setState({ description: e.target.value })}
                                />
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className="!border-0 !p-2">
                                <Typography className={classes.title} sx={{ color: '#413067' }}>
                                    Tarih
                                </Typography>
                            </TableCell>
                            <TableCell className="!border-0 !p-2">
                                <Box className="flex gap-x-1 items-center">

                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DatePicker format="DD/MM/YYYY" onChange={date => this.setState({ date })} value={this.state.date}
                                            disabled={!Boolean(this.state.date)} disablePast
                                        />
                                    </LocalizationProvider>

                                    <Tooltip title="1 gün azalt" placement="top">
                                        <IconButton onClick={() => this.setState({ date: this.state.date?.subtract?.(1, 'day') ?? dayjs(new Date()) })}
                                            className="w-9 h-9"
                                        >
                                            <SvgIcon>
                                                <Remove />
                                            </SvgIcon>
                                        </IconButton>
                                    </Tooltip>

                                    <Tooltip title="1 gün arttır" placement="top">
                                        <IconButton onClick={() => this.setState({ date: this.state.date?.add?.(1, 'day') ?? dayjs(new Date()) })}
                                            className="w-9 h-9"
                                        >
                                            <SvgIcon>
                                                <Add />
                                            </SvgIcon>
                                        </IconButton>
                                    </Tooltip>

                                    <Tooltip title="Süresiz" placement="top">
                                        <IconButton onClick={() => this.setState({ date: this.state.date ? null : dayjs(new Date()) })}
                                            className={clsx(this.state.date ? null : "!bg-blue-400", "w-9 h-9")}
                                        >
                                            <SvgIcon>
                                                <AllInclusive htmlColor={this.state.date ? null : 'white'} />
                                            </SvgIcon>
                                        </IconButton>
                                    </Tooltip>
                                </Box>
                            </TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell className="!border-0 !p-2">
                                <Typography className={classes.title} sx={{ color: '#413067' }}>
                                    Nick Rengi
                                </Typography>
                            </TableCell>
                            <TableCell className="!border-0 !p-2">
                                <TextField margin="dense" fullWidth select
                                    value={this.state.usernameColor} sx={{
                                        borderTopRightRadius: '0 !important',
                                        borderBottomRightRadius: '0 !important',
                                        margin: 0,
                                    }}
                                    InputProps={{
                                        classes: {
                                            input: "h-8 sm:h-12",
                                            root: "h-8 sm:h-12",
                                        }
                                    }}
                                    onChange={(e) => this.setState({ usernameColor: e.target.value })}
                                >
                                    {this.usernameColors.map((color) => (
                                        <MenuItem key={color} value={color}>
                                            <span className={`NickRenk${color}`}>Nick Rengi {color - 500}</span>
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell className="!border-0 !p-2">
                                <Typography className={classes.title} sx={{ color: '#413067' }}>
                                    Yazı Rengi
                                </Typography>
                            </TableCell>
                            <TableCell className="!border-0 !p-2">
                                <TextField margin="dense" fullWidth select
                                    value={this.state.textColor} sx={{
                                        borderTopRightRadius: '0 !important',
                                        borderBottomRightRadius: '0 !important',
                                        margin: 0,
                                    }}
                                    InputProps={{
                                        classes: {
                                            input: "h-8 sm:h-12",
                                            root: "h-8 sm:h-12",
                                        }
                                    }}
                                    onChange={(e) => this.setState({ textColor: e.target.value })}
                                >
                                    {this.textColors.map((color) => (
                                        <MenuItem key={color} value={color}>
                                            <span className={`YaziRenk${color}`}>Yazı Rengi {color - 500}</span>
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </TableCell>
                        </TableRow>
                    </Table>
                </DialogContent>

                <DialogActions>
                    <Button onClick={() => this.props.onClose()} variant="contained" color="error">
                        İptal
                    </Button>
                    <Button onClick={this.onSave} variant="contained" color="primary"
                        className="!text-white"
                        disabled={!this.state.username || this.state.saving}
                    >
                        Kaydet
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

EditPremiumUser.propTypes = {
    classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
    return {
    }
};

export default withStyles(styles)(connect(mapStateToProps, {
    setNotification,
})(EditPremiumUser));
