import React from "react";
import { connect } from "react-redux";
import { withStyles } from "@mui/styles";
import PropTypes from "prop-types";
import { mdiAccount, mdiAccountOutline, mdiCellphone, mdiChevronLeft, mdiChevronRight, mdiEye, mdiEyeOff, mdiHeart, mdiHeartOutline, mdiLock, mdiMagnify, mdiPhone, mdiPlusCircle, mdiRobot, mdiTuneVertical } from "@mdi/js";
import GameOptions from "./GameOptions";
import Header from "../Header";
import Chatbox from "../Chatbox";
import {
    Avatar, Box, Button, Card, CardContent, CardHeader, Chip, Dialog, DialogActions, DialogContent, DialogTitle, Drawer, Grid, Icon, IconButton, InputAdornment,
    MenuItem,
    SvgIcon, Tab, Tabs, TextField, ThemeProvider, Tooltip, Typography, createTheme
} from "@mui/material";
import UserActions from "./UserActions";
import { setNotification, addChatTab } from "../../actions/global";
import { GlobalTypes } from "../../action_types";
import clsx from "clsx";
import {
    TableRestaurant, HourglassEmpty, CheckCircle,
} from "@mui/icons-material";
import UserList from "./UserList";
import ContextMenuHandler from "../../utils/ContextMenuHandler";
import UserAvatar from "./UserAvatar";
import { deviceIcons } from "../../global/device_icons";
import { getPath } from "../../global/route";
import { CHATBOX_TYPES } from "../../global/chatbox";
import { loadedResources } from "../../global/resources";
import { roles } from "../../global/role";

const humanize = require('humanize-number');

const styles = theme => ({
    root: {
        height: '100vh',
    },
    header: {
        backgroundImage: "linear-gradient(to right, #624799 50%, #1D9AB6)",
        borderBottom: '1px solid #a097b3',
        display: 'flex',
        alignItems: 'center',
        columnGap: 0
    },
    title: {
        fontWeight: '500 !important',
        color: 'var(--white)',
    },
    text: {
        fontWeight: '400 !important',
        color: 'var(--white)',
    },
    tabTitle: {
        fontWeight: '400 !important',
        color: '#505050',
    },
});

const theme = createTheme({
    breakpoints: {
        values: {
            xs: 0,
            sm: 750,
            md: 1280,
            lg: 1600,
            xl: 2120,
        },
    },
});

const tabs = {
    USERS: 0,
    FRIENDS: 1,
}

class Lobby extends React.Component {
    constructor(props) {
        super(props);

        this.friendContextMenuHandler = new ContextMenuHandler(
            (e, u) => {
                this.onUserRightClicked(e, u);
            }
        );

        this.state = {
            creatingTable: false,
            searchTable: '',
            tab: tabs.USERS,
            searchUser: '',
            askPassword: false,
            password: '',
            reveal: false,
            privateTable: null,
            privateIndex: -1,
            filtering: false,
            statusFilter: 0,
            indicatorFilter: 0,
            pairFilter: 0,
            chatFilter: 0,
            userAnchor: null,
            userAnchorPos: null,
            contextUser: null,
            usersClosed: false,
        }

        this.chatboxRef = React.createRef();
        this.usersRef = React.createRef();
        this.friendsRef = React.createRef();
    }

    componentDidMount() {
    }

    onCreateTable = () => {
        this.setState({ creatingTable: true });
    }

    doCreateTable = (opts) => {
        const { socket, user } = this.props;
        const points = user?.points ?? 0;
        if (points < opts.startingPts) {
            this.props.setNotification({ message: 'Yeterli puanınız bulunmuyor.', severity: GlobalTypes.NOTIFY_ERR })
            return;
        }

        socket.emit('create_table', opts);
        this.setState({ creatingTable: false });
    }

    onPlay = (table, i) => {
        if (!table?.options?.private) {
            this.joinTable(table.id, i);
            return;
        }

        this.setState({ askPassword: true, privateTable: table, privateIndex: i });
    }

    joinTable = (id, index) => {
        const { socket } = this.props;
        //this.setState({ creatingTable: true });
        socket.emit('join_table', { tableId: id, index, password: this.state.password });
    }

    onSpectate = (table) => {
        if (!table?.options?.private) {
            this.spectateTable(table.id);
            return;
        }

        this.setState({ askPassword: true, privateTable: table, privateIndex: -1 });
    }

    spectateTable = (id) => {
        const { socket } = this.props;
        socket.emit('spectate_table', { tableId: id, password: this.state.password });
    }

    askPasswordDialog = () => {
        const { classes } = this.props;
        const { askPassword } = this.state;

        return (
            <Dialog
                maxWidth={"xs"}
                fullWidth
                open={askPassword}
            >
                <DialogTitle>
                    <Typography className={clsx(classes.title, "!text-xs md:!text-sm lg:!text-base")} sx={{ color: '#624799' }}>ÖZEL MASA</Typography>
                </DialogTitle>

                <DialogContent>
                    <TextField type={this.state.reveal ? "text" : "password"} label="Parola" size="small"
                        margin="dense" fullWidth
                        value={this.state.password} onChange={e => this.setState({ password: e.target.value })}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton style={{ width: 24, height: 24, padding: 8 }}
                                        onClick={() => this.setState({ reveal: !this.state.reveal })}>
                                        <Tooltip title={this.state.reveal ? 'Parolayı gizle' : 'Parolayı göster'}
                                            placement='top'
                                        >
                                            <SvgIcon fontSize='small'>
                                                <path d={this.state.reveal ? mdiEyeOff : mdiEye} />
                                            </SvgIcon>
                                        </Tooltip>
                                    </IconButton>
                                </InputAdornment>
                            )
                        }}
                    />
                </DialogContent>

                <DialogActions>
                    <Button variant="contained" disabled={false}
                        onClick={() => this.setState({ askPassword: false, password: '', reveal: false, privateTable: null, privateIndex: -1 })}
                        disableFocusRipple disableRipple disableTouchRipple
                        sx={{
                            color: '#FFFFFF', backgroundColor: '#939393 !important',
                        }}
                    >
                        Vazgeç
                    </Button>

                    <Button variant="contained" disabled={false} onClick={() =>
                        this.state.privateIndex === -1 ?
                            this.spectateTable(this.state.privateTable.id) :
                            this.joinTable(this.state.privateTable.id, this.state.privateIndex)
                    }
                        disableFocusRipple disableRipple disableTouchRipple
                        sx={{
                            color: '#FFFFFF', backgroundImage: "linear-gradient(to bottom, #2FB16B 50%, #168549)"
                        }}
                    >
                        Tamam
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }

    filterDialog = () => {
        const { classes } = this.props;
        const { filtering } = this.state;

        return (
            <Dialog
                maxWidth={"xs"}
                open={filtering}
            >
                <DialogTitle>
                    <Typography className={clsx(classes.title, "!text-xs md:!text-sm lg:!text-base")} sx={{ color: '#624799' }}>FİLTRE</Typography>
                </DialogTitle>

                <DialogContent>
                    <Box display={"flex"} flexDirection={"column"} rowGap={1} p={1}>

                        <Box display="flex" columnGap={2} alignItems={"center"}>
                            <Typography className={clsx(classes.text, "!text-xs md:!text-sm lg:!text-base")} sx={{ color: '#624799', width: 96 }}>Durum</Typography>
                            <TextField variant="outlined" placeholder="Durum" size="medium" select
                                value={this.state.statusFilter} onChange={e => this.setState({ statusFilter: e.target.value })}
                                sx={{
                                    '& .MuiOutlinedInput-root': {
                                        borderRadius: '24px !important', border: '1px solid #a097b3 !important',
                                        color: '#333 !important', padding: 0,
                                    },
                                    '& .MuiSelect-select': {
                                        padding: '6px 32px 6px 14px !important', width: 120,
                                    },
                                }}
                            >
                                <MenuItem value={0}>Hepsi</MenuItem>
                                <MenuItem value={1}>Başladı</MenuItem>
                                <MenuItem value={2}>Başlamadı</MenuItem>
                            </TextField>
                        </Box>

                        <Box display="flex" columnGap={2} alignItems={"center"}>
                            <Typography className={clsx(classes.text, "!text-xs md:!text-sm lg:!text-base")} sx={{ color: '#624799', width: 96 }}>Gösterge</Typography>
                            <TextField variant="outlined" placeholder="Göstergeli" size="medium" select
                                value={this.state.indicatorFilter} onChange={e => this.setState({ indicatorFilter: e.target.value })}
                                sx={{
                                    '& .MuiOutlinedInput-root': {
                                        borderRadius: '24px !important', border: '1px solid #a097b3 !important',
                                        color: '#333 !important',
                                    },
                                    '& .MuiSelect-select': {
                                        padding: '6px 32px 6px 14px !important', width: 120,
                                    },
                                }}
                            >
                                <MenuItem value={0}>Hepsi</MenuItem>
                                <MenuItem value={1}>Göstergeli</MenuItem>
                                <MenuItem value={2}>Göstergesiz</MenuItem>
                            </TextField>
                        </Box>

                        <Box display="flex" columnGap={2} alignItems={"center"}>
                            <Typography className={clsx(classes.text, "!text-xs md:!text-sm lg:!text-base")} sx={{ color: '#624799', width: 96 }}>Eş</Typography>
                            <TextField variant="outlined" placeholder="Eşlilik" size="medium" select
                                value={this.state.pairFilter} onChange={e => this.setState({ pairFilter: e.target.value })}
                                sx={{
                                    '& .MuiOutlinedInput-root': {
                                        borderRadius: '24px !important', border: '1px solid #a097b3 !important',
                                        color: '#333 !important',
                                    },
                                    '& .MuiSelect-select': {
                                        padding: '6px 32px 6px 14px !important', width: 120,
                                    },
                                }}
                            >
                                <MenuItem value={0}>Hepsi</MenuItem>
                                <MenuItem value={1}>Eşli</MenuItem>
                                <MenuItem value={2}>Eşsiz</MenuItem>
                            </TextField>
                        </Box>

                        <Box display="flex" columnGap={2} alignItems={"center"}>
                            <Typography className={clsx(classes.text, "!text-xs md:!text-sm lg:!text-base")} sx={{ color: '#624799', width: 96 }}>Sohbet</Typography>
                            <TextField variant="outlined" placeholder="Sohbet" size="medium" select
                                value={this.state.chatFilter} onChange={e => this.setState({ chatFilter: e.target.value })}
                                sx={{
                                    '& .MuiOutlinedInput-root': {
                                        borderRadius: '24px !important', border: '1px solid #a097b3 !important',
                                        color: '#333 !important',
                                    },
                                    '& .MuiSelect-select': {
                                        padding: '6px 32px 6px 14px !important', width: 120,
                                    },
                                }}
                            >
                                <MenuItem value={0}>Hepsi</MenuItem>
                                <MenuItem value={1}>Açık</MenuItem>
                                <MenuItem value={2}>Kapalı</MenuItem>
                            </TextField>
                        </Box>

                    </Box>

                </DialogContent>

                <DialogActions>
                    <Button variant="contained" disabled={false}
                        onClick={() => this.setState({ filtering: false, statusFilter: 0, indicatorFilter: 0, pairFilter: 0, chatFilter: 0 })}
                        disableFocusRipple disableRipple disableTouchRipple
                        sx={{
                            color: '#FFFFFF', backgroundColor: '#939393 !important',
                        }}
                    >
                        Vazgeç
                    </Button>

                    <Button variant="contained" disabled={false} onClick={() => this.setState({ filtering: false })}
                        disableFocusRipple disableRipple disableTouchRipple
                        sx={{
                            color: '#FFFFFF', backgroundImage: "linear-gradient(to bottom, #2FB16B 50%, #168549)"
                        }}
                    >
                        Tamam
                    </Button>
                </DialogActions>

            </Dialog>
        );
    }

    toolbar = () => {
        const { classes, user } = this.props;
        const { statusFilter, indicatorFilter, pairFilter, chatFilter } = this.state;
        const filterCount = [statusFilter, indicatorFilter, pairFilter, chatFilter].filter(f => f).length;

        return (
            <Box bgcolor={"#ccc"} className="flex flex-row max-w-full gap-x-1 md:gap-x-2 p-1 md:p-2"
                overflow={"auto"} sx={{ touchAction: 'none !important' }}
            >
                <Box>
                    <Tooltip title="Yeni Masa">
                        <Button variant="contained"
                            className="bg-gradient-to-b from-red-500 via-red-600 to-red-700 w-fit h-full !rounded-xl"
                            disabled={this.state.creatingTable || user?.table} onClick={this.onCreateTable}
                        >
                            <Box display="flex" columnGap="8px" overflow={"hidden"} whiteSpace={"nowrap"}>
                                <SvgIcon fontSize="small" className="!hidden lg:!block" sx={{ margin: 'auto' }}><path d={mdiPlusCircle} color="var(--white)" /></SvgIcon>
                                <Typography className="text-white !text-xs md:!text-sm lg:!text-base">
                                    Yeni Masa
                                </Typography>
                            </Box>
                        </Button>
                    </Tooltip>
                </Box>

                <Box>
                    <Tooltip title="Filtre">
                        <Button variant="outlined" onClick={() => this.setState({ filtering: true })}
                            className="bg-gradient-to-b from-green-500 via-green-600 to-green-700 w-fit h-full !rounded-xl"
                            disableFocusRipple disableRipple disableTouchRipple
                        >
                            <Box display="flex" columnGap="8px" overflow={"hidden"} whiteSpace={"nowrap"}>
                                <SvgIcon fontSize="small" className="!hidden lg:!block" sx={{ margin: 'auto' }}><path d={mdiTuneVertical} color="#FFFFFF" /></SvgIcon>
                                <Typography className="text-white !text-xs md:!text-sm lg:!text-base !normal-case">Filtre</Typography>

                                <Box className={clsx(filterCount > 0 ? "flex" : "hidden lg:block")} columnGap="8px">
                                    {filterCount > 0 ? (
                                        <Box borderRadius={'50%'} bgcolor={'#ff5757'} alignSelf={'center'}
                                            className="flex w-4 h-4 md:w-5 md:h-5"
                                        >
                                            <Typography className={clsx(classes.text, "!leading-3 !text-xs md:!text-sm !font-bold")} sx={{
                                                color: '#FFFFFF',
                                                margin: 'auto',
                                            }}>
                                                {filterCount}
                                            </Typography>
                                        </Box>
                                    ) : null}
                                </Box>
                            </Box>
                        </Button>
                    </Tooltip>
                </Box>

                <TextField variant="outlined" placeholder="Masa Ara" className="w-full min-w-32 sm:w-1/2 md:max-w-sm"
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <SvgIcon fontSize='small' sx={{ color: '#1D9AB6' }}>
                                    <path d={mdiMagnify} />
                                </SvgIcon>
                            </InputAdornment>
                        ),
                        classes: {
                            input: "!text-xs sm:!text-sm md:!text-base !rounded-xl",
                        }
                    }}
                    value={this.state.searchTable} onChange={e => this.setState({ searchTable: e.target.value })}
                    sx={{
                        backgroundColor: '#FFFFFF00 !important',
                        '& .MuiOutlinedInput-root': {
                            border: '1px solid #a097b3 !important',
                            backgroundColor: '#FFFFFF00 !important', color: '#333 !important',
                            height: '36px !important',
                            '& input': {
                                '&::placeholder': { color: '#1D9AB6 !important', opacity: 1 },
                            },
                        },
                        '& .MuiOutlinedInput-root::before': { borderBottom: 'none !important' },
                        '& .MuiOutlinedInput-root::after': { borderBottom: 'none !important' },
                    }}
                />
            </Box >
        );
    }

    onUserRightClicked = (e, user) => {
        e.preventDefault();

        const touch = e.touches?.[0];

        if (touch) {
            this.setState({ userAnchorPos: { left: touch.clientX, top: touch.clientY }, contextUser: user });
        }
        else {
            this.setState({ userAnchor: e.currentTarget, contextUser: user });
        }
    }

    userActionsClose = () => {
        this.setState({ userAnchor: null, contextUser: null });
    }

    onPrivateMessage = (userId, username) => {
        if (!(userId in this.props.usersById)) {
            return;
        }

        this.userActionsClose();
        this.props.addChatTab(userId, username, true);
        this.props.history.push(getPath("/sohbet"), { userId, username });
    }

    onUserSelected = (e, userId) => {
        switch (this.state.tab) {
            case tabs.USERS:
                this.usersRef?.onSelected?.(e, userId);
                break;
            case tabs.FRIENDS:
                this.friendsRef?.onSelected?.(e, userId);
                break;
        }
    }

    usersList = () => {
        return (
            <UserList
                onRef={ref => this.usersRef = ref}
                history={this.props.history}
                search={this.state.searchUser}
                users={Object.values(this.props.usersById) ?? []}
                onTabUser={this.chatboxRef?.onTabUserWithUsername}
                isOnline={(u) => u?.id in this.props.usersById}
            />
        );
    }

    friendsList = () => {
        return (
            <UserList
                onRef={ref => this.friendsRef = ref}
                history={this.props.history}
                search={this.state.searchUser}
                users={this.props.friends.map(fid => this.props.friendsById[fid]?.user) ?? []}
                onTabUser={this.chatboxRef?.onTabUserWithUsername}
                isOnline={(u) => u?.id in this.props.usersById}
            />
        );
    }

    usersTab = () => {
        switch (this.state.tab) {
            case tabs.FRIENDS:
                return this.friendsList();
            case tabs.USERS:
                return this.usersList();
            default:
                return null;
        }
    }

    rightDrawer = () => {
        const { classes, friends, usersById } = this.props;
        const { usersClosed } = this.state;
        const imgPath = `${process.env.REACT_APP_CDN_URL}/web/img`;

        const toggle = () => {
            this.setState({ usersClosed: !usersClosed });
        }

        return (
            <>
                <input type="password" name='fake-password' autoComplete='new-password' tabIndex='-1' hidden />
                <Box position={"relative"} sx={{ userSelect: 'none' }}>
                    <Box display={"flex"} position={"absolute"} bottom={0} left={0} zIndex={99}>
                        <Tooltip title={usersClosed ? "Kişileri Aç" : "Kişileri Kapat"}>
                            <Button variant="contained" disableElevation disableFocusRipple disableRipple disableTouchRipple
                                sx={{
                                    width: 28, height: 28, borderRadius: '0 !important', backgroundColor: '#F8F8F8 !important',
                                    minWidth: 28, px: '2px !important', border: '1px solid #D9D9D9'
                                }}
                                onClick={toggle}
                            >
                                <SvgIcon><path d={usersClosed ? mdiChevronLeft : mdiChevronRight} /></SvgIcon>
                            </Button>
                        </Tooltip>
                    </Box>
                </Box>

                <Box display={usersClosed ? "none" : "flex"} flexDirection={"column"}
                    padding="0" borderLeft={"1px solid #a097b3"}
                    bgcolor={"#f6f6ff"}
                    className="w-4/12 max-w-sm min-h-0 max-h-full !text-xs sm:!text-sm md:!text-base"
                    sx={{
                        overscrollBehavior: 'none',
                    }}
                >
                    <Tabs
                        sx={{
                            height: '48px !important',
                            '& .MuiTabs-flexContainer': { columnGap: 0 },
                            '& .MuiTabs-indicator': { backgroundColor: "#FF9559", height: '4px' },
                            '& .MuiTab-root': { color: '#505050 !important', flex: 1, borderBottom: '1px solid #E3E3E3', padding: 0 },
                            '& .Mui-selected': { color: '#505050 !important', backgroundColor: '#FFF3EC' },
                        }}
                        value={this.state.tab}
                        textColor="primary"
                        className={classes.tabs}
                        onChange={(e, value) => this.setState({ tab: value, searchUser: '' })}>

                        <Tab value={tabs.USERS}
                            label={
                                <Box display={"flex"} justifyContent={"space-between"} className="gap-x-1 md:gap-x-2 p-0 lg:p-2">
                                    <Typography className={clsx(classes.tabTitle, "hidden lg:block", "!text-xs md:!text-sm lg:!text-base")}
                                        alignSelf={"center"}
                                    >
                                        Kişiler
                                    </Typography>

                                    <Box className="block lg:hidden self-center">
                                        <img src={loadedResources[`${imgPath}/users.svg`]} alt="Kişiler"
                                            className="w-8 aspect-square"
                                        />
                                    </Box>
                                </Box>
                            }
                            sx={{
                                textTransform: 'none', padding: 3, flexDirection: 'row', borderRight: '1px solid #E3E3E3',
                                flex: '1 !important', minWidth: 0
                            }}
                        />

                        <Tab value={tabs.FRIENDS}
                            label={
                                <Box display={"flex"} justifyContent={"space-between"} className="gap-x-1 md:gap-x-2 p-0 lg:p-2">
                                    <Typography className={clsx(classes.tabTitle, "hidden lg:block", "!text-xs md:!text-sm lg:!text-base")}
                                        alignSelf={"center"}
                                    >
                                        Arkadaşlar
                                    </Typography>

                                    <Box className="block lg:hidden self-center">
                                        <img src={loadedResources[`${imgPath}/friends.svg`]} alt="Arkadaşlar"
                                            className="w-8 aspect-square"
                                        />
                                    </Box>

                                    <Chip
                                        label={(() => {
                                            const onlineFriends = friends.filter(f => f in this.props.usersById)?.length ?? 0;
                                            return onlineFriends >= 100 ? '99+' : onlineFriends;
                                        })()}
                                        color="primary" size="small"
                                        className="self-center font-bold !hidden min-[450px]:!flex"
                                        sx={{ backgroundColor: '#FFF3EC', color: '#FF9559' }}
                                    />
                                </Box>}
                            sx={{
                                textTransform: 'none', padding: 3, flexDirection: 'row', flex: '1 !important', minWidth: 0
                            }}
                        />
                    </Tabs>

                    <TextField variant="standard" placeholder="Kişi Ara" fullWidth
                        type="search"
                        autoComplete="off"
                        InputProps={{
                            classes: { root: "h-[42px] p-[4px] md:p-[8px]" },
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SvgIcon fontSize='small'>
                                        <path d={mdiMagnify} />
                                    </SvgIcon>
                                </InputAdornment>
                            )
                        }}
                        value={this.state.searchUser}
                        onChange={e => this.setState({ searchUser: e.target.value })}
                        //classes={{ root: "!p-2 md:p-2" }}
                        //inputProps={{ className: "p-0 md:p-2" }}
                        className="!h-[42px]"
                        sx={{
                            backgroundColor: '#f6f6ff',
                            //height: '38px !important',
                            //'& .MuiInputBase-root': { padding: '12px' },
                            '& .MuiInputBase-root::before': { borderBottom: '2px solid #E3E3E3 !important' },
                            '& .MuiInputBase-root::after': { borderBottom: '2px solid #E3E3E3 !important' },
                        }}
                    />

                    <Box className="relative flex-1">
                        <Box className="absolute flex flex-col w-full h-full">
                            <Box className="flex flex-col flex-1 min-h-0" sx={{ overflowX: 'hidden', overflowY: 'auto' }}>
                                {this.usersTab()}
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </>
        );
    }

    filteredTables = () => {
        const { tables } = this.props;
        const { statusFilter, indicatorFilter, pairFilter, chatFilter, searchTable } = this.state;

        const filteredTables = Object.values(tables).filter(t => {
            // This happened once, so I'm adding this check
            if (!t || !t.name) {
                return false;
            }

            if (!t.name.toLowerCase().toString().includes(searchTable.toLowerCase())) {
                return false;
            }

            if ((statusFilter === 1 && !t.game?.started) || (statusFilter === 2 && t.game?.started)) {
                return false;
            }

            if ((indicatorFilter === 1 && !t.options?.wIndicator) || (indicatorFilter === 2 && t.options?.wIndicator)) {
                return false;
            }

            if ((pairFilter === 1 && !t.options?.paired) || (pairFilter === 2 && t.options?.paired)) {
                return false;
            }

            if ((chatFilter === 1 && !t.options?.allowChat) || (chatFilter === 2 && t.options?.allowChat)) {
                return false;
            }

            return true;

        }) ?? [];

        filteredTables.sort((a, b) => b.created_at < a.created_at ? -1 : 1);

        return filteredTables;
    }

    content = () => {
        const imgPath = `${process.env.REACT_APP_CDN_URL}/web/img`;
        const filteredTables = this.filteredTables();

        const playButton = (table, index, flex = null) => (
            <Box display={"flex"} flex={flex} justifyContent={"center"} alignSelf={"center"}>
                <Button onClick={() => this.onPlay(table, index)}
                    sx={{
                        color: '#282231', width: 'min-content', minWidth: 0, height: 'min-content',
                        boxShadow: '2px 2px #3336'
                    }}
                    className="bg-gradient-to-b from-yellow-400 via-yellow-500 to-yellow-600 !rounded-lg"
                >
                    <span className="font['Roboto Slab'] font-bold text-[0.5em] md:text-[0.6em] lg:text-[0.7em]"
                        sx={{ color: '#282231', fontWeight: 'bold !important' }}
                    >
                        Otur
                    </span>
                </Button>
            </Box>
        );

        return (
            <Box bgcolor={"#ccc"} zIndex={0}
                overflow={"auto"} flex={1} sx={{ overscrollBehavior: 'none !important' }}
            >
                {this.askPasswordDialog()}
                {this.filterDialog()}

                <ThemeProvider theme={theme}>
                    <Grid container direction="row" p={0} spacing={0} rowGap={0.25} maxWidth={"100%"}
                        alignItems={"stretch"} height={0}
                    >
                        {filteredTables.map((table, i) => {
                            return (
                                <Grid key={table.id} item xs={12} sm={6} md={4} lg={3} xl={2}
                                    className={clsx(
                                        "w-full h-max min-w-48",
                                        (i + 1) % 2 === 0 ? "min-[750px]:pr-0" : "min-[750px]:pr-0.5",
                                        (i + 1) % 3 === 0 ? "min-[1280px]:pr-0" : "min-[1280px]:pr-0.5",
                                        (i + 1) % 4 === 0 ? "min-[1600px]:pr-0" : "min-[1600px]:pr-0.5",
                                        (i + 1) % 6 === 0 ? "min-[2120px]:pr-0" : "min-[2120px]:pr-0.5",
                                    )}
                                >
                                    <Box sx={{
                                        backgroundImage: !table.game?.started ?
                                            "linear-gradient(to bottom, #1D9AB6 50%, #157B92)" : "linear-gradient(to bottom, #084 50%, #063)",
                                        borderRadius: 4, boxShadow: '0', border: '2px solid #333', height: '100%',
                                    }}
                                    >
                                        <Box sx={{ padding: '0 !important', display: "flex", flex: 1 }}>
                                            <Box display={"flex"} columnGap={"2px"} flex={1} overflow={"hidden"}
                                            //className="flex-row sm:flex-col md:flex-row"
                                            >
                                                {/* Table Users */}
                                                <Box display={"flex"} flex={"auto"} flexDirection={"column"} border={"2px solid #333"}
                                                    className="p-1 md:p-2 max-w-[60%]"
                                                    sx={{
                                                        borderRadius: '12px', borderTopRightRadius: 0, borderBottomRightRadius: 0,
                                                    }}
                                                >
                                                    <Box className="flex justify-between relative">
                                                        <span className={"font['Roboto Slab'] font-bold text-white text-[0.6em] md:text-[0.7em] lg:text-[0.8em]"}>
                                                            Masa {table.id}
                                                        </span>

                                                        <Box className="flex absolute top-0 right-0">
                                                            {table.options?.private ?
                                                                <Tooltip title="Özel Masa">
                                                                    <Box>
                                                                        <SvgIcon htmlColor="white" fontSize="small"><path d={mdiLock} /></SvgIcon>
                                                                    </Box>
                                                                </Tooltip> :
                                                                null
                                                            }

                                                            {table.icon ?
                                                                <img src={loadedResources[`${imgPath}/${table.icon}`]} alt={table.icon} className="w-8 h-8" /> :
                                                                null
                                                            }
                                                        </Box>

                                                    </Box>

                                                    <Box className="grid flex-1 overflow-hidden py-2"
                                                        sx={{
                                                            gridTemplateColumns: 'repeat(1, 1fr)',
                                                            gridAutoRows: '1fr',
                                                        }}
                                                    >

                                                        <Box
                                                            className="flex flex-1 justify-center items-center"
                                                        >
                                                            {(() => {
                                                                const { usersById } = this.props;
                                                                const u = table.users[0];
                                                                const user = u?.bot || !(u?.id in usersById) ? u : usersById[u?.id];
                                                                if (!u) {
                                                                    return playButton(table, 0);
                                                                }

                                                                return (
                                                                    <Box display={"flex"} flexDirection={"column"} justifyContent={"center"} alignContent={"center"}
                                                                        flex={1} overflow={"hidden"}
                                                                        onContextMenu={(e) => this.friendContextMenuHandler.onContextMenu(e, user)}
                                                                        onTouchStart={(e) => this.friendContextMenuHandler.onTouchStart(e, user)}
                                                                        onTouchCancel={this.friendContextMenuHandler.onTouchCancel}
                                                                        onTouchEnd={this.friendContextMenuHandler.onTouchEnd}
                                                                        onTouchMove={this.friendContextMenuHandler.onTouchMove}
                                                                        onDoubleClick={() => this.onPrivateMessage(user?.id, user?.username)}
                                                                        sx={{
                                                                            cursor: 'pointer',
                                                                        }}
                                                                    >
                                                                        <Box alignSelf={"center"}>
                                                                            <UserAvatar user={user} />
                                                                        </Box>
                                                                        <Box overflow={"hidden"} textOverflow={"ellipsis"} alignSelf={"center"}
                                                                            className="max-w-full font['Roboto Slab'] text-white text-[0.5em] md:text-[0.6em] lg:text-[0.7em]"
                                                                            style={{
                                                                                textAlign: 'center', whiteSpace: 'nowrap', textOverflow: 'ellipsis',
                                                                            }}
                                                                        >
                                                                            {user?.username}
                                                                        </Box>
                                                                    </Box>
                                                                );
                                                            })()}
                                                        </Box>

                                                        <Box
                                                            className="flex flex-1 justify-center items-center"
                                                        >
                                                            {(() => {
                                                                const { usersById } = this.props;
                                                                const u = table.users[3];
                                                                const user = u?.bot || !(u?.id in usersById) ? u : usersById[u?.id];
                                                                if (!u) {
                                                                    return playButton(table, 3, 1);
                                                                }

                                                                return (
                                                                    <Box display={"flex"} flexDirection={"column"} justifyContent={"center"} alignContent={"center"}
                                                                        flex={1} overflow={"hidden"}
                                                                        onContextMenu={(e) => this.friendContextMenuHandler.onContextMenu(e, user)}
                                                                        onTouchStart={(e) => this.friendContextMenuHandler.onTouchStart(e, user)}
                                                                        onTouchCancel={this.friendContextMenuHandler.onTouchCancel}
                                                                        onTouchEnd={this.friendContextMenuHandler.onTouchEnd}
                                                                        onTouchMove={this.friendContextMenuHandler.onTouchMove}
                                                                        onDoubleClick={() => this.onPrivateMessage(user?.id, user?.username)}
                                                                        sx={{
                                                                            cursor: 'pointer',
                                                                        }}
                                                                    >
                                                                        <Box alignSelf={"center"}>
                                                                            <UserAvatar user={user}
                                                                            />
                                                                        </Box>
                                                                        <Box overflow={"hidden"} textOverflow={"ellipsis"} alignSelf={"center"}
                                                                            className={clsx(
                                                                                "max-w-full font['Roboto Slab'] text-white text-[0.5em] md:text-[0.6em] lg:text-[0.7em]",
                                                                            )}
                                                                            style={{
                                                                                textAlign: 'center', whiteSpace: 'nowrap', textOverflow: 'ellipsis',
                                                                            }}
                                                                        >
                                                                            {user?.username}
                                                                        </Box>
                                                                    </Box>
                                                                );
                                                            })()}

                                                            <Box>
                                                                <Tooltip title="Masayı İzle" placement="top">
                                                                    <Button onClick={() => this.onSpectate(table)}
                                                                        sx={{
                                                                            backgroundImage: "linear-gradient(to bottom, #FFFFFF 50%, #B0EEDF)",
                                                                            color: '#282231', borderRadius: '50%', width: 'min-content', minWidth: 0, height: 'min-content',
                                                                            boxShadow: '2px 2px #3336'
                                                                        }}
                                                                    >
                                                                        <SvgIcon className="!text-[1em] md:!text-[1.2em] lg:!text-[1.4em]">
                                                                            <path d={mdiMagnify} />
                                                                        </SvgIcon>
                                                                    </Button>
                                                                </Tooltip>
                                                            </Box>

                                                            {(() => {
                                                                const { usersById } = this.props;
                                                                const u = table.users[1];
                                                                const user = u?.bot || !(u?.id in usersById) ? u : usersById[u?.id];
                                                                if (!u) {
                                                                    return playButton(table, 1, 1);
                                                                }

                                                                return (
                                                                    <Box display={"flex"} flexDirection={"column"} justifyContent={"center"} alignContent={"center"}
                                                                        flex={1} overflow={"hidden"}
                                                                        onContextMenu={(e) => this.friendContextMenuHandler.onContextMenu(e, user)}
                                                                        onTouchStart={(e) => this.friendContextMenuHandler.onTouchStart(e, user)}
                                                                        onTouchCancel={this.friendContextMenuHandler.onTouchCancel}
                                                                        onTouchEnd={this.friendContextMenuHandler.onTouchEnd}
                                                                        onTouchMove={this.friendContextMenuHandler.onTouchMove}
                                                                        onDoubleClick={() => this.onPrivateMessage(user?.id, user?.username)}
                                                                        sx={{
                                                                            cursor: 'pointer',
                                                                        }}
                                                                    >
                                                                        <Box alignSelf={"center"}>
                                                                            <UserAvatar user={user} />
                                                                        </Box>
                                                                        <Box overflow={"hidden"} textOverflow={"ellipsis"} alignSelf={"center"}
                                                                            className={clsx(
                                                                                "max-w-full font['Roboto Slab'] text-white text-[0.5em] md:text-[0.6em] lg:text-[0.7em]",
                                                                            )}
                                                                            style={{
                                                                                textAlign: 'center', whiteSpace: 'nowrap', textOverflow: 'ellipsis',
                                                                            }}
                                                                        >
                                                                            {user?.username}
                                                                        </Box>
                                                                    </Box>
                                                                );
                                                            })()}
                                                        </Box>

                                                        <Box
                                                            className="flex flex-1 justify-center items-center"
                                                        >
                                                            {(() => {
                                                                const { usersById } = this.props;
                                                                const u = table.users[2];
                                                                const user = u?.bot || !(u?.id in usersById) ? u : usersById[u?.id];
                                                                if (!u) {
                                                                    return playButton(table, 2);
                                                                }

                                                                return (
                                                                    <Box display={"flex"} flexDirection={"column"} justifyContent={"center"} alignContent={"center"}
                                                                        flex={1} overflow={"hidden"}
                                                                        onContextMenu={(e) => this.friendContextMenuHandler.onContextMenu(e, user)}
                                                                        onTouchStart={(e) => this.friendContextMenuHandler.onTouchStart(e, user)}
                                                                        onTouchCancel={this.friendContextMenuHandler.onTouchCancel}
                                                                        onTouchEnd={this.friendContextMenuHandler.onTouchEnd}
                                                                        onTouchMove={this.friendContextMenuHandler.onTouchMove}
                                                                        onDoubleClick={() => this.onPrivateMessage(user?.id, user?.username)}
                                                                        sx={{
                                                                            cursor: 'pointer',
                                                                        }}
                                                                    >
                                                                        <Box alignSelf={"center"}>
                                                                            <UserAvatar user={user} />
                                                                        </Box>
                                                                        <Box overflow={"hidden"} textOverflow={"ellipsis"} alignSelf={"center"}
                                                                            className={"max-w-full font['Roboto Slab'] text-white text-[0.5em] md:text-[0.6em] lg:text-[0.7em]"}
                                                                            style={{
                                                                                textAlign: 'center', whiteSpace: 'nowrap', textOverflow: 'ellipsis',
                                                                            }}
                                                                        >
                                                                            {user?.username}
                                                                        </Box>
                                                                    </Box>
                                                                );
                                                            })()}
                                                        </Box>
                                                    </Box>
                                                </Box>

                                                {/* Table Options */}
                                                <Box className={clsx(
                                                    "flex flex-col items-start px-2 py-4",
                                                    "gap-y-0 min-w-[40%]"
                                                )}
                                                    border={"2px solid #333"}
                                                    sx={{
                                                        borderRadius: '12px', borderTopLeftRadius: 0, borderBottomLeftRadius: 0,
                                                        overflow: 'hidden',
                                                    }}
                                                >
                                                    <span className="font['Roboto Slab'] text-white text-[0.5em] md:text-[0.6em] lg:text-[0.7em] truncate"                                                    >
                                                        {`${table.options?.paired ? 'Eşli' : 'Eşsiz'}`}
                                                    </span>
                                                    <span className="font['Roboto Slab'] text-white text-[0.5em] md:text-[0.6em] lg:text-[0.7em] truncate"                                                    >
                                                        {`${table.options?.wColor ? 'Renkli' : 'Renksiz'}`}
                                                    </span>
                                                    <span className="font['Roboto Slab'] text-white text-[0.5em] md:text-[0.6em] lg:text-[0.7em] truncate"                                                    >
                                                        {`${table.options?.wIndicator ? 'Göstergeli' : 'Göstergesiz'}`}
                                                    </span>
                                                    <span className="font['Roboto Slab'] text-white text-[0.5em] md:text-[0.6em] lg:text-[0.7em] truncate"                                                    >
                                                        {`Sohbete ${table.options?.allowChat ? 'açık' : 'kapalı'}`}
                                                    </span>
                                                    <span className="font['Roboto Slab'] text-white text-[0.5em] md:text-[0.6em] lg:text-[0.7em] truncate"                                                    >
                                                        {`İzleyiciye ${table.options?.allowSpec ? 'açık' : 'kapalı'}`}
                                                    </span>
                                                    <span className="font['Roboto Slab'] text-white text-[0.5em] md:text-[0.6em] lg:text-[0.7em] truncate">
                                                        {`Başlangıç Puanı: ${table.options?.startingPts ?? 0}`}
                                                    </span>
                                                    <span className="font['Roboto Slab'] text-white text-[0.5em] md:text-[0.6em] lg:text-[0.7em] truncate">
                                                        {`İzleyiciler: ${table.spectators?.length ?? 0}`}
                                                    </span>

                                                    <Box flex={1} />

                                                    <Chip size="small" className="!hidden md:!flex" label={(
                                                        <span className="font['Roboto Slab'] font-bold text-white text-[0.5em] md:text-[0.6em] lg:text-[0.7em]"
                                                            sx={{ color: '#FFFFFF', alignSelf: 'center' }}
                                                        >
                                                            {table.game?.started ? 'OYNANIYOR' : 'BAŞLAMADI'}
                                                        </span>
                                                    )}
                                                        sx={{ backgroundColor: table.game?.started ? '#1D9AB6' : '#B8B8B8' }}
                                                    />

                                                    <Tooltip title={table.game?.started ? 'Oynanıyor' : 'Başlamadı'} placement="top">
                                                        <SvgIcon className={clsx(
                                                            "!block md:!hidden rounded-full",
                                                            table.game?.started ? "p-0 bg-white" : "p-1 bg-[#B8B8B8]"
                                                        )}>
                                                            {table.game?.started ?
                                                                <CheckCircle htmlColor="#1D9AB6" /> :
                                                                <HourglassEmpty htmlColor="white" />
                                                            }
                                                        </SvgIcon>
                                                    </Tooltip>
                                                </Box>
                                            </Box>
                                        </Box>
                                    </Box>
                                </Grid>
                            );
                        })}
                    </Grid>
                </ThemeProvider>
            </Box>
        );
    }

    render() {
        const { roomConfig } = this.props;
        const { usersClosed } = this.state;

        return (
            <Box display="flex" flexDirection="column" flex={1} className={clsx("max-w-full")} sx={{ touchAction: 'none !important' }}>
                <GameOptions open={this.state.creatingTable}
                    onClose={() => this.setState({ creatingTable: false })}
                    onCreate={this.doCreateTable}
                />

                <Box display={"flex"} flex={1} overflow={"hidden"}>
                    <Box display={"flex"} flex={1} flexDirection={"column"} className={clsx("w-6/12")} position={"relative"}>
                        {this.toolbar()}

                        <Box display={"flex"} flex={(100 - this.state.chatHeight) / 10} flexDirection={"column"}>
                            {this.content()}
                        </Box>

                        <Chatbox onRef={(ref) => this.chatboxRef = ref}
                            history={this.props.history} allowExpand
                            rightDrawer={usersClosed ? {
                                title: 'Kişileri Aç', onClick: () => this.setState({ usersClosed: false }),
                                icon: <SvgIcon className="!text-white"><path d={mdiChevronLeft} /></SvgIcon>,
                            } : null}
                            users={this.props.usersById}
                            isPortrait={this.props.isPortrait} isLandscape={this.props.isLandscape}
                            watermark={true} type={CHATBOX_TYPES.LOBBY}
                            onHeightChanged={(height) => this.setState({ chatHeight: height })}
                            onUserSelected={this.onUserSelected}
                            disabled={!roomConfig?.allowLobbyChat && this.props.role < roles.Moderator}
                            label={!roomConfig?.allowLobbyChat && this.props.role < roles.Moderator ? "Lobi Sohbeti Kapalı" : ""}
                        />
                    </Box>

                    {this.rightDrawer()}
                </Box>

                <UserActions
                    anchor={this.state.userAnchor}
                    anchorPos={this.state.userAnchorPos}
                    user={this.state.contextUser}
                    onClose={() => this.setState({ userAnchor: null, userAnchorPos: null, contextUser: null })}
                    history={this.props.history}
                    onTabUser={this.chatboxRef?.onTabUserWithUsername}
                />
            </Box>
        );
    }
}

Lobby.propTypes = {
    classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
    return {
        users: state.app.lobby.users,
        usersById: state.app.lobby.usersById,
        tables: state.app.lobby.tables,
        socket: state.app.global.socket,
        user: state.app.global.user,
        friends: state.app.global.friends,
        friendsById: state.app.global.friendsById,
        roomConfig: state.app.global.roomConfig,
        role: state.app.global.role,
    }
};

export default withStyles(styles)(
    connect(mapStateToProps, {
        setNotification, addChatTab,
    })(Lobby)
);
