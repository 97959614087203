import React from "react";
import { connect } from "react-redux";
import { withStyles } from "@mui/styles";
import PropTypes from "prop-types";
import { Box, Tab, Tabs, Typography } from "@mui/material";
import { setAdminTab } from "../../../actions/global";
import PremiumUsers from "./PremiumUsers";

const styles = theme => ({
});

const tabs = {
    GOLD_USERS: 0,
}

class PremiumSettings extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            tab: tabs.GOLD_USERS,
        }

    }

    componentDidMount() {
        this.setState({ tab: this.props.adminTab });
    }

    componentDidUpdate(prevProps) {
    }

    onTabChange = (e, value) => {
        this.props.setAdminTab(value);
        this.setState({ tab: value });
    }

    content = () => {
        switch (this.state.tab) {
            case tabs.GOLD_USERS:
                return <PremiumUsers />;
        }
    }

    render() {
        const { classes } = this.props;

        return (
            <Box className="w-full overflow-auto">
                <Tabs
                    sx={{
                        width: 'fit-content',
                        '& .MuiTabs-flexContainer': { columnGap: 0 },
                        '& .MuiTabs-indicator': { backgroundColor: "#FF9559", height: '4px' },
                        '& .MuiTab-root': { color: '#505050 !important', flex: 1, borderBottom: '1px solid #E3E3E3', padding: 0 },
                        '& .Mui-selected': { color: '#505050 !important', backgroundColor: '#FFF3EC' },
                    }}
                    value={this.state.tab}
                    textColor="primary"
                    className={classes.tabs}
                    onChange={this.onTabChange}>

                    <Tab value={tabs.MAIL_FORBIDS}
                        label={
                            <Box display={"flex"} columnGap={2} flex={1} p={2} justifyContent={"center"}>
                                <Typography sx={{ whiteSpace: 'nowrap', px: 1 }} className="!text-xs md:!text-sm lg:!text-base">Premium Kullanıcılar</Typography>
                            </Box>
                        }
                        sx={{ textTransform: 'none', padding: 3, flexDirection: 'row', borderRight: '1px solid #E3E3E3' }} />
                </Tabs>

                {this.content()}
            </Box>
        );
    }
}

PremiumSettings.propTypes = {
    classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
    return {
        user: state.app.global.user,
        adminTab: state.app.global.adminTab,
    }
};

export default withStyles(styles)(connect(mapStateToProps, {
    setAdminTab,
})(PremiumSettings));
