import React from 'react';
import PropTypes from 'prop-types';

class Focusable extends React.Component {

    state = {
        focused: this.props.focused ?? false,
    }

    constructor(props) {
        super(props);
        this.wrapperRef = React.createRef();
        this.focusButton = this.props.focusButton ?? 0;
        this.focusCheck = this.props.focusCheck ?? ((e) => true);
        this.blurCheck = this.props.blurCheck ?? ((e) => true);
    }

    componentDidMount() {
        document.addEventListener("mousedown", this.handleClick);
    }

    componentWillUnmount() {
        document.removeEventListener("mousedown", this.handleClick);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.focused !== this.props.focused) {
            this.setState({ focused: this.props.focused });
        }
    }

    handleClick = (event) => {
        const inside = this.wrapperRef?.current?.contains(event.target);
        const buttonPressed = event.button === this.focusButton;

        if (buttonPressed) {
            if (!this.state.focused && inside && this.focusCheck?.(event)) {
                this.props.onFocus?.(event);
                this.setState({ focused: true });
            }
            else if (this.state.focused && !inside && this.blurCheck?.(event)) {
                this.props.onBlur?.(event);
                this.setState({ focused: false });
            }
            else if (this.props.toggleRef?.current?.contains(event.target)) {
                this.props.onToggle?.(event);
            }
        }
    }

    render() {
        return (
            <div ref={this.wrapperRef}>
                {this.props.children}
            </div>
        );
    }
}

Focusable.propTypes = {
    focusCheck: PropTypes.func,
    blurCheck: PropTypes.func,
    focusButton: PropTypes.number,
    onFocus: PropTypes.func,
    onBlur: PropTypes.func,
    focused: PropTypes.bool,
};

export default Focusable;
