import keyMirror from '../utils/key_mirror';

export default keyMirror({
    ADD_TABLE: null,
    LEAVE_TABLE: null,
    SET_PIECES: null,
    SET_GAME_INFO: null,
    SET_TURN_TIME: null,
    SET_POTS: null,
    SET_GROUND: null,
    SET_END_ROUND: null,
    SHOW_INDICATOR: null,
    SET_DOUBLE: null,
    SET_RETURN_OKEY: null,
    SET_MOBILE_CHAT_AT: null,
});
