import React from "react";
import { connect } from "react-redux";
import { withStyles } from "@mui/styles";
import PropTypes from "prop-types";
import { Box, Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, Divider, FormControlLabel, FormGroup, IconButton, InputAdornment, SvgIcon, Tab, Table, TableBody, TableCell, TableRow, Tabs, TextField, Tooltip, Typography } from "@mui/material";
import { Close } from "@mui/icons-material";
import clsx from "clsx";
import { mdiEye, mdiEyeOff } from "@mdi/js";
import forge from 'node-forge';
import { setNotification } from "../../actions/global";
import { GlobalTypes } from "../../action_types";
import axios, { AxiosError } from "axios";
import { ERROR_CODES } from "../../global/error";
import { generateKey } from "../../global/pbkdf2";
import { AxiosClient } from "../../global/axios";

const styles = theme => ({
});

class ChangePassword extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            email: '',
            password: '',
            passwordAgain: '',
            reveal: false,
            revealAgain: false,
            loading: false,
        }

    }

    componentDidMount() {
        this.getProfile();
    }

    getProfile = () => {
        try {
            const profileText = sessionStorage.getItem('profile') ?? localStorage.getItem('profile');
            const profile = JSON.parse(profileText);
            this.setState({ email: profile.email });
        }
        catch (e) {
            console.error(e);
        }
    }

    componentDidUpdate(prevProps) {
    }

    onClose = () => {
        this.setState({ email: '', password: '', passwordAgain: '', reveal: false, revealAgain: false });
        this.props.onClose();
    }

    doSave = () => {
        const { email, password, passwordAgain } = this.state;

        if (!email) {
            this.props.setNotification({ message: 'Profile cookie bulunamadı.', severity: GlobalTypes.NOTIFY_ERR });
            return;
        }

        if (!password || !passwordAgain) {
            this.props.setNotification({ message: 'Şifre alanları boş olamaz.', severity: GlobalTypes.NOTIFY_ERR });
            return;
        }

        if (password !== passwordAgain) {
            this.props.setNotification({ message: 'Şifreler uyuşmuyor.', severity: GlobalTypes.NOTIFY_ERR });
            return;
        }

        this.setState({ loading: true });

        let that = this;

        forge.pki.rsa.generateKeyPair({ bits: 1024 }, async (err, pair) => {
            try {

                const privatePem = forge.pki.privateKeyToPem(pair.privateKey);
                const public_key = forge.pki.publicKeyToPem(pair.publicKey);

                const iv = forge.random.getBytesSync(16);
                const salt = forge.random.getBytesSync(16);

                const key = generateKey(email, password, salt);

                var cipher = forge.aes.createEncryptionCipher(key);
                cipher.start(iv);
                cipher.update(forge.util.createBuffer(privatePem));
                cipher.finish();

                const enc_private_key = forge.util.bytesToHex(cipher.output.getBytes());

                const req = {
                    enc_private_key,
                    public_key,
                    salt: forge.util.bytesToHex(salt),
                    iv: forge.util.bytesToHex(iv),
                };

                const resp = await AxiosClient().post(`${process.env.REACT_APP_API_URL}/v1/user/password.change`, req);
                const message = 'Şifreniz başarıyla değiştirildi.';
                that.props.setNotification({ message, severity: GlobalTypes.NOTIFY_SUCC });
                that.onClose();
            }
            catch (e) {
                console.error(e);
                let message = ERROR_CODES[2];

                if (e instanceof AxiosError) {
                    const code = e.response?.data?.error?.code;
                    if (code in ERROR_CODES) {
                        message = ERROR_CODES[code];
                    } else {
                        message = e.message;
                    }
                }

                this.props.setNotification({ message: message, severity: GlobalTypes.NOTIFY_ERR, traceId: e.response?.data?.trace_id });
            }
            finally {
                this.setState({ loading: false });
            }
        })
    }

    render() {
        const { classes } = this.props;
        const disabled = !this.state.password || !this.state.passwordAgain || this.state.password !== this.state.passwordAgain || this.state.loading;

        return (
            <Dialog
                maxWidth={"xs"}
                fullWidth
                open={this.props.open}
            >
                <DialogTitle>
                    <Box padding={0} display={"flex"} justifyContent={"space-between"}>
                        <Typography className={"!text-xs sm:!text-sm md:!text-base"}>
                            Şifremi Değiştir
                        </Typography>

                        <Tooltip title="Kapat" placement="top">
                            <IconButton size="small" onClick={this.onClose} sx={{ backgroundColor: '#624799 !important' }} tabIndex={-1}>
                                <Close htmlColor="var(--white)" fontSize="small" />
                            </IconButton>
                        </Tooltip>
                    </Box>
                </DialogTitle>

                <DialogContent sx={{ p: 0 }}>
                    <form>
                        <Table>
                            <TableBody>
                                <TableRow>
                                    <TableCell className="w-36">
                                        <Typography className={clsx("!text-xs md:!text-sm lg:!text-base")} sx={{ color: '#828282' }}>
                                            Yeni Şifre
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <TextField margin="dense" fullWidth tabIndex={0} autoFocus
                                            type={this.state.reveal ? 'text' : 'password'}
                                            value={this.state.password}
                                            sx={{
                                                borderTopRightRadius: '0 !important',
                                                borderBottomRightRadius: '0 !important',
                                                margin: 0,
                                            }}
                                            InputProps={{
                                                classes: {
                                                    input: "h-8 sm:h-12",
                                                    root: "h-8 sm:h-12",
                                                },
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <IconButton style={{ width: 24, height: 24, padding: 8 }} tabIndex={-1}
                                                            onClick={() => this.setState({ reveal: !this.state.reveal })}>
                                                            <Tooltip title={this.state.reveal ? 'Şifreyi gizle' : 'Şifreyi göster'}
                                                                placement='top'
                                                            >
                                                                <SvgIcon fontSize='small'>
                                                                    <path d={this.state.reveal ? mdiEyeOff : mdiEye} />
                                                                </SvgIcon>
                                                            </Tooltip>
                                                        </IconButton>
                                                    </InputAdornment>
                                                )
                                            }}
                                            onChange={(e) => this.setState({ password: e.target.value })}
                                        />
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell className="w-36 md:w-48">
                                        <Typography className={clsx("!text-xs md:!text-sm lg:!text-base")} sx={{ color: '#828282' }}>
                                            Yeni Şifre (Tekrar)
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <TextField margin="dense" fullWidth tabIndex={2}
                                            type={this.state.revealAgain ? 'text' : 'password'}
                                            value={this.state.passwordAgain}
                                            sx={{
                                                borderTopRightRadius: '0 !important',
                                                borderBottomRightRadius: '0 !important',
                                                margin: 0,
                                            }}
                                            InputProps={{
                                                classes: {
                                                    input: "h-8 sm:h-12",
                                                    root: "h-8 sm:h-12",
                                                },
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <IconButton style={{ width: 24, height: 24, padding: 8 }} tabIndex={-1}
                                                            onClick={() => this.setState({ revealAgain: !this.state.revealAgain })}>
                                                            <Tooltip title={this.state.revealAgain ? 'Şifreyi gizle' : 'Şifreyi göster'}
                                                                placement='top'
                                                            >
                                                                <SvgIcon fontSize='small'>
                                                                    <path d={this.state.revealAgain ? mdiEyeOff : mdiEye} />
                                                                </SvgIcon>
                                                            </Tooltip>
                                                        </IconButton>
                                                    </InputAdornment>
                                                )
                                            }}
                                            onChange={(e) => this.setState({ passwordAgain: e.target.value })}
                                        />
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </form>
                </DialogContent>

                <DialogActions sx={{ p: 2 }}>
                    <Button variant="contained" disabled={false} onClick={() => this.onClose()}
                        disableFocusRipple disableRipple disableTouchRipple color="error"
                        sx={{
                            color: '#FFFFFF',
                        }}
                        className="!text-xs sm:!text-sm md:!text-base"
                    >
                        İptal
                    </Button>

                    <Button variant="contained" disabled={!!disabled} onClick={() => this.doSave()}
                        disableFocusRipple disableRipple disableTouchRipple
                        sx={{
                            color: '#FFFFFF !important', backgroundColor: disabled ? '#939393 !important' : null,
                            backgroundImage: disabled ? null : "linear-gradient(to bottom, #2FB16B 50%, #168549)"
                        }}
                        className="!text-xs sm:!text-sm md:!text-base"
                    >
                        Kaydet
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

ChangePassword.propTypes = {
    classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
    return {
    }
};

export default withStyles(styles)(connect(mapStateToProps, {
    setNotification,
})(ChangePassword));
