import keyMirror from '../utils/key_mirror';

export default keyMirror({
    SET_LOBBY_USERS: null,
    SET_LOBBY_TABLES: null,
    ADD_CHAT_MESSAGE: null,
    CLEAR_CHAT: null,
    SET_REJOIN_TABLE: null,
    SET_LOBBY_USER_STATUS: null,
    ADD_UNREAD_CHAT_MESSAGE: null,
    CLEAR_UNREAD_CHAT_MESSAGES: null,

    UPDATE_USER_INFO: null,
    UPDATE_USER_INFO_KV: null,

    ADD_CHAT_HISTORY: null,

    UPDATE_TABLE_INFO: null,
    UPDATE_TABLE_INFO_KV: null,
    REMOVE_TABLE: null,

    USER_CONNECTED: null,
    USER_DISCONNECTED: null,
});
