import React from "react";
import { connect } from "react-redux";
import { withStyles } from "@mui/styles";
import PropTypes from "prop-types";
import { Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Tooltip, Typography } from "@mui/material";
import { addChatTab, setNotification, addBlockedUser, removeBlockedUser, addFriend, removeFriend, setReportDialog } from "../../actions/global";
import { GlobalTypes } from "../../action_types";
import {
    Block, PersonAddAlt1, PersonRemoveAlt1, Flag, CheckCircleOutline,
    Close as CloseIcon, MoreHoriz as MoreHorizIcon
} from "@mui/icons-material"
import Chatbox from "../Chatbox";
import { isMobile } from "react-device-detect";
import clsx from "clsx";
import UserAvatar from "../Lobby/UserAvatar";
import { AxiosClient } from "../../global/axios";
import ProfileDetails from "../User/ProfileDetails";
import { CHATBOX_TYPES } from "../../global/chatbox";

const styles = theme => ({
    title: {
        fontWeight: '500 !important',
        color: 'var(--white)',
    },
    text: {
        fontWeight: '400 !important',
        color: 'var(--white)',
    },
    subText: {
        fontWeight: '400 !important',
        color: 'var(--white)',
    },
});

class Chat extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            user: null,
            loading: true,
            addFriendConfirm: false,
            addingFriend: false,
            removeFriendConfirm: false,
            removingFriend: false,
            blockConfirm: false,
            blocking: false,
            unblockConfirm: false,
            unblocking: false,
            previewAvatar: false,
            previewProfile: false,
        }

    }

    componentDidMount() {
        this.init();
    }

    init = async () => {
        try {
            const { userId, username } = this.props.history.location.state;

            this.setState({ user: null, loading: true });

            if (!userId || !username) {
                this.props.addChatTab(userId, username, true);
                return;
            }

            // Check if user is already in chat tabs.
            // If not, add it.
            if (!this.props.chatTabs.find(tab => tab.id === userId)) {
                this.props.addChatTab(userId, username, true);
            }

            const resp = await AxiosClient().get(`${process.env.REACT_APP_API_URL}/v1/user/profile/${userId}`);

            if (!resp.data.user) {
                throw new Error("User not found");
            }

            this.setState({ user: resp.data.user });
        }
        catch (err) {
            console.error(err);

            let message = 'Bir hata oluştu. Lütfen tekrar deneyiniz.';
            let severity = GlobalTypes.NOTIFY_ERR;

            this.props.setNotification({ message, severity });
            //this.props.history.push(getPath('/'));
        }
        finally {
            this.setState({ loading: false });
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.selectedTab !== prevProps.selectedTab) {
            this.init();
        }
    }

    onFriendRequest = () => {
        this.setState({ addFriendConfirm: true });
    }

    onFriendRemove = () => {
        this.setState({ removeFriendConfirm: true });
    }

    onBlock = () => {
        this.setState({ blockConfirm: true });
    }

    onUnblock = () => {
        this.setState({ unblockConfirm: true });
    }

    doBlock = async () => {
        const { user } = this.state;
        if (!user) {
            return;
        }

        this.setState({ blocking: true });

        try {
            const resp = await AxiosClient().post(`${process.env.REACT_APP_API_URL}/v1/user/block/${user.id}`, null);

            this.props.setNotification({ message: 'Kullanıcı engellendi.', severity: GlobalTypes.NOTIFY_SUCC });
            if (resp.data.blocked_user) {
                this.props.addBlockedUser(resp.data.blocked_user);
            }
        }
        catch (err) {
            console.error(err);
            this.props.setNotification({ message: 'Bir hata oluştu. Lütfen tekrar deneyiniz.', severity: GlobalTypes.NOTIFY_ERR });
        }
        finally {
            this.setState({ blockConfirm: false, blocking: false });
        }
    }

    doUnblock = async () => {
        const { user } = this.state;
        if (!user) {
            return;
        }

        this.setState({ unblocking: true });

        try {
            const resp = await AxiosClient().delete(`${process.env.REACT_APP_API_URL}/v1/user/block/${user.id}`);

            this.props.setNotification({ message: 'Kullanıcının engeli kaldırıldı.', severity: GlobalTypes.NOTIFY_SUCC });
            if (resp.data.id) {
                this.props.removeBlockedUser(resp.data.id);
            }
        }
        catch (err) {
            console.error(err);
            this.props.setNotification({ message: 'Bir hata oluştu. Lütfen tekrar deneyiniz.', severity: GlobalTypes.NOTIFY_ERR });
        }
        finally {
            this.setState({ unblockConfirm: false, unblocking: false });
        }
    }

    doAddFriend = async () => {
        const { user } = this.state;
        if (!user) {
            return;
        }

        this.setState({ addingFriend: true });

        try {
            const resp = await AxiosClient().put(`${process.env.REACT_APP_API_URL}/v1/user/friend.request`, {
                id: user.id,
            });

            this.props.setNotification({ message: 'Arkadaşlık isteği gönderildi.', severity: GlobalTypes.NOTIFY_SUCC });
            if (resp.data.friend) {
                this.props.addFriend(resp.data.friend);
            }
        }
        catch (err) {
            console.error(err);
            this.props.setNotification({ message: 'Bir hata oluştu. Lütfen tekrar deneyiniz.', severity: GlobalTypes.NOTIFY_ERR });
        }
        finally {
            this.setState({ addFriendConfirm: false, addingFriend: false });
        }
    }

    doRemoveFriend = async () => {
        const { user } = this.state;
        if (!user) {
            return;
        }

        this.setState({ removingFriend: true });

        try {
            const resp = await AxiosClient().delete(`${process.env.REACT_APP_API_URL}/v1/user/friend/${user.id}`);

            this.props.setNotification({ message: 'Arkadaşlıktan çıkarıldı.', severity: GlobalTypes.NOTIFY_SUCC });
            if (resp.data.id) {
                this.props.removeFriend(resp.data.id);
            }
        }
        catch (err) {
            console.error(err);
            this.props.setNotification({ message: 'Bir hata oluştu. Lütfen tekrar deneyiniz.', severity: GlobalTypes.NOTIFY_ERR });
        }
        finally {
            this.setState({ removeFriendConfirm: false, removingFriend: false });
        }
    }

    renderBlockingDialog = () => {
        const { classes } = this.props;
        const { blockConfirm, blocking } = this.state;

        return (
            <Dialog open={blockConfirm} onClose={() => { this.setState({ blockConfirm: false }) }}>
                <DialogTitle>
                    <Typography className={clsx(classes.title, "!text-xs md:!text-sm lg:!text-base")} sx={{ color: '#413067' }}>
                        Engelle
                    </Typography>
                </DialogTitle>
                <DialogContent>
                    <Typography className={clsx(classes.text, "!text-xs md:!text-sm lg:!text-base")} sx={{ color: '#413067' }}>
                        Bu kullanıcıyı engellemek istediğinize emin misiniz?
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => { this.setState({ blockConfirm: false }) }} variant="contained" color="error">
                        İptal
                    </Button>
                    <Button onClick={this.doBlock} sx={{ color: 'white' }} variant="contained" disabled={blocking}>
                        Engelle
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }

    renderUnblockingDialog = () => {
        const { classes } = this.props;
        const { unblockConfirm, unblocking } = this.state;

        return (
            <Dialog open={unblockConfirm} onClose={() => { this.setState({ unblockConfirm: false }) }}>
                <DialogTitle>
                    <Typography className={clsx(classes.title, "!text-xs md:!text-sm lg:!text-base")} sx={{ color: '#413067' }}>
                        Engeli Kaldır
                    </Typography>
                </DialogTitle>
                <DialogContent>
                    <Typography className={clsx(classes.text, "!text-xs md:!text-sm lg:!text-base")} sx={{ color: '#413067' }}>
                        Bu kullanıcının engelini kaldırmak istediğinize emin misiniz?
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => { this.setState({ unblockConfirm: false }) }} variant="contained" color="error">
                        İptal
                    </Button>
                    <Button onClick={this.doUnblock} sx={{ color: 'white' }} variant="contained" disabled={unblocking}>
                        Engeli Kaldır
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }

    renderAddFriendDialog = () => {
        const { classes } = this.props;
        const { addFriendConfirm, addingFriend } = this.state;

        return (
            <Dialog open={addFriendConfirm} onClose={() => { this.setState({ addFriendConfirm: false }) }}>
                <DialogTitle>
                    <Typography className={clsx(classes.title, "!text-xs md:!text-sm lg:!text-base")} sx={{ color: '#413067' }}>
                        Arkadaş Ekle
                    </Typography>
                </DialogTitle>
                <DialogContent>
                    <Typography className={clsx(classes.text, "!text-xs md:!text-sm lg:!text-base")} sx={{ color: '#413067' }}>
                        Bu kullanıcıyı arkadaş listenize eklemek istediğinize emin misiniz?
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => { this.setState({ addFriendConfirm: false }) }} variant="contained" color="error">
                        İptal
                    </Button>
                    <Button onClick={this.doAddFriend} sx={{ color: 'white' }} variant="contained" disabled={addingFriend}>
                        Ekle
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }

    renderRemoveFriendDialog = () => {
        const { classes } = this.props;
        const { removeFriendConfirm, removingFriend } = this.state;

        return (
            <Dialog open={removeFriendConfirm} onClose={() => { this.setState({ removeFriendConfirm: false }) }}>
                <DialogTitle>
                    <Typography className={clsx(classes.title, "!text-xs md:!text-sm lg:!text-base")} sx={{ color: '#413067' }}>
                        Arkadaşlıktan Çıkar
                    </Typography>
                </DialogTitle>
                <DialogContent>
                    <Typography className={clsx(classes.text, "!text-xs md:!text-sm lg:!text-base")} sx={{ color: '#413067' }}>
                        Bu kullanıcıyı arkadaş listenizden çıkarmak istediğinize emin misiniz?
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => { this.setState({ removeFriendConfirm: false }) }} variant="contained" color="error">
                        İptal
                    </Button>
                    <Button onClick={this.doRemoveFriend} sx={{ color: 'white' }} variant="contained" disabled={removingFriend}>
                        Çıkar
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }

    renderAvatarPreviewDialog = () => {
        const { classes } = this.props;
        const { previewAvatar, user } = this.state;

        const close = () => {
            this.setState({ previewAvatar: false });
        }

        return (
            <Dialog open={previewAvatar} onClose={close}>
                <DialogTitle>
                    <Box display={"flex"} justifyContent={"space-between"}>
                        <Typography className={clsx(classes.title, "!text-xs md:!text-sm lg:!text-base")} sx={{ color: '#413067' }}>
                            Avatar
                        </Typography>

                        <Tooltip title="Kapat" placement="top">
                            <IconButton size="small" onClick={close} sx={{ backgroundColor: '#624799 !important' }}>
                                <CloseIcon htmlColor="var(--white)" fontSize="small" />
                            </IconButton>
                        </Tooltip>
                    </Box>
                </DialogTitle>
                <DialogContent>
                    <Box display={"flex"} justifyContent={"center"} pt={4}>
                        <UserAvatar user={user} noCache
                            classes={{ root: 'min-w-[12em] min-h-[12em] max-w-full max-h-full md:min-w-[18em] md:min-h-[18em]' }}
                        />
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={close} variant="contained" color="error">
                        Kapat
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }

    renderProfilePreviewDialog = () => {
        const { classes } = this.props;
        const { previewProfile } = this.state;

        const close = () => {
            this.setState({ previewProfile: false });
        }

        return (
            <Dialog open={previewProfile} onClose={close}>
                <DialogTitle>
                    <Box display={"flex"} justifyContent={"space-between"}>
                        <Typography className={clsx(classes.title, "!text-xs md:!text-sm lg:!text-base")} sx={{ color: '#413067' }}>
                            Profil
                        </Typography>

                        <Tooltip title="Kapat" placement="top">
                            <IconButton size="small" onClick={close} sx={{ backgroundColor: '#624799 !important' }}>
                                <CloseIcon htmlColor="var(--white)" fontSize="small" />
                            </IconButton>
                        </Tooltip>
                    </Box>
                </DialogTitle>
                <DialogContent>
                    <Box className="pt-2">
                        <ProfileDetails user={this.state.user} />
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => { this.setState({ previewProfile: false }) }} variant="contained" color="error">
                        Kapat
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }

    renderProfile = () => {
        const { classes, usersById, friendsById, blocksById } = this.props;
        const { user, loading } = this.state;

        if (loading) {
            return (
                <Box display="flex" flexDirection="column" padding="0" borderLeft={"1px solid #a097b3"}
                    bgcolor={"#F9F6FF"}
                    className={clsx("w-4/12 max-w-xs !pt-6 md:!pt-0")}
                >
                    <Box display={"flex"} flex={1} justifyContent={"center"} alignItems={"center"}>
                        <Typography className={clsx(classes.text, "!text-xs md:!text-sm lg:!text-base")} sx={{ color: '#413067' }}>
                            <CircularProgress color="inherit" />
                        </Typography>
                    </Box>
                </Box>
            );
        }

        return (
            <Box display="flex" flexDirection="column" padding="0" borderLeft={"1px solid #a097b3"}
                bgcolor={"#F9F6FF"}
                className={clsx("relative w-4/12 max-w-xs !pt-6 md:!pt-0")}
            >
                <Box className="absolute flex flex-col w-full h-full">
                    <Box className="flex flex-col flex-1 min-h-0" sx={{ overflowX: 'hidden', overflowY: 'auto' }}>
                        <Box display={"flex"} flexDirection={"column"} className="p-2 gap-y-1 md:p-4 md:gap-y-2">
                            <Box margin={"0 auto"} sx={{ cursor: 'pointer' }}
                                onClick={() => { this.setState({ previewAvatar: true }) }}
                            >
                                {/** Avatar */}
                                <UserAvatar user={user} noCache
                                    classes={{ root: clsx('min-w-[36px] min-h-[36px]', isMobile ? '' : 'md:min-w-[160px] md:min-h-[160px]') }}
                                />
                            </Box>

                            <Box display={"flex"} justifyContent={"center"} columnGap={1}>
                                {/** Status Color */}
                                {(() => {
                                    const colors = ["#ABABAB", "#30B26C", "#DA4E4E", "#EFAE2F"];
                                    const status = Boolean(user) ? (user.id in usersById ? Math.abs(user.status ?? 1) : 0) : 1;

                                    return (
                                        <div style={{
                                            borderRadius: '50%', width: 12, height: 12,
                                            backgroundColor: colors[status] ?? colors[0],
                                            margin: 'auto 0'
                                        }} />
                                    );
                                })()}

                                {/** Name */}
                                <Box display={"flex"} flexDirection={"row"} justifyContent={"center"}>
                                    <Typography
                                        className={clsx(
                                            user?.username_color ? `NickRenk${user.username_color}` : 'text-black',
                                            "!text-xs md:!text-sm lg:!text-base !font-bold",
                                        )}
                                    >
                                        {user?.username ?? 'Sistem'}
                                    </Typography>
                                </Box>
                            </Box>

                            {/** Actions */}
                            <Box justifyContent={"center"} columnGap={1}
                                className={clsx("mt-2 flex flex-col gap-y-2 gap-x-2", isMobile ? "" : "md:flex-row")}
                            >
                                <Box className="flex flex-wrap gap-x-2 gap-y-2 justify-center">
                                    {user?.id in blocksById ?
                                        <Tooltip title={"Engeli Kaldır"} placement={"bottom"}>
                                            <Button variant='contained'
                                                disabled={!Boolean(user)}
                                                className="!w-8 !h-8 !min-w-8 !min-h-8 !p-0 md:!w-12 md:!h-12 md:!min-w-12 md:!min-h-12"
                                                sx={{
                                                    color: 'var(--white)', bgcolor: '#FFFFFF !important', minWidth: 0,
                                                    borderRadius: '8px !important', boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.25) !important',
                                                    '&:hover': {
                                                        bgcolor: '#FB8F32 !important',
                                                        '& .MuiSvgIcon-root': {
                                                            color: '#FFFFFF',
                                                        }
                                                    },
                                                }}
                                                onClick={this.onUnblock}
                                            >
                                                <CheckCircleOutline htmlColor="#413067" />
                                            </Button>
                                        </Tooltip> :
                                        <Tooltip title={"Engelle"} placement={"bottom"}>
                                            <Button variant='contained'
                                                disabled={!Boolean(user)}
                                                className="!w-8 !h-8 !min-w-8 !min-h-8 !p-0 md:!w-12 md:!h-12 md:!min-w-12 md:!min-h-12"
                                                sx={{
                                                    color: 'var(--white)', bgcolor: '#FFFFFF !important', minWidth: 0,
                                                    borderRadius: '8px !important', boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.25) !important',
                                                    '&:hover': {
                                                        bgcolor: '#FB8F32 !important',
                                                        '& .MuiSvgIcon-root': {
                                                            color: '#FFFFFF',
                                                        }
                                                    },
                                                }}
                                                onClick={this.onBlock}
                                            >
                                                <Block htmlColor="#413067" />
                                            </Button>
                                        </Tooltip>
                                    }

                                    {user?.id in friendsById ?
                                        <Tooltip title={"Arkadaşlıktan Çıkar"} placement={"bottom"}>
                                            <Button variant='contained'
                                                disabled={!Boolean(user)}
                                                className="!w-8 !h-8 !min-w-8 !min-h-8 !p-0 md:!w-12 md:!h-12 md:!min-w-12 md:!min-h-12"
                                                sx={{
                                                    color: 'var(--white)', bgcolor: '#FFFFFF !important', minWidth: 0,
                                                    borderRadius: '8px !important', boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.25) !important',
                                                    '&:hover': {
                                                        bgcolor: '#FB8F32 !important',
                                                        '& .MuiSvgIcon-root': {
                                                            color: '#FFFFFF',
                                                        }
                                                    },
                                                }}
                                                onClick={this.onFriendRemove}
                                            >
                                                <PersonRemoveAlt1 htmlColor="#413067" />
                                            </Button>
                                        </Tooltip> :
                                        <Tooltip title={"Arkadaş Ekle"} placement={"bottom"}>
                                            <Button variant='contained'
                                                disabled={!Boolean(user)}
                                                className="!w-8 !h-8 !min-w-8 !min-h-8 !p-0 md:!w-12 md:!h-12 md:!min-w-12 md:!min-h-12"
                                                sx={{
                                                    color: 'var(--white)', bgcolor: '#FFFFFF !important', minWidth: 0,
                                                    borderRadius: '8px !important', boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.25) !important',
                                                    '&:hover': {
                                                        bgcolor: '#FB8F32 !important',
                                                        '& .MuiSvgIcon-root': {
                                                            color: '#FFFFFF',
                                                        }
                                                    },
                                                }}
                                                onClick={this.onFriendRequest}
                                            >
                                                <PersonAddAlt1 htmlColor="#413067" />
                                            </Button>
                                        </Tooltip>
                                    }
                                </Box>

                                <Box className="flex flex-wrap gap-x-2 gap-y-2 justify-center">
                                    <Tooltip title={"Şikayet Et"} placement={"bottom"}>
                                        <Button variant='contained'
                                            disabled={!Boolean(user)}
                                            className="!w-8 !h-8 !min-w-8 !min-h-8 !p-0 md:!w-12 md:!h-12 md:!min-w-12 md:!min-h-12"
                                            sx={{
                                                color: 'var(--white)', bgcolor: '#FFFFFF !important', minWidth: 0,
                                                borderRadius: '8px !important', boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.25) !important',
                                                '&:hover': {
                                                    bgcolor: '#FB8F32 !important',
                                                    '& .MuiSvgIcon-root': {
                                                        color: '#FFFFFF',
                                                    }
                                                },
                                            }}
                                            onClick={() => { this.props.setReportDialog({ active: true, reportedUserId: user?.id }) }}
                                        >
                                            <Flag htmlColor="#413067" />
                                        </Button>
                                    </Tooltip>

                                    <Tooltip title={"Profil"} placement={"bottom"}>
                                        <Button variant='contained'
                                            className={clsx(
                                                "!block !w-8 !h-8 !min-w-8 !min-h-8 !p-0 md:!w-12 md:!h-12 md:!min-w-12 md:!min-h-12",
                                                isMobile ? "" : "md:hidden"
                                            )}
                                            sx={{
                                                color: 'var(--white)', bgcolor: '#FFFFFF !important', minWidth: 0,
                                                borderRadius: '8px !important', boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.25) !important',
                                                '&:hover': {
                                                    bgcolor: '#FB8F32 !important',
                                                    '& .MuiSvgIcon-root': {
                                                        color: '#FFFFFF',
                                                    }
                                                },
                                            }}
                                            onClick={() => this.setState({ previewProfile: true })}
                                        >
                                            <MoreHorizIcon htmlColor="#413067" />
                                        </Button>
                                    </Tooltip>
                                </Box>
                            </Box>

                            <Box className={clsx("hidden", isMobile ? "" : "md:block")}>
                                <ProfileDetails user={user} />
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Box>
        );
    }

    render() {
        const { classes, blocksById } = this.props;
        const { user, loading } = this.state;
        const disabled = user?.id in blocksById;

        return (
            <Box display="flex" flexDirection="column" className={clsx("max-w-full flex-1")}>
                <Box display={"flex"} flex={1}>
                    <Box display={"flex"} flex={1} flexDirection={"column"} className={clsx("w-6/12")} position={"relative"}>
                        <Chatbox history={this.props.history} hideTitle pm disabled={disabled || !Boolean(user)}
                            label={disabled ? 'Bu kullanıcıyı engellediğiniz için mesaj yollayamazsınız.' :
                                (!Boolean(user) ? 'Sistem botuna mesaj yollayamazsınız.' : '')}
                            users={user ? { [user.id]: user } : {}}
                            isPortrait={this.props.isPortrait} isLandscape={this.props.isLandscape}
                            type={CHATBOX_TYPES.PM} dmUserId={user?.id}
                        />
                    </Box>

                    {this.renderProfile()}
                    {this.renderBlockingDialog()}
                    {this.renderUnblockingDialog()}
                    {this.renderAddFriendDialog()}
                    {this.renderRemoveFriendDialog()}
                    {this.renderProfilePreviewDialog()}
                    {this.renderAvatarPreviewDialog()}
                </Box>

            </Box>
        );
    }
}

Chat.propTypes = {
    classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
    return {
        usersById: state.app.lobby.usersById,
        socket: state.app.global.socket,
        friendsById: state.app.global.friendsById,
        blocksById: state.app.global.blocksById,
        selectedTab: state.app.global.selectedTab,
        chatTabs: state.app.global.chatTabs,
    }
};

export default withStyles(styles)(connect(mapStateToProps, {
    addChatTab, setNotification, addBlockedUser, removeBlockedUser, addFriend, removeFriend, setReportDialog,
})(Chat));
