import React from "react";
import { connect } from "react-redux";
import { withStyles } from "@mui/styles";
import PropTypes from "prop-types";
import { Box, Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, Divider, FormControlLabel, FormGroup, IconButton, Tab, Table, TableBody, TableCell, Tabs, Tooltip, Typography } from "@mui/material";
import { Close } from "@mui/icons-material";
import clsx from "clsx";


const styles = theme => ({
});

const tabs = {
    SOUND: 0,
};

class NotificationSettings extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            tab: tabs.SOUND,
            settings: {},
        }

    }

    componentDidMount() {
        let settings = localStorage.getItem('notification_settings');
        if (!settings) {
            settings = JSON.stringify({
                online_friend: true,
                private_message: true,
                new_notification: true,
                game_fx: true,
            });

            localStorage.setItem('notification_settings', settings);
        }

        this.setState({ settings: JSON.parse(settings) });
    }

    componentDidUpdate(prevProps) {
    }

    doSave = () => {
        localStorage.setItem('notification_settings', JSON.stringify(this.state.settings));
        this.props.onClose();
    }

    onTabChange = (e, value) => {
        this.setState({ tab: value });
    }

    soundContent = () => {
        const { settings } = this.state;

        return (
            <Box p={4} py={2}>
                <FormGroup>
                    <FormControlLabel control={
                        <Checkbox checked={settings.online_friend}
                            onChange={e => this.setState({ settings: { ...settings, online_friend: e.target.checked } })}
                            className="!py-0 !px-0 md:!px-1 md:!py-1"
                        />
                    }
                        label={<Typography className={clsx("!text-xs md:!text-sm lg:!text-base")} sx={{ color: '#828282' }}>
                            Online Arkadaş
                        </Typography>
                        }
                    />

                    <FormControlLabel control={
                        <Checkbox checked={settings.game_fx}
                            onChange={e => this.setState({ settings: { ...settings, game_fx: e.target.checked } })}
                            className="!py-0 !px-0 md:!px-1 md:!py-1"
                        />
                    }
                        label={<Typography className={clsx("!text-xs md:!text-sm lg:!text-base")} sx={{ color: '#828282' }}>
                            Oyun Efektleri
                        </Typography>
                        }
                    />

                    <FormControlLabel control={
                        <Checkbox checked={settings.private_message}
                            onChange={e => this.setState({ settings: { ...settings, private_message: e.target.checked } })}
                            className="!py-0 !px-0 md:!px-1 md:!py-1"
                        />
                    }
                        label={<Typography className={clsx("!text-xs md:!text-sm lg:!text-base")} sx={{ color: '#828282' }}>
                            Özel Mesaj
                        </Typography>
                        }
                    />

                    <FormControlLabel control={
                        <Checkbox checked={settings.new_notification}
                            onChange={e => this.setState({ settings: { ...settings, new_notification: e.target.checked } })}
                            className="!py-0 !px-0 md:!px-1 md:!py-1"
                        />
                    }
                        label={<Typography className={clsx("!text-xs md:!text-sm lg:!text-base")} sx={{ color: '#828282' }}>
                            Yeni Bildirim
                        </Typography>
                        }
                    />
                </FormGroup>
            </Box>
        );
    }

    content = () => {
        switch (this.state.tab) {
            case tabs.SOUND:
                return this.soundContent();
            default:
                return null;
        }
    }

    render() {
        const { classes } = this.props;
        const disabled = false;

        return (
            <Dialog
                maxWidth={"xs"}
                fullWidth
                open={this.props.open}
            >
                <DialogTitle>
                    <Box padding={0} display={"flex"} justifyContent={"space-between"}>
                        <Typography className={"!text-xs sm:!text-sm md:!text-base"}>
                            Bildirim Ayarları
                        </Typography>

                        <Tooltip title="Kapat" placement="top">
                            <IconButton size="small" onClick={this.props.onClose} sx={{ backgroundColor: '#624799 !important' }}>
                                <Close htmlColor="var(--white)" fontSize="small" />
                            </IconButton>
                        </Tooltip>
                    </Box>
                </DialogTitle>

                <DialogContent sx={{ p: 0 }}>
                    <Tabs
                        sx={{
                            width: 'fit-content',
                            '& .MuiTabs-flexContainer': { columnGap: 0 },
                            '& .MuiTabs-indicator': { backgroundColor: "#FF9559", height: '4px' },
                            '& .MuiTab-root': { color: '#505050 !important', flex: 1, borderBottom: '1px solid #E3E3E3', padding: 0 },
                            '& .Mui-selected': { color: '#505050 !important', backgroundColor: '#FFF3EC' },
                        }}
                        value={this.state.tab}
                        textColor="primary"
                        className={classes.tabs}
                        onChange={this.onTabChange}>

                        <Tab value={tabs.SOUND}
                            label={
                                <Box display={"flex"} columnGap={2} flex={1} p={2} justifyContent={"center"}>
                                    <Typography className={"!text-xs sm:!text-sm md:!text-base"}>Sesler</Typography>
                                </Box>
                            }
                            sx={{ textTransform: 'none', padding: 3, flexDirection: 'row', borderRight: '1px solid #E3E3E3' }} />
                    </Tabs>

                    {this.content()}
                </DialogContent>

                <DialogActions sx={{ p: 2 }}>
                    <Button variant="contained" disabled={false} onClick={() => this.props.onClose()}
                        disableFocusRipple disableRipple disableTouchRipple color="error"
                        sx={{
                            color: '#FFFFFF',
                        }}
                        className="!text-xs sm:!text-sm md:!text-base"
                    >
                        İptal
                    </Button>

                    <Button variant="contained" disabled={!!disabled} onClick={() => this.doSave()}
                        disableFocusRipple disableRipple disableTouchRipple
                        sx={{
                            color: '#FFFFFF !important', backgroundColor: disabled ? '#939393 !important' : null,
                            backgroundImage: disabled ? null : "linear-gradient(to bottom, #2FB16B 50%, #168549)"
                        }}
                        className="!text-xs sm:!text-sm md:!text-base"
                    >
                        Kaydet
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

NotificationSettings.propTypes = {
    classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
    return {
    }
};

export default withStyles(styles)(connect(mapStateToProps, {
})(NotificationSettings));
