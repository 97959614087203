const longPressDuration = 610;

export default class ContextMenuHandler {
    constructor(callback) {
        this.callback = callback;
        this.longPressCountdown = null;
        this.contextMenuPossible = false;
    }

    onTouchStart = (e, u) => {
        //e.preventDefault();
        this.contextMenuPossible = true;

        //const touch = e.touches[0];

        this.longPressCountdown = setTimeout(() => {
            if (this.contextMenuPossible) {
                this.callback(e, u);
                this.contextMenuPossible = false;
            }
        }, longPressDuration);
    };

    onTouchMove = e => {
        //e.preventDefault();
        clearTimeout(this.longPressCountdown);
    };

    onTouchCancel = e => {
        //e.preventDefault();
        this.contextMenuPossible = false;
        clearTimeout(this.longPressCountdown);
    };

    onTouchEnd = e => {
        //e.preventDefault();
        this.contextMenuPossible = false;
        clearTimeout(this.longPressCountdown);
    };

    onContextMenu = (e, u) => {
        //e.preventDefault();
        this.contextMenuPossible = false;

        clearTimeout(this.longPressCountdown);

        this.callback(e, u);
        e.preventDefault();
    };
}
