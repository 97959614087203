import React from "react";
import { connect } from "react-redux";
import { withStyles } from "@mui/styles";
import PropTypes from "prop-types";
import ReactPlayer from "react-player";
import { Box, IconButton, Menu, Slider, SvgIcon, Tooltip } from "@mui/material";
import { PlayArrow, Stop, VolumeOff, VolumeUp } from "@mui/icons-material";
import clsx from "clsx";
import { getPath } from "../../global/route";
import { loadedResources } from "../../global/resources";


const styles = theme => ({
});

class Radio extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            playing: false,
            muted: false,
            volume: 0.5,
            radioAnchorEl: null,
        }

        this.active = false;
        this.startedAt = null;
    }

    componentDidMount() {
        let settings = localStorage.getItem('general_settings');
        if (!settings) {
            settings = JSON.stringify({
                in_game_double_click_closed: false,
                play_radio_at_startup: true,
                msg_time: false,
                max_message_count: 100,
            });

            localStorage.setItem('general_settings', settings);
        }

        settings = JSON.parse(settings);

        const callback = () => {
            this.active = true;

            if (settings.play_radio_at_startup) {
                this.start();
            }

            document.removeEventListener('click', callback);
        }

        document.addEventListener('click', callback);

        window.addEventListener('radio_on', (e) => {
            this.setState({ playing: true });
        });

        window.addEventListener('radio_off', (e) => {
            this.setState({ playing: false });
        });

        window.addEventListener('volume_on', (e) => {
            this.setState({ volume: 0.5, muted: false });
        });

        window.addEventListener('volume_off', (e) => {
            this.setState({ volume: 0, muted: true });
        });
    }

    componentDidUpdate(prevProps) {
    }

    handleVolume = (newValue) => {
        const radio = document.getElementById('radio');
        radio.volume = newValue / 100;

        if ((this.state.volume === 0 && newValue > 0) || (this.state.volume > 0 && newValue === 0)) {
            window.dispatchEvent(new CustomEvent('volume', { 'detail': { muted: newValue === 0 } }));
        }

        this.setState({ volume: newValue / 100, muted: newValue === 0 });
    };

    start = () => {
        const radio = document.getElementById('radio');
        radio.play();

        if (!this.startedAt) {
            this.startedAt = Date.now();
        } else {
            const duration = Date.now() - this.startedAt;
            radio.currentTime = duration / 1000;
        }

        window.dispatchEvent(new CustomEvent('radio', { 'detail': { playing: true } }));
        this.setState({ playing: true });
    }

    pause = () => {
        const radio = document.getElementById('radio');
        radio.pause();

        window.dispatchEvent(new CustomEvent('radio', { 'detail': { playing: false } }));
        this.setState({ playing: false });
    }

    toggleRadio = () => {
        const { playing } = this.state;

        if (!playing) this.start();
        else this.pause();
    }

    radio = (isMobile) => {

        const { classes } = this.props;
        const { playing, muted, volume } = this.state;

        return (
            <Box className={clsx(
                "flex justify-center items-middle gap-x-0 md:gap-x-1"
            )}>
                <Tooltip title={playing ? "Durdur" : "Başlat"} placement="bottom">
                    <IconButton disableFocusRipple disableRipple disableTouchRipple
                        sx={{
                            ':hover .MuiSvgIcon-root': { color: '#ffffff' }
                        }}
                        className="!p-0"
                        onClick={this.toggleRadio}
                    >
                        <SvgIcon className="!w-5 !h-5 md:!w-7 md:!h-7">
                            {playing ? <Stop htmlColor="#e8e8e8" /> : <PlayArrow htmlColor="#e8e8e8" />}
                        </SvgIcon>
                    </IconButton>
                </Tooltip>

                <Tooltip title={muted ? "Sesi Aç" : "Sesi Kapat"} placement="bottom">
                    <Box className="flex justify-center items-center"
                        sx={{
                            ':hover .volume': { display: 'flex' }
                        }}
                    >
                        <IconButton disableFocusRipple disableRipple disableTouchRipple
                            sx={{
                                ':hover .MuiSvgIcon-root': { color: '#ffffff' },
                            }}
                            className="!p-0"
                            onClick={() => this.handleVolume(muted ? 50 : 0)}
                        >
                            <SvgIcon className="!w-5 !h-5 md:!w-7 md:!h-7">
                                {muted ? <VolumeOff htmlColor="#e8e8e8" /> : <VolumeUp htmlColor="#e8e8e8" />}
                            </SvgIcon>
                        </IconButton>
                    </Box>
                </Tooltip>

                <Tooltip title={`${parseInt(volume * 100)}`} placement="bottom">
                    <Box className="flex items-center w-16 volume px-2">
                        <Slider value={volume * 100} onChange={(e, newValue) => this.handleVolume(newValue)}
                            sx={{
                                color: 'white', padding: '0 !important', margin: '0',
                                '& .MuiSlider-thumb': { width: '12px', height: '12px' },
                                '& .MuiSlider-thumb:hover': { boxShadow: 'none' },
                            }}
                        />
                    </Box>
                </Tooltip>
            </Box>
        );
    }

    mobileRadio = () => {
        const rect = this.state.radioAnchorEl?.getBoundingClientRect();
        const imgPath = `${process.env.REACT_APP_CDN_URL}/web/img`;

        return (
            <>
                <Tooltip title="Radyo" placement="bottom">
                    <Box sx={{ cursor: 'pointer', boxShadow: '0 0 0 1px #444' }} justifyContent={"center"}
                        onClick={(e) => this.setState({ radioAnchorEl: e.currentTarget })}
                        className={clsx(
                            "flex md:hidden relative items-center h-full w-auto aspect-square",
                            "text-white bg-gradient-to-r from-teal-400 via-teal-500 to-teal-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-teal-300 dark:focus:ring-teal-800 shadow-lg shadow-teal-500/50 dark:shadow-lg dark:shadow-teal-800/80 font-medium rounded-lg text-sm text-center",
                        )}
                    >
                        <img src={loadedResources[`${imgPath}/radio.svg`]} alt="Radyo"
                            className="w-full h-auto p-2"
                        />
                    </Box>
                </Tooltip>

                <Menu open={Boolean(this.state.radioAnchorEl)} anchorReference="anchorPosition"
                    anchorPosition={{ top: rect?.y + rect?.height + 4, left: rect?.x + rect?.width + 96 }}
                    onClose={() => this.setState({ radioAnchorEl: null })}
                    sx={{
                        '& .MuiPaper-root': {
                            backgroundColor: '#1f2937', px: 1, py: 2,
                        },
                    }}
                >
                    {this.radio()}
                </Menu>
            </>
        );

        return (
            <Box className="relative block md:hidden">
                <IconButton disableFocusRipple disableRipple disableTouchRipple
                    className={clsx(
                        "relative items-center h-full w-auto aspect-square",
                        "text-white bg-gradient-to-r from-teal-400 via-teal-500 to-teal-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-teal-300 dark:focus:ring-teal-800 shadow-lg shadow-teal-500/50 dark:shadow-lg dark:shadow-teal-800/80 font-medium rounded-lg text-sm text-center",
                    )}
                    onClick={(e) => this.setState({ radioAnchorEl: e.currentTarget })}
                    sx={{ outline: '1px solid #444' }}
                >
                    <img src={loadedResources[`${imgPath}/radio.svg`]} alt="Radyo"
                        className="w-full h-auto p-2"
                    />
                </IconButton>

                <Menu open={Boolean(this.state.radioAnchorEl)} anchorReference="anchorPosition"
                    anchorPosition={{ top: rect?.y + rect?.height + 4, left: rect?.x + rect?.width + 96 }}
                    onClose={() => this.setState({ radioAnchorEl: null })}
                    sx={{
                        '& .MuiPaper-root': {
                            backgroundColor: '#1f2937', px: 1, py: 2,
                        },
                    }}
                >
                    {this.radio()}
                </Menu>
            </Box>
        );
    }

    render() {
        if (!this.props.roomConfig?.radio_url) {
            return null;
        }

        return (
            <Box className="flex gap-x-1 md:gap-x-2 items-center h-full pl-1 md:pl-2 py-1.5 md:py-3">

                <Box className="hidden md:flex items-center">
                    {this.radio()}
                </Box>

                {this.mobileRadio()}

                <audio id="radio" controls style={{ width: 0, height: 0, visibility: 'hidden' }}>
                    <source src={this.props.roomConfig?.radio_url} type="audio/mpeg" />
                </audio>
            </Box>
        );
    }
}

Radio.propTypes = {
    classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
    return {
        roomConfig: state.app.global.roomConfig,
    }
};

export default withStyles(styles)(connect(mapStateToProps, {
})(Radio));
