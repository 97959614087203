import React from "react";
import { connect } from "react-redux";
import { withStyles } from "@mui/styles";
import PropTypes from "prop-types";
import {
    Box, Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormControlLabel,
    FormGroup, IconButton, MenuItem, RadioGroup, Select, Tab, Tabs, TextField, Tooltip, Typography
} from "@mui/material";
import { Close } from "@mui/icons-material";
import clsx from "clsx";
import axios, { AxiosError } from "axios";
import { ERROR_CODES } from "../../global/error";
import { GlobalTypes } from "../../action_types";
import { setNotification, setGeneralSettings } from "../../actions/global";
import { AxiosClient } from "../../global/axios";


const styles = theme => ({
});

const tabs = {
    GENERAL_SETTINGS: 0,
};

class GeneralSettings extends React.Component {
    constructor(props) {
        super(props);

        this.fontSizes = [
            { name: 'Çok Küçük', size: 12 },
            { name: 'Küçük', size: 15 },
            { name: 'Orta', size: 18 },
            { name: 'Büyük', size: 19 },
            { name: 'Çok Büyük', size: 20 },
        ];

        this.state = {
            tab: tabs.GENERAL_SETTINGS,
            settings: {
                in_game_double_click_closed: false,
                play_radio_at_startup: true,
                dm_closed: false,
                msg_time: false,
                invitation_closed: false,
                max_message_count: 100,
                font_size: this.fontSizes[3].size,
            },
            loading: false,
        }
    }

    componentDidMount() {
        const settings = this.getSettings();
        this.setState({ settings: { ...settings } });
        document.documentElement.style.fontSize = `${settings.font_size}px`;
    }

    componentDidUpdate(prevProps) {
        if (prevProps.open !== this.props.open && this.props.open) {
            this.initialize();
        }
    }

    getSettings = () => {
        let settings = localStorage.getItem('general_settings');
        if (!settings) {
            settings = JSON.stringify({
                in_game_double_click_closed: false,
                play_radio_at_startup: true,
                dm_closed: false,
                msg_time: false,
                invitation_closed: false,
                max_message_count: 100,
                font_size: this.fontSizes[3].size,
            });

            localStorage.setItem('general_settings', settings);
        }

        const settingsObj = JSON.parse(settings);
        if (!settingsObj.font_size) {
            settingsObj.font_size = this.fontSizes[3].size;
        }

        if (!settingsObj.max_message_count) {
            settingsObj.max_message_count = 100;
        }

        if (!settingsObj.dm_closed) {
            settingsObj.dm_closed = false;
        }

        if (!settingsObj.invitation_closed) {
            settingsObj.invitation_closed = false;
        }

        return settingsObj;
    }

    initialize = async () => {
        const settings = this.getSettings();

        const profileText = sessionStorage.getItem('profile') ?? localStorage.getItem('profile');
        const profile = JSON.parse(profileText);

        const resp = await AxiosClient().get(`${process.env.REACT_APP_API_URL}/v1/user/profile/${profile?.id}`);

        if (!resp.data.user) {
            throw new Error("User not found");
        }

        this.setState({ settings: { ...settings, dm_closed: resp.data.user.dm_closed, invitation_closed: resp.data.user.invitation_closed } });
    }

    doSave = async () => {
        this.setState({ loading: true });

        const settings = {
            in_game_double_click_closed: this.state.settings.in_game_double_click_closed,
            play_radio_at_startup: this.state.settings.play_radio_at_startup,
            msg_time: this.state.settings.msg_time,
            max_message_count: this.state.settings.max_message_count,
            font_size: this.state.settings.font_size,
        };

        localStorage.setItem('general_settings', JSON.stringify(settings));
        document.documentElement.style.fontSize = `${this.state.settings.font_size}px`;

        this.props.setGeneralSettings(settings);

        try {
            const req = {
                dm_closed: this.state.settings.dm_closed,
                invitation_closed: this.state.settings.invitation_closed,
            };

            await AxiosClient().post(`${process.env.REACT_APP_API_URL}/v1/user/general.settings`, req);
            const message = 'Ayarlarınız başarıyla kaydedildi.';
            this.props.setNotification({ message, severity: GlobalTypes.NOTIFY_SUCC });
            //this.props.onClose();
        } catch (e) {
            let message = ERROR_CODES[2];

            if (e instanceof AxiosError) {
                const code = e.response?.data?.error?.code;
                if (code in ERROR_CODES) {
                    message = ERROR_CODES[code];
                } else {
                    message = e.message;
                }
            }

            this.props.setNotification({ message: message, severity: GlobalTypes.NOTIFY_ERR, traceId: e.response?.data?.trace_id });
        } finally {
            this.setState({ loading: false });
        }
    }

    onTabChange = (e, value) => {
        this.setState({ tab: value });
    }

    generalContent = () => {
        const { settings } = this.state;

        return (
            <Box className="flex flex-col px-5 py-2 gap-y-2">
                <FormGroup>
                    <FormControlLabel control={
                        <Checkbox checked={settings.msg_time}
                            onChange={e => this.setState({ settings: { ...settings, msg_time: e.target.checked } })}
                            className="!py-0 !px-0 md:!px-1 md:!py-1"
                        />
                    }
                        label={<Typography className={clsx("!text-xs md:!text-sm lg:!text-base")} sx={{ color: '#828282' }}>
                            Mesajlarda Saat Bilgisini Göster
                        </Typography>
                        }
                    />
                    <FormControlLabel control={
                        <Checkbox checked={settings.dm_closed}
                            onChange={e => this.setState({ settings: { ...settings, dm_closed: e.target.checked } })}
                            className="!py-0 !px-0 md:!px-1 md:!py-1"
                        />
                    }
                        label={<Typography className={clsx("!text-xs md:!text-sm lg:!text-base")} sx={{ color: '#828282' }}>
                            Özeli Kapat
                        </Typography>
                        }
                    />

                    <FormControlLabel control={
                        <Checkbox checked={settings.invitation_closed}
                            onChange={e => this.setState({ settings: { ...settings, invitation_closed: e.target.checked } })}
                            className="!py-0 !px-0 md:!px-1 md:!py-1"
                        />
                    }
                        label={<Typography className={clsx("!text-xs md:!text-sm lg:!text-base")} sx={{ color: '#828282' }}>
                            Davetleri Kapat
                        </Typography>
                        }
                    />

                    <FormControlLabel control={
                        <Checkbox checked={settings.in_game_double_click_closed}
                            onChange={e => this.setState({ settings: { ...settings, in_game_double_click_closed: e.target.checked } })}
                            className="!py-0 !px-0 md:!px-1 md:!py-1"
                        />
                    }
                        label={<Typography className={clsx("!text-xs md:!text-sm lg:!text-base")} sx={{ color: '#828282' }}>
                            Oyuniçi Çift Tıklamayı Kapat
                        </Typography>
                        }
                    />

                    <FormControlLabel control={
                        <Checkbox checked={settings.play_radio_at_startup}
                            onChange={e => this.setState({ settings: { ...settings, play_radio_at_startup: e.target.checked } })}
                            className="!py-0 !px-0 md:!px-1 md:!py-1"
                        />
                    }
                        label={<Typography className={clsx("!text-xs md:!text-sm lg:!text-base")} sx={{ color: '#828282' }}>
                            Açılışta Radyoyu Çal
                        </Typography>
                        }
                    />
                </FormGroup>

                <TextField select margin="dense" size="small" label={
                    "Sohbet Maksimum Mesaj Sayısı"
                }
                    className="w-28"
                    InputProps={{
                        className: "!text-xs sm:!text-sm md:!text-base text-[#828282]",
                    }}
                    InputLabelProps={{
                        className: "!text-xs sm:!text-sm md:!text-base text-[#828282]"
                    }}
                    fullWidth
                    value={settings.max_message_count}
                    onChange={e => this.setState({ settings: { ...settings, max_message_count: e.target.value } })}>
                    <MenuItem key={"max_lines_100"} value={100}>
                        <Typography className="!text-xs sm:!text-sm md:!text-base text-[#828282]">
                            100
                        </Typography>
                    </MenuItem>
                    <MenuItem key={"max_lines_500"} value={500}>
                        <Typography className="!text-xs sm:!text-sm md:!text-base text-[#828282]">
                            500
                        </Typography>
                    </MenuItem>
                    <MenuItem key={"max_lines_1000"} value={1000}>
                        <Typography className="!text-xs sm:!text-sm md:!text-base text-[#828282]">
                            1000
                        </Typography>
                    </MenuItem>
                </TextField>

                <TextField select margin="dense" size="small" label={
                    "Yazı Boyutu"
                }
                    InputProps={{
                        className: "!text-xs sm:!text-sm md:!text-base text-[#828282]",
                    }}
                    InputLabelProps={{
                        className: "!text-xs sm:!text-sm md:!text-base text-[#828282]"
                    }}
                    fullWidth
                    value={this.state.settings.font_size ?? this.fontSizes[3].size}
                    onChange={e => this.setState({ settings: { ...this.state.settings, font_size: e.target.value } })}>
                    {this.fontSizes.map(fs => (
                        <MenuItem key={fs.name} value={fs.size}>
                            <Typography className="!text-xs sm:!text-sm md:!text-base text-[#828282]">
                                {fs.name}
                            </Typography>
                        </MenuItem>
                    ))}
                </TextField>
            </Box>
        );
    }

    content = () => {
        switch (this.state.tab) {
            case tabs.GENERAL_SETTINGS:
                return this.generalContent();
            default:
                return null;
        }
    }

    render() {
        const { classes } = this.props;
        const disabled = this.state.loading;

        return (
            <Dialog
                maxWidth={"xs"}
                fullWidth
                open={this.props.open}
            >
                <DialogTitle>
                    <Box padding={0} display={"flex"} justifyContent={"space-between"}>
                        <Typography className={"!text-xs sm:!text-sm md:!text-base"}>
                            Genel Ayarlarım
                        </Typography>

                        <Tooltip title="Kapat" placement="top">
                            <IconButton size="small" onClick={this.props.onClose} sx={{ backgroundColor: '#624799 !important' }}>
                                <Close htmlColor="var(--white)" fontSize="small" />
                            </IconButton>
                        </Tooltip>
                    </Box>
                </DialogTitle>

                <DialogContent sx={{ p: 0 }}>
                    <Tabs
                        sx={{
                            width: 'fit-content',
                            '& .MuiTabs-flexContainer': { columnGap: 0 },
                            '& .MuiTabs-indicator': { backgroundColor: "#FF9559", height: '4px' },
                            '& .MuiTab-root': { color: '#505050 !important', flex: 1, borderBottom: '1px solid #E3E3E3', padding: 0 },
                            '& .Mui-selected': { color: '#505050 !important', backgroundColor: '#FFF3EC' },
                        }}
                        value={this.state.tab}
                        textColor="primary"
                        className={classes.tabs}
                        onChange={this.onTabChange}>

                        <Tab value={tabs.GENERAL_SETTINGS}
                            label={
                                <Box display={"flex"} columnGap={2} flex={1} p={2} justifyContent={"center"}>
                                    <Typography className={"!text-xs sm:!text-sm md:!text-base"}>Ayarlar</Typography>
                                </Box>
                            }
                            sx={{ textTransform: 'none', padding: 3, flexDirection: 'row', borderRight: '1px solid #E3E3E3' }} />
                    </Tabs>

                    {this.content()}
                </DialogContent>

                <DialogActions sx={{ p: 2 }}>
                    <Button variant="contained" disabled={false} onClick={() => this.props.onClose()}
                        disableFocusRipple disableRipple disableTouchRipple color="error"
                        sx={{
                            color: '#FFFFFF',
                        }}
                        className="!text-xs sm:!text-sm md:!text-base"
                    >
                        İptal
                    </Button>

                    <Button variant="contained" disabled={!!disabled} onClick={() => this.doSave()}
                        disableFocusRipple disableRipple disableTouchRipple
                        sx={{
                            color: '#FFFFFF !important', backgroundColor: disabled ? '#939393 !important' : null,
                            backgroundImage: disabled ? null : "linear-gradient(to bottom, #2FB16B 50%, #168549)"
                        }}
                        className="!text-xs sm:!text-sm md:!text-base"
                    >
                        Kaydet
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

GeneralSettings.propTypes = {
    classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
    return {
    }
};

export default withStyles(styles)(connect(mapStateToProps, {
    setNotification, setGeneralSettings
})(GeneralSettings));
