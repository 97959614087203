import React from "react";
import { connect } from "react-redux";
import { withStyles } from "@mui/styles";
import PropTypes from "prop-types";
import { Box, Button, Card, CardContent, Checkbox, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, FormControlLabel, FormGroup, IconButton, Pagination, Radio, RadioGroup, SvgIcon, Tab, Table, TableBody, TableCell, TableHead, TableRow, Tabs, Tooltip, Typography } from "@mui/material";
import { mdiPause, mdiPlay, mdiPlusCircle } from "@mdi/js";
import axios from "axios";
import { setAdminTab, setNotification } from "../../../actions/global";
import { GlobalTypes } from "../../../action_types";
import EditAnnouncement from "./EditAnnouncement";
import {
    Edit as EditIcon, Delete as DeleteIcon,
} from "@mui/icons-material";
import clsx from "clsx";
import { AxiosClient } from "../../../global/axios";


const styles = theme => ({
    table: {
        border: '2px solid #f0f0f0',
        userSelect: 'text !important',
    },
    tCell: {
        border: 0,
        padding: 32,
        verticalAlign: 'top',
    },
    cellHeader: {
        fontFamily: 'Roboto Slab',
        width: 180,
        fontWeight: 700,
    },
    cellContent: {
        color: '#6b7280 !important',
        fontFamily: 'Roboto Slab !important',
    },
    text: {
        fontWeight: '400 !important',
        color: 'var(--white)',
    },
});

const tabs = {
    ANNOUNCEMENTS: 0,
}

class GlobalSettings extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            tab: tabs.ANNOUNCEMENTS,
            search: '',
            page: 1,
            size: 25,
            announcements: [],
            totalAnnouncements: 0,
            edit: false,
            editAnnouncement: null,
            deleteAnnouncement: null,
        }

    }

    componentDidMount() {
        this.listAnnouncements();
        this.setState({ tab: this.props.adminTab });
    }

    componentDidUpdate(prevProps) {
    }

    onAddAnnouncement = () => {
        this.setState({ edit: true });
    }

    listAnnouncements = async () => {
        try {
            const { search, page, size } = this.state;
            const resp = await AxiosClient().get(`${process.env.REACT_APP_API_URL}/v1/admin/global/announcements?search=${search}&page=${page}&size=${size}`);

            this.setState({ announcements: resp.data.data ?? [], totalAnnouncements: resp.data.total ?? 0 });

        } catch (err) {
            console.error(err);
            this.props.setNotification({ message: 'Bir hata oluştu. Lütfen tekrar deneyiniz.', severity: GlobalTypes.NOTIFY_ERR });
        }
    }

    handleEdit = (a) => {
        this.setState({ edit: true, editAnnouncement: a });
    }

    handleDelete = (a) => {
        this.setState({ deleteAnnouncement: a });
    }

    editIsActive = async (a) => {
        try {
            const resp = await AxiosClient().post(`${process.env.REACT_APP_API_URL}/v1/admin/global/announcements`, a);

            if (resp.data?.ok) {
                this.props.setNotification({ message: 'Duyuru güncellendi.', severity: GlobalTypes.NOTIFY_SUCC });
                this.listAnnouncements();
            }

        } catch (err) {
            console.error(err);
            this.props.setNotification({ message: 'Bir hata oluştu. Lütfen tekrar deneyiniz.', severity: GlobalTypes.NOTIFY_ERR });
        }
    }

    deleteAnnouncement = async (a) => {
        try {
            const resp = await AxiosClient().delete(`${process.env.REACT_APP_API_URL}/v1/admin/global/announcements/${a.id}`);

            if (resp.data?.ok) {
                this.props.setNotification({ message: 'Duyuru silindi.', severity: GlobalTypes.NOTIFY_SUCC });
                this.listAnnouncements();
            }

        } catch (err) {
            console.error(err);
            this.props.setNotification({ message: 'Bir hata oluştu. Lütfen tekrar deneyiniz.', severity: GlobalTypes.NOTIFY_ERR });
        } finally {
            this.setState({ deleteAnnouncement: null }, () => this.listAnnouncements());
        }
    }

    deleteAnnouncementDialog = () => {
        const { deleteAnnouncement } = this.state;

        return (
            <Dialog open={!!deleteAnnouncement} onClose={() => this.setState({ deleteAnnouncement: null })}>
                <DialogTitle>Duyuru Sil</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Duyuru silinecek, emin misiniz?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => this.setState({ deleteAnnouncement: null })} variant="contained" color="error">
                        İptal
                    </Button>
                    <Button onClick={() => this.deleteAnnouncement(deleteAnnouncement)} variant="contained" color="primary" autoFocus className="!text-white">
                        Sil
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }

    onTabChange = (e, value) => {
        this.props.setAdminTab(value);
        this.setState({ tab: value });
    }

    tableView = () => {
        const { classes } = this.props;
        const { announcements } = this.state;

        if (announcements.length === 0) {
            return (
                <Box className="!hidden lg:!flex justify-center items-center h-[300px]">
                    <Typography variant="h6" className={classes.title}>Sonuç bulunamadı.</Typography>
                </Box>
            );
        }

        return (
            <Table className={clsx(
                classes.table,
                "!hidden lg:!table",
            )}>
                <TableHead>
                    <TableRow>
                        <TableCell className={classes.tCell} sx={{ borderBottom: '2px solid #f0f0f0', minWidth: 0, maxWidth: 0 }}>
                            <Typography className={clsx(classes.cellHeader, "!text-xs md:!text-sm lg:!text-base")}>
                                Mesaj
                            </Typography>
                        </TableCell>
                        <TableCell className={classes.tCell} sx={{ borderBottom: '2px solid #f0f0f0', minWidth: 0, maxWidth: 0 }}>
                            <Typography className={clsx(classes.cellHeader, "!text-xs md:!text-sm lg:!text-base")}>
                                Cron
                            </Typography>
                        </TableCell>
                        <TableCell className={classes.tCell} sx={{ borderBottom: '2px solid #f0f0f0', minWidth: 0, maxWidth: 0 }}>
                            <Typography className={clsx(classes.cellHeader, "!text-xs md:!text-sm lg:!text-base")}>
                                Ekleyen
                            </Typography>
                        </TableCell>
                        <TableCell className={classes.tCell} sx={{ borderBottom: '2px solid #f0f0f0', minWidth: 0, maxWidth: 0 }}>
                            <Typography className={clsx(classes.cellHeader, "!text-xs md:!text-sm lg:!text-base")}>
                                Aktif
                            </Typography>
                        </TableCell>
                        <TableCell className={classes.tCell} sx={{ borderBottom: '2px solid #f0f0f0', minWidth: 0, maxWidth: 0 }}>
                            <Typography className={clsx(classes.cellHeader, "!text-xs md:!text-sm lg:!text-base")}>
                                Eklenen Tarih
                            </Typography>
                        </TableCell>
                        <TableCell className={classes.tCell} sx={{ borderBottom: '2px solid #f0f0f0', minWidth: 0, maxWidth: 0 }}>
                            <Typography className={clsx(classes.cellHeader, "!text-xs md:!text-sm lg:!text-base")}>
                                Son Güncelleme
                            </Typography>
                        </TableCell>
                        <TableCell className={classes.tCell} sx={{ borderBottom: '2px solid #f0f0f0', minWidth: 0, maxWidth: 0 }}>
                            <Typography className={clsx(classes.cellHeader, "!text-xs md:!text-sm lg:!text-base")}>
                                Aksiyon
                            </Typography>
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>

                    {announcements.map(a => {
                        return (
                            <TableRow key={a.id}>
                                <TableCell>
                                    <Typography className={clsx(
                                        classes.cellContent,
                                        "max-w-[240px] xl:max-w-[360px]",
                                        "!text-xs md:!text-sm lg:!text-base"
                                    )}>
                                        {a.message}
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography className={clsx(classes.cellContent, "!text-xs md:!text-sm lg:!text-base")}>
                                        {a.cron}
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography className={clsx(classes.cellContent, "!text-xs md:!text-sm lg:!text-base")}>
                                        {a.created_by}
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography className={clsx(classes.cellContent, "!text-xs md:!text-sm lg:!text-base")}>
                                        {a.is_active ? "Evet" : "Hayır"}
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography className={clsx(classes.cellContent, "!text-xs md:!text-sm lg:!text-base")}>
                                        {new Date(a.created_at).toLocaleDateString()}
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography className={clsx(classes.cellContent, "!text-xs md:!text-sm lg:!text-base")}>
                                        {new Date(a.updated_at).toLocaleDateString()}
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    {a.is_active ? (
                                        <Tooltip title="Duraklat" placement="top">
                                            <IconButton onClick={() => this.editIsActive({ ...a, is_active: false })}>
                                                <SvgIcon fontSize="small" color="error"><path d={mdiPause} /></SvgIcon>
                                            </IconButton>
                                        </Tooltip>
                                    ) : (
                                        <Tooltip title="Başlat" placement="top">
                                            <IconButton onClick={() => this.editIsActive({ ...a, is_active: true })}>
                                                <SvgIcon fontSize="small" color="success"><path d={mdiPlay} /></SvgIcon>
                                            </IconButton>
                                        </Tooltip>
                                    )}
                                    <Tooltip title="Düzenle" placement="top">
                                        <IconButton onClick={() => this.handleEdit(a)}>
                                            <EditIcon fontSize="small" />
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip title="Sil" placement="top">
                                        <IconButton onClick={() => this.handleDelete(a)}>
                                            <DeleteIcon fontSize="small" />
                                        </IconButton>
                                    </Tooltip>
                                </TableCell>
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
        );
    }

    mobileView = () => {
        const { classes } = this.props;
        const { announcements } = this.state;

        if (announcements.length === 0) {
            return (
                <Box className="flex lg:hidden justify-center items-center">
                    <Typography variant="h6" className={classes.title}>Sonuç bulunamadı.</Typography>
                </Box>
            );
        }

        return (
            <Box className="flex flex-col gap-y-2 lg:hidden">
                {announcements.map(a => {
                    return (
                        <Card key={a.id} sx={{ borderRadius: 4, border: '1px solid #c4c4c4', boxShadow: 0 }}>
                            <CardContent>
                                <Table>
                                    <TableRow>
                                        <TableCell className="!p-2">
                                            <Typography className={clsx(classes.cellContent, "!text-xs md:!text-sm lg:!text-base")}>
                                                Mesaj
                                            </Typography>
                                        </TableCell>
                                        <TableCell className="!p-2">
                                            <Typography className={clsx(classes.cellContent, "!text-xs md:!text-sm lg:!text-base")}>
                                                {a.message}
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell className="!p-2">
                                            <Typography className={clsx(classes.cellContent, "!text-xs md:!text-sm lg:!text-base")}>
                                                Cron
                                            </Typography>
                                        </TableCell>
                                        <TableCell className="!p-2">
                                            <Typography className={clsx(classes.cellContent, "!text-xs md:!text-sm lg:!text-base")}>
                                                {a.cron}
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell className="!p-2">
                                            <Typography className={clsx(classes.cellContent, "!text-xs md:!text-sm lg:!text-base")}>
                                                Ekleyen
                                            </Typography>
                                        </TableCell>
                                        <TableCell className="!p-2">
                                            <Typography className={clsx(classes.cellContent, "!text-xs md:!text-sm lg:!text-base")}>
                                                {a.created_by}
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell className="!p-2">
                                            <Typography className={clsx(classes.cellContent, "!text-xs md:!text-sm lg:!text-base")}>
                                                Aktif
                                            </Typography>
                                        </TableCell>
                                        <TableCell className="!p-2">
                                            <Typography className={clsx(classes.cellContent, "!text-xs md:!text-sm lg:!text-base")}>
                                                {a.is_active ? "Evet" : "Hayır"}
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell className="!p-2">
                                            <Typography className={clsx(classes.cellContent, "!text-xs md:!text-sm lg:!text-base")}>
                                                Eklenen Tarih
                                            </Typography>
                                        </TableCell>
                                        <TableCell className="!p-2">
                                            <Typography className={clsx(classes.cellContent, "!text-xs md:!text-sm lg:!text-base")}>
                                                {new Date(a.created_at).toLocaleDateString()}
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell className="!p-2">
                                            <Typography className={clsx(classes.cellContent, "!text-xs md:!text-sm lg:!text-base")}>
                                                Son Güncelleme
                                            </Typography>
                                        </TableCell>
                                        <TableCell className="!p-2">
                                            <Typography className={clsx(classes.cellContent, "!text-xs md:!text-sm lg:!text-base")}>
                                                {new Date(a.updated_at).toLocaleDateString()}
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell className="!p-2">
                                            <Typography className={clsx(classes.cellContent, "!text-xs md:!text-sm lg:!text-base")}>
                                                Aksiyon
                                            </Typography>
                                        </TableCell>
                                        <TableCell className="!p-2">
                                            {a.is_active ? (
                                                <Tooltip title="Duraklat" placement="top">
                                                    <IconButton className="!p-[2px] !mr-2" onClick={() => this.editIsActive({ ...a, is_active: false })}>
                                                        <SvgIcon className="!text-[16px]" color="error"><path d={mdiPause} /></SvgIcon>
                                                    </IconButton>
                                                </Tooltip>
                                            ) : (
                                                <Tooltip title="Başlat" placement="top">
                                                    <IconButton className="!p-[2px] !mr-2" onClick={() => this.editIsActive({ ...a, is_active: true })}>
                                                        <SvgIcon className="!text-[16px]" color="success"><path d={mdiPlay} /></SvgIcon>
                                                    </IconButton>
                                                </Tooltip>
                                            )}
                                            <Tooltip title="Düzenle" placement="top">
                                                <IconButton className="!p-[2px] !mr-2" onClick={() => this.handleEdit(a)}>
                                                    <EditIcon className="!text-[16px]" />
                                                </IconButton>
                                            </Tooltip>
                                            <Tooltip title="Sil" placement="top">
                                                <IconButton className="!p-[2px] !mr-2" onClick={() => this.handleDelete(a)}>
                                                    <DeleteIcon className="!text-[16px]" />
                                                </IconButton>
                                            </Tooltip>
                                        </TableCell>
                                    </TableRow>
                                </Table>
                            </CardContent>
                        </Card>
                    );
                })}
            </Box>
        );
    }

    announcementsTab = () => {
        const { classes } = this.props;

        return (
            <Box margin={1} display={"flex"} flexDirection={"column"} rowGap={1}>
                <Box className="flex gap-x-2 justify-between items-center">
                    <Typography variant="h6" className={classes.title}></Typography>

                    <Button variant="contained" sx={{ backgroundImage: "linear-gradient(to bottom, #2FB16B 50%, #168549)" }}
                        onClick={this.onAddAnnouncement}
                        className="h-9"
                    >
                        <Box display="flex" columnGap="8px">
                            <SvgIcon fontSize="small" sx={{ margin: 'auto' }}><path d={mdiPlusCircle} color="var(--white)" /></SvgIcon>
                            <Typography className={clsx(classes.text, "!text-xs md:!text-sm lg:!text-base")} alignSelf={"center"}>Ekle</Typography>
                        </Box>
                    </Button>
                </Box>

                {this.tableView()}
                {this.mobileView()}

                <Box className="flex justify-end">
                    <Pagination count={parseInt(Math.ceil(this.state.totalAnnouncements / this.state.size))} page={this.state.page}
                        variant="outlined" shape="rounded" color="primary"
                        onChange={(e, p) => this.setState({ page: p }, () => this.listAnnouncements())}
                    />
                </Box>

            </Box>
        );
    }

    content = () => {
        switch (this.state.tab) {
            case tabs.ANNOUNCEMENTS:
                return this.announcementsTab();
        }
    }

    render() {
        const { classes } = this.props;

        return (
            <Box>
                <EditAnnouncement open={this.state.edit} announcement={this.state.editAnnouncement}
                    onClose={(refresh) => this.setState({ edit: false, editAnnouncement: null }, () => refresh && this.listAnnouncements())}
                />

                <Tabs
                    sx={{
                        width: 'fit-content',
                        '& .MuiTabs-flexContainer': { columnGap: 0 },
                        '& .MuiTabs-indicator': { backgroundColor: "#FF9559", height: '4px' },
                        '& .MuiTab-root': { color: '#505050 !important', flex: 1, borderBottom: '1px solid #E3E3E3', padding: 0 },
                        '& .Mui-selected': { color: '#505050 !important', backgroundColor: '#FFF3EC' },
                    }}
                    value={this.state.tab}
                    textColor="primary"
                    className={classes.tabs}
                    onChange={this.onTabChange}>

                    <Tab value={tabs.ANNOUNCEMENTS}
                        label={
                            <Box display={"flex"} columnGap={2} flex={1} p={2} justifyContent={"space-between"}>
                                <Typography className={"!text-xs md:!text-sm lg:!text-base"}>Duyurular</Typography>
                            </Box>
                        }
                        sx={{ textTransform: 'none', padding: 3, flexDirection: 'row', borderRight: '1px solid #E3E3E3' }} />
                </Tabs>

                {this.content()}

                {this.deleteAnnouncementDialog()}
            </Box>
        );
    }
}

GlobalSettings.propTypes = {
    classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
    return {
        adminTab: state.app.global.adminTab,
    }
};

export default withStyles(styles)(connect(mapStateToProps, {
    setNotification, setAdminTab,
})(GlobalSettings));
