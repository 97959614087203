import React from "react";
import { Box, Typography } from "@mui/material";
import { connect } from "react-redux";
import { withStyles } from "@mui/styles";

const styles = (theme) => ({});

class Piece extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    const { isActive, color, title, count, img } = this.props;

    return (
      <Box sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        alignItems: "center",
        pt: 3,
        pb: 1,
        width: "130px",
        minWidth: "130px",
        height: "200px",
        minHeight: "200px",
        borderBottom: `3px solid ${color}`,
        borderRadius: "12px",
        boxShadow: "0px 1px 3px rgba(0, 0, 0, 0.08), 0px 15px 40px rgba(122, 133, 255, 0.2)",
        backgroundColor: "#fff",
      }}>
        <Typography sx={{ textAlign: "center", fontSize: "26px", fontWeight: "bold", letterSpacing: "-0.02rem", color: "#1E2C5F" }}>
          {title}
        </Typography>

        <Box className="flex flex-1 justify-center px-4">
          <img src={img} alt={title} draggable={false} />
        </Box>

        <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", background: `${color}50`, borderRadius: "300px", py: "3px", px: "8px" }}>
          {/* {isActive && <Person fontSize="13px" sx={{fill: color}}/>} */}
          <Typography variant="h2" component="h2" sx={{ textAlign: "center", fontSize: "13px", fontWeight: 500, letterSpacing: "-0.02rem", color: color }}>
            {isActive ? "Aktif" : "Çok yakında"}
          </Typography>
        </Box>
      </Box>
    );
  }
}

const mapStateToProps = (state) => {
  return {
  }
};

export default withStyles(styles)(connect(mapStateToProps, {})(Piece));