import React from "react";
import { connect } from "react-redux";
import { withStyles } from "@mui/styles";
import PropTypes from "prop-types";
import { Box, Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, Divider, FormControlLabel, FormGroup, IconButton, InputAdornment, MenuItem, SvgIcon, TextField, Tooltip, Typography } from "@mui/material";
import {
    Close as CloseIcon
} from '@mui/icons-material';
import { mdiEye, mdiEyeOff } from "@mdi/js";
import clsx from "clsx";

const humanize = require('humanize-number');


const styles = theme => ({
    title: {
        color: '#624799',
        fontWeight: '600 !important',
    },
    subTitle: {
        color: 'var(--ultra-violet)',
        fontWeight: '500 !important',
    }
});

class GameOptions extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            startingPts: props.options?.startingPts ?? 8,
            private: props.options?.private ?? false,
            reveal: false,
            password: props.options?.password ?? '',
            allowSpec: props.options?.allowSpec ?? true,
            allowChat: props.options?.allowChat ?? true,
            paired: props.options?.paired ?? false,
            wIndicator: props.options?.wIndicator ?? false,
            wColor: props.options?.wColor ?? false,
        }

        this.startingPtsArr = [2, 4, 8, 16, 32];
    }

    componentDidMount() {
    }

    componentDidUpdate(prevProps) {
        if (prevProps.open !== this.props.open && this.props.open) {
            this.setState({
                startingPts: this.props.options?.startingPts ?? 8,
                private: this.props.options?.private ?? false,
                reveal: false,
                password: this.props.options?.password ?? '',
                allowSpec: this.props.options?.allowSpec ?? true,
                allowChat: this.props.options?.allowChat ?? true,
                paired: this.props.options?.paired ?? false,
                wIndicator: this.props.options?.wIndicator ?? false,
                wColor: this.props.options?.wColor ?? false,
            });
        }
    }

    getOpts = () => ({
        startingPts: this.state.startingPts,
        private: this.state.private,
        password: this.state.password,
        allowSpec: this.state.allowSpec,
        allowChat: this.state.allowChat,
        paired: this.state.paired,
        wIndicator: this.state.wIndicator,
        wColor: this.state.wColor,
    });

    doSave = (opts) => {
        if (this.props.options) {
            this.props.onEdit(opts);
        } else {
            this.props.onCreate(opts);
        }
    }

    render() {
        const { classes, options, disabled, created } = this.props;

        return (
            <Dialog
                maxWidth={"xs"}
                fullWidth
                open={Boolean(this.props.open)}
            >
                <DialogTitle>
                    <Box padding={0} display={"flex"} justifyContent={"space-between"}>
                        <Typography className={clsx(classes.title, "!text-xs sm:!text-sm md:!text-base")}>
                            {options ? 'MASA AYARLARI' : 'YENİ MASA'}
                        </Typography>

                        <Tooltip title="Kapat" placement="top">
                            <IconButton size="small" onClick={this.props.onClose} sx={{ backgroundColor: '#624799 !important' }}>
                                <CloseIcon htmlColor="var(--white)" fontSize="small" />
                            </IconButton>
                        </Tooltip>
                    </Box>
                </DialogTitle>

                <DialogContent sx={{ px: 0 }}>

                    <Box py={2} display={"flex"} flexDirection={"column"} rowGap={2}>

                        <Box display={"flex"} flexDirection={"column"} rowGap={2} px={4}>
                            <Typography className={clsx(classes.subTitle, "!text-sm sm:!text-base md:!text-lg")}>Masa Ayarları</Typography>

                            <Box display={"flex"} flexDirection={"column"}>
                                <FormGroup>
                                    <FormControlLabel disabled={!!disabled} control={
                                        <Checkbox checked={this.state.private}
                                            onChange={e => this.setState({ private: e.target.checked, reveal: false, password: '' })}
                                            sx={{ '& .MuiSvgIcon-root': { color: '#32BEA6', fill: '#32BEA6' } }}
                                        />
                                    } label={
                                        <Typography className="!text-xs sm:!text-sm md:!text-base">
                                            Özel Masa
                                        </Typography>
                                    }
                                    />
                                </FormGroup>

                                <TextField type={this.state.reveal ? "text" : "password"} label={
                                    <Typography className="!text-xs sm:!text-sm md:!text-base" alignSelf={"center"}>
                                        Parola
                                    </Typography>
                                } size="small" disabled={!!disabled}
                                    margin="dense" fullWidth sx={{ display: this.state.private ? null : 'none' }}
                                    value={this.state.password} onChange={e => this.setState({ password: e.target.value })}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton style={{ width: 24, height: 24, padding: 8 }}
                                                    onClick={() => this.setState({ reveal: !this.state.reveal })}>
                                                    <Tooltip title={this.state.reveal ? 'Parolayı gizle' : 'Parolayı göster'}
                                                        placement='top'
                                                    >
                                                        <SvgIcon fontSize='small'>
                                                            <path d={this.state.reveal ? mdiEyeOff : mdiEye} />
                                                        </SvgIcon>
                                                    </Tooltip>
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                        classes: {
                                            input: "!text-xs sm:!text-sm md:!text-base",
                                        }
                                    }}
                                />
                            </Box>

                            <TextField select margin="dense" size="small" label={
                                "Başlangıç Puanı"
                            }
                                InputProps={{
                                    className: "!text-xs sm:!text-sm md:!text-base",
                                }}
                                InputLabelProps={{
                                    className: "!text-xs sm:!text-sm md:!text-base"
                                }}
                                fullWidth disabled={!!disabled}
                                value={this.state.startingPts} onChange={e => this.setState({ startingPts: e.target.value })}>
                                {this.startingPtsArr.map(p => (
                                    <MenuItem key={p} value={p}>
                                        <Typography className="!text-xs sm:!text-sm md:!text-base">
                                            {p}
                                        </Typography>
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Box>

                        <Divider />

                        <Box display={"flex"} flexDirection={"column"} rowGap={2} px={4}>
                            <Typography className={clsx(classes.subTitle, "!text-xs sm:!text-sm md:!text-base")}>Diğer Ayarlar</Typography>
                            <FormGroup>
                                <FormControlLabel disabled={!!disabled} control={
                                    <Checkbox checked={this.state.allowChat}
                                        onChange={e => this.setState({ allowChat: e.target.checked })}
                                        sx={{ '& .MuiSvgIcon-root': { color: '#32BEA6', fill: '#32BEA6' } }}
                                    />
                                } label={
                                    <Typography className="!text-xs sm:!text-sm md:!text-base">
                                        Sohbete izin ver
                                    </Typography>
                                }
                                />

                                <FormControlLabel disabled={!!disabled} control={
                                    <Checkbox checked={this.state.allowSpec}
                                        onChange={e => this.setState({ allowSpec: e.target.checked })}
                                        sx={{ '& .MuiSvgIcon-root': { color: '#32BEA6', fill: '#32BEA6' } }}
                                    />
                                } label={
                                    <Typography className="!text-xs sm:!text-sm md:!text-base">
                                        İzleyiciye izin ver
                                    </Typography>
                                }
                                />

                                <FormControlLabel disabled={!!disabled} control={
                                    <Checkbox checked={this.state.paired}
                                        onChange={e => this.setState({ paired: e.target.checked })}
                                        sx={{ '& .MuiSvgIcon-root': { color: '#32BEA6', fill: '#32BEA6' } }}
                                    />
                                } label={
                                    <Typography className="!text-xs sm:!text-sm md:!text-base">
                                        Eşli
                                    </Typography>
                                }
                                />

                                <FormControlLabel disabled={!!disabled} control={
                                    <Checkbox checked={this.state.wIndicator}
                                        onChange={e => this.setState({ wIndicator: e.target.checked })}
                                        sx={{ '& .MuiSvgIcon-root': { color: '#32BEA6', fill: '#32BEA6' } }}
                                    />
                                } label={
                                    <Typography className="!text-xs sm:!text-sm md:!text-base">
                                        Göstergeli
                                    </Typography>
                                }
                                />

                                <FormControlLabel disabled={!!disabled} control={
                                    <Checkbox checked={this.state.wColor}
                                        onChange={e => this.setState({ wColor: e.target.checked })}
                                        sx={{ '& .MuiSvgIcon-root': { color: '#32BEA6', fill: '#32BEA6' } }}
                                    />
                                } label={
                                    <Typography className="!text-xs sm:!text-sm md:!text-base">
                                        Renkli
                                    </Typography>
                                }
                                />
                            </FormGroup>
                        </Box>
                    </Box>

                    <Divider />

                </DialogContent>

                <DialogActions sx={{ p: 2 }}>
                    <Button variant="contained" disabled={false} onClick={() => this.props.onClose()}
                        disableFocusRipple disableRipple disableTouchRipple
                        sx={{
                            color: '#FFFFFF', backgroundColor: '#939393 !important',
                        }}
                        className="!text-xs sm:!text-sm md:!text-base"
                    >
                        Vazgeç
                    </Button>

                    <Button variant="contained" disabled={!!disabled} onClick={() => this.doSave(this.getOpts())}
                        disableFocusRipple disableRipple disableTouchRipple
                        sx={{
                            color: '#FFFFFF !important', backgroundColor: disabled ? '#939393 !important' : null,
                            backgroundImage: disabled ? null : "linear-gradient(to bottom, #2FB16B 50%, #168549)"
                        }}
                        className="!text-xs sm:!text-sm md:!text-base"
                    >
                        Tamam
                    </Button>
                </DialogActions>

            </Dialog>
        );
    }
}

GameOptions.propTypes = {
    classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
    return {
    }
};

export default withStyles(styles)(connect(mapStateToProps, {
})(GameOptions));
