export const getPath = (path) => {
    const publicURL = window.__PUBLIC_URL__ || "";
    if (publicURL === "/" || publicURL === "") {
        return path;
    }

    const u = new URL(publicURL);
    if (u.pathname === "/") {
        return path;
    }

    return u.pathname + path;
}