export const ERROR_CODES = {
    1: "Veritabanı hatası",
    2: "Bir hata oluştu",
    3: "Yetkisiz erişim",
    4: "Depolama hatası",
    5: "RPC hatası",
    6: "Sunucu bakım modunda",

    101: "Geçersiz e-posta",
    102: "Geçersiz parametreler",
    103: "Geçersiz token",
    104: "Geçersiz kullanıcı adı veya şifre",
    105: "Geçersiz captcha",
    106: "Geçersiz session",
    107: "Geçersiz istek",
    108: "Geçersiz kullanıcı adı",

    201: "İstek limiti aşıldı",
    202: "Kullanıcı mevcut",
    203: "Kullanıcı bulunamadı",
    204: "Kullanıcı bloke edilmemiş",
    205: "Arkadaşlık isteği bulunamadı",
    206: "Banlandınız",
    207: "İstek bulunamadı",
    208: "Avatar değiştirme isteği mevcut",

    209: "Nick değiştirme isteği mevcut",
    210: "Kendini rapor edemezsin",
    211: "Kullanıcı zaten rapor edilmiş",
    212: "Rapor kapatılmış",
    213: "Rapor zaten bu durumda",
    214: "Oda zaten bakım modunda",
    215: "Oda zaten bakım modunda değil",
    216: "Kayıt isteği henüz onaylanmamış",
    217: "Hakkında değiştirme isteği mevcut",
};