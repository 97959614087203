import React from "react";
import { connect } from "react-redux";
import { withStyles } from "@mui/styles";
import PropTypes from "prop-types";
import { Box, Divider, Typography } from "@mui/material";
import replaceStringWithEmoji from "../../global/emoji";
import clsx from "clsx";
import dayjs from "dayjs";
import moment from "moment";
import { countries } from "../../global/countries";
import { ROLES, ROLE_COLORS, roles } from "../../global/role";

const humanize = require('humanize-number');

const styles = theme => ({
    subText: {
        fontWeight: '400 !important',
        color: 'var(--white)',
    },
});

class ProfileDetails extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        }

    }

    componentDidMount() {
    }

    componentDidUpdate(prevProps) {
    }

    profileDetails = () => {
        const { classes, usersById, user } = this.props;

        const isOnline = user?.id in usersById || !user?.id;
        const win = user?.win ?? 0;
        const lose = user?.lose ?? 0;
        const abandon = user?.abandon ?? 0;
        const total = win + lose + abandon;
        const rate = total > 0 ? parseInt(100 * win / total) : 0;
        const premium = user ? (user.premium && (!user.premium_until || user.premium_until * 1000 > Date.now())) : false;
        const description = Boolean(user?.description) ? user?.description : '';

        return (
            <>
                {/** Description */}
                {Boolean(description) ?
                    (
                        <Box display={Boolean(description) ? 'block' : 'none'}>
                            <Box mt={2} display={Boolean(description) ? 'block' : 'none'}>
                                <Typography className={clsx(classes.subText, "!text-xs lg:!text-sm w-28")} sx={{ color: '#A3A3A3' }}>HAKKINDA</Typography>
                            </Box>

                            <Box my={1}>
                                <Divider />
                            </Box>

                            <Box>
                                <Typography className={clsx(classes.subText, "!text-center !text-xs md:!text-sm lg:!text-base")} sx={{ color: '#413067' }}>
                                    {replaceStringWithEmoji(description)}
                                </Typography>
                            </Box>
                            <Box mt={1} mb={2}>
                                <Divider />
                            </Box>
                        </Box>) :
                    (
                        <Box my={2}>
                            <Divider />
                        </Box>
                    )}


                {/** Details */}
                <Box display={"flex"} flexDirection={"column"} rowGap={2}>
                    <Box display={"flex"}>
                        <Typography className={clsx(classes.subText, "!text-xs lg:!text-sm w-28")} sx={{ color: '#A3A3A3' }}>PUANI</Typography>
                        <Typography className={clsx(classes.subText, "!text-xs lg:!text-sm")} sx={{ color: '#413067' }}>
                            {humanize(user?.points ?? 0, { delimiter: '.', separator: ',' })}
                        </Typography>
                    </Box>
                    <Box display={"flex"}>
                        <Typography className={clsx(classes.subText, "!text-xs lg:!text-sm w-28")} sx={{ color: '#A3A3A3' }}>CİNSİYET</Typography>
                        <Typography className={clsx(classes.subText, "!text-xs lg:!text-sm")}
                            sx={{
                                color: user?.gender === 'male' ? '#39ccff' : '#ff79fc'
                            }}
                        >
                            {Boolean(user) ? (user.gender === 'male' ? 'ERKEK' : 'BAYAN') : 'ROBOT'}
                        </Typography>
                    </Box>
                    <Box display={"flex"}>
                        <Typography className={clsx(classes.subText, "!text-xs lg:!text-sm w-28")} sx={{ color: '#A3A3A3' }}>ÜYELİK TARİHİ</Typography>
                        <Typography className={clsx(classes.subText, "!text-xs lg:!text-sm")} sx={{ color: '#413067', textTransform: 'uppercase' }}>
                            {new Date(Date.parse(user?.created_at ?? 0)).toLocaleString('tr-TR', { day: 'numeric', month: 'long', year: 'numeric' })}
                        </Typography>
                    </Box>
                    <Box display={"flex"}>
                        <Typography className={clsx(classes.subText, "!text-xs lg:!text-sm w-28")} sx={{ color: '#A3A3A3' }}>ÜLKE</Typography>
                        <Typography className={clsx(classes.subText, "!text-xs lg:!text-sm")} sx={{ color: '#413067', textTransform: 'uppercase' }}>
                            {Boolean(user) ? (user.country in countries ? countries[user.country] : user.country) : 'Türkiye'}
                        </Typography>
                    </Box>
                    {user?.ip && (<Box display={"flex"}>
                        <Typography className={clsx(classes.subText, "!text-xs lg:!text-sm w-28")} sx={{ color: '#A3A3A3' }}>IP</Typography>
                        <Typography className={clsx(classes.subText, "!text-xs lg:!text-sm")} sx={{ color: '#413067' }}>
                            {user.ip}
                        </Typography>
                    </Box>
                    )}
                    <Box display={"flex"}>
                        <Typography className={clsx(classes.subText, "!text-xs lg:!text-sm w-28")} sx={{ color: '#A3A3A3' }}>SON GİRİŞ</Typography>
                        <Typography className={clsx(classes.subText, "!text-xs lg:!text-sm")} sx={{ color: '#413067' }}>
                            {isOnline ? 'ÇEVRİMİÇİ' : dayjs(user?.last_login).format('DD.MM.YYYY HH:mm')}
                        </Typography>
                    </Box>
                    <Box display={"flex"}>
                        <Typography className={clsx(classes.subText, "!text-xs lg:!text-sm w-28")} sx={{ color: '#A3A3A3' }}>TOPLAM SÜRE</Typography>
                        <Typography className={clsx(classes.subText, "!text-xs lg:!text-sm")} sx={{ color: '#413067' }}>
                            {moment.duration(user?.total_online_time ?? 0, "second").asHours().toFixed(0)} SAAT
                        </Typography>
                    </Box>
                    <Box display={"flex"}>
                        <Typography className={clsx(classes.subText, "!text-xs lg:!text-sm w-28")} sx={{ color: '#A3A3A3' }}>YETKİ</Typography>
                        <Typography className={clsx(classes.subText, "!text-xs lg:!text-sm")}
                            sx={{
                                color: user?.role === roles.Player && premium ? 'goldenrod' : (ROLE_COLORS[user?.role ?? 0]),
                                textTransform: 'uppercase'
                            }}
                        >
                            {user?.role === roles.Player && premium ? 'Gold Üye' : (ROLES[user?.role ?? 0] ?? 'Oyuncu')}
                        </Typography>
                    </Box>
                </Box>

                <Box my={2}>
                    <Divider />
                </Box>

                {/** Statistics */}
                <Box display={"flex"} flexDirection={"column"} rowGap={2}>
                    <Box display={"flex"}>
                        <Typography className={clsx(classes.subText, "!text-xs lg:!text-sm w-28")} sx={{ color: '#A3A3A3' }}>KAZANILAN</Typography>
                        <Typography className={clsx(classes.subText, "!text-xs lg:!text-sm")} sx={{ color: '#413067' }}>{win}</Typography>
                    </Box>
                    <Box display={"flex"}>
                        <Typography className={clsx(classes.subText, "!text-xs lg:!text-sm w-28")} sx={{ color: '#A3A3A3' }}>KAYBEDİLEN</Typography>
                        <Typography className={clsx(classes.subText, "!text-xs lg:!text-sm")} sx={{ color: '#413067' }}>{lose}</Typography>
                    </Box>
                    <Box display={"flex"}>
                        <Typography className={clsx(classes.subText, "!text-xs lg:!text-sm w-28")} sx={{ color: '#A3A3A3' }}>KAÇIŞLAR</Typography>
                        <Typography className={clsx(classes.subText, "!text-xs lg:!text-sm")} sx={{ color: '#413067' }}>{abandon}</Typography>
                    </Box>
                    <Box display={"flex"}>
                        <Typography className={clsx(classes.subText, "!text-xs lg:!text-sm w-28")} sx={{ color: '#A3A3A3' }}>KAZ. YÜZDESİ</Typography>
                        <Typography className={clsx(classes.subText, "!text-xs lg:!text-sm")} sx={{ color: '#413067' }}>%{rate}</Typography>
                    </Box>
                </Box>
            </>
        );
    }

    render() {
        return this.profileDetails();
    }
}

ProfileDetails.propTypes = {
    classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
    return {
        usersById: state.app.lobby.usersById,
    }
};

export default withStyles(styles)(connect(mapStateToProps, {
})(ProfileDetails));
