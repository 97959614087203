import axios from "axios";
import { setStreamInfo } from "../actions/global";

function startJobs(dispatch, getState) {
    doFetchStreamInfo(dispatch, getState);
}

function doFetchStreamInfo(dispatch, getState) {
    fetchStreamInfo(dispatch, getState);
    setInterval(() => {
        fetchStreamInfo(dispatch, getState);
    }, 10000);
}

async function fetchStreamInfo(dispatch, getState) {
    try {
        const { radio_url } = getState().app.global.roomConfig;
        if (!radio_url) {
            return;
        }

        const url = new URL(radio_url);
        const port = url.port || url.pathname.split('/')[1];
        const resp = await axios.get(`${url.protocol}//${url.hostname}/cp/get_info.php?p=${port}`);
        dispatch(setStreamInfo(resp.data));
    }
    catch (err) {
        console.log(`Fetching stream info failed: ${err}`);
    }
}

export default startJobs;