import React from "react";
import { connect } from "react-redux";
import { withStyles } from "@mui/styles";
import PropTypes from "prop-types";
import { Box } from "@mui/system";
import { IconButton, List, SvgIcon, Tooltip } from "@mui/material";
import { mdiMagnify } from "@mdi/js";
import Tile from "../../models/tile";
import clsx from "clsx";


const styles = theme => ({
});

class Pot extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dragging: false,
        }

    }

    componentDidMount() {
    }

    componentDidUpdate(prevProps) {
    }

    onDragStart = (value, index) => {
        const { onDragStart, selectedTableDouble } = this.props;
        if (selectedTableDouble) {
            this.setState({ dragging: true });
        }

        onDragStart?.(value, index);
    }

    onDragEnd = (clientX, clientY, index) => {
        const { onDragEnd, selectedTableDouble } = this.props;
        if (selectedTableDouble) {
            this.setState({ dragging: false });
        }

        onDragEnd?.(clientX, clientY, index);
    }

    render() {
        const {
            className, isLandscape, potIndex, tileIndex, onInspect, potRef,
            selectedTablePots: pots,
            selectedTablePotAt: potAt,
            selectedTableDouble: double,
            onMovePiece,
            onDoubleClick,
        } = this.props;

        const pot = pots?.[potIndex];

        return (
            <Box display={"flex"} flex={1} className={clsx(className)} ref={potRef}>
                <List component="div"
                    sx={{
                        padding: 0, bgcolor: !pot?.length ? 'lightgray' : 'transparent',
                    }}
                    className={clsx(
                        "m-0 p-0 rounded-[4px] h-fit",
                    )}
                >
                    <Box className="relative">
                        {!this.state.dragging && pot?.length > 0 && <Box position={"absolute"} left={-2} bottom={0} zIndex={4}
                            display={double ? 'block' : 'none'}
                        >
                            <Tooltip title="Taşlara göz at" placement="top">
                                <IconButton sx={{ padding: 0 }} disableFocusRipple disableRipple disableTouchRipple
                                    onClick={(e) => onInspect?.(e, pot)} disabled={!pot?.length}
                                >
                                    <SvgIcon htmlColor="#666"><path d={mdiMagnify} /></SvgIcon>
                                </IconButton>
                            </Tooltip>
                        </Box>
                        }


                        {pot && <Tile
                            isLandscape={isLandscape}
                            value={pot[pot.length - 1]}
                            key={`pot_${potIndex}_${potAt}`}
                            index={tileIndex}
                            onDragStart={this.onDragStart}
                            onDragEnd={this.onDragEnd}
                            onMovePiece={onMovePiece}
                            onDoubleClick={onDoubleClick}
                        />}
                    </Box>
                </List>
            </Box>
        );
    }
}

Pot.propTypes = {
    classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
    const selectedTable = state.app.global.selectedTable;

    return {
        selectedTablePots: state.app.table.tables[selectedTable]?.pots,
        selectedTablePotAt: state.app.table.tables[selectedTable]?.potAt,
        selectedTableDouble: state.app.table.tables[selectedTable]?.double,
    }
};

export default withStyles(styles)(connect(mapStateToProps, {
})(Pot));
