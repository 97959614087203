import React from "react";
import { Box, Typography } from "@mui/material";
import { connect } from "react-redux";
import { withStyles } from "@mui/styles";

const styles = (theme) => ({});

class ContentPiece extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    render() {
        const { titles, img, description } = this.props;

        return (
            <Box sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                gap: "20px",
            }}>
                <img src={img} alt="content" />
                <Typography sx={{ textAlign: "center", fontSize: "40px", fontWeight: "900", lineHeight: ".1", letterSpacing: "-0.02rem", color: "#1E2C5F" }}>
                    {titles[0]}
                </Typography>
                <Typography sx={{ textAlign: "center", fontSize: "40px", fontWeight: "900", lineHeight: "1", letterSpacing: "-0.02rem", color: "#FF8159" }}>
                    {titles[1]}
                </Typography>
                <Typography variant="h2" component="h2" sx={{ textAlign: "center", fontSize: "13px", fontWeight: 500, letterSpacing: "-0.02rem", color: "#6F7795" }}>
                    {description}
                </Typography>
            </Box>
        )
    }
}

const mapStateToProps = (state) => {
    return {
    }
};

export default withStyles(styles)(connect(mapStateToProps, {})(ContentPiece));