import { applyMiddleware, compose, createStore } from "redux";
import promise from 'redux-promise';
import thunk from 'redux-thunk';
import createRootReducer from "../reducers/index";
import { createLogger } from 'redux-logger';
import { routerMiddleware } from "react-router-redux";
const createHistory = require("history").createBrowserHistory;

const history = createHistory({ basename: "/" });
const routeMiddleware = routerMiddleware(history);
const loggerMiddleware = createLogger({
    predicate: () => process.env.NODE_ENV === 'development',
});

const middlewares = [thunk, promise, routeMiddleware, loggerMiddleware];
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export default function configureStore(initialState) {
    const store = createStore(createRootReducer(history), initialState,
        composeEnhancers(applyMiddleware(...middlewares)));

    //sagaMiddleware.run(rootSaga);

    if (module.hot) {
        // Enable Webpack hot module replacement for reducers
        module.hot.accept('../reducers/index', () => {
            const nextRootReducer = require('../reducers/index');
            store.replaceReducer(nextRootReducer);
        });
    }
    return store;
}

export { history };
