import React from "react";
import { connect } from "react-redux";
import { withStyles } from "@mui/styles";
import PropTypes from "prop-types";
import { Box, Button, Card, CardContent, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, FormControlLabel, IconButton, InputAdornment, Pagination, Radio, RadioGroup, SvgIcon, Table, TableBody, TableCell, TableHead, TableRow, TextField, Tooltip, Typography } from "@mui/material";
import { mdiMagnify, mdiPlusCircle } from "@mdi/js";
import debounce from "lodash.debounce";
import { setNotification } from "../../../actions/global";
import { GlobalTypes } from "../../../action_types";
import axios from "axios";
import { Delete, Edit } from "@mui/icons-material";
import EditPremiumUser from "./EditPremiumUser";
import clsx from "clsx";
import { AxiosClient } from "../../../global/axios";


const styles = theme => ({
    table: {
        border: '2px solid #f0f0f0',
        userSelect: 'text !important',
    },
    tCell: {
        border: 0,
        padding: 32,
        verticalAlign: 'top',
    },
    cellHeader: {
        fontFamily: 'Roboto Slab',
        width: 180,
        fontWeight: 700,
    },
    cellContent: {
        color: '#6b7280 !important',
        fontFamily: 'Roboto Slab !important',
    },
    text: {
        fontWeight: '400 !important',
        color: 'var(--white)',
    },
    inputIcon: {
        marginRight: '8px',
        color: '#31204A !important'
    },
});

class PremiumUsers extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            search: '',
            page: 1,
            size: 10,
            premiumUsers: [],
            totalPremiumUsers: 0,
            deletePremiumUser: null,
            edit: false,
            editPremiumUser: null,
        }

    }

    componentDidMount() {
        this.listPremiumUsers();
    }

    componentDidUpdate(prevProps) {
    }

    handleDeletePremiumUser = (premiumUser) => {
        this.setState({ deletePremiumUser: premiumUser });
    }

    listPremiumUsers = async () => {
        try {
            const { search, page, size, filter } = this.state;
            const resp = await AxiosClient().get(`${process.env.REACT_APP_API_URL}/v1/admin/premium?search=${search}&page=${page}&size=${size}`);

            this.setState({ premiumUsers: resp.data.data ?? [], totalPremiumUsers: resp.data.total });

        } catch (err) {
            console.error(err);
            this.props.setNotification({ message: 'Bir hata oluştu. Lütfen tekrar deneyiniz.', severity: GlobalTypes.NOTIFY_ERR });
        }
    }

    debounceSearchPremium = debounce(() => this.setState({ page: 1 }, () => this.listPremiumUsers()), 500);

    searchChanged = (e) => {
        this.setState({ search: e.target.value });
        this.debounceSearchPremium();
    }

    deletePremiumUser = async () => {
        try {
            const { deletePremiumUser } = this.state;

            if (!deletePremiumUser) return;

            await AxiosClient().delete(`${process.env.REACT_APP_API_URL}/v1/admin/premium?id=${deletePremiumUser.id}`);

            this.props.setNotification({ message: 'Premium kullanıcı başarıyla silindi.', severity: GlobalTypes.NOTIFY_SUCC });

        } catch (err) {
            console.error(err);
            this.props.setNotification({ message: 'Bir hata oluştu. Lütfen tekrar deneyiniz.', severity: GlobalTypes.NOTIFY_ERR });

        } finally {
            this.setState({ deletePremiumUser: null }, () => this.listPremiumUsers());
        }
    }

    onAddPremiumUser = () => {
        this.setState({ edit: true, editPremiumUser: null });
    }

    handleEditPremiumUser = (premiumUser) => {
        this.setState({ edit: true, editPremiumUser: premiumUser });
    }

    // UI PART

    deletePremiumUserDialog = () => {
        const { deletePremiumUser } = this.state;

        const onClose = () => this.setState({ deletePremiumUser: null });

        return (
            <Dialog open={deletePremiumUser !== null} onClose={onClose}>
                <DialogTitle>Premium Kullanıcı Kaldır</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Premium kullanıcıyı kaldırmak istediğinize emin misiniz?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onClose} variant="contained" color="error">
                        İptal
                    </Button>
                    <Button onClick={() => this.deletePremiumUser()} variant="contained" color="primary" autoFocus className="!text-white">
                        Kaldır
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }

    tableView = () => {
        const { classes } = this.props;
        const { premiumUsers } = this.state;

        if (premiumUsers.length === 0) {
            return (
                <Box className="!hidden lg:!flex justify-center items-center h-[300px]">
                    <Typography variant="h6" className={classes.title}>Sonuç bulunamadı.</Typography>
                </Box>
            );
        }

        return (
            <Table className={clsx(
                classes.table,
                "!hidden lg:!table",
            )}>
                <TableHead>
                    <TableRow>
                        <TableCell className={classes.tCell} sx={{ borderBottom: '2px solid #f0f0f0', minWidth: 0, maxWidth: 0 }}>
                            <Typography className={clsx(classes.cellHeader, "!text-xs md:!text-sm lg:!text-base")}>
                                Nick
                            </Typography>
                        </TableCell>
                        <TableCell className={classes.tCell} sx={{ borderBottom: '2px solid #f0f0f0', minWidth: 0, maxWidth: 0 }}>
                            <Typography className={clsx(classes.cellHeader, "!text-xs md:!text-sm lg:!text-base")}>
                                Ekleyen
                            </Typography>
                        </TableCell>
                        <TableCell className={classes.tCell} sx={{ borderBottom: '2px solid #f0f0f0', minWidth: 0, maxWidth: 0 }}>
                            <Typography className={clsx(classes.cellHeader, "!text-xs md:!text-sm lg:!text-base")}>
                                Eklenen Tarih
                            </Typography>
                        </TableCell>
                        <TableCell className={classes.tCell} sx={{ borderBottom: '2px solid #f0f0f0', minWidth: 0, maxWidth: 0 }}>
                            <Typography className={clsx(classes.cellHeader, "!text-xs md:!text-sm lg:!text-base")}>
                                Bitiş Tarihi
                            </Typography>
                        </TableCell>
                        <TableCell className={classes.tCell} sx={{ borderBottom: '2px solid #f0f0f0', minWidth: 0, maxWidth: 0 }}>
                            <Typography className={clsx(classes.cellHeader, "!text-xs md:!text-sm lg:!text-base")}>
                                Not
                            </Typography>
                        </TableCell>
                        <TableCell className={classes.tCell} sx={{ borderBottom: '2px solid #f0f0f0', minWidth: 0, maxWidth: 0 }}>
                            <Typography className={clsx(classes.cellHeader, "!text-xs md:!text-sm lg:!text-base")}>
                            </Typography>
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {premiumUsers.map((premiumUser) => {
                        return (
                            <TableRow key={premiumUser.id}>
                                <TableCell>
                                    <Typography className={clsx(classes.cellContent, "!text-xs md:!text-sm lg:!text-base")}>
                                        {premiumUser.username}
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography className={clsx(classes.cellContent, "!text-xs md:!text-sm lg:!text-base")}>
                                        {premiumUser.created_by}
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography className={clsx(classes.cellContent, "!text-xs md:!text-sm lg:!text-base")}>
                                        {new Date(premiumUser.created_at).toLocaleDateString()}
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography className={clsx(classes.cellContent, "!text-xs md:!text-sm lg:!text-base")}>
                                        {premiumUser.valid_until ? new Date(premiumUser.valid_until).toLocaleDateString() : 'Süresiz'}
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography className={clsx(classes.cellContent, "!text-xs md:!text-sm lg:!text-base")}>
                                        {premiumUser.description ?? '-'}
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Tooltip title="Düzenle" placement="top">
                                        <IconButton onClick={() => this.handleEditPremiumUser(premiumUser)}>
                                            <Edit fontSize="small" />
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip title="Sil" placement="top">
                                        <IconButton onClick={() => this.handleDeletePremiumUser(premiumUser)}>
                                            <Delete fontSize="small" />
                                        </IconButton>
                                    </Tooltip>
                                </TableCell>
                            </TableRow>
                        );
                    })}

                </TableBody>
            </Table>
        );
    }

    mobileView = () => {
        const { classes } = this.props;
        const { premiumUsers } = this.state;

        if (premiumUsers.length === 0) {
            return (
                <Box className="flex lg:hidden justify-center items-center">
                    <Typography variant="h6" className={classes.title}>Sonuç bulunamadı.</Typography>
                </Box>
            );
        }

        return (
            <Box className="flex flex-col gap-y-2 lg:hidden">
                {premiumUsers.map((premiumUser) => {
                    return (
                        <Card sx={{ borderRadius: 4, border: '1px solid #c4c4c4', boxShadow: 0 }}>
                            <CardContent>
                                <Table>
                                    <TableRow>
                                        <TableCell className="!p-2">
                                            <Typography className={clsx(classes.cellContent, "!text-xs md:!text-sm lg:!text-base")}>
                                                Nick
                                            </Typography>
                                        </TableCell>
                                        <TableCell className="!p-2">
                                            <Typography className={clsx(classes.cellContent, "!text-xs md:!text-sm lg:!text-base")}>
                                                {premiumUser.username}
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell className="!p-2">
                                            <Typography className={clsx(classes.cellContent, "!text-xs md:!text-sm lg:!text-base")}>
                                                Ekleyen
                                            </Typography>
                                        </TableCell>
                                        <TableCell className="!p-2">
                                            <Typography className={clsx(classes.cellContent, "!text-xs md:!text-sm lg:!text-base")}>
                                                {premiumUser.created_by}
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell className="!p-2">
                                            <Typography className={clsx(classes.cellContent, "!text-xs md:!text-sm lg:!text-base")}>
                                                Eklenen Tarih
                                            </Typography>
                                        </TableCell>
                                        <TableCell className="!p-2">
                                            <Typography className={clsx(classes.cellContent, "!text-xs md:!text-sm lg:!text-base")}>
                                                {new Date(premiumUser.created_at).toLocaleDateString()}
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell className="!p-2">
                                            <Typography className={clsx(classes.cellContent, "!text-xs md:!text-sm lg:!text-base")}>
                                                Bitiş Tarihi
                                            </Typography>
                                        </TableCell>
                                        <TableCell className="!p-2">
                                            <Typography className={clsx(classes.cellContent, "!text-xs md:!text-sm lg:!text-base")}>
                                                {premiumUser.valid_until ? new Date(premiumUser.valid_until).toLocaleDateString() : 'Süresiz'}
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell className="!p-2">
                                            <Typography className={clsx(classes.cellContent, "!text-xs md:!text-sm lg:!text-base")}>
                                                Not
                                            </Typography>
                                        </TableCell>
                                        <TableCell className="!p-2">
                                            <Typography className={clsx(classes.cellContent, "!text-xs md:!text-sm lg:!text-base")}>
                                                {premiumUser.description ?? '-'}
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell className="!p-2">
                                            <Typography className={clsx(classes.cellContent, "!text-xs md:!text-sm lg:!text-base")}>
                                                Aksiyon
                                            </Typography>
                                        </TableCell>
                                        <TableCell className="!p-2">
                                            <Tooltip title="Düzenle" placement="top">
                                                <IconButton className="!p-[2px] !mr-2" onClick={() => this.handleEditPremiumUser(premiumUser)}>
                                                    <Edit className="!text-[16px]" />
                                                </IconButton>
                                            </Tooltip>
                                            <Tooltip title="Sil" placement="top">
                                                <IconButton className="!p-[2px] !mr-2" onClick={() => this.handleDeletePremiumUser(premiumUser)}>
                                                    <Delete className="!text-[16px]" />
                                                </IconButton>
                                            </Tooltip>
                                        </TableCell>
                                    </TableRow>
                                </Table>
                            </CardContent>
                        </Card>
                    );
                })}
            </Box>
        );
    }


    render() {
        const { classes } = this.props;
        const { premiumUsers, totalPremiumUsers } = this.state;

        return (
            <Box margin={1} display={"flex"} flexDirection={"column"} rowGap={1}>

                <Box className="flex gap-x-2 justify-between items-center">
                    <div />
                    <Button variant="contained" sx={{ backgroundImage: "linear-gradient(to bottom, #2FB16B 50%, #168549)" }}
                        onClick={this.onAddPremiumUser}
                        className="h-9"
                    >
                        <Box display="flex" columnGap="8px">
                            <SvgIcon fontSize="small" sx={{ margin: 'auto' }}><path d={mdiPlusCircle} color="var(--white)" /></SvgIcon>
                            <Typography className={clsx(classes.text, "!text-xs md:!text-sm lg:!text-base")} alignSelf={"center"}>Ekle</Typography>
                        </Box>
                    </Button>
                </Box>

                <TextField variant="outlined" placeholder="Kişi Ara" fullWidth margin="dense"
                    InputProps={{
                        classes: { root: "p-1 md:p-2" },
                        startAdornment: (
                            <InputAdornment position="start">
                                <SvgIcon fontSize='small'>
                                    <path d={mdiMagnify} />
                                </SvgIcon>
                            </InputAdornment>
                        )
                    }}
                    value={this.state.search}
                    onChange={this.searchChanged}
                    sx={{
                        '& .MuiInputBase-root': { p: 0, px: 2 },
                        '& .MuiInputBase-root::before': { borderBottom: '1px solid #E3E3E3 !important' },
                        '& .MuiInputBase-root::after': { borderBottom: '1px solid #E3E3E3 !important' },
                    }}
                />

                {this.tableView()}
                {this.mobileView()}

                <Box className="flex justify-end">
                    <Pagination count={parseInt(Math.ceil(totalPremiumUsers / this.state.size))} page={this.state.page}
                        variant="outlined" shape="rounded" color="primary"
                        onChange={(e, p) => this.setState({ page: p }, () => this.listPremiumUsers())}
                    />
                </Box>

                <EditPremiumUser open={this.state.edit} premiumUser={this.state.editPremiumUser}
                    onClose={(refresh) => this.setState({ edit: false, editPremiumUser: null }, () => refresh && this.listPremiumUsers())}
                />

                {this.deletePremiumUserDialog()}
            </Box>
        );
    }
}

PremiumUsers.propTypes = {
    classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
    return {
    }
};

export default withStyles(styles)(connect(mapStateToProps, {
    setNotification,
})(PremiumUsers));
