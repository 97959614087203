import React from "react";
import { connect } from "react-redux";
import { withStyles } from "@mui/styles";
import PropTypes from "prop-types";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, MenuItem, SvgIcon, Table, TableCell, TableRow, TextField, Tooltip, Typography } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { Add, AllInclusive, Remove } from "@mui/icons-material";
import dayjs from "dayjs";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import clsx from "clsx";
import axios from "axios";
import { GlobalTypes } from "../../../action_types";
import { setNotification } from "../../../actions/global";
import { icons } from "./icons";
import { AxiosClient } from "../../../global/axios";
import { getPath } from "../../../global/route";
import { loadedResources } from "../../../global/resources";


const styles = theme => ({
});

const MAX_TABLE_ID = 10_000;

class EditUserTable extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            username: props.userTable?.username || '',
            date: props.userTable?.until ? dayjs(props.userTable.until) : dayjs(new Date()).add(1, 'day'),
            tableId: props.userTable?.table_id || 0,
            icon: props.userTable?.icon || icons[0].img,
            saving: false,
        }

    }

    componentDidMount() {
    }

    componentDidUpdate(prevProps) {
        if (this.props.userTable && !prevProps.userTable) {
            this.setState({
                username: this.props.userTable.username,
                date: this.props.userTable.until ? dayjs(this.props.userTable.until) : null,
                tableId: this.props.userTable.table_id,
                icon: this.props.userTable.icon ?? icons[0].img,
            });
        }

        if (!this.props.userTable && prevProps.userTable) {
            this.setState({
                username: '',
                date: dayjs(new Date()).add(1, 'day'),
                tableId: 0,
                icon: icons[0].img,
            });
        }
    }

    onSave = async () => {
        this.setState({ saving: true });

        const userTable = {
            username: this.state.username,
            until: this.state.date?.unix?.() ?? null,
            table_id: parseInt(this.state.tableId),
            icon: this.state.icon,
        };

        if (this.props.userTable) {
            userTable.id = this.props.userTable.id;
        }

        try {
            await AxiosClient().post(`${process.env.REACT_APP_API_URL}/v1/admin/users/tables`, userTable);

            this.props.setNotification({ message: `Masa ${this.props.userTable ? 'düzenlendi.' : 'eklendi.'}`, severity: GlobalTypes.NOTIFY_SUCC });
            this.props.onClose(true);

        } catch (error) {
            console.error(error);
            this.props.setNotification({ message: 'Bir hata oluştu. Lütfen tekrar deneyin.', severity: GlobalTypes.NOTIFY_ERR });
        } finally {
            this.setState({ saving: false, username: '', date: dayjs(new Date()), reason: '' });
        }
    }

    render() {
        const { classes } = this.props;
        const imgPath = `${process.env.REACT_APP_CDN_URL}/web/img`;

        return (
            <Dialog open={this.props.open} onClose={() => this.props.onClose()}>
                <DialogTitle>
                    <Typography className={classes.title} sx={{ color: '#413067' }}>
                        {`Masa ${this.props.userTable ? 'Düzenle' : 'Ekle'}`}
                    </Typography>
                </DialogTitle>
                <DialogContent>
                    <Table>
                        <TableRow>
                            <TableCell className="!border-0 !p-2 min-w-32">
                                <Typography className={classes.title} sx={{ color: '#413067' }}>
                                    Kullanıcı Adı
                                </Typography>
                            </TableCell>
                            <TableCell className="!border-0 !p-2">
                                <TextField margin="dense" fullWidth
                                    value={this.state.username}
                                    sx={{
                                        borderTopRightRadius: '0 !important',
                                        borderBottomRightRadius: '0 !important',
                                        margin: 0,
                                    }}
                                    InputProps={{
                                        classes: {
                                            input: "h-8 sm:h-12",
                                            root: "h-8 sm:h-12",
                                        }
                                    }}
                                    disabled={Boolean(this.props.userTable)}
                                    onChange={(e) => this.setState({ username: e.target.value })}
                                />
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className="!border-0 !p-2">
                                <Typography className={classes.title} sx={{ color: '#413067' }}>
                                    Tarih
                                </Typography>
                            </TableCell>
                            <TableCell className="!border-0 !p-2">
                                <Box className="flex gap-x-1 items-center">

                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DatePicker format="DD/MM/YYYY" onChange={date => this.setState({ date })} value={this.state.date}
                                            disabled={!Boolean(this.state.date)} disablePast
                                        />
                                    </LocalizationProvider>

                                    <Tooltip title="1 gün azalt" placement="top">
                                        <IconButton onClick={() => this.setState({ date: this.state.date?.subtract?.(1, 'day') ?? dayjs(new Date()) })}
                                            className="w-9 h-9"
                                        >
                                            <SvgIcon>
                                                <Remove />
                                            </SvgIcon>
                                        </IconButton>
                                    </Tooltip>

                                    <Tooltip title="1 gün arttır" placement="top">
                                        <IconButton onClick={() => this.setState({ date: this.state.date?.add?.(1, 'day') ?? dayjs(new Date()) })}
                                            className="w-9 h-9"
                                        >
                                            <SvgIcon>
                                                <Add />
                                            </SvgIcon>
                                        </IconButton>
                                    </Tooltip>

                                    <Tooltip title="Süresiz" placement="top">
                                        <IconButton onClick={() => this.setState({ date: this.state.date ? null : dayjs(new Date()) })}
                                            className={clsx(this.state.date ? null : "!bg-blue-400", "w-9 h-9")}
                                        >
                                            <SvgIcon>
                                                <AllInclusive htmlColor={this.state.date ? null : 'white'} />
                                            </SvgIcon>
                                        </IconButton>
                                    </Tooltip>
                                </Box>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className="!border-0 !p-2">
                                <Typography sx={{ color: '#413067' }}>
                                    Masa Numarası
                                </Typography>
                            </TableCell>
                            <TableCell className="!border-0 !p-2">
                                <TextField margin="dense" fullWidth
                                    value={this.state.tableId}
                                    type="number"
                                    sx={{
                                        borderTopRightRadius: '0 !important',
                                        borderBottomRightRadius: '0 !important',
                                        margin: 0,
                                    }}
                                    InputProps={{
                                        classes: {
                                            input: "h-8 sm:h-12",
                                            root: "h-8 sm:h-12",
                                        },
                                        inputProps: { min: 1, max: MAX_TABLE_ID },
                                    }}
                                    onChange={(e) => this.setState({ tableId: e.target.value })}
                                />
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className="!border-0 !p-2 !flex">
                                <Typography className={classes.title} sx={{ color: '#413067' }}>
                                    İkon
                                </Typography>
                            </TableCell>
                            <TableCell className="!border-0 !p-2">
                                <TextField margin="dense" fullWidth select
                                    className="!mb-2"
                                    value={this.state.icon}
                                    sx={{
                                        borderTopRightRadius: '0 !important',
                                        borderBottomRightRadius: '0 !important',
                                        margin: 0,
                                    }}
                                    InputProps={{
                                        classes: {
                                            input: "h-8 sm:h-12",
                                            root: "h-8 sm:h-12",
                                        },
                                    }}
                                    onChange={(e) => this.setState({ icon: e.target.value })}
                                >
                                    {icons.map((icon) => (
                                        <MenuItem key={icon.img} value={icon.img}>
                                            {icon.title}
                                        </MenuItem>
                                    ))}
                                </TextField>

                                {!this.state.icon || this.state.icon === '_' ?
                                    null :
                                    <img src={loadedResources[`${imgPath}/${this.state.icon}`]} alt={this.state.icon} className="w-8 h-8" />
                                }
                            </TableCell>
                        </TableRow>
                    </Table>
                </DialogContent>

                <DialogActions>
                    <Button onClick={() => this.props.onClose()} variant="contained" color="error">
                        İptal
                    </Button>
                    <Button onClick={this.onSave} variant="contained" color="primary"
                        className="!text-white"
                        disabled={!this.state.username || !this.state.tableId || this.state.tableId > MAX_TABLE_ID || this.state.saving}
                    >
                        Kaydet
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

EditUserTable.propTypes = {
    classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
    return {
    }
};

export default withStyles(styles)(connect(mapStateToProps, {
    setNotification,
})(EditUserTable));
