import React from "react";
import { connect } from "react-redux";
import { withStyles } from "@mui/styles";
import PropTypes from "prop-types";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Slider, Tooltip, Typography } from "@mui/material";
import {
    Close as CloseIcon
} from '@mui/icons-material';
import Cropper from 'react-easy-crop';
import axios from "axios";
import { setNotification } from "../../actions/global";
import { GlobalTypes } from "../../action_types";
import { ERROR_CODES } from "../../global/error";
import Compressor from 'compressorjs';
import clsx from "clsx";
import { AxiosClient } from "../../global/axios";
import { generateRequestId } from "../../global/session";
import { getUserImage } from "../../global/cdn";

const styles = theme => ({
    title: {
        color: '#624799',
        fontWeight: '600 !important',
    },
    text: {
        fontWeight: '400 !important',
        color: 'var(--white)',
    },
});

class UploadPhoto extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            image: null,
            profilePhoto: null,
            croppedImage: null,
            crop: { x: 0, y: 0 },
            croppedAreaPixels: { x: 0, y: 0, width: 0, height: 0 },
            zoom: 1,
            uploading: false,
        }

        this.fileInputRef = React.createRef();
    }

    componentDidMount() {
    }

    loadImage = () => {
        const { user, editUser } = this.props;

        if ((user || editUser) && (user?.profile_photo || editUser?.profile_photo)) {
            getUserImage(editUser?.id ?? user?.id, editUser?.profile_photo ?? user?.profile_photo).
                then(async (base64) => {
                    const profilePhoto = await this.createImage(base64);
                    this.setState({ profilePhoto, profileSrc: base64 });
                }).catch(e => {
                    console.error(e);
                });
        }
    }

    componentDidUpdate(prevProps) {
        if (!prevProps.open && this.props.open) {
            this.loadImage();
        }
    }

    onClose = () => {
        this.setState({ image: null, croppedImage: null, crop: { x: 0, y: 0 }, croppedAreaPixels: { x: 0, y: 0, width: 0, height: 0 }, zoom: 1 });
        this.props.onClose();
    }

    handleChange = (e) => {
        const file = e.target.files[0];
        if (!file) {
            return;
        }

        const reader = new FileReader();
        reader.onload = () => {
            this.setState({ image: reader.result });
        };
        reader.readAsDataURL(file);
    }

    onCropChange = (crop) => {
        if (this.state.crop?.x === crop?.x && this.state.crop?.y === crop?.y) {
            return;
        }

        this.setState({ crop });
    }

    onCropComplete = async (croppedArea, croppedAreaPixels) => {
        if (this.state.croppedAreaPixels?.x === croppedAreaPixels?.x && this.state.croppedAreaPixels?.y === croppedAreaPixels?.y &&
            this.state.croppedAreaPixels?.width === croppedAreaPixels?.width && this.state.croppedAreaPixels?.height === croppedAreaPixels?.height) {
            return;
        }

        try {
            let image = this.state.profilePhoto;
            if (this.state.image) {
                image = await this.createImage(this.state.image);
            }

            if (!image) {
                return;
            }

            const croppedImage = await this.getCroppedImg(
                image,
                croppedAreaPixels,
                //rotation
            )

            this.setState({ croppedImage, croppedAreaPixels })
        } catch (e) {
            console.error(e)
        }
    }

    onZoomChange = (zoom) => {
        if (this.state.zoom === zoom) {
            return;
        }

        this.setState({ zoom });
    }

    createImage = (url) =>
        new Promise((resolve, reject) => {
            const image = new Image();
            image.addEventListener('load', () => resolve(image))
            image.addEventListener('error', (error) => reject(error))
            image.setAttribute('crossOrigin', 'anonymous') // needed to avoid cross-origin issues on CodeSandbox
            image.src = url
        })

    rotateSize = (width, height, rotation) => {
        const rotRad = this.getRadianAngle(rotation)

        return {
            width:
                Math.abs(Math.cos(rotRad) * width) + Math.abs(Math.sin(rotRad) * height),
            height:
                Math.abs(Math.sin(rotRad) * width) + Math.abs(Math.cos(rotRad) * height),
        }
    }

    getRadianAngle = (degreeValue) => (degreeValue * Math.PI) / 180

    getCroppedImg = async (
        image,
        pixelCrop,
        rotation = 0,
        flip = { horizontal: false, vertical: false }
    ) => {
        const canvas = document.createElement('canvas')
        const ctx = canvas.getContext('2d')

        if (!ctx) {
            return null
        }

        const rotRad = this.getRadianAngle(rotation)

        // calculate bounding box of the rotated image
        const { width: bBoxWidth, height: bBoxHeight } = this.rotateSize(
            image.width,
            image.height,
            rotation
        )

        // set canvas size to match the bounding box
        canvas.width = bBoxWidth
        canvas.height = bBoxHeight

        // translate canvas context to a central location to allow rotating and flipping around the center
        ctx.translate(bBoxWidth / 2, bBoxHeight / 2)
        ctx.rotate(rotRad)
        ctx.scale(flip.horizontal ? -1 : 1, flip.vertical ? -1 : 1)
        ctx.translate(-image.width / 2, -image.height / 2)

        // draw rotated image
        ctx.drawImage(image, 0, 0)

        const croppedCanvas = document.createElement('canvas')

        const croppedCtx = croppedCanvas.getContext('2d')

        if (!croppedCtx) {
            return null
        }

        // Set the size of the cropped canvas
        croppedCanvas.width = pixelCrop.width
        croppedCanvas.height = pixelCrop.height

        // Draw the cropped image onto the new canvas
        croppedCtx.drawImage(
            canvas,
            pixelCrop.x,
            pixelCrop.y,
            pixelCrop.width,
            pixelCrop.height,
            0,
            0,
            pixelCrop.width,
            pixelCrop.height
        )

        // As Base64 string
        return croppedCanvas.toDataURL('image/jpeg');

        // As a blob
        /*
        return new Promise((resolve, reject) => {
            croppedCanvas.toBlob((file) => {
                resolve(URL.createObjectURL(file))
            }, 'image/jpeg')
        })
        */
    }

    base64ToBlob = (base64, contentType) => {
        const byteCharacters = atob(base64);
        const byteArrays = [];

        for (let offset = 0; offset < byteCharacters.length; offset += 512) {
            const slice = byteCharacters.slice(offset, offset + 512);

            const byteNumbers = new Array(slice.length);
            for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }

            const byteArray = new Uint8Array(byteNumbers);
            byteArrays.push(byteArray);
        }

        return new Blob(byteArrays, { type: contentType });
    };

    upload = async () => {
        try {
            const { croppedImage } = this.state;
            const url = this.props.url ?? `${process.env.REACT_APP_API_URL}/v1/user/profile/photo`;

            if (!croppedImage) {
                return;
            }

            this.setState({ uploading: true });

            // Extract content type and base64 data from the string
            const match = croppedImage.match(/^data:(.*);base64,(.*)$/);
            if (!match) {
                console.error('Invalid base64 image');
                return;
            }

            const contentType = match[1];
            const base64Data = match[2];

            // Convert base64 to blob
            const blob = this.base64ToBlob(base64Data, contentType);

            // Compress the image
            const compressedImage = await new Promise((resolve, reject) => {
                new Compressor(blob, {
                    quality: 0.5,
                    success: (result) => resolve(result),
                    error: (err) => reject(err),
                });
            });

            // if compressedImage is bigger than 8MB, return
            if (compressedImage.size > 8 * 1024 * 1024) {
                let message = 'Fotoğraf boyutu 8MB\'dan büyük olamaz.';
                let severity = GlobalTypes.NOTIFY_ERR;

                this.props.setNotification({ message, severity });
                return;
            }

            const uploadLink = await AxiosClient().get(url);
            const uploadUrl = decodeURIComponent(decodeURIComponent(uploadLink.data.link));
            if (!uploadUrl) {
                return;
            }

            await axios.put(uploadUrl, compressedImage, {
                headers: {
                    'Content-Type': contentType,
                    "x-amz-acl": "public-read",
                },
            });

            let message = 'Fotoğraf değiştirme talebi başarılı.';
            let severity = GlobalTypes.NOTIFY_SUCC;

            this.props.setNotification({ message, severity });
            this.onClose();
        }
        catch (e) {
            const err = e.response?.data?.error;
            let message = err?.code in ERROR_CODES ? ERROR_CODES[err.code] : 'Fotoğraf değiştirme talebi başarısız. Lütfen tekrar deneyiniz.';
            let severity = GlobalTypes.NOTIFY_ERR;

            this.props.setNotification({ message, severity });
        }
        finally {
            this.setState({ uploading: false });
        }
    }

    render() {
        const { classes, open } = this.props;

        return (
            <Dialog open={open}>
                <DialogTitle>
                    <Box padding={0} display={"flex"} justifyContent={"space-between"}>
                        <Typography className={clsx(classes.title, "!text-xs md:!text-sm lg:!text-base")}>PROFİL FOTOĞRAFI</Typography>

                        <Tooltip title="Kapat" placement="top">
                            <IconButton size="small" onClick={this.onClose} sx={{ backgroundColor: '#624799 !important' }}>
                                <CloseIcon htmlColor="var(--white)" fontSize="small" />
                            </IconButton>
                        </Tooltip>
                    </Box>
                </DialogTitle>

                <DialogContent>
                    <input onChange={this.handleChange} multiple={false} ref={this.fileInputRef} type='file' hidden accept="image/*" />

                    <Box display={"flex"} flexDirection={"column"} rowGap={4} minWidth={240} p={2}>
                        <Button variant="contained" sx={{ backgroundImage: "linear-gradient(to bottom, #2FB16B 50%, #168549)" }}
                            onClick={() => this.fileInputRef.current.click()}
                        >
                            <Typography className={clsx(classes.text, "!text-xs md:!text-sm lg:!text-base")}>Fotoğraf Seç</Typography>
                        </Button>

                        <Box borderRadius={"50%"} margin={"auto"} position={"relative"} width={240} height={240}>
                            <Cropper
                                image={this.state.image ?? this.state.profileSrc}
                                crop={this.state.crop}
                                zoom={this.state.zoom}
                                aspect={1}
                                onCropChange={this.onCropChange}
                                onCropComplete={this.onCropComplete}
                                onZoomChange={this.onZoomChange}
                            />
                        </Box>

                        <Box>
                            <Slider
                                value={this.state.zoom}
                                min={1}
                                max={3}
                                step={0.1}
                                aria-labelledby="Zoom"
                                classes={{ root: classes.slider }}
                                onChange={(e, zoom) => this.setState({ zoom })}
                            />
                        </Box>
                    </Box>
                </DialogContent>

                <DialogActions sx={{ p: 2 }}>
                    <Button variant="contained" onClick={() => this.onClose()}
                        disableFocusRipple disableRipple disableTouchRipple
                        sx={{
                            color: '#FFFFFF', backgroundColor: '#939393 !important',
                        }}
                        className="!text-xs sm:!text-sm md:!text-base"
                    >
                        Vazgeç
                    </Button>

                    <Button variant="contained" onClick={() => this.upload()}
                        disableFocusRipple disableRipple disableTouchRipple disabled={this.state.uploading}
                        sx={{
                            color: '#FFFFFF', backgroundImage: "linear-gradient(to bottom, #2FB16B 50%, #168549)"
                        }}
                        className="!text-xs sm:!text-sm md:!text-base"
                    >
                        Tamam
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

UploadPhoto.propTypes = {
    classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
    return {
        user: state.app.global.user,
    }
};

export default withStyles(styles)(connect(mapStateToProps, {
    setNotification,
})(UploadPhoto));
