import React from "react";
import { connect } from "react-redux";
import { withStyles } from "@mui/styles";
import PropTypes from "prop-types";
import { Box, Button, Card, CardContent, CircularProgress, IconButton, InputAdornment, SvgIcon, TextField, Tooltip, Typography } from "@mui/material";
import { mdiAccount, mdiRefresh, mdiSecurity } from '@mdi/js';
import axios, { AxiosError } from "axios";
import { setNotification } from "../../actions/global";
import { GlobalTypes } from "../../action_types";
import { ERROR_CODES } from "../../global/error";
import clsx from "clsx";
import { AxiosClient } from "../../global/axios";
import { getPath } from "../../global/route";
import { generateRequestId } from "../../global/session";

const styles = theme => ({
    root: {
        backgroundColor: '#624799',
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        overflowY: 'auto',
    },
    title: {
        fontWeight: 'bold !important',
        fontFamily: 'Roboto Slab',
        color: '#1E2C5F !important',
    },
    text: {
        fontWeight: 'medium !important',
        color: '#1E2C5F !important',
    },
    textField: {
        fontFamily: 'Roboto Slab',
        fontWeight: 'bold !important',
    },
    inputIcon: {
        marginRight: '8px',
        color: '#1E2C5F !important'
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        rowGap: 12,
        padding: 24,
        color: 'white !important',
    },
    header: {
        //backgroundImage: "linear-gradient(to right, #624799 50%, #1D9AB6)",
        backgroundColor: '#624799',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        columnGap: 12
    },
    headerTitle: {
        color: '#E6E1FF',
    },
    button: {
    }
});

class Forget extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            username: '',
            captchaId: null,
            captchaQuery: "",
        }
    }

    componentDidMount = () => {
        this.loadCaptcha();
    }

    loadCaptcha = async () => {
        try {
            const resp = await AxiosClient().get(`${process.env.REACT_APP_API_URL}/v1/auth/captcha`);
            const captchaId = resp.data;
            this.setState({ captchaId }, () => this.getCaptchaImage());
        }
        catch (e) {
            console.error(e);
            let message = e.toString();

            if (e instanceof AxiosError) {
                const code = e.response?.data?.error?.code;
                if (code in ERROR_CODES) {
                    message = ERROR_CODES[code];
                } else {
                    message = e.message;
                }
            }

            this.props.setNotification({ message: message, severity: GlobalTypes.NOTIFY_ERR, traceId: e.response?.data?.trace_id });
        }
    }

    refreshCaptcha = () => {
        this.setState({ captchaQuery: `?reload=${new Date().getTime()}` })
    }

    getCaptchaImage = async () => {
        const { captchaId, captchaQuery } = this.state;
        if (!captchaId) {
            return;
        }

        const requestIdQuery = `${captchaQuery ? '&' : '?'}k-request-id=${generateRequestId()}`;
        const src = `${process.env.REACT_APP_API_URL}/v1/auth/captcha/${captchaId}.png${captchaQuery}${requestIdQuery}`;

        const response = await AxiosClient().get(src, { responseType: 'blob' });
        const url = URL.createObjectURL(response.data);
        this.setState({ captchaSrc: url });
    }

    forget = async () => {
        try {
            const { username, captchaId, code } = this.state;
            this.setState({ loading: true });

            const req = {
                user: username,
                captcha_id: captchaId,
                captcha: code,
            };

            await AxiosClient().post(`${process.env.REACT_APP_API_URL}/v1/user/forgot.password`, req);
            this.setState({ loading: false });

            let message = 'Şifre sıfırlama e-postası gönderildi. Lütfen gelen kutunuzu kontrol ediniz. ';
            let severity = GlobalTypes.NOTIFY_SUCC;

            this.props.setNotification({ message, severity });
            this.loadCaptcha();
            this.setState({ username: '', code: '' });
        }
        catch (e) {
            console.error(e);
            let message = e.toString();

            if (e instanceof AxiosError) {
                const code = e.response?.data?.error?.code;
                if (code in ERROR_CODES) {
                    message = ERROR_CODES[code];
                } else {
                    message = e.message;
                }
            }

            this.loadCaptcha();
            this.props.setNotification({ message: message, severity: GlobalTypes.NOTIFY_ERR, traceId: e.response?.data?.trace_id });
            this.setState({ loading: false });
        }
    }

    loginForm = () => {
        const { classes } = this.props;

        return (
            <Box className={classes.content}>
                <form onSubmit={(e) => e.preventDefault()}>
                    <Box display={"flex"} flexDirection={"column"} rowGap={'6px'}>
                        <Typography className={clsx(classes.title, "!text-base !mb-4")}>Şifremi Unuttum</Typography>
                        <Box>
                            <Typography className={classes.text}>Kullanıcı Adı</Typography>
                            <TextField className={classes.textField}
                                autoComplete='new-password'
                                autoFocus
                                fullWidth
                                //placeholder='Kullanıcı Adı'
                                margin="dense"
                                variant="outlined"
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment className={classes.inputIcon} position="start">
                                            <SvgIcon><path d={mdiAccount} /></SvgIcon>
                                        </InputAdornment>
                                    ),
                                    classes: {
                                        input: "!text-sm md:!text-base",
                                    }
                                }}
                                value={this.state.username}
                                onChange={(e) => {
                                    //e.target.value = e.target.value.charAt(0).toLowerCase() + e.target.value.slice(1).toLocaleLowerCase("tr-TR");
                                    this.setState({ username: e.target.value })
                                }
                                }
                            />
                        </Box>

                        <Box>
                            <Typography className={classes.text}>Güvenlik Kodu</Typography>
                            <Box display={"flex"} columnGap={2}>
                                <TextField className={classes.textField}
                                    autoComplete='new-password'
                                    fullWidth
                                    //placeholder='Güvenlik Kodu'
                                    margin="dense"
                                    variant="outlined"
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment className={classes.inputIcon} position="start">
                                                <SvgIcon><path d={mdiSecurity} /></SvgIcon>
                                            </InputAdornment>
                                        ),
                                        classes: {
                                            input: "!text-sm md:!text-base"
                                        }
                                    }}
                                    value={this.state.code}
                                    onChange={(e) => {
                                        // only digits
                                        e.target.value = e.target.value.replace(/\D/g, '');
                                        this.setState({ code: e.target.value })
                                    }}
                                />

                                <Box px={2} marginTop={"8px"} marginBottom={"4px"} bgcolor={"#FFF"} border={"1px solid #A0A0A0"}
                                    className="flex justify-center items-center w-24 rounded-3xl"
                                >
                                    {this.state.captchaSrc && <img src={this.state.captchaSrc} alt={"captcha"} className="h-min w-auto scale-125" />}
                                </Box>

                                <Tooltip title="Yenile" placement="top">
                                    <IconButton sx={{ backgroundColor: '#35A6CA' }} onClick={() => this.refreshCaptcha()}
                                        disableFocusRipple disableRipple disableTouchRipple
                                        className="!w-16 !h-10 !p-0 !text-white !rounded-3xl self-center"
                                    >
                                        <SvgIcon fontSize="small"><path d={mdiRefresh} /></SvgIcon>
                                    </IconButton>
                                </Tooltip>
                            </Box>
                        </Box>
                    </Box>

                    <Box display={"flex"} columnGap={2}>
                        <Button variant='contained' fullWidth sx={{ mt: 2, color: 'var(--white)', backgroundColor: '#FF9559 !important' }}
                            onClick={() => { this.props.history.push(getPath('/')) }}
                            className="!text-sm md:!text-base !min-h-10"
                        >
                            Geri Dön
                        </Button>

                        <Button variant='contained' type="submit" fullWidth sx={{ mt: 2, color: 'var(--white)', backgroundColor: '#FF9559 !important' }}
                            disabled={!this.state.username || !this.state.code || this.state.loading}
                            onClick={this.forget}
                            className="!text-sm md:!text-base !min-h-10"
                        >
                            {this.state.loading ? <CircularProgress size={20} sx={{ margin: '4px 0px', color: '#FF9559' }} thickness={8} /> : 'Şifremi Sıfırla'}
                        </Button>
                    </Box>
                </form>
            </Box>
        );
    }

    header = () => {
        const { classes } = this.props;
        const imgPath = getPath('/img');

        return (
            <Box height={60} px={4}
                className={clsx(classes.header, "flex")}
            >
                <img src={`${imgPath}/logo.svg`} alt="logo" />
                <Box>
                    <Typography className={clsx("!font-['Roboto Slab'] !text-white !text-sm md:!text-base !font-bold")}>{process.env.REACT_APP_HEADER_TITLE}</Typography>
                    <Typography className={clsx("!font-['Roboto Slab'] !text-white !text-sm md:!text-base !font-bold")}>{process.env.REACT_APP_HEADER_SUBTITLE}</Typography>
                </Box>
            </Box>
        );
    }

    render() {
        const { classes } = this.props;

        return (
            <div className={classes.root}>

                {this.header()}

                <div className="w-full flex-1 self-center max-w-md mt-[4vh]">
                    <Card sx={{ backgroundColor: '#FFFFFF', borderRadius: 4, border: '1px solid #E8E8E8' }}>
                        <CardContent sx={{ padding: 0 }}>

                            {this.loginForm()}

                        </CardContent>
                    </Card>
                </div>

                <div className="w-full flex flex-col gap-y-2 justify-center items-center mt-4">
                    <Typography sx={{ color: '#FFFFFF' }} className="!text-xs sm:!text-sm md:!text-base">
                        Tüm hakları saklıdır. © 2024 kerizim.com
                    </Typography>
                </div>
            </div>
        );
    }
}

Forget.propTypes = {
    classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
    return {
    }
};

export default withStyles(styles)(connect(mapStateToProps, {
    setNotification,
})(Forget));
