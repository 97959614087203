import React from "react";
import { connect } from "react-redux";
import { withStyles } from "@mui/styles";
import PropTypes from "prop-types";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, TableBody, TableCell, TableContainer, TableRow, Tooltip, Typography } from "@mui/material";
import { Close } from "@mui/icons-material";
import { setNotification } from "../../actions/global";
import axios from "axios";
import { GlobalTypes } from "../../action_types";
import { AxiosClient } from "../../global/axios";

const styles = theme => ({
});

class NickChangeRequestTracker extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            approval: undefined,
        }
    }

    onClose = () => {
        this.setState({});
        this.props.onClose();
    }

    componentDidMount() {
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.open !== this.props.open && this.props.open) {
            this.getUsernameApproval();
        }
    }

    getUsernameApproval = async () => {
        try {
            const resp = await AxiosClient().get(`${process.env.REACT_APP_API_URL}/v1/user/username.approval`);

            this.setState({ approval: resp.data.approval ?? undefined });

        } catch (err) {
            console.error(err);
            this.props.setNotification({ message: 'Bir hata oluştu. Lütfen tekrar deneyiniz.', severity: GlobalTypes.NOTIFY_ERR });
        }
    }

    render() {
        const { classes } = this.props;

        return (
            <Dialog
                maxWidth={"xs"}
                fullWidth
                open={this.props.open}
            >
                <DialogTitle>
                    <Box padding={0} display={"flex"} justifyContent={"space-between"}>
                        <Typography className={"!text-xs sm:!text-sm md:!text-base"}>
                            Nick Değişim Talebi Sorgulama
                        </Typography>

                        <Tooltip title="Kapat" placement="top">
                            <IconButton size="small" onClick={this.onClose} sx={{ backgroundColor: '#624799 !important' }} tabIndex={-1}>
                                <Close htmlColor="var(--white)" fontSize="small" />
                            </IconButton>
                        </Tooltip>
                    </Box>
                </DialogTitle>

                <DialogContent sx={{ p: 0 }}>
                    <Box sx={{ height: "200px", display: "flex", alignItems: "center", justifyContent: "center" }}>
                        {!this.state.approval && <Typography sx={{ textAlign: "center" }} className="!text-xs sm:!text-sm md:!text-base">
                            Nick değişim talebiniz bulunmamaktadır.
                        </Typography>}
                        {this.state.approval && <Box sx={{ p: 2 }}>
                            <Typography sx={{ textAlign: "center" }} className="!text-xs sm:!text-sm md:!text-base">
                                Nick değişim talebiniz bulunmaktadır. <br />
                                Yetkililer tarafından onaylandıktan sonra nick değişikliği gerçekleşecektir.
                            </Typography>
                            <Typography sx={{ textAlign: "center", mt: 2 }} className="!text-xs sm:!text-sm md:!text-base">
                                Talep edilen yeni nick: {this.state.approval.new_username}
                            </Typography>
                        </Box>}
                    </Box>

                </DialogContent>

                <DialogActions sx={{ p: 2 }}>
                    <Button variant="contained" disabled={false} onClick={() => this.onClose()}
                        disableFocusRipple disableRipple disableTouchRipple color="error"
                        sx={{
                            color: '#FFFFFF',
                        }}
                        className="!text-xs sm:!text-sm md:!text-base"
                    >
                        Kapat
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

NickChangeRequestTracker.propTypes = {
    classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
    return {
    }
};

export default withStyles(styles)(connect(mapStateToProps, {
    setNotification,
})(NickChangeRequestTracker));
