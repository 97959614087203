import React from "react";
import { connect } from "react-redux";
import { withStyles } from "@mui/styles";
import PropTypes from "prop-types";
import { Box } from "@mui/system";
import { mdiRobot } from "@mdi/js";
import { Avatar, Button, SvgIcon, Typography } from "@mui/material";
import clsx from "clsx";
import ContextMenuHandler from "../../utils/ContextMenuHandler";
import UserAvatar from "../Lobby/UserAvatar";


const styles = theme => ({
    text: {
        fontWeight: '400 !important',
    },
});

class TableUser extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            avatar: null,
        };
    }

    componentDidMount() {
    }

    componentDidUpdate(prevProps) {
    }

    render() {
        const { classes, index, user, myTable, selectedTable, selectedTableTurn: turn } = this.props;

        return (
            <Box display={"flex"} columnGap={2}
                bgcolor={turn === index ? "#f48c52" : "#00000033"}
                px={2} py={1} borderRadius={12} height={"min-content"} alignSelf={"center"}
            >
                {user ?
                    <Box
                        className={clsx(
                            "flex relative gap-x-2 max-h-[8vh] h-6 md:h-8",
                            "max-w-16 sm:max-w-24 md:max-w-32 lg:max-w-48"
                        )}
                        onContextMenu={(e) => this.props.userContextMenuHandler?.onContextMenu(e, user)}
                        onTouchStart={(e) => this.props.userContextMenuHandler?.onTouchStart(e, user)}
                        onTouchCancel={this.props.userContextMenuHandler?.onTouchCancel}
                        onTouchEnd={this.props.userContextMenuHandler?.onTouchEnd}
                        onTouchMove={this.props.userContextMenuHandler?.onTouchMove}
                        onDoubleClick={user.bot ? null : () => this.props.onPrivateMessage?.(user.id, user.username)}
                        sx={{
                            cursor: 'pointer',
                        }}
                    >
                        {/** Avatar */}
                        <UserAvatar user={user} />

                        <Typography sx={{ margin: 'auto', color: 'white', whiteSpace: 'nowrap' }}
                            className={clsx(classes.text, "!text-xs sm:!text-sm md:!text-base text-ellipsis overflow-hidden")}>
                            {user.username}
                        </Typography>
                    </Box> :
                    <Button variant="contained" disableFocusRipple disableRipple disableTouchRipple
                        disabled={myTable !== selectedTable}
                        onClick={(e) => this.props.addBot(index)}
                        sx={{ backgroundColor: '#f48c52', minWidth: 0, minHeight: 0 }}
                        className={clsx(
                            "!w-fit !p-1 !max-h-[8vh] !min-h-0 h-6 md:h-8",
                            "bg-gradient-to-b from-yellow-400 via-yellow-500 to-yellow-600 !rounded-lg"
                        )}
                    >
                        <Typography className="!font['Roboto Slab'] !font-bold !text-xs lg:!text-sm">
                            +BOT
                        </Typography>
                    </Button>
                }
            </Box>
        );
    }
}

TableUser.propTypes = {
    classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
    const myTable = state.app.global.myTable;
    const selectedTable = state.app.global.selectedTable;

    return {
        myTable,
        selectedTable,
        selectedTableTurn: state.app.table.tables[selectedTable]?.turn,
    }
};

export default withStyles(styles)(connect(mapStateToProps, {
})(TableUser));
