import React from "react";
import { connect } from "react-redux";
import { withStyles } from "@mui/styles";
import PropTypes from "prop-types";
import { Box, Button, Card, CardContent, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, FormControlLabel, IconButton, InputAdornment, Pagination, Radio, RadioGroup, SvgIcon, Table, TableBody, TableCell, TableHead, TableRow, TextField, Tooltip, Typography } from "@mui/material";
import { mdiMagnify, mdiPlusCircle } from "@mdi/js";
import debounce from "lodash.debounce";
import { setNotification } from "../../../actions/global";
import { GlobalTypes } from "../../../action_types";
import axios from "axios";
import { Delete, Edit } from "@mui/icons-material";
import EditUsernameForbid from "./EditUsernameForbid";
import clsx from "clsx";
import { AxiosClient } from "../../../global/axios";


const styles = theme => ({
    table: {
        border: '2px solid #f0f0f0',
        userSelect: 'text !important',
    },
    tCell: {
        border: 0,
        padding: 32,
        verticalAlign: 'top',
    },
    cellHeader: {
        fontFamily: 'Roboto Slab',
        width: 180,
        fontWeight: 700,
    },
    cellContent: {
        color: '#6b7280 !important',
        fontFamily: 'Roboto Slab !important',
    },
    text: {
        fontWeight: '400 !important',
        color: 'var(--white)',
    },
    inputIcon: {
        marginRight: '8px',
        color: '#31204A !important'
    },
});

class UsernameForbids extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            search: '',
            page: 1,
            size: 10,
            usernameForbids: [],
            totalUsernameForbids: 0,
            deleteUsernameForbid: null,
            edit: false,
            editUsernameForbid: null,
        }

    }

    componentDidMount() {
        this.listUsernameForbids();
    }

    componentDidUpdate(prevProps) {
    }

    handleDeleteUsernameForbid = (usernameForbid) => {
        this.setState({ deleteUsernameForbid: usernameForbid });
    }

    listUsernameForbids = async () => {
        try {
            const { search, page, size, filter } = this.state;
            const resp = await AxiosClient().get(`${process.env.REACT_APP_API_URL}/v1/admin/server/username.forbids?search=${search}&page=${page}&size=${size}`);

            this.setState({ usernameForbids: resp.data.data ?? [], totalUsernameForbids: resp.data.total });

        } catch (err) {
            console.error(err);
            this.props.setNotification({ message: 'Bir hata oluştu. Lütfen tekrar deneyiniz.', severity: GlobalTypes.NOTIFY_ERR });
        }
    }

    debounceSearchForbids = debounce(() => this.setState({ page: 1 }, () => this.listUsernameForbids()), 500);

    searchChanged = (e) => {
        this.setState({ search: e.target.value });
        this.debounceSearchForbids();
    }

    deleteUsernameForbid = async () => {
        try {
            const { deleteUsernameForbid } = this.state;

            if (!deleteUsernameForbid) return;

            await AxiosClient().delete(`${process.env.REACT_APP_API_URL}/v1/admin/server/username.forbids/${deleteUsernameForbid.id}`);

            this.props.setNotification({ message: 'Nick yasaklama başarıyla silindi.', severity: GlobalTypes.NOTIFY_SUCC });

        } catch (err) {
            console.error(err);
            this.props.setNotification({ message: 'Bir hata oluştu. Lütfen tekrar deneyiniz.', severity: GlobalTypes.NOTIFY_ERR });

        } finally {
            this.setState({ deleteUsernameForbid: null }, () => this.listUsernameForbids());
        }
    }

    onAddUsernameForbid = () => {
        this.setState({ edit: true, editUsernameForbid: null });
    }

    handleEditUsernameForbid = (usernameForbid) => {
        this.setState({ edit: true, editUsernameForbid: usernameForbid });
    }

    // UI PART

    deleteUsernameForbidDialog = () => {
        const { deleteUsernameForbid } = this.state;

        const onClose = () => this.setState({ deleteUsernameForbid: null });

        return (
            <Dialog open={deleteUsernameForbid !== null} onClose={onClose}>
                <DialogTitle>Nick Yasaklama Kaldır</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Nick yasaklamayı kaldırmak istediğinize emin misiniz?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onClose} variant="contained" color="error">
                        İptal
                    </Button>
                    <Button onClick={() => this.deleteUsernameForbid()} variant="contained" color="primary" autoFocus className="!text-white">
                        Kaldır
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }

    tableView = () => {
        const { classes } = this.props;
        const { usernameForbids } = this.state;

        if (usernameForbids.length === 0) {
            return (
                <Box className="!hidden lg:!flex justify-center items-center h-[300px]">
                    <Typography className={"!text-xs md:!text-sm lg:!text-base"}>Sonuç bulunamadı.</Typography>
                </Box>
            );
        }

        return (
            <Table className={clsx(
                classes.table,
                "!hidden lg:!table",
            )}>
                <TableHead>
                    <TableRow>
                        <TableCell className={classes.tCell} sx={{ borderBottom: '2px solid #f0f0f0', minWidth: 0, maxWidth: 0 }}>
                            <Typography className={clsx(classes.cellHeader)}>
                                Kalıp
                            </Typography>
                        </TableCell>
                        <TableCell className={classes.tCell} sx={{ borderBottom: '2px solid #f0f0f0', minWidth: 0, maxWidth: 0 }}>
                            <Typography className={clsx(classes.cellHeader)}>
                                Ekleyen
                            </Typography>
                        </TableCell>
                        <TableCell className={classes.tCell} sx={{ borderBottom: '2px solid #f0f0f0', minWidth: 0, maxWidth: 0 }}>
                            <Typography className={clsx(classes.cellHeader)}>
                                Eklenen Tarih
                            </Typography>
                        </TableCell>
                        <TableCell className={classes.tCell} sx={{ borderBottom: '2px solid #f0f0f0', minWidth: 0, maxWidth: 0 }}>
                            <Typography className={clsx(classes.cellHeader)}>
                                Sebep
                            </Typography>
                        </TableCell>
                        <TableCell className={classes.tCell} sx={{ borderBottom: '2px solid #f0f0f0', minWidth: 0, maxWidth: 0 }}>
                            <Typography className={clsx(classes.cellHeader)}>
                                Aksiyon
                            </Typography>
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {usernameForbids.map((usernameForbid) => {
                        return (
                            <TableRow key={usernameForbid.id}>
                                <TableCell>
                                    <Typography className={clsx(classes.cellContent)}>
                                        {usernameForbid.pattern}
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography className={clsx(classes.cellContent)}>
                                        {usernameForbid.created_by}
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography className={clsx(classes.cellContent)}>
                                        {new Date(usernameForbid.created_at).toLocaleDateString()}
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography className={clsx(classes.cellContent)}>
                                        {usernameForbid.reason ?? '-'}
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Tooltip title="Düzenle" placement="top">
                                        <IconButton onClick={() => this.handleEditUsernameForbid(usernameForbid)}>
                                            <Edit fontSize="small" />
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip title="Sil" placement="top">
                                        <IconButton onClick={() => this.handleDeleteUsernameForbid(usernameForbid)}>
                                            <Delete fontSize="small" />
                                        </IconButton>
                                    </Tooltip>
                                </TableCell>
                            </TableRow>
                        );
                    })}

                </TableBody>
            </Table>
        );
    }

    mobileView = () => {
        const { classes } = this.props;
        const { usernameForbids } = this.state;

        if (usernameForbids.length === 0) {
            return (
                <Box className="flex lg:hidden justify-center items-center">
                    <Typography className={"!text-xs md:!text-sm lg:!text-base"}>Sonuç bulunamadı.</Typography>
                </Box>
            );
        }

        return (
            <Box className="flex flex-col gap-y-2 lg:hidden">
                {usernameForbids.map((usernameForbid) => {
                    return (
                        <Card key={usernameForbid.id} sx={{ borderRadius: 4, border: '1px solid #c4c4c4', boxShadow: 0 }}>
                            <CardContent>
                                <Table>
                                    <TableRow>
                                        <TableCell className="!p-2">
                                            <Typography className={clsx(classes.cellContent)}>
                                                Kalıp
                                            </Typography>
                                        </TableCell>
                                        <TableCell className="!p-2">
                                            <Typography className={clsx(classes.cellContent)}>
                                                {usernameForbid.pattern}
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell className="!p-2">
                                            <Typography className={clsx(classes.cellContent)}>
                                                Ekleyen
                                            </Typography>
                                        </TableCell>
                                        <TableCell className="!p-2">
                                            <Typography className={clsx(classes.cellContent)}>
                                                {usernameForbid.created_by}
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell className="!p-2">
                                            <Typography className={clsx(classes.cellContent)}>
                                                Eklenen Tarih
                                            </Typography>
                                        </TableCell>
                                        <TableCell className="!p-2">
                                            <Typography className={clsx(classes.cellContent)}>
                                                {new Date(usernameForbid.created_at).toLocaleDateString()}
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell className="!p-2">
                                            <Typography className={clsx(classes.cellContent)}>
                                                Sebep
                                            </Typography>
                                        </TableCell>
                                        <TableCell className="!p-2">
                                            <Typography className={clsx(classes.cellContent)}>
                                                {usernameForbid.reason ?? '-'}
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell className="!p-2">
                                            <Typography className={clsx(classes.cellContent)}>
                                                Aksiyon
                                            </Typography>
                                        </TableCell>
                                        <TableCell className="!p-2">
                                            <Tooltip title="Düzenle" placement="top">
                                                <IconButton className="!p-[2px] !mr-2" onClick={() => this.handleEditUsernameForbid(usernameForbid)}>
                                                    <Edit className="!text-[16px]" />
                                                </IconButton>
                                            </Tooltip>
                                            <Tooltip title="Sil" placement="top">
                                                <IconButton className="!p-[2px] !mr-2" onClick={() => this.handleDeleteUsernameForbid(usernameForbid)}>
                                                    <Delete className="!text-[16px]" />
                                                </IconButton>
                                            </Tooltip>
                                        </TableCell>
                                    </TableRow>
                                </Table>
                            </CardContent>
                        </Card>

                    );
                })}
            </Box>
        );
    }

    render() {
        const { classes } = this.props;
        const { totalUsernameForbids } = this.state;

        return (
            <Box margin={1} display={"flex"} flexDirection={"column"} rowGap={1}>

                <Box className="flex gap-x-2 justify-between items-center">
                    <div />
                    <Button variant="contained" sx={{ backgroundImage: "linear-gradient(to bottom, #2FB16B 50%, #168549)" }}
                        onClick={this.onAddUsernameForbid}
                        className="h-9"
                    >
                        <Box display="flex" columnGap="8px">
                            <SvgIcon fontSize="small" sx={{ margin: 'auto' }}><path d={mdiPlusCircle} color="var(--white)" /></SvgIcon>
                            <Typography className={clsx(classes.text, "!text-xs md:!text-sm lg:!text-base")} alignSelf={"center"}>Ekle</Typography>
                        </Box>
                    </Button>
                </Box>

                <TextField variant="outlined" placeholder="Kalıp Ara" fullWidth margin="dense"
                    InputProps={{
                        classes: {
                            input: "!text-xs md:!text-sm lg:!text-base",
                            root: "p-1 md:p-2"
                        },
                        startAdornment: (
                            <InputAdornment position="start">
                                <SvgIcon fontSize='small'>
                                    <path d={mdiMagnify} />
                                </SvgIcon>
                            </InputAdornment>
                        )
                    }}
                    value={this.state.search}
                    onChange={this.searchChanged}
                    sx={{
                        '& .MuiInputBase-root': { p: 0, px: 2 },
                        '& .MuiInputBase-root::before': { borderBottom: '1px solid #E3E3E3 !important' },
                        '& .MuiInputBase-root::after': { borderBottom: '1px solid #E3E3E3 !important' },
                    }}
                />

                {this.tableView()}
                {this.mobileView()}

                <Box className="flex justify-end">
                    <Pagination count={parseInt(Math.ceil(totalUsernameForbids / this.state.size))} page={this.state.page}
                        variant="outlined" shape="rounded" color="primary"
                        onChange={(e, p) => this.setState({ page: p }, () => this.listUsernameForbids())}
                    />
                </Box>

                <EditUsernameForbid open={this.state.edit} usernameForbid={this.state.editUsernameForbid}
                    onClose={(refresh) => this.setState({ edit: false, editUsernameForbid: null }, () => refresh && this.listUsernameForbids())}
                />

                {this.deleteUsernameForbidDialog()}
            </Box>
        );
    }
}

UsernameForbids.propTypes = {
    classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
    return {
    }
};

export default withStyles(styles)(connect(mapStateToProps, {
    setNotification,
})(UsernameForbids));
