const endMessage = (endRound, usersById) => {
    let message = '';
    if (!endRound?.user) {
        message = 'Tur berabere bitti.';
    }
    else {
        switch (endRound?.r) {
            case 1:
                message = `${usersById[endRound?.user]?.username ?? ''} okey attı.`;
                break;
            case 2:
                message = `${usersById[endRound?.user]?.username ?? ''} tura dönerek eli açtı.`;
                break;
            case 3:
                message = `${usersById[endRound?.user]?.username ?? ''} tura dönerek okey attı!`;
                break;
            default:
                message = `${usersById[endRound?.user]?.username ?? ''} eli açtı.`;
                break;
        }
    }

    if (endRound?.end_game) {
        message += ` Oyun bitti.`;
    }
    else {
        message += ' Bir sonraki tur 4 saniye içinde başlayacak.';
    }

    return message;
}

module.exports = {
    endMessage,
}