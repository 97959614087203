import keyMirror from '../utils/key_mirror';

export default keyMirror({
    SET_SOCKET: null,
    SET_SOCKET_USER: null,

    SET_DISCONNECTED: null,
    SET_ERROR: null,
    SET_NOTIFICATION: null,
    SET_PING: null,
    SELECT_TAB: null,

    SET_MY_TABLE: null,
    ADD_SPECTATING_TABLE: null,
    REMOVE_SPECTATING_TABLE: null,

    SET_INFO_DIALOG: null,
    CLOSE_INFO_DIALOG: null,
    SET_LEAVING_TABLE: null,
    SET_INVITATION_DIALOG: null,

    NOTIFY_ERR: null,
    NOTIFY_INFO: null,
    NOTIFY_SUCC: null,
    NOTIFY_WARN: null,

    SET_USER_STATUS: null,
    SET_USER_AUTOPILOT: null,

    ADD_CHAT_TAB: null,
    REMOVE_CHAT_TAB: null,

    ADD_NOTIFICATIONS: null,
    UPDATE_NOTIFICATION: null,
    READ_NOTIFICATIONS: null,
    SET_NOTIFICATIONS: null,

    ADD_FRIEND: null,
    UPDATE_FRIEND: null,
    SET_FRIENDS: null,
    REMOVE_FRIEND: null,

    ADD_BLOCKED_USER: null,
    REMOVE_BLOCKED_USER: null,
    SET_BLOCKED_USERS: null,

    SET_ROOM_CONFIG: null,
    SET_ROOM_MAINTENANCE: null,
    SET_ADMIN_PATH: null,
    SET_ADMIN_TAB: null,

    SET_ROLE_PERMISSON: null,
    SET_GENERAL_SETTINGS: null,
    SET_REPORT_DIALOG: null,

    SET_LOADING_STATE: null,
    SET_STREAM_INFO: null,
});
