import React from 'react';
import { history } from './store';
import Snackbar from './components/Error/Snackbar';
import { withOrientationChange } from 'react-device-detect';
import Container from './Container';

function App(props) {
  return (
    <>
      <Snackbar />

      <Container history={history} {...props} />
    </>
  );
}

export default withOrientationChange(App);
