export const presents = [
    "cay.png",
    "kahve.png",
    "pasta.png",
    "yat.png",
    "jelibon.png",
    "sut.png",
    "portakalsuyu.png",
    "dondurma.png",
    "sandvic.png",
    "tektas.png",
    "nazar.gif",
    "araba.png",
    "limonata.png",
    "serpmekahvalti.png",
    "ananas.png",
    "sahlep.png",
    "ozelkokteyl.png",
    "simit.png",
    "pizza.png",
    "cilek.png",
    "lokum.png",
    "elma.png",
    "baklava.png",
    "patatescipsi.png",
    "cerez.png",
    "kek.png",
    "hurma.png",
    "armut.png",
    "karpuz.png",
    "sucukekmek.png",
];