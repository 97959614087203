import React from "react";
import { connect } from "react-redux";
import { withStyles } from "@mui/styles";
import PropTypes from "prop-types";
import { Box, Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, FormGroup, Grid, IconButton, MenuItem, SvgIcon, Table, TableCell, TableRow, TextField, Tooltip, Typography } from "@mui/material";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from "dayjs";
import { Add, AllInclusive, PlusOne, Remove } from "@mui/icons-material";
import clsx from "clsx";
import axios from "axios";
import { setNotification } from "../../../actions/global";
import { GlobalTypes } from "../../../action_types";
import { AxiosClient } from "../../../global/axios";
import { getPath } from "../../../global/route";
import { loadedResources } from "../../../global/resources";
import { roles as userRoles } from "../../../global/role";

/* global BigInt */

const styles = theme => ({
});

class EditAdmin extends React.Component {
    constructor(props) {
        super(props);

        this.icons = [
            "iyetki01.png",
            //"iyetki02.png",
            //"iyetki03.png",
            //"iyetki04.png",
            "iyetki05.png",
            "iyetki06.png",
            "iyetki07.png",
            "iyetki08.webp",
        ];

        this.roles = [
            { value: 1, label: 'Yayıncı' },
            { value: 2, label: 'Oyun Operatörü' },
            { value: 3, label: 'Oyun Admini' },
            { value: 4, label: 'Oyun Sahibi' },
            { value: 5, label: 'Oyun Kurucusu' },
            //{ value: 5, label: 'Oyun Kurucusu' },
        ];

        this.permissions = [
            "Nick İşlemleri",
            "Nick Renk İşlemleri",
            "Sunucu Komutu Uyg",
            "Oda Düzenleyebilir",
            "Yetki Ekleyebilir",
            "Regex Uygulayabilir",
            "Spam İşlemleri",
            "Forbid İşlemleri",
            "Banları Düzenleyebilir",
            "Gold Üye İşlemleri",
            "Kill Atabilir",
            "Ban Atabilir",
            "Sınırsız Ban",
            "Oda Ayarlayabilir",
            "Genel Mesaj Gönderebilir",
            "Geneli Aç/Kapa",
            "Geneli Temizle",
            "Puanı 1000 Yapabilir",
            "Oturma Süresi Verebilir",
            "Kullanıcı Susturabilir",
            "Kullanıcı Onaylayabilir",
        ];

        this.extraPermissions = [
            "User Agent Görebilir",
            "IP Adresi Görebilir",
            "İşlem Yapmama Görebilir",
            "Etkinlik Erişimi",
            "Özeli Spamdan Muaf",
            "Yazdıkları Spamdan Muaf",
        ];

        this.logPermissions = [
            "Komut Logları",
            "Diğer Loglar",
            "Spam Logları",
            "Kayıt ve Avatar Logları",
            "Uzak Giriş Çıkış",
        ];

        this.usernameColors = Array(99).fill(501).map((x, y) => x + y);
        this.textColors = Array(99).fill(501).map((x, y) => x + y);
        this.defaultPermission = (1n << 6n | 1n << 7n | 1n << 8n | 1n << 9n | 1n << 10n | 1n << 11n | 1n << 12n | 1n << 13n | 1n << 14n | 1n << 15n | 1n << 16n | 1n << 17n | 1n << 18n | 1n << 19n | 1n << 20n);
        this.defaultExtraPermission = (1n << 21n | 1n << 22n | 1n << 23n | 1n << 26n);
        this.defaultLogPermission = (1n << 27n | 1n << 28n | 1n << 29n | 1n << 30n | 1n << 31n);

        this.state = {
            username: props.admin?.username ?? '',
            icon: props.admin?.icon ?? this.icons[1],
            role: props.admin?.role ?? userRoles.Moderator,
            usernameColor: props.admin?.username_color ?? this.usernameColors[0],
            textColor: props.admin?.text_color ?? this.textColors[0],
            note: props.admin?.description ?? '',
            date: props.admin?.date ? dayjs(props.admin.date) : dayjs(new Date()).add(1, 'day'),
            permission: props.admin?.permission ?? Number(this.defaultPermission | this.defaultExtraPermission | this.defaultLogPermission),
            saving: false,
        }
    }

    componentDidMount() {
    }

    componentDidUpdate(prevProps) {
        if (this.props.admin && !prevProps.admin) {
            this.setState({
                username: this.props.admin.username,
                icon: this.props.admin.icon,
                role: this.props.admin.role,
                usernameColor: (this.props.admin.username_color),
                textColor: (this.props.admin.text_color),
                note: this.props.admin.description,
                date: this.props.admin.valid_until ? dayjs(this.props.admin.valid_until) : null,
                permission: this.props.admin.permission,
            });
        }

        if (!this.props.admin && prevProps.admin) {
            this.setState({
                username: '',
                icon: this.icons[0],
                role: userRoles.Moderator,
                usernameColor: this.usernameColors[0],
                textColor: this.textColors[0],
                note: '',
                date: dayjs(new Date()),
                permission: Number(this.defaultPermission | this.defaultExtraPermission | this.defaultLogPermission),
            });
        }
    }

    onSave = async () => {
        this.setState({ saving: true });

        const admin = {
            username: this.state.username,
            icon: this.state.icon,
            role: this.state.role,
            usernameColor: this.state.usernameColor?.toString(),
            textColor: this.state.textColor?.toString(),
            note: this.state.note,
            date: this.state.date ? this.state.date.unix() : null,
            permission: this.state.permission,
        };

        if (this.props.admin) {
            admin.id = this.props.admin.id;
        }

        try {
            await AxiosClient().post(`${process.env.REACT_APP_API_URL}/v1/admin/admins`, admin);

            this.props.setNotification({ message: `Admin ${this.props.admin ? 'düzenlendi.' : 'eklendi.'}`, severity: GlobalTypes.NOTIFY_SUCC });
            this.props.onClose(true);

        } catch (error) {
            console.error(error);
            this.props.setNotification({ message: 'Bir hata oluştu. Lütfen tekrar deneyin.', severity: GlobalTypes.NOTIFY_ERR });
        } finally {
            this.setState({ saving: false });
        }
    }

    onRoleChange = (e) => {
        let { permission, icon } = this.state;

        if (e.target.value === 1) {
            permission = 0;
            icon = this.icons[0];
        }
        else if (this.state.role === 1 && e.target.value !== 1) {
            permission = Number(this.defaultPermission | this.defaultExtraPermission | this.defaultLogPermission);
            icon = this.icons[1];
        }

        this.setState({ role: e.target.value, permission, icon });
    }

    editDialog = () => {
        const { classes } = this.props;

        return (
            <Dialog open={this.props.open} onClose={() => this.props.onClose()}>
                <DialogTitle>
                    <Typography className={classes.title} sx={{ color: '#413067' }}>
                        {`Admin ${this.props.admin ? 'Düzenle' : 'Ekle'}`}
                    </Typography>
                </DialogTitle>
                <DialogContent>
                    <Table>
                        <TableRow>
                            <TableCell className="!border-0 !p-2 min-w-32">
                                <Typography className={classes.title} sx={{ color: '#413067' }}>
                                    Kullanıcı Adı
                                </Typography>
                            </TableCell>
                            <TableCell className="!border-0 !p-2">
                                <TextField margin="dense" fullWidth
                                    value={this.state.username}
                                    sx={{
                                        borderTopRightRadius: '0 !important',
                                        borderBottomRightRadius: '0 !important',
                                        margin: 0,
                                    }}
                                    InputProps={{
                                        classes: {
                                            input: "h-8 sm:h-12",
                                            root: "h-8 sm:h-12",
                                        }
                                    }}
                                    disabled={Boolean(this.props.admin)}
                                    onChange={(e) => this.setState({ username: e.target.value })}
                                />
                            </TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell className="!border-0 !p-2">
                                <Typography className={classes.title} sx={{ color: '#413067' }}>
                                    Icon
                                </Typography>
                            </TableCell>
                            <TableCell className="!border-0 !p-2">
                                <TextField margin="dense" fullWidth select
                                    value={this.state.icon} sx={{
                                        borderTopRightRadius: '0 !important',
                                        borderBottomRightRadius: '0 !important',
                                        margin: 0,
                                    }}
                                    InputProps={{
                                        classes: {
                                            input: "h-8 sm:h-12",
                                            root: "h-8 sm:h-12",
                                        }
                                    }}
                                    onChange={(e) => this.setState({ icon: e.target.value })}
                                >
                                    {this.icons.map((icon) => (
                                        <MenuItem key={icon} value={icon} sx={{ display: 'flex', justifyContent: 'center' }}>
                                            <img src={loadedResources[`${process.env.REACT_APP_CDN_URL}/web/img/${icon}`]} alt={icon} className="w-8 h-8" />
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell className="!border-0 !p-2">
                                <Typography className={classes.title} sx={{ color: '#413067' }}>
                                    Seviye
                                </Typography>
                            </TableCell>
                            <TableCell className="!border-0 !p-2">
                                <TextField margin="dense" fullWidth select
                                    value={this.state.role} sx={{
                                        borderTopRightRadius: '0 !important',
                                        borderBottomRightRadius: '0 !important',
                                        margin: 0,
                                    }}
                                    InputProps={{
                                        classes: {
                                            input: "h-8 sm:h-12",
                                            root: "h-8 sm:h-12",
                                        }
                                    }}
                                    onChange={this.onRoleChange}
                                >
                                    {this.roles.map((role) => (
                                        <MenuItem key={role.value} value={role.value}>
                                            {role.label}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell className="!border-0 !p-2">
                                <Typography className={classes.title} sx={{ color: '#413067' }}>
                                    Nick Rengi
                                </Typography>
                            </TableCell>
                            <TableCell className="!border-0 !p-2">
                                <TextField margin="dense" fullWidth select
                                    value={this.state.usernameColor} sx={{
                                        borderTopRightRadius: '0 !important',
                                        borderBottomRightRadius: '0 !important',
                                        margin: 0,
                                    }}
                                    InputProps={{
                                        classes: {
                                            input: "h-8 sm:h-12",
                                            root: "h-8 sm:h-12",
                                        }
                                    }}
                                    onChange={(e) => this.setState({ usernameColor: e.target.value })}
                                >
                                    {this.usernameColors.map((color) => (
                                        <MenuItem key={color} value={color}>
                                            <span className={`NickRenk${color}`}>Nick Rengi {color - 500}</span>
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell className="!border-0 !p-2">
                                <Typography className={classes.title} sx={{ color: '#413067' }}>
                                    Yazı Rengi
                                </Typography>
                            </TableCell>
                            <TableCell className="!border-0 !p-2">
                                <TextField margin="dense" fullWidth select
                                    value={this.state.textColor} sx={{
                                        borderTopRightRadius: '0 !important',
                                        borderBottomRightRadius: '0 !important',
                                        margin: 0,
                                    }}
                                    InputProps={{
                                        classes: {
                                            input: "h-8 sm:h-12",
                                            root: "h-8 sm:h-12",
                                        }
                                    }}
                                    onChange={(e) => this.setState({ textColor: e.target.value })}
                                >
                                    {this.textColors.map((color) => (
                                        <MenuItem key={color} value={color}>
                                            <span className={`YaziRenk${color}`}>Yazı Rengi {color - 500}</span>
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell className="!border-0 !p-2">
                                <Typography className={classes.title} sx={{ color: '#413067' }}>
                                    Not
                                </Typography>
                            </TableCell>
                            <TableCell className="!border-0 !p-2">
                                <TextField margin="dense" fullWidth
                                    value={this.state.note} sx={{
                                        borderTopRightRadius: '0 !important',
                                        borderBottomRightRadius: '0 !important',
                                        margin: 0,
                                    }}
                                    InputProps={{
                                        classes: {
                                            input: "h-8 sm:h-12",
                                            root: "h-8 sm:h-12",
                                        }
                                    }}
                                    onChange={(e) => this.setState({ note: e.target.value })}
                                />
                            </TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell className="!border-0 !p-2">
                                <Typography className={classes.title} sx={{ color: '#413067' }}>
                                    Tarih
                                </Typography>
                            </TableCell>
                            <TableCell className="!border-0 !p-2">
                                <Box className="flex gap-x-1 items-center">

                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DatePicker format="DD/MM/YYYY" onChange={date => this.setState({ date })} value={this.state.date}
                                            disabled={!Boolean(this.state.date)} disablePast
                                        />
                                    </LocalizationProvider>

                                    <Tooltip title="1 gün azalt" placement="top">
                                        <IconButton onClick={() => this.setState({ date: this.state.date?.subtract?.(1, 'day') ?? dayjs(new Date()) })}
                                            className="w-9 h-9"
                                        >
                                            <SvgIcon>
                                                <Remove />
                                            </SvgIcon>
                                        </IconButton>
                                    </Tooltip>

                                    <Tooltip title="1 gün arttır" placement="top">
                                        <IconButton onClick={() => this.setState({ date: this.state.date?.add?.(1, 'day') ?? dayjs(new Date()) })}
                                            className="w-9 h-9"
                                        >
                                            <SvgIcon>
                                                <Add />
                                            </SvgIcon>
                                        </IconButton>
                                    </Tooltip>

                                    <Tooltip title="Süresiz" placement="top">
                                        <IconButton onClick={() => this.setState({ date: this.state.date ? null : dayjs(new Date()) })}
                                            className={clsx(this.state.date ? null : "!bg-blue-400", "w-9 h-9")}
                                        >
                                            <SvgIcon>
                                                <AllInclusive htmlColor={this.state.date ? null : 'white'} />
                                            </SvgIcon>
                                        </IconButton>
                                    </Tooltip>
                                </Box>
                            </TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell className="!border-0 !p-2 !flex">
                                <Typography className={classes.title} sx={{ color: '#413067' }}>
                                    Yetki Listesi
                                </Typography>
                            </TableCell>

                            <TableCell className="!border-0 !p-2">
                                <FormGroup>
                                    <Grid container spacing={1}>
                                        {this.permissions.map((permission, index) => (
                                            <Grid item xs={12} md={6} key={permission}>
                                                <FormControlLabel control={
                                                    <Checkbox onChange={(e, checked) => this.setState({
                                                        permission: checked ?
                                                            Number(BigInt(this.state.permission) | (1n << BigInt(index))) :
                                                            Number(BigInt(this.state.permission) & ~(1n << BigInt(index)))
                                                    })}
                                                        checked={Number(BigInt(this.state.permission) & (1n << BigInt(index)))}
                                                        disabled={this.state.role === 1}
                                                    />
                                                } label={permission} />
                                            </Grid>
                                        ))}
                                    </Grid>
                                </FormGroup>
                            </TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell className="!border-0 !p-2 !flex">
                                <Typography className={classes.title} sx={{ color: '#413067' }}>
                                    Extra Yetkiler
                                </Typography>
                            </TableCell>

                            <TableCell className="!border-0 !p-2">
                                <FormGroup>
                                    <Grid container spacing={1}>
                                        {this.extraPermissions.map((permission, index) => {
                                            index += this.permissions.length;

                                            return (
                                                <Grid item xs={12} md={6} key={permission}>
                                                    <FormControlLabel control={
                                                        <Checkbox onChange={(e, checked) => this.setState({
                                                            permission: checked ?
                                                                Number(BigInt(this.state.permission) | (1n << BigInt(index))) :
                                                                Number(BigInt(this.state.permission) & ~(1n << BigInt(index)))
                                                        })}
                                                            checked={Number(BigInt(this.state.permission) & (1n << BigInt(index)))}
                                                            disabled={this.state.role === 1}
                                                        />
                                                    } label={permission} />
                                                </Grid>
                                            );
                                        })}
                                    </Grid>
                                </FormGroup>
                            </TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell className="!border-0 !p-2 !flex">
                                <Typography className={classes.title} sx={{ color: '#413067' }}>
                                    Log Yetkileri
                                </Typography>
                            </TableCell>

                            <TableCell className="!border-0 !p-2">
                                <FormGroup>
                                    <Grid container spacing={1}>
                                        {this.logPermissions.map((permission, index) => {
                                            index += this.permissions.length + this.extraPermissions.length;

                                            return (
                                                <Grid item xs={12} md={6} key={permission}>
                                                    <FormControlLabel control={
                                                        <Checkbox onChange={(e, checked) => this.setState({
                                                            permission: checked ?
                                                                Number(BigInt(this.state.permission) | (1n << BigInt(index))) :
                                                                Number(BigInt(this.state.permission) & ~(1n << BigInt(index)))
                                                        })}
                                                            checked={Number(BigInt(this.state.permission) & (1n << BigInt(index)))}
                                                            disabled={this.state.role === 1}
                                                        />
                                                    } label={permission} />
                                                </Grid>
                                            );
                                        })}
                                    </Grid>
                                </FormGroup>
                            </TableCell>
                        </TableRow>

                    </Table>
                </DialogContent>

                <DialogActions>
                    <Button onClick={() => this.props.onClose()} variant="contained" color="error">
                        İptal
                    </Button>
                    <Button onClick={this.onSave} variant="contained" color="primary"
                        className="!text-white"
                        disabled={!this.state.username || !this.state.icon || !this.state.role || !this.state.usernameColor || !this.state.textColor || this.state.saving}
                    >
                        Kaydet
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }

    render() {
        return this.editDialog();
    }
}

EditAdmin.propTypes = {
    classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
    return {
    }
};

export default withStyles(styles)(connect(mapStateToProps, {
    setNotification
})(EditAdmin));
