const applause = require("../audio/applause.wav");
const applause2 = require("../audio/applause2.wav");
const applause3 = require("../audio/applause3.ogg");
const piece = require("../audio/piece.wav");
const start = require("../audio/start.wav");
const login = require("../audio/login.wav");
const tension = require("../audio/tension.wav");
const laugh = require("../audio/laugh.wav");
const notification = require("../audio/notification.mp3");
const message = require("../audio/message.wav");
const welcome = require("../audio/welcome.wav");

export const pieceAudio = new Audio(piece);
export const applauseAudio = new Audio(applause);
export const applauseAudio2 = new Audio(applause2);
export const applauseAudio3 = new Audio(applause3);
export const startAudio = new Audio(start);
export const loginAudio = new Audio(login);
export const tensionAudio = new Audio(tension);
export const laughAudio = new Audio(laugh);
export const notificationAudio = new Audio(notification);
export const messageAudio = new Audio(message);
export const welcomeAudio = new Audio(welcome);

let active = false;

export function play(wav, shouldPlay) {
    try {
        const settings = localStorage.getItem('notification_settings');
        if (settings) {
            const settingsJson = JSON.parse(settings);
            if (!shouldPlay?.(settingsJson)) {
                return;
            }
        }

        if (active) {
            wav.play();
            return;
        }

        const callback = () => {
            active = true;
            wav.play();
            document.removeEventListener('click', callback);
        }

        const l = document.addEventListener('click', callback);
    } catch { }
}