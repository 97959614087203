import React from "react";
import { connect } from "react-redux";
import { withStyles } from "@mui/styles";
import PropTypes from "prop-types";
import { Box, Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, FormGroup, Grid, IconButton, MenuItem, SvgIcon, Tab, Table, TableCell, TableRow, Tabs, TextField, Tooltip, Typography } from "@mui/material";
import axios from "axios";
import { setNotification } from "../../../actions/global";
import { GlobalTypes } from "../../../action_types";
import { AxiosClient } from "../../../global/axios";

const cron = require('cron-parser');

const styles = theme => ({
});

const CRON_TYPES = {
    MINS: 0,
    HOURS: 1,
    DAYS: 2,
    WEEKS: 3,
    MONTHS: 4,
    ADVANCED: 5,
};

class EditAnnouncement extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            message: '',
            cron: '*/5 * * * *',
            cronType: CRON_TYPES.MINS,
            saving: false,
            cronMin: 5,
            cronHour: 1,
            cronDays: [],
            cronMonths: 1,
        }
    }

    componentDidMount() {
    }

    componentDidUpdate(prevProps) {
        if (this.props.announcement && !prevProps.announcement) {
            this.parseCron(this.props.announcement.cron, this.props.announcement.type ?? 0);

            this.setState({
                message: this.props.announcement.message,
                cron: this.props.announcement.cron,
                cronType: this.props.announcement.type ?? 0,
            });
        }

        if (!this.props.announcement && prevProps.announcement) {
            this.setState({
                message: '',
                cron: '*/5 * * * *',
                cronType: CRON_TYPES.MINS,
                cronMin: 5,
                cronHour: 1,
                cronDays: [],
                cronMonths: 1,
            });
        }
    }

    parseCron = (cron, type) => {
        let cronMin, cronHour, cronDays, cronMonths;
        let p = cron.split(' ');

        switch (type) {
            case CRON_TYPES.MINS:
                cronMin = parseInt(p[0].replace('*/', ''));
                this.setState({ cronMin });
                break;
            case CRON_TYPES.HOURS:
                cronMin = parseInt(p[0]);
                cronHour = parseInt(p[1].replace('*/', ''));
                this.setState({ cronMin, cronHour });
                break;
            case CRON_TYPES.DAYS:
                cronMin = parseInt(p[0]);
                cronHour = parseInt(p[1]);
                this.setState({ cronMin, cronHour });
                break;
            case CRON_TYPES.WEEKS:
                cronMin = parseInt(p[0]);
                cronHour = parseInt(p[1]);
                cronDays = p[4].split(',');
                this.setState({ cronMin, cronHour, cronDays });
            case CRON_TYPES.MONTHS:
                cronMin = parseInt(p[0]);
                cronHour = parseInt(p[1]);
                cronDays = p[4].split(',');
                cronMonths = parseInt(p[3].replace('*/', ''));
                this.setState({ cronMin, cronHour, cronDays, cronMonths });
            case CRON_TYPES.ADVANCED:
                this.setState({ cron });
        }
    }

    builCron = () => {
        const { cronType, cronMin, cronHour, cronDays, cronMonths, cron } = this.state;

        switch (cronType) {
            case CRON_TYPES.MINS:
                return `*/${cronMin} * * * *`;
            case CRON_TYPES.HOURS:
                return `${cronMin} */${cronHour} * * *`;
            case CRON_TYPES.DAYS:
                return `${cronMin} ${cronHour} * * *`;
            case CRON_TYPES.WEEKS:
                return `${cronMin} ${cronHour} * * ${cronDays.join(',')}`;
            case CRON_TYPES.MONTHS:
                return `${cronMin} ${cronHour} * */${cronMonths} ${cronDays.join(',')}`;
            case CRON_TYPES.ADVANCED:
                return cron;
        }

        return '';
    }

    onSave = async () => {
        this.setState({ saving: true });

        const announcement = {
            message: this.state.message,
            cron: this.builCron(),
            is_active: Boolean(this.props.announcement?.is_active),
            type: this.state.cronType,
        };

        if (!announcement.message || !announcement.cron) {
            this.props.setNotification({ message: 'Lütfen tüm alanları doldurun.', severity: GlobalTypes.NOTIFY_ERR });
            this.setState({ saving: false });
            return;
        }

        if (this.props.announcement) {
            announcement.id = this.props.announcement.id;
        }

        try {
            await AxiosClient().post(`${process.env.REACT_APP_API_URL}/v1/admin/global/announcements`, announcement);

            this.props.setNotification({ message: `Duyuru ${this.props.announcement ? 'düzenlendi.' : 'eklendi.'}`, severity: GlobalTypes.NOTIFY_SUCC });
            this.props.onClose(true);

        } catch (error) {
            console.error(error);
            this.props.setNotification({ message: 'Bir hata oluştu. Lütfen tekrar deneyin.', severity: GlobalTypes.NOTIFY_ERR });
        } finally {
            this.setState({ saving: false });
        }
    }

    onCronTypeChanged = (cronType) => {
        if (cronType === (this.props.announcement?.type ?? 0)) {
            this.parseCron(this.props.announcement?.cron, cronType);
            this.setState({ cronType });
            return;
        }

        switch (cronType) {
            case CRON_TYPES.MINS:
                this.setState({ cronType, cronMin: 5 });
                break;
            case CRON_TYPES.HOURS:
                this.setState({ cronType, cronHour: 1, cronMin: 0 });
                break;
            case CRON_TYPES.DAYS:
                this.setState({ cronType, cronHour: 9, cronMin: 0 });
                break;
            case CRON_TYPES.WEEKS:
                this.setState({ cronType, cronHour: 9, cronMin: 0, cronDays: [] });
                break;
            case CRON_TYPES.MONTHS:
                this.setState({ cronType, cronHour: 9, cronMin: 0, cronDays: [], cronMonths: 1 });
                break;
            case CRON_TYPES.ADVANCED:
                this.setState({ cronType });
                break;
        }
    }

    renderMinutely = () => {
        return (
            <Box className="flex items-center gap-x-4">
                <Typography>Her</Typography>
                <TextField margin="dense"
                    value={this.state.cronMin} sx={{
                        borderTopRightRadius: '0 !important',
                        borderBottomRightRadius: '0 !important',
                        margin: 0,
                    }}
                    InputProps={{
                        classes: {
                            input: "h-8 sm:h-12",
                            root: "h-8 sm:h-12",
                        },
                        inputProps: { min: 1, max: 59 },
                    }}
                    label="Dakika"
                    type="number"
                    onChange={(e) => this.setState({ cronMin: e.target.value })}
                />
            </Box>
        );
    }

    renderHourly = () => {
        return (
            <Box className="flex items-center gap-x-4">
                <Typography>Her</Typography>
                <TextField margin="dense"
                    value={this.state.cronHour} sx={{
                        borderTopRightRadius: '0 !important',
                        borderBottomRightRadius: '0 !important',
                        margin: 0,
                    }}
                    InputProps={{
                        classes: {
                            input: "h-8 sm:h-12",
                            root: "h-8 sm:h-12",
                        },
                        inputProps: { min: 1, max: 23 },
                    }}
                    label="Saat"
                    type="number"
                    onChange={(e) => this.setState({ cronHour: e.target.value })}
                />
                <TextField margin="dense"
                    value={this.state.cronMin} sx={{
                        borderTopRightRadius: '0 !important',
                        borderBottomRightRadius: '0 !important',
                        margin: 0,
                    }}
                    InputProps={{
                        classes: {
                            input: "h-8 sm:h-12",
                            root: "h-8 sm:h-12",
                        },
                        inputProps: { min: 0, max: 59 },
                    }}
                    label="Dakika"
                    type="number"
                    onChange={(e) => this.setState({ cronMin: e.target.value })}
                />
            </Box>
        );
    }

    renderDaily = () => {
        return (
            <Box className="flex items-center gap-x-4">
                <Typography>Her gün</Typography>
                <TextField margin="dense"
                    value={this.state.cronHour.toString().padStart(2, '0')} sx={{
                        borderTopRightRadius: '0 !important',
                        borderBottomRightRadius: '0 !important',
                        margin: 0,
                    }}
                    InputProps={{
                        classes: {
                            input: "h-8 sm:h-12",
                            root: "h-8 sm:h-12",
                        },
                        inputProps: { min: 0, max: 23 },
                    }}
                    label="Saat"
                    type="number"
                    onChange={(e) => this.setState({ cronHour: e.target.value })}
                />
                <Typography>:</Typography>
                <TextField margin="dense"
                    value={this.state.cronMin.toString().padStart(2, '0')} sx={{
                        borderTopRightRadius: '0 !important',
                        borderBottomRightRadius: '0 !important',
                        margin: 0,
                    }}
                    InputProps={{
                        classes: {
                            input: "h-8 sm:h-12",
                            root: "h-8 sm:h-12",
                        },
                        inputProps: { min: 0, max: 59 },
                    }}
                    label="Dakika"
                    type="number"
                    onChange={(e) => this.setState({ cronMin: e.target.value })}
                />
            </Box>
        );
    }

    renderWeekly = () => {
        const addDay = (day) => {
            const { cronDays } = this.state;

            if (!cronDays.find(d => d === day)) {
                {
                    cronDays.push(day);
                }

                this.setState({ cronDays });
            }
        }

        const removeDay = (day) => {
            const { cronDays } = this.state;

            const index = cronDays.findIndex(d => d === day);
            if (index >= 0) {
                cronDays.splice(index, 1);
                this.setState({ cronDays });
            }
        }

        const days = [
            { name: 'Pazartesi', value: 'MON' },
            { name: 'Salı', value: 'TUE' },
            { name: 'Çarşamba', value: 'WED' },
            { name: 'Perşembe', value: 'THU' },
            { name: 'Cuma', value: 'FRI' },
            { name: 'Cumartesi', value: 'SAT' },
            { name: 'Pazar', value: 'SUN' },
        ];

        return (
            <Box className="flex flex-col gap-y-4">
                <FormGroup>
                    <Grid container spacing={1}>
                        {days.map((day, index) => (
                            <Grid key={day.value} item xs={12} sm={6} md={4} lg={3}>
                                <Box>
                                    <FormControlLabel
                                        control={
                                            <Checkbox checked={this.state.cronDays.find(d => d === day.value)}
                                                onChange={(e) => e.target.checked ? addDay(day.value) : removeDay(day.value)}
                                            />
                                        }
                                        label={day.name}
                                    />
                                </Box>
                            </Grid>
                        ))}
                    </Grid>
                </FormGroup>

                <Box className="flex justify-center items-center gap-x-4">
                    <TextField margin="dense"
                        value={this.state.cronHour.toString().padStart(2, '0')} sx={{
                            borderTopRightRadius: '0 !important',
                            borderBottomRightRadius: '0 !important',
                            margin: 0,
                        }}
                        InputProps={{
                            classes: {
                                input: "h-8 sm:h-12",
                                root: "h-8 sm:h-12",
                            },
                            inputProps: { min: 0, max: 23 },
                        }}
                        label="Saat"
                        type="number"
                        onChange={(e) => this.setState({ cronHour: e.target.value })}
                    />
                    <Typography>:</Typography>
                    <TextField margin="dense"
                        value={this.state.cronMin.toString().padStart(2, '0')} sx={{
                            borderTopRightRadius: '0 !important',
                            borderBottomRightRadius: '0 !important',
                            margin: 0,
                        }}
                        InputProps={{
                            classes: {
                                input: "h-8 sm:h-12",
                                root: "h-8 sm:h-12",
                            },
                            inputProps: { min: 0, max: 59 },
                        }}
                        label="Dakika"
                        type="number"
                        onChange={(e) => this.setState({ cronMin: e.target.value })}
                    />
                </Box>
            </Box>
        );
    }

    renderMonthly = () => {
        const addDay = (day) => {
            const { cronDays } = this.state;

            if (!cronDays.find(d => d === day)) {
                {
                    cronDays.push(day);
                }

                this.setState({ cronDays });
            }
        }

        const removeDay = (day) => {
            const { cronDays } = this.state;

            const index = cronDays.findIndex(d => d === day);
            if (index >= 0) {
                cronDays.splice(index, 1);
                this.setState({ cronDays });
            }
        }

        const days = [
            { name: 'Pazartesi', value: 'MON' },
            { name: 'Salı', value: 'TUE' },
            { name: 'Çarşamba', value: 'WED' },
            { name: 'Perşembe', value: 'THU' },
            { name: 'Cuma', value: 'FRI' },
            { name: 'Cumartesi', value: 'SAT' },
            { name: 'Pazar', value: 'SUN' },
        ];

        return (
            <Box className="flex flex-col gap-y-4">
                <Box className="flex justify-center items-center gap-x-4">
                    <Typography>Her</Typography>
                    <TextField margin="dense"
                        value={this.state.cronMonths} sx={{
                            borderTopRightRadius: '0 !important',
                            borderBottomRightRadius: '0 !important',
                            margin: 0,
                        }}
                        InputProps={{
                            classes: {
                                input: "h-8 sm:h-12",
                                root: "h-8 sm:h-12",
                            },
                            inputProps: { min: 1, max: 12 },
                        }}
                        label="Ay"
                        type="number"
                        onChange={(e) => this.setState({ cronMonths: e.target.value })}
                    />
                </Box>

                <FormGroup>
                    <Grid container spacing={1}>
                        {days.map((day, index) => (
                            <Grid key={day.value} item xs={12} sm={6} md={4} lg={3}>
                                <Box>
                                    <FormControlLabel
                                        control={
                                            <Checkbox checked={this.state.cronDays.find(d => d === day.value)}
                                                onChange={(e) => e.target.checked ? addDay(day.value) : removeDay(day.value)}
                                            />
                                        }
                                        label={day.name}
                                    />
                                </Box>
                            </Grid>
                        ))}
                    </Grid>
                </FormGroup>

                <Box className="flex justify-center items-center gap-x-4">
                    <TextField margin="dense"
                        value={this.state.cronHour.toString().padStart(2, '0')} sx={{
                            borderTopRightRadius: '0 !important',
                            borderBottomRightRadius: '0 !important',
                            margin: 0,
                        }}
                        InputProps={{
                            classes: {
                                input: "h-8 sm:h-12",
                                root: "h-8 sm:h-12",
                            },
                            inputProps: { min: 0, max: 23 },
                        }}
                        label="Saat"
                        type="number"
                        onChange={(e) => this.setState({ cronHour: e.target.value })}
                    />
                    <Typography>:</Typography>
                    <TextField margin="dense"
                        value={this.state.cronMin.toString().padStart(2, '0')} sx={{
                            borderTopRightRadius: '0 !important',
                            borderBottomRightRadius: '0 !important',
                            margin: 0,
                        }}
                        InputProps={{
                            classes: {
                                input: "h-8 sm:h-12",
                                root: "h-8 sm:h-12",
                            },
                            inputProps: { min: 0, max: 59 },
                        }}
                        label="Dakika"
                        type="number"
                        onChange={(e) => this.setState({ cronMin: e.target.value })}
                    />
                </Box>
            </Box>
        );
    }

    renderAdvanced = () => {
        return (
            <Box className="flex items-center gap-x-4">
                <TextField margin="dense" fullWidth
                    value={this.state.cron} sx={{
                        borderTopRightRadius: '0 !important',
                        borderBottomRightRadius: '0 !important',
                        margin: 0,
                    }}
                    InputProps={{
                        classes: {
                            input: "h-8 sm:h-12",
                            root: "h-8 sm:h-12",
                        }
                    }}
                    label="Cron Expression"
                    onChange={(e) => this.setState({ cron: e.target.value })}
                />
            </Box>
        );
    }

    renderCron = () => {
        const { cronType } = this.state;

        switch (cronType) {
            case CRON_TYPES.MINS:
                return this.renderMinutely();
            case CRON_TYPES.HOURS:
                return this.renderHourly();
            case CRON_TYPES.DAYS:
                return this.renderDaily();
            case CRON_TYPES.WEEKS:
                return this.renderWeekly();
            case CRON_TYPES.MONTHS:
                return this.renderMonthly();
            case CRON_TYPES.ADVANCED:
                return this.renderAdvanced();
        }
    }

    editDialog = () => {
        const { classes } = this.props;

        return (
            <Dialog open={this.props.open} onClose={() => this.props.onClose()}>
                <DialogTitle>
                    <Typography className={classes.title} sx={{ color: '#413067' }}>
                        {`Duyuru ${this.props.announcement ? 'Düzenle' : 'Ekle'}`}
                    </Typography>
                </DialogTitle>
                <DialogContent>
                    <Table>
                        <TableRow>
                            <TableCell className="!border-0 !p-2 min-w-32">
                                <Typography className={classes.title} sx={{ color: '#413067' }}>
                                    Mesaj
                                </Typography>
                            </TableCell>
                            <TableCell className="!border-0 !p-2">
                                <TextField margin="dense" fullWidth
                                    value={this.state.message} sx={{
                                        borderTopRightRadius: '0 !important',
                                        borderBottomRightRadius: '0 !important',
                                        margin: 0,
                                    }}
                                    InputProps={{
                                        classes: {
                                            input: "h-8 sm:h-12",
                                            root: "h-8 sm:h-12",
                                        }
                                    }}
                                    onChange={(e) => this.setState({ message: e.target.value })}
                                />
                            </TableCell>
                        </TableRow>
                    </Table>

                    <Tabs value={this.state.cronType} onChange={(e, v) => this.onCronTypeChanged(v)} variant="fullWidth"
                        sx={{
                            width: 'fit-content',
                            '& .MuiTabs-flexContainer': { columnGap: 0 },
                            '& .MuiTabs-indicator': { backgroundColor: "#FF9559", height: '4px' },
                            '& .MuiTab-root': { color: '#505050 !important', flex: 1, padding: 0, textTransform: 'none' },
                            '& .Mui-selected': { color: '#505050 !important', backgroundColor: '#FFF3EC' },
                        }}
                    >
                        <Tab label="Dakikalık" value={CRON_TYPES.MINS} />
                        <Tab label="Saatlik" value={CRON_TYPES.HOURS} />
                        <Tab label="Günlük" value={CRON_TYPES.DAYS} />
                        <Tab label="Haftalık" value={CRON_TYPES.WEEKS} />
                        <Tab label="Aylık" value={CRON_TYPES.MONTHS} />
                        <Tab label="Gelişmiş" value={CRON_TYPES.ADVANCED} />
                    </Tabs>

                    <Box className="flex pt-4 justify-center">
                        {this.renderCron()}
                    </Box>
                </DialogContent>

                <DialogActions>
                    <Button onClick={() => this.props.onClose()} variant="contained" color="error">
                        İptal
                    </Button>
                    <Button onClick={this.onSave} variant="contained" color="primary"
                        className="!text-white"
                        disabled={!this.state.message || !this.state.cron || this.state.saving}
                    >
                        Kaydet
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }

    render() {
        return this.editDialog();
    }
}

EditAnnouncement.propTypes = {
    classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
    return {
    }
};

export default withStyles(styles)(connect(mapStateToProps, {
    setNotification
})(EditAnnouncement));
