import React from "react";
import { Route, Routes, unstable_HistoryRouter as HistoryRouter } from 'react-router-dom';
import Admin from './components/Admin';
import Chat from './components/Chat';
import Forget from './components/Forget';
import Lobby from './components/Lobby';
import Login from './components/Login';
import Reset from './components/Reset';
import Table from './components/Table';
import DeleteAccount from './components/Delete';
import { connect } from "react-redux";
import HeaderWrapper from "./HeaderWrapper";
import Logs from "./components/Logs";
import Landing from "./components/Landing";
import Protected from "./Protected";
import { getPath } from "./global/route";
import PrivacyPolicy from "./components/Header/PrivacyPolicy";


class Container extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    withHeader = (children) => {
        const { history } = this.props;

        return (
            <>
                <Protected history={history} {...this.props}>
                    <HeaderWrapper {...this.props}>
                        {children}
                    </HeaderWrapper >
                </Protected>
            </>
        );
    }

    render() {
        const { history } = this.props;

        return (
            <>
                <HistoryRouter location={history.location} history={history}>
                    <Routes>
                        <Route path={getPath("/")} element={<Landing history={history} {...this.props} />} />
                        <Route path={getPath("/login")} element={<Login history={history} {...this.props} />} />
                        <Route path={getPath("/sifremi.unuttum")} element={<Forget history={history} {...this.props} />} />
                        <Route path={getPath("/sifremi.sifirla")} element={<Reset history={history} {...this.props} />} />
                        <Route path={getPath("/kullanici.sil")} element={<DeleteAccount history={history} {...this.props} />} />
                        <Route path={getPath("/privacy")} element={<PrivacyPolicy open={true} />} />
                        <Route path={getPath("/salon")} element={this.withHeader(<Lobby history={history} {...this.props} />)} />
                        <Route path={getPath("/admin/:cat?")} element={this.withHeader(<Admin history={history} {...this.props} />)} />
                        <Route path={getPath("/masa/:id")} element={this.withHeader(<Table history={history} {...this.props} />)} />
                        <Route path={getPath("/sohbet")} element={this.withHeader(<Chat history={history} {...this.props} />)} />
                        <Route path={getPath("/admin/log/:cat?")} element={this.withHeader(<Logs history={history} {...this.props} />)} />
                    </Routes>
                </HistoryRouter>
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
    };
};

Container.propTypes = {
};

export default connect(mapStateToProps, {})(Container);