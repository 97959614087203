import React from "react";
import { connect } from "react-redux";
import { withStyles } from "@mui/styles";
import PropTypes from "prop-types";
import axios from "axios";
import { GlobalTypes } from "../../../action_types";
import { setNotification } from "../../../actions/global";
import debounce from "lodash.debounce";
import {
    Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle,
    FormControl, FormControlLabel, IconButton, InputAdornment, Radio, RadioGroup,
    SvgIcon, TextField, Tooltip, Typography, Table, TableBody, TableCell, TableHead,
    TableRow, Pagination, Box, Card, CardContent
} from "@mui/material";
import { mdiMagnify, mdiPlusCircle } from "@mdi/js";
import { Delete, Edit } from "@mui/icons-material";
import EditUserColor from "./EditUserColor";
import clsx from "clsx";
import { AxiosClient } from "../../../global/axios";


const styles = theme => ({
    table: {
        border: '2px solid #f0f0f0',
        userSelect: 'text !important',
    },
    tCell: {
        border: 0,
        padding: 32,
        verticalAlign: 'top',
    },
    cellHeader: {
        fontFamily: 'Roboto Slab',
        width: 180,
        fontWeight: 700,
    },
    cellContent: {
        color: '#6b7280 !important',
        fontFamily: 'Roboto Slab !important',
    },
    text: {
        fontWeight: '400 !important',
        color: 'var(--white)',
    },
    inputIcon: {
        marginRight: '8px',
        color: '#31204A !important'
    },
});

class UserColors extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            filter: 'all',
            search: '',
            page: 1,
            size: 10,
            userColors: [],
            totalUserColors: 0,
            deleteUserColor: null,
            edit: false,
            editUserColor: null,
        }

    }

    componentDidMount() {
        this.listUserColors();
    }

    componentDidUpdate(prevProps) {
    }

    handleDeleteUserColor = (userColor) => {
        this.setState({ deleteUserColor: userColor });
    }

    listUserColors = async () => {
        try {
            const { search, page, size, filter } = this.state;
            const resp = await AxiosClient().get(`${process.env.REACT_APP_API_URL}/v1/admin/users/colors?search=${search}&page=${page}&size=${size}&filter=${filter}`);

            this.setState({ userColors: resp.data.data ?? [], totalUserColors: resp.data.total });

        } catch (err) {
            console.error(err);
            this.props.setNotification({ message: 'Bir hata oluştu. Lütfen tekrar deneyiniz.', severity: GlobalTypes.NOTIFY_ERR });
        }
    }

    debounceSearchColors = debounce(() => this.setState({ page: 1 }, () => this.listUserColors()), 500);

    searchChanged = (e) => {
        this.setState({ search: e.target.value });

        this.debounceSearchColors();
    }

    deleteUserColor = async () => {
        try {
            const { deleteUserColor } = this.state;

            if (!deleteUserColor) return;

            await AxiosClient().delete(`${process.env.REACT_APP_API_URL}/v1/admin/users/${deleteUserColor.id}/colors`);

            this.props.setNotification({ message: 'Kullanıcı rengi başarıyla silindi.', severity: GlobalTypes.NOTIFY_SUCC });

        } catch (err) {
            console.error(err);
            this.props.setNotification({ message: 'Bir hata oluştu. Lütfen tekrar deneyiniz.', severity: GlobalTypes.NOTIFY_ERR });

        } finally {
            this.setState({ deleteUserColor: null }, () => this.listUserColors());
        }
    }

    onAddUserColor = () => {
        this.setState({ edit: true, editUserColor: null });
    }

    handleEditUserColor = (userColor) => {
        this.setState({ edit: true, editUserColor: userColor });
    }

    // UI PART

    deleteUserColorDialog = () => {
        const { deleteUserColor } = this.state;

        const onClose = () => this.setState({ deleteUserColor: null });

        return (
            <Dialog open={deleteUserColor !== null} onClose={onClose}>
                <DialogTitle>Renk Sil</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {deleteUserColor?.username} kullanıcısının rengini silmek istediğinizden emin misiniz?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onClose} variant="contained" color="error">
                        İptal
                    </Button>
                    <Button onClick={() => this.deleteUserColor()} variant="contained" color="primary" autoFocus className="!text-white">
                        Sil
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }

    tableView = () => {
        const { classes } = this.props;
        const { userColors } = this.state;

        if (userColors.length === 0) {
            return (
                <Box className="!hidden lg:!flex justify-center items-center h-[300px]">
                    <Typography className={"!text-xs md:!text-sm lg:!text-base"}>Sonuç bulunamadı.</Typography>
                </Box>
            );
        }

        return (
            <Table className={clsx(
                classes.table,
                "!hidden lg:!table",
            )}>
                <TableHead>
                    <TableRow>
                        <TableCell className={classes.tCell} sx={{ borderBottom: '2px solid #f0f0f0', minWidth: 0, maxWidth: 0 }}>
                            <Typography className={clsx(classes.cellHeader, "!text-xs md:!text-sm lg:!text-base")}>
                                Nick
                            </Typography>
                        </TableCell>
                        <TableCell className={classes.tCell} sx={{ borderBottom: '2px solid #f0f0f0', minWidth: 0, maxWidth: 0 }}>
                            <Typography className={clsx(classes.cellHeader, "!text-xs md:!text-sm lg:!text-base")}>
                                Ekleyen
                            </Typography>
                        </TableCell>
                        <TableCell className={classes.tCell} sx={{ borderBottom: '2px solid #f0f0f0', minWidth: 0, maxWidth: 0 }}>
                            <Typography className={clsx(classes.cellHeader, "!text-xs md:!text-sm lg:!text-base")}>
                                Eklenen Tarih
                            </Typography>
                        </TableCell>
                        <TableCell className={classes.tCell} sx={{ borderBottom: '2px solid #f0f0f0', minWidth: 0, maxWidth: 0 }}>
                            <Typography className={clsx(classes.cellHeader, "!text-xs md:!text-sm lg:!text-base")}>
                                Bitiş Tarihi
                            </Typography>
                        </TableCell>
                        <TableCell className={classes.tCell} sx={{ borderBottom: '2px solid #f0f0f0', minWidth: 0, maxWidth: 0 }}>
                            <Typography className={clsx(classes.cellHeader, "!text-xs md:!text-sm lg:!text-base")}>
                                Aksiyon
                            </Typography>
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {userColors.map((color) => {
                        return (
                            <TableRow key={color.id}>
                                <TableCell>
                                    <Typography className={clsx(classes.cellContent, "!text-xs md:!text-sm lg:!text-base")}>
                                        {color.username}
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography className={clsx(classes.cellContent, "!text-xs md:!text-sm lg:!text-base")}>
                                        {color.style_created_by}
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography className={clsx(classes.cellContent, "!text-xs md:!text-sm lg:!text-base")}>
                                        {new Date(color.style_updated_at).toLocaleDateString()}
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography className={clsx(classes.cellContent, "!text-xs md:!text-sm lg:!text-base")}>
                                        {color.svu ? new Date(color.svu).toLocaleDateString() : 'Süresiz'}
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Tooltip title="Düzenle" placement="top">
                                        <IconButton onClick={() => this.handleEditUserColor(color)}>
                                            <Edit fontSize="small" />
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip title="Sil" placement="top">
                                        <IconButton onClick={() => this.handleDeleteUserColor(color)}>
                                            <Delete fontSize="small" />
                                        </IconButton>
                                    </Tooltip>
                                </TableCell>
                            </TableRow>
                        );
                    })}

                </TableBody>
            </Table>
        );
    }

    mobileView = () => {
        const { classes } = this.props;
        const { userColors } = this.state;

        if (userColors.length === 0) {
            return (
                <Box className="flex lg:hidden justify-center items-center">
                    <Typography className={"!text-xs md:!text-sm lg:!text-base"}>Sonuç bulunamadı.</Typography>
                </Box>
            );
        }

        return (
            <Box className="flex flex-col gap-y-2 lg:hidden">
                {userColors.map((color) => {
                    return (

                        <Card key={color.id} sx={{ borderRadius: 4, border: '1px solid #c4c4c4', boxShadow: 0 }}>
                            <CardContent>
                                <Table>
                                    <TableRow>
                                        <TableCell className="!p-2">
                                            <Typography className={clsx(classes.cellContent, "!text-xs md:!text-sm lg:!text-base")}>
                                                Nick
                                            </Typography>
                                        </TableCell>
                                        <TableCell className="!p-2">
                                            <Typography className={clsx(classes.cellContent, "!text-xs md:!text-sm lg:!text-base")}>
                                                {color.username}
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell className="!p-2">
                                            <Typography className={clsx(classes.cellContent, "!text-xs md:!text-sm lg:!text-base")}>
                                                Ekleyen
                                            </Typography>
                                        </TableCell>
                                        <TableCell className="!p-2">
                                            <Typography className={clsx(classes.cellContent, "!text-xs md:!text-sm lg:!text-base")}>
                                                {color.style_created_by}
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell className="!p-2">
                                            <Typography className={clsx(classes.cellContent, "!text-xs md:!text-sm lg:!text-base")}>
                                                Eklenen Tarih
                                            </Typography>
                                        </TableCell>
                                        <TableCell className="!p-2">
                                            <Typography className={clsx(classes.cellContent, "!text-xs md:!text-sm lg:!text-base")}>
                                                {new Date(color.style_updated_at).toLocaleDateString()}
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell className="!p-2">
                                            <Typography className={clsx(classes.cellContent, "!text-xs md:!text-sm lg:!text-base")}>
                                                Bitiş Tarihi
                                            </Typography>
                                        </TableCell>
                                        <TableCell className="!p-2">
                                            <Typography className={clsx(classes.cellContent, "!text-xs md:!text-sm lg:!text-base")}>
                                                {color.svu ? new Date(color.svu).toLocaleDateString() : 'Süresiz'}
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell className="!p-2">
                                            <Typography className={clsx(classes.cellContent, "!text-xs md:!text-sm lg:!text-base")}>
                                                Aksiyon
                                            </Typography>
                                        </TableCell>
                                        <TableCell className="!p-2">
                                            <Tooltip title="Düzenle" placement="top">
                                                <IconButton className="!p-[2px] !mr-2" onClick={() => this.handleEditUserColor(color)}>
                                                    <Edit className="!text-[16px]" />
                                                </IconButton>
                                            </Tooltip>
                                            <Tooltip title="Sil" placement="top">
                                                <IconButton className="!p-[2px] !mr-2" onClick={() => this.handleDeleteUserColor(color)}>
                                                    <Delete className="!text-[16px]" />
                                                </IconButton>
                                            </Tooltip>
                                        </TableCell>
                                    </TableRow>
                                </Table>
                            </CardContent>
                        </Card>
                    );
                })}
            </Box>
        );
    }

    render() {
        const { classes } = this.props;
        const { userColors, totalUserColors } = this.state;

        return (
            <Box margin={1} display={"flex"} flexDirection={"column"} rowGap={1}>

                <Box className="flex flex-col md:flex-row gap-x-2 justify-between items-start md:items-center">
                    <FormControl>
                        <RadioGroup value={this.state.filter}>
                            <Box className="px-2 py-1">
                                <FormControlLabel value={"all"} control={
                                    <Radio onChange={(e) => this.setState({ filter: "all", page: 1 }, () => this.listUserColors())}
                                        checked={this.state.filter === "all"}
                                    />
                                } label={
                                    <Typography className="!text-xs md:!text-sm lg:!text-base">
                                        Tümü
                                    </Typography>
                                }
                                />

                                <FormControlLabel value={"unlimited"} control={
                                    <Radio onChange={(e) => this.setState({ filter: "unlimited", page: 1 }, () => this.listUserColors())}
                                        checked={this.state.filter === "unlimited"}
                                    />
                                } label={
                                    <Typography className="!text-xs md:!text-sm lg:!text-base">
                                        Süresizler
                                    </Typography>
                                }
                                />

                                <FormControlLabel value={"limited"} control={
                                    <Radio onChange={(e) => this.setState({ filter: "limited", page: 1 }, () => this.listUserColors())}
                                        checked={this.state.filter === "limited"}
                                    />
                                } label={
                                    <Typography className="!text-xs md:!text-sm lg:!text-base">
                                        Süreliler
                                    </Typography>
                                }
                                />

                                <FormControlLabel value={"expires_soon"} control={
                                    <Radio onChange={(e) => this.setState({ filter: "expires_soon", page: 1 }, () => this.listUserColors())}
                                        checked={this.state.filter === "expires_soon"}
                                    />
                                } label={
                                    <Typography className="!text-xs md:!text-sm lg:!text-base">
                                        Yaklaşanlar
                                    </Typography>
                                }
                                />

                                <FormControlLabel value={"expired"} control={
                                    <Radio onChange={(e) => this.setState({ filter: "expired", page: 1 }, () => this.listUserColors())}
                                        checked={this.state.filter === "expired"}
                                    />
                                } label={
                                    <Typography className="!text-xs md:!text-sm lg:!text-base">
                                        Geçenler
                                    </Typography>
                                }
                                />
                            </Box>
                        </RadioGroup>
                    </FormControl>

                    <Button variant="contained" sx={{ backgroundImage: "linear-gradient(to bottom, #2FB16B 50%, #168549)" }}
                        onClick={this.onAddUserColor}
                        className="h-9"
                    >
                        <Box display="flex" columnGap="8px">
                            <SvgIcon fontSize="small" sx={{ margin: 'auto' }}><path d={mdiPlusCircle} color="var(--white)" /></SvgIcon>
                            <Typography className={clsx(classes.text, "!text-xs md:!text-sm lg:!text-base")} alignSelf={"center"}>Ekle</Typography>
                        </Box>
                    </Button>
                </Box>

                <TextField variant="outlined" placeholder="Kişi Ara" fullWidth margin="dense"
                    InputProps={{
                        classes: {
                            input: "!text-xs md:!text-sm lg:!text-base",
                            root: "p-1 md:p-2"
                        },
                        startAdornment: (
                            <InputAdornment position="start">
                                <SvgIcon fontSize='small'>
                                    <path d={mdiMagnify} />
                                </SvgIcon>
                            </InputAdornment>
                        )
                    }}
                    value={this.state.search}
                    onChange={this.searchChanged}
                    sx={{
                        '& .MuiInputBase-root': { p: 0, px: 2 },
                        '& .MuiInputBase-root::before': { borderBottom: '1px solid #E3E3E3 !important' },
                        '& .MuiInputBase-root::after': { borderBottom: '1px solid #E3E3E3 !important' },
                    }}
                />

                {this.tableView()}
                {this.mobileView()}

                <Box className="flex justify-end">
                    <Pagination count={parseInt(Math.ceil(totalUserColors / this.state.size))} page={this.state.page}
                        variant="outlined" shape="rounded" color="primary"
                        onChange={(e, p) => this.setState({ page: p }, () => this.listUserColors())}
                    />
                </Box>

                <EditUserColor open={this.state.edit} userColor={this.state.editUserColor}
                    onClose={(refresh) => this.setState({ edit: false, editUserColor: null }, () => refresh && this.listUserColors())}
                />

                {this.deleteUserColorDialog()}
            </Box>
        );
    }
}

UserColors.propTypes = {
    classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
    return {
    }
};

export default withStyles(styles)(connect(mapStateToProps, {
    setNotification,
})(UserColors));
