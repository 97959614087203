import { LobbyTypes } from '../../action_types';
import { addChatTab } from '../../actions/global';
import { addUnreadChatMessage } from '../../actions/lobby';
import { store } from '../../index';

const initialState = {
    users: [],
    usersById: {},
    messages: {},
    unreadMessages: {},
    tableIds: [],
    tables: {},
    table: null,
    rejoinTable: null,
}

const lobby = (state = initialState, action) => {
    switch (action.type) {

        case LobbyTypes.SET_LOBBY_USERS: {
            const usersById = action.users.reduce((acc, user) => {
                acc[user.id] = user;
                return acc;
            }, {});

            return {
                ...state,
                users: action.users.map(user => user.id),
                usersById,
            };
        }

        case LobbyTypes.SET_LOBBY_USER_STATUS: {
            const usersById = { ...state.usersById };
            const user = usersById[action.id];
            if (user) {
                user.status = action.status;
            }

            return {
                ...state,
                usersById: usersById,
            };
        }

        case LobbyTypes.USER_CONNECTED: {
            if (!action.user) {
                return state;
            }

            const users = [...state.users];
            const usersById = { ...state.usersById };

            if (!users.includes(action.user.id)) {
                users.push(action.user.id);
            }

            usersById[action.user.id] = action.user;

            return {
                ...state,
                users,
                usersById,
            };
        }

        case LobbyTypes.USER_DISCONNECTED: {
            const usersById = { ...state.usersById };
            delete usersById[action.id];

            return {
                ...state,
                usersById,
                users: state.users.filter(id => id !== action.id),
            };
        }

        case LobbyTypes.SET_LOBBY_TABLES: {
            return {
                ...state,
                tableIds: action.tables.map(table => table.id),
                tables: action.tables.reduce((acc, table) => {
                    acc[table.id] = table;
                    return acc;
                }, {}),
            };
        }

        case LobbyTypes.SET_TABLE: {

            return {
                ...state,
                table: action.table,
            };
        }

        case LobbyTypes.ADD_CHAT_MESSAGE: {
            const messages = { ...state.messages };
            if (!(action.message.room in messages)) {
                messages[action.message.room] = [];
            }

            if (action.message?.room === "salon") {
                setTimeout(() => {
                    const maxMessageCount = store.getState().app.global.generalSettings.max_message_count;

                    if (messages[action.message.room].length > maxMessageCount) {
                        messages[action.message.room] = messages[action.message.room].slice(messages[action.message.room].length - maxMessageCount);
                    }
                }, 100);
            }


            messages[action.message.room].push(action.message);
            messages[action.message.room] = messages[action.message.room].filter((v, i, a) => a.findIndex(t => (t.id === v.id)) === i);
            setTimeout(() => {
                const room = action.message.room;
                const name = room ? state.usersById[room]?.username : "Sistem";
                if (name) {
                    store.dispatch(addChatTab(room, name));
                }

                if (store.getState().app.global.selectedTab !== room) {
                    store.dispatch(addUnreadChatMessage(room));
                }
            }, 100);

            return {
                ...state,
                messages,
            };
        }

        case LobbyTypes.ADD_CHAT_HISTORY: {
            const messages = { ...state.messages };

            if (!(action.id in messages)) {
                messages[action.id] = [];
            }

            const mapHistory = action.messages?.map(message => {
                return {
                    id: message.id,
                    sender: message.username,
                    message: message.message,
                    ts: Date.parse(message.created_at),
                    room: message.room,
                    present: false,
                    sender_id: message.user_id,
                    color: null,
                    isOldMessage: true,
                };
            }) ?? [];

            const history = [...mapHistory, ...messages[action.id]];
            const uniqueHistory = history.filter((v, i, a) => a.findIndex(t => (t.id === v.id)) === i);
            messages[action.id] = uniqueHistory.sort((a, b) => a.ts - b.ts);

            return {
                ...state,
                messages,
            };
        }

        case LobbyTypes.ADD_UNREAD_CHAT_MESSAGE: {
            const unreadMessages = { ...state.unreadMessages };
            if (!(action.room in unreadMessages)) {
                unreadMessages[action.room] = 0;
            }

            unreadMessages[action.room]++;

            return {
                ...state,
                unreadMessages,
            };
        }

        case LobbyTypes.CLEAR_UNREAD_CHAT_MESSAGES: {
            const unreadMessages = { ...state.unreadMessages };
            if (action.room in unreadMessages) {
                delete unreadMessages[action.room];
            }

            return {
                ...state,
                unreadMessages,
            };
        }

        case LobbyTypes.CLEAR_CHAT: {
            const messages = { ...state.messages };
            if (action.room in messages) {
                delete messages[action.room];
            }

            return {
                ...state,
                messages,
            };
        }

        case LobbyTypes.SET_REJOIN_TABLE: {
            return {
                ...state,
                rejoinTable: action.id ?
                    {
                        id: action.id,
                        index: action.index,
                        password: action.password,
                    } : null,
            };
        }

        case LobbyTypes.UPDATE_USER_INFO: {
            const users = [...state.users];
            const usersById = { ...state.usersById };

            if (!(action.user.id in usersById)) {
                users.push(action.user.id);
            }

            usersById[action.user.id] = action.user;

            return {
                ...state,
                users,
                usersById,
            };
        }

        case LobbyTypes.UPDATE_USER_INFO_KV: {
            return {
                ...state,
                usersById: {
                    ...state.usersById,
                    [action.id]: {
                        ...state.usersById[action.id],
                        [action.key]: action.value,
                    },
                }
            };
        }

        case LobbyTypes.UPDATE_TABLE_INFO: {
            const tableIds = [...state.tableIds];
            const tables = { ...state.tables };

            if (!(action.table.id in tables)) {
                tableIds.push(action.table.id);
            }

            tables[action.table.id] = action.table;

            return {
                ...state,
                tables,
                tableIds,
            };
        }

        case LobbyTypes.UPDATE_TABLE_INFO_KV: {

            return {
                ...state,
                tables: {
                    ...state.tables,
                    [action.id]: {
                        ...state.tables[action.id],
                        [action.key]: action.value,
                    },
                },
            };
        }

        case LobbyTypes.REMOVE_TABLE: {
            const tables = { ...state.tables };
            delete tables[action.id];

            return {
                ...state,
                tableIds: state.tableIds.filter(id => id !== action.id),
                tables,
            };
        }

        default:
            return state;

    }
};

export default lobby;
