import React from "react";
import { connect } from "react-redux";
import { withStyles } from "@mui/styles";
import PropTypes from "prop-types";
import axios from "axios";
import { ERROR_CODES } from "../../global/error";
import { selectTab, setNotification } from "../../actions/global";
import { Box, Card, CardContent, Pagination, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@mui/material";
import dayjs from "dayjs";
import { GlobalTypes } from "../../action_types";
import debounce from "lodash.debounce";
import clsx from "clsx";
import { AxiosClient } from "../../global/axios";
import { getPath } from "../../global/route";

const styles = theme => ({
    table: {
        border: '2px solid #f0f0f0',
        userSelect: 'text !important',
    },
    tCell: {
        border: 0,
        padding: 32,
        verticalAlign: 'top',
    },
    cellHeader: {
        fontFamily: 'Roboto Slab',
        width: 180,
        fontWeight: 700,
    },
    cellContent: {
        color: '#6b7280 !important',
        fontFamily: 'Roboto Slab !important',
    },
    inputIcon: {
        marginRight: '8px',
        color: '#31204A !important'
    },
});

class AuditLogs extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            search: '',
            page: 1,
            size: 10,
            auditLogs: [],
            totalAuditLogs: 0,
        }

    }

    componentDidMount() {
        this.listAuditLogs();
    }

    componentDidUpdate(prevProps) {
    }

    listAuditLogs = async () => {
        try {
            const { filter } = this.props;
            const { search, page, size } = this.state;

            const resp = await AxiosClient().get(`${process.env.REACT_APP_API_URL}/v1/logs/audit?search=${search}&page=${page}&size=${size}&filter=${filter}`);

            this.setState({ auditLogs: resp.data.data ?? [], totalAuditLogs: resp.data.total });

        } catch (err) {
            console.error(err);

            if (err.response?.data?.error?.code in ERROR_CODES) {
                this.props.setNotification({ message: ERROR_CODES[err.response.data.error.code], severity: GlobalTypes.NOTIFY_ERR });
                this.props.selectTab('salon');
                this.props.history.push(getPath('/salon'));
            } else {
                this.props.setNotification({ message: 'Bir hata oluştu. Lütfen tekrar deneyiniz.', severity: GlobalTypes.NOTIFY_ERR });
            }
        }
    }

    debounceSearchAuditLog = debounce(() => this.setState({ page: 1 }, () => this.listAuditLogs()), 500);

    searchChanged = (e) => {
        this.setState({ search: e.target.value });
        this.debounceSearchAuditLog();
    }

    // UI PART

    tableView = () => {
        const { classes } = this.props;
        const { auditLogs } = this.state;

        if (auditLogs.length === 0) {
            return (
                <Box className="!hidden lg:!flex justify-center items-center h-[300px]">
                    <Typography className={clsx(classes.title, "!text-xs sm:!text-sm md:!text-base")}>Sonuç bulunamadı.</Typography>
                </Box>
            );
        }

        return (
            <Table className={clsx(
                classes.table,
                "!hidden lg:!table",
            )}>
                <TableHead>
                    <TableRow>
                        <TableCell className={classes.tCell} sx={{ borderBottom: '2px solid #f0f0f0', minWidth: 0, maxWidth: 0 }}>
                            <Typography className={clsx(classes.cellHeader, "!text-xs sm:!text-sm md:!text-base")}>
                                Log Sahibi
                            </Typography>
                        </TableCell>
                        <TableCell className={classes.tCell} sx={{ borderBottom: '2px solid #f0f0f0', minWidth: 0, maxWidth: 0 }}>
                            <Typography className={clsx(classes.cellHeader, "!text-xs sm:!text-sm md:!text-base")}>
                                Log
                            </Typography>
                        </TableCell>
                        <TableCell className={classes.tCell} sx={{ borderBottom: '2px solid #f0f0f0', minWidth: 0, maxWidth: 0 }}>
                            <Typography className={clsx(classes.cellHeader, "!text-xs sm:!text-sm md:!text-base")}>
                                Tarih
                            </Typography>
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {auditLogs.map((log) => {
                        return (
                            <TableRow key={log.id}>
                                <TableCell>
                                    <Typography className={clsx(classes.cellContent, "!text-xs sm:!text-sm md:!text-base")}>
                                        {log.username}
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography className={clsx(classes.cellContent, "!text-xs sm:!text-sm md:!text-base")}>
                                        {log.log}
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography className={clsx(classes.cellContent, "!text-xs sm:!text-sm md:!text-base")}>
                                        {dayjs(log.created_at).format('DD.MM.YYYY HH:mm:ss')}
                                    </Typography>
                                </TableCell>
                            </TableRow>
                        );
                    })}

                </TableBody>
            </Table>
        );
    }

    mobileView = () => {
        const { classes } = this.props;
        const { auditLogs } = this.state;

        if (auditLogs.length === 0) {
            return (
                <Box className="flex lg:hidden justify-center items-center">
                    <Typography className={clsx(classes.title, "!text-xs sm:!text-sm md:!text-base")}>Sonuç bulunamadı.</Typography>
                </Box>
            );
        }

        return (
            <Box className="flex flex-col gap-y-2 lg:hidden">
                {auditLogs.map((log) => {
                    return (
                        <Card key={log.id} sx={{ borderRadius: 4, border: '1px solid #c4c4c4', boxShadow: 0 }}>
                            <CardContent>
                                <Table>
                                    <TableRow>
                                        <TableCell className="!p-2 !w-0">
                                            <Typography className={clsx(classes.cellContent, "!text-xs sm:!text-sm md:!text-base")}>
                                                Log Sahibi
                                            </Typography>
                                        </TableCell>
                                        <TableCell className="!p-2">
                                            <Typography className={clsx(classes.cellContent, "!text-xs sm:!text-sm md:!text-base")}>
                                                {log.username}
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell className="!p-2 !w-0">
                                            <Typography className={clsx(classes.cellContent, "!text-xs sm:!text-sm md:!text-base")}>
                                                Log
                                            </Typography>
                                        </TableCell>
                                        <TableCell className="!p-2">
                                            <Typography className={clsx(classes.cellContent, "!text-xs sm:!text-sm md:!text-base")}>
                                                {log.log}
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell className="!p-2 !w-0">
                                            <Typography className={clsx(classes.cellContent, "!text-xs sm:!text-sm md:!text-base")}>
                                                Tarih
                                            </Typography>
                                        </TableCell>
                                        <TableCell className="!p-2">
                                            <Typography className={clsx(classes.cellContent, "!text-xs sm:!text-sm md:!text-base")}>
                                                {dayjs(log.created_at).format('DD.MM.YYYY HH:mm:ss')}
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                </Table>
                            </CardContent>
                        </Card>
                    );
                })}
            </Box>
        );
    }

    render() {
        const { totalAuditLogs } = this.state;

        return (
            <Box display={"flex"} flexDirection={"column"} rowGap={1} className="w-full">

                {this.tableView()}
                {this.mobileView()}

                <Box className="flex justify-end">
                    <Pagination
                        count={parseInt(Math.ceil(totalAuditLogs / this.state.size))} page={this.state.page}
                        variant="outlined" shape="rounded" color="primary"
                        onChange={(e, p) => this.setState({ page: p }, () => this.listAuditLogs())}
                    />
                </Box>
            </Box>
        );
    }
}

AuditLogs.propTypes = {
    classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
    return {
    }
};

export default withStyles(styles)(connect(mapStateToProps, {
    setNotification, selectTab,
})(AuditLogs));
