import React from "react";
import { connect } from "react-redux";
import { withStyles } from "@mui/styles";
import PropTypes from "prop-types";
import { Box, CircularProgress, Typography } from "@mui/material";
import clsx from "clsx";


const styles = theme => ({
});

class TurnTimer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        }

    }

    componentDidMount() {
    }

    componentDidUpdate(prevProps) {
    }

    render() {
        const { selectedTableDefaultTurnTime, selectedTableTurnTimeLeft } = this.props;

        return (
            <Box
                display={selectedTableDefaultTurnTime ? "inline-flex" : "none"}
                position={"relative"}
            >
                <CircularProgress
                    className={clsx(
                        `!w-6 !h-6`,
                        `min-[482px]:!w-8 min-[482px]:!h-8`,
                        `min-[637px]:!w-10 min-[637px]:!h-10`,
                        `lg:!w-8 lg:!h-8`,
                        `xl:!w-10 xl:!h-10`
                    )}
                    //size={36}
                    thickness={6}
                    variant="determinate"
                    value={selectedTableTurnTimeLeft * 100 / selectedTableDefaultTurnTime}
                />

                <Box
                    sx={{
                        top: 0,
                        left: 0,
                        bottom: 0,
                        right: 0,
                        position: 'absolute',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <Typography className="text-white !text-[10px] min-[482px]:!text-[12px] min-[637px]:!text-[14px] lg:!text-[12px] xl:!text-[14px]">
                        {selectedTableTurnTimeLeft}
                    </Typography>
                </Box>
            </Box>
        );
    }
}

TurnTimer.propTypes = {
    classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
    const selectedTable = state.app.global.selectedTable;

    return {
        selectedTableDefaultTurnTime: state.app.table.tables[selectedTable]?.defaultTurnTime,
        selectedTableTurnTimeLeft: state.app.table.tables[selectedTable]?.turnTimeLeft,
    }
};

export default withStyles(styles)(connect(mapStateToProps, {
})(TurnTimer));
