import React from "react";
import { connect } from "react-redux";
import { withStyles } from "@mui/styles";
import PropTypes from "prop-types";
import { Box } from "@mui/material";
import Tile from "../../models/tile";


const styles = theme => ({
});

class PiecesLeft extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        }

    }

    componentDidMount() {
    }

    componentDidUpdate(prevProps) {
    }

    render() {
        const {
            groundRef, isLandscape,
            selectedTablePiecesLeft: piecesLeft,
            selectedTableInfoAt: infoAt,
            onDragStart,
            onDragEnd,
            onMovePiece,
            onDoubleClick,
        } = this.props;

        return (
            <Box ref={groundRef}>
                {/*this.state.previewGround ?
                                <Tile value={0} key={`ground_preview`} text={piecesLeft}
                                    index={26} preview /> :
                                null
                            */}
                <Tile value={0} key={`ground_${infoAt}`} text={piecesLeft}
                    index={26}
                    onDragStart={onDragStart}
                    onDragEnd={onDragEnd}
                    onMovePiece={onMovePiece}
                    onDoubleClick={onDoubleClick}
                    isLandscape={isLandscape}
                />
            </Box>
        );
    }
}

PiecesLeft.propTypes = {
    classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
    const selectedTable = state.app.global.selectedTable;

    return {
        selectedTablePiecesLeft: state.app.table.tables[selectedTable]?.piecesLeft,
        selectedTableInfoAt: state.app.table.tables[selectedTable]?.infoAt,
    }
};

export default withStyles(styles)(connect(mapStateToProps, {
})(PiecesLeft));
