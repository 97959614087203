import React from "react";
import { connect } from "react-redux";
import { withStyles } from "@mui/styles";
import PropTypes from "prop-types";
import { Box, Tab, Tabs, Typography } from "@mui/material";
import RegistrationApproval from "./RegistrationApproval";
import Users from "./Users";
import ChatBans from "./ChatBans";
import UserColors from "./UserColors";
import UserTables from "./UserTables";
import UserBans from "./UserBans";
import IPBans from "./IPBans";
import { setAdminTab } from "../../../actions/global";
import AvatarApproval from "./AvatarApproval";
import UsernameApproval from "./UsernameApproval";
import ReportedUsers from "./ReportedUsers";
import DescriptionApproval from "./DescriptionApproval";

const styles = theme => ({
});

export const tabs = {
    APPROVAL: 0,
    AVATAR_APPROVAL: 1,
    USERNAME_APPROVAL: 2,
    DESCRIPTION_APPROVAL: 3,
    USERS: 4,
    USER_COLORS: 5,
    USER_TABLES: 6,
    REPORTED_USERS: 7,
    CHAT_BANS: 8,
    USER_BANS: 9,
    IP_BANS: 10,
}

class UserSettings extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            tab: tabs.APPROVAL,
        }

    }

    componentDidMount() {
        this.setState({ tab: this.props.adminTab });
    }

    componentDidUpdate(prevProps) {
    }

    onTabChange = (e, value) => {
        this.props.setAdminTab(value);
        this.setState({ tab: value });
    }

    content = () => {
        switch (this.state.tab) {
            case tabs.APPROVAL:
                return <RegistrationApproval />;
            case tabs.AVATAR_APPROVAL:
                return <AvatarApproval />;
            case tabs.USERNAME_APPROVAL:
                return <UsernameApproval />;
            case tabs.DESCRIPTION_APPROVAL:
                return <DescriptionApproval />;
            case tabs.USERS:
                return <Users />;
            case tabs.USER_COLORS:
                return <UserColors />;
            case tabs.USER_TABLES:
                return <UserTables />;
            case tabs.REPORTED_USERS:
                return <ReportedUsers />;
            case tabs.CHAT_BANS:
                return <ChatBans />;
            case tabs.USER_BANS:
                return <UserBans />;
            case tabs.IP_BANS:
                return <IPBans />;
        }
    }

    hasPermission = (perm) => (this.props.permission & Number(perm)) !== 0

    render() {
        const { classes } = this.props;

        return (
            <Box className="w-full overflow-auto">
                <Tabs
                    sx={{
                        width: 'fit-content', maxWidth: '100%',
                        '& .MuiTabs-flexContainer': { columnGap: 0 },
                        '& .MuiTabs-indicator': { backgroundColor: "#FF9559", height: '4px' },
                        '& .MuiTab-root': { color: '#505050 !important', borderBottom: '1px solid #E3E3E3', padding: 0 },
                        '& .Mui-selected': { color: '#505050 !important', backgroundColor: '#FFF3EC' },
                        '& .MuiTabs-scrollButtons.Mui-disabled': { opacity: 0.3 },
                    }}
                    variant="scrollable"
                    scrollButtons
                    allowScrollButtonsMobile
                    value={this.state.tab}
                    textColor="primary"
                    className={classes.tabs}
                    onChange={this.onTabChange}>

                    {this.hasPermission(1n << 20n) &&
                        <Tab value={tabs.APPROVAL}
                            label={
                                <Box display={"flex"} columnGap={2} flex={1} p={2} justifyContent={"center"}>
                                    <Typography className="!text-xs md:!text-sm lg:!text-base" sx={{ whiteSpace: 'nowrap', px: 1 }}>
                                        Kayıt Onaylama
                                    </Typography>
                                </Box>
                            }
                            sx={{ textTransform: 'none', padding: 3, flexDirection: 'row', borderRight: '1px solid #E3E3E3' }} />
                    }

                    {this.hasPermission(1n << 20n) &&
                        <Tab value={tabs.AVATAR_APPROVAL} hidden={(this.props.permission & Number(1n << 20n)) === 0}
                            label={
                                <Box display={"flex"} columnGap={2} flex={1} p={2} justifyContent={"center"}>
                                    <Typography className="!text-xs md:!text-sm lg:!text-base" sx={{ whiteSpace: 'nowrap', px: 1 }}>
                                        Avatar Onaylama
                                    </Typography>
                                </Box>
                            }
                            sx={{ textTransform: 'none', padding: 3, flexDirection: 'row', borderRight: '1px solid #E3E3E3' }} />
                    }

                    {this.hasPermission(1n << 20n) &&
                        <Tab value={tabs.USERNAME_APPROVAL} hidden={(this.props.permission & Number(1n << 20n)) === 0}
                            label={
                                <Box display={"flex"} columnGap={2} flex={1} p={2} justifyContent={"center"}>
                                    <Typography className="!text-xs md:!text-sm lg:!text-base" sx={{ whiteSpace: 'nowrap', px: 1 }}>
                                        Kullanıcı Adı Onaylama
                                    </Typography>
                                </Box>
                            }
                            sx={{ textTransform: 'none', padding: 3, flexDirection: 'row', borderRight: '1px solid #E3E3E3' }} />
                    }

                    {this.hasPermission(1n << 20n) &&
                        <Tab value={tabs.DESCRIPTION_APPROVAL} hidden={(this.props.permission & Number(1n << 20n)) === 0}
                            label={
                                <Box display={"flex"} columnGap={2} flex={1} p={2} justifyContent={"center"}>
                                    <Typography className="!text-xs md:!text-sm lg:!text-base" sx={{ whiteSpace: 'nowrap', px: 1 }}>
                                        Hakkında Onaylama
                                    </Typography>
                                </Box>
                            }
                            sx={{ textTransform: 'none', padding: 3, flexDirection: 'row', borderRight: '1px solid #E3E3E3' }} />
                    }

                    {this.hasPermission(1n << 0n) &&
                        <Tab value={tabs.USERS}
                            label={
                                <Box display={"flex"} columnGap={2} flex={1} p={2} justifyContent={"center"}>
                                    <Typography className="!text-xs md:!text-sm lg:!text-base" sx={{ whiteSpace: 'nowrap', px: 1 }}>
                                        Kullanıcılar
                                    </Typography>
                                </Box>
                            }
                            sx={{ textTransform: 'none', padding: 3, flexDirection: 'row', borderRight: '1px solid #E3E3E3' }} />
                    }

                    {this.hasPermission(1n << 1n) &&
                        <Tab value={tabs.USER_COLORS}
                            label={
                                <Box display={"flex"} columnGap={2} flex={1} p={2} justifyContent={"center"}>
                                    <Typography className="!text-xs md:!text-sm lg:!text-base" sx={{ whiteSpace: 'nowrap', px: 1 }}>
                                        Renk Yönetimi
                                    </Typography>
                                </Box>
                            }
                            sx={{ textTransform: 'none', padding: 3, flexDirection: 'row', borderRight: '1px solid #E3E3E3' }} />
                    }

                    {this.hasPermission(1n << 13n) &&
                        <Tab value={tabs.USER_TABLES}
                            label={
                                <Box display={"flex"} columnGap={2} flex={1} p={2} justifyContent={"center"}>
                                    <Typography className="!text-xs md:!text-sm lg:!text-base" sx={{ whiteSpace: 'nowrap', px: 1 }}>
                                        Kullanıcı Masaları
                                    </Typography>
                                </Box>
                            }
                            sx={{ textTransform: 'none', padding: 3, flexDirection: 'row', borderRight: '1px solid #E3E3E3' }} />
                    }

                    {(this.hasPermission(1n << 11n) || this.hasPermission(1n << 12n)) &&
                        <Tab value={tabs.REPORTED_USERS}
                            label={
                                <Box display={"flex"} columnGap={2} flex={1} p={2} justifyContent={"center"}>
                                    <Typography className="!text-xs md:!text-sm lg:!text-base" sx={{ whiteSpace: 'nowrap', px: 1 }}>
                                        Raporlanmış Kullanıcılar
                                    </Typography>
                                </Box>
                            }
                            sx={{ textTransform: 'none', padding: 3, flexDirection: 'row', borderRight: '1px solid #E3E3E3' }} />
                    }

                    {this.hasPermission(1n << 19n) &&
                        <Tab value={tabs.CHAT_BANS}
                            label={
                                <Box display={"flex"} columnGap={2} flex={1} p={2} justifyContent={"center"}>
                                    <Typography className="!text-xs md:!text-sm lg:!text-base" sx={{ whiteSpace: 'nowrap', px: 1 }}>
                                        Kullanıcı Susturma
                                    </Typography>
                                </Box>
                            }
                            sx={{ textTransform: 'none', padding: 3, flexDirection: 'row', borderRight: '1px solid #E3E3E3' }} />
                    }

                    {(this.hasPermission(1n << 11n) || this.hasPermission(1n << 12n)) &&
                        <Tab value={tabs.USER_BANS}
                            label={
                                <Box display={"flex"} columnGap={2} flex={1} p={2} justifyContent={"center"}>
                                    <Typography className="!text-xs md:!text-sm lg:!text-base" sx={{ whiteSpace: 'nowrap', px: 1 }}>
                                        Kullanıcı Engelleme
                                    </Typography>
                                </Box>
                            }
                            sx={{ textTransform: 'none', padding: 3, flexDirection: 'row', borderRight: '1px solid #E3E3E3' }} />
                    }

                    {(this.hasPermission(1n << 11n) || this.hasPermission(1n << 12n)) &&
                        <Tab value={tabs.IP_BANS}
                            label={
                                <Box display={"flex"} columnGap={2} flex={1} p={2} justifyContent={"center"}>
                                    <Typography className="!text-xs md:!text-sm lg:!text-base" sx={{ whiteSpace: 'nowrap', px: 1 }}>
                                        IP Engelleme
                                    </Typography>
                                </Box>
                            }
                            sx={{ textTransform: 'none', padding: 3, flexDirection: 'row', borderRight: '1px solid #E3E3E3' }} />
                    }
                </Tabs>

                {this.content()}
            </Box>
        );
    }
}

UserSettings.propTypes = {
    classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
    return {
        user: state.app.global.user,
        adminTab: state.app.global.adminTab,
    }
};

export default withStyles(styles)(connect(mapStateToProps, {
    setAdminTab,
})(UserSettings));
