import React from "react";
import { connect } from "react-redux";
import { withStyles } from "@mui/styles";
import PropTypes from "prop-types";
import { Box } from "@mui/system";
import { mod } from "../../utils/math";
import Pot from "./Pot";
import { Avatar, Button, SvgIcon, Typography } from "@mui/material";
import { mdiRobot } from "@mdi/js";
import clsx from "clsx";
import TableUser from "./TableUser";


const styles = theme => ({
    text: {
        fontWeight: '400 !important',
    },
});

class ThirdRow extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        }

    }

    componentDidMount() {
    }

    componentDidUpdate(prevProps) {
    }

    render() {
        const {
            isLandscape, user, selectedTableUsers, selectedTableSpectators,
            selectedTableTurn: turn,
        } = this.props;

        const usersById = this.props.store.getState().app.lobby.usersById;

        let myIndex = selectedTableUsers?.findIndex?.(u => u?.id === user.id);
        if (myIndex === -1) {
            myIndex = selectedTableSpectators?.findIndex?.(s => s === user.id);
        }

        if (myIndex === -1) {
            return null;
        }

        const tableUser = selectedTableUsers?.[mod(myIndex, 4)];
        const lobbyUser = tableUser?.bot || !(tableUser?.id in usersById) ? tableUser : usersById[tableUser?.id];

        const myTurn = myIndex === turn;

        return (
            <Box width={"100%"} display={"flex"} justifyContent={"space-between"}
                className={clsx("p-2 lg:p-0")}
            >
                <Pot
                    className="justify-center xl:justify-end"
                    isLandscape={isLandscape}
                    potIndex={mod(myIndex - 1, selectedTableUsers?.length)}
                    tileIndex={27}
                    onInspect={(e, pot) => this.props.onInspect(pot)}
                    onDragStart={myTurn ? this.props.onDragStart : null}
                    onDragEnd={myTurn ? this.props.onDragEnd : null}
                    onMovePiece={myTurn ? this.props.onMovePiece : null}
                    onDoubleClick={myTurn ? this.props.onDoubleClick : null}
                />

                <Box position={"relative"} display={"flex"} justifyContent={"center"}
                    className="w-fit flex-1"
                >
                    <TableUser
                        index={mod(myIndex, selectedTableUsers?.length)}
                        user={lobbyUser}
                        addBot={this.props.addBot}
                        userContextMenuHandler={this.props.userContextMenuHandler}
                        onPrivateMessage={this.props.onPrivateMessage}
                    />
                </Box>

                <Pot
                    className="justify-center xl:justify-start"
                    potRef={this.props.potRef}
                    isLandscape={isLandscape}
                    potIndex={myIndex}
                    tileIndex={28}
                    onInspect={(e, pot) => this.props.onInspect(pot)}
                />
            </Box>
        );
    }
}

ThirdRow.propTypes = {
    classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
    const myTable = state.app.global.myTable;
    const selectedTable = state.app.global.selectedTable;

    return {
        myTable,
        selectedTable,
        user: state.app.global.user,
        selectedTableTurn: state.app.table.tables[selectedTable]?.turn,
        selectedTableUsers: state.app.lobby.tables[selectedTable]?.users,
        selectedTableSpectators: state.app.lobby.tables[selectedTable]?.spectators,
    }
};

export default withStyles(styles)(connect(mapStateToProps, {
})(ThirdRow));
