import clsx from "clsx";

function AppStore({ url }) {
    return (
        <div className={
            clsx(
                "flex flex-1 border-4 rounded-2xl px-6 py-4 w-fit border-gray-400 bg-gray-900",
                "cursor-pointer hover:bg-gray-800",
            )}
            onClick={() => window.open(url, "_blank")}
        >
            <img
                className="h-12 self-center"
                src={`${`${process.env.REACT_APP_CDN_URL}/web/img/appstore.svg`}`}
                alt="appstore_logo"
            />

            <div className="flex flex-col">
                <span
                    className="text-white font-semibold text-lg ml-4"
                    style={{ fontFamily: "Myriad Pro", letterSpacing: "0.1em" }}
                >
                    Uygulamayı İndir
                </span>
                <span className="text-white font-bold text-2xl ml-4"
                    style={{ fontFamily: "Myriad Pro", letterSpacing: "0.1em" }}
                >
                    App Store
                </span>
            </div>
        </div>
    );
}

export default AppStore;