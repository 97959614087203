import React from "react";
import { connect } from "react-redux";
import { withStyles } from "@mui/styles";
import PropTypes from "prop-types";
import { Alert, Box, IconButton, Snackbar as ReactSnackbar, SvgIcon, Tooltip, Typography } from "@mui/material";
import { clearNotification } from "../../actions/global";
import { GlobalTypes } from "../../action_types";
import { mdiContentCopy } from "@mdi/js";
import clsx from "clsx";


const styles = theme => ({
    text: {
        fontFamily: 'Roboto Slab',
        fontWeight: 'medium !important',
    },
});

const severityMap = {
    [GlobalTypes.NOTIFY_ERR]: 'error',
    [GlobalTypes.NOTIFY_INFO]: 'info',
    [GlobalTypes.NOTIFY_SUCC]: 'success',
    [GlobalTypes.NOTIFY_WARN]: 'warning',
};

class Snackbar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
        }

    }

    componentDidMount() {
    }

    componentDidUpdate(prevProps) {
        if (prevProps.notification?.createdAt !== this.props.notification?.createdAt) {
            this.setState({ open: Boolean(this.props.notification) });
        }
    }

    handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        this.setState({ open: false });
    };

    onExited = () => {
        this.props.clearNotification();
    }

    render() {
        const { classes, notification } = this.props;

        return (
            <ReactSnackbar open={this.state.open} autoHideDuration={6000} onClose={this.handleClose}
                TransitionProps={{ onExited: this.onExited }}
            >
                <Alert onClose={this.handleClose} severity={severityMap[notification?.severity] ?? severityMap[GlobalTypes.NOTIFY_INFO]}
                    sx={{ width: '100%' }}>
                    <Box>
                        <Typography className={clsx(classes.text, "!text-xs md:!text-sm lg:!text-base")}>{notification?.message}</Typography>

                        {notification?.traceId ?
                            <Box display={"flex"} columnGap={1}>
                                <Typography className={clsx(classes.text, "!text-xs md:!text-sm lg:!text-base")}><br />İstek No:<br />{notification.traceId}</Typography>
                                <Box display={"flex"} alignSelf={"end"}>
                                    <Tooltip title="Kopyala" placement="top">
                                        <IconButton onClick={() => navigator.clipboard.writeText(notification.traceId)}>
                                            <SvgIcon sx={{ fontSize: '12pt' }}><path d={mdiContentCopy} /></SvgIcon>
                                        </IconButton>
                                    </Tooltip>
                                </Box>
                            </Box> :
                            null
                        }
                    </Box>
                </Alert>
            </ReactSnackbar>
        );
    }
}

Snackbar.propTypes = {
    classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
    return {
        notification: state.app.global.notification,
    }
};

export default withStyles(styles)(connect(mapStateToProps, {
    clearNotification
})(Snackbar));
