const icons = [
    { "title": "Resim YOK", img: "_" },
    { "title": "Bir Numara", img: "1numara.png" },
    { "title": "On Numara", img: "10numara.png" },
    { "title": "Yuz Numara", img: "100numara.png" },
    { "title": "Turkiye Bayragi", img: "turkiyebayragi.png" },
    { "title": "Kalp - 1", img: "kalp.png" },
    { "title": "Kalp - 2", img: "kalp2.png" },
    { "title": "Kalp Bayrak", img: "kalpbayrak.png" },
    { "title": "Sonsuzluk", img: "sonszluk.png" },
    { "title": "Dudak Izi", img: "dudakizi.png" },
    { "title": "Cicek", img: "cicek.png" },
    { "title": "Kugu", img: "kugu.png" },
    { "title": "Civciv", img: "civciv.png" },
    { "title": "Nazar Boncugu", img: "nazarboncugu.png" },
    { "title": "Zar", img: "zar.png" },
    { "title": "1938", img: "1938.png" },
    { "title": "Besiktas", img: "besiktas.png" },
    { "title": "Galatasaray", img: "galatasaray.png" },
    { "title": "Galatasaray - 2", img: "gs.png" },
    { "title": "Fenerbahce", img: "fenerbahce.png" },
    { "title": "Trabzonspor", img: "trabzonspor.png" },
    { "title": "Kartlar", img: "iskambil.png" },
    { "title": "Seytan", img: "seytan1.png" },
    { "title": "Kalp Goz", img: "kalpgozemoji.png" },
    { "title": "smileyyz", img: "smileyyz.jpg" },
    { "title": "Romantik", img: "indir.png" },
    { "title": "Beyaz Papatya", img: "masa345.png" },
    { "title": "Sarı Çiçek", img: "masa12.png" },
    { "title": "Kelebek", img: "06.gif" },
    { "title": "Kırmızı Gül", img: "masa13.png" },
    { "title": "Kral Tacı", img: "images.png" },
    { "title": "Osmanlı Türk", img: "turks.png" },
    { "title": "kiss", img: "kiss.gif" },
    { "title": "Galatasaray 3", img: "images(14).png" },
    { "title": "Melek Emoji", img: "images(13).png" },
    { "title": "Kalp Ok", img: "images(12).png" },
    { "title": "fdghg55", img: "fdghg55.png" },
    { "title": "Ay Yıldız", img: "images(10).png" },
    { "title": "Siyah Kalp", img: "images(9).png" },
    { "title": "Somurtkan", img: "images(8).png" },
    { "title": "ilone", img: "ilone.png" },
    { "title": "gözkırp", img: "qoobee.gif" },
    { "title": "kelebek", img: "kelebek1.jpg" },
    { "title": "Cadı", img: "cadi.gif" },
    { "title": "Kurt", img: "bass.png" },
    { "title": "Aşık Emojiler", img: "images(7).png" },
    { "title": "Öfkeli Emoji", img: "images(5).png" },
    { "title": "Aşık emoji", img: "images535.png" },
    { "title": "Atatürk 1", img: "images(2).png" },
    { "title": "Atatürk", img: "images(3).png" },
    { "title": "Şaşkın Emoji", img: "images(1).png" },
    { "title": "karakalp", img: "karakalp.png" },
    { "title": "aycicek", img: "aycicek.png" },
    { "title": "biryildiz", img: "biryildiz.png" },
    { "title": "ugurbocegi", img: "ugurbocegi.png" },
    { "title": "samsunspor", img: "samsunspor.png" },
    { "title": "1yildiz2", img: "1yildiz2.jpg" },
    { "title": "tac_kral_taci", img: "tac_kral_taci.gif" },
    { "title": "cute-devil", img: "cute-devil.gif" },
    { "title": "Kalp Goz Kedi", img: "kalpgozkedi.png" },
    { "title": "pikachu", img: "pikachury.png" },
    { "title": "Gulen Kalp 1", img: "gulenkalp1.png" },
    { "title": "Gulen Kalp 2", img: "gulenkalp2.png" },
    { "title": "Melek Kalp 1", img: "melekkalp1.png" },
    { "title": "casper", img: "afas.png" },
    { "title": "Sari Papatya", img: "papatyasari.png" },
    { "title": "Melek Kalp 2", img: "melekkalp2.png" },
    { "title": "Melek Emoji 1", img: "melekemoji1.png" },
    { "title": "şirin baba", img: "sirinbaba.png" },
    { "title": "gitbasımdan", img: "gitbasimdan.gif" },
    { "title": "unlem", img: "unlem.gif" },
    { "title": "Melek Emoji 2", img: "melekemoji2.png" },
];

module.exports = { icons };