import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import global from './global';
import lobby from './lobby';
import table from './table';

const appReducer = (history) => combineReducers({
    router: connectRouter(history),
    global,
    lobby,
    table,
})

export default appReducer;
