import React from "react";
import { connect } from "react-redux";
import { withStyles } from "@mui/styles";
import PropTypes from "prop-types";
import { Avatar, Box, Tooltip, Typography } from "@mui/material";
import clsx from "clsx";
import UserActions from "./UserActions";
import { Face, Laptop, PhoneAndroid, TableRestaurant, VoiceOverOff } from "@mui/icons-material";
import { addChatTab } from "../../actions/global";
import ContextMenuHandler from "../../utils/ContextMenuHandler";
import UserAvatar from "./UserAvatar";
import { TableIcon, deviceIcons } from "../../global/device_icons";
import { getPath } from "../../global/route";
import { loadedResources } from "../../global/resources";
import { roles } from "../../global/role";

const humanize = require('humanize-number');


const styles = theme => ({
});

class usersList extends React.Component {
    constructor(props) {
        super(props);

        this.contextMenuHandler = new ContextMenuHandler(
            (e, u) => {
                this.onUserRightClicked(e, u);
            }
        );

        this.state = {
            searchUser: '',
            userAnchor: null,
            userAnchorPos: null,
            contextUser: null,
            selectedUser: null,
        }

    }

    componentDidMount() {
        this.props.onRef?.(this);
    }

    componentDidUpdate(prevProps) {
    }

    onUserRightClicked = (e, user) => {
        e.preventDefault();

        const touch = e.touches?.[0];
        if (touch) {
            this.setState({ userAnchorPos: { left: touch.clientX, top: touch.clientY }, contextUser: user });
        }
        else {
            this.setState({ userAnchor: e.currentTarget, contextUser: user });
        }
    }

    userActionsClose = () => {
        this.setState({ userAnchor: null, userAnchorPos: null, contextUser: null });
    }

    onPrivateMessage = (userId, username) => {
        if (!userId) {
            return;
        }

        this.userActionsClose();
        this.props.addChatTab(userId, username, true);
        this.props.history.push(getPath("/sohbet"), { userId, username });
    }

    getProfile = () => {
        let profile = null;

        try {
            profile = JSON.parse(sessionStorage.getItem('profile') ?? localStorage.getItem('profile'));
        } catch (e) {
            console.error(e);
        }

        return profile;
    }

    onSelected = (e, u) => {
        e.preventDefault();

        if (!u) {
            return;
        }

        document.getElementById(`user_${u.id}`)?.scrollIntoView({ behavior: 'smooth', block: 'nearest' });

        this.setState({ selectedUser: u });
        this.props.onSelected?.(u);
    }

    render() {
        const profile = this.getProfile();
        const filtered = this.props.users?.filter(user => (
            user?.username?.toLowerCase()?.includes(this.props.search?.toLowerCase() ?? '')
        )) ?? [];

        filtered.sort((ua, ub) => {
            const uaOnline = this.props.isOnline?.(ua) ?? false;
            const ubOnline = this.props.isOnline?.(ub) ?? false;

            if ((!ua.bot && ub.bot) || (!ubOnline && uaOnline) || ua.role > ub.role) {
                return -1;
            }

            if ((ua.bot && !ub.bot) || (ubOnline && !uaOnline) || ub.role > ua.role) {
                return 1;
            }

            if (ua.premium && !ub.premium) {
                return -1;
            }

            if (!ua.premium && ub.premium) {
                return 1;
            }

            return ua.username.toLowerCase().localeCompare(ub.username.toLowerCase());
        });

        if (filtered.length === 0) {
            return (
                <Box>
                    <Box p={2} textAlign="center">
                        <Typography className={clsx("!text-xs sm:!text-sm md:!text-base")} sx={{ color: 'black' }}>Kişi bulunamadı.</Typography>
                    </Box>
                </Box>
            );
        }

        return (
            <Box sx={this.props.sx}>
                <UserActions
                    anchor={this.state.userAnchor}
                    anchorPos={this.state.userAnchorPos}
                    user={this.state.contextUser}
                    onClose={() => this.userActionsClose()} onPrivateMessage={this.onPrivateMessage}
                    history={this.props.history}
                    onTabUser={this.props.onTabUser}
                />

                <Box>
                    {filtered.map(u => {
                        if (!u) {
                            return null;
                        }

                        let outlineColor = null;
                        if (u.gender === 'male') outlineColor = "#39ccff";
                        else if (u.gender === 'female') outlineColor = "#ff79fc";

                        return (
                            <Box key={u.id} id={`user_${u.id}`} sx={{ userSelect: 'none' }}>

                                <Box display="flex" className={clsx(
                                    "px-[8px] md:px-[10px] lg:px-[12px] xl:px-[14px]",
                                    "py-[1px] md:py-[2px] lg:py-[3px] xl:py-[4px]",
                                    "gap-x-1 sm:gap-x-2 md:gap-x-3 lg:gap-x-4",
                                    "cursor-pointer select-none bg-gradient-to-b",
                                    "hover:from-teal-100 hover:via-teal-200 hover:to-teal-300 shadow-lg",
                                    this.state.selectedUser?.id === u.id ?
                                        "!from-emerald-300 !via-emerald-400 !to-emerald-500" :
                                        "from-[#f2f2f2] via-[#e2e2e2] to-[#d2d2d2]",
                                )}
                                    //onContextMenu={(e) => this.onUserRightClicked(e, u)}
                                    onContextMenu={(e) => this.contextMenuHandler.onContextMenu(e, u)}
                                    onTouchStart={(e) => this.contextMenuHandler.onTouchStart(e, u)}
                                    onTouchCancel={this.contextMenuHandler.onTouchCancel}
                                    onTouchEnd={this.contextMenuHandler.onTouchEnd}
                                    onTouchMove={this.contextMenuHandler.onTouchMove}
                                    onDoubleClick={() => this.onPrivateMessage(u.id, u.username)}
                                    onClick={(e) => this.onSelected(e, u)}
                                >
                                    <Box display={"flex"} position={"relative"} columnGap={2}>
                                        {/** Avatar */}
                                        <UserAvatar user={u} showStatus online={this.props.isOnline?.(u) ?? false}
                                            sx={{
                                                boxShadow: !!outlineColor ? `0 0 0 2px ${outlineColor}` : null,
                                            }}
                                        />
                                    </Box>

                                    <Box display={"flex"} flexDirection={"column"} justifyContent={"space-between"}>
                                        <Box className="flex gap-x-1 items-center">
                                            {u.icon ?
                                                <img src={loadedResources[`${process.env.REACT_APP_CDN_URL}/web/img/${u.icon}`]} className="w-[16px] h-[16px] sm:w-[20px] sm:h-[20px] md:w-[24px] md:h-[24px]" /> :
                                                (u.premium ?
                                                    <img src={loadedResources[`${process.env.REACT_APP_CDN_URL}/web/img/vip.png`]} className="w-[16px] h-[16px] sm:w-[20px] sm:h-[20px] md:w-[24px] md:h-[24px]" /> :
                                                    null
                                                )
                                            }

                                            <Box className={clsx(
                                                "!text-xs sm:!text-sm md:!text-base !font-bold whitespace-nowrap",
                                                u?.username_color ? `NickRenk${u.username_color}` : 'text-black'
                                            )}
                                            >
                                                {u.username}
                                            </Box>
                                        </Box>
                                        <Box className="flex items-center gap-x-[2px] md:gap-x-2">
                                            <Box sx={{ color: 'black' }}>
                                                {(() => {
                                                    const MobileIcon = deviceIcons[u.mobile ?? null];
                                                    return (
                                                        <MobileIcon className="!text-[1.4em] sm:!text-[1.6em] md:!text-[1.8em]" />
                                                    );
                                                })()}
                                            </Box>
                                            <Box className={"text-[0.9em] sm:text-[0.95em] md:text-[1em]"} sx={{ color: 'black' }}>
                                                {humanize(u?.points ?? 0, { delimiter: '.', separator: ',' })}
                                            </Box>
                                            <Box className="flex gap-x-1 items-center">
                                                <TableIcon className="mt-[2px] !text-[1.4em] sm:!text-[1.6em] md:!text-[1.8em]" />
                                                <span>{u?.tableId ?? '-'}</span>
                                            </Box>
                                            <Box className={clsx(u?.chat_ban && profile?.role >= roles.Moderator ? "flex" : "hidden", "gap-x-1 items-center")}>
                                                <Tooltip title="Susturuldu" placement="top">
                                                    <VoiceOverOff className="mt-[2px] !text-[1.4em] sm:!text-[1.6em] md:!text-[1.8em]" />
                                                </Tooltip>
                                            </Box>
                                            {this.props.isSpectator?.(u) && (
                                                <Box className="flex gap-x-1 items-center">
                                                    <Tooltip title="İzleyici" placement="top">
                                                        <Face className="mt-[2px] !text-[1.2em] sm:!text-[1.4em] md:!text-[1.6em]" />
                                                    </Tooltip>
                                                </Box>
                                            )}
                                        </Box>
                                    </Box>
                                </Box>
                            </Box>
                        );
                    })}
                </Box>
            </Box>
        );
    }
}

usersList.propTypes = {
    classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
    return {}
};

export default withStyles(styles)(connect(mapStateToProps, {
    addChatTab,
})(usersList));
