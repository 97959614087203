export const roles = {
    Rejected: -3,
    NotVerified: -2,
    Banned: -1,
    Player: 0,
    Streamer: 1,
    Moderator: 2,
    Admin: 3,
    Owner: 4,
    Founder: 5,
    SuperFounder: 6,
}

export const ROLES = {
    [-3]: 'Reddedildi',
    [-2]: 'Onay Bekliyor',
    [-1]: 'Yasaklı',
    0: 'Oyuncu',
    1: 'Yayıncı',
    2: 'Moderatör',
    3: 'Oyun Admini',
    4: 'Oyun Sahibi',
    5: 'Oyun Kurucusu',
    6: 'Oyun Kurucusu',
}

export const ROLE_COLORS = {
    // grey tone
    [-3]: '#DAD2D8',
    [-2]: '#DAD2D8',
    [-1]: '#DAD2D8',
    0: '#413067',
    1: '#1D9AB6',
    2: '#30B26C',
    3: '#EFAE2F',
    4: '#DA4E4E',
    5: '#9E0059',
    6: '#9E0059',
}