import React from "react";
import { connect } from "react-redux";
import { withStyles } from "@mui/styles";
import PropTypes from "prop-types";
import clsx from "clsx";
import {
    Box, Table, TableBody, TableCell, TableHead, TableRow, Typography, TextField, InputAdornment, SvgIcon,
    IconButton, Tooltip, Dialog, DialogTitle, DialogContent, DialogActions, Button, MenuItem,
    Menu,
    Card,
    CardContent,
    List,
    ListItem,
    ListItemText,
    FormControl,
    RadioGroup,
    FormControlLabel,
    Radio,
} from "@mui/material";
import { Remove, Delete, Block, Add, AllInclusive, Chat, Report, Close } from "@mui/icons-material";
import { mdiMagnify } from "@mdi/js";
import Pagination from '@mui/material/Pagination';
import dayjs from "dayjs";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import axios, { AxiosError } from "axios";
import { setNotification } from "../../../actions/global";
import { GlobalTypes } from "../../../action_types";
import debounce from "lodash.debounce";
import LogDetails from "../../Logs/LogDetails";
import { ERROR_CODES } from "../../../global/error";
import { AxiosClient } from "../../../global/axios";


const styles = theme => ({
    table: {
        border: '2px solid #f0f0f0',
        userSelect: 'text !important',
    },
    tCell: {
        border: 0,
        padding: 32,
        verticalAlign: 'top',
    },
    cellHeader: {
        fontFamily: 'Roboto Slab',
        width: 180,
        fontWeight: 700,
    },
    cellContent: {
        color: '#6b7280 !important',
        fontFamily: 'Roboto Slab !important',
    },
    inputIcon: {
        marginRight: '8px',
        color: '#31204A !important'
    },
});

class ReportList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            search: '',
            filter: 0,
            page: 1,
            size: 10,
            reportList: [],
            totalReports: 0,
            avatarAt: Date.now(),
            password: null,
            banUser: null,
            banUserEl: null,
            banUserType: null,
            banUserUntil: dayjs(new Date()),
            banUserReason: null,
            startDate: null,
            endDate: null,
            logDetails: null,
            showReportInformations: false,
            reportInformation: null,

            repertStatusList: [
                { value: -1, label: 'Kapalı' },
                { value: 0, label: 'Beklemede' },
                { value: 1, label: 'Banlandı' },
            ],
        }
    }

    componentDidMount() {
        this.listReports();
    }

    componentDidUpdate(prevProps) {
    }

    listReports = async () => {
        try {
            const { search, page, size, filter } = this.state;
            const resp = await AxiosClient().get(`${process.env.REACT_APP_API_URL}/v1/admin/users/report.list?search=${search}&filter=${filter}&page=${page}&size=${size}`);

            if (resp.data) {
                this.setState({ reportList: resp.data?.data, totalReports: resp.data?.total });
            }

        } catch (err) {
            console.error(err);
            this.props.setNotification({ message: 'Bir hata oluştu. Lütfen tekrar deneyiniz.', severity: GlobalTypes.NOTIFY_ERR });
        }
    }

    updateReportStatus = async (u, status) => {
        try {
            const resp = await AxiosClient().post(`${process.env.REACT_APP_API_URL}/v1/admin/users/report.user/${u.id}`, {
                status,
            });

            if (resp.data?.ok) {
                this.props.setNotification({ message: 'Rapor durumu güncellendi.', severity: GlobalTypes.NOTIFY_SUCC });
            }

        } catch (e) {
            console.error(e);
            let message = ERROR_CODES[2];

            if (e instanceof AxiosError) {
                const code = e.response?.data?.error?.code;
                if (code in ERROR_CODES) {
                    message = ERROR_CODES[code];
                } else {
                    message = e.message;
                }
            }

            this.props.setNotification({ message: message, severity: GlobalTypes.NOTIFY_ERR, traceId: e.response?.data?.trace_id });

            throw e;
        } finally {
            this.listReports();
        }
    }

    handleBanUser = (e, u) => {
        this.setState({ banUser: u, banUserEl: e.currentTarget });
    }

    banUser = async () => {
        try {
            const { banUser, banUserType, banUserUntil, banUserReason } = this.state;

            if (!banUser) {
                return;
            }


            await this.updateReportStatus(banUser, 1);

            const resp = await AxiosClient().post(`${process.env.REACT_APP_API_URL}/v1/admin/users/${banUserType === 1 ? '' : 'ip.'}${banUserUntil ? 'ban' : 'permanent.ban'}`, {
                username: banUser.reported_username,
                until: banUserUntil?.unix(),
                reason: banUserReason,
            });

            if (resp.data?.ok) {
                this.props.setNotification({ message: 'Kullanıcı banlandı.', severity: GlobalTypes.NOTIFY_SUCC });
            }

        } catch (e) {
            console.error(e);
            let message = ERROR_CODES[2];

            if (e instanceof AxiosError) {
                const code = e.response?.data?.error?.code;
                if (code in ERROR_CODES) {
                    message = ERROR_CODES[code];
                } else {
                    message = e.message;
                }
            }

            this.props.setNotification({ message: message, severity: GlobalTypes.NOTIFY_ERR, traceId: e.response?.data?.trace_id });
        } finally {
            this.setState({ banUser: null, banUserType: null, banUserUntil: dayjs(new Date()), banUserReason: null });
        }
    }

    debounceSearchUser = debounce(() => this.setState({ page: 1 }, () => this.listReports()), 500);

    searchChanged = (e) => {
        this.setState({ search: e.target.value });
        this.debounceSearchUser();
    }

    reportInformationsDialog = () => {
        const { showReportInformations, reportInformation } = this.state;

        return (
            <Dialog open={showReportInformations} onClose={() => this.setState({ showReportInformations: false })}>
                <DialogTitle>Rapor Bilgileri</DialogTitle>
                <DialogContent>
                    <List>
                        <ListItem>
                            <ListItemText
                                primary="Raporlayan"
                                secondary={reportInformation?.reporter_username}
                            />
                        </ListItem>
                        <ListItem>
                            <ListItemText
                                primary="Raporlanan"
                                secondary={reportInformation?.reported_username}
                            />
                        </ListItem>
                        <ListItem>
                            <ListItemText
                                primary="Rapor Nedeni"
                                secondary={reportInformation?.report_reason}
                            />
                        </ListItem>
                        <ListItem>
                            <ListItemText
                                primary="Rapor Açıklaması"
                                secondary={reportInformation?.report_description}
                            />
                        </ListItem>
                        <ListItem>
                            <ListItemText
                                primary="Raporlama Tarihi"
                                secondary={new Date(reportInformation?.created_at).toLocaleDateString()}
                            />
                        </ListItem>
                    </List>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => this.setState({ showReportInformations: false })} variant="contained" color="primary" autoFocus className="!text-white">
                        Kapat
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }

    banUserMenu = () => {
        const { banUser, banUserEl } = this.state;

        return (
            <Menu anchorEl={banUserEl} open={!!banUserEl} onClose={() => this.setState({ banUserEl: null, banUser: null })}>
                <MenuItem onClick={() => this.setState({ banUserType: 1, banUserEl: null })}>Ban</MenuItem>
                <MenuItem onClick={() => this.setState({ banUserType: 2, banUserEl: null })}>IP Ban</MenuItem>
            </Menu>
        );
    }

    banUserDialog = () => {
        const { banUser, banUserType } = this.state;

        const title = () => {
            switch (banUserType) {
                case 2:
                    return 'IP Ban';
                default:
                    return 'Ban';
            }
        }

        const onClose = () => {
            this.setState({ banUser: null, banUserType: null, banUserUntil: dayjs(new Date()), banUserReason: null });
        }

        return (
            <Dialog open={!!banUser && !!banUserType} onClose={onClose}>
                <DialogTitle>
                    {`Kullanıcı ${title()}`}
                </DialogTitle>
                <DialogContent>
                    <Table>
                        <TableBody>
                            <TableRow>
                                <TableCell className="!border-0 !p-2">
                                    <Typography sx={{ color: '#413067' }}>
                                        Sebep
                                    </Typography>
                                </TableCell>
                                <TableCell className="!border-0 !p-2">
                                    <TextField variant="outlined" fullWidth margin="dense"
                                        value={this.state.banUserReason}
                                        sx={{
                                            borderTopRightRadius: '0 !important',
                                            borderBottomRightRadius: '0 !important',
                                            margin: 0,
                                        }}
                                        InputProps={{
                                            classes: {
                                                input: "h-8 sm:h-12",
                                                root: "h-8 sm:h-12",
                                            }
                                        }}
                                        onChange={e => this.setState({ banUserReason: e.target.value })}
                                    />
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell className="!border-0 !p-2">
                                    <Typography sx={{ color: '#413067' }}>
                                        Tarih
                                    </Typography>
                                </TableCell>
                                <TableCell className="!border-0 !p-2">
                                    <Box className="flex gap-x-1 items-center">

                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <DatePicker format="DD/MM/YYYY" onChange={date => this.setState({ banUserUntil: date })} value={this.state.banUserUntil}
                                                disabled={!Boolean(this.state.banUserUntil)} disablePast
                                            />
                                        </LocalizationProvider>

                                        <Tooltip title="1 gün azalt" placement="top">
                                            <IconButton onClick={() => this.setState({ banUserUntil: this.state.banUserUntil?.subtract?.(1, 'day') ?? dayjs(new Date()) })}
                                                className="w-9 h-9"
                                            >
                                                <SvgIcon>
                                                    <Remove />
                                                </SvgIcon>
                                            </IconButton>
                                        </Tooltip>

                                        <Tooltip title="1 gün arttır" placement="top">
                                            <IconButton onClick={() => this.setState({ banUserUntil: this.state.banUserUntil?.add?.(1, 'day') ?? dayjs(new Date()) })}
                                                className="w-9 h-9"
                                            >
                                                <SvgIcon>
                                                    <Add />
                                                </SvgIcon>
                                            </IconButton>
                                        </Tooltip>

                                        <Tooltip title="Süresiz" placement="top">
                                            <IconButton onClick={() => this.setState({ banUserUntil: this.state.banUserUntil ? null : dayjs(new Date()) })}
                                                className={clsx(this.state.banUserUntil ? null : "!bg-blue-400", "w-9 h-9")}
                                            >
                                                <SvgIcon>
                                                    <AllInclusive htmlColor={this.state.banUserUntil ? null : 'white'} />
                                                </SvgIcon>
                                            </IconButton>
                                        </Tooltip>
                                    </Box>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onClose} variant="contained" color="error">
                        İptal
                    </Button>
                    <Button onClick={() => this.banUser()} variant="contained" color="primary" autoFocus className="!text-white">
                        Banla
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }

    tableView = () => {
        const { classes } = this.props;
        const { reportList } = this.state;

        if (reportList?.length === 0) {
            return (
                <Box className="!hidden lg:!flex justify-center items-center h-[300px]">
                    <Typography variant="h6" className={classes.title}>Sonuç bulunamadı.</Typography>
                </Box>
            );
        }

        return (
            <Table className={clsx(
                classes.table,
                "!hidden lg:!table",
            )}>
                <TableHead>
                    <TableRow>
                        <TableCell className={classes.tCell} sx={{ borderBottom: '2px solid #f0f0f0', minWidth: 0, maxWidth: 0 }}>
                            <Typography className={clsx(classes.cellHeader, "!text-xs md:!text-sm lg:!text-base")}>
                                Raporlayan
                            </Typography>
                        </TableCell>
                        <TableCell className={classes.tCell} sx={{ borderBottom: '2px solid #f0f0f0', minWidth: 0, maxWidth: 0 }}>
                            <Typography className={clsx(classes.cellHeader, "!text-xs md:!text-sm lg:!text-base")}>
                                Raporlanan
                            </Typography>
                        </TableCell>
                        <TableCell className={classes.tCell} sx={{ borderBottom: '2px solid #f0f0f0', minWidth: 0, maxWidth: 0 }}>
                            <Typography className={clsx(classes.cellHeader, "!text-xs md:!text-sm lg:!text-base")}>
                                Şikayet Nedeni
                            </Typography>
                        </TableCell>
                        <TableCell className={classes.tCell} sx={{ borderBottom: '2px solid #f0f0f0', minWidth: 0, maxWidth: 0 }}>
                            <Typography className={clsx(classes.cellHeader, "!text-xs md:!text-sm lg:!text-base")}>
                                Rapor Tarihi
                            </Typography>
                        </TableCell>
                        <TableCell className={classes.tCell} sx={{ borderBottom: '2px solid #f0f0f0', minWidth: 0, maxWidth: 0 }}>
                            <Typography className={clsx(classes.cellHeader, "!text-xs md:!text-sm lg:!text-base")}>
                                Rapor Bilgileri
                            </Typography>
                        </TableCell>
                        <TableCell className={classes.tCell} sx={{ borderBottom: '2px solid #f0f0f0', minWidth: 0, maxWidth: 0 }}>
                            <Typography className={clsx(classes.cellHeader, "!text-xs md:!text-sm lg:!text-base")}>
                                Aksiyon
                            </Typography>
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {reportList?.map(u => {
                        return (
                            <TableRow key={u.id}>
                                <TableCell>
                                    <Typography className={clsx(classes.cellContent, "!text-xs md:!text-sm lg:!text-base")}>
                                        {u.reporter_username}
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography className={clsx(classes.cellContent, "!text-xs md:!text-sm lg:!text-base")}>
                                        {u.reported_username}
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Tooltip title={u.report_reason} placement="top">
                                        <Typography className={clsx(classes.cellContent, "!text-xs md:!text-sm lg:!text-base")}>
                                            {u.report_reason?.length > 20 ? `${u.report_reason?.substring(0, 20)}...` : u.report_reason}
                                        </Typography>
                                    </Tooltip>
                                </TableCell>
                                <TableCell>
                                    <Typography className={clsx(classes.cellContent, "!text-xs md:!text-sm lg:!text-base")}>
                                        {new Date(u.created_at).toLocaleDateString()}
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    {(u?.message_started_at && u?.message_ended_at && u?.room) && <Tooltip title="Mesajları Gör" placement="top">
                                        <IconButton onClick={() => { (u?.message_started_at && u?.message_ended_at && u?.room) && this.setState({ logDetails: { id: u.id, user_id: u.reported_user, username: u.reported_username, dm_username: u.room !== "salon" ? u.reporter_username : null, room: u.room === "salon" ? u.room : u.reported_by, pm: u.room !== "salon" }, startDate: u.message_started_at, endDate: u.message_ended_at }) }}>
                                            <Chat fontSize="small" />
                                        </IconButton>
                                    </Tooltip>}
                                    <Tooltip title="Rapor Bilgileri" placement="top">
                                        <IconButton onClick={() => this.setState({ showReportInformations: true, reportInformation: u })}>
                                            <Report fontSize="small" />
                                        </IconButton>
                                    </Tooltip>
                                </TableCell >
                                <TableCell>
                                    {this.state.filter === 0 && (
                                        <Tooltip title="Raporu Kapat" placement="top">
                                            <IconButton onClick={() => this.updateReportStatus(u, -1)}>
                                                <Close fontSize="small" />
                                            </IconButton>
                                        </Tooltip>
                                    )}
                                    {this.state.filter === 0 && (
                                        <Tooltip title="Banla" placement="top">
                                            <IconButton onClick={(e) => this.handleBanUser(e, u)}>
                                                <Block fontSize="small" />
                                            </IconButton>
                                        </Tooltip>
                                    )}
                                </TableCell>
                            </TableRow >
                        );
                    })
                    }
                </TableBody >
            </Table >
        );
    }

    mobileView = () => {
        const { classes } = this.props;
        const { reportList } = this.state;

        if (reportList?.length === 0) {
            return (
                <Box className="flex lg:hidden justify-center items-center">
                    <Typography variant="h6" className={classes.title}>Sonuç bulunamadı.</Typography>
                </Box>
            );
        }

        return (
            <Box className="flex flex-col gap-y-2 lg:hidden">
                {reportList?.map(u => {
                    return (
                        <Card key={u.id} sx={{ borderRadius: 4, border: '1px solid #c4c4c4', boxShadow: 0 }}>
                            <CardContent>
                                <Table>
                                    <TableRow>
                                        <TableCell className="!p-2">
                                            <Typography className={clsx(classes.cellHeader, "!text-xs md:!text-sm lg:!text-base")}>
                                                Raporlayan
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography className={clsx(classes.cellContent, "!text-xs md:!text-sm lg:!text-base")}>
                                                {u.reporter_username}
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell className="!p-2">
                                            <Typography className={clsx(classes.cellHeader, "!text-xs md:!text-sm lg:!text-base")}>
                                                Raporlanan
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography className={clsx(classes.cellContent, "!text-xs md:!text-sm lg:!text-base")}>
                                                {u.reported_username}
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell className="!p-2">
                                            <Typography className={clsx(classes.cellHeader, "!text-xs md:!text-sm lg:!text-base")}>
                                                Şikayet Nedeni
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Tooltip title={u.report_reason} placement="top">
                                                <Typography className={clsx(classes.cellContent, "!text-xs md:!text-sm lg:!text-base")}>
                                                    {u.report_reason?.length > 20 ? `${u.report_reason?.substring(0, 20)}...` : u.report_reason}
                                                </Typography>
                                            </Tooltip>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell className="!p-2">
                                            <Typography className={clsx(classes.cellHeader, "!text-xs md:!text-sm lg:!text-base")}>
                                                Rapor Tarihi
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography className={clsx(classes.cellContent, "!text-xs md:!text-sm lg:!text-base")}>
                                                {new Date(u.created_at).toLocaleDateString()}
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell className="!p-2">
                                            <Typography className={clsx(classes.cellHeader, "!text-xs md:!text-sm lg:!text-base")}>
                                                Rapor Bilgileri
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Tooltip title="Mesajları Gör" placement="top">
                                                <IconButton onClick={() => this.setState({ logDetails: { id: u.id, user_id: u.reported_user, username: u.reported_username, dm_username: u.room !== "salon" ? u.reporter_username : null, room: u.room, pm: u.room !== "salon" }, startDate: u.message_started_at, endDate: u.message_ended_at })}>
                                                    <Chat fontSize="small" />
                                                </IconButton>
                                            </Tooltip>
                                            <Tooltip title="Rapor Bilgileri" placement="top">
                                                <IconButton onClick={() => this.setState({ showReportInformations: true, reportInformation: u })}>
                                                    <Report fontSize="small" />
                                                </IconButton>
                                            </Tooltip>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell className="!p-2">
                                            <Typography className={clsx(classes.cellContent, "!text-xs md:!text-sm lg:!text-base")}>
                                                Aksiyon
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Tooltip title="Raporu Kapat" placement="top">
                                                <IconButton onClick={() => this.updateReportStatus(u, -1)}>
                                                    <Close fontSize="small" />
                                                </IconButton>
                                            </Tooltip>
                                            <Tooltip title="Banla" placement="top">
                                                <IconButton onClick={(e) => this.handleBanUser(e, u)}>
                                                    <Block fontSize="small" />
                                                </IconButton>
                                            </Tooltip>
                                        </TableCell>
                                    </TableRow>
                                </Table>
                            </CardContent>
                        </Card>
                    );
                })}
            </Box>
        );
    }

    render() {
        return (
            <Box margin={1} display={"flex"} flexDirection={"column"} rowGap={1}>
                <Box className="flex gap-x-2 justify-between items-center">
                    <FormControl>
                        <RadioGroup value={this.state.filter}>
                            <Box className="px-2 py-1">
                                {this.state.repertStatusList.map((r, i) => {
                                    return (
                                        <FormControlLabel key={i} value={r.value} control={
                                            <Radio onChange={(e) => this.setState({ filter: r.value, page: 1 }, () => this.listReports())}
                                                checked={this.state.filter === r.value}
                                            />
                                        } label={
                                            <Typography className="!text-xs md:!text-sm lg:!text-base">
                                                {r.label}
                                            </Typography>
                                        }
                                        />
                                    );
                                })}
                            </Box>
                        </RadioGroup>
                    </FormControl>
                </Box>

                <TextField variant="outlined" placeholder="Kişi Ara" fullWidth margin="dense"
                    InputProps={{
                        classes: {
                            input: "!text-xs md:!text-sm lg:!text-base",
                            root: "p-1 md:p-2"
                        },
                        startAdornment: (
                            <InputAdornment position="start">
                                <SvgIcon fontSize='small'>
                                    <path d={mdiMagnify} />
                                </SvgIcon>
                            </InputAdornment>
                        )
                    }}
                    value={this.state.search}
                    onChange={this.searchChanged}
                    sx={{
                        '& .MuiInputBase-root': { p: 0, px: 2 },
                        '& .MuiInputBase-root::before': { borderBottom: '1px solid #E3E3E3 !important' },
                        '& .MuiInputBase-root::after': { borderBottom: '1px solid #E3E3E3 !important' },
                    }}
                />

                {this.tableView()}
                {this.mobileView()}
                <LogDetails
                    startDate={this.state.startDate}
                    endDate={this.state.endDate}
                    log={this.state.logDetails}
                    onClose={() => this.setState({ logDetails: null })}
                    report
                />

                <Box className="flex justify-end">
                    <Pagination count={parseInt(Math.ceil(this.state.totalReports / this.state.size))} page={this.state.page}
                        variant="outlined" shape="rounded" color="primary"
                        onChange={(e, p) => this.setState({ page: p }, () => this.listUsers())}
                    />
                </Box>

                {this.reportInformationsDialog()}
                {this.banUserMenu()}
                {this.banUserDialog()}
            </Box>
        );
    }
}

ReportList.propTypes = {
    classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
    return {
    }
};

export default withStyles(styles)(connect(mapStateToProps, {
    setNotification,
})(ReportList));
