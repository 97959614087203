import React from "react";
import { connect } from "react-redux";
import { withStyles } from "@mui/styles";
import PropTypes from "prop-types";
import { TextField } from "@mui/material";
import { shortcuts } from "./shortcuts";
import { roles } from "../../global/role";


const userTabRe = /(?:^|\W)@(\w+)(?!\w)/g;

class Chatbar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            message: '',
            smileyAnchorEl: null,
            presentAnchorEl: null,
        }

        this.userTabPrefix = '';
        this.userTabIndex = 0;
    }

    componentDidMount() {
        this.props.onRef?.(this);
    }

    componentDidUpdate(prevProps) {
    }

    onTabUser = () => {
        const { message } = this.state;

        const words = message.trim().split(' ');
        let last = words.pop();
        if (!last) {
            return;
        }

        if (this.userTabIndex === 0) {
            this.userTabPrefix = last.startsWith("@") ? last.substring(1) : last;
        }

        const filteredUsers = Object.values(this.props.users).filter((user) => {
            return user?.username?.toLowerCase()?.startsWith(this.userTabPrefix.toLowerCase());
        });

        if (filteredUsers.length === 0) {
            return;
        }

        const tabbedUser = filteredUsers[this.userTabIndex % filteredUsers.length];
        this.userTabIndex++;
        //this.userTabIndex %= filteredUsers.length;

        words.push('@' + tabbedUser.username + ' ');

        this.setState({ message: words.join(' ') });
    }

    onTabUserWithUsername = (username) => {
        let { message } = this.state;

        if (!message) {
            message = '';
        }

        if (message.length > 0 && !message.endsWith(' ')) {
            message += ' ';
        }

        if (!username) {
            return;
        }

        this.setState({ message: `${message}@${username} ` });
    }

    onMessageSend = () => {
        const { message } = this.state;
        const { selectedTab } = this.props;

        if (!message) {
            return;
        }

        let tab, tabs = [];
        while (tab = userTabRe.exec(message)) {
            tabs.push(tab[1]);
        }


        this.props.socket?.emit('send_message', { message, room: selectedTab, pm: Boolean(this.props.pm), mentions: tabs });
        this.setState({ message: '' });
    }

    onChatboxKeydown = (e) => {
        switch (e.key) {
            case 'Tab':
                e.preventDefault();
                this.onTabUser();
                return;
            case 'Enter':
                this.userTabIndex = 0;
                this.userTabPrefix = '';
                this.onMessageSend();
                return;
            default:
                this.userTabIndex = 0;
                this.userTabPrefix = '';
                break;
        }
    }

    onSmileySelected = (smiley) => {
        const { message } = this.state;
        this.setState({ message: message + shortcuts[smiley] });
    }

    focusSendRef = () => {
        const { message } = this.state;
        this.props.sendRef?.current?.focus();
        this.props.sendRef?.current?.setSelectionRange(message.length, message.length);
    }

    render() {

        return (
            <form className="flex-1"
                onSubmit={(e) => { delete e['returnValue']; e.preventDefault(); }}
            >
                <TextField margin="dense" fullWidth
                    value={this.state.message} sx={{
                        borderTopRightRadius: '0 !important',
                        borderBottomRightRadius: '0 !important',
                        margin: 0,
                        '& .MuiInputBase-root': {
                            borderTopRightRadius: '0 !important',
                            borderBottomRightRadius: '0 !important',
                        },
                        '& .MuiOutlinedInput-root': {
                            //height: '24px',
                        },
                        '& .MuiOutlinedInput-root': {
                            '& input': {
                                overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap',
                                '&::placeholder': {
                                    color: '#1D9AB6 !important', opacity: 1
                                },
                            },
                        },
                    }}
                    InputProps={{
                        classes: {
                            input: "h-8 sm:h-12 !text-xs md:!text-sm lg:!text-base",
                            root: "h-8 sm:h-12 !bg-[#f6f6ff]",
                        }
                    }}
                    disabled={this.props.disabled}
                    placeholder={this.props.reporting ? "Raporlama aktif, lütfen bildirmek istediğiniz mesajı seçiniz." : this.props.label}
                    //autoFocus={true}
                    autoComplete="off"
                    inputRef={this.props.sendRef}
                    onChange={(e) => this.setState({ message: e.target.value })}
                    onKeyDown={e => this.onChatboxKeydown(e)}
                    onPaste={(e) => this.props.role < roles.Moderator && e.preventDefault()}
                />
            </form>
        );
    }
}

Chatbar.propTypes = {
    classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
    return {
        role: state.app.global.role,
        selectedTab: state.app.global.selectedTab,
        socket: state.app.global.socket,
    }
};

export default connect(mapStateToProps, {
})(Chatbar);
