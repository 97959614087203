import React from "react";
import { connect } from "react-redux";
import { withStyles } from "@mui/styles";
import PropTypes from "prop-types";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Tooltip, Typography } from "@mui/material";
import { Close } from "@mui/icons-material";
import { setNotification } from "../../actions/global";

const styles = theme => ({
});

class PlanedEvents extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
        }
    }

    onClose = () => {
        this.setState({});
        this.props.onClose();
    }

    render() {
        const { classes } = this.props;

        return (
            <Dialog
                maxWidth={"xs"}
                fullWidth
                open={this.props.open}
            >
                <DialogTitle>
                    <Box padding={0} display={"flex"} justifyContent={"space-between"}>
                        <Typography className={"!text-xs sm:!text-sm md:!text-base"}>
                            Planlı Etkinlikler
                        </Typography>

                        <Tooltip title="Kapat" placement="top">
                            <IconButton size="small" onClick={this.onClose} sx={{ backgroundColor: '#624799 !important' }} tabIndex={-1}>
                                <Close htmlColor="var(--white)" fontSize="small" />
                            </IconButton>
                        </Tooltip>
                    </Box>
                </DialogTitle>

                <DialogContent sx={{ p: 0 }}>
                    <Box sx={{ height: "200px", display: "flex", alignItems: "center", justifyContent: "center" }}>
                        <Typography sx={{ textAlign: "center" }} className="!text-xs sm:!text-sm md:!text-base">
                            Planlı etkinlik bulunamadı.
                        </Typography>
                    </Box>
                </DialogContent>

                <DialogActions sx={{ p: 2 }}>
                    <Button variant="contained" disabled={false} onClick={() => this.onClose()}
                        disableFocusRipple disableRipple disableTouchRipple color="error"
                        sx={{
                            color: '#FFFFFF',
                        }}
                        className="!text-xs sm:!text-sm md:!text-base"
                    >
                        Kapat
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

PlanedEvents.propTypes = {
    classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
    return {
    }
};

export default withStyles(styles)(connect(mapStateToProps, {
    setNotification,
})(PlanedEvents));
