import React from "react";
import { connect } from "react-redux";
import { withStyles } from "@mui/styles";
import PropTypes from "prop-types";
import clsx from "clsx";
import {
    Box, Table, TableBody, TableCell, TableHead, TableRow, Typography, TextField, InputAdornment, SvgIcon, Avatar,
    IconButton, Tooltip, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, MenuItem,
    Menu,
    Card,
    CardContent,
} from "@mui/material";
import {
    Edit, Remove, Delete, Block, Add, AllInclusive, Person,
} from "@mui/icons-material";
import { mdiMagnify } from "@mdi/js";
import Pagination from '@mui/material/Pagination';
import dayjs from "dayjs";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import axios from "axios";
import { setNotification } from "../../../actions/global";
import { GlobalTypes } from "../../../action_types";
import { generateKey } from "../../../global/pbkdf2";
import forge from 'node-forge';
import debounce from "lodash.debounce";
import UploadPhoto from "../../Header/UploadPhoto";
import { AxiosClient } from "../../../global/axios";
import { generateRequestId } from "../../../global/session";
import ProfileDetails from "../../User/ProfileDetails";


const styles = theme => ({
    table: {
        border: '2px solid #f0f0f0',
        userSelect: 'text !important',
    },
    tCell: {
        border: 0,
        padding: 32,
        verticalAlign: 'top',
    },
    cellHeader: {
        fontFamily: 'Roboto Slab',
        width: 180,
        fontWeight: 700,
    },
    cellContent: {
        color: '#6b7280 !important',
        fontFamily: 'Roboto Slab !important',
    },
    text: {
        fontWeight: '400 !important',
        color: 'var(--white)',
    },
    inputIcon: {
        marginRight: '8px',
        color: '#31204A !important'
    },
});

class Users extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            search: '',
            page: 1,
            size: 10,
            users: [],
            totalUsers: 0,
            avatarAt: Date.now(),
            userProfile: null,
            editUser: null,
            deleteUser: null,
            editUserAvatar: false,
            deleteUserAvatar: false,
            password: null,
            banUser: null,
            banUserEl: null,
            banUserType: null,
            banUserUntil: dayjs(new Date()),
            banUserReason: null,
        }

    }

    componentDidMount() {
        this.listUsers();
    }

    componentDidUpdate(prevProps) {
    }

    listUsers = async () => {
        try {
            const { search, page, size } = this.state;
            const resp = await AxiosClient().get(`${process.env.REACT_APP_API_URL}/v1/admin/users?search=${search}&page=${page}&size=${size}`);

            if (resp.data?.data) {
                this.setState({ users: resp.data.data, totalUsers: resp.data.total });
            }

        } catch (err) {
            console.error(err);
            this.props.setNotification({ message: 'Bir hata oluştu. Lütfen tekrar deneyiniz.', severity: GlobalTypes.NOTIFY_ERR });
        }
    }

    handleOpenUserProfile = (u) => {
        this.setState({ userProfile: u });
    }

    handleEditUser = (u) => {
        this.setState({ editUser: u });
    }

    handleDeleteUser = (u) => {
        this.setState({ deleteUser: u });
    }

    handleBanUser = (e, u) => {
        this.setState({ banUser: u, banUserEl: e.currentTarget });
    }

    deleteUser = async (u) => {
        try {
            const { deleteUser } = this.state;

            const resp = await AxiosClient().delete(`${process.env.REACT_APP_API_URL}/v1/admin/users/${deleteUser.id}`);

            if (resp.data?.ok) {
                this.props.setNotification({ message: 'Kullanıcı silindi.', severity: GlobalTypes.NOTIFY_SUCC });
            }

        } catch (err) {
            console.error(err);
            this.props.setNotification({ message: 'Bir hata oluştu. Lütfen tekrar deneyiniz.', severity: GlobalTypes.NOTIFY_ERR });
        } finally {
            this.setState({ deleteUser: null }, () => this.listUsers());
        }
    }

    deleteUserAvatar = async () => {
        try {
            const { editUser } = this.state;

            const resp = await AxiosClient().delete(`${process.env.REACT_APP_API_URL}/v1/admin/users/${editUser.id}/profile/photo`);

            if (resp.data?.ok) {
                this.props.setNotification({ message: 'Kullanıcı avatarı silindi.', severity: GlobalTypes.NOTIFY_SUCC });
            }

        } catch (err) {
            console.error(err);
            this.props.setNotification({ message: 'Bir hata oluştu. Lütfen tekrar deneyiniz.', severity: GlobalTypes.NOTIFY_ERR });
        } finally {
            this.setState({ deleteUserAvatar: false, avatarAt: Date.now() });
        }
    }

    createCredentials = async (email, password) => {
        return new Promise((resolve, reject) => {
            forge.pki.rsa.generateKeyPair({ bits: 1024 }, async (err, pair) => {
                try {

                    const privatePem = forge.pki.privateKeyToPem(pair.privateKey);
                    const public_key = forge.pki.publicKeyToPem(pair.publicKey);

                    const iv = forge.random.getBytesSync(16);
                    const salt = forge.random.getBytesSync(16);

                    const key = generateKey(email, password, salt);

                    var cipher = forge.aes.createEncryptionCipher(key);
                    cipher.start(iv);
                    cipher.update(forge.util.createBuffer(privatePem));
                    cipher.finish();

                    const enc_private_key = forge.util.bytesToHex(cipher.output.getBytes());

                    const creds = {
                        enc_private_key,
                        public_key,
                        salt: forge.util.bytesToHex(salt),
                        iv: forge.util.bytesToHex(iv),
                    };

                    resolve(creds);
                }
                catch (e) {
                    reject(e);
                }
            })
        });
    }

    editUser = async () => {
        try {
            const { editUser, password } = this.state;

            let data = {
                username: editUser.username,
                email: editUser.newEmail ?? editUser.email,
                gender: editUser.gender,
                points: editUser.points,
            }

            // email changed but password not
            if (data.email !== editUser.email && !password) {
                this.props.setNotification({ message: 'Email adresini değiştirirken yeni şifre alanı zorunludur.', severity: GlobalTypes.NOTIFY_ERR });
                return;
            }

            if (password || data.email !== editUser.email) {
                const creds = await this.createCredentials(editUser.email, password);
                data = { ...data, ...creds };
            }

            const resp = await AxiosClient().post(`${process.env.REACT_APP_API_URL}/v1/admin/users/${editUser.id}`, data);

            if (resp.data?.ok) {
                this.props.setNotification({ message: 'Kullanıcı düzenlendi.', severity: GlobalTypes.NOTIFY_SUCC });
            }

        } catch (err) {
            console.error(err);
            this.props.setNotification({ message: 'Bir hata oluştu. Lütfen tekrar deneyiniz.', severity: GlobalTypes.NOTIFY_ERR });
        } finally {
            this.setState({ editUser: null, password: null }, () => this.listUsers());
        }
    }

    banUser = async () => {
        try {
            const { banUser, banUserType, banUserUntil, banUserReason } = this.state;

            if (!banUser) {
                return;
            }

            const resp = await AxiosClient().post(`${process.env.REACT_APP_API_URL}/v1/admin/users/${banUserType === 1 ? '' : 'ip.'}${banUserUntil ? 'ban' : 'permanent.ban'}`, {
                username: banUser.username,
                until: banUserUntil?.unix(),
                reason: banUserReason,
            });

            if (resp.data?.ok) {
                this.props.setNotification({ message: 'Kullanıcı banlandı.', severity: GlobalTypes.NOTIFY_SUCC });
            }

        } catch (err) {
            console.error(err);
            this.props.setNotification({ message: 'Bir hata oluştu. Lütfen tekrar deneyiniz.', severity: GlobalTypes.NOTIFY_ERR });
        } finally {
            this.setState({ banUser: null, banUserType: null, banUserUntil: dayjs(new Date()), banUserReason: null });
        }
    }

    debounceSearchUser = debounce(() => this.setState({ page: 1 }, () => this.listUsers()), 500);

    searchChanged = (e) => {
        this.setState({ search: e.target.value });
        this.debounceSearchUser();
    }

    // UI PART

    userProfileDialog = () => {
        const { userProfile } = this.state;

        const onClose = () => {
            this.setState({ userProfile: null });
        }

        return (
            <Dialog open={!!userProfile} onClose={onClose}>
                <DialogTitle>{userProfile?.username} Profili</DialogTitle>
                <DialogContent>
                    <ProfileDetails user={userProfile} />
                </DialogContent>
                <DialogActions>
                    <Button onClick={onClose} variant="contained" color="error">
                        Kapat
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }

    editUserDialog = () => {
        const { editUser, avatarAt } = this.state;

        return (
            <Dialog open={!!editUser} onClose={() => this.setState({ editUser: null })}>
                <DialogTitle>Kullanıcı Düzenle</DialogTitle>
                <DialogContent>
                    <Table>
                        <TableBody>
                            <TableRow>
                                <TableCell className="!border-0 !p-2 min-w-32">
                                    <Typography className="!text-gray-500">
                                        Avatar
                                    </Typography>
                                </TableCell>
                                <TableCell className="!border-0 !p-2 min-w-32">
                                    <Box className="flex items-center gap-x-2">
                                        <Avatar src={`${process.env.REACT_APP_STORAGE_URL}/users/${editUser?.id}/${editUser?.profile_photo}`} key={avatarAt}
                                            classes={{ root: 'min-w-[2em] min-h-[2em] max-w-full max-h-full md:min-w-[3em] md:min-h-[3em]' }}
                                        />

                                        <Tooltip title="Düzenle" placement="top">
                                            <IconButton onClick={() => this.setState({ editUserAvatar: true })} className="h-6 w-6">
                                                <Edit fontSize="small" />
                                            </IconButton>
                                        </Tooltip>

                                        <Tooltip title="Sil" placement="top">
                                            <IconButton onClick={() => this.setState({ deleteUserAvatar: true })} className="h-6 w-6">
                                                <Delete fontSize="small" />
                                            </IconButton>
                                        </Tooltip>
                                    </Box>
                                </TableCell>
                            </TableRow>

                            <TableRow>
                                <TableCell className="!border-0 !p-2 min-w-32">
                                    <Typography className="!text-gray-500">
                                        Nick
                                    </Typography>
                                </TableCell>
                                <TableCell className="!border-0 !p-2 min-w-32">
                                    <TextField variant="outlined" fullWidth margin="dense"
                                        value={editUser?.username}
                                        sx={{
                                            borderTopRightRadius: '0 !important',
                                            borderBottomRightRadius: '0 !important',
                                            margin: 0,
                                        }}
                                        InputProps={{
                                            classes: {
                                                input: "h-8 sm:h-12",
                                                root: "h-8 sm:h-12",
                                            }
                                        }}
                                        onChange={e => this.setState({ editUser: { ...editUser, username: e.target.value } })}
                                    />
                                </TableCell>
                            </TableRow>

                            <TableRow>
                                <TableCell className="!border-0 !p-2 min-w-32">
                                    <Typography className="!text-gray-500">
                                        Mail Adresi
                                    </Typography>
                                </TableCell>
                                <TableCell className="!border-0 !p-2 min-w-32">
                                    <TextField variant="outlined" fullWidth margin="dense"
                                        value={editUser?.newEmail ?? editUser?.email}
                                        sx={{
                                            borderTopRightRadius: '0 !important',
                                            borderBottomRightRadius: '0 !important',
                                            margin: 0,
                                        }}
                                        InputProps={{
                                            classes: {
                                                input: "h-8 sm:h-12",
                                                root: "h-8 sm:h-12",
                                            }
                                        }}
                                        onChange={e => this.setState({ editUser: { ...editUser, newEmail: e.target.value } })}
                                    />
                                </TableCell>
                            </TableRow>

                            <TableRow>
                                <TableCell className="!border-0 !p-2 min-w-32">
                                    <Typography className="!text-gray-500">
                                        Cinsiyeti
                                    </Typography>
                                </TableCell>
                                <TableCell className="!border-0 !p-2 min-w-32">
                                    <TextField
                                        autoComplete='new-password'
                                        fullWidth
                                        select
                                        margin="dense"
                                        variant="outlined"
                                        InputProps={{
                                            //startAdornment: (
                                            //    <InputAdornment className={classes.inputIcon} position="start">
                                            //        <SvgIcon><path d={mdiGenderMaleFemale} /></SvgIcon>
                                            //    </InputAdornment>
                                            //)
                                        }}
                                        value={editUser?.gender ?? '_'}
                                        onChange={(e) => this.setState({ editUser: { ...editUser, gender: e.target.value } })}
                                    >
                                        <MenuItem value={"_"} key={"_"}>Cinsiyet</MenuItem>
                                        <MenuItem value={"male"} key={"male"}>Bay</MenuItem>
                                        <MenuItem value={"female"} key={"female"}>Bayan</MenuItem>
                                    </TextField>
                                </TableCell>
                            </TableRow>

                            <TableRow>
                                <TableCell className="!border-0 !p-2 min-w-32">
                                    <Typography className="!text-gray-500">
                                        Puanı
                                    </Typography>
                                </TableCell>
                                <TableCell className="!border-0 !p-2 min-w-32">
                                    <TextField variant="outlined" fullWidth margin="dense"
                                        value={editUser?.points}
                                        sx={{
                                            borderTopRightRadius: '0 !important',
                                            borderBottomRightRadius: '0 !important',
                                            margin: 0,
                                        }}
                                        InputProps={{
                                            classes: {
                                                input: "h-8 sm:h-12",
                                                root: "h-8 sm:h-12",
                                            }
                                        }}
                                        onChange={e => this.setState({ editUser: { ...editUser, points: parseInt(e.target.value) } })}
                                    />
                                </TableCell>
                            </TableRow>

                            <TableRow>
                                <TableCell className="!border-0 !p-2 min-w-32">
                                    <Typography className="!text-gray-500">
                                        Yeni Şifre
                                    </Typography>
                                </TableCell>
                                <TableCell className="!border-0 !p-2 min-w-32">
                                    <TextField autoComplete='new-password' fullWidth margin="dense" variant="outlined" type="password"
                                        sx={{
                                            borderTopRightRadius: '0 !important',
                                            borderBottomRightRadius: '0 !important',
                                            margin: 0,
                                        }}
                                        InputProps={{
                                            classes: {
                                                input: "h-8 sm:h-12",
                                                root: "h-8 sm:h-12",
                                            }
                                        }}
                                        value={this.state.password}
                                        onChange={(e) => this.setState({ password: e.target.value })}
                                    />
                                </TableCell>
                            </TableRow>

                        </TableBody>
                    </Table>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => this.setState({ editUser: null })} variant="contained" color="error">
                        İptal
                    </Button>
                    <Button onClick={() => this.editUser()} variant="contained" color="primary" autoFocus className="!text-white">
                        Onayla
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }

    deleteUserDialog = () => {
        const { deleteUser } = this.state;

        return (
            <Dialog open={!!deleteUser} onClose={() => this.setState({ deleteUser: null })}>
                <DialogTitle>Kullanıcı Sil</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Kullanıcı silinecek, emin misiniz?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => this.setState({ deleteUser: null })} variant="contained" color="error">
                        İptal
                    </Button>
                    <Button onClick={() => this.deleteUser()} variant="contained" color="primary" autoFocus className="!text-white">
                        Sil
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }

    deleteUserAvatarDialog = () => {
        const { deleteUserAvatar } = this.state;

        return (
            <Dialog open={!!deleteUserAvatar} onClose={() => this.setState({ deleteUserAvatar: null })}>
                <DialogTitle>Kullanıcı Avatarı Sil</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Kullanıcı avatarı silinecek, emin misiniz?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => this.setState({ deleteUserAvatar: null })} variant="contained" color="error">
                        İptal
                    </Button>
                    <Button onClick={() => this.deleteUserAvatar()} variant="contained" color="primary" autoFocus className="!text-white">
                        Sil
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }

    banUserMenu = () => {
        const { banUser, banUserEl } = this.state;

        return (
            <Menu anchorEl={banUserEl} open={!!banUserEl} onClose={() => this.setState({ banUserEl: null, banUser: null })}>
                <MenuItem onClick={() => this.setState({ banUserType: 1, banUserEl: null })}>Ban</MenuItem>
                <MenuItem onClick={() => this.setState({ banUserType: 2, banUserEl: null })}>IP Ban</MenuItem>
            </Menu>
        );
    }

    banUserDialog = () => {
        const { banUser, banUserType } = this.state;

        const title = () => {
            switch (banUserType) {
                case 2:
                    return 'IP Ban';
                default:
                    return 'Ban';
            }
        }

        const onClose = () => {
            this.setState({ banUser: null, banUserType: null, banUserUntil: dayjs(new Date()), banUserReason: null });
        }

        return (
            <Dialog open={!!banUser && !!banUserType} onClose={onClose}>
                <DialogTitle>
                    {`Kullanıcı ${title()}`}
                </DialogTitle>
                <DialogContent>
                    <Table>
                        <TableBody>
                            <TableRow>
                                <TableCell className="!border-0 !p-2">
                                    <Typography sx={{ color: '#413067' }}>
                                        Sebep
                                    </Typography>
                                </TableCell>
                                <TableCell className="!border-0 !p-2">
                                    <TextField variant="outlined" fullWidth margin="dense"
                                        value={this.state.banUserReason}
                                        sx={{
                                            borderTopRightRadius: '0 !important',
                                            borderBottomRightRadius: '0 !important',
                                            margin: 0,
                                        }}
                                        InputProps={{
                                            classes: {
                                                input: "h-8 sm:h-12",
                                                root: "h-8 sm:h-12",
                                            }
                                        }}
                                        onChange={e => this.setState({ banUserReason: e.target.value })}
                                    />
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell className="!border-0 !p-2">
                                    <Typography sx={{ color: '#413067' }}>
                                        Tarih
                                    </Typography>
                                </TableCell>
                                <TableCell className="!border-0 !p-2">
                                    <Box className="flex gap-x-1 items-center">

                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <DatePicker format="DD/MM/YYYY" onChange={date => this.setState({ banUserUntil: date })} value={this.state.banUserUntil}
                                                disabled={!Boolean(this.state.banUserUntil)} disablePast
                                            />
                                        </LocalizationProvider>

                                        <Tooltip title="1 gün azalt" placement="top">
                                            <IconButton onClick={() => this.setState({ banUserUntil: this.state.banUserUntil?.subtract?.(1, 'day') ?? dayjs(new Date()) })}
                                                className="w-9 h-9"
                                            >
                                                <SvgIcon>
                                                    <Remove />
                                                </SvgIcon>
                                            </IconButton>
                                        </Tooltip>

                                        <Tooltip title="1 gün arttır" placement="top">
                                            <IconButton onClick={() => this.setState({ banUserUntil: this.state.banUserUntil?.add?.(1, 'day') ?? dayjs(new Date()) })}
                                                className="w-9 h-9"
                                            >
                                                <SvgIcon>
                                                    <Add />
                                                </SvgIcon>
                                            </IconButton>
                                        </Tooltip>

                                        <Tooltip title="Süresiz" placement="top">
                                            <IconButton onClick={() => this.setState({ banUserUntil: this.state.banUserUntil ? null : dayjs(new Date()) })}
                                                className={clsx(this.state.banUserUntil ? null : "!bg-blue-400", "w-9 h-9")}
                                            >
                                                <SvgIcon>
                                                    <AllInclusive htmlColor={this.state.banUserUntil ? null : 'white'} />
                                                </SvgIcon>
                                            </IconButton>
                                        </Tooltip>
                                    </Box>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onClose} variant="contained" color="error">
                        İptal
                    </Button>
                    <Button onClick={() => this.banUser()} variant="contained" color="primary" autoFocus className="!text-white">
                        Banla
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }

    tableView = () => {
        const { classes } = this.props;
        const { users, avatarAt } = this.state;

        if (users.length === 0) {
            return (
                <Box className="!hidden lg:!flex justify-center items-center h-[300px]">
                    <Typography className={"!text-xs md:!text-sm lg:!text-base"}>Sonuç bulunamadı.</Typography>
                </Box>
            );
        }

        return (
            <Table className={clsx(
                classes.table,
                "!hidden lg:!table",
            )}>
                <TableHead>
                    <TableRow>
                        <TableCell className={classes.tCell} sx={{ borderBottom: '2px solid #f0f0f0', minWidth: 0, maxWidth: 0 }}>
                            <Typography className={clsx(classes.cellHeader, "!w-[2em] md:!w-[3em] !text-xs md:!text-sm lg:!text-base")}>
                                Avatar
                            </Typography>
                        </TableCell>
                        <TableCell className={classes.tCell} sx={{ borderBottom: '2px solid #f0f0f0', minWidth: 0, maxWidth: 0 }}>
                            <Typography className={clsx(classes.cellHeader, "!text-xs md:!text-sm lg:!text-base")}>
                                Nick
                            </Typography>
                        </TableCell>
                        <TableCell className={classes.tCell} sx={{ borderBottom: '2px solid #f0f0f0', minWidth: 0, maxWidth: 0 }}>
                            <Typography className={clsx(classes.cellHeader, "!text-xs md:!text-sm lg:!text-base")}>
                                Mail Adresi
                            </Typography>
                        </TableCell>
                        <TableCell className={classes.tCell} sx={{ borderBottom: '2px solid #f0f0f0', minWidth: 0, maxWidth: 0 }}>
                            <Typography className={clsx(classes.cellHeader, "!text-xs md:!text-sm lg:!text-base")}>
                                Cinsiyeti
                            </Typography>
                        </TableCell>
                        <TableCell className={classes.tCell} sx={{ borderBottom: '2px solid #f0f0f0', minWidth: 0, maxWidth: 0 }}>
                            <Typography className={clsx(classes.cellHeader, "!text-xs md:!text-sm lg:!text-base")}>
                                IP Adresi
                            </Typography>
                        </TableCell>
                        <TableCell className={classes.tCell} sx={{ borderBottom: '2px solid #f0f0f0', minWidth: 0, maxWidth: 0 }}>
                            <Typography className={clsx(classes.cellHeader, "!text-xs md:!text-sm lg:!text-base")}>
                                Kayıt Tarihi
                            </Typography>
                        </TableCell>
                        <TableCell className={classes.tCell} sx={{ borderBottom: '2px solid #f0f0f0', minWidth: 0, maxWidth: 0 }}>
                            <Typography className={clsx(classes.cellHeader, "!text-xs md:!text-sm lg:!text-base")}>
                                Aksiyon
                            </Typography>
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {users.map(u => {
                        return (
                            <TableRow key={u.id}>
                                <TableCell>
                                    <Avatar src={`${process.env.REACT_APP_STORAGE_URL}/users/${u?.id}/${u?.profile_photo}`} key={avatarAt}
                                        classes={{ root: 'min-w-[2em] min-h-[2em] max-w-full max-h-full md:min-w-[3em] md:min-h-[3em]' }}
                                    />
                                </TableCell>
                                <TableCell>
                                    <Typography className={clsx(classes.cellContent, "!text-xs md:!text-sm lg:!text-base")}>
                                        {u.username}
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography className={clsx(classes.cellContent, "!text-xs md:!text-sm lg:!text-base")}>
                                        {u.email}
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography className={clsx(classes.cellContent, "!text-xs md:!text-sm lg:!text-base")}>
                                        {u.gender === 'male' ? 'Bay' : 'Bayan'}
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography className={clsx(classes.cellContent, "!text-xs md:!text-sm lg:!text-base")}>
                                        {u.ip}
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography className={clsx(classes.cellContent, "!text-xs md:!text-sm lg:!text-base")}>
                                        {new Date(u.created_at).toLocaleDateString()}
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Tooltip title="Profil" placement="top">
                                        <IconButton onClick={() => this.handleOpenUserProfile(u)}>
                                            <Person fontSize="small" />
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip title="Düzenle" placement="top">
                                        <IconButton onClick={() => this.handleEditUser(u)}>
                                            <Edit fontSize="small" />
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip title="Sil" placement="top">
                                        <IconButton onClick={() => this.handleDeleteUser(u)} disabled={u?.id === this.props.user?.id}>
                                            <Delete fontSize="small" />
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip title="Banla" placement="top">
                                        <IconButton onClick={(e) => this.handleBanUser(e, u)} disabled={u?.id === this.props.user?.id}>
                                            <Block fontSize="small" />
                                        </IconButton>
                                    </Tooltip>
                                </TableCell>
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
        );
    }

    mobileView = () => {
        const { classes } = this.props;
        const { users, avatarAt } = this.state;

        if (users.length === 0) {
            return (
                <Box className="flex lg:hidden justify-center items-center">
                    <Typography className={"!text-xs md:!text-sm lg:!text-base"}>Sonuç bulunamadı.</Typography>
                </Box>
            );
        }

        return (
            <Box className="flex flex-col gap-y-2 lg:hidden">
                {users.map(u => {
                    return (
                        <Card key={u.id} sx={{ borderRadius: 4, border: '1px solid #c4c4c4', boxShadow: 0 }}>
                            <CardContent>
                                <Table>
                                    <TableRow>
                                        <TableCell className="!p-2">
                                            <Typography className={clsx(classes.cellContent, "!text-xs md:!text-sm lg:!text-base")}>
                                                Avatar
                                            </Typography>
                                        </TableCell>
                                        <TableCell className="!p-2">
                                            <Avatar src={`${process.env.REACT_APP_STORAGE_URL}/users/${u?.id}/${u?.profile_photo}`} key={avatarAt}
                                                classes={{ root: 'min-w-[2em] min-h-[2em] max-w-full max-h-full md:min-w-[3em] md:min-h-[3em]' }}
                                            />
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell className="!p-2">
                                            <Typography className={clsx(classes.cellContent, "!text-xs md:!text-sm lg:!text-base")}>
                                                Nick
                                            </Typography>
                                        </TableCell>
                                        <TableCell className="!p-2">
                                            <Typography className={clsx(classes.cellContent, "!text-xs md:!text-sm lg:!text-base")}>
                                                {u.username}
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell className="!p-2">
                                            <Typography className={clsx(classes.cellContent, "!text-xs md:!text-sm lg:!text-base")}>
                                                Mail Adresi
                                            </Typography>
                                        </TableCell>
                                        <TableCell className="!p-2">
                                            <Typography className={clsx(classes.cellContent, "!text-xs md:!text-sm lg:!text-base")}>
                                                {u.email}
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell className="!p-2">
                                            <Typography className={clsx(classes.cellContent, "!text-xs md:!text-sm lg:!text-base")}>
                                                Cinsiyeti
                                            </Typography>
                                        </TableCell>
                                        <TableCell className="!p-2">
                                            <Typography className={clsx(classes.cellContent, "!text-xs md:!text-sm lg:!text-base")}>
                                                {u.gender === 'male' ? 'Bay' : 'Bayan'}
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell className="!p-2">
                                            <Typography className={clsx(classes.cellContent, "!text-xs md:!text-sm lg:!text-base")}>
                                                IP Adresi
                                            </Typography>
                                        </TableCell>
                                        <TableCell className="!p-2">
                                            <Typography className={clsx(classes.cellContent, "!text-xs md:!text-sm lg:!text-base")}>
                                                {u.ip}
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell className="!p-2">
                                            <Typography className={clsx(classes.cellContent, "!text-xs md:!text-sm lg:!text-base")}>
                                                Kayıt Tarihi
                                            </Typography>
                                        </TableCell>
                                        <TableCell className="!p-2">
                                            <Typography className={clsx(classes.cellContent, "!text-xs md:!text-sm lg:!text-base")}>
                                                {new Date(u.created_at).toLocaleDateString()}
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell className="!p-2">
                                            <Typography className={clsx(classes.cellContent, "!text-xs md:!text-sm lg:!text-base")}>
                                                Aksiyon
                                            </Typography>
                                        </TableCell>
                                        <TableCell className="!p-2">
                                            <Tooltip title="Profil" placement="top">
                                                <IconButton className="!p-[2px] !mr-2" onClick={() => this.handleOpenUserProfile(u)}>
                                                    <Person className="!text-[16px]" />
                                                </IconButton>
                                            </Tooltip>
                                            <Tooltip title="Düzenle" placement="top">
                                                <IconButton className="!p-[2px] !mr-2" onClick={() => this.handleEditUser(u)}>
                                                    <Edit className="!text-[16px]" />
                                                </IconButton>
                                            </Tooltip>
                                            <Tooltip title="Sil" placement="top">
                                                <IconButton className="!p-[2px] !mr-2" onClick={() => this.handleDeleteUser(u)} disabled={u?.id === this.props.user?.id}>
                                                    <Delete className="!text-[16px]" />
                                                </IconButton>
                                            </Tooltip>
                                            <Tooltip title="Banla" placement="top">
                                                <IconButton className="!p-[2px] !mr-2" onClick={(e) => this.handleBanUser(e, u)} disabled={u?.id === this.props.user?.id}>
                                                    <Block className="!text-[16px]" />
                                                </IconButton>
                                            </Tooltip>
                                        </TableCell>
                                    </TableRow>
                                </Table>
                            </CardContent>
                        </Card>
                    );
                })}
            </Box>
        );
    }

    render() {
        return (
            <Box margin={1} display={"flex"} flexDirection={"column"} rowGap={1}>

                <TextField variant="outlined" placeholder="Kişi Ara" fullWidth margin="dense"
                    InputProps={{
                        classes: {
                            input: "!text-xs md:!text-sm lg:!text-base",
                            root: "p-1 md:p-2"
                        },
                        startAdornment: (
                            <InputAdornment position="start">
                                <SvgIcon fontSize='small'>
                                    <path d={mdiMagnify} />
                                </SvgIcon>
                            </InputAdornment>
                        )
                    }}
                    value={this.state.search}
                    onChange={this.searchChanged}
                    sx={{
                        '& .MuiInputBase-root': { p: 0, px: 2 },
                        '& .MuiInputBase-root::before': { borderBottom: '1px solid #E3E3E3 !important' },
                        '& .MuiInputBase-root::after': { borderBottom: '1px solid #E3E3E3 !important' },
                    }}
                />

                {this.tableView()}
                {this.mobileView()}

                <Box className="flex justify-end">
                    <Pagination count={parseInt(Math.ceil(this.state.totalUsers / this.state.size))} page={this.state.page}
                        variant="outlined" shape="rounded" color="primary"
                        onChange={(e, p) => this.setState({ page: p }, () => this.listUsers())}
                    />
                </Box>

                <UploadPhoto open={this.state.editUserAvatar} onClose={() => this.setState({ editUserAvatar: false, avatarAt: Date.now() })}
                    editUser={this.state.editUser}
                    url={`${process.env.REACT_APP_API_URL}/v1/admin/users/${this.state.editUser?.id}/profile/photo`}
                />

                {this.userProfileDialog()}
                {this.editUserDialog()}
                {this.deleteUserDialog()}
                {this.deleteUserAvatarDialog()}
                {this.banUserMenu()}
                {this.banUserDialog()}
            </Box>
        );
    }
}

Users.propTypes = {
    classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
    return {
    }
};

export default withStyles(styles)(connect(mapStateToProps, {
    setNotification,
})(Users));
