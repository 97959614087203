import { BrowserHistory } from "history";
import { setLobbyUsers, setLobbyTables, addChatMessage, setRejoinTableId, clearChat, setUserStatus as setLobbyUserStatus, updateUserInfo, updateTableInfo, removeTable, userDisconnected, userConnected, updateUserPhoto, updateUserInfoKV, updateTableInfoKV } from "../actions/lobby";
import { setPieces, setGameInfo, setTurnTime, setPots, setGround, setEndRound, showIndicator, setDouble, setReturnOkey, leaveTable, addTable } from "../actions/table";
import { addFriend, addNotifications, addSpectatingTable, removeBlockedUser, removeFriend, removeSpectatingTable, selectTab, setBlockedUsers, setDisconnected, setError, setFriends, setInfoDialog, setInvitationDialog, setMyTable, setNotifications, setPing, setRoomConfig, setSocket, setSocketUser, setUserAutoPilot, setUserStatus, updateFriend, updateNotification, readNotifications } from "../actions/global";
import { applauseAudio, pieceAudio, play, startAudio, loginAudio, tensionAudio, applauseAudio2, applauseAudio3, notificationAudio, messageAudio, welcomeAudio } from "./audio";
import { getPath } from "./route";
import { endMessage } from "../utils/message";
import { roles } from "./role";
import startJobs from "./jobs";
const uuid = require('uuid');

/**
 * 
 * @param {WebSocket} s 
 * @param {BrowserHistory} history 
 */
export function setSocketHandler(s, history) {
    s.handlers = {};

    s.on = function (event, callback) {
        s.handlers[event] = callback;
    }

    return async (dispatch, getState) => {
        dispatch(setSocket(s));
        startJobs(dispatch, getState);

        s.onmessage = (event) => {
            const msg = JSON.parse(event.data);
            if (s.handlers[msg.e]) {
                s.handlers[msg.e](msg.d);
            }
        }

        const pingInterval = setInterval(function ping() {
            const start = Date.now();
            const data = { s: start };

            if (s.reconnected) {
                data.r = true;
                s.reconnected = false;
            }

            s.emit("ping", data);
            return ping;

        }(), 10000);

        s.on('pong', (data) => {
            const end = Date.now();
            dispatch(setPing(end - data.s));
        });

        s.onclose = (e) => {
            clearInterval(pingInterval);

            const diconnect = setTimeout(() => {
                s.close();
                dispatch(setDisconnected(true));
            }, 5000);

            if ((e.code >= 4000 && e.code <= 4010)) {
                s.close();
                clearTimeout(diconnect);
                dispatch(setDisconnected(true));
                return;
            }

            s.reconnect((ws) => {
                s.close();
                clearTimeout(diconnect);
                dispatch(setDisconnected(false));
                dispatch(setSocketHandler(ws, history));
            });
        }

        s.on('login', (data) => {
            dispatch(setSocketUser(data.user));
            //play(loginAudio);
            // history.push(getPath('/salon'));
        });

        // someone joined to the room
        s.on('joined', (user) => {
            if (user.id in getState().app.global.friendsById) {
                play(welcomeAudio, (settings) => settings.online_friend);

                const welcomeMsg = {
                    "id": uuid.v4(),
                    "sender": "[SİSTEM]",
                    "sender_id": null,
                    "message": `${user.username} şimdi çevrimiçi.`,
                    "ts": new Date(),
                    "room": getState().app.global.selectedTab,
                    "present": false
                }

                dispatch(addChatMessage(welcomeMsg));
            }

            dispatch(userConnected(user));
        });

        s.on('rcv_chat_msg', (data) => {
            const selectedTab = getState().app.global.selectedTab;

            if (data.sender_id === data.room && selectedTab !== data.room) {
                play(messageAudio, (settings) => settings.private_message);
            }

            dispatch(addChatMessage(data));
        })

        s.on('rcv_mention', (data) => {
            const selectedTab = getState().app.global.selectedTab;
            data.room = selectedTab;

            if (data.sender_id === data.room && selectedTab !== data.room) {
                play(messageAudio, (settings) => settings.private_message);
            }

            dispatch(addChatMessage(data));
        });

        s.on('rcv_global', (data) => {
            const rooms = [];
            rooms.push('salon');
            rooms.push('table_' + getState().app.global.myTable);
            getState().app.global.spectatingTables.forEach(t => rooms.push('table_' + t));
            getState().app.global.chatTabs.forEach(t => rooms.push(t.id));

            rooms.forEach(room => {
                const _data = { ...data };
                _data["room"] = room;
                dispatch(addChatMessage(_data));
            });
        });

        s.on('get_room', (data) => {
            dispatch(setLobbyUsers(data.users));
            dispatch(setLobbyTables(data.tables));
        });

        s.on('get_room_users', (data) => {
            dispatch(setLobbyUsers(data));
        });

        s.on('get_room_tables', (data) => {
            dispatch(setLobbyTables(data));
        });

        s.on('get_table', (table) => {
            dispatch(addTable(table.id));

            if (table.joined) {
                const tabId = `table_${table.id}`;
                dispatch(selectTab(tabId));

                const pathname = `/masa/${table.id}`;
                if (history.location.pathname !== pathname) {
                    history.push(getPath(pathname));
                }
            }

            if (table.spectating) {
                dispatch(addSpectatingTable(table.id));
            }
            else if (getState().app.global.myTable === null && table.users.find(u => u?.id === getState().app.global.user?.id)) {
                dispatch(setMyTable(table.id));
            }
        });

        s.on('set_pieces', (pieces) => {
            const myTable = getState().app.global.myTable;
            dispatch(setPieces(myTable, pieces));
        });

        s.on('game_info', (info) => {
            dispatch(setGameInfo(info.tableId, info));
        });

        s.on('turn_time', (data) => {
            if (data.timeLeft < 10) {
                play(tensionAudio, (settings) => settings.game_fx);
            } else if (data.next) {
                play(pieceAudio, (settings) => settings.game_fx);
            }
            dispatch(setTurnTime(data.tableId, data));
        });

        s.on('set_pots', (data) => {
            dispatch(setPots(data.tableId, data.pots));
        });

        s.on('set_ground', (data) => {
            dispatch(setGround(data.tableId, data.left));
        });

        s.on('end_round', (data) => {
            const usersById = getState().app.lobby.usersById;

            if (data.user) {
                switch (data.r) {
                    case 1:
                    case 2:
                        play(applauseAudio2, (settings) => settings.game_fx);
                        break;
                    case 3:
                        play(applauseAudio3, (settings) => settings.game_fx);
                        break;
                    default:
                        play(applauseAudio, (settings) => settings.game_fx);
                }
            }

            const msgData = {
                color: null,
                id: Date.now(),
                message: endMessage(data, usersById),
                present: null,
                room: `table_${data.tableId}`,
                sender: "[Sistem]",
                sender_id: null,
                ts: Date.now(),
            };

            dispatch(addChatMessage(msgData))
            dispatch(setEndRound(data.tableId, data));
        });

        s.on('rejoin', (data) => {
            dispatch(setRejoinTableId(data?.tableId, data?.index, data?.password));
        });

        s.on('game_started', (data) => {
            play(startAudio, (settings) => settings.game_fx);
            dispatch(setDouble(data.tableId, false));
            dispatch(setReturnOkey(data.tableId, false));
        });

        s.on('leave_table', (data) => {
            if (data.spectating) {
                dispatch(leaveTable(data.tableId));
                dispatch(removeSpectatingTable(data.tableId));
                dispatch(clearChat(`table_${data.tableId}`));
                dispatch(selectTab('salon'));
                history.push(getPath('/salon'));
                return;
            }

            dispatch(leaveTable(data.tableId));
            dispatch(clearChat(`table_${data.tableId}`));
            dispatch(selectTab('salon'));
            dispatch(setGameInfo(data.tableId, null));
            dispatch(setPieces(data.tableId, []));
            dispatch(setPots(data.tableId, []));
            dispatch(setMyTable(null));
            history.push(getPath('/salon'));
        });

        s.on('error', (error) => {
            dispatch(setError(error));
        });

        s.on('show_indicator', (data) => {
            dispatch(showIndicator(data.tableId, data.userId));
        });

        s.on('double', (data) => {
            const myUser = getState().app.global.user;
            const user = getState().app.lobby.usersById[data.id];

            if (user?.id === myUser.id) {
                dispatch(setInfoDialog('Çifte Git', 'Bu el çift gidiyorsunuz.'));
                dispatch(setDouble(user.tableId, true));
            } else {
                dispatch(setInfoDialog('', `${user?.username} bu el çift gidiyor.`));
            }
        });

        s.on('return_okey', (data) => {
            const myUser = getState().app.global.user;
            const user = getState().app.lobby.usersById[data.id];

            if (user?.id === myUser.id) {
                dispatch(setInfoDialog('Tura Dön', 'Bu el tura döndünüz.'));
                dispatch(setReturnOkey(user.tableId, true));
            } else {
                dispatch(setInfoDialog('Tura Dön', `${user?.username} bu el tura döndü.`));
            }
        });

        s.on('set_status', (data) => {
            dispatch(setUserStatus(data.status));
        });

        s.on('set_user_status', (data) => {
            dispatch(setLobbyUserStatus(data.id, data.status));
        });

        s.on('update_user_info', (data) => {
            dispatch(updateUserInfo(data))
        });

        s.on('update_user_info_kv', (data) => {
            dispatch(updateUserInfoKV(data.id, data.key, data.value));
        });

        s.on('rcv_notification', (data) => {
            play(notificationAudio, (settings) => settings.new_notification);
            dispatch(addNotifications(data));
        });

        s.on('init_notification', (data) => {
            dispatch(addNotifications(data));
        });

        s.on('set_notifications', (data) => {
            dispatch(setNotifications(data))
        });

        s.on('update_notification', (data) => {
            dispatch(updateNotification(data))
        });

        s.on('read_notifications', () => {
            dispatch(readNotifications())
        });

        s.on('add_friend', (data) => {
            dispatch(addFriend(data))
        });

        s.on('update_friend', (data) => {
            dispatch(updateFriend(data))
        });

        s.on('rcv_friends', (data) => {
            dispatch(setFriends(data));
        });

        s.on('remove_friend', (data) => {
            dispatch(removeFriend(data));
        });

        s.on('rcv_blocks', (data) => {
            dispatch(setBlockedUsers(data));
        });

        s.on('auto_pilot', (data) => {
            dispatch(setUserAutoPilot(data.auto_pilot));
        });

        s.on('rcv_invitation', (data) => {
            dispatch(setInvitationDialog(data));
        });

        s.on('clear_lobby_chat', () => {
            const user = getState().app.global.user;
            if (user?.role < roles.Moderator) {
                dispatch(clearChat('salon'));
            }

            const clearMSg = {
                "id": uuid.v4(),
                "sender": "[SİSTEM]",
                "sender_id": null,
                "message": `Lobi sohbeti admin tarafından temizlendi.`,
                "ts": new Date(),
                "room": getState().app.global.selectedTab,
                "present": false
            }

            dispatch(addChatMessage(clearMSg));
        });

        s.on('update_table', (table) => {
            dispatch(updateTableInfo(table));
        });

        s.on('update_table_kv', (data) => {
            dispatch(updateTableInfoKV(data.id, data.key, data.value));
        });

        s.on('remove_table', (data) => {
            dispatch(removeTable(data.tableId));
        });

        s.on('user_disconnected', (data) => {
            dispatch(userDisconnected(data.id));
        });

        s.on('set_slow_mode', (data) => {
            const { roomConfig } = getState().app.global;
            if (!roomConfig)
                return;

            roomConfig.slowMode = data.slowMode;
            dispatch(setRoomConfig(roomConfig));
        });
    };
}
