import { smileyMap } from "../components/Chatbox/shortcuts";
import { loadedResources } from "./resources";

export const escape = s => s.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&');

const replaceStringWithEmoji = (message) => {
    const pattern = new RegExp(
        Object.keys(smileyMap).map(escape).join('|'),
        'g'
    );

    const arr = message?.match(pattern);
    const parts = [];
    let index1 = 0;

    arr?.forEach((match) => {
        let index2 = message.indexOf(match, index1);
        if (index2)
            parts.push(
                message.substring(index1, index2)
            );

        parts.push(<img src={loadedResources[`${process.env.REACT_APP_CDN_URL}/web/smileys/${smileyMap[match]}`]} alt={smileyMap[match]} width={24} height={24} style={{ display: 'inline', backgroundColor: 'transparent' }} />);
        index1 = index2 + match.length;
    });

    parts.push(message?.substring(index1));

    return (
        <span>
            {parts}
        </span>
    );
};

export default replaceStringWithEmoji;