import React from "react";
import { connect } from "react-redux";
import { withStyles } from "@mui/styles";
import PropTypes from "prop-types";
import { Box, CircularProgress, List, ListItemButton, ListItemIcon, ListItemText, ListSubheader, Tooltip, Typography } from "@mui/material";
import {
    AdminPanelSettings as AdminIcon, RoomPreferences as RoomIcon,
    Public as GlobalIcon, WorkspacePremium as PremiumIcon, Person as UserIcon,
    Chat as ChatIcon, TableRestaurant as GameIcon, Storage as ServerIcon,
} from '@mui/icons-material';
import AdminSettings from "./AdminSettings";
import { selectTab, setAdminPath, setNotification } from "../../actions/global";
import GlobalSettings from "./GlobalSettings";
import UserSettings from "./UserSettings";
import RoomSettings from "./RoomSettings";
import ServerSettings from "./ServerSettings";
import ChatSettings from "./ChatSettings";
import PremiumSettings from "./PremiumSettings";
import axios from "axios";
import { GlobalTypes } from "../../action_types";
import { AxiosClient } from "../../global/axios";
import { getPath } from "../../global/route";


const styles = theme => ({
});

const items = [
    { link: getPath('/admin'), name: 'Admin Ayarları', icon: AdminIcon, hidden: (role, permission) => (permission & Number(1n << 4n)) === 0 },
    { link: getPath('/admin/global'), name: 'Global Ayarlar', icon: GlobalIcon, hidden: (role, permission) => (permission & Number(1n << 14n)) === 0 },
    { link: getPath('/admin/user'), name: 'Kullanıcı Ayarları', icon: UserIcon, hidden: (role, permission) => ((permission & 1) === 0) && ((permission & 2) === 0) && ((permission & Number(1n << 11n)) === 0) && ((permission & Number(1n << 12n)) === 0) && ((permission & Number(1n << 13n)) === 0) && ((permission & Number(1n << 19n)) === 0) && ((permission & Number(1n << 20n)) === 0) },
    { link: getPath('/admin/oda'), name: 'Oda Ayarları', icon: RoomIcon, hidden: (role, permission) => (permission & Number(1n << 3n)) === 0 },
    //{ link: getPath('/admin/game'), name: 'Oyun Ayarları', icon: GameIcon },
    { link: getPath('/admin/premium'), name: 'Premium Ayarları', icon: PremiumIcon, hidden: (role, permission) => (permission & Number(1n << 9n)) === 0 },
    { link: getPath('/admin/chat'), name: 'Sohbet Ayarları', icon: ChatIcon, hidden: (role, permission) => ((permission & Number(1n << 5n)) === 0) || ((permission & Number(1n << 6n)) === 0) },
    { link: getPath('/admin/server'), name: 'Sunucu Ayarları', icon: ServerIcon, hidden: (role, permission) => ((permission & Number(1n << 5n)) === 0) || ((permission & Number(1n << 7n)) === 0) },
];

class Admin extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            role: 0,
            permission: 0,
        }

    }

    componentDidMount() {
        this.props.selectTab('admin');

        if (this.props.history?.location?.pathname !== this.props.adminPath) {
            this.props.history.push(getPath(this.props.adminPath));
            this.setState({ clickedAt: Date.now() });
        }

        AxiosClient().get(`${process.env.REACT_APP_API_URL}/v1/user/role`).then((res) => {
            const item = items.find((item) => {
                return item.link === this.props.history?.location?.pathname;
            });

            const hidden = item?.hidden?.(res.data.role, res.data.perm) === true;

            if (hidden) {
                const firstItem = items.find((item) => !item.hidden?.(res.data.role, res.data.perm));
                if (firstItem) {
                    this.props.history.push(firstItem.link);
                }
                else {
                    this.props.history.push(getPath('/salon'));
                    this.props.setNotification({ message: 'Bu sayfayı görüntülemek için yetkiniz bulunmamaktadır.', severity: GlobalTypes.NOTIFY_ERR });
                    return;
                }
            }

            this.setState({ loading: false, role: res.data.role, permission: res.data.perm });

        }).catch((err) => {
            this.props.setNotification({ message: 'Bu sayfayı görüntülemek için yetkiniz bulunmamaktadır.', severity: GlobalTypes.NOTIFY_ERR });
            this.props.selectTab('salon');
            this.props.history.push(getPath('/salon'));
        });
    }

    componentDidUpdate(prevProps) {
    }

    navigate = (link) => {
        this.props.history.push(link);
        this.props.setAdminPath(link);
        this.setState({ clickedAt: Date.now() });
    }

    createListItem = (link, name, Icon, selected) => {
        const { classes } = this.props;

        return (
            <ListItemButton onClick={() => this.navigate(link)} className={classes.ListButton}
                style={{ padding: 0, columnGap: 18, marginBottom: 1, backgroundColor: selected ? '#FFC8A2' : '#F0F0F0' }}>
                <ListItemIcon sx={{ backgroundColor: selected ? '#FF9559' : '#D3D3D3', minWidth: 0, width: 48, height: 48 }}>
                    <Icon selected htmlColor={selected ? "#FFFFFF" : "#666666"} sx={{ fontSize: 22, margin: 'auto' }} />
                </ListItemIcon>
                <ListItemText disableTypography primary={name}
                    sx={{ color: selected ? '#575757' : "#9E9E9E", fontWeight: 400, marginTop: 1, whiteSpace: 'nowrap', paddingRight: 4 }}
                    className="hidden xl:block"
                />
            </ListItemButton >
        )
    }

    content = () => {
        const { role, permission } = this.state;
        switch (this.props.history?.location?.pathname) {
            case getPath('/admin'):
                return <AdminSettings history={this.props.history} role={role} permission={permission} />;
            case getPath('/admin/global'):
                return <GlobalSettings role={role} permission={permission} />;
            case getPath('/admin/user'):
                return <UserSettings role={role} permission={permission} />;
            case getPath('/admin/oda'):
                return <RoomSettings role={role} permission={permission} />;
            case getPath('/admin/chat'):
                return <ChatSettings role={role} permission={permission} />;
            case getPath('/admin/server'):
                return <ServerSettings role={role} permission={permission} />;
            case getPath('/admin/premium'):
                return <PremiumSettings role={role} permission={permission} />;
        }
    }

    render() {
        const { classes } = this.props;

        if (this.state.loading) {
            return (
                <Box display="flex" flexDirection="column" padding="0" borderLeft={"1px solid #a097b3"}
                    bgcolor={"#F9F6FF"}
                    className={"!pt-6 md:!pt-0 flex-1 h-full"}
                >
                    <Box display={"flex"} flex={1} justifyContent={"center"} alignItems={"center"}>
                        <Typography className={classes.text} sx={{ color: '#413067' }}>
                            <CircularProgress color="inherit" />
                        </Typography>
                    </Box>
                </Box>
            );
        }

        return (
            <Box display="flex" flexDirection="column" className="flex-1" overflow={"auto"}>

                <Box display={"flex"} flex={1} overflow={"hidden"}>
                    <Box display="flex" flexDirection="column">
                        <div style={{
                            display: 'flex', flexDirection: 'column', flex: 1, borderRight: '1px solid #F0F0F0',
                            backgroundColor: '#E6E6FA'
                        }}>
                            <List
                                dense
                                component="nav"
                                aria-labelledby="nested-list-subheader"
                                sx={{
                                    padding: 0,
                                    '& .MuiListItem-root:last-child': { margin: '0 !important' }
                                }}
                                subheader={
                                    <ListSubheader component="div" id="nested-list-subheader" className={classes.ListTitle}>
                                    </ListSubheader>
                                }>

                                {items.map((item, index) => {
                                    const selected = this.props.history?.location?.pathname === item.link;
                                    const content = this.createListItem(item.link, item.name, item.icon, selected);

                                    if (item.hidden?.(this.state.role, this.state.permission)) {
                                        return null;
                                    }

                                    return (
                                        <>
                                            <Box className="block xl:hidden">
                                                <Tooltip key={item.link} title={item.name} placement="top">
                                                    {content}
                                                </Tooltip>
                                            </Box>
                                            <Box className="hidden xl:block">
                                                {content}
                                            </Box>
                                        </>
                                    );
                                })}

                            </List>
                        </div>
                    </Box>

                    <Box overflow={"scroll"} className="w-full">
                        {this.content()}
                    </Box>
                </Box>
            </Box>
        );
    }
}

Admin.propTypes = {
    classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
    return {
        socket: state.app.global.socket,
        adminPath: state.app.global.adminPath,
    }
};

export default withStyles(styles)(connect(mapStateToProps, {
    selectTab, setAdminPath, setNotification,
})(Admin));
