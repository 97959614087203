import React from "react";
import style from "./index.module.css";
import { connect } from "react-redux";
import { withStyles } from "@mui/styles";
import clsx from "clsx";
import { AppBar, Toolbar, Typography, Container, Box, IconButton, Button, Tooltip, Drawer, List, ListSubheader, ListItemText, ListItemButton, ListItemIcon, Divider } from "@mui/material";
import { Article, Close, Info, Mail, Menu as MenuIcon, Person, PersonAddAlt1 } from "@mui/icons-material";
import Piece from "./Piece";
import ContentPiece from "./Content/ContentPiece";
import Blog from "./Blog";
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { getPath } from "../../global/route";
import PlayStore from "./PlayStore";
import AppStore from "./AppStore";

const styles = (theme) => ({
    root: {
        overflow: "hidden",
    }
});

class Landing extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            drawer: false,
        };

        this.pages = [];

        this.pieces = [
            {
                title: "Okey",
                count: 1569,
                color: "#D98D90",
                isActive: true,
                img: `${process.env.REACT_APP_CDN_URL}/web/img/tas1.svg`
            },
            {
                title: "101",
                count: 987,
                color: "#BE89CE",
                isActive: false,
                img: `${process.env.REACT_APP_CDN_URL}/web/img/tas2.svg`
            },
            {
                title: "Batak",
                count: 29,
                color: "#9191DF",
                isActive: false,
                img: `${process.env.REACT_APP_CDN_URL}/web/img/tas3.svg`
            },
            {
                title: "İhale",
                count: 2365,
                color: "#90B7D3",
                isActive: false,
                img: `${process.env.REACT_APP_CDN_URL}/web/img/tas4.svg`
            },
            {
                title: "Banko",
                count: 421,
                color: "#7FC788",
                isActive: false,
                img: `${process.env.REACT_APP_CDN_URL}/web/img/tas5.svg`
            },
            {
                title: "King",
                count: 88,
                color: "#DA9A85",
                isActive: false,
                img: `${process.env.REACT_APP_CDN_URL}/web/img/tas6.svg`
            },
            {
                title: "Pişti",
                count: 1930,
                color: "#97B2DD",
                isActive: false,
                img: `${process.env.REACT_APP_CDN_URL}/web/img/tas7.svg`
            },
            {
                title: "Tavla",
                count: 25335,
                color: "#A5A5A5",
                isActive: false,
                img: `${process.env.REACT_APP_CDN_URL}/web/img/tas8.svg`
            },
        ]

        this.contents = [
            {
                titles: ["Hem oyna,", "hem sohbet et"],
                description: "Gerçek oyuncularla eşzamanlı oyun oyna genel veya özel sohbet odalarında sohbet et",
                img: `${process.env.REACT_APP_CDN_URL}/web/img/content-1.png`,
            },
            {
                titles: ["Hediye al,", "hediye gönder"],
                description: "Gerçek oyuncularla eşzamanlı oyun oyna genel veya özel sohbet odalarında sohbet et",
                img: `${process.env.REACT_APP_CDN_URL}/web/img/content-2.png`,
            },
            {
                titles: ["Çok kazan,", "daha çok yüksel"],
                description: "Gerçek oyuncularla eşzamanlı oyun oyna genel veya özel sohbet odalarında sohbet et",
                img: `${process.env.REACT_APP_CDN_URL}/web/img/content-3.png`,
            },
        ]

        this.blogs = [
            {
                title: "Yeni özellik ile artık arkadaş eklenebilir.",
                description: "Gerçek oyuncularla eşzamanlı oyun oyna genel veya özel sohbet odalarında sohbet et",
                img: `${process.env.REACT_APP_CDN_URL}/web/img/blog-1.png`,
            },
            {
                title: "Yeni özellik ile artık arkadaş eklenebilir.",
                description: "Gerçek oyuncularla eşzamanlı oyun oyna genel veya özel sohbet odalarında sohbet et",
                img: `${process.env.REACT_APP_CDN_URL}/web/img/blog-1.png`,
            },
            {
                title: "Yeni özellik ile artık arkadaş eklenebilir.",
                description: "Gerçek oyuncularla eşzamanlı oyun oyna genel veya özel sohbet odalarında sohbet et",
                img: `${process.env.REACT_APP_CDN_URL}/web/img/blog-1.png`,
            },
            {
                title: "Yeni özellik ile artık arkadaş eklenebilir.",
                description: "Gerçek oyuncularla eşzamanlı oyun oyna genel veya özel sohbet odalarında sohbet et",
                img: `${process.env.REACT_APP_CDN_URL}/web/img/blog-1.png`,
            },
        ];

        this.responsive = {
            desktop: {
                breakpoint: { max: 3000, min: 1268 },
                items: 8,
                slidesToSlide: 3 // optional, default to 1.
            },
            dekstop2: {
                breakpoint: { max: 1268, min: 1024 },
                items: 6,
                slidesToSlide: 2 // optional, default to 1.
            },
            tablet: {
                breakpoint: { max: 1024, min: 765 },
                items: 5,
                slidesToSlide: 2 // optional, default to 1.
            },
            tablet2: {
                breakpoint: { max: 765, min: 590 },
                items: 4,
                slidesToSlide: 1 // optional, default to 1.
            },
            mobile: {
                breakpoint: { max: 590, min: 0 },
                items: 3,
                slidesToSlide: 1 // optional, default to 1.
            }
        };

        this.blogResponsive = {
            active: {
                breakpoint: { max: 1024, min: 0 },
                items: 1,
            }
        };
    }

    toggleDrawer = (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        this.setState({ drawer: true });
    };

    header = () => {
        const sid = sessionStorage.getItem("sid") ?? localStorage.getItem("sid");
        const profile = sessionStorage.getItem("profile") ?? localStorage.getItem("profile");

        return (
            <Box
                className={clsx(style.header,
                    "h-[34rem] sm:h-[38rem] md:block items-end sm:items-end relative"
                )}>
                <div className={clsx("w-full h-full bg-cover bg-no-repeat bg-center")}
                    style={{ backgroundImage: `url(${`${process.env.REACT_APP_CDN_URL}/web/img/landing-bg1.jpg`})` }}
                />
                <AppBar color="transparent" sx={{ boxShadow: 'none', pt: 1, position: "absolute" }}>
                    <Container>
                        <Toolbar disableGutters>
                            <Box className="gap-x-2"
                                sx={{
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "center",
                                    alignItems: "center",
                                }}>
                                <img src={getPath("/img/logo.svg")} alt="" />
                                <Box>
                                    <Typography className={clsx("!font-['Roboto Slab'] !text-white !text-sm md:!text-base !font-bold")}>{process.env.REACT_APP_HEADER_TITLE}</Typography>
                                    <Typography className={clsx("!font-['Roboto Slab'] !text-white !text-sm md:!text-base !font-bold")}>{process.env.REACT_APP_HEADER_SUBTITLE}</Typography>
                                </Box>
                            </Box>
                            <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'flex' } }}>
                                {this.pages.map((page) => (
                                    <Button
                                        key={page}
                                        sx={{ my: 2, color: 'white', display: { xs: "none", md: "flex" } }}
                                    >
                                        {page}
                                    </Button>
                                ))}
                            </Box>

                            {(sid && profile) ? (
                                <Box className="hidden md:flex align-center justify-center gap-5 hover:cursor-pointer hover:dec">
                                    <Typography sx={{
                                        fontStyle: "normal",
                                        fontWeight: 700,
                                        fontSize: "14px",
                                        color: "#FFFFFF",
                                        textAlign: "center",
                                        alignSelf: "center",
                                    }}
                                    >
                                        Merhaba, {JSON.parse(profile)?.username}.
                                    </Typography>
                                    <Button
                                        onClick={() => { this.props.history.push(getPath('/salon')); }}
                                        sx={{
                                            backgroundColor: "#FF9559",
                                            borderRadius: "300px",
                                            borderBottom: "2px solid rgba(0, 0, 0, 0.2)",
                                            display: "flex",
                                            alignItems: "center",
                                            px: 2,
                                        }}
                                    >
                                        <Typography sx={{
                                            fontStyle: "normal",
                                            fontWeight: 700,
                                            fontSize: "16px",
                                            lineHeight: "21px",
                                            color: "#FFFFFF",
                                            textAlign: "center",
                                            display: "flex",
                                            alignItems: "center",
                                        }}
                                        >
                                            Salona Git
                                        </Typography>
                                    </Button>
                                </Box>
                            ) : (
                                <Box className="hidden md:flex align-center justify-center gap-5 hover:cursor-pointer hover:dec">
                                    <Button
                                        onClick={() => { this.props.history.push(getPath('/login?t=1')); }}
                                    >
                                        <Typography sx={{
                                            fontStyle: "normal",
                                            fontWeight: 700,
                                            fontSize: "16px",
                                            lineHeight: "21px",
                                            color: "#FFFFFF",
                                            textAlign: "center",
                                            display: "flex",
                                            alignItems: "center",
                                        }}
                                        >
                                            Kayıt Ol
                                        </Typography>
                                    </Button>
                                    <Button
                                        onClick={() => { this.props.history.push(getPath('/login')); }}
                                        sx={{
                                            color: "#FFFFFF",
                                            backgroundColor: "#FF9559",
                                            borderRadius: "300px",
                                            borderBottom: "2px solid rgba(0, 0, 0, 0.2)",
                                            display: "flex",
                                            alignItems: "center",
                                            px: 2,
                                        }}
                                    >
                                        <Typography sx={{
                                            fontStyle: "normal",
                                            fontWeight: 700,
                                            fontSize: "16px",
                                        }}>Giriş Yap</Typography>
                                        <Person sx={{ ml: "2px" }} />
                                    </Button>
                                </Box>
                            )}

                            <Box className="md:hidden" sx={{ flexGrow: 0 }}>
                                <Tooltip title="Menüyü Aç">
                                    <IconButton onClick={(e) => this.toggleDrawer(e)} sx={{ p: 0 }}>
                                        <MenuIcon sx={{ display: "flex", color: "#fff", fontSize: "35px !important" }} />
                                    </IconButton>
                                </Tooltip>
                            </Box>
                        </Toolbar>
                    </Container>
                </AppBar>
                <Box
                    className={clsx("absolute top-[50%] left-[50%] -translate-x-1/2 -translate-y-1/2")}
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        gap: "20px",
                        zIndex: 2,
                        width: "100%",
                    }}>
                    {/* <Box sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        alignItems: "center",
                        gap: "10px",
                        width: "309px",
                        height: "40px",
                        background: "linear-gradient(180deg, #B3B3FA 0%, #EAEAFF 93.23%)",
                        border: "1px solid rgba(255, 255, 255, 0.2)",
                        borderRadius: "300px",
                        mb: 2,
                    }}>
                        <Typography sx={{
                            fontStyle: "normal",
                            fontWeight: 500,
                            fontSize: "16px",
                            letterSpacing: "-0.01em",
                            color: "#1E2C5F",
                        }}>
                        235,365 kişi online oyun oynuyor
                        </Typography>
                    </Box> */}
                    <Box className="flex flex-col gap-y-4 align-middle sm:text-[52px] md:text-[62px] lg:text-[72px] text-[40px]" sx={{}}>
                        <Box>
                            <Typography

                                sx={{
                                    fontWeight: 900,
                                    textAlign: "center",
                                    letterSpacing: "-0.02em",
                                    color: "#FFFFFF",
                                    lineHeight: "0.5",
                                    fontSize: "unset",
                                }}>
                                Yeni insanlarla
                            </Typography>
                            <Typography sx={{
                                fontWeight: 900,
                                textAlign: "center",
                                letterSpacing: "-0.02em",
                                color: "#FFFFFF",
                                fontSize: "unset",
                            }}>
                                tanış, oyna, kazan!
                            </Typography>
                        </Box>

                        <Box className="flex flex-col md:flex-row w-full justify-center items-center gap-x-4 gap-y-4">
                            <PlayStore url={"https://play.google.com/store/apps/details?id=com.kerizim.okey&gl=TR"} />
                            <AppStore url={"https://apps.apple.com/us/app/kerizim-okey/id6581491508"} />
                        </Box>

                        {(sid && profile) ? (
                            <Box className="flex flex-col gap-y-2 md:hidden self-center justify-center">
                                <Typography sx={{
                                    fontStyle: "normal",
                                    fontWeight: 700,
                                    fontSize: "16px",
                                    color: "#FFFFFF",
                                    textAlign: "center",
                                    alignSelf: "center",
                                }}
                                >
                                    Merhaba, {JSON.parse(profile)?.username}.
                                </Typography>
                                <Button
                                    onClick={() => { this.props.history.push(getPath('/salon')); }}
                                    sx={{
                                        backgroundColor: "#FF9559",
                                        borderRadius: "300px",
                                        borderBottom: "2px solid rgba(0, 0, 0, 0.2)",
                                        display: "flex",
                                        alignItems: "center",
                                        px: 2,
                                    }}
                                >
                                    <Typography sx={{
                                        fontStyle: "normal",
                                        fontWeight: 700,
                                        fontSize: "16px",
                                        lineHeight: "21px",
                                        color: "#FFFFFF",
                                        textAlign: "center",
                                        display: "flex",
                                        alignItems: "center",
                                    }}
                                    >
                                        Salona Git
                                    </Typography>
                                </Button>
                            </Box>
                        ) : (
                            <Box className="block md:hidden self-center">
                                <Button
                                    onClick={() => { this.props.history.push(getPath('/login')); }}
                                    sx={{
                                        color: "#FFFFFF",
                                        backgroundColor: "#FF9559",
                                        borderRadius: "300px",
                                        borderBottom: "2px solid rgba(0, 0, 0, 0.2)",
                                        display: "flex",
                                        alignItems: "center",
                                        pl: 4,
                                        pr: 3,
                                        columnGap: '2px'
                                    }}
                                >
                                    <Typography className="!text-xl font-bold">Giriş Yap</Typography>
                                    <Person className="!text-3xl" />
                                </Button>
                            </Box>
                        )}
                    </Box>
                </Box>
                <Container className="relative" maxWidth={"lg"}>
                    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", mt: 5 }}>
                        <Box
                            className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-32"
                            sx={{
                                width: "100%",
                                height: "200px",
                                minHeight: "200px",
                                zIndex: 1000,
                            }}
                        >
                            <Carousel
                                responsive={this.responsive}
                                infinite
                                autoPlay
                                autoPlaySpeed={3000}
                                keyBoardControl={true}
                                customTransition="all 1s ease-in-out"
                                itemClass="flex justify-center items-center"
                                transitionDuration={1000}
                                removeArrowOnDeviceType={["tablet", "desktop", "dekstop2", "tablet2", "mobile"]}
                            >
                                {this.pieces.map((piece, index) => {
                                    return <Piece img={getPath(piece?.img)} key={piece.title} isActive={piece?.isActive} title={piece?.title} count={piece?.count} color={piece?.color} />
                                })}
                            </Carousel>
                        </Box>
                    </Box>
                </Container>
            </Box>
        );
    };

    content = () => {
        return (
            <Box sx={{
                width: "100%",
                pt: 15,
                backgroundColor: "#FBFBFF",
                pb: 12,
            }}>
                <Container>
                    <Box className="lg:grid lg:grid-cols-3 lg:gap-3">
                        {this.contents.map((content, index) => {
                            return <ContentPiece key={`${content}_${index}`}
                                titles={content?.titles} description={content?.description} img={getPath(content?.img)} />
                        })}
                    </Box>
                </Container>
            </Box>
        );
    };

    blog = () => {
        return (
            <Box>
                <Container sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "20px",
                    py: 5,
                }}>
                    <Typography sx={{
                        textAlign: "start",
                        fontStyle: "normal",
                        fontWeight: 900,
                        fontSize: "30px",
                        lineHeight: "35px",
                        color: "#7A51ED",
                    }}>
                        Son Blog Yazıları
                    </Typography>
                    <Box sx={{}} className="lg:grid-cols-4 lg:gap-4  lg:grid hidden">
                        {this.blogs.map((blog, index) => {
                            return (
                                <Box key={`${blog}_${index}`}
                                    sx={{ display: "flex", alignItems: "center", justifyContent: "center", gap: "20px", flexDirection: "column", mb: 2 }}>
                                    <Blog title={blog?.title} description={blog?.description} img={getPath(blog?.img)} />
                                </Box>
                            );
                        })}
                    </Box>
                    <Carousel
                        className="lg:!hidden min-h-[540px] overflow-visible"
                        dotListClass=""
                        responsive={this.blogResponsive}
                        infinite
                        showDots
                        autoPlay
                        autoPlaySpeed={3000}
                        keyBoardControl={true}
                        customTransition="all 1s ease-in-out"
                        itemClass="flex justify-center items-center"
                        transitionDuration={1000}
                        removeArrowOnDeviceType={["active"]}
                    >
                        {this.blogs.map((blog, index) => {
                            return (
                                <Box key={`${blog}_${index}`}
                                    sx={{ display: "flex", alignItems: "center", justifyContent: "center", gap: "20px", flexDirection: "column", mb: 2 }}>
                                    <Blog title={blog?.title} description={blog?.description} img={getPath(blog?.img)} />
                                </Box>
                            );
                        })}
                    </Carousel>

                    <Box sx={{ mt: 2 }} className="lg:grid lg:grid-cols-2 lg:gap-2">
                        <Box className="mb-4 lg:mb-0" sx={{ backgroundColor: "#FF9559", borderRadius: "12px", height: "140px", display: "flex", alignItems: 'center', justifyContent: "start", overflow: "hidden" }}>
                            <img src={`${process.env.REACT_APP_CDN_URL}/web/img/phone.png`} alt="" style={{ marginBottom: "-15px" }} />
                            <Box sx={{ display: "flex", alignItems: 'center', justifyContent: "start" }}>
                                <Typography sx={{
                                    fontStyle: "normal",
                                    fontWeight: 700,
                                    fontSize: "24px",
                                    letterSpacing: "-0.01em",
                                    lineHeight: "1.5rem",
                                    color: "#fff",
                                }}>
                                    Telefonunla her yerden bağlan, oyna
                                </Typography>
                            </Box>
                        </Box>
                        <Box sx={{ backgroundColor: "#7A51ED", borderRadius: "12px", height: "140px", display: "flex", alignItems: 'center', justifyContent: "start" }}>
                            <img src={`${process.env.REACT_APP_CDN_URL}/web/img/phone`} alt="" />
                            <Box sx={{ display: "flex", alignItems: 'center', justifyContent: "center", margin: 'auto' }}>
                                <Typography sx={{
                                    fontStyle: "normal",
                                    fontWeight: 700,
                                    fontSize: "24px",
                                    letterSpacing: "-0.01em",
                                    lineHeight: "1.5rem",
                                    color: "#fff",
                                    width: "50%",
                                    textAlign: "center",
                                }}>
                                    Arkadaşını davet et masa kur ve oynamaya başla
                                </Typography>
                            </Box>
                        </Box>
                    </Box>
                </Container>
            </Box>
        )
    };

    footer = () => {
        return (
            <footer className="flex justify-center bg-[#4646A0] py-12 overflow-hidden">
                <Container className="relative">
                    <img src={`${process.env.REACT_APP_CDN_URL}/web/img/footer-bg.svg`} alt="" className="hidden lg:block absolute left-[10%] xl:left-0 top-[10%] xl:-top-[40%] w-48 h-48 xl:w-64 xl:h-64" />
                    <Box className="flex justify-center align-center flex-col">
                        <Box className="flex align-center justify-center gap-x-2">
                            <img src={getPath("/img/logo.svg")} alt="" />
                            <Box>
                                <Typography className={clsx("!font-['Roboto Slab'] !text-white !text-sm md:!text-base !font-bold")}>{process.env.REACT_APP_HEADER_TITLE}</Typography>
                                <Typography className={clsx("!font-['Roboto Slab'] !text-white !text-sm md:!text-base !font-bold")}>{process.env.REACT_APP_HEADER_SUBTITLE}</Typography>
                            </Box>
                        </Box>
                        <Box className="flex align-center justify-center mt-5">
                            <Typography className="!text-sm font-medium text-white !mr-3 hover:cursor-pointer hover:underline">
                                Hakkımızda
                            </Typography>
                            <Box className="flex align-center">
                                <Divider orientation="vertical" className="!h-full !w-[1px] bg-white" />
                            </Box>
                            <Typography className="!text-sm font-medium text-white !ml-3 hover:cursor-pointer hover:underline">
                                Bize Ulaşın
                            </Typography>
                        </Box>
                        <Box className="flex align-center justify-center mt-10">
                            <Typography className="!text-xs text-[#FFFFFF50] !mr-3 hover:cursor-pointer">
                                Copyright {new Date().getFullYear()} Kerizim
                            </Typography>
                        </Box>
                    </Box>
                </Container>
            </footer>
        )
    };

    render() {
        const { classes } = this.props;
        const { drawer } = this.state;

        return (
            <div className={classes.root}>
                {this.header()}
                {this.content()}
                {this.blog()}
                {this.footer()}
                <Drawer
                    anchor={"right"}
                    open={drawer}
                    onClose={(e) => this.setState({ drawer: false })}
                    width="100vw"
                    height="100vh"
                    sx={{
                        "& .MuiDrawer-paper": {
                            width: "100%",
                            height: "100%",
                            backgroundColor: "#4646A0",
                            padding: "20px",
                        }
                    }}
                >
                    <Toolbar disableGutters>
                        <Box className="gap-x-2"
                            sx={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "center",
                                alignItems: "center",
                            }}>
                            <img src={getPath("/img/logo.svg")} alt="" />
                            <Box>
                                <Typography className={clsx("!font-['Roboto Slab'] !text-white !text-sm md:!text-base !font-bold")}>{process.env.REACT_APP_HEADER_TITLE}</Typography>
                                <Typography className={clsx("!font-['Roboto Slab'] !text-white !text-sm md:!text-base !font-bold")}>{process.env.REACT_APP_HEADER_SUBTITLE}</Typography>
                            </Box>
                        </Box>
                        <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'flex' } }}>
                            {this.pages.map((page) => (
                                <Button
                                    key={page}
                                    sx={{ my: 2, color: 'white', display: { xs: "none", md: "flex" } }}
                                >
                                    {page}
                                </Button>
                            ))}
                        </Box>

                        <Box sx={{ flexGrow: 0 }}>
                            <Tooltip title="Kapat">
                                <IconButton
                                    onClick={(e) => this.setState({ drawer: false })}
                                    sx={{
                                        p: 0,
                                        backgroundColor: "#FFFFFF",
                                        width: "44px",
                                        height: "35px",
                                        borderBotton: "2px solid rgba(0, 0, 0, 0.2)",
                                        borderRadius: "300px",
                                        ":hover": {
                                            backgroundColor: "#6666B0 !important",
                                        },
                                    }}
                                >
                                    <Close sx={{
                                        display: "flex",
                                        color: "#4646A0",
                                        ":hover": {
                                            color: "#FFFFFF !important",
                                        }
                                    }}
                                    />
                                </IconButton>
                            </Tooltip>
                        </Box>
                    </Toolbar>

                    {/* <List
                    sx={{
                        width: "100%",
                        height: "100%",
                        mt: 4
                    }}
                    component="nav"
                    subheader={
                        <ListSubheader 
                            component="div" 
                            sx={{
                                backgroundColor: "transparent",
                                color: "rgba(255, 255, 255, 0.5)",
                                fontStyle: "normal",
                                fontWeight: 400,
                                fontSize: "18px",
                                lineHeight: "16px",
                                padding: "0px",
                                pb: "10px",
                                borderBottom: "1px solid rgba(255, 255, 255, 0.1)",
                            }}
                        >
                          Oyunlar
                        </ListSubheader>
                      }
                >
                    {this.pieces.map((piece, index) => {
                        return <ListItemText 
                            primary={piece?.title}
                            primaryTypographyProps={{
                                sx: {
                                    fontStyle: "normal",
                                    fontWeight: 600,
                                    fontSize: "24px",
                                    lineHeight: "30px",
                                    color: "#FFFFFF",
                                    letterSpacing: "-0.01em",
                                }
                            }}
                            sx={{
                                py: "10px",
                                borderBottom: "1px solid rgba(255, 255, 255, 0.1)",
                            }} 
                        />
                    })}
                </List> */}

                    <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", flexDirection: "column", mt: 4, height: "100%" }}>
                        <List
                            sx={{
                                width: "100%",
                                mt: 4
                            }}
                            component="nav"
                            subheader={
                                <ListSubheader
                                    component="div"
                                    sx={{
                                        backgroundColor: "transparent",
                                        color: "rgba(255, 255, 255, 0.5)",
                                        fontStyle: "normal",
                                        fontWeight: 400,
                                        fontSize: "18px",
                                        lineHeight: "16px",
                                        padding: "0px",
                                        pb: "10px",
                                        borderBottom: "1px solid rgba(255, 255, 255, 0.1)",
                                    }}
                                >
                                    Hemen oyna
                                </ListSubheader>
                            }
                        >
                            <ListItemButton
                                onClick={() => { this.props.history.push(getPath('/login')) }}
                                sx={{
                                    py: "10px",
                                    borderBottom: "1px solid rgba(255, 255, 255, 0.1)",
                                    px: "0px",
                                }}
                            >
                                <ListItemIcon sx={{ minWidth: "47px !important" }}>
                                    <Person sx={{ fill: "#fff", fontSize: "35px !important" }} />
                                </ListItemIcon>
                                <ListItemText
                                    primary={"Giriş Yap"}
                                    primaryTypographyProps={{
                                        sx: {
                                            fontStyle: "normal",
                                            fontWeight: 600,
                                            fontSize: "24px",
                                            lineHeight: "30px",
                                            color: "#FFFFFF",
                                            letterSpacing: "-0.01em",
                                        }
                                    }}
                                />
                            </ListItemButton>
                            <ListItemButton
                                onClick={() => { this.props.history.push(getPath('/login?t=1')) }}
                                sx={{
                                    py: "10px",
                                    borderBottom: "1px solid rgba(255, 255, 255, 0.1)",
                                    px: "0px",
                                }}
                            >
                                <ListItemIcon sx={{ minWidth: "47px !important" }}>
                                    <PersonAddAlt1 sx={{ fill: "#fff", fontSize: "35px !important" }} />
                                </ListItemIcon>
                                <ListItemText
                                    primary={"Kayıt Ol"}
                                    primaryTypographyProps={{
                                        sx: {
                                            fontStyle: "normal",
                                            fontWeight: 600,
                                            fontSize: "24px",
                                            lineHeight: "30px",
                                            color: "#FFFFFF",
                                            letterSpacing: "-0.01em",
                                        }
                                    }}
                                />
                            </ListItemButton>
                        </List>
                        <List
                            sx={{
                                width: "100%",
                                mt: 4
                            }}
                            component="nav"
                            subheader={
                                <ListSubheader
                                    component="div"
                                    sx={{
                                        backgroundColor: "transparent",
                                        color: "rgba(255, 255, 255, 0.5)",
                                        fontStyle: "normal",
                                        fontWeight: 400,
                                        fontSize: "18px",
                                        lineHeight: "16px",
                                        padding: "0px",
                                        pb: "10px",
                                        borderBottom: "1px solid rgba(255, 255, 255, 0.1)",
                                    }}
                                >
                                    KERİZİM.COM
                                </ListSubheader>
                            }
                        >
                            <ListItemButton
                                sx={{
                                    py: "10px",
                                    borderBottom: "1px solid rgba(255, 255, 255, 0.1)",
                                    px: "0px",
                                }}
                            >
                                <ListItemIcon sx={{ minWidth: "47px !important" }}>
                                    <Info sx={{ fill: "#fff", fontSize: "35px !important" }} />
                                </ListItemIcon>
                                <ListItemText
                                    primary={"Hakkımızda"}
                                    primaryTypographyProps={{
                                        sx: {
                                            fontStyle: "normal",
                                            fontWeight: 600,
                                            fontSize: "24px",
                                            lineHeight: "30px",
                                            color: "#FFFFFF",
                                            letterSpacing: "-0.01em",
                                        }
                                    }}
                                />
                            </ListItemButton>
                            <ListItemButton
                                sx={{
                                    py: "10px",
                                    borderBottom: "1px solid rgba(255, 255, 255, 0.1)",
                                    px: "0px",
                                }}
                            >
                                <ListItemIcon sx={{ minWidth: "47px !important" }}>
                                    <Article sx={{ fill: "#fff", fontSize: "35px !important" }} />
                                </ListItemIcon>
                                <ListItemText
                                    primary={"Blog"}
                                    primaryTypographyProps={{
                                        sx: {
                                            fontStyle: "normal",
                                            fontWeight: 600,
                                            fontSize: "24px",
                                            lineHeight: "30px",
                                            color: "#FFFFFF",
                                            letterSpacing: "-0.01em",
                                        }
                                    }}
                                />
                            </ListItemButton>
                            <ListItemButton
                                sx={{
                                    py: "10px",
                                    borderBottom: "1px solid rgba(255, 255, 255, 0.1)",
                                    px: "0px",
                                }}
                            >
                                <ListItemIcon sx={{ minWidth: "47px !important" }}>
                                    <Mail sx={{ fill: "#fff", fontSize: "35px !important" }} />
                                </ListItemIcon>
                                <ListItemText
                                    primary={"İletişim"}
                                    primaryTypographyProps={{
                                        sx: {
                                            fontStyle: "normal",
                                            fontWeight: 600,
                                            fontSize: "24px",
                                            lineHeight: "30px",
                                            color: "#FFFFFF",
                                            letterSpacing: "-0.01em",
                                        }
                                    }}
                                />
                            </ListItemButton>
                        </List>
                    </Box>
                </Drawer>
            </div>
        );
    }
}


const mapStateToProps = (state) => {
    return {
    }
};

export default withStyles(styles)(connect(mapStateToProps, {})(Landing));