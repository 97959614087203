import React from "react";
import { connect } from "react-redux";
import { withStyles } from "@mui/styles";
import PropTypes from "prop-types";
import { Box, Typography, Table, TableHead, TableRow, TableCell, TableBody, IconButton, Tooltip, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, Avatar, FormControl, RadioGroup, FormControlLabel, Radio, TextField, InputAdornment, SvgIcon, Pagination, Card, CardContent } from "@mui/material";
import {
    Delete as DeleteIcon, Check as CheckIcon, Search as SearchIcon, Close,
} from "@mui/icons-material";
import axios from "axios";
import { setNotification } from "../../../actions/global";
import { GlobalTypes } from "../../../action_types";
import { mdiMagnify } from "@mdi/js";
import debounce from "lodash.debounce";
import clsx from "clsx";
import { AxiosClient } from "../../../global/axios";


const styles = theme => ({
    table: {
        border: '2px solid #f0f0f0',
        userSelect: 'text !important',
    },
    tCell: {
        border: 0,
        padding: 32,
        verticalAlign: 'top',
    },
    cellHeader: {
        fontFamily: 'Roboto Slab',
        width: 180,
        fontWeight: 700,
    },
    cellContent: {
        color: '#6b7280 !important',
        fontFamily: 'Roboto Slab !important',
    },
});

class DescriptionApproval extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            reason: '',
            search: '',
            page: 1,
            size: 10,
            filter: 0,
            descriptions: [],
            totalDescriptions: 0,
            approveDescription: null,
            approveStatus: 0,
            deleteDescription: null,
        }

    }

    componentDidMount() {
        this.listDescriptionRequests();
    }

    listDescriptionRequests = async () => {
        try {
            const { search, page, size, filter } = this.state;
            const resp = await AxiosClient().get(`${process.env.REACT_APP_API_URL}/v1/admin/users/description.approvals?search=${search}&page=${page}&size=${size}&filter=${filter}`);

            this.setState({ descriptions: resp.data.data ?? [], totalDescriptions: resp.data.total ?? 0 });

        } catch (err) {
            console.error(err);
            this.props.setNotification({ message: 'Bir hata oluştu. Lütfen tekrar deneyiniz.', severity: GlobalTypes.NOTIFY_ERR });
        }
    }

    componentDidUpdate(prevProps) {
    }

    handleDelete = (r) => {
        this.setState({ deleteDescription: r });
    }

    handleApprove = (r) => {
        this.setState({ approveDescription: r, approveStatus: 1 });
    }

    handleReject = (r) => {
        this.setState({ approveDescription: r, approveStatus: 2 });
    }

    deleteDescription = async (r) => {
        try {
            const resp = await AxiosClient().delete(`${process.env.REACT_APP_API_URL}/v1/admin/users/description.approvals?id=${r.id}`);

            if (resp.data?.ok) {
                this.props.setNotification({ message: 'Hakkında değiştirme isteği silindi.', severity: GlobalTypes.NOTIFY_SUCC });
            }

        } catch (err) {
            console.error(err);
            this.props.setNotification({ message: 'Bir hata oluştu. Lütfen tekrar deneyiniz.', severity: GlobalTypes.NOTIFY_ERR });
        } finally {
            this.setState({ deleteDescription: null }, () => this.listDescriptionRequests());
        }
    }

    approveDescription = async (r, status = 2) => {
        try {
            const { approveDescription, reason, approveStatus } = this.state;

            if (!approveDescription) {
                return;
            }

            const body = {
                id: approveDescription.id,
                status,
                reason,
            };

            const resp = await AxiosClient().post(`${process.env.REACT_APP_API_URL}/v1/admin/users/description.approvals`, body);

            if (resp.data?.ok) {
                this.props.setNotification({ message: `Hakkında değiştirme isteği ${approveStatus === 1 ? 'onaylandı.' : 'reddedildi.'}`, severity: GlobalTypes.NOTIFY_SUCC });
            }

        } catch (err) {
            console.error(err);
            this.props.setNotification({ message: 'Bir hata oluştu. Lütfen tekrar deneyiniz.', severity: GlobalTypes.NOTIFY_ERR });
        } finally {
            this.setState({ approveDescription: null }, () => this.listDescriptionRequests());
        }
    }

    debounceSearchApprovals = debounce(() => this.setState({ page: 1 }, () => this.listDescriptionRequests()), 500);

    searchChanged = (e) => {
        this.setState({ search: e.target.value });
        this.debounceSearchApprovals();
    }

    // UI PART

    deleteDescriptionDialog = () => {
        const { deleteDescription } = this.state;

        return (
            <Dialog open={!!deleteDescription} onClose={() => this.setState({ deleteDescription: null })}>
                <DialogTitle>Hakkında Değiştirme İsteği Sil</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Hakkında değiştirme isteği silinecek, emin misiniz?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => this.setState({ deleteDescription: null })} variant="contained" color="error">
                        İptal
                    </Button>
                    <Button onClick={() => this.deleteDescription(deleteDescription)} variant="contained" color="primary" autoFocus className="!text-white">
                        Sil
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }

    approveDescriptionDialog = () => {
        const { approveDescription, approveStatus } = this.state;
        const close = () => this.setState({ approveDescription: null, approveStatus: 0 });

        return (
            <Dialog open={!!approveDescription} onClose={close}>
                <DialogTitle>{`Hakkında İsteğini ${approveStatus === 1 ? 'Onayla' : 'Reddet'}`}</DialogTitle>
                <DialogContent>
                    {approveStatus === 1 && (
                        <DialogContentText>
                            {`Hakkında isteği onaylanacak emin misiniz?`}
                        </DialogContentText>
                    )}
                    {approveStatus === 2 && (
                        <Table>
                            <TableRow>
                                <TableCell className="!border-0 !p-2">
                                    <Typography sx={{ color: '#413067' }}>
                                        Neden
                                    </Typography>
                                </TableCell>
                                <TableCell className="!border-0 !p-2">
                                    <TextField margin="dense" fullWidth
                                        value={this.state.reason}
                                        sx={{
                                            borderTopRightRadius: '0 !important',
                                            borderBottomRightRadius: '0 !important',
                                            margin: 0,
                                        }}
                                        InputProps={{
                                            classes: {
                                                input: "h-8 sm:h-12",
                                                root: "h-8 sm:h-12",
                                            }
                                        }}
                                        onChange={(e) => this.setState({ reason: e.target.value })}
                                    />
                                </TableCell>
                            </TableRow>
                        </Table>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={close} variant="contained" color="error">
                        İptal
                    </Button>
                    <Button onClick={() => this.approveDescription(approveDescription, approveStatus)} variant="contained" color="primary" autoFocus className="!text-white">
                        Onayla
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }

    getStatus = (status) => {
        switch (status) {
            case 0:
                return 'Beklemede';
            case 1:
                return 'Onaylandı';
            case 2:
                return 'Reddedildi';
            default:
                return 'Beklemede';
        }
    }

    tableView = () => {
        const { classes } = this.props;
        const { descriptions } = this.state;

        if (descriptions.length === 0) {
            return (
                <Box className="!hidden lg:!flex justify-center items-center h-[300px]">
                    <Typography className={"!text-xs md:!text-sm lg:!text-base"}>Sonuç bulunamadı.</Typography>
                </Box>
            );
        }

        return (
            <Table className={clsx(
                classes.table,
                "!hidden lg:!table",
            )}>
                <TableHead>
                    <TableRow>
                        <TableCell className={classes.tCell} sx={{ borderBottom: '2px solid #f0f0f0', minWidth: 0, maxWidth: 0 }}>
                            <Typography className={clsx(classes.cellHeader, "!text-xs md:!text-sm lg:!text-base")}>
                                Nick
                            </Typography>
                        </TableCell>
                        <TableCell className={classes.tCell} sx={{ borderBottom: '2px solid #f0f0f0', minWidth: 0, maxWidth: 0 }}>
                            <Typography className={clsx(classes.cellHeader, "!text-xs md:!text-sm lg:!text-base")}>
                                Hakkında
                            </Typography>
                        </TableCell>
                        <TableCell className={classes.tCell} sx={{ borderBottom: '2px solid #f0f0f0', minWidth: 0, maxWidth: 0 }}>
                            <Typography className={clsx(classes.cellHeader, "!text-xs md:!text-sm lg:!text-base")}>
                                Durum
                            </Typography>
                        </TableCell>
                        {this.state.filter === 2 && (
                            <TableCell className={classes.tCell} sx={{ borderBottom: '2px solid #f0f0f0', minWidth: 0, maxWidth: 0 }}>
                                <Typography className={clsx(classes.cellHeader, "!text-xs md:!text-sm lg:!text-base")}>
                                    Neden
                                </Typography>
                            </TableCell>
                        )}
                        <TableCell className={classes.tCell} sx={{ borderBottom: '2px solid #f0f0f0', minWidth: 0, maxWidth: 0 }}>
                            <Typography className={clsx(classes.cellHeader, "!text-xs md:!text-sm lg:!text-base")}>
                                İstek Tarihi
                            </Typography>
                        </TableCell>
                        <TableCell className={classes.tCell} sx={{ borderBottom: '2px solid #f0f0f0', minWidth: 0, maxWidth: 0 }}>
                            <Typography className={clsx(classes.cellHeader, "!text-xs md:!text-sm lg:!text-base")}>
                                Aksiyon
                            </Typography>
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {descriptions.map(r => {
                        return (
                            <TableRow key={r.id}>
                                <TableCell>
                                    <Typography className={clsx(classes.cellContent, "!text-xs md:!text-sm lg:!text-base")}>
                                        {r.username}
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography className={clsx(classes.cellContent, "!text-xs md:!text-sm lg:!text-base")}>
                                        {r.description}
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography className={clsx(classes.cellContent, "!text-xs md:!text-sm lg:!text-base")}>
                                        {this.getStatus(r.status)}
                                    </Typography>
                                </TableCell>
                                {this.state.filter === 2 && (
                                    <TableCell>
                                        <Typography className={clsx(classes.cellContent, "!text-xs md:!text-sm lg:!text-base")}>
                                            {r.reason ?? '-'}
                                        </Typography>
                                    </TableCell>
                                )}
                                <TableCell>
                                    <Typography className={clsx(classes.cellContent, "!text-xs md:!text-sm lg:!text-base")}>
                                        {new Date(r.created_at).toLocaleDateString()}
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Tooltip title="Sil" placement="top">
                                        <IconButton onClick={() => this.handleDelete(r)}>
                                            <DeleteIcon fontSize="small" />
                                        </IconButton>
                                    </Tooltip>
                                    {this.state.filter === 0 && (
                                        <Tooltip title="Onayla" placement="top">
                                            <IconButton onClick={() => this.handleApprove(r)}>
                                                <CheckIcon fontSize="small" />
                                            </IconButton>
                                        </Tooltip>
                                    )}
                                    {this.state.filter === 0 && (
                                        <Tooltip title="Reddet" placement="top">
                                            <IconButton onClick={() => this.handleReject(r)}>
                                                <Close fontSize="small" />
                                            </IconButton>
                                        </Tooltip>
                                    )}
                                </TableCell>
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
        );
    }

    mobileView = () => {
        const { classes } = this.props;
        const { descriptions } = this.state;

        if (descriptions.length === 0) {
            return (
                <Box className="flex lg:hidden justify-center items-center">
                    <Typography className={"!text-xs md:!text-sm lg:!text-base"}>Sonuç bulunamadı.</Typography>
                </Box>
            );
        }

        return (
            <Box className="flex flex-col gap-y-2 lg:hidden">
                {descriptions.map(a => {
                    return (
                        <Card key={a.id} sx={{ borderRadius: 4, border: '1px solid #c4c4c4', boxShadow: 0 }}>
                            <CardContent>
                                <Table>
                                    <TableRow>
                                        <TableCell className="!p-2">
                                            <Typography className={clsx(classes.cellContent, "!text-xs md:!text-sm lg:!text-base")}>
                                                Nick
                                            </Typography>
                                        </TableCell>
                                        <TableCell className="!p-2">
                                            <Typography className={clsx(classes.cellContent, "!text-xs md:!text-sm lg:!text-base")}>
                                                {a.username}
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell className="!p-2">
                                            <Typography className={clsx(classes.cellContent, "!text-xs md:!text-sm lg:!text-base")}>
                                                Hakkında
                                            </Typography>
                                        </TableCell>
                                        <TableCell className="!p-2">
                                            <Typography className={clsx(classes.cellContent, "!text-xs md:!text-sm lg:!text-base")}>
                                                {a.description}
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell className="!p-2">
                                            <Typography className={clsx(classes.cellContent, "!text-xs md:!text-sm lg:!text-base")}>
                                                Durum
                                            </Typography>
                                        </TableCell>
                                        <TableCell className="!p-2">
                                            <Typography className={clsx(classes.cellContent, "!text-xs md:!text-sm lg:!text-base")}>
                                                {this.getStatus(a.status)}
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                    {this.state.filter === 2 && (
                                        <TableRow>
                                            <TableCell className="!p-2">
                                                <Typography className={clsx(classes.cellContent, "!text-xs md:!text-sm lg:!text-base")}>
                                                    Neden
                                                </Typography>
                                            </TableCell>
                                            <TableCell className="!p-2">
                                                <Typography className={clsx(classes.cellContent, "!text-xs md:!text-sm lg:!text-base")}>
                                                    {a.reason ?? '-'}
                                                </Typography>
                                            </TableCell>
                                        </TableRow>
                                    )}
                                    <TableRow>
                                        <TableCell className="!p-2">
                                            <Typography className={clsx(classes.cellContent, "!text-xs md:!text-sm lg:!text-base")}>
                                                İstek Tarihi
                                            </Typography>
                                        </TableCell>
                                        <TableCell className="!p-2">
                                            <Typography className={clsx(classes.cellContent, "!text-xs md:!text-sm lg:!text-base")}>
                                                {new Date(a.created_at).toLocaleDateString()}
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell className="!p-2">
                                            <Typography className={clsx(classes.cellContent, "!text-xs md:!text-sm lg:!text-base")}>
                                                Aksiyon
                                            </Typography>
                                        </TableCell>
                                        <TableCell className="!p-2">
                                            <Tooltip title="Sil" placement="top">
                                                <IconButton className="!p-[2px] !mr-2" onClick={() => this.handleDelete(a)}>
                                                    <DeleteIcon className="!text-[16px]" />
                                                </IconButton>
                                            </Tooltip>
                                            {this.state.filter === 0 && (
                                                <Tooltip title="Onayla" placement="top">
                                                    <IconButton className="!p-[2px] !mr-2" onClick={() => this.handleApprove(a)}>
                                                        <CheckIcon className="!text-[16px]" />
                                                    </IconButton>
                                                </Tooltip>
                                            )}
                                            {this.state.filter === 0 && (
                                                <Tooltip title="Reddet" placement="top">
                                                    <IconButton className="!p-[2px] !mr-2" onClick={() => this.handleReject(a)}>
                                                        <Close className="!text-[16px]" />
                                                    </IconButton>
                                                </Tooltip>
                                            )}
                                        </TableCell>
                                    </TableRow>
                                </Table>
                            </CardContent>
                        </Card>
                    );
                })}
            </Box>
        );
    }

    render() {
        return (
            <Box margin={1} display={"flex"} flexDirection={"column"} rowGap={1}>
                <Box className="flex gap-x-2 justify-between items-center">
                    <FormControl>
                        <RadioGroup value={this.state.filter}>
                            <Box className="px-2 py-1">
                                <FormControlLabel value={0} control={
                                    <Radio onChange={(e) => this.setState({ filter: 0, page: 1 }, () => this.listDescriptionRequests())}
                                        checked={this.state.filter === 0}
                                    />
                                } label={
                                    <Typography className="!text-xs md:!text-sm lg:!text-base">
                                        Beklemede
                                    </Typography>
                                }
                                />

                                <FormControlLabel value={1} control={
                                    <Radio onChange={(e) => this.setState({ filter: 1, page: 1 }, () => this.listDescriptionRequests())}
                                        checked={this.state.filter === 1}
                                    />
                                } label={
                                    <Typography className="!text-xs md:!text-sm lg:!text-base">
                                        Kabul Edildi
                                    </Typography>
                                }
                                />

                                <FormControlLabel value={2} control={
                                    <Radio onChange={(e) => this.setState({ filter: 2, page: 1 }, () => this.listDescriptionRequests())}
                                        checked={this.state.filter === 2}
                                    />
                                } label={
                                    <Typography className="!text-xs md:!text-sm lg:!text-base">
                                        Reddedildi
                                    </Typography>
                                }
                                />
                            </Box>
                        </RadioGroup>
                    </FormControl>
                </Box>

                <TextField variant="outlined" placeholder="Kişi Ara" fullWidth margin="dense"
                    InputProps={{
                        classes: {
                            input: "!text-xs md:!text-sm lg:!text-base",
                            root: "p-1 md:p-2"
                        },
                        startAdornment: (
                            <InputAdornment position="start">
                                <SvgIcon fontSize='small'>
                                    <path d={mdiMagnify} />
                                </SvgIcon>
                            </InputAdornment>
                        )
                    }}
                    value={this.state.search}
                    onChange={this.searchChanged}
                    sx={{
                        '& .MuiInputBase-root': { p: 0, px: 2 },
                        '& .MuiInputBase-root::before': { borderBottom: '1px solid #E3E3E3 !important' },
                        '& .MuiInputBase-root::after': { borderBottom: '1px solid #E3E3E3 !important' },
                    }}
                />

                {this.tableView()}
                {this.mobileView()}

                <Box className="flex justify-end">
                    <Pagination count={parseInt(Math.ceil(this.state.totalDescriptions / this.state.size))} page={this.state.page}
                        variant="outlined" shape="rounded" color="primary"
                        onChange={(e, p) => this.setState({ page: p }, () => this.listDescriptionRequests())}
                    />
                </Box>

                {this.approveDescriptionDialog()}
                {this.deleteDescriptionDialog()}
            </Box>
        );
    }
}

DescriptionApproval.propTypes = {
    classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
    return {
    }
};

export default withStyles(styles)(connect(mapStateToProps, {
    setNotification,
})(DescriptionApproval));
