import React from "react";
import { connect } from "react-redux";
import { withStyles } from "@mui/styles";
import PropTypes from "prop-types";
import { Avatar, Box, Button, Checkbox, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, FormGroup, Grid, IconButton, Menu, SvgIcon, Tooltip, Typography } from "@mui/material";
import { samePieces } from "../../utils/piece";
import EndRound from "./EndRound";
import Chatbox from "../Chatbox";
import { setRejoinTableId } from "../../actions/lobby";
import { mdiAccountGroup, mdiAccountPlus, mdiChartLine, mdiChat, mdiCog, mdiExitRun, mdiMessageAlert, mdiPlay, mdiRobot, mdiSync } from "@mdi/js";
import Scoreboard from "./Scoreboard";
import Indicator from "./Indicator";
import { setGameInfo, setPieces, setPots, setEndRound, setMobileChatAt } from "../../actions/table";
import Tile from "../../models/tile";
import UserActions from "../Lobby/UserActions";
import { GlobalTypes } from "../../action_types";
import { setNotification, setLeavingTable, addChatTab } from "../../actions/global";
import clsx from "clsx";
import { Close as CloseIcon, Face, Laptop, PhoneAndroid, Settings, TableRestaurant } from "@mui/icons-material";
import { isMobile } from "react-device-detect";
import GameOptions from "../Lobby/GameOptions";
import UserList from "../Lobby/UserList";
import FirstRow from "./FirstRow";
import SecondRow from "./SecondRow";
import ThirdRow from "./ThirdRow";
import Takoz from "./Takoz";
import ContextMenuHandler from "../../utils/ContextMenuHandler";
import axios from "axios";
import UserAvatar from "../Lobby/UserAvatar";
import { deviceIcons } from "../../global/device_icons";
import { AxiosClient } from "../../global/axios";
import { getPath } from "../../global/route";
import { CHATBOX_TYPES } from "../../global/chatbox";
import { roles } from "../../global/role";
import { loadedResources } from "../../global/resources";

const humanize = require('humanize-number');

const styles = theme => ({
    root: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
    },
    text: {
        fontWeight: '400 !important',
    },
    subText: {
        fontWeight: '400 !important',
        color: 'var(--white)',
    },
});

class Table extends React.Component {
    constructor(props) {
        super(props);

        this.userContextMenuHandler = new ContextMenuHandler(
            (e, u) => {
                this.onUserRightClicked(e, u);
            }
        );

        this.state = {
            previewAt: null,
            previewValue: null,
            scoreboard: false,
            chatAnchor: null,
            usersListAnchor: null,
            gameOptionsDialog: false,
            invitationDialog: false,
            reportDialog: false,
            inviteUser: null,
            reported: false,
            returningOkey: false,
        }

        this.listRef = React.createRef();
        this.potRef = React.createRef();
        this.groundRef = React.createRef();
        this.chatboxRef = React.createRef();
        this.tileRefs = new Array(26).fill(0).map(() => React.createRef());
        this.usersRef = React.createRef();
    }

    componentDidMount() {
        document.addEventListener("touchmove", function (event) { event.preventDefault(); });

        this.props.setRejoinTableId(null);

        if (this.props.tableIds.length === 0) {
            setTimeout(() => {
                this.props.history.push(getPath('/salon'));
            }, 0);
        }
    }

    editTableOptions = (opts) => {
        const { socket, user } = this.props;
        const points = user?.points ?? 0;
        if (points < opts.startingPts) {
            this.props.setNotification({ message: 'Yeterli puanınız bulunmuyor.', severity: GlobalTypes.NOTIFY_ERR })
            return;
        }

        socket.emit('edit_table', { opts, tableId: this.props.selectedTable });
        this.setState({ gameOptionsDialog: false });
    }

    startGame = () => {
        this.props.socket?.emit('start_game');
        this.setState({ mobileActionsAnchor: null });
    }

    onDragStart = (value, index) => {
    }

    isGroup = (group, indicator, preview = false) => {
        if ((group.length < 3 || group.length > 4) && !preview) {
            return false; // Grup en az 3, en fazla 4 taştan oluşmalıdır
        }

        let nonOkeyNumber = null;
        let colors = new Set(); // Renkleri takip etmek için bir set

        for (let tile of group) {
            const isOkey = samePieces(tile, (indicator % 13) === 0 ? indicator - 12 : indicator + 1);
            const modp2 = tile > 104 ? ((indicator) % 13) + 1 : ((tile - 1) % 13) + 1;

            if (isOkey) {
                continue;
            }
            else {
                if (!nonOkeyNumber) {
                    nonOkeyNumber = modp2; // İlk okey olmayan taşın sayısını sakla
                }
                else if (modp2 !== nonOkeyNumber) {
                    return false; // Farklı sayıda bir okey olmayan taş bulunursa, grup geçersizdir
                }
            }

            const c2 = tile > 104 ? ~~((indicator - 1) / 26) : ~~((tile - 1) / 26);

            if (colors.has(c2)) {
                return false; // Aynı renkte bir taş zaten varsa, grup geçersizdir
            }

            colors.add(c2);
        }

        return true;
    }

    isRun = (group, indicator, preview = false) => {
        if (group.length < 3 && !preview) {
            return false; // Bir seri en az 3 taştan oluşmalıdır
        }

        const okeys = group.filter(tile => samePieces(tile, (indicator % 13) === 0 ? indicator - 12 : indicator + 1)); // Okeyleri filtrele
        let tiles = group.filter(tile => !okeys.includes(tile)); // Okey olmayan taşları filtrele

        let okeyCount = group.length - tiles.length; // Okey sayısını sakla
        let okeyUsed = 0; // Kullanılan okey sayısını sakla

        if (tiles.length === 0) {
            return preview && okeyCount > 0; // Okey haricinde taş olmayan bir seri, önizleme modunda ise ve okey kullanılmışsa geçerlidir
        }
        else if (tiles.length === 1) {
            return preview || okeyCount > 1; // Okey haricinde tek bir taş varsa, iki okey kullanılmışsa veya önizleme modunda ise seri geçerlidir
        }

        const c1 = tiles[0] > 104 ? ~~((indicator - 1) / 26) : ~~((tiles[0] - 1) / 26);

        if (tiles.slice(1).some(tile => {
            const c2 = tile > 104 ? ~~((indicator - 1) / 26) : ~~((tile - 1) / 26);
            return c1 !== c2;
        })) {
            return false; // Aynı renkte olmayan bir taş bulunursa, seri geçersizdir
        }

        let modp1 = tiles[0] > 104 ? ((indicator) % 13) + 1 : ((tiles[0] - 1) % 13) + 1;
        let modp2 = tiles[1] > 104 ? ((indicator) % 13) + 1 : ((tiles[1] - 1) % 13) + 1;

        let isAscending = modp2 > modp1;
        if (isAscending && modp1 === 1 && modp2 >= 11) {
            isAscending = false;
        }

        if (!isAscending && modp1 >= 11 && modp1 <= 13 && modp2 === 1) {
            isAscending = true;
        }

        //console.log('isrun.isAscending', isAscending, modp1, modp2, tiles[0], tiles[1])
        let lastNumberChecked = modp1;
        let canContinue = true;

        for (let i = 1; i < tiles.length; i++) {
            if (!canContinue) {
                return false; // Seri devam etmiyorsa, seri geçersizdir
            }

            modp2 = tiles[i] > 104 ? ((indicator) % 13) + 1 : ((tiles[i] - 1) % 13) + 1;
            canContinue = modp2 !== 1;

            let expectedNumber = isAscending ? (lastNumberChecked % 13) + 1 : (lastNumberChecked - 1 === 0 ? 13 : lastNumberChecked - 1);

            if (modp2 !== expectedNumber) {
                while (okeyCount > okeyUsed && modp2 !== expectedNumber && expectedNumber !== 1) {
                    expectedNumber = isAscending ? (expectedNumber % 13) + 1 : (expectedNumber - 1 === 0 ? 13 : expectedNumber - 1);
                    okeyUsed++;
                }

                if (modp2 !== expectedNumber) {
                    return false; // Ardışık olmayan bir sayı bulunursa veya doğru sıra gelmezse, seri geçersizdir
                }
            }

            lastNumberChecked = modp2;
        }

        return true;
    }

    endRound = (index) => {
        const { myTableIndicator: indicator, myTableTable: table, selectedTableDouble: double } = this.props;
        const pieces = this.getPieces();
        const exclude = pieces[index];

        const turnIndex = this.props.store.getState().app.table.tables[this.props.myTable].turnIndex;

        if (turnIndex <= table?.users?.length && table?.options?.wIndicator && samePieces(exclude, indicator)) {
            return true;
        }

        if (double) {
            return this.endRoundDouble(index);
        }

        return this.endRoundSerial(index);
    }

    endRoundDouble = (index) => {
        const { myTableIndicator: indicator } = this.props;
        const pieces = this.getPieces();
        const exclude = pieces[index];
        const doubles = [[]];

        for (let i = 0; i < pieces.length; i++) {
            if (i === index) {
                continue;
            }

            const p = pieces[i];
            if (!p) {
                continue;
            }

            if (samePieces(p, exclude)) {
                continue;
            }

            if (doubles[doubles.length - 1].length < 2) {
                doubles[doubles.length - 1].push(p);
            }
            else {
                doubles.push([p]);
            }
        }

        for (let i = 0; i < doubles.length; i++) {
            const per = doubles[i];
            if (per.length !== 2) {
                this.props.setNotification({ message: 'Geçersiz taş dizimi.', severity: GlobalTypes.NOTIFY_ERR });
                return false;
            }

            const lmp = per[0];
            const lp = per[1];

            // Check if one of the pieces is okey, if so, continue
            if (samePieces(lmp, (indicator % 13) === 0 ? indicator - 12 : indicator + 1) || samePieces(lp, (indicator % 13) === 0 ? indicator - 12 : indicator + 1)) {
                continue;
            }

            // Check if the pieces are the same number
            if (samePieces(lmp, lp)) {
                continue;
            }

            this.props.setNotification({ message: 'Geçersiz taş dizimi.', severity: GlobalTypes.NOTIFY_ERR });
            return false;
        }

        return true;
    }

    endRoundSerial = (index) => {
        const { myTableIndicator: indicator } = this.props;
        const pieces = this.getPieces();
        const exclude = pieces[index];

        // list of pers
        const pers = new Array();

        // temp per array
        let per = new Array();

        pieces.forEach((p, i) => {
            if (!p) {
                if (per.length > 0) {
                    pers.push(per);
                    per = new Array();
                }
            }
            else if (p !== exclude) {
                per.push(p);
            }

            if (i === 12 && per.length > 0) {
                pers.push(per);
                per = new Array();
            }
        });

        if (per.length > 0) {
            pers.push(per);
        }

        const splitIntoSubgroups = (group) => {
            let subgroups = [];
            let i = 0;

            while (i < group.length) {
                let windowSize;
                let foundValidGroup = false;

                for (windowSize = 3; i + windowSize <= group.length; windowSize++) {
                    let window = group.slice(i, i + windowSize);
                    if (this.isRun(window, indicator) || this.isGroup(window, indicator)) {
                        foundValidGroup = true;
                        continue; // Geçerli bir yapı bulundu, pencereyi genişlet
                    } else {
                        break; // Geçerli bir yapı bulunamadı, döngüyü kır
                    }
                }

                if (foundValidGroup) {
                    // Geçerli bir grup veya seri bulunmuştu, bu yüzden onu kaydet
                    subgroups.push(group.slice(i, i + windowSize - 1));
                    i += windowSize - 1; // Pencere başlangıcını kaydır
                }
                else {
                    return null;
                }
            }

            return subgroups;
        }

        for (let i = 0; i < pers.length; i++) {
            per = pers[i];
            const valid = this.isGroup(per, indicator) || this.isRun(per, indicator);

            if (!valid && per.length >= 6) {
                const parts = splitIntoSubgroups(per);
                if (!parts) {
                    this.props.setNotification({ message: 'Geçersiz taş dizimi.', severity: GlobalTypes.NOTIFY_ERR });
                    return false;
                }

                pers.splice(i, 1, ...parts);
                i += parts.length - 1;
            }
            else if (!valid) {
                this.props.setNotification({ message: 'Geçersiz taş dizimi.', severity: GlobalTypes.NOTIFY_ERR });
                return false;
            }
        }

        return true;
    }

    onDragEnd = (clientX, clientY, index) => {
        const { myTable } = this.props;
        const turn = this.props.store.getState().app.table.tables[this.props.myTable].turn;

        const pieces = this.getPieces();

        const x = clientX, y = clientY;
        const overlapRects = (rect) => (
            x > rect.left && x < rect.right && y > rect.top && y < rect.bottom
        );

        const groundRect = this.groundRef.current.getBoundingClientRect();
        if (overlapRects(groundRect)) { // end the game
            if (index === 26) // put piece back to the ground
                return;

            const myIndex = this.findUserIndex();
            if (myIndex !== turn) // if it is not my turn then discard.
                return;

            if (this.endRound(index)) {
                this.props.socket?.emit('end_round', index);
            }

            return;
        }

        const potRect = this.potRef.current.getBoundingClientRect();
        if (overlapRects(potRect)) { // put the piece to my pot
            const myIndex = this.findUserIndex();
            if (myIndex !== turn) // if it is not my turn then discard.
                return;

            this.props.socket?.emit('send_pot', index);

            pieces[index] = null;
            this.props.setPieces(myTable, pieces);

            return;
        }

        const children = Array.from(this.listRef.current?.children);

        for (let i = 0; i < children.length; i++) {
            const p = children?.at(i);
            const rect = p.getBoundingClientRect();
            if (i !== index && overlapRects(rect)) {
                this.props.socket?.emit('move_piece', { p1: index, p2: i });

                const temp = pieces[index];
                pieces[index] = pieces[i];
                pieces[i] = temp;
                this.props.setPieces(myTable, pieces);

                break;
            }
        }
    }

    onMovePiece = (e, index) => {
        return;
    }

    onDoubleClick = (e, index) => {
        const { myTable, generalSettings } = this.props;
        const turn = this.props.store.getState().app.table.tables[this.props.myTable].turn;

        if (generalSettings.in_game_double_click_closed) {
            return;
        }

        const myIndex = this.findUserIndex();

        if (myIndex !== turn) // if it is not my turn then discard.
            return;

        const pieces = this.getPieces();

        if (index === 26 || index === 27) { // get from ground or pot
            const i = pieces.findIndex(p => p === null);
            this.props.socket?.emit('move_piece', { p1: index, p2: i });
        }
        else {
            this.props.socket?.emit('send_pot', index);
        }

        pieces[index] = null;
        this.props.setPieces(myTable, pieces);
    }


    /**
     * 
     * @param {Array} okeys 
     * @param {Array} serialPieces 
     * @param {Array} rest 
     * @returns 
     */
    findOkeyPlace = (okeys, serialPieces, rest) => {
        if (okeys.length === 0) {
            return;
        }

        // place okey somewhere sensible
        const places1 = new Array();
        const places2 = new Array();

        for (let c = 0; c < serialPieces.length; c++) {
            const serials = serialPieces[c];
            for (let i = 0; i < serials.length; i++) {
                const per1 = serials[i];
                const lmp = per1[0];
                const lp = per1[per1.length - 1];

                if (i + 1 < serials.length) {
                    const per2 = serials[i + 1];
                    const rp = per2[0];

                    if (samePieces(lp + 2, rp) && lp <= 11) {
                        places1.push({ pts: per1.length + per2.length + 1, i, k: i + 1, c });
                    }
                    else if (okeys.length === 2 && samePieces(lp + 3, rp) && lp <= 10) {
                        places2.push({ pts: per1.length + per2.length + 2, i, k: i + 1, c });
                    }
                }

                for (let j = 0; j < rest.length; j++) {
                    if (samePieces(lp + 2, rest[j])) {
                        places1.push({ pts: per1.length + 2, i, j, c });
                    }
                    else if (samePieces(lmp, rest[j] + 2)) {
                        places1.push({ pts: per1.length + 2, i, j, c, reverse: true });
                    }
                    else if (okeys.length === 2 && samePieces(lp + 3, rest[j])) {
                        places2.push({ pts: per1.length + 3, i, j, c });
                    }
                    else if (okeys.length === 2 && samePieces(lmp, rest[j] + 3)) {
                        places2.push({ pts: per1.length + 3, i, j, c, reverse: true });
                    }
                }

                if ((lmp - 1) % 13 > 0 && per1.length <= 3) {
                    places1.push({ pts: per1.length + 1, i, c, reverse: true });
                }

                if ((lp - 1) % 13 > 0 && per1.length <= 3) {
                    places1.push({ pts: per1.length + 1, i, c });
                }
            }
        }

        // Sort ascending
        places1.sort((p1, p2) => p1.pts - p2.pts);
        //places2.sort((p1, p2) => p1.pts - p2.pts);

        let score1 = (places1[0]?.pts ?? 0) + ((okeys.length === 2 ? places1[1]?.pts : null) ?? 0);
        //let score2 = places2[0]?.pts ?? 0;
        let score2 = 0;

        const places = new Array();
        if (score1 >= score2) {
            if (places1[0]) places.push(places1[0]);
            if (okeys.length === 2 && places1[1]) places.push(places1[1]);
        }
        else {
            places.push({ ...places2[0], double: true });
        }

        places.forEach(p => {
            const _okeys = [okeys.pop()];
            if (p.double) {
                _okeys.push(okeys.pop());
            }

            const per = serialPieces[p.c][p.i];

            // per + rest
            if (p.j) {
                const rp = rest[p.j];
                if (p.reverse) {
                    per.splice(0, 0, ..._okeys);
                    per.splice(0, 0, rp);
                }
                else {
                    per.push(..._okeys);
                    per.push(rp);
                }

                rest.splice(p.j, 1);
            }
            // per + per
            else if (p.k) {
                const per2 = serialPieces[p.c][p.k];
                per.push(..._okeys);
                per.push(...per2);

                serialPieces[p.c].splice(p.k, 1);
            }
            // per + okey
            else {
                if (p.reverse) {
                    per.splice(0, 0, ..._okeys);
                }
                else {
                    per.push(..._okeys);
                }
            }
        });
        rest.push(...okeys);
    }

    arrangeDouble = () => {
        const { myTableIndicator: indicator } = this.props;
        const myPieces = this.props.store.getState().app.table.tables[this.props.myTable].pieces;
        const pieces = Array.from(myPieces);

        pieces.sort((p1, p2) => {
            if (!p1)
                return 1;

            if (!p2)
                return -1;

            const c1 = ~~((p1 - 1) / 26);
            const c2 = ~~((p2 - 1) / 26);

            if (c1 === c2)
                return ((p1 - 1) % 13) - ((p2 - 1) % 13);

            return p1 - p2;
        });

        const doubles = Array.of();
        const rest = Array.of();
        const okeys = new Array();

        const pushRest = (p) => {
            if (rest.indexOf(p) === -1) {
                rest.push(p);
            }
        }

        for (let i = 0; i < pieces.length - 1; i++) {
            const p1 = pieces[i];
            const p2 = pieces[i + 1];
            if (!p1)
                continue;

            let isOkey = samePieces(p1, (indicator % 13) === 0 ? indicator - 12 : indicator + 1)
            if (isOkey) {
                okeys.push(p1);
                continue;
            }

            if (!p2) {
                pushRest(p1);
                continue;
            }

            if (samePieces(p1, p2)) {
                doubles.push([p1, p2]);
                i++;
            }
            else {
                pushRest(p1);
            }
        }

        while (okeys.length > 0 && rest.length > 0) {
            doubles.push([rest.pop(), okeys.pop()]);
        }

        if (okeys.length > 0) {
            rest.push(...okeys);
        }

        const doublePiecesMerged = new Array();
        doubles.forEach(dp => {
            doublePiecesMerged.push(dp);
        });

        doublePiecesMerged.push(rest);

        const piecesArranged = [];
        doublePiecesMerged.forEach(dp => {
            while ((piecesArranged.length % 13) + (dp.length % 13) > 13) {
                piecesArranged.push(null);
            }

            piecesArranged.push(...dp);
            if (piecesArranged.length % 13 > 0)
                piecesArranged.push(null);
        });

        while (piecesArranged.length < 26)
            piecesArranged.push(null);

        this.props.socket?.emit('set_pieces', piecesArranged);

    }

    arrangeSerial = () => {
        const { myTableIndicator: indicator } = this.props;
        const myPieces = this.props.store.getState().app.table.tables[this.props.myTable].pieces;
        const pieces = Array.from(myPieces);

        pieces.sort((a, b) => a && b ? a - b : 1);

        const piecesByColors = Array.of(new Array(), new Array(), new Array(), new Array());

        for (let i = 0; i < pieces.length; i++) {
            const p = pieces[i];
            if (!p)
                continue;

            const c = p > 104 ? ~~((indicator - 1) / 26) : ~~((p - 1) / 26);
            piecesByColors.at(c).push(p);
        }

        const serialPieces = Array.of(new Array(), new Array(), new Array(), new Array());
        const okeys = new Array();
        const rest = new Array();

        for (let i = 0; i < piecesByColors.length; i++) {
            const pmap = piecesByColors.at(i);
            pmap.sort((a, b) => {
                if (a > 104)
                    return ((indicator) % 13) - ((b - 1) % 13)
                else if (b > 104)
                    return ((a - 1) % 13) - ((indicator) % 13)
                return ((a - 1) % 13) - ((b - 1) % 13)
            });
        }

        for (let i = 0; i < piecesByColors.length; i++) {
            const pmap = piecesByColors.at(i);
            if (pmap.length === 0) {
                continue;
            }

            let found = false;
            let count = 0;
            let leapOne = null;


            for (let j = 0; pmap.length; j++) {
                const p1 = pmap.at(j);

                let isOkey = samePieces(p1, (indicator % 13) === 0 ? indicator - 12 : indicator + 1)
                if (isOkey) {
                    found = false;
                    okeys.push(p1);
                    continue;
                }

                if (j + 1 >= pmap.length) {
                    if (found && leapOne) {
                        const per = serialPieces.at(i).at(count);
                        const lastP = per.at(per.length - 1);
                        const modLastP = lastP > 104 ? ((indicator) % 13) + 1 : ((lastP - 1) % 13) + 1;
                        if (modLastP === 13) {
                            per.push(leapOne);
                        }
                        else {
                            rest.push(leapOne);
                        }
                    } else if (leapOne) {
                        rest.push(leapOne);
                    }

                    if (!found) {
                        rest.push(p1);
                    }

                    break;
                }

                const p2 = pmap.at(j + 1);
                isOkey = samePieces(p2, (indicator % 13) === 0 ? indicator - 12 : indicator + 1)
                if (isOkey) {
                    if (found) {
                        count++;
                        found = false;
                    }
                    else
                        rest.push(p1);

                    continue;
                }

                const modp1 = p1 > 104 ? ((indicator) % 13) + 1 : ((p1 - 1) % 13) + 1;
                const modp2 = p2 > 104 ? ((indicator) % 13) + 1 : ((p2 - 1) % 13) + 1;

                if (modp1 + 1 === modp2) {

                    if (!found) {
                        serialPieces.at(i).push([p1, p2]);
                    } else {
                        serialPieces.at(i).at(count).push(p2);
                    }

                    found = true;
                } else {
                    if (found) {
                        count++;
                    }
                    else if (modp1 === 1 && leapOne === null) {
                        leapOne = p1;
                    }
                    else {
                        rest.push(p1);
                    }

                    found = false;
                }
            }
        }

        this.findOkeyPlace(okeys, serialPieces, rest);

        const used = new Map();
        const sameNumbers = new Array();

        for (let i = 0; i < rest.length - 1; i++) {
            const p1 = rest[i];
            if (used.has(p1))
                continue;

            const modp1 = p1 > 104 ? ((indicator) % 13) + 1 : ((p1 - 1) % 13) + 1;
            const c1 = p1 > 104 ? ~~((indicator - 1) / 26) : ~~((p1 - 1) / 26);

            const colors = new Map();
            const tempSameNumbers = new Array();
            colors.set(c1, true);
            tempSameNumbers.push(p1);

            for (let j = i + 1; j < rest.length; j++) {
                const p2 = rest[j];
                if (used.has(p2))
                    continue;

                const modp2 = p2 > 104 ? ((indicator) % 13) + 1 : ((p2 - 1) % 13) + 1;

                const c2 = ~~p2 > 104 ? ~~((indicator - 1) / 26) : ~~((p2 - 1) / 26);
                if (c2 >= 4)
                    continue;

                if (modp1 !== modp2 || colors.has(c2))
                    continue;

                colors.set(c2);
                used.set(p1, true);
                used.set(p2, true);
                tempSameNumbers.push(p2);
            }

            if (tempSameNumbers.length > 1) {
                sameNumbers.push(tempSameNumbers);
            }
        }

        sameNumbers.forEach(sna => {
            sna.forEach(sn => {
                for (let i = 0; i < rest.length; i++) {
                    if (rest[i] === sn) {
                        rest.splice(i, 1);
                        break;
                    }
                }
            })
        });

        const serialPiecesMerged = new Array();
        serialPieces.forEach(sp => {
            sp.forEach(spc => {
                serialPiecesMerged.push(spc);
            });
        });

        sameNumbers.forEach(sna => {
            serialPiecesMerged.push(sna);
        });

        serialPiecesMerged.push(rest);

        const piecesArranged = [];
        serialPiecesMerged.forEach(sp => {
            while ((piecesArranged.length % 13) + sp.length > 13) {
                piecesArranged.push(null);
            }

            piecesArranged.push(...sp);
            if (piecesArranged.length % 13 > 0)
                piecesArranged.push(null);
        });

        while (piecesArranged.length < 26)
            piecesArranged.push(null);

        this.props.socket?.emit('set_pieces', piecesArranged);
    }

    getCombinationsWithNextDepth = (all, basePer, combination, indicator) => {
        const okeys = new Array();
        const nextCombinations = new Array();
        let found = false;

        basePer.forEach(p => {
            if (samePieces(p, (indicator % 13) === 0 ? indicator - 12 : indicator + 1)) {
                okeys.push(p);
            }
        });

        loop2:
        for (let j = 0; j < all.length; j++) {
            const _validPer = all[j].filter(p => !okeys.includes(p));
            if (!this.isGroup(_validPer, indicator) && !this.isRun(_validPer, indicator)) {
                continue;
            }

            for (let k = 0; k < combination.length; k++) {
                if (_validPer.some(p => combination[k].includes(p))) {
                    continue loop2;
                }
            }

            found = true;
            nextCombinations.push([...combination, _validPer]);
        }

        if (!found) {
            return null;
        }

        return nextCombinations;
    }

    selectCombination = (combinations, invalidPers, pieces) => {
        const combinationMerged = new Array();

        if (combinations.length > 0) {
            combinations.sort((c1, c2) =>
                c1.reduce((a, b) => a + b.length, 0) - c2.reduce((a, b) => a + b.length, 0)
            );

            combinations[combinations.length - 1].forEach(comb => {
                while (combinationMerged.length < 13 && combinationMerged.length + comb.length > 13) {
                    combinationMerged.push(null);
                }

                combinationMerged.push(...comb);
                if (combinationMerged.length !== 13) {
                    combinationMerged.push(null);
                }
            });

            while (combinationMerged.length < 13)
                combinationMerged.push(null);
        }

        for (let j = 0; j < invalidPers.length; j++) {
            if (invalidPers[j].some(p => combinationMerged.includes(p))) {
                continue;
            }

            combinationMerged.push(...invalidPers[j]);
        }

        pieces.forEach(p => {
            if (!combinationMerged.includes(p)) {
                combinationMerged.push(p);
            }
        });

        while (combinationMerged.length < 26)
            combinationMerged.push(null);

        this.props.socket?.emit('set_pieces', combinationMerged);
    }

    clearDuplicates = (combinations) => {
        const _combinations = new Array();
        const flatMap = {};

        combinations.forEach(comb => {
            // flat comb array
            const flatComb = new Array();
            comb.forEach(c => {
                flatComb.push(...c);
            });

            // sort flat comb
            flatComb.sort((a, b) => a - b);

            const key = flatComb.join(',');
            if (!flatMap[key]) {
                flatMap[key] = comb;
            }
        });

        Object.keys(flatMap).forEach(key => {
            const comb = flatMap[key]
            _combinations.push(comb);
        });

        return _combinations;
    }

    initialSerialPieces = (pieces, indicator) => {
        const pers = new Array();

        for (let i = 0; i < pieces.length; i++) {
            const per = new Array();
            per.push(pieces[i]);
            const pass = new Array();

            for (let k = 0; k < pieces.length; k++) {
                if (i === k || pass.includes(k)) {
                    continue;
                }

                const p2 = pieces[k];
                if (this.isRun([...per, p2], indicator, true)) {
                    per.push(p2);
                    pass.push(k);
                    k = 0;
                    pers.push([...per]);
                }
            }
        }

        return pers;
    }

    initialSerialPiecesV2 = (pieces, indicator) => {
        const pers = new Array();

        for (let i = 0; i < pieces.length; i++) {
            const per = new Array();
            const newPers = new Array();

            per.push(pieces[i]);
            newPers.push(per);

            const pass = new Array();

            for (let j = 0; j < pieces.length; j++) {
                if (i === j || pass.includes(j)) {
                    continue;
                }

                const p2 = pieces[j];
                const size = newPers.length;
                let found = false;

                for (let k = 0; k < size; k++) {
                    const pi = newPers[k];

                    if (this.isRun([...pi, p2], indicator, true)) {
                        found = true;
                        newPers.push([...pi, p2]);
                    }
                }

                if (!found) {
                    pass.push(j);
                    j = 0;
                }
            }

            pers.push(...newPers);
        }

        return pers;
    }

    initialSerialPiecesV3 = (pieces, indicator) => {
        const pers = new Array();
        const equalPieces = {};

        for (let i = 0; i < pieces.length; i++) {
            const p1 = pieces[i];

            for (let j = 0; j < pieces.length; j++) {
                if (i === j) {
                    continue;
                }

                const p2 = pieces[j];
                if (samePieces(p1, p2)) {
                    equalPieces[p1] = p2;
                    equalPieces[p2] = p1;
                }
            }
        }

        for (let i = 0; i < pieces.length; i++) {
            const per = new Array();
            per.push(pieces[i]);

            const pass = new Array();

            for (let k = 0; k < pieces.length; k++) {
                if (i === k || pass.includes(k)) {
                    continue;
                }

                const p2 = pieces[k];
                if (this.isRun([...per, p2], indicator, true)) {
                    per.push(p2);
                    pass.push(k);
                    k = 0;
                    pers.push([...per]);
                }
            }
        }

        const persLength = pers.length;
        for (let i = 0; i < persLength; i++) {
            const per = pers[i];
            const hasEqual = per.some(p => equalPieces[p]);

            if (!hasEqual) {
                continue;
            }

            const equalPer = new Array();
            per.forEach(p => {
                if (equalPieces[p]) {
                    equalPer.push(equalPieces[p]);
                }
                else {
                    equalPer.push(p);
                }
            });

            pers.push(equalPer);
        }

        return pers;
    }

    arrangeSerialV2 = () => {
        /*
        runTest(isRunSuite, (sc) => {
            return this.isRun(sc.pieces, sc.indicator, false);
        });
        return
        */

        const { myTableIndicator: indicator } = this.props;
        const myPieces = this.props.store.getState().app.table.tables[this.props.myTable].pieces;

        const okeys = Array.from(myPieces).filter(p => samePieces(p, (indicator % 13) === 0 ? indicator - 12 : indicator + 1));
        const pieces = Array.from(myPieces).filter(p => p && !okeys.includes(p));
        pieces.push(...okeys);

        let okeyPers = new Array();
        const pers = this.initialSerialPiecesV3(pieces, indicator);

        const groups = {};
        for (let i = 0; i < pieces.length; i++) {
            const per = new Array();
            per.push(pieces[i]);

            for (let j = 0; j < pieces.length; j++) {
                const p2 = pieces[j];

                if (i === j || p2 in groups) {
                    continue;
                }

                if (this.isGroup([...per, p2], indicator, true)) {
                    per.push(p2);
                    pers.push([...per]);
                    groups[pieces[i]] = true;
                }
            }

            if (per.length > 1) {
                pers.push(per);
            }
        }

        okeys.forEach(okey => {
            pers.forEach(per => {
                if (per.includes(okey)) {
                    return;
                }

                if (this.isGroup([okey, ...per], indicator, true) || this.isRun([okey, ...per], indicator, true)) {
                    okeyPers.push([okey, ...per]);
                }
            });
        });

        pers.push(...okeyPers);

        let combinations = new Array();
        const validPers = pers.filter(per => per.length >= 3);
        const invalidPers = pers.filter(per => per.length === 2);

        //console.log("ALL", pers)
        //console.log("VALID", validPers);

        // 1. depth
        for (let i = 0; i < validPers.length; i++) {
            combinations.push([validPers[i]]);
        }

        combinations = this.clearDuplicates(combinations);
        //console.log("COMBINATIONS 1", combinations)

        let _combinations = new Array();

        // 2. depth
        for (let i = 0; i < combinations.length; i++) {
            const validPer = combinations[i];
            const comb = [...validPer];

            let nextCombinations = this.getCombinationsWithNextDepth(validPers, validPer, comb, indicator);
            if (!nextCombinations) {
                continue;
            }

            _combinations.push(...nextCombinations);
        }

        if (_combinations.length === 0) {
            this.selectCombination(combinations, invalidPers, pieces);
            return;
        }

        combinations.push(..._combinations);
        combinations = this.clearDuplicates(combinations);

        //console.log("COMBINATIONS 2", combinations)

        // 3. depth
        _combinations = new Array();
        let filteredCombinations = combinations.filter(comb => comb.length === 2);

        for (let i = 0; i < filteredCombinations.length; i++) {
            const comb = filteredCombinations[i];
            const validPer = comb[0];

            const nextCombinations = this.getCombinationsWithNextDepth(validPers, validPer, comb, indicator);
            if (!nextCombinations) {
                continue;
            }

            _combinations.push(...nextCombinations);
        }

        if (_combinations.length === 0) {
            this.selectCombination(combinations, invalidPers, pieces);
            return;
        }

        combinations.push(..._combinations);
        combinations = this.clearDuplicates(combinations);

        //console.log("COMBINATIONS 3", combinations)

        // 4. depth
        _combinations = new Array();
        filteredCombinations = combinations.filter(comb => comb.length === 3);

        for (let i = 0; i < filteredCombinations.length; i++) {
            const comb = filteredCombinations[i];
            const validPer = comb[0];

            const nextCombinations = this.getCombinationsWithNextDepth(validPers, validPer, comb, indicator);
            if (!nextCombinations) {
                continue;
            }

            _combinations.push(...nextCombinations);
        }

        if (_combinations.length > 0) {
            combinations.push(..._combinations);
            combinations = this.clearDuplicates(combinations);
        }

        //console.log("COMBINATIONS 4", combinations)
        this.selectCombination(combinations, invalidPers, pieces);
        return;

        for (let i = 0; i < validPers.length; i++) {
            const comb = new Array();
            const okeys = new Array();

            comb.push(validPers[i]);
            validPers[i].forEach(p => {
                if (samePieces(p, (indicator % 13) === 0 ? indicator - 12 : indicator + 1)) {
                    okeys.push(p);
                }
            });

            loop2:
            for (let j = 0; j < validPers.length; j++) {
                if (i === j) {
                    continue;
                }

                const _validPer = validPers[j].filter(p => !okeys.includes(p));
                if (!this.isGroup(_validPer, indicator) && !this.isRun(_validPer, indicator)) {
                    continue;
                }

                for (let k = 0; k < comb.length; k++) {
                    if (_validPer.some(p => comb[k].includes(p))) {
                        continue loop2;
                    }
                }

                comb.push(_validPer);
            }

            if (comb.length > 1) {
                combinations.push(comb);
            }
        }

        for (let i = 0; i < combinations.length; i++) {
            const comb = new Array();
            comb.push(...combinations[i]);

            loop2:
            for (let j = i + 1; j < invalidPers.length; j++) {
                for (let k = 0; k < comb.length; k++) {
                    if (invalidPers[j].some(p => comb[k].includes(p))) {
                        continue loop2;
                    }
                }

                comb.push(invalidPers[j]);
            }

            if (comb.length > 1) {
                combinations[i] = comb;
            }
        }

        combinations.sort((c1, c2) =>
            c1.filter(p => p.length >= 3).reduce((a, b) => a + b.length, 0) -
            c2.filter(p => p.length >= 3).reduce((a, b) => a + b.length, 0)
        );

        if (combinations.length === 0) {
            return;
        }

        const combinationMerged = new Array();
        combinations[combinations.length - 1].forEach(comb => {
            while (combinationMerged.length < 13 && combinationMerged.length + comb.length > 13) {
                combinationMerged.push(null);
            }

            combinationMerged.push(...comb);
            if (combinationMerged.length !== 13) {
                combinationMerged.push(null);
            }
        });

        while (combinationMerged.length < 13)
            combinationMerged.push(null);

        pieces.forEach(p => {
            if (!combinationMerged.includes(p)) {
                combinationMerged.push(p);
            }
        });

        while (combinationMerged.length < 26)
            combinationMerged.push(null);

        this.props.socket?.emit('set_pieces', combinationMerged);
    }

    onDouble = () => {
        this.setState({ doubling: true });
    }

    closeScoreboard = () => {
        const { selectedTable, selectedTableEndRound: endRound } = this.props;

        this.setState({ scoreboard: false });

        if (endRound?.end_game) {
            this.props.setGameInfo(selectedTable, null);
            this.props.setPieces(selectedTable, []);
            this.props.setPots(selectedTable, []);
            this.props.setEndRound(selectedTable, null);
        }
    }

    returnOkey = () => {
        const { myTableIndicator: indicator } = this.props;

        const pieces = this.getPieces();
        const okeyIndex = pieces.findIndex(p => p && samePieces(p, (indicator % 13) === 0 ? indicator - 12 : indicator + 1));
        if (okeyIndex === -1) {
            this.props.setNotification({ message: 'Okey bulunamadı.', severity: GlobalTypes.NOTIFY_ERR });
            return;
        }

        this.props.socket?.emit('return_okey', { index: okeyIndex });
    }

    returnOkeyDialog = () => {
        const { classes } = this.props;

        const onClose = () => {
            this.setState({ returningOkey: false });
        }

        const confirmReturnOkey = () => {
            this.returnOkey();
            onClose();
        }

        return (
            <Dialog
                open={this.state.returningOkey}
                onClose={onClose}
            >
                <DialogTitle className={classes.dialogTitle}>
                    <Typography className={clsx(classes.text, "!text-xs sm:!text-sm md:!text-base")}>
                        Tura dönmek istediğinize emin misiniz?
                    </Typography>
                </DialogTitle>
                <DialogActions>
                    <Button variant="contained" sx={{ color: 'var(--white)' }} color='error' onClick={onClose}>
                        Hayır
                    </Button>
                    <Button variant="contained" autoFocus sx={{ color: 'var(--white)' }} onClick={confirmReturnOkey}>
                        Evet
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }

    doubleDialog = () => {
        const { classes } = this.props;

        const onClose = () => {
            this.setState({ doubling: false });
        }

        const confirmDouble = () => {
            this.props.socket?.emit('double');
            onClose();
        }

        return (
            <Dialog
                open={this.state.doubling}
                onClose={onClose}
            >
                <DialogTitle className={classes.dialogTitle}>
                    <Typography className={clsx(classes.text, "!text-xs sm:!text-sm md:!text-base")}>
                        Çifte gitmek istediğinize emin misiniz?
                    </Typography>
                </DialogTitle>
                <DialogActions>
                    <Button variant="contained" sx={{ color: 'var(--white)' }} color='error' onClick={onClose}>
                        Hayır
                    </Button>
                    <Button variant="contained" autoFocus sx={{ color: 'var(--white)' }} onClick={confirmDouble}>
                        Evet
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }

    addBot = (index) => {
        this.props.socket?.emit('add_bot', { index });
    }

    mobileHeader = () => {
        const { isLandscape } = this.props;

        return (
            <Box borderBottom={"2px solid #2f997a"}
                className={clsx(
                    isLandscape ? "hidden" : "flex md:hidden",
                    "w-full justify-center py-4"
                )}
            >
                <Box className="flex max-w-xl flex-1 items-center justify-evenly">
                    {this.actionButtons()}
                </Box>
            </Box>
        );
    }

    renderMobileActionsMenu = () => {
        const { mobileActionsAnchor } = this.state;

        return (
            <Menu open={Boolean(mobileActionsAnchor)} anchorEl={mobileActionsAnchor}
                onClose={() => this.setState({ mobileActionsAnchor: null })}
                anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                transformOrigin={{ horizontal: "right", vertical: "bottom" }}
            >
                <Box p={2}>
                    {this.actionButtons()}
                </Box>
            </Menu>
        );
    }

    actionButtons = () => {
        const { selectedTable, myTable, selectedTableGame: game, role } = this.props;
        const { chatAnchor } = this.state;

        const started = Boolean(game?.started);
        const notificationCount = chatAnchor ? 0 :
            this.props.tableMessages.filter(m => m.ts > this.props.selectedTableMobileChatAt).length;

        return (
            <>
                {this.buildButton(
                    'Başlat', mdiPlay, 'contained', started || selectedTable !== myTable,
                    {
                        style: {
                            backgroundColor: 'none !important',
                            backgroundImage: started || selectedTable !== myTable ?
                                'linear-gradient(to bottom, #1F97B560 50%, #1F97B560)' :
                                'linear-gradient(to bottom, #F94449 50%, #F01E2C)',
                        },
                        onClicked: this.startGame
                    }
                )}

                {this.buildButton(
                    'Oyuncu Davet Et', mdiAccountPlus, 'text', started || selectedTable !== myTable,
                    {
                        style: { backgroundColor: started || selectedTable !== myTable ? '#1F97B560 !important' : '#1F97B5 !important', px: '16px' },
                        onClicked: () => {
                            this.setState({ mobileActionsAnchor: null, invitationDialog: true })
                        },
                        props: { disableFocusRipple: true, disableRipple: true }
                    }
                )}

                {this.buildButton(
                    'Masa Ayarları', mdiCog, 'text', selectedTable !== myTable && role <= roles.Player,
                    {
                        style: { backgroundColor: selectedTable !== myTable && role <= roles.Player ? '#1F97B560 !important' : '#1F97B5 !important', px: '16px' },
                        onClicked: () => {
                            this.setState({ mobileActionsAnchor: null, gameOptionsDialog: true })
                        },
                        props: { disableFocusRipple: true, disableRipple: true }
                    }
                )}

                {this.buildButton(
                    'Skor Tablosu', mdiChartLine, 'text', !started,
                    {
                        style: { backgroundColor: started ? '#1F97B5 !important' : '#1F97B560 !important', px: '16px' },
                        onClicked: () => this.setState({ scoreboard: true, mobileActionsAnchor: null }),
                        props: { disableFocusRipple: true, disableRipple: true }
                    }
                )}

                {this.buildButton(
                    'Şikayet Et', mdiMessageAlert, 'text', this.state.reported,
                    {
                        style: { backgroundColor: !this.state.reported ? '#1F97B5 !important' : '#1F97B560 !important', px: '16px' },
                        onClicked: () => {
                            this.setState({ mobileActionsAnchor: null, reportDialog: true })
                        },
                        props: { disableFocusRipple: true, disableRipple: true }
                    }
                )}

                {this.buildButton(
                    'Sohbeti Göster', mdiChat, 'text', false,
                    {
                        style: { backgroundColor: '#1F97B5 !important', px: '16px' }, onClicked: (e) => {
                            this.props.setMobileChatAt(this.props.selectedTable, Date.now());
                            this.setState({ chatAnchor: e.currentTarget });
                        },
                        props: { disableFocusRipple: true, disableRipple: true },
                        className: "!hidden md:!flex lg:!hidden",
                        notificationCount: notificationCount,
                    }
                )}

                {this.buildButton(
                    'Kullanıcı Listesi', mdiAccountGroup, 'text', false,
                    {
                        style: { backgroundColor: '#1F97B5 !important', px: '16px' }, onClicked: (e) => this.setState({ usersListAnchor: e.currentTarget }),
                        props: { disableFocusRipple: true, disableRipple: true },
                        className: "!flex lg:!hidden"
                    }
                )}

                {this.buildButton(
                    'Masadan Kalk', mdiExitRun, 'text', false,
                    {
                        style: { backgroundColor: '#1F97B5 !important', px: '16px' }, onClicked: () => this.props.setLeavingTable(selectedTable),
                        props: { disableFocusRipple: true, disableRipple: true }
                    }
                )}

            </>
        );
    }

    autoPilot = () => {
        const { user } = this.props;

        const onChange = (e) => {
            this.props.socket.emit('auto_pilot', { auto_pilot: e.target.checked });
        }

        return (
            <Box className="px-4">
                <FormGroup>
                    <FormControlLabel control={
                        <Checkbox checked={user?.autoPilot ?? false}
                            onChange={onChange}
                            className="!py-0 !px-1 md:!px-1"
                            sx={{ '& .MuiSvgIcon-root': { color: '#FFFFFF' } }}
                        />
                    }
                        label={<Typography className={clsx("!text-xs md:!text-sm lg:!text-base text-white")}>
                            Oto Pilot
                        </Typography>
                        }
                    />
                </FormGroup>
            </Box>
        );
    }

    fourthRow = () => {
        const { classes, isLandscape, selectedTable, myTable,
            selectedTableDouble: double, selectedTableGame: game,
            selectedTableReturnOkey: returnOkey,
        } = this.props;

        const { chatAnchor } = this.state

        const spectating = selectedTable !== myTable;

        const disabled = !game?.started;
        const notificationCount = chatAnchor ? 0 :
            this.props.tableMessages.filter(m => m.ts > this.props.selectedTableMobileChatAt).length;

        const buttons = [
            (mobile) => (
                <Tooltip title="Seri Diz">
                    <Button variant="contained" onClick={this.arrangeSerialV2}
                        className="w-min self-center lg:w-full lg:self-end"
                        sx={{
                            color: 'var(--white)', borderTopRightRadius: mobile ? null : '0 !important',
                            borderBottomRightRadius: mobile ? null : '0 !important', px: 2, py: 1,
                            backgroundImage: disabled || spectating ? null : "linear-gradient(to bottom, #2FB16B 50%, #168549)",
                            display: 'flex', justifyContent: 'start', maxHeight: '10vh'
                        }}
                        disabled={disabled || spectating}
                    >
                        <Box columnGap={1}
                            className="flex flex-1 items-center justify-center lg:justify-start"
                        >
                            <img draggable="false" src={loadedResources[`${process.env.REACT_APP_CDN_URL}/web/img/serial_pieces.png`]} width={32} height={25} />
                            <span className={clsx(classes.subText, "!text-xs md:!text-sm hidden lg:flex whitespace-nowrap")}>
                                Seri Diz
                            </span>
                        </Box>
                    </Button>
                </Tooltip>
            ),
            (mobile) => (
                <Tooltip title="Çift Diz">
                    <Button variant="contained" onClick={() => this.arrangeDouble()}
                        className="w-min self-center lg:w-full lg:self-end"
                        sx={{
                            color: 'var(--white)', borderTopRightRadius: mobile ? null : '0 !important',
                            borderBottomRightRadius: mobile ? null : '0 !important', px: 2, py: 1,
                            backgroundImage: disabled || spectating ? null : "linear-gradient(to bottom, #2FB16B 50%, #168549)",
                            display: 'flex', justifyContent: 'start', maxHeight: '12.5vh'
                        }}
                        disabled={disabled || spectating}
                    >
                        <Box columnGap={1}
                            className="flex flex-1 items-center justify-center lg:justify-start"
                        >
                            <img draggable="false" src={loadedResources[`${process.env.REACT_APP_CDN_URL}/web/img/double_pieces.png`]} width={32} height={25} />
                            <span className={clsx(classes.subText, "!text-xs md:!text-sm hidden lg:flex whitespace-nowrap")}>
                                Çift Diz
                            </span>
                        </Box>
                    </Button>
                </Tooltip>
            ),
            (mobile) => (
                <Tooltip title="Çift Git">
                    <Button variant="contained" onClick={this.onDouble}
                        className="w-min self-center lg:w-full lg:self-start"
                        sx={{
                            color: 'var(--white)', borderTopLeftRadius: mobile ? null : '0 !important',
                            borderBottomLeftRadius: mobile ? null : '0 !important', px: 2, py: 1,
                            backgroundImage: disabled || spectating || double ? null : "linear-gradient(to bottom, #2FB16B 50%, #168549)",
                            display: 'flex', justifyContent: 'start', maxHeight: '12.5vh'
                        }}
                        disabled={disabled || spectating || double}
                    >
                        <Box columnGap={1}
                            className="flex flex-1 items-center justify-center lg:justify-start"
                        >
                            <img draggable="false" src={loadedResources[`${process.env.REACT_APP_CDN_URL}/web/img/double_pieces.png`]} width={32} height={25} />
                            <span className={clsx(classes.subText, "!text-xs md:!text-sm hidden lg:block whitespace-nowrap")}>
                                Çift Git
                            </span>
                        </Box>
                    </Button>
                </Tooltip>
            ),
            (mobile) => (
                <Tooltip title="Tura Dön">
                    <Button variant="contained" onClick={() => this.setState({ returningOkey: true })}
                        className="w-min self-center lg:w-full lg:self-start"
                        sx={{
                            color: 'var(--white)', borderTopLeftRadius: mobile ? null : '0 !important',
                            borderBottomLeftRadius: mobile ? null : '0 !important', px: 2, py: 1,
                            backgroundImage: disabled || spectating || returnOkey ? null : "linear-gradient(to bottom, #2FB16B 50%, #168549)",
                            display: 'flex', justifyContent: 'start', maxHeight: '12.5vh'
                        }}
                        disabled={disabled || spectating || returnOkey}
                    >
                        <Box columnGap={1}
                            className="flex flex-1 items-center justify-center lg:justify-start"
                        >
                            <SvgIcon sx={{ color: 'white' }}><path d={mdiSync} /></SvgIcon>
                            <span className={clsx(classes.subText, "!text-xs md:!text-sm hidden lg:flex whitespace-nowrap")}>
                                Tura Dön
                            </span>
                        </Box>
                    </Button>
                </Tooltip>
            ),
            (mobile) => (
                <Tooltip title="Sohbeti Göster">
                    <Button variant="contained" onClick={(e) => {
                        this.props.setMobileChatAt(this.props.selectedTable, Date.now());
                        this.setState({ chatAnchor: e.currentTarget });
                    }}
                        className="w-min self-center lg:w-full lg:self-start"
                        sx={{
                            color: 'var(--white)', borderTopLeftRadius: mobile ? null : '0 !important',
                            borderBottomLeftRadius: mobile ? null : '0 !important', px: 2, py: 1,
                            backgroundImage: disabled || returnOkey ? null : "linear-gradient(to bottom, #2FB16B 50%, #168549)",
                            display: 'flex', justifyContent: 'start', maxHeight: '12.5vh'
                        }}
                        disabled={false}
                    >
                        <Box columnGap={1}
                            className="flex flex-1 items-center justify-center lg:justify-start"
                        >
                            <SvgIcon sx={{ color: 'white' }}><path d={mdiChat} /></SvgIcon>
                            <span className={clsx(classes.subText, "!text-xs sm:!text-sm md:!text-base hidden lg:flex")}>
                                Sohbeti Göster
                            </span>
                        </Box>

                        {notificationCount > 0 && (
                            <div className="w-5 h-5 md:w-6 md:h-6 top-[-0.1rem] right-[-0.2rem] md:right-[-0.4rem]" style={{
                                borderRadius: '50%', position: 'absolute',
                                display: notificationCount === 0 ? 'none' : 'flex', backgroundColor: '#FF5757',
                                boxShadow: '0 0 0 1px #444',
                            }}>
                                <Typography className={clsx(classes.subText, "!text-xs !leading-3 lh")}
                                    sx={{ margin: 'auto', textAlign: 'center' }} alignSelf={"center"}
                                >
                                    {notificationCount > 9 ? '9+' : notificationCount}
                                </Typography>
                            </div>
                        )}
                    </Button>
                </Tooltip>
            )
        ];

        return (
            <Box className="flex flex-col flex-1">
                {this.renderMobileActionsMenu()}
                {this.renderChatDialog()}
                {this.renderUsersListDialog()}

                <Box width={"100%"} display={"flex"} justifyContent={"center"}
                //className={clsx(isLandscape ? "mb-4" : "")}
                >
                    {this.returnOkeyDialog()}
                    {this.doubleDialog()}

                    <Box flexDirection={"column"} justifyContent={"space-evenly"}
                        className={clsx(
                            spectating ? "hidden" : (
                                isLandscape ? "flex" : "hidden md:flex"
                            )
                        )}
                    >
                        {buttons[0](false)}
                        {buttons[1](false)}
                    </Box>

                    {spectating ? null :
                        <Takoz
                            isLandscape={isLandscape}
                            listRef={this.listRef}
                            onDragStart={this.onDragStart}
                            onDragEnd={this.onDragEnd}
                            onMovePiece={this.onMovePiece}
                            onDoubleClick={this.onDoubleClick}
                            tileRefs={this.tileRefs}
                        />
                    }

                    <Box flexDirection={"column"} justifyContent={"space-evenly"}
                        className={clsx(
                            spectating ? "hidden" : (
                                isLandscape ? "flex" : "hidden md:flex"
                            )
                        )}
                    >
                        {buttons[2](false)}
                        {buttons[3](false)}
                    </Box>

                </Box>

                <Box className={clsx(
                    isLandscape ? "hidden" : "flex md:hidden flex-1"
                )}
                    justifyContent={"space-evenly"} my={1}
                >
                    {buttons[0](true)}
                    {buttons[1](true)}
                    {buttons[2](true)}
                    {buttons[3](true)}
                    {buttons[4](true)}
                </Box>
            </Box>
        );
    }

    renderChatDialog = () => {
        const { usersById, selectedTableUsers } = this.props;
        const { chatAnchor } = this.state;

        const onClose = () => {
            this.props.setMobileChatAt(this.props.selectedTable, Date.now());
            this.setState({ chatAnchor: null });
        }

        return (
            <Dialog open={Boolean(chatAnchor)} onClose={onClose}>
                <DialogTitle sx={{ padding: '6px 16px' }}>
                    <Box padding={0} display={"flex"} justifyContent={"space-between"}>
                        <span />
                        <Tooltip title="Kapat" placement="top">
                            <IconButton className="!w-6 !h-6" onClick={onClose} sx={{ backgroundColor: '#624799 !important' }}>
                                <CloseIcon htmlColor="var(--white)" sx={{ fontSize: 18 }} />
                            </IconButton>
                        </Tooltip>
                    </Box>
                </DialogTitle>

                <DialogContent sx={{ padding: '0 !important' }}>
                    <Box display={"flex"} flexDirection={"column"} height={"50vh"} position={"relative"}>
                        <Chatbox history={this.props.history} height={100}
                            users={selectedTableUsers?.filter(u => u).reduce((a, v) => ({ ...a, [v.id]: usersById[v.id] }), {}) ?? {}}
                            isPortrait={this.props.isPortrait} isLandscape={this.props.isLandscape}
                            maxHeight={"50vh"} type={CHATBOX_TYPES.TABLE_MOBILE} hideHeader={true}
                            onUserSelected={this.onUserSelected} tableId={this.props.selectedTable}
                        />
                    </Box>
                </DialogContent>
            </Dialog>
        );
    }

    renderUsersListDialog = () => {
        const { usersListAnchor } = this.state;

        const onClose = () => {
            this.setState({ usersListAnchor: null });
        }


        return (
            <Dialog open={Boolean(usersListAnchor)} onClose={onClose}>
                <DialogTitle sx={{ padding: '6px 16px' }}>
                    <Box padding={0} display={"flex"} justifyContent={"space-between"}>
                        <span />
                        <Tooltip title="Kapat" placement="top">
                            <IconButton className="!w-6 !h-6" onClick={onClose} sx={{ backgroundColor: '#624799 !important' }}>
                                <CloseIcon htmlColor="var(--white)" sx={{ fontSize: 18 }} />
                            </IconButton>
                        </Tooltip>
                    </Box>
                </DialogTitle>

                <DialogContent sx={{ padding: '0 !important' }}>
                    <Box display="flex" flexDirection="column" padding="0" borderLeft={"1px solid #D8D6E8"}
                        borderTop={"1px solid #a097b3"} overflow={"auto"}
                        zIndex={2}
                    >
                        {this.usersList()}
                    </Box>
                </DialogContent>
            </Dialog>
        );
    }

    findUserIndex = () => {
        const { selectedTableUsers } = this.props;
        const user = this.props.user;

        for (let i = 0; i < selectedTableUsers?.length; i++) {
            if (selectedTableUsers[i]?.id === user?.id) {
                return i
            }
        }

        return -1;
    }

    getPieces = () => {
        const pieces = this.props.store.getState().app.table.tables[this.props.myTable].pieces;

        if (!pieces || pieces.length === 0) {
            return [];
        }

        const arr = Array.from(pieces);
        while (arr.length < 26) {
            arr.push(null);
        }

        return arr;
    }

    onUserRightClicked = (e, user) => {
        e.preventDefault();

        const touch = e.touches?.[0];

        if (touch) {
            this.setState({ userAnchorPos: { left: touch.clientX, top: touch.clientY }, contextUser: user });
        }
        else {
            this.setState({ userAnchor: e.currentTarget, contextUser: user });
        }
    }

    onPrivateMessage = (userId, username) => {
        this.props.addChatTab(userId, username, true);
        this.props.history.push(getPath("/sohbet"), { userId, username });
    }

    onUserSelected = (e, userId) => {
        this.usersRef?.onSelected?.(e, userId);
    }

    usersList = () => {
        const { usersById, selectedTableUsers, selectedTableSpectators, selectedTable } = this.props;

        const users = Array.from(selectedTableUsers?.map(u => u?.bot || !(u?.id in usersById) ? u : usersById[u?.id]) ?? []);
        const spectators = Array.from(selectedTableSpectators?.map(s => usersById[s]) ?? []);
        users.push(...spectators);

        return (
            <UserList
                onRef={r => this.usersRef = r}
                history={this.props.history}
                search={this.state.searchUser}
                users={users}
                onTabUser={this.chatboxRef?.onTabUserWithUsername}
                isOnline={(u) => u?.id in this.props.usersById || u?.bot}
                isSpectator={(u) => u?.tableId !== selectedTable}
            />
        );

        return (
            <Box bgcolor={"#f6f6ff"}>
                <UserActions
                    anchor={this.state.userAnchor}
                    anchorPos={this.state.userAnchorPos}
                    user={this.state.contextUser}
                    onClose={() => this.setState({ userAnchor: null, userAnchorPos: null, contextUser: null })}
                    history={this.props.history} bot={this.state.contextUser?.bot}
                    onTabUser={this.chatboxRef?.onTabUserWithUsername}
                />

                {users?.map(u => {
                    if (!u) return;

                    return (
                        <Box key={u.id} display="flex" columnGap="12px" px={2} py={0.5}
                            borderBottom="1px solid #E3E3E3"
                            //onContextMenu={(e) => this.onUserRightClicked(e, u)}
                            onContextMenu={(e) => this.userContextMenuHandler.onContextMenu(e, u)}
                            onTouchStart={(e) => this.userContextMenuHandler.onTouchStart(e, u)}
                            onTouchCancel={this.userContextMenuHandler.onTouchCancel}
                            onTouchEnd={this.userContextMenuHandler.onTouchEnd}
                            onTouchMove={this.userContextMenuHandler.onTouchMove}
                            onDoubleClick={u.bot ? null : () => this.onPrivateMessage(u.id, u.username)}
                            className="cursor-pointer bg-[#f6f6ff] hover:bg-emerald-400"
                        >
                            <Box display={"flex"} position={"relative"} columnGap={2}>
                                {/** Avatar */}
                                <UserAvatar user={u} showStatus />

                            </Box>
                            <Box display={"flex"} flexDirection={"column"} justifyContent={"space-between"}>
                                <Box className="flex gap-x-1 items-center">
                                    {u.icon ?
                                        <img src={loadedResources[`${process.env.REACT_APP_CDN_URL}/web/img/${u.icon}`]} className="w-6 h-6" /> :
                                        (u.premium ?
                                            <img src={loadedResources[`${process.env.REACT_APP_CDN_URL}/web/img/vip.png`]} className="w-4 h-4 sm:w-5 sm:h-5 md:w-6 md:h-6" /> :
                                            null
                                        )
                                    }

                                    <Box className={clsx(
                                        "text-[0.8em] sm:text-[0.9em] md:text-[1em] !font-bold",
                                        u?.username_color ? `NickRenk${u.username_color}` : 'text-black'
                                    )}
                                    >
                                        {u.username}
                                    </Box>
                                </Box>
                                <Box className="flex items-center gap-x-2">
                                    <Box className="flex flex-col justify-center">
                                        {(() => {
                                            const MobileIcon = deviceIcons[u.mobile ?? null];
                                            return (
                                                <MobileIcon className="!text-[1.2em] sm:!text-[1.4em] md:!text-[1.6em]" />
                                            );
                                        })()}
                                    </Box>
                                    <Box className={"flex items-center text-[0.6em] sm:text-[0.7em] md:text-[0.8em] text-black"}>
                                        {humanize(u?.points ?? 0, { delimiter: '.', separator: ',' })}
                                    </Box>
                                    <Box className="flex flex-col justify-center">
                                        <TableRestaurant className="!text-[1.2em] sm:!text-[1.4em] md:!text-[1.6em]" />
                                    </Box>
                                    <Box className={"flex items-center text-[0.6em] sm:text-[0.7em] md:text-[0.8em] text-black"}>
                                        <span>{u?.tableId ?? '-'}</span>
                                    </Box>
                                    {u?.tableId !== selectedTable && (
                                        <Box className="flex gap-x-1 items-center">
                                            <Tooltip title="İzleyici" placement="top">
                                                <Face className="mt-[2px] !text-[1.2em] sm:!text-[1.4em] md:!text-[1.6em]" />
                                            </Tooltip>
                                        </Box>
                                    )}

                                </Box>
                            </Box>
                        </Box>
                    );
                })}
            </Box>
        );
    }

    buildButton = (text, icon, variant, disabled, { style, onClicked, props, className, notificationCount = 0 }) => {
        const { classes } = this.props;

        return (
            <Tooltip title={text}>
                <Button variant={variant}
                    disabled={disabled}
                    onClick={onClicked}
                    className={clsx(
                        "justify-center lg:!justify-start px-0 !py-1 lg:!py-2 w-10 md:w-12 lg:w-full !min-h-0",
                        className,
                    )}
                    sx={{
                        border: '1px solid #FFFFFF40', minWidth: 0, ...style,
                    }}
                    {...props}
                >
                    <Box display={"flex"} columnGap={1}>
                        <SvgIcon className="text-white !text-[1em] md:!text-[1.2em] lg:!text-[1.4em]">
                            <path d={icon} />
                        </SvgIcon>
                        <Typography className={clsx("hidden lg:block")} sx={{ color: 'white', whiteSpace: "nowrap" }}>
                            {text}
                        </Typography>
                    </Box>

                    {notificationCount > 0 && (
                        <div className="w-3 h-3 md:w-4 md:h-4 top-[-0.1rem] right-[-0.2rem] md:right-[-0.4rem]" style={{
                            borderRadius: '50%', position: 'absolute',
                            display: notificationCount === 0 ? 'none' : 'block', backgroundColor: '#FF5757',
                            boxShadow: '0 0 0 1px #444',
                        }}>
                            <Typography className={clsx(classes.subText, "!text-[0.5rem] !leading-3 lh md:!text-xs")}
                                sx={{ margin: 'auto', textAlign: 'center' }}
                            >
                                {notificationCount > 9 ? '9+' : notificationCount}
                            </Typography>
                        </div>
                    )}
                </Button>
            </Tooltip>
        );
    }

    potInspectDialog = () => {
        const { classes } = this.props;
        const { potInspect } = this.state;

        const onClose = () => {
            this.setState({ potInspect: null });
        }

        return (
            <Dialog open={Boolean(potInspect)} onClose={onClose} maxWidth="xs" fullWidth>
                <DialogTitle className={classes.dialogTitle}>
                    <Typography className={clsx(classes.text, "!text-xs sm:!text-sm md:!text-base")}>
                        Taşlara Göz At
                    </Typography>
                </DialogTitle>
                <DialogContent sx={{ p: 0 }}>
                    <Box className="flex p-1 flex-wrap">
                        {potInspect?.map((p, i) => {
                            return (
                                <Tile value={p} index={i} position={"relative"} />
                            );
                        })}
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onClose} variant="contained"
                        sx={{ color: 'var(--white)' }} color='error'
                    >
                        Kapat
                    </Button>
                </DialogActions>
            </Dialog >
        );
    }

    invitationDialog = () => {
        const { classes, selectedTableUsers } = this.props;
        const { invitationDialog } = this.state;

        const onClose = () => {
            this.setState({ invitationDialog: false, inviteUser: null });
        }

        const onInvite = (e) => {
            e.preventDefault();
            const { inviteUser } = this.state;

            if (!inviteUser) {
                return;
            }

            this.props.socket.emit('invite_user', { user_id: inviteUser.id });
            onClose();
        }

        return (
            <Dialog open={invitationDialog} onClose={onClose}>
                <DialogTitle className={classes.dialogTitle}>
                    <Typography className={clsx(classes.text, "!text-xs sm:!text-sm md:!text-base")}>
                        Oyuncu Davet Et
                    </Typography>
                </DialogTitle>
                <DialogContent sx={{ p: 0 }}>
                    <UserList
                        onSelected={(user) => this.setState({ inviteUser: user })}
                        history={this.props.history}
                        users={Object.values(this.props.usersById).filter(u => !u.tableId)}
                        isOnline={(u) => u?.id in this.props.usersById}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={onClose} variant="contained"
                        sx={{ color: 'var(--white)' }} color='error'
                        className="!text-xs sm:!text-sm md:!text-base"
                    >
                        Kapat
                    </Button>
                    <Button onClick={onInvite} variant="contained" disabled={!this.state.inviteUser}
                        sx={{ color: 'var(--white) !important' }} color='success'
                        className="!text-xs sm:!text-sm md:!text-base"
                    >
                        Davet Et
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }

    reportDialog = () => {
        const { classes, selectedTable } = this.props;
        const { reportDialog } = this.state;

        const onClose = (reported = false) => {
            this.setState({ reportDialog: false, reported });
        }

        const onReport = async (e) => {
            e.preventDefault();

            try {
                await AxiosClient().post(`${process.env.REACT_APP_API_URL}/v1/user/report.table`, {
                    table_id: selectedTable,
                });

                this.props.setNotification({ message: 'Masa şikayet edildi.', severity: GlobalTypes.NOTIFY_SUCC });
                onClose(true);
            }
            catch (e) {
                console.error(e);
                this.props.setNotification({ message: 'Masa şikayet edilemedi.', severity: GlobalTypes.NOTIFY_ERR });
                onClose(false);
            }
        }

        return (
            <Dialog open={reportDialog} onClose={onClose} >
                <DialogTitle className={classes.dialogTitle}>
                    <Typography className={clsx(classes.text, "!text-xs sm:!text-sm md:!text-base")}>
                        Masayı Şikayet Et
                    </Typography>
                </DialogTitle>
                <DialogContent>
                    <Typography className={clsx(classes.text, "!text-xs sm:!text-sm md:!text-base")}>
                        Masayı şikayet etmek istediğinize emin misiniz?
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onClose} variant="contained"
                        sx={{ color: 'var(--white)' }} color='error'
                        className="!text-xs sm:!text-sm md:!text-base"
                    >
                        Kapat
                    </Button>
                    <Button onClick={onReport} variant="contained"
                        sx={{ color: 'var(--white) !important' }} color='success'
                        className="!text-xs sm:!text-sm md:!text-base"
                    >
                        Şikayet Et
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }

    render() {
        const {
            classes, isLandscape, usersById, selectedTable,
            selectedTableGame, selectedTableRound: round,
            selectedTableOptions, selectedTableUsers,
        } = this.props;

        return (
            <div className={clsx(classes.root, "relative max-w-full")} style={{
                touchAction: 'none', overscrollBehavior: 'none !important', pointerEvents: 'none !important', userSelect: "none !important"
            }}>
                <EndRound
                    onEndGame={() => this.setState({ scoreboard: true })}
                />

                <GameOptions open={this.state.gameOptionsDialog} options={selectedTableOptions}
                    disabled={Boolean(selectedTableGame?.started)}
                    onClose={() => this.setState({ gameOptionsDialog: false })}
                    onEdit={(opts) => this.editTableOptions(opts)}
                    created={true}
                />

                {this.potInspectDialog()}
                {this.invitationDialog()}
                {this.reportDialog()}

                <Box key={"content"} display={"flex"} bgcolor={"#322149"}
                    alignContent={"space-between"} flex={1} pb={0} className={"p-0 lg:p-2"}
                    sx={{
                        overscrollBehavior: 'none !important',
                        backgroundImage: "linear-gradient(to bottom, #006838 50%, #063)",
                    }}
                >
                    <Box key={"table-id"} className={clsx("hidden lg:block")}>
                        <Typography className={clsx(classes.text, "!text-xs md:!text-sm")} sx={{ color: 'white' }}>MASA</Typography>
                        <Typography className={clsx(classes.subText, "!text-md sm:!text-base md:!text-lg")} sx={{ color: 'white' }}>
                            {selectedTable}
                        </Typography>
                    </Box>

                    <Box key={"game-round"} className={clsx("hidden lg:block ml-2")}>
                        <Typography className={clsx(classes.text, "!text-xs md:!text-sm")} sx={{ color: 'white' }}>OYUN</Typography>
                        <Typography className={clsx(classes.subText, "!text-md sm:!text-base md:!text-lg")} sx={{ color: 'white' }}>
                            {round ?? 0}
                        </Typography>
                    </Box>

                    <Box className="flex flex-col" flex={4}>
                        {this.mobileHeader()}
                        {this.autoPilot()}

                        <Box key={"game"} display={"flex"} flex={1} flexDirection={"column"}
                            justifyContent={"space-between"} position={"relative"}
                        >
                            <Box className="flex flex-col justify-around relative flex-1">
                                {/*this.firstRow()*/}
                                <FirstRow
                                    isLandscape={isLandscape}
                                    addBot={this.addBot}
                                    onInspect={(pot) => this.setState({ potInspect: pot })}
                                    userContextMenuHandler={this.userContextMenuHandler}
                                    onPrivateMessage={this.onPrivateMessage}
                                    store={this.props.store}
                                />

                                {/*this.secondRow()*/}
                                <SecondRow
                                    isLandscape={isLandscape}
                                    addBot={this.addBot}
                                    onInspect={(pot) => this.setState({ potInspect: pot })}
                                    groundRef={this.groundRef}
                                    onDragStart={this.onDragStart}
                                    onDragEnd={this.onDragEnd}
                                    onMovePiece={this.onMovePiece}
                                    onDoubleClick={this.onDoubleClick}
                                    userContextMenuHandler={this.userContextMenuHandler}
                                    onPrivateMessage={this.onPrivateMessage}
                                    store={this.props.store}
                                />

                                {/*this.thirdRow()*/}
                                <ThirdRow
                                    isLandscape={isLandscape}
                                    addBot={this.addBot}
                                    onInspect={(pot) => this.setState({ potInspect: pot })}
                                    potRef={this.potRef}
                                    onDragStart={this.onDragStart}
                                    onDragEnd={this.onDragEnd}
                                    onMovePiece={this.onMovePiece}
                                    onDoubleClick={this.onDoubleClick}
                                    userContextMenuHandler={this.userContextMenuHandler}
                                    onPrivateMessage={this.onPrivateMessage}
                                    store={this.props.store}
                                />
                            </Box>

                            <Box className={clsx(
                                "flex flex-col relative",
                                isLandscape ? "pb-6" : "pb-0",
                            )}>
                                {this.fourthRow()}
                            </Box>
                        </Box>
                    </Box>

                    <Box key={"right-container"} alignItems={"center"}
                        maxWidth={240}
                        maxHeight={isMobile ? null : 360}
                        className={clsx(
                            isLandscape ? "flex" : "hidden md:flex",
                            "lg:flex-1 flex-col justify-start gap-y-1 p-2"
                        )}
                    >
                        {this.actionButtons()}
                    </Box>

                    <Scoreboard open={this.state.scoreboard} onClose={() => this.closeScoreboard()} />
                    <Indicator />
                </Box >

                <Box overflow={"visible"} height={240}
                    className={clsx(
                        "hidden lg:flex",
                    )}
                >
                    <Box flex={1} className="flex">
                        <Chatbox onRef={(ref) => this.chatboxRef = ref}
                            history={this.props.history}
                            users={selectedTableUsers?.filter(u => u).reduce((a, v) => ({ ...a, [v.id]: usersById[v.id] }), {}) ?? {}}
                            isPortrait={this.props.isPortrait} isLandscape={this.props.isLandscape}
                            maxHeight={240} type={CHATBOX_TYPES.TABLE} hideHeader={true}
                            onUserSelected={this.onUserSelected} tableId={this.props.selectedTable}
                        />
                    </Box>

                    <Box display="flex" flexDirection="column" padding="0" borderLeft={"1px solid #D8D6E8"}
                        borderTop={"1px solid #a097b3"} overflow={"auto"} className={"w-72 xl:w-96"}
                        zIndex={2} bgcolor={"#f6f6ff"}
                    >
                        {this.usersList()}
                    </Box>
                </Box>
            </div>
        );
    }
}

Table.propTypes = {
    classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
    const myTable = state.app.global.myTable;
    const selectedTable = state.app.global.selectedTable;
    const selectedTab = state.app.global.selectedTab;

    return {
        tableIds: state.app.table.tableIds,
        users: state.app.lobby.users,
        socket: state.app.global.socket,
        user: state.app.global.user,
        generalSettings: state.app.global.generalSettings,
        usersById: state.app.lobby.usersById,
        role: state.app.global.role,
        myTable,
        selectedTable,

        tableMessages: state.app.lobby.messages[selectedTab] ?? [],
        myTableIndicator: state.app.table.tables[myTable]?.indicator,
        myTableTable: state.app.lobby.tables[myTable],

        selectedTableEndRound: state.app.table.tables[selectedTable]?.endRound,
        selectedTableDouble: state.app.table.tables[selectedTable]?.double,
        selectedTableReturnOkey: state.app.table.tables[selectedTable]?.returnOkey,
        selectedTableRound: state.app.table.tables[selectedTable]?.round,
        selectedTableMobileChatAt: state.app.table.tables[selectedTable]?.mobileChatAt,
        selectedTableGame: state.app.lobby.tables[selectedTable]?.game,
        selectedTableUsers: state.app.lobby.tables[selectedTable]?.users,
        selectedTableSpectators: state.app.lobby.tables[selectedTable]?.spectators,
        selectedTableOptions: state.app.lobby.tables[selectedTable]?.options,
    }
};

export default withStyles(styles)(connect(mapStateToProps, {
    setRejoinTableId, setGameInfo, setPieces, setPots, setEndRound,
    setNotification, setLeavingTable, addChatTab, setMobileChatAt,
})(Table));
