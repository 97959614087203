import React from "react";
import { connect } from "react-redux";
import { withStyles } from "@mui/styles";
import PropTypes from "prop-types";
import { Avatar, Box, Dialog, DialogContent, DialogTitle, List, Typography } from "@mui/material";
import { setEndRound, setPieces, setPots, setGameInfo } from "../../actions/table";
import { addChatMessage } from "../../actions/lobby";
import ConfettiExplosion from 'react-confetti-explosion';
import Tile from "../../models/tile";
import clsx from "clsx";
import UserAvatar from "../Lobby/UserAvatar";
import { getPath } from "../../global/route";
import { endMessage } from "../../utils/message";
import { loadedResources } from "../../global/resources";


const styles = theme => ({
    text: {
        fontWeight: '500 !important',
    },
});

class EndRound extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        }

    }

    componentDidMount() {
    }

    componentDidUpdate(prevProps) {
        const { selectedTable, onEndGame } = this.props;

        if (selectedTable && prevProps.tables[selectedTable]?.endRound !== this.props.tables[selectedTable]?.endRound && this.props.tables[selectedTable]?.endRound) {
            if (this.props.tables[selectedTable]?.endRound?.end_game) {
                onEndGame?.();
            }
        }
    }

    getPieces = () => {
        const { selectedTable } = this.props;
        const endRound = this.props.tables[selectedTable]?.endRound;

        if ((endRound?.pieces?.length ?? 0) === 0) {
            return [];
        }

        const arr = Array.from(endRound.pieces);
        while (arr.length < 26) {
            arr.push(null);
        }

        return arr;
    }

    winner = () => {
        const { classes, selectedTable } = this.props;
        const endRound = this.props.tables[selectedTable]?.endRound;
        const usersById = this.props.usersById;

        const user = usersById[endRound?.user];
        if (!user)
            return null;

        const lobbyUser = user?.bot ? user : this.props.usersById[user.id];

        return (
            <Box display={"flex"} alignItems={"center"}>
                <Box display={"flex"} justifyContent={"center"} margin={"0 auto"} columnGap={2}
                    bgcolor={"#5682b6"} px={2} py={1} borderRadius={12}
                >
                    <>
                        <UserAvatar user={lobbyUser} />
                        <Typography sx={{ margin: 'auto', color: 'white' }}
                            className={clsx(classes.text, "!text-xs sm:!text-sm md:!text-base")}>
                            {lobbyUser?.username}
                        </Typography>
                    </>
                </Box>
            </Box>
        );
    }

    confetti = () => {
        const { selectedTable } = this.props;
        const endRound = this.props.tables[selectedTable]?.endRound;
        const usersById = this.props.usersById;

        const user = usersById[endRound?.user];
        if (!user)
            return null;

        return (
            <Box position={"absolute"} width={0} height={0} left={"50%"} top={"30vh"}>
                <ConfettiExplosion force={1} duration={2500} particleCount={250} width={1920} />
            </Box>
        );
    }

    render() {
        const { classes, selectedTable } = this.props;
        const endRound = this.props.tables[selectedTable]?.endRound;
        const usersById = this.props.usersById;

        return (
            <Box position={"relative"}>
                {this.confetti()}

                <Dialog
                    maxWidth={false}
                    open={Boolean(endRound)}
                    sx={{
                        '& .MuiPaper-root': { p: 0, m: 0, width: 'fit-content', maxWidth: '100%' },
                        '& .MuiDialog-container': { width: '100%' },
                    }}
                >
                    <DialogTitle component={"div"} sx={{ backgroundColor: '#6BA2E3' }}>
                        {this.winner()}
                    </DialogTitle>

                    <DialogContent className="!px-0 !py-2 min-[512px]:!px-2 min-[680px]:!px-4 lg:!px-6 xl:!px-8">

                        <Box display={"flex"} flexDirection={"column"} alignItems={"center"} rowGap={2} py={2}>
                            <Typography className={clsx(classes.text, "!text-xs sm:!text-sm md:!text-base")} sx={{ px: 2, textAlign: 'center' }}>
                                {endMessage(endRound, usersById)}
                            </Typography>

                            {usersById[endRound?.user] ?
                                <Box position={"relative"} justifyContent={"center"}
                                    maxHeight={"25vh"}
                                    className={clsx(
                                        "flex justify-center",
                                        "w-full hidden min-[312px]:flex",
                                        "min-[312px]:max-w-full",
                                        "md:max-w-[60vw]",
                                        "lg:max-w-[482px] lg:h-[104px]",
                                        "xl:max-w-[637px] xl:h-[136px]",
                                    )}
                                >
                                    <img draggable="false" src={loadedResources[`${process.env.REACT_APP_CDN_URL}/web/img/takoz.png`]}
                                        style={{ width: '100%', height: 'auto' }}
                                    />

                                    <div className={clsx(
                                        "flex w-full absolute top-0 bottom-0 right-0",
                                        "min-[312px]:max-w-[92%] min-[312px]:left-[4%]",
                                        "md:max-w-[92%] md:left-[4%]",
                                        "lg:max-w-[442px] lg:left-[20px]",
                                        "xl:max-w-[585px] xl:left-[26px]",
                                    )}>
                                        <List component="div" ref={this.listRef}
                                            sx={{
                                                padding: 0, margin: '0 auto', flex: 1,
                                            }}
                                        >
                                            {this.getPieces().map((val, i) => {
                                                return (
                                                    <Tile id={`Piece ${i + 1}`} key={`Piece${i + 1}`}
                                                        value={val} index={i} />
                                                );
                                            })}
                                        </List>
                                    </div>
                                </Box> :
                                null
                            }

                        </Box>

                    </DialogContent>

                    {/*
                <DialogActions>
                    <Button variant='contained' sx={{ color: 'var(--white)' }}
                        color='error' onClick={() => this.close()}>Kapat</Button>
                </DialogActions>
                */}

                </Dialog>
            </Box >
        );
    }
}

EndRound.propTypes = {
    classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
    return {
        tables: state.app.table.tables,
        user: state.app.global.user,
        selectedTable: state.app.global.selectedTable,
        usersById: state.app.lobby.usersById,
    }
};

export default withStyles(styles)(connect(mapStateToProps, {
    setEndRound, setPieces, setPots, setGameInfo, addChatMessage
})(EndRound));
