import { GlobalTypes } from '../action_types';

export function setSocket(socket) {
    return {
        type: GlobalTypes.SET_SOCKET,
        socket
    };
}

export function setSocketUser(user) {
    return {
        type: GlobalTypes.SET_SOCKET_USER,
        user
    };
}

export function setDisconnected(disconnected, allowReconnect = true) {
    return {
        type: GlobalTypes.SET_DISCONNECTED,
        disconnected,
        allowReconnect,
    };
}

export function setError(error) {
    return {
        type: GlobalTypes.SET_ERROR,
        error
    };
}

/**
 * @param {object} 
 */
export function setNotification({ message, severity, traceId }) {
    return {
        type: GlobalTypes.SET_NOTIFICATION,
        notification: { message, severity, traceId }
    };
}

export function clearNotification() {
    return {
        type: GlobalTypes.SET_NOTIFICATION,
        notification: null
    };
}

/**
 * @param {number} ping 
 */
export function setPing(ping) {
    return {
        type: GlobalTypes.SET_PING,
        ping,
    };
}

/**
 * 
 * @param {string} id 
 */
export function selectTab(id) {
    return {
        type: GlobalTypes.SELECT_TAB,
        id,
    };
}

export function setMyTable(myTable) {
    return {
        type: GlobalTypes.SET_MY_TABLE,
        myTable,
    };
}

export function addSpectatingTable(tableId) {
    return {
        type: GlobalTypes.ADD_SPECTATING_TABLE,
        tableId,
    };
}

export function removeSpectatingTable(tableId) {
    return {
        type: GlobalTypes.REMOVE_SPECTATING_TABLE,
        tableId,
    };
}

export function setInfoDialog(title, message) {
    return {
        type: GlobalTypes.SET_INFO_DIALOG,
        title,
        message,
    };
}

export function closeInfoDialog() {
    return {
        type: GlobalTypes.CLOSE_INFO_DIALOG,
    };
}

export function setLeavingTable(leavingTable) {
    return {
        type: GlobalTypes.SET_LEAVING_TABLE,
        leavingTable,
    };
}

export function setUserStatus(status) {
    return {
        type: GlobalTypes.SET_USER_STATUS,
        status,
    };
}

export function setUserAutoPilot(autoPilot) {
    return {
        type: GlobalTypes.SET_USER_AUTOPILOT,
        autoPilot,
    };
}

export function addChatTab(id, name, switchTo) {
    return {
        type: GlobalTypes.ADD_CHAT_TAB,
        id,
        name,
        switchTo,
    };
}

export function removeChatTab(id) {
    return {
        type: GlobalTypes.REMOVE_CHAT_TAB,
        id,
    };
}

export function addNotifications(notifications) {
    return {
        type: GlobalTypes.ADD_NOTIFICATIONS,
        notifications,
    };
}

export function updateNotification(notification) {
    return {
        type: GlobalTypes.UPDATE_NOTIFICATION,
        notification,
    };
}

export function readNotifications() {
    return {
        type: GlobalTypes.READ_NOTIFICATIONS,
    };
}

export function setNotifications(notifications) {
    return {
        type: GlobalTypes.SET_NOTIFICATIONS,
        notifications,
    };
}

export function addFriend(friend) {
    return {
        type: GlobalTypes.ADD_FRIEND,
        friend,
    };
}

export function updateFriend(friend) {
    return {
        type: GlobalTypes.UPDATE_FRIEND,
        friend,
    };
}

export function setFriends(friends) {
    return {
        type: GlobalTypes.SET_FRIENDS,
        friends,
    };
}

export function removeFriend(id) {
    return {
        type: GlobalTypes.REMOVE_FRIEND,
        id,
    };
}

export function addBlockedUser(user) {
    return {
        type: GlobalTypes.ADD_BLOCKED_USER,
        user,
    };
}

export function removeBlockedUser(id) {
    return {
        type: GlobalTypes.REMOVE_BLOCKED_USER,
        id,
    };
}

export function setBlockedUsers(users) {
    return {
        type: GlobalTypes.SET_BLOCKED_USERS,
        users,
    };
}

export function setRoomConfig(config) {
    return {
        type: GlobalTypes.SET_ROOM_CONFIG,
        config,
    };
}

export function setRoomMaintenance(maintenance) {
    return {
        type: GlobalTypes.SET_ROOM_MAINTENANCE,
        maintenance,
    };
}

export function setAdminPath(path) {
    return {
        type: GlobalTypes.SET_ADMIN_PATH,
        path,
    };
}

export function setAdminTab(tab) {
    return {
        type: GlobalTypes.SET_ADMIN_TAB,
        tab,
    };
}

export function setInvitationDialog(invitationDialog) {
    return {
        type: GlobalTypes.SET_INVITATION_DIALOG,
        invitationDialog,
    };
}

export function setRolePermission(role, permission) {
    return {
        type: GlobalTypes.SET_ROLE_PERMISSON,
        role,
        permission,
    };
}

export function setGeneralSettings(generalSettings) {

    return {
        type: GlobalTypes.SET_GENERAL_SETTINGS,
        generalSettings,
    };
}

export function setReportDialog(reportDialog) {

    return {
        type: GlobalTypes.SET_REPORT_DIALOG,
        reportDialog,
    };
}

export function setLoadingState(loadingState) {
    return {
        type: GlobalTypes.SET_LOADING_STATE,
        loadingState,
    };
}

export function setStreamInfo(streamInfo) {
    return {
        type: GlobalTypes.SET_STREAM_INFO,
        streamInfo,
    };
}