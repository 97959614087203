const samePieces = (p1, p2) => {
    if (p1 > 104 || p2 > 104)
        return p1 > 104 && p2 > 104;

    const c1 = ~~((p1 - 1) / 26);
    const c2 = ~~((p2 - 1) / 26);

    if (c1 !== c2)
        return false;

    const modp1 = ((p1 - 1) % 13) + 1;
    const modp2 = ((p2 - 1) % 13) + 1;
    return modp1 === modp2;
}

const findEquivalentPiece = (piece) => {
    if (piece === 105)
        return 106;

    if (piece === 106)
        return 105;

    const secondary = ~~((piece - 1) / 13) % 2 === 1;
    return secondary ? piece - 13 : piece + 13;
}

module.exports = {
    samePieces,
    findEquivalentPiece,
}