import React from "react";
import { connect } from "react-redux";
import { withStyles } from "@mui/styles";
import PropTypes from "prop-types";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, SvgIcon, Typography } from "@mui/material";
import { mdiPowerPlugOff } from "@mdi/js";
import { setError } from "../../actions/global";
import { Error as ErrorIcon } from "@mui/icons-material";


const styles = theme => ({
});

class ErrorDialog extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            closing: false,
        }

    }

    componentDidMount() {
    }

    componentDidUpdate(prevProps) {
    }

    onClose = () => {
        this.setState({ closing: true });
        setTimeout(() => {
            this.props.setError();
            this.setState({ closing: false });
        }, 1000);
    }

    render() {
        const { classes, error } = this.props;

        return (
            <Dialog open={Boolean(error) && !this.state.closing}>
                <DialogTitle>
                    <Box display={"flex"} columnGap={1}>
                        <ErrorIcon color="error" />
                        <Typography className="!font['Roboto Slab'] !text-[0.8em] md:!text-[0.9em]">
                            Hata
                        </Typography>
                    </Box>
                </DialogTitle>

                <DialogContent>
                    <Typography className="!font['Roboto Slab'] !py-4 !text-[0.8em] md:!text-[0.9em] lg:!text-[1em]">
                        {error?.message}
                    </Typography>
                </DialogContent>

                <DialogActions>
                    <Button variant='contained' sx={{ color: 'var(--white)' }} className="!font['Roboto Slab'] !text-[0.8em] md:!text-[0.9em] lg:!text-[1em]"
                        color='error' onClick={() => this.onClose()}>Kapat</Button>
                </DialogActions>
            </Dialog>
        );
    }
}

ErrorDialog.propTypes = {
    classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
    return {
        error: state.app.global.error,
    }
};

export default withStyles(styles)(connect(mapStateToProps, {
    setError,
})(ErrorDialog));
