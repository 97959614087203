import React from "react";
import { connect } from "react-redux";
import { withStyles } from "@mui/styles";
import PropTypes from "prop-types";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, InputAdornment, MenuItem, SvgIcon, Table, TableBody, TableCell, TableRow, TextField, Tooltip, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { Close } from "@mui/icons-material";
import clsx from "clsx";
import { mdiEarth, mdiEmail, mdiGenderMaleFemale } from "@mdi/js";
import { countries } from "../../global/countries";
import axios from "axios";
import { setNotification } from "../../actions/global";
import { GlobalTypes } from "../../action_types";
import { AxiosClient } from "../../global/axios";


const styles = theme => ({
    inputIcon: {
        marginRight: '8px',
        color: '#31204A !important'
    },
});

class UpdateProfile extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            profile: null,
            description: '',
            loading: false,
        }

    }

    componentDidMount() {
    }

    componentDidUpdate(prevProps) {
        if (!prevProps.open && this.props.open) {
            this.setState({ loading: true });
            this.getUserProfile();
        }

    }

    getUserProfile = async () => {
        try {
            const resp = await AxiosClient().get(`${process.env.REACT_APP_API_URL}/v1/user/profile`);

            const { description } = resp.data.user;
            this.setState({ description, loading: false, profile: resp.data.user });
        }
        catch (err) {
            console.error(err);
            this.props.setNotification({ message: 'Bir hata oluştu. Lütfen tekrar deneyiniz.', severity: GlobalTypes.NOTIFY_ERR });
        }
    }

    onClose = () => {
        this.setState({ description: '', loading: false, profile: null });
        this.props.onClose();
    }

    doSave = () => {
        this.setState({ loading: true });

        AxiosClient().post(`${process.env.REACT_APP_API_URL}/v1/user/description.change`, {
            description: this.state.description,
        }).then((resp) => {
            this.props.setNotification({ message: 'Hakkında başarıyla güncellendi.', severity: GlobalTypes.NOTIFY_SUCC });
            this.onClose();
        }).catch((err) => {
            console.error(err);
            this.props.setNotification({ message: 'Bir hata oluştu. Lütfen tekrar deneyiniz.', severity: GlobalTypes.NOTIFY_ERR });
        }).finally(() => {
            this.setState({ loading: false });
        });
    }

    render() {
        const { classes } = this.props;
        const { profile, description, loading } = this.state;

        let disabled = loading;
        disabled |= profile?.description === description;

        return (
            <Dialog
                maxWidth={"xs"}
                fullWidth
                open={this.props.open}
            >
                <DialogTitle>
                    <Box padding={0} display={"flex"} justifyContent={"space-between"}>
                        <Typography className={"!text-xs sm:!text-sm md:!text-base"}>
                            Hakkımda
                        </Typography>

                        <Tooltip title="Kapat" placement="top">
                            <IconButton size="small" onClick={this.onClose} sx={{ backgroundColor: '#624799 !important' }} tabIndex={-1}>
                                <Close htmlColor="var(--white)" fontSize="small" />
                            </IconButton>
                        </Tooltip>
                    </Box>
                </DialogTitle>

                <DialogContent sx={{ p: 0 }}>
                    <form>
                        <Table>
                            <TableBody>
                                <TableRow>
                                    <TableCell>
                                        <TextField margin="dense" fullWidth tabIndex={0} autoFocus
                                            value={this.state.description} disabled={this.state.loading}
                                            multiline rows={4}
                                            sx={{
                                                borderTopRightRadius: '0 !important',
                                                borderBottomRightRadius: '0 !important',
                                                margin: 0,
                                            }}
                                            InputProps={{
                                                classes: {
                                                    input: "h-24 sm:h-32 !text-xs sm:!text-sm md:!text-base",
                                                    root: "h-24 sm:h-32",
                                                },
                                            }}
                                            onChange={(e) => this.setState({ description: e.target.value })}
                                        />
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </form>
                </DialogContent>

                <DialogActions sx={{ p: 2 }}>
                    <Button variant="contained" disabled={false} onClick={() => this.onClose()}
                        disableFocusRipple disableRipple disableTouchRipple color="error"
                        sx={{
                            color: '#FFFFFF',
                        }}
                        className="!text-xs sm:!text-sm md:!text-base"
                    >
                        İptal
                    </Button>

                    <Button variant="contained" disabled={!!disabled} onClick={() => this.doSave()}
                        disableFocusRipple disableRipple disableTouchRipple
                        sx={{
                            color: '#FFFFFF !important', backgroundColor: disabled ? '#939393 !important' : null,
                            backgroundImage: disabled ? null : "linear-gradient(to bottom, #2FB16B 50%, #168549)"
                        }}
                        className="!text-xs sm:!text-sm md:!text-base"
                    >
                        Kaydet
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

UpdateProfile.propTypes = {
    classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
    return {
    }
};

export default withStyles(styles)(connect(mapStateToProps, {
    setNotification,
})(UpdateProfile));
