import React from "react";
import { connect } from "react-redux";
import { withStyles } from "@mui/styles";
import PropTypes from "prop-types";
import { Box, Button, Card, CardContent, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, FormControlLabel, IconButton, InputAdornment, Pagination, Radio, RadioGroup, SvgIcon, Tab, Table, TableBody, TableCell, TableHead, TableRow, Tabs, TextField, Tooltip, Typography } from "@mui/material";
import { mdiMagnify, mdiPlusCircle } from "@mdi/js";
import debounce from "lodash.debounce";
import { selectTab, setNotification } from "../../actions/global";
import { GlobalTypes } from "../../action_types";
import axios from "axios";
import { Delete, Edit, Search } from "@mui/icons-material";
import LogDetails from "./LogDetails";
import { ERROR_CODES } from "../../global/error";
import clsx from "clsx";
import { AxiosClient } from "../../global/axios";
import { getPath } from "../../global/route";

const styles = theme => ({
    table: {
        border: '2px solid #f0f0f0',
        userSelect: 'text !important',
    },
    tCell: {
        border: 0,
        padding: 32,
        verticalAlign: 'top',
    },
    cellHeader: {
        fontFamily: 'Roboto Slab',
        width: 180,
        fontWeight: 700,
    },
    cellContent: {
        color: '#6b7280 !important',
        fontFamily: 'Roboto Slab !important',
    },
    inputIcon: {
        marginRight: '8px',
        color: '#31204A !important'
    },
});

const tabs = {
    HISTORY: 0,
    LIVE: 1,
}

class ChatLogs extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            search: '',
            page: 1,
            size: 10,
            chatLogs: [],
            totalChatLogs: 0,
            logDetails: null,
            tab: tabs.HISTORY,
            liveChatLogs: [],
        }

    }

    componentDidMount() {
        this.listChatLogs();
        this.props.socket.on('live_chat_log', (data) => {
            const { liveChatLogs } = this.state;
            liveChatLogs.unshift(data);
            this.setState({ liveChatLogs });
        });
    }

    componentDidUpdate(prevProps) {
    }

    componentWillUnmount() {
        this.props.socket.emit('9a6c18f5-0005-474d-b425-5c8ef2d9e8ac', { join: false });
    }


    listChatLogs = async () => {
        try {
            const { search, page, size, filter } = this.state;
            const resp = await AxiosClient().get(`${process.env.REACT_APP_API_URL}/v1/logs/chat-9a6c18f5-0005-474d-b425-5c8ef2d9e8ac?search=${search}&page=${page}&size=${size}`);

            this.setState({ chatLogs: resp.data.data ?? [], totalChatLogs: resp.data.total });

        } catch (err) {
            console.error(err);

            if (err.response?.data?.error?.code in ERROR_CODES) {
                this.props.setNotification({ message: ERROR_CODES[err.response.data.error.code], severity: GlobalTypes.NOTIFY_ERR });
                this.props.selectTab('salon');
                this.props.history.push(getPath('/salon'));
            } else {
                this.props.setNotification({ message: 'Bir hata oluştu. Lütfen tekrar deneyiniz.', severity: GlobalTypes.NOTIFY_ERR });
            }
        }
    }

    debounceSearchChatLog = debounce(() => this.setState({ page: 1 }, () => this.listChatLogs()), 500);

    searchChanged = (e) => {
        this.setState({ search: e.target.value });
        this.debounceSearchChatLog();
    }

    onTabChange = (e, value) => {
        this.setState({ tab: value, page: 1 });

        if (value === tabs.HISTORY) {
            this.listChatLogs();
            this.props.socket.emit('9a6c18f5-0005-474d-b425-5c8ef2d9e8ac', { join: false });
        } else {
            this.props.socket.emit('9a6c18f5-0005-474d-b425-5c8ef2d9e8ac', { join: true });
        }
    }

    // UI PART

    tableView = () => {
        const { classes } = this.props;
        const { chatLogs } = this.state;

        if (chatLogs.length === 0) {
            return (
                <Box className="!hidden lg:!flex justify-center items-center h-[300px]">
                    <Typography className={clsx(classes.title, "!text-xs sm:!text-sm md:!text-base")}>Sonuç bulunamadı.</Typography>
                </Box>
            );
        }

        return (
            <Table className={clsx(
                classes.table,
                "!hidden lg:!table",
            )}>
                <TableHead>
                    <TableRow>
                        <TableCell className={classes.tCell} sx={{ borderBottom: '2px solid #f0f0f0', minWidth: 0, maxWidth: 0 }}>
                            <Typography className={clsx(classes.cellHeader, "!text-xs sm:!text-sm md:!text-base")}>
                                Nick
                            </Typography>
                        </TableCell>
                        <TableCell className={classes.tCell} sx={{ borderBottom: '2px solid #f0f0f0', minWidth: 0, maxWidth: 0 }}>
                            <Typography className={clsx(classes.cellHeader, "!text-xs sm:!text-sm md:!text-base")}>
                                Kime
                            </Typography>
                        </TableCell>
                        <TableCell className={classes.tCell} sx={{ borderBottom: '2px solid #f0f0f0', minWidth: 0, maxWidth: 0 }}>
                            <Typography className={clsx(classes.cellHeader, "!text-xs sm:!text-sm md:!text-base")}>
                                Mesaj Sayısı
                            </Typography>
                        </TableCell>
                        <TableCell className={classes.tCell} sx={{ borderBottom: '2px solid #f0f0f0', minWidth: 0, maxWidth: 0 }}>
                            <Typography className={clsx(classes.cellHeader, "!text-xs sm:!text-sm md:!text-base")}>
                                Son Mesaj Tarihi
                            </Typography>
                        </TableCell>
                        <TableCell className={classes.tCell} sx={{ borderBottom: '2px solid #f0f0f0', minWidth: 0, maxWidth: 0 }}>
                            <Typography className={clsx(classes.cellHeader, "!text-xs sm:!text-sm md:!text-base")}>
                                Aksiyon
                            </Typography>
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {chatLogs.map((log) => {
                        return (
                            <TableRow key={log.id}>
                                <TableCell>
                                    <Typography className={clsx(classes.cellContent, "!text-xs sm:!text-sm md:!text-base")}>
                                        {log.username}
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography className={clsx(classes.cellContent, "!text-xs sm:!text-sm md:!text-base")}>
                                        {log.dm_username ?? log.room}
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography className={clsx(classes.cellContent, "!text-xs sm:!text-sm md:!text-base")}>
                                        {log.msg_count}
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography className={clsx(classes.cellContent, "!text-xs sm:!text-sm md:!text-base")}>
                                        {(() => {
                                            const msgAt = new Date(log.last_msg_at);
                                            return `${msgAt.getDate().toString().padStart(2, '0')}/${(msgAt.getMonth() + 1).toString().padStart(2, '0')}/${msgAt.getFullYear()} ${msgAt.getHours().toString().padStart(2, '0')}:${msgAt.getMinutes().toString().padStart(2, '0')}:${msgAt.getSeconds().toString().padStart(2, '0')}`;
                                        })()}
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Tooltip title="İncele" placement="top">
                                        <IconButton onClick={() => this.setState({ logDetails: log })}>
                                            <Search fontSize="small" />
                                        </IconButton>
                                    </Tooltip>
                                </TableCell>
                            </TableRow>
                        );
                    })}

                </TableBody>
            </Table>
        );
    }

    mobileView = () => {
        const { classes } = this.props;
        const { chatLogs } = this.state;

        if (chatLogs.length === 0) {
            return (
                <Box className="flex lg:hidden justify-center items-center">
                    <Typography className={clsx(classes.title, "!text-xs sm:!text-sm md:!text-base")}>Sonuç bulunamadı.</Typography>
                </Box>
            );
        }

        return (
            <Box className="flex flex-col gap-y-2 lg:hidden">
                {chatLogs.map((log) => {
                    return (
                        <Card key={log.id} sx={{ borderRadius: 4, border: '1px solid #c4c4c4', boxShadow: 0 }}>
                            <CardContent>
                                <Table>
                                    <TableRow>
                                        <TableCell className="!p-2">
                                            <Typography className={clsx(classes.cellContent, "!text-xs sm:!text-sm md:!text-base")}>
                                                Nick
                                            </Typography>
                                        </TableCell>
                                        <TableCell className="!p-2">
                                            <Typography className={clsx(classes.cellContent, "!text-xs sm:!text-sm md:!text-base")}>
                                                {log.username}
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell className="!p-2">
                                            <Typography className={clsx(classes.cellContent, "!text-xs sm:!text-sm md:!text-base")}>
                                                Kime
                                            </Typography>
                                        </TableCell>
                                        <TableCell className="!p-2">
                                            <Typography className={clsx(classes.cellContent, "!text-xs sm:!text-sm md:!text-base")}>
                                                {log.dm_username ?? log.room}
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell className="!p-2">
                                            <Typography className={clsx(classes.cellContent, "!text-xs sm:!text-sm md:!text-base")}>
                                                Mesaj Sayısı
                                            </Typography>
                                        </TableCell>
                                        <TableCell className="!p-2">
                                            <Typography className={clsx(classes.cellContent, "!text-xs sm:!text-sm md:!text-base")}>
                                                {log.msg_count}
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell className="!p-2">
                                            <Typography className={clsx(classes.cellContent, "!text-xs sm:!text-sm md:!text-base")}>
                                                Son Mesaj Tarihi
                                            </Typography>
                                        </TableCell>
                                        <TableCell className="!p-2">
                                            <Typography className={clsx(classes.cellContent, "!text-xs sm:!text-sm md:!text-base")}>
                                                {(() => {
                                                    const msgAt = new Date(log.last_msg_at);
                                                    return `${msgAt.getDate().toString().padStart(2, '0')}/${(msgAt.getMonth() + 1).toString().padStart(2, '0')}/${msgAt.getFullYear()} ${msgAt.getHours().toString().padStart(2, '0')}:${msgAt.getMinutes().toString().padStart(2, '0')}:${msgAt.getSeconds().toString().padStart(2, '0')}`;
                                                })()}
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell className="!p-2">
                                            <Typography className={clsx(classes.cellContent, "!text-xs sm:!text-sm md:!text-base")}>
                                                Aksiyon
                                            </Typography>
                                        </TableCell>
                                        <TableCell className="!p-2">
                                            <Tooltip title="İncele" placement="top">
                                                <IconButton className="!p-[2px] !mr-2" onClick={() => this.setState({ logDetails: log })}>
                                                    <Search className="!text-[16px]" />
                                                </IconButton>
                                            </Tooltip>
                                        </TableCell>
                                    </TableRow>
                                </Table>
                            </CardContent>
                        </Card>
                    );
                })}
            </Box>
        );
    }

    renderHistory = () => {
        const { totalChatLogs } = this.state;

        return (
            <Box margin={1} display={"flex"} flexDirection={"column"} rowGap={1}>
                <TextField variant="outlined" placeholder="Log Ara" fullWidth margin="dense"
                    InputProps={{
                        classes: {
                            input: "!text-xs sm:!text-sm md:!text-base",
                            root: "p-1 md:p-2"
                        },
                        startAdornment: (
                            <InputAdornment position="start">
                                <SvgIcon fontSize='small'>
                                    <path d={mdiMagnify} />
                                </SvgIcon>
                            </InputAdornment>
                        )
                    }}
                    value={this.state.search}
                    onChange={this.searchChanged}
                    sx={{
                        '& .MuiInputBase-root': { p: 0, px: 2 },
                        '& .MuiInputBase-root::before': { borderBottom: '1px solid #E3E3E3 !important' },
                        '& .MuiInputBase-root::after': { borderBottom: '1px solid #E3E3E3 !important' },
                    }}
                />

                {this.tableView()}
                {this.mobileView()}

                <Box className="flex justify-end">
                    <Pagination count={parseInt(Math.ceil(totalChatLogs / this.state.size))} page={this.state.page}
                        variant="outlined" shape="rounded" color="primary"
                        onChange={(e, p) => this.setState({ page: p }, () => this.listChatLogs())}
                    />
                </Box>

                <LogDetails log={this.state.logDetails} onClose={() => this.setState({ logDetails: null })} />
            </Box>
        );
    }

    renderLiveLogs = () => {
        const { usersById } = this.props;

        return (
            <Box>
                {this.state.liveChatLogs.map((log, i) => {
                    return (
                        <Box key={log.id} display={"flex"} columnGap={1} p={1} border={"1px solid #E3E3E3"} borderRadius={2}>
                            {/* render date */}
                            <Typography variant="caption" color="textSecondary">{(() => {
                                const msgAt = new Date(log.ts);
                                return `${msgAt.getHours().toString().padStart(2, '0')}:${msgAt.getMinutes().toString().padStart(2, '0')}`;
                            })()}</Typography>

                            {/* render room */}
                            <Typography variant="body2" color="textSecondary">
                                {log.room in usersById ? `(Özel: ${usersById[log.room].username})` : `#${log.room}`}
                            </Typography>

                            {/* render sender */}
                            <Typography variant="body2" color="textSecondary">{`<${log.sender}>`}</Typography>

                            {/* render message */}
                            <Typography variant="body2">{log.message}</Typography>
                        </Box>
                    );
                })}
            </Box>
        );
    }

    content = () => {
        switch (this.state.tab) {
            case tabs.HISTORY:
                return this.renderHistory();
            case tabs.LIVE:
                return this.renderLiveLogs();
        }
    }

    render() {
        const { classes } = this.props;

        return (
            <Box>
                <Tabs
                    sx={{
                        width: 'fit-content', maxWidth: '100%',
                        '& .MuiTabs-flexContainer': { columnGap: 0 },
                        '& .MuiTabs-indicator': { backgroundColor: "#FF9559", height: '4px' },
                        '& .MuiTab-root': { color: '#505050 !important', borderBottom: '1px solid #E3E3E3', padding: 0 },
                        '& .Mui-selected': { color: '#505050 !important', backgroundColor: '#FFF3EC' },
                        '& .MuiTabs-scrollButtons.Mui-disabled': { opacity: 0.3 },
                    }}
                    variant="scrollable"
                    scrollButtons
                    allowScrollButtonsMobile
                    value={this.state.tab}
                    textColor="primary"
                    className={classes.tabs}
                    onChange={this.onTabChange}>

                    <Tab value={tabs.HISTORY}
                        label={
                            <Box display={"flex"} columnGap={2} flex={1} p={2} justifyContent={"center"}>
                                <Typography sx={{ whiteSpace: 'nowrap', px: 1 }}
                                    className="!text-xs sm:!text-sm md:!text-base"
                                >
                                    Geçmiş
                                </Typography>
                            </Box>
                        }
                        sx={{ textTransform: 'none', padding: 3, flexDirection: 'row', borderRight: '1px solid #E3E3E3' }} />

                    <Tab value={tabs.LIVE}
                        label={
                            <Box display={"flex"} columnGap={2} flex={1} p={2} justifyContent={"center"}>
                                <Typography sx={{ whiteSpace: 'nowrap', px: 1 }}
                                    className="!text-xs sm:!text-sm md:!text-base"
                                >
                                    Canlı
                                </Typography>
                            </Box>
                        }
                        sx={{ textTransform: 'none', padding: 3, flexDirection: 'row', borderRight: '1px solid #E3E3E3' }} />
                </Tabs>

                {this.content()}
            </Box>
        );
    }
}

ChatLogs.propTypes = {
    classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
    return {
        socket: state.app.global.socket,
        usersById: state.app.lobby.usersById,
    }
};

export default withStyles(styles)(connect(mapStateToProps, {
    setNotification, selectTab,
})(ChatLogs));
