import React from "react";
import { Box, IconButton, Typography } from "@mui/material";
import { connect } from "react-redux";
import { withStyles } from "@mui/styles";
import { ArrowForward } from "@mui/icons-material";

const styles = (theme) => ({});

class Blog extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    render() {
        const { title, description, img } = this.props;
        return (
            <Box sx={{
                display: "flex",
                flexDirection: "column",
                width: "auto",
                maxWidth: "276px",
                height: "486px",
                minHeight: "486px",
                borderRadius: "12px",
                boxShadow: "0px 1px 3px rgba(0, 0, 0, 0.05), 0px 15px 40px rgba(122, 133, 255, 0.2)",
                backgroundColor: "#fff",
                position: "relative",
                cursor: "pointer",
            }}>
                <Box>
                    <img src={img} alt="" />
                </Box>
                <Box sx={{ display: "flex", justifyContent: "center", alignItems: "start", flexDirection: "column", p: 3 }}>
                    <Typography sx={{ textAlign: "start", fontSize: "26px", fontWeight: "bold", letterSpacing: "-0.02rem", color: "#1E2C5F" }}>
                        {title}
                    </Typography>
                    <Typography variant="h2" component="h2" sx={{ textAlign: "start", fontSize: "13px", fontWeight: 500, letterSpacing: "-0.02rem", color: "#6F7795", mt: 2 }}>
                        {description}
                    </Typography>
                </Box>
                <IconButton sx={{ position: "absolute", bottom: "20px", right: "20px", }}>
                    <ArrowForward sx={{ color: "#7B53ED" }} />
                </IconButton>
            </Box>
        );
    }
}

const mapStateToProps = (state) => {
    return {
    }
};

export default withStyles(styles)(connect(mapStateToProps, {})(Blog));