import React from "react";
import { connect } from "react-redux";
import { withStyles } from "@mui/styles";
import PropTypes from "prop-types";
import { Box, Button, Card, CardContent, Checkbox, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, FormControlLabel, FormGroup, IconButton, InputAdornment, Pagination, Radio, RadioGroup, SvgIcon, Tab, Table, TableBody, TableCell, TableHead, TableRow, Tabs, TextField, Tooltip, Typography } from "@mui/material";
import {
    Edit as EditIcon, Delete as DeleteIcon,
} from "@mui/icons-material";
import { mdiMagnify, mdiPlusCircle, mdiTuneVertical } from "@mdi/js";
import axios from "axios";
import { selectTab, setAdminTab, setNotification } from "../../../actions/global";
import { GlobalTypes } from "../../../action_types";
import EditAdmin from "./EditAdmin";
import { ERROR_CODES } from "../../../global/error";
import debounce from "lodash.debounce";
import clsx from "clsx";
import { ROLES } from "../../../global/role";
import { AxiosClient } from "../../../global/axios";
import { getPath } from "../../../global/route";

const styles = theme => ({
    table: {
        border: '2px solid #f0f0f0',
        userSelect: 'text !important',
    },
    tCell: {
        border: 0,
        padding: 32,
        verticalAlign: 'top',
    },
    cellHeader: {
        fontFamily: 'Roboto Slab',
        width: 180,
        fontWeight: 700,
    },
    cellContent: {
        color: '#6b7280 !important',
        fontFamily: 'Roboto Slab !important',
    },
    text: {
        fontWeight: '400 !important',
        color: 'var(--white)',
    },
});

const tabs = {
    ADMINS: 0,
}

class AdminSettings extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            tab: tabs.ADMINS,
            admins: [],
            totalAdmins: 0,
            search: '',
            page: 1,
            size: 25,
            edit: false,
            editAdmin: null,
            filter: 'all',
        }

    }

    componentDidMount() {
        this.listAdmins();
        this.setState({ tab: this.props.adminTab });
    }

    componentDidUpdate(prevProps) {
    }

    onAddAdmin = () => {
        this.setState({ edit: true });
    }

    listAdmins = async () => {
        try {
            const { search, page, size, filter } = this.state;
            const resp = await AxiosClient().get(`${process.env.REACT_APP_API_URL}/v1/admin/admins?search=${search}&page=${page}&size=${size}&filter=${filter}`);

            this.setState({ admins: resp.data.data ?? [], totalAdmins: resp.data.total ?? 0 });

        } catch (err) {
            console.error(err);

            if (err.response?.data?.error?.code in ERROR_CODES) {
                this.props.setNotification({ message: ERROR_CODES[err.response.data.error.code], severity: GlobalTypes.NOTIFY_ERR });
                this.props.selectTab('salon');
                this.props.history.push(getPath('/salon'));
            } else {
                this.props.setNotification({ message: 'Bir hata oluştu. Lütfen tekrar deneyiniz.', severity: GlobalTypes.NOTIFY_ERR });
            }
        }
    }

    handleEdit = (admin) => {
        this.setState({ edit: true, editAdmin: admin });
    }

    handleDelete = (admin) => {
        this.setState({ deleteAdmin: admin });
    }

    deleteAdmin = async (admin) => {
        try {
            const resp = await AxiosClient().delete(`${process.env.REACT_APP_API_URL}/v1/admin/admins/${admin.id}`);

            if (resp.data?.ok) {
                this.props.setNotification({ message: 'Admin silindi.', severity: GlobalTypes.NOTIFY_SUCC });
            }

        } catch (err) {
            console.error(err);
            this.props.setNotification({ message: 'Bir hata oluştu. Lütfen tekrar deneyiniz.', severity: GlobalTypes.NOTIFY_ERR });
        } finally {
            this.setState({ deleteAdmin: null }, () => this.listAdmins());
        }
    }

    deleteAdminDialog = () => {
        const { deleteAdmin } = this.state;

        return (
            <Dialog open={!!deleteAdmin} onClose={() => this.setState({ deleteAdmin: null })}>
                <DialogTitle>Admin Sil</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Admin {deleteAdmin?.username} silinecek, emin misiniz?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => this.setState({ deleteAdmin: null })} variant="contained" color="error">
                        İptal
                    </Button>
                    <Button onClick={() => this.deleteAdmin(deleteAdmin)} variant="contained" color="primary" autoFocus className="!text-white">
                        Sil
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }

    onTabChange = (e, value) => {
        this.props.setAdminTab(value);
        this.setState({ tab: value });
    }

    debounceSearchAdmin = debounce(() => this.setState({ page: 1 }, () => this.listAdmins()), 500);

    searchChanged = (e) => {
        this.setState({ search: e.target.value });
        this.debounceSearchAdmin();
    }

    tableView = () => {
        const { classes } = this.props;

        if (this.state.admins.length === 0) {
            return (
                <Box className="!hidden lg:!flex justify-center items-center h-[300px]">
                    <Typography className="text-[16px]">Sonuç bulunamadı.</Typography>
                </Box>
            );
        }

        return (
            <Table className={clsx(
                classes.table,
                "!hidden lg:!table",
            )}
            >
                <TableHead>
                    <TableRow>
                        <TableCell className={classes.tCell} sx={{ borderBottom: '2px solid #f0f0f0', minWidth: 0, maxWidth: 0 }}>
                            <Typography className={clsx(classes.cellHeader, "!text-xs md:!text-sm lg:!text-base")}>
                                Nick
                            </Typography>
                        </TableCell>
                        <TableCell className={classes.tCell} sx={{ borderBottom: '2px solid #f0f0f0', minWidth: 0, maxWidth: 0 }}>
                            <Typography className={clsx(classes.cellHeader, "!text-xs md:!text-sm lg:!text-base")}>
                                Rol
                            </Typography>
                        </TableCell>
                        <TableCell className={classes.tCell} sx={{ borderBottom: '2px solid #f0f0f0', minWidth: 0, maxWidth: 0 }}>
                            <Typography className={clsx(classes.cellHeader, "!text-xs md:!text-sm lg:!text-base")}>
                                Ekleyen
                            </Typography>
                        </TableCell>
                        <TableCell className={classes.tCell} sx={{ borderBottom: '2px solid #f0f0f0', minWidth: 0, maxWidth: 0 }}>
                            <Typography className={clsx(classes.cellHeader, "!text-xs md:!text-sm lg:!text-base")}>
                                Eklenen Tarih
                            </Typography>
                        </TableCell>
                        <TableCell className={classes.tCell} sx={{ borderBottom: '2px solid #f0f0f0', minWidth: 0, maxWidth: 0 }}>
                            <Typography className={clsx(classes.cellHeader, "!text-xs md:!text-sm lg:!text-base")}>
                                Bitiş Tarihi
                            </Typography>
                        </TableCell>
                        <TableCell className={classes.tCell} sx={{ borderBottom: '2px solid #f0f0f0', minWidth: 0, maxWidth: 0 }}>
                            <Typography className={clsx(classes.cellHeader, "!text-xs md:!text-sm lg:!text-base")}>
                                Not
                            </Typography>
                        </TableCell>
                        <TableCell className={classes.tCell} sx={{ borderBottom: '2px solid #f0f0f0', minWidth: 0, maxWidth: 0 }}>
                            <Typography className={clsx(classes.cellHeader, "!text-xs md:!text-sm lg:!text-base")}>
                                Aksiyon
                            </Typography>
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {this.state.admins.map(admin => {
                        return (
                            <TableRow key={admin.id}>
                                <TableCell>
                                    <Typography className={clsx(classes.cellContent, "!text-xs md:!text-sm lg:!text-base")}>
                                        {admin.username}
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography className={clsx(classes.cellContent, "!text-xs md:!text-sm lg:!text-base")}>
                                        {ROLES[admin.role]}
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography className={clsx(classes.cellContent, "!text-xs md:!text-sm lg:!text-base")}>
                                        {admin.created_by}
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography className={clsx(classes.cellContent, "!text-xs md:!text-sm lg:!text-base")}>
                                        {new Date(admin.created_at).toLocaleDateString()}
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography className={clsx(classes.cellContent, "!text-xs md:!text-sm lg:!text-base")}>
                                        {admin.valid_until ? new Date(admin.valid_until).toLocaleDateString() : '-'}
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography className={clsx(classes.cellContent, "!text-xs md:!text-sm lg:!text-base")}>
                                        {admin.description}
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Tooltip title="Düzenle" placement="top">
                                        <IconButton onClick={() => this.handleEdit(admin)}
                                            disabled={admin.role > this.props.role}
                                        >
                                            <EditIcon fontSize="small" />
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip title="Sil" placement="top">
                                        <IconButton onClick={() => this.handleDelete(admin)}
                                            disabled={admin.role > this.props.role}
                                        >
                                            <DeleteIcon fontSize="small" />
                                        </IconButton>
                                    </Tooltip>
                                </TableCell>
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
        );
    }

    mobileView = () => {
        const { classes } = this.props;

        if (this.state.admins.length === 0) {
            return (
                <Box className="flex lg:hidden justify-center items-center">
                    <Typography className="text-[16px]">Sonuç bulunamadı.</Typography>
                </Box>
            );
        }

        return (
            <Box className="flex flex-col gap-y-2 lg:hidden">
                {this.state.admins.map(admin => {
                    return (
                        <Card key={admin.id} sx={{ borderRadius: 4, border: '1px solid #c4c4c4', boxShadow: 0 }}>
                            <CardContent>
                                <Table>
                                    <TableRow>
                                        <TableCell className="!p-2">
                                            <Typography className={clsx(classes.cellContent, "!text-xs md:!text-sm lg:!text-base")}>
                                                Nick
                                            </Typography>
                                        </TableCell>
                                        <TableCell className="!p-2">
                                            <Typography className={clsx(classes.cellContent, "!text-xs md:!text-sm lg:!text-base")}>
                                                {admin.username}
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell className="!p-2">
                                            <Typography className={clsx(classes.cellContent, "!text-xs md:!text-sm lg:!text-base")}>
                                                Email
                                            </Typography>
                                        </TableCell>
                                        <TableCell className="!p-2">
                                            <Typography className={clsx(classes.cellContent, "!text-xs md:!text-sm lg:!text-base")}>
                                                {admin.email}
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell className="!p-2">
                                            <Typography className={clsx(classes.cellContent, "!text-xs md:!text-sm lg:!text-base")}>
                                                Ekleyen
                                            </Typography>
                                        </TableCell>
                                        <TableCell className="!p-2">
                                            <Typography className={clsx(classes.cellContent, "!text-xs md:!text-sm lg:!text-base")}>
                                                {admin.created_by}
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell className="!p-2">
                                            <Typography className={clsx(classes.cellContent, "!text-xs md:!text-sm lg:!text-base")}>
                                                Eklenen Tarih
                                            </Typography>
                                        </TableCell>
                                        <TableCell className="!p-2">
                                            <Typography className={clsx(classes.cellContent, "!text-xs md:!text-sm lg:!text-base")}>
                                                {new Date(admin.created_at).toLocaleDateString()}
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell className="!p-2">
                                            <Typography className={clsx(classes.cellContent, "!text-xs md:!text-sm lg:!text-base")}>
                                                Bitiş Tarihi
                                            </Typography>
                                        </TableCell>
                                        <TableCell className="!p-2">
                                            <Typography className={clsx(classes.cellContent, "!text-xs md:!text-sm lg:!text-base")}>
                                                {admin.valid_until ? new Date(admin.valid_until).toLocaleDateString() : '-'}
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell className="!p-2">
                                            <Typography className={clsx(classes.cellContent, "!text-xs md:!text-sm lg:!text-base")}>
                                                Not
                                            </Typography>
                                        </TableCell>
                                        <TableCell className="!p-2">
                                            <Typography className={clsx(classes.cellContent, "!text-xs md:!text-sm lg:!text-base")}>
                                                {admin.description}
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell className="!p-2">
                                            <Typography className={clsx(classes.cellContent, "!text-xs md:!text-sm lg:!text-base")}>
                                                Aksiyon
                                            </Typography>
                                        </TableCell>
                                        <TableCell className="!p-2">
                                            <Typography className={clsx(classes.cellContent, "!text-xs md:!text-sm lg:!text-base")}>
                                                <Tooltip title="Düzenle" placement="top">
                                                    <IconButton onClick={() => this.handleEdit(admin)}
                                                        className="!p-[2px] !mr-2"
                                                    >
                                                        <EditIcon className="!text-[16px]" />
                                                    </IconButton>
                                                </Tooltip>
                                                <Tooltip title="Sil" placement="top">
                                                    <IconButton onClick={() => this.handleDelete(admin)}
                                                        className="!p-[2px]"
                                                    >
                                                        <DeleteIcon className="!text-[16px]" />
                                                    </IconButton>
                                                </Tooltip>
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                </Table>
                            </CardContent>
                        </Card>
                    );
                })}
            </Box>
        );
    }

    adminsTab = () => {
        const { classes } = this.props;

        return (
            <Box margin={1} display={"flex"} flexDirection={"column"} rowGap={1}>
                <Box className="flex flex-col md:flex-row gap-x-2 justify-between items-start md:items-center">
                    <FormControl>
                        <RadioGroup value={this.state.filter}>
                            <Box className="px-2 py-1">
                                <FormControlLabel value={"all"} control={
                                    <Radio onChange={(e) => this.setState({ filter: "all", page: 1 }, () => this.listAdmins())}
                                        checked={this.state.filter === "all"}
                                    />
                                } label={
                                    <Typography className={clsx(classes.text, "!text-xs md:!text-sm lg:!text-base !text-black")} alignSelf={"center"}>Tümü</Typography>
                                } />

                                <FormControlLabel value={"unlimited"} control={
                                    <Radio onChange={(e) => this.setState({ filter: "unlimited", page: 1 }, () => this.listAdmins())}
                                        checked={this.state.filter === "unlimited"}
                                    />
                                } label={
                                    <Typography className={clsx(classes.text, "!text-xs md:!text-sm lg:!text-base !text-black")} alignSelf={"center"}>Süresizler</Typography>
                                } />

                                <FormControlLabel value={"limited"} control={
                                    <Radio onChange={(e) => this.setState({ filter: "limited", page: 1 }, () => this.listAdmins())}
                                        checked={this.state.filter === "limited"}
                                    />
                                } label={
                                    <Typography className={clsx(classes.text, "!text-xs md:!text-sm lg:!text-base !text-black")} alignSelf={"center"}>Süreliler</Typography>
                                } />

                                <FormControlLabel value={"expires_soon"} control={
                                    <Radio onChange={(e) => this.setState({ filter: "expires_soon", page: 1 }, () => this.listAdmins())}
                                        checked={this.state.filter === "expires_soon"}
                                    />
                                } label={
                                    <Typography className={clsx(classes.text, "!text-xs md:!text-sm lg:!text-base !text-black")} alignSelf={"center"}>Yaklaşanlar</Typography>
                                } />

                                <FormControlLabel value={"expired"} control={
                                    <Radio onChange={(e) => this.setState({ filter: "expired", page: 1 }, () => this.listAdmins())}
                                        checked={this.state.filter === "expired"}
                                    />
                                } label={
                                    <Typography className={clsx(classes.text, "!text-xs md:!text-sm lg:!text-base !text-black")} alignSelf={"center"}>Geçenler</Typography>
                                } />
                            </Box>
                        </RadioGroup>
                    </FormControl>

                    <Button variant="contained" sx={{ backgroundImage: "linear-gradient(to bottom, #2FB16B 50%, #168549)" }}
                        onClick={this.onAddAdmin}
                        className="h-9"
                    >
                        <Box columnGap="8px" className="flex align-middle">
                            <SvgIcon fontSize="small" sx={{ margin: 'auto' }}><path d={mdiPlusCircle} color="var(--white)" /></SvgIcon>
                            <Typography className={clsx(classes.text, "!text-xs md:!text-sm lg:!text-base")} alignSelf={"center"}>Ekle</Typography>
                        </Box>
                    </Button>
                </Box>

                <TextField variant="outlined" placeholder="Admin Ara" fullWidth margin="dense"
                    InputProps={{
                        classes: { root: "p-1 md:p-2" },
                        startAdornment: (
                            <InputAdornment position="start">
                                <SvgIcon fontSize='small'>
                                    <path d={mdiMagnify} />
                                </SvgIcon>
                            </InputAdornment>
                        )
                    }}
                    value={this.state.search}
                    onChange={this.searchChanged}
                    sx={{
                        '& .MuiInputBase-root': { p: 0, px: 2 },
                        '& .MuiInputBase-root::before': { borderBottom: '1px solid #E3E3E3 !important' },
                        '& .MuiInputBase-root::after': { borderBottom: '1px solid #E3E3E3 !important' },
                    }}
                />

                {this.tableView()}
                {this.mobileView()}

                <Box className="flex justify-end">
                    <Pagination count={parseInt(Math.ceil(this.state.totalAdmins / this.state.size))} page={this.state.page}
                        variant="outlined" shape="rounded" color="primary"
                        onChange={(e, p) => this.setState({ page: p }, () => this.listAdmins())}
                    />
                </Box>
            </Box>
        );
    }

    content = () => {
        switch (this.state.tab) {
            case tabs.ADMINS:
                return this.adminsTab();
        }
    }

    render() {
        const { classes } = this.props;

        return (
            <Box overflow={"scroll"}>
                <EditAdmin open={this.state.edit} admin={this.state.editAdmin}
                    onClose={(refresh) => this.setState({ edit: false, editAdmin: null }, () => refresh && this.listAdmins())}
                />

                <Tabs
                    sx={{
                        width: 'fit-content',
                        '& .MuiTabs-flexContainer': { columnGap: 0 },
                        '& .MuiTabs-indicator': { backgroundColor: "#FF9559", height: '4px' },
                        '& .MuiTab-root': { color: '#505050 !important', flex: 1, borderBottom: '1px solid #E3E3E3', padding: 0 },
                        '& .Mui-selected': { color: '#505050 !important', backgroundColor: '#FFF3EC' },
                    }}
                    value={this.state.tab}
                    textColor="primary"
                    className={classes.tabs}
                    onChange={this.onTabChange}>

                    <Tab value={tabs.ADMINS}
                        label={
                            <Box display={"flex"} columnGap={2} flex={1} p={2} justifyContent={"space-between"}>
                                <Typography className={clsx(classes.tabTitle, "!text-xs md:!text-sm lg:!text-base")}>Adminler</Typography>
                            </Box>
                        }
                        sx={{ textTransform: 'none', padding: 3, flexDirection: 'row', borderRight: '1px solid #E3E3E3' }} />
                </Tabs>

                {this.content()}

                {this.deleteAdminDialog()}
            </Box>
        );
    }
}

AdminSettings.propTypes = {
    classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
    return {
        adminTab: state.app.global.adminTab,
        role: state.app.global.role,
    }
};

export default withStyles(styles)(connect(mapStateToProps, {
    setNotification, setAdminTab, selectTab,
})(AdminSettings));
