import React from "react";
import { connect } from "react-redux";
import { withStyles } from "@mui/styles";
import PropTypes from "prop-types";
import Header from "./components/Header";
import { Box } from "@mui/material";
import clsx from "clsx";


const styles = theme => ({
});

class HeaderWrapper extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        }

    }

    componentDidMount() {
    }

    componentDidUpdate(prevProps) {
    }

    render() {
        const { classes } = this.props;

        return (
            <Box className={clsx("flex flex-col h-full")}>
                <Header history={this.props.history} isLandscape={this.props.isLandscape} />
                {this.props.children}
            </Box>
        );
    }
}

HeaderWrapper.propTypes = {
    classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
    return {
    }
};

export default withStyles(styles)(connect(mapStateToProps, {
})(HeaderWrapper));
