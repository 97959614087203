import React from "react";
import { connect } from "react-redux";
import { withStyles } from "@mui/styles";
import PropTypes from "prop-types";
import {
    Chat as ChatIcon, HowToReg, Login, SpeakerNotesOff, ClearAll,
} from '@mui/icons-material';
import { selectTab, setNotification } from "../../actions/global";
import axios from "axios";
import { Box, CircularProgress, List, ListItemButton, ListItemIcon, ListItemText, ListSubheader, Tooltip } from "@mui/material";
import { GlobalTypes } from "../../action_types";
import { roles } from "../../global/role";
import ChatLogs from "./ChatLogs";
import AuditLogs from "./AuditLogs";
import { AxiosClient } from "../../global/axios";
import { getPath } from "../../global/route";


const styles = theme => ({
});

const items = [
    { link: getPath('/admin/log/loginout'), name: 'Giriş Çıkış Logları', icon: Login, hidden: (role, permission) => (permission & Number(1n << 31n)) === 0 },
    { link: getPath('/admin/log/register.avatar'), name: 'Kayıt ve Avatar Logları', icon: HowToReg, hidden: (role, permission) => (permission & Number(1n << 30n)) === 0 },
    { link: getPath('/admin/log/spam'), name: 'Spam Logları', icon: SpeakerNotesOff, hidden: (role, permission) => (permission & Number(1n << 29n)) === 0 },
    { link: getPath('/admin/log/other'), name: 'Diğer Loglar', icon: ClearAll, hidden: (role, permission) => (permission & Number(1n << 28n)) === 0 },
    { link: getPath('/admin/log/chat'), name: 'Sohbet Logları', icon: ChatIcon, hidden: (role, permission) => role !== roles.SuperFounder },
];

class Logs extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            clickedAt: null,
            loading: true,
            role: 0,
            permission: 0,
        }

    }

    componentDidMount() {
        this.props.selectTab('log');

        AxiosClient().get(`${process.env.REACT_APP_API_URL}/v1/user/role`).then((res) => {
            this.setState({ loading: false, role: res.data.role, permission: res.data.perm });
        }).catch((err) => {
            this.props.setNotification({ message: 'Bu sayfayı görüntülemek için yetkiniz bulunmamaktadır.', severity: GlobalTypes.NOTIFY_ERR });
            this.props.selectTab('salon');
            this.props.history.push(getPath('/salon'));
        });
    }

    componentDidUpdate(prevProps) {
    }

    navigate = (link) => {
        this.props.history.push(link);
        this.setState({ clickedAt: Date.now() });
    }

    createListItem = (link, name, Icon, selected) => {
        const { classes } = this.props;

        return (
            <ListItemButton onClick={() => this.navigate(link)} className={classes.ListButton}
                style={{ padding: 0, columnGap: 18, marginBottom: 1, backgroundColor: selected ? '#FFC8A2' : '#F0F0F0' }}>
                <ListItemIcon sx={{ backgroundColor: selected ? '#FF9559' : '#D3D3D3', minWidth: 0, width: 48, height: 48 }}>
                    <Icon selected htmlColor={selected ? "#FFFFFF" : "#666666"} sx={{ fontSize: 22, margin: 'auto' }} />
                </ListItemIcon>
                <ListItemText disableTypography primary={name}
                    sx={{ color: selected ? '#575757' : "#9E9E9E", fontWeight: 400, marginTop: 1, whiteSpace: 'nowrap', paddingRight: 4 }}
                    className="hidden xl:block !text-xs sm:!text-sm md:!text-base"
                />
            </ListItemButton >
        )
    }

    content = () => {
        switch (this.props.history?.location?.pathname) {
            case getPath('/admin/log/loginout'):
                return <AuditLogs key={this.state.clickedAt} filter="LOGIN,LOGOUT" history={this.props.history} />;
            case getPath('/admin/log/register.avatar'):
                return <AuditLogs key={this.state.clickedAt} filter="REGISTER,AVATAR" history={this.props.history} />;
            case getPath('/admin/log/spam'):
                return <AuditLogs key={this.state.clickedAt} filter="SPAM" history={this.props.history} />;
            case getPath('/admin/log/other'):
                return <AuditLogs key={this.state.clickedAt} filter="OTHER" history={this.props.history} />;
            case getPath('/admin/log/chat'):
                return <ChatLogs key={this.state.clickedAt} history={this.props.history} />;
        }
    }

    render() {
        const { classes } = this.props;
        const { loading, role, permission } = this.state;

        if (loading) {
            return <CircularProgress />;
        }

        return (
            <Box display="flex" flexDirection="column" className="flex-1" overflow={"auto"}>

                <Box display={"flex"} flex={1} overflow={"hidden"}>
                    <Box display="flex" flexDirection="column">
                        <div style={{
                            display: 'flex', flexDirection: 'column', flex: 1, borderRight: '1px solid #F0F0F0',
                            backgroundColor: '#E6E6FA'
                        }}>
                            <List
                                dense
                                component="nav"
                                aria-labelledby="nested-list-subheader"
                                sx={{
                                    padding: 0,
                                    '& .MuiListItem-root:last-child': { margin: '0 !important' }
                                }}
                                subheader={
                                    <ListSubheader component="div" id="nested-list-subheader" className={classes.ListTitle}>
                                    </ListSubheader>
                                }>

                                {items.map((item, index) => {
                                    if (item.hidden?.(role, permission)) {
                                        return null;
                                    }


                                    const selected = this.props.history?.location?.pathname === item.link;
                                    const content = this.createListItem(item.link, item.name, item.icon, selected);

                                    return (
                                        <>
                                            <Box className="block xl:hidden">
                                                <Tooltip key={item.link} title={item.name} placement="top">
                                                    {content}
                                                </Tooltip>
                                            </Box>
                                            <Box className="hidden xl:block">
                                                {content}
                                            </Box>
                                        </>
                                    );
                                })}

                            </List>
                        </div>
                    </Box>

                    <Box overflow={"scroll"} className="w-full p-2">
                        {this.content()}
                    </Box>
                </Box>
            </Box>
        );
    }
}

Logs.propTypes = {
    classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
    return {
    }
};

export default withStyles(styles)(connect(mapStateToProps, {
    selectTab, setNotification,
})(Logs));
