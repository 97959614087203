import React from "react";
import PropTypes from "prop-types";
import { Avatar } from "@mui/material";
import clsx from "clsx";
import { getPath } from "../../global/route";
import { loadedResources } from "../../global/resources";

class UserAvatar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            avatar: null,
        }

    }

    componentDidMount() {
        this.getAvatar();
    }

    getAvatar = async () => {
        const { user, classes, className, sx } = this.props;
        const imgPath = `${process.env.REACT_APP_CDN_URL}/web/img`;

        let avatar = null;

        if (!user) {
            avatar = (
                <Avatar src={loadedResources[`${imgPath}/robot.png`]} classes={classes} sx={sx} />
            );
        }
        else if (user && !user.bot) {
            avatar = (
                <Avatar className={clsx(
                    "my-auto",
                    "!w-[1.0em] !h-[1.0em] sm:!w-[1.2em] sm:!h-[1.2em] md:!w-[1.4em] md:!h-[1.4em] lg:!w-[1.6em] lg:!h-[1.6em] xl:!w-[1.8em] xl:!h-[1.8em]",
                    //className,
                )}
                    classes={classes}
                    sx={sx}
                    src={`${process.env.REACT_APP_STORAGE_URL}/users/${user?.id}/${user?.profile_photo}`}
                />
            );
        }
        else if (user && user.bot) {
            avatar = (
                <Avatar
                    className="my-auto !w-[1.0em] !h-[1.0em] sm:!w-[1.2em] sm:!h-[1.2em] md:!w-[1.4em] md:!h-[1.4em] lg:!w-[1.6em] lg:!h-[1.6em] xl:!w-[1.8em] xl:!h-[1.8em]"
                    classes={classes}
                    sx={sx}
                    src={loadedResources[`${imgPath}/robot.svg`]}
                />
            );

            this.setState({ avatar });
        }

        this.setState({ avatar });
    }

    componentDidUpdate(prevProps) {
        const { user, noCache } = this.props;

        if (user?.profile_photo && prevProps.user?.profile_photo !== user?.profile_photo) {
            this.getAvatar();
        }

        if (prevProps.user?.id !== user?.id && (user?.id || noCache)) {
            this.getAvatar();
        }
    }

    render() {
        const { user, showStatus, online } = this.props;

        return (
            <>
                {this.state.avatar}

                {/** Status Color */}
                {showStatus ? (() => {
                    const colors = ["#ABABAB", "#30B26C", "#DA4E4E", "#EFAE2F"];

                    let hide = false;
                    let color = colors[0];

                    if (user?.bot) {
                        color = colors[1];
                    }
                    else if (online && user && user.status > 0) {
                        color = colors[user.status];
                    }
                    else if (online && user && user.status <= 0) {
                        hide = true;
                    }

                    return (
                        <div style={{
                            borderRadius: '50%', position: 'absolute',
                            backgroundColor: color,
                            border: '2px solid #FFFFFF', display: hide && online !== false ? 'none' : 'block',
                        }}
                            className={clsx(
                                "!w-[0.6em] !h-[0.6em] sm:!w-[0.7em] sm:!h-[0.7em] md:!w-[0.8em] md:!h-[0.8em] lg:!w-[0.9em] lg:!h-[0.9em] xl:!w-[1em] xl:!h-[1em]",
                                //"bottom-[0.3em] sm:bottom-[0.3em] md:bottom-[0.3em] lg:bottom-[0.3em] xl:bottom-[0.3em] left-[-0.3em]",
                                "bottom-[0.3em] left-[-0.3em]"
                            )}
                        />
                    );
                })() : null}
            </>
        );
    }
}

UserAvatar.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default UserAvatar;
