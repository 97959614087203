import React from "react";
import { connect } from "react-redux";
import { withStyles } from "@mui/styles";
import PropTypes from "prop-types";
import clsx from "clsx";
import { Typography } from "@mui/material";


const styles = theme => ({
});

class UserLatency extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        }

    }

    componentDidMount() {
    }

    componentDidUpdate(prevProps) {
    }

    render() {
        const ping = (this.props.ping / 1000).toFixed(2);

        return (
            <Typography className={clsx("!text-xs text-white sm:!text-sm md:!text-base", this.props.className)} sx={{ whiteSpace: 'nowrap' }}>
                Ping: {ping} s
            </Typography>
        );
    }
}

UserLatency.propTypes = {
    classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
    return {
        ping: state.app.global.ping,
    }
};

export default withStyles(styles)(connect(mapStateToProps, {
})(UserLatency));
