import React from "react";
import { connect } from "react-redux";
import { withStyles } from "@mui/styles";
import PropTypes from "prop-types";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, MenuItem, SvgIcon, Table, TableCell, TableRow, TextField, Tooltip, Typography } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { Add, AllInclusive, Remove } from "@mui/icons-material";
import dayjs from "dayjs";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import clsx from "clsx";
import axios from "axios";
import { GlobalTypes } from "../../../action_types";
import { setNotification } from "../../../actions/global";
import { icons } from "./icons";
import { AxiosClient } from "../../../global/axios";
import { getPath } from "../../../global/route";
import { loadedResources } from "../../../global/resources";


const styles = theme => ({
});

class EditUserColor extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            username: props.userColor?.username || '',
            date: props.userColor?.svu ? dayjs(props.userColor.svu) : dayjs(new Date()).add(1, 'day'),
            usernameColor: props.userColor?.username_color || 501,
            textColor: props.userColor?.text_color || 501,
            icon: props.tableIcon || '_',
            saving: false,
        }

        this.usernameColors = Array(99).fill(501).map((x, y) => x + y);
        this.textColors = Array(99).fill(501).map((x, y) => x + y);
    }

    componentDidMount() {
    }

    componentDidUpdate(prevProps) {
        if (this.props.userColor && !prevProps.userColor) {
            this.setState({
                username: this.props.userColor.username,
                date: this.props.userColor.svu ? dayjs(this.props.userColor.svu) : null,
                usernameColor: this.props.userColor.username_color,
                textColor: this.props.userColor.text_color,
            });
        }

        if (!this.props.userColor && prevProps.userColor) {
            this.setState({
                username: '',
                date: dayjs(new Date()).add(1, 'day'),
                usernameColor: 501,
                textColor: 501,
            });
        }
    }

    onSave = async () => {
        this.setState({ saving: true });

        const userColor = {
            username: this.state.username,
            svu: this.state.date ? this.state.date.unix() : null,
            username_color: this.state.usernameColor.toString(),
            text_color: this.state.textColor.toString(),
        };

        if (this.props.userColor) {
            userColor.id = this.props.userColor.id;
        }

        try {
            await AxiosClient().post(`${process.env.REACT_APP_API_URL}/v1/admin/users/colors`, userColor);

            this.props.setNotification({ message: `Kullanıcı rengi ${this.props.userColor ? 'düzenlendi.' : 'eklendi.'}`, severity: GlobalTypes.NOTIFY_SUCC });
            this.props.onClose(true);

        } catch (error) {
            console.error(error);
            this.props.setNotification({ message: 'Bir hata oluştu. Lütfen tekrar deneyin.', severity: GlobalTypes.NOTIFY_ERR });
        } finally {
            this.setState({ saving: false, username: '', date: dayjs(new Date()), reason: '' });
        }
    }

    render() {
        const { classes, preview } = this.props;
        const imgPath = `${process.env.REACT_APP_CDN_URL}/web/img`;

        return (
            <Dialog open={this.props.open} onClose={() => this.props.onClose()}>
                <DialogTitle>
                    <Typography className={"!text-xs md:!text-sm lg:!text-base"} sx={{ color: '#413067' }}>
                        {preview ? 'Renk Önizleme' :
                            (`Renk ${this.props.userColor ? 'Düzenle' : 'Ekle'}`)
                        }
                    </Typography>
                </DialogTitle>
                <DialogContent>
                    <Table>
                        {preview ? null : (
                            <TableRow>
                                <TableCell className="!border-0 !p-2 min-w-32">
                                    <Typography className={"!text-xs md:!text-sm lg:!text-base"} sx={{ color: '#413067' }}>
                                        Kullanıcı Adı
                                    </Typography>
                                </TableCell>
                                <TableCell className="!border-0 !p-2">
                                    <TextField margin="dense" fullWidth
                                        value={this.state.username}
                                        sx={{
                                            borderTopRightRadius: '0 !important',
                                            borderBottomRightRadius: '0 !important',
                                            margin: 0,
                                        }}
                                        InputProps={{
                                            classes: {
                                                input: "h-8 sm:h-12",
                                                root: "h-8 sm:h-12",
                                            }
                                        }}
                                        disabled={Boolean(this.props.userColor)}
                                        onChange={(e) => this.setState({ username: e.target.value })}
                                    />
                                </TableCell>
                            </TableRow>
                        )}
                        {preview ? null : (
                            <TableRow>
                                <TableCell className="!border-0 !p-2">
                                    <Typography className={"!text-xs md:!text-sm lg:!text-base"} sx={{ color: '#413067' }}>
                                        Tarih
                                    </Typography>
                                </TableCell>
                                <TableCell className="!border-0 !p-2">
                                    <Box className="flex gap-x-1 items-center">

                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <DatePicker format="DD/MM/YYYY" onChange={date => this.setState({ date })} value={this.state.date}
                                                disabled={!Boolean(this.state.date)} disablePast
                                            />
                                        </LocalizationProvider>

                                        <Tooltip title="1 gün azalt" placement="top">
                                            <IconButton onClick={() => this.setState({ date: this.state.date?.subtract?.(1, 'day') ?? dayjs(new Date()) })}
                                                className="w-9 h-9"
                                            >
                                                <SvgIcon>
                                                    <Remove />
                                                </SvgIcon>
                                            </IconButton>
                                        </Tooltip>

                                        <Tooltip title="1 gün arttır" placement="top">
                                            <IconButton onClick={() => this.setState({ date: this.state.date?.add?.(1, 'day') ?? dayjs(new Date()) })}
                                                className="w-9 h-9"
                                            >
                                                <SvgIcon>
                                                    <Add />
                                                </SvgIcon>
                                            </IconButton>
                                        </Tooltip>

                                        <Tooltip title="Süresiz" placement="top">
                                            <IconButton onClick={() => this.setState({ date: this.state.date ? null : dayjs(new Date()) })}
                                                className={clsx(this.state.date ? null : "!bg-blue-400", "w-9 h-9")}
                                            >
                                                <SvgIcon>
                                                    <AllInclusive htmlColor={this.state.date ? null : 'white'} />
                                                </SvgIcon>
                                            </IconButton>
                                        </Tooltip>
                                    </Box>
                                </TableCell>
                            </TableRow>
                        )}
                        <TableRow>
                            <TableCell className="!border-0 !p-2">
                                <Typography className={"!text-xs md:!text-sm lg:!text-base"} sx={{ color: '#413067' }}>
                                    Nick Rengi
                                </Typography>
                            </TableCell>
                            <TableCell className="!border-0 !p-2">
                                <TextField margin="dense" fullWidth select
                                    value={this.state.usernameColor} sx={{
                                        borderTopRightRadius: '0 !important',
                                        borderBottomRightRadius: '0 !important',
                                        margin: 0,
                                    }}
                                    InputProps={{
                                        classes: {
                                            input: "h-8 sm:h-12 !text-xs md:!text-sm lg:!text-base",
                                            root: "h-8 sm:h-12",
                                        }
                                    }}
                                    onChange={(e) => this.setState({ usernameColor: e.target.value })}
                                >
                                    {this.usernameColors.map((color) => (
                                        <MenuItem key={color} value={color}>
                                            <span className={`NickRenk${color} !text-xs md:!text-sm lg:!text-base`}>Nick Rengi {color - 500}</span>
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell className="!border-0 !p-2">
                                <Typography className={"!text-xs md:!text-sm lg:!text-base"} sx={{ color: '#413067' }}>
                                    Yazı Rengi
                                </Typography>
                            </TableCell>
                            <TableCell className="!border-0 !p-2">
                                <TextField margin="dense" fullWidth select
                                    value={this.state.textColor} sx={{
                                        borderTopRightRadius: '0 !important',
                                        borderBottomRightRadius: '0 !important',
                                        margin: 0,
                                    }}
                                    InputProps={{
                                        classes: {
                                            input: "h-8 sm:h-12 !text-xs md:!text-sm lg:!text-base",
                                            root: "h-8 sm:h-12",
                                        }
                                    }}
                                    onChange={(e) => this.setState({ textColor: e.target.value })}
                                >
                                    {this.textColors.map((color) => (
                                        <MenuItem key={color} value={color}>
                                            <span className={`YaziRenk${color} !text-xs md:!text-sm lg:!text-base`}>Yazı Rengi {color - 500}</span>
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </TableCell>
                        </TableRow>

                        {preview ? (
                            <TableRow>
                                <TableCell className="!border-0 !p-2 !flex">
                                    <Typography className={"!text-xs md:!text-sm lg:!text-base"} sx={{ color: '#413067' }}>
                                        İkon
                                    </Typography>
                                </TableCell>
                                <TableCell className="!border-0 !p-2">
                                    <TextField margin="dense" fullWidth select
                                        className="!mb-2"
                                        value={this.state.icon}
                                        sx={{
                                            borderTopRightRadius: '0 !important',
                                            borderBottomRightRadius: '0 !important',
                                            margin: 0,
                                        }}
                                        InputProps={{
                                            classes: {
                                                input: "h-8 sm:h-12 !text-xs md:!text-sm lg:!text-base",
                                                root: "h-8 sm:h-12",
                                            },
                                        }}
                                        onChange={(e) => this.setState({ icon: e.target.value })}
                                    >
                                        {icons.map((icon) => (
                                            <MenuItem key={icon.img} value={icon.img}>
                                                <Typography className="!text-xs md:!text-sm lg:!text-base">
                                                    {icon.title}
                                                </Typography>
                                            </MenuItem>
                                        ))}
                                    </TextField>

                                    {!this.state.icon || this.state.icon === '_' ?
                                        null :
                                        <img src={loadedResources[`${imgPath}/${this.state.icon}`]} alt={this.state.icon} className="w-8 h-8" />
                                    }
                                </TableCell>
                            </TableRow>
                        ) : null}
                    </Table>
                </DialogContent>

                <DialogActions>
                    <Button onClick={() => this.props.onClose()} variant="contained" color="error" className="!text-xs md:!text-sm lg:!text-base">
                        İptal
                    </Button>
                    {preview ? null : (
                        <Button onClick={this.onSave} variant="contained" color="primary"
                            className="!text-white !text-xs md:!text-sm lg:!text-base"
                            disabled={!this.state.username || this.state.saving}
                        >
                            Kaydet
                        </Button>
                    )}
                </DialogActions>
            </Dialog>
        );
    }
}

EditUserColor.propTypes = {
    classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
    return {
    }
};

export default withStyles(styles)(connect(mapStateToProps, {
    setNotification,
})(EditUserColor));
