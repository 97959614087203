import React from "react";
import { connect } from "react-redux";
import { withStyles } from "@mui/styles";
import PropTypes from "prop-types";
import { Dialog, DialogContent, DialogTitle, IconButton, Tooltip, Typography } from "@mui/material";
import { Box } from "@mui/system";
import {
    Close as CloseIcon,
} from '@mui/icons-material';
import clsx from "clsx";


const styles = theme => ({
    title: {
        fontWeight: '500 !important',
        color: 'var(--white)',
    },
});

class TermsOfUse extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        }

    }

    componentDidMount() {
    }

    componentDidUpdate(prevProps) {
    }

    render() {
        const { classes } = this.props;

        return (
            <Dialog open={this.props.open} onClose={e => this.props.onClose?.(e)}
                maxWidth="lg"
            >
                <DialogTitle>
                    <Box display={"flex"} justifyContent={"space-between"}>
                        <Typography className={clsx(classes.title, "!text-xs sm:!text-sm md:!text-base")} sx={{ color: '#413067' }}>
                            Kullanım Şartları
                        </Typography>

                        <Tooltip title="Kapat" placement="top">
                            <IconButton size="small" onClick={e => this.props.onClose?.(e)} sx={{ backgroundColor: '#624799 !important' }}>
                                <CloseIcon htmlColor="var(--white)" fontSize="small" />
                            </IconButton>
                        </Tooltip>
                    </Box>
                </DialogTitle>
                <DialogContent
                    sx={{ '& .p': { marginTop: '0px !important', marginBottom: '1rem !important' } }}
                >
                    <Box py={2} px={1}>
                        <p className="p !text-xs sm:!text-sm md:!text-base">DİKKAT! OYUN OYNAMAYA BAŞLADIĞINIZ VE/VEYA OYUNUN BİR BÖLÜMÜNÜ/PARÇASINI YÜKLEDİĞİNİZ, KOPYALADIĞINIZ, VE/VEYA OYUNU DİĞER BİR ŞEKİLDE KULLANDIĞINIZ TAKDİRDE OYUN KULLANICISI SÖZLEŞMESİNİN ŞARTLARINI SÜRESİZ OLARAK VE HİÇ BİR KISITLAMAYA BAĞLI OLMADAN KABUL ETTİĞİNİZİ BEYAN TEYİT EDİYORSUNUZ.</p>
                        <p className="p !text-xs sm:!text-sm md:!text-base">Vatandaş olduğunuz veya oturduğunuz ülkenin geçerli mevzuatı uyarınca bu oyun kumar oyun olarak algılandığı için ülkenizde yasaklı olması halinde ve/veya online müşteri hizmetlerinden faydalanmak yasak olduğu takdirde ve/veya ülkenizde konu ile ilgili diğer kanuni yasakların veya kısıtlamaların uygulanması halinde, bu oyunu ve/veya oyunda sunulan hizmetleri kullanma hakkınızın bulunmamasının yanısıra oyunu ve oyunda teklif edilen hizmetleri kullanmaktan bir an önce vazgeçeceğinizi kabul ederek beyan teyit ediyorsunuz.</p>

                        <p className="p !text-xs sm:!text-sm md:!text-base">Aşağıda bundan böyle 'Oyun sunucusu' olarak anılacak ‘KERİZİM.COM’ ve 'Oyun kullanıcısı' olarak anılacak gerçek kişi arasındaki ilişkiler 'Sözleşme' olarak anılacak bu Oyun Kullanıcısı Sözleşmesinde düzenlenmiş olup koordine edilmektedir.</p>
                        <p className="p !text-xs sm:!text-sm md:!text-base">1. Genel hükümler</p>
                        <p className="p !text-xs sm:!text-sm md:!text-base">1.1. İş bu Sözleşmenin şartları, Oyun sunucusunun KERİZİM.COM. ile imzalanmış sözleşmeye dayanarak (aşağıda 'Ortak' olarak anılacak) KERİZİM.COM web kaynaklarında yerleştirdiği (aşağıda bundan böyle 'Oyun' olarak anılacak) geçerli sayılmaktadır.</p>
                        <p className="p !text-xs sm:!text-sm md:!text-base">1.2. 'Oyun kuralları' bu Sözleşmenin ayrılamaz parçası olmakla beraber Oyun kullanıcısının oyuna başlayabilmesi için kendisine gerekli yazılım, programlar veya eklentilerin sağlanması sonucunda imzalanan 'Lisans sözleşmesi' iş bu Sözleşmenin ayrılamaz parçası sayılmaktadır. Hep birlikte söz konusu belgeler Oyun sunucusu ile Oyun kullanıcısı arasındaki ilişkileri düzenleyen Oyun Kullanıcısı Sözleşmesini oluşturmaktadır.</p>
                        <p className="p !text-xs sm:!text-sm md:!text-base">1.3. Oyun için bütün mülk haklarının aşağıda bundan böyle 'Oyun sunucusu' olarak anılacak Forbes Consult Limited' a ait olduğu anlaşılmaktadır. Oyun sunucusunun belirli ülkelerin geçerli mevzuatına dayanarak söz konusu ülkelerde oyunu uygulamaya ve kullanmaya özel hakları bulunmaktadır. Oyun kullanıcısı Oyun sunucusundan yazılı onay almadan oyunun bir bölümü, parça ve/veya içeriklerini oyun dışında kullanamamaktadır.</p>
                        <p className="p !text-xs sm:!text-sm md:!text-base">1.4. Oyun kullanıcısı, oyunda veya Sözleşmede diğer hususların öngörülmemesi şartıyla oyunda kullanılan kerekterler, üniteler, kahramanlar, aksesuarlar, oyun içi ödeme yöntemleri (oyun parası), grafik görüntüleri, resimler, fotoğraflar, animasyonlar, video görüntüleri, video clip, ses efektleri, ses kayıtları, müzik, metinler ve oyunun her çeşit diğer özellikleri dahil olmak üzere oyun için bütün mülk haklarının Oyun sunucusuna ait olduğunu açıkça anlayıp kabul etmektedir.Oyun kullanıcısı her hangi bir oyun ünitesi dahil olmak üzere her hangi bir oyun elementinin oyunun ayrılamaz parçası olduğunu, söz konusu parçaların oyunda belli fonksiyonlarının olduğunu anlayarak kabul etmektedir. Oyun kullanıcısı, kendisinin oyunda oyun kerekterlerini kullanma ve geliştirme hakkının bulunduğuna rağmen Oyun sunucusunun bu oyun kerekteri için sahip olduğu mülk haklarını Oyun kullanıcısına teslim ettiği, devrettiği ve/veya Oyun kullanıcısının oyun karekterinin tasarımcısı/sahibi olduğu veya Oyun sunucusu ile birlikte söz konusu karekterin tasarımcısı/sahibi olduğu anlamına gelmemektedir.Oyun kullanıcısı iş bu Sözleşmeyi kabul etmek için gerekli yetkilere sahip olduğunu garanti etmektedir. 18 yaşına girmeyen reşit olmayan Oyun kullanıcısı kendi annesi babasından, vasilerinden veya diğer yasal temsilcilerinden geçerli mevzuata bağlı olarak gerekli onay aldığını garanti etmektedir.Oyun kullanıcısı oyunu kullanmak için gerekli teknik araçları ve yazılım ile ilgili bilgilerin kendisine önceden açıklanıp anlatıldığını beyan etmektedir. Oyun kullanıcısı söz konusu talepler hakkında bilgili olduğunu ve oyun oynamak için gerekli teknik araçlarının ve yazılımın kendisinde mevcut olduğunu teyit beyan etmektedir.</p>
                        <p className="p !text-xs sm:!text-sm md:!text-base">1.5. İş bu Sözleşmede kullanılan terimler:
                            Oyun — PC programı sayılan ve bilgisayarda oynanabilen çevrimiçi online oyun anlamına gelmektedir. Oyun, web sitesi kaynaklarında bulunmaktadır.Oyun kullanıcıları internet bağlantısı üzerinden çevrimiçi (online) rejiminden web tarayıcı üzerinden ve kendi bilgisayarlarına Oyun kullanıcısı veri tabanını yükleme (zorunlu bir şart değil) yoluyla oyuna girerek oyunu oynayabilmektedir.
                            Oyun kullanıcısı — oyunu oynayan, oyunda teklif edilen hizmet ve ek servislerden faydalayabilen gerçek kişi anlamına gelmektedir.Hizmetler — Oyun sunucusu tarafından Oyun kullanıcısına oyuna girme haklarının verilmesi, iş bu Sözleşme şartları kapsamında oyunda verilen imkan ile servislerin kullanılması ve oyun sürecinin desteklenmesi anlamına gelmektedir. Oyun sunucusu Oyun kullanıcısının sadece oyunu oynadığı süresince kendisine ek servisleri sağlamaktadır.
                            Ek servisler — iş bu Sözleşme şartları kapsamında oyunda kullanılabilen özel imkanların Oyun kullancısına sağlanması anlamına gelmektedir. Oyun kullanıcısının oyunu oynayabilmesi için Ek servesleri satın almak zorunlu bir şart olmamakla birlikte standart hizmetler dışında bulunan ve oyunda kullanılabilen özel imkanlara sahip olabilmesi için teklif edilmektedir. Söz konusu Ek servisler Oyun kullanıcısının isteği üzerine kendisine sağlanmaktadır.Ek servisler Oyun kullanıcısının oyunda ve/veya web tarayıcı satın aldığı ödeme yönteminden ve Oyun kullanıcısının oyun oynadığı süre içerisinde kendisine teklif edilen ve sadece oyunda kullanılabilen kıymetli kalemlerden oluşmaktadır.
                            Oyun sunucusu, Oyun kullanıcısının oyun içi ödeme yöntemleri dahil satın aldığı hizmet ile ek servisler sayesinde kazandığı oyun kıymetlilerini nakit veya nakit olmayan paraya bozdurmakla uğraşmıyor. Oyun sunucusu, kullanıcının oyunda verilen ek hizmetleri için gördüğü masraflar için sorumluluğu taşımıyor ve söz konusu masrafları kullanıcıya tazmin etmemektedir.
                            Oyun kullanıcısı veri tabanı – Çoğu zaman Oyun kullanıcısının oyunu oynayabilmesi için gerekli olan ve Oyun sunucusu tarafından sağlanan yazılım/programlar anlamına gelmektedir. Oyun kullanıcısı bu programları kendi bilgisayarına tekbaşına kolayca yükleyebilir.Oyun kullanıcısı veri tabanı için özel mülk hakları sadece Oyun sunucuna aittir.
                            Oyun kuralları – Oyun kullanıcısının oyunda hareketlerini ve oyun üyeliğini koordine eden Oyun Kullanıcısı Sözleşmesinin Ek Sözleşmesi anlamına gelmektedir. Oyun kullanıcısının oyun oynayabilmesi ve hizmet ile ek servisleri kullanabilmesi için kendisinin Oyun kurallarını takip etmesi zorunlu bir şarttır. Oyun kuralları Oyun kullanıcısına ön haber verilmeden Oyun sunucusu tarafından değiştirilebilmektedir. Oyun sunucusu oyun web sayfasında ve/veya web tarayıcı bilgi yayınlama yoluyla oyun kurallarında yapılmış değişikliklerini Oyun kullanıcısına bildirmektedir. Oyun kullanıcısı oyun kurallarında değişiklikler yapıldıktan sonra oynamaya devam ettiği takdirde söz konusu değişiklikleri kabul etmiş demektir.</p>
                        <h5>2. Sözleşmenin konusu</h5>
                        <p className="p !text-xs sm:!text-sm md:!text-base">2.1. Oyun sunucusu oyuna girme ve oynama hakkını, iş bu Sözleşme şartları gereğince oyunda verilen hizmetleri ve ek servislerini kullanmayı sınırsız kullanıcı çevresine teklif etmektedir.Oyuna girme hakkını almak isteyen gerçek kişi iş bu Sözleşme şartlarını kabul ettiği andan itibaren Oyun kullanıcısı olup iş bu Sözleşmede öngörülen hak ile taahhütleri kendisine üstlemektedir.</p>
                        <h5>2.2 Oyun sunucusunun yükümlülükleri</h5>

                        <p className="p !text-xs sm:!text-sm md:!text-base">2.2.1. Oyun sunucusu iş bu Sözleşme şartları uyarınca oyun oynama imkanını, oyunun çalışmasını, kendisinin verdiği hizmet ile ek servislerini kullanma imkanını Oyun kullanıcısına sağlayacaktır.</p>
                        <p className="p !text-xs sm:!text-sm md:!text-base">2.2.2. Oyun sunucusu oyunda çeteleşme sistemi kullanıldığı takdirde Oyun kullanıcısına bunu kullanma imkanını sağlayacaktır.</p>
                        <p className="p !text-xs sm:!text-sm md:!text-base">2.2.3. Oyun sunucusu iş bu Sözleşmenin şartlarının değiştirilmesi halinde söz konusu değişiklikleri oyun web sayfasında ve/veya web tarayıcı yayınlayıp bunları Oyun kullanıcısına bildirecektir.</p>
                        <p className="p !text-xs sm:!text-sm md:!text-base">2.2.4. Oyun sunucusu, kullanıcının bu oyunu oynayabilmesi için kullanıcının bilgisayarına Oyun kullanıcısı veri tabanının yüklenmesi gerekli olduğu durumlarda kendi oyun web sayfasından, Ortakların web sayfalarından veya diğer kaynaklardan Oyun kullanıcısı veri tabanını yükleme imkanını Oyun kullanıcısına sağlayacaktır.</p>
                        <h5>2.3. Oyun sunucusunun hakları</h5>
                        <p className="p !text-xs sm:!text-sm md:!text-base">2.3.1. Oyun sunucusu oyunda teknik işlerin yapılması dahilinde her hangi bir zaman sağladığı hizmetleri veya ek servisleri Oyun kullanıcısına ön uyarı vermeden kısıtlayabilir, genişletebilir, değiştirebilir veya bunların sağlanmasını durdurabilir.</p>
                        <p className="p !text-xs sm:!text-sm md:!text-base">2.3.2. Oyun sunucusu oyunu yönetebilmekle beraber oyun süreçlerini istediğine göre değiştirebilmektedir. Oyun kullanıcısına uyarı vermeden oyun sürecini veya oyun şartlarını istediği zaman değiştirebilmektedir.</p>
                        <p className="p !text-xs sm:!text-sm md:!text-base">2.3.3. Oyun sunucusu kendi web kaynaklarında Oyun kullanıcısının yerleştirdiği her hangi bir bilgi değiştirebilir veya bunları silebilir.</p>
                        <p className="p !text-xs sm:!text-sm md:!text-base">2.3.4. Oyun sunucusu, Oyun kullanıcısının iş bu Sözleşmenin şartlarını ihlal etmesi veya Oyun sunucusunun Oyun kullanıcısının oyun verilerini doğru şekilde kullanmaması ile ilgili şüphelerinin bulunması dahilinde iş bu Sözleşme şartları gereğince Oyun kullanıcısına verilen hizmet ile ek servislerin sağlanmasını her an kısıtlayabilir ve/veya durdurabilir. Ayrıca, Oyun sunucusu, Oyun kullanıcısının Sözleşme şartlarını ihlal ettiğinde ona ilgili uyarı verebilir, oyunda veya Oyun sunucusunun web kaynaklarında Oyun kullanıcısının ismini belirterek kendisine yönelik uyarıları yayımlayabilir ve/veya Oyun kullanıcısının oyuna girme hakkını geçici olarak veya tamamen iptal ederek bu Sözleşemeyi tek taraflı olarak feshedebilir. Sözleşmenin feshedilmesi ile ilgili bildiri Oyun kullanıcısının oyunda belirttiği e-mail adresine Oyun sunucusunun bilgi@kerizim.com mail adresinden yollanacaktır. Oyun kullanıcısı uzun süre içerisinde oyun oynamadığı takdirde Oyun sunucusu onun profilini silebilmektedir.</p>
                        <p className="p !text-xs sm:!text-sm md:!text-base">2.3.5. Oyun sunucusu Oyun ile ilgili teknik veya yeni haber konulu mesajları (bundan böyle 'mesajlar' olarak anılacak) gerek kendi araçlarıyla gerekse üçüncü kişilere ait araçların yardımıyla Oyun kullanıcılarına gönderebilmektedir.Oyun kullanıcısı böyle mesajların kendisine gönderilebileceğini ve bunların spam olmadığını kabul etmektedir.Oyun kullanıcısı iş bu Sözleşmenin şartlarını kabul etmekle Oyun sunucusunun söz konusu mesajları kendisine göndermesini istediğini teyit etmiş sayılmaktadır.Oyun kullanıcısı iş bu Sözleşmenin 2.5.1. Maddesinde öngörülmüş düzene göre söz konusu mesajların gönderilmesini durdurabilmektedir.</p>
                        <p className="p !text-xs sm:!text-sm md:!text-base">2.3.6. Oyun sunucusu, oyunda Oyun kullanıcılarının oyun kurallarını veya iş bu Sözleşmenin şartlarını ihlal etmeleri ile ilgili olarak bunlara uyarıları verebilir. Oyun kullanıcısı, Oyun sunucusunun verdiği uyarıları dikkate alıp yerine getirmeyi taahhüt etmektedir.</p>
                        <p className="p !text-xs sm:!text-sm md:!text-base">2.3.7. Oyun sunucusu, Oyun kullanıcısı veri tabanı dahil olmak üzere oyunu veya bunun her hangi bir parçasını Oyun kullanıcısına ön uyarı vermeden değiştirebilir, geliştirebilir ve/veya yenilebilir. Bundan dolayı Oyun kullanıcısı sadece cari oyun versiyonunu kullanmaktadır.</p>
                        <p className="p !text-xs sm:!text-sm md:!text-base">2.3.8. Oyun sunucusu, oyun ile ilgili kendi haklarını savunmak amacıyla Kıbrıs Cumhuriyetinin geçerli mevzuatına ve/veya Uluslar arası kanunlara göre ilgili yöntemleri kullanabilmektedir.</p>
                        <p className="p !text-xs sm:!text-sm md:!text-base">2.3.10. Oyun sunucusu, Oyun kullanıcısına verilen hizmet veya ek servislerin sağlanmasının kısıtlanması, bunların kısmen veya tamamen durdurulması halinde Oyun kullanıcısının profilinin kilitlenmesini kaldırıp söz konusu hizmetleri ve/veya ek servisleri yeniden sağlamaya başlayabilir.
                        </p>
                        <h5>2.4. Oyun sunucusunun taahhütlerinin sınırlanması</h5>
                        <p className="p !text-xs sm:!text-sm md:!text-base">2.4.1. Oyun kullanıcısı, Kerizim kaynaklarını. oyunu, Oyun sunucusunun web kaynaklarını ve Oyun kullanıcısı veri tabanını kendi riskine göre kullanmaktadır. Oyun kullancısı kendi bilgisayarın teknik durumunun ve üzerindeki programların birbiriyle bağdaşabilmesi için sorumluluğu tekbaşına taşımaktadır.
                        </p>
                        <p className="p !text-xs sm:!text-sm md:!text-base">2.4.2. Oyun sunucusu Oyun kullanıcısının mevzuata aykırı yaptığı hareketleri için sorumluluğu taşımamaktadır.
                        </p>
                        <p className="p !text-xs sm:!text-sm md:!text-base">2.4.3. Oyun sunucusu web tarayıcısında Oyun kullanıcısının yayınladığı bildiriler için sorumluluğu taşımamaktadır. Oyun sunucusu, Oyun kullanıcısının kerizim web kaynaklarında gösterdiği davranış, Oyun kullanıcısının yönettiği oyun karekterlerinin hareketleri veya ideolojisi, diğer kullanıcılara karşı gösterdiği saygısızlık için sorumluluğu taşımamaktadır.
                        </p>
                        <p className="p !text-xs sm:!text-sm md:!text-base">2.4.4. Oyun sunucusu, Oyun kullanıcısının kendi profiline girememesi için (login, şifre veya kullanıcının oyunu oynayabilmesi için gerekli diğer özel bilgilerin unutması için) sorumluluğu taşımamaktadır.
                        </p>
                        <p className="p !text-xs sm:!text-sm md:!text-base">2.4.5. Oyun sunucusu, Oyun kullanıcısı kendi profilini kurduğunda yanlış veya tam olmayan bilgileri girmesi için sorumluluğu taşımamaktadır.
                        </p>
                        <p className="p !text-xs sm:!text-sm md:!text-base">2.4.6. Oyun sunucusu, Oyun kullanıcısının verilen hizmet ile ek servisleri kullanması sonucunda kazandığı oyun kıymetlilerini kaybetmesi için sorumluluğu taşımamaktadır.
                        </p>
                        <p className="p !text-xs sm:!text-sm md:!text-base">2.4.7. Oyun sunucusu, Oyun kullanıcısının kullandığı internet bağlantısı, trafik ve internet kalitesi için sorumluluğu taşımamaktadır.
                        </p>
                        <p className="p !text-xs sm:!text-sm md:!text-base">2.4.8. Oyun sunucusu Oyun kullanıcısının veya diğer üçüncü kişilerin zarar gördüğü aşağıdaki durumlarda sorumluluğu taşımamaktadır:
                            - Oyun kullanıcılarının Kerizim'e ait web kaynaklarınını kullanması veya kullanmaması sonucunda zarar gördükleri durumlarda;
                            - Oyun kullanıcısının profilinin, hesabının veya diğer özel bilgilerin üçüncü kişiler tarafından kullanılması sonucunda zarar gördüğü durumlarda;
                            - Kerizim'e ait web kaynaklarında üçüncü kişilerin hareketleri veya bildirileri sonucunda Oyun kullanıcısının zarar gördüğü durumlarda;

                        </p>
                        <p className="p !text-xs sm:!text-sm md:!text-base">2.4.9. Oyun sunucusu aşağıdaki hususları garanti etmemektedir:
                            - Oyunun Oyun kullanıcısı için ilginç olmasını;
                            - Kerizim web kaynaklarında Oyun sürecinin, hizmet ve ek servislerin verilmesinin hızlı olduğunu ve bunların verilmesi sırasında ortaya çıkabilen
                            hataların bulunmadığını;
                            - Oyunda verilen hizmet ile ek servislerin kullanılmasıyla kazanılan avantajlarda hataların bulunmadığını;
                            - Oyun sürecinin, bir hizmetin veya ek servisin, Oyun sunucusunun web kaynaklarında verilen oyun servisleri sayesinde kazanılan bilgilerin veya kalitesinin Oyun kullanıcılarının taleplerine uygun olduğunu; </p>
                        <p className="p !text-xs sm:!text-sm md:!text-base">2.4.10. Oyun sunucusu, Oyun kullanıcısının oyun kurallarını ve Sözleşme şartlarını ihlal etmesi sonucunda Oyun kullanıcısına hizmet ile ek servislerin verilmesinin durdurulması veya kısıtlanması ile ilgili kanıtları ihlalcı kullanıcılara bildirmek zorunlu bulunmamaktadır.
                        </p>
                        <p className="p !text-xs sm:!text-sm md:!text-base">2.4.11. Diğer durumlarda Oyun sunucusunun taşıdığı sorumluluk, Oyun kullanıcısının oyun hizmetleri ile ek servisleri için ödediği para tutarları ile sınırlı olmaktadır.
                        </p>
                        <p className="p !text-xs sm:!text-sm md:!text-base">2.5. Oyun kullanıcısının hakları ve yükümlülükleri
                        </p>
                        <p className="p !text-xs sm:!text-sm md:!text-base">2.5.1. Oyun kullanıcısı iş bu Sözleşmenin şartlarını takip ederken aşağıdaki haklara sahip olmaktadır:
                            - Oyun ile oyun web sayfası dahil olmak üzere Kerizim web kaynaklarına giriş ve kullanma hakkı;
                            - Oyunu oynayabilmek için Kerizim web kaynaklarından veya iş bu Sözleşmede belirtilmiş bulunan diğer web kaynaklarından Oyun kullanıcısı veri tabanını ücretsiz olarak yükleme hakkı;
                            - İş bu Sözleşmede öngörülen şartlara göre ayrı ödemeyi yaparak ek servisleri alma hakkı;
                            - Oyun web sayfasında düzenlenen Forum bölümünde veya oyun mesaj sistemini kullanarak diğer oyun kullanıcılarıyla yazışma ve bilgi gönderme hakkı;
                            - Oyun sunucusu tarafından düzenlenen kampanyalara katılma hakkı;
                            - İş bu Sözleşmeyi kabul etmekle ilgili olarak Oyun sunucusuna başvurma hakkı;
                            - Oyun kullanıcısı Oyun sunucusundan gelen mesajların gönderilmesinden vazgeçebilir. Bunu yapmak için söz konusu mesajda belirtilen link üzerine tıklayıp 'Mesajlardan vazgeç' tuşuna basıp mesajların gönderilmesinden vazgeçebilir.
                        </p>
                        <p className="p !text-xs sm:!text-sm md:!text-base">2.5.2. Oyun kullanıcısının taahhütleri:
                            - Oyun kullanıcısı, oyun web sayfasında, oyunda, forumda, çeteleşme sayfasında, haber bölümünde, kullanıcılara yardım bölümünde oyun web sayfasında kullanılan dilde yazışmaya taahhütlüdür.
                            - İş bu Sözleşmenin şartları, Lisans Sözleşmesinin şartları ve Oyun kuralları takip edilecektir;
                            - Oyun kullanıcısı Kerizim web kaynaklarında kayıt formunu doldururken doğru bilgi girmek zorundadır;
                            - Mail adresi dahil olmak üzere Oyun kullanıcısı ile ilgili her hangi bir bilginin değişmesi durumunda söz konusu değişiklikler Oyun sunucusuna bildirilecektir;
                            - Oyun sunucusunun oyun ve/veya oyun parçaları ve/veya Kerizim web kaynakları ile ilgili fikri hakları bozulmayacaktır;
                            - Oyun kullanıcısı, oyunda kullandığı profilin güvenliğini veya şifrenin saklı olmasını sağlayan tedbirleri uygulayabilir. Oyun kullanıcısının, üçüncü kişilerin söz konusu bilgileri elde etmeleri ile ilgili şüphesi bulunduğu takdirde bunu hemen Oyun sunucusuna bildirmekle beraber söz konusu bilgi tek başına değiştirebilir veya Oyun sunucusunun bunları değiştirmesini isteyebilir;
                            - Oyun kullanıcısı, Oyun sunucusunun oyunda, kullanıcı grubunda, teknik destek bölümünde, oyun web sayfasındaki haber bölümünde veya Oyun kullanıcısının metin yazabildiği ve fotograf ile resimleri yükleyebildiği bölümde verdiği talimatları takip edecektir. Oyun kullanıcısı, Oyun sunucusunun verdiği talimatları yerine getirmediği takdirde Oyun sunucusu böyle kullanıcıya verilen hizmet ile ek servislerin sağlanmasını durdurabilir;
                            - Oyun kullanıcısı, Oyun sunucusunun isteği üzerine kendi ismi, soyismi, baba adı gibi özel bilgileri bildirmek zorundadır;
                            - Oyun sunucusunun isteği üzerine Oyun kullanıcısı girdiği verilerin gerçek olduğunu doğrulamak zorundadır;
                            - Oyun kullanıcısı iş bu Sözleşmeyi kabul etmek için gerekli yetkilerinin mevcut olduğunu garanti etmektedir. Oyun kullanıcısı reşit olmadığı takdirde geçerli mevzuat gereğince kendi annesi, babasından veya yasal temsilcilerinden oyunu oynayabilmesi için onay alacaktır;
                            Oyun sunucusunun diğer hakları ve Oyun kullanıcısının diğer taahhütleri Oyun kurallarında, Lisans Sözleşmesinde ve iş bu Sözleşmenin 'Ücretli Ek hizmetler' isimli 2.6 maddesinde öngörülmüştür.
                        </p>
                        <h5>2.6. Diğer servisler.
                        </h5>
                        <p className="p !text-xs sm:!text-sm md:!text-base">2.6.1. Oyun sunucusu, Oyun kullanıcısının isteği üzerine kendisine daha gelişmiş özel oyun imkanlarını sağlayan diğer servisleri verebilmektedir.
                        </p>

                        <p className="p !text-xs sm:!text-sm md:!text-base">2.6.2. Oyun kullanıcısı, Kerizim web kaynaklarında belirtilen ödeme yöntemleri yardımıyla oyunda bir ek servisi ödediği andan itibaren söz konusu ek servisi kullanmaya başlayabilmektedir. Oyun kullanıcısının bir servis kullanabilmesi için ödediği para geri verilmez.</p>

                        <p className="p !text-xs sm:!text-sm md:!text-base">2.6.3. Oyun kullanıcısı ek servisleri kullanmaya karar verdiği takdirde Oyun sunucusunun oyunda belirli profile sahip olan gerçek kişiyi gerektiğinde tanımlayabilmesi için isim, soyisim, baba adı, doğum tarihi ve diğer benzeri özel bilgileri Oyun kullanıcısı hakkındaki bilgiler bölümünde girecektir. Oyun kullanıcısı kendisine ait özel bilgilerin tam, gerçek ve doğru yazılması için sorumlu olmaktadır.</p>

                        <p className="p !text-xs sm:!text-sm md:!text-base">2.6.4. Ek servislerin listesi ve bunların bedeli Kerizim web kaynaklarında sadece Ortak tarafından yayınlanabilmektedir.</p>

                        <p className="p !text-xs sm:!text-sm md:!text-base">2.6.5. Para tutarları Ortak tarafının hesabına geldiği an itibariyle ek servisi Oyun kullanıcısı tarafından ödenmiş sayılmaktadır. Oyun kullanıcısının ödediği para tutarları Ortak tarafının hesabına geldikten sonra söz konusu para tutarları oyun parasına dönüştürülecektir. Para tutarların oyun parasına bozdurulması Ortak tarafının kendi web kaynaklarında belirttiği döviz kuruna göre gerçekleştirilmektedir. Satın alınmış oyun parası Kerizim web sayfasında kurulan Oyun kullanıcısı oyun hesabına Ortak tarafından aktarılacaktır.</p>

                        <p className="p !text-xs sm:!text-sm md:!text-base">2.6.6. Oyun parasını (oyun içi ödeme yöntemleri) satın alan kullanıcı ödeme koşulları ile kurallarını içeren ödeme talimatına uymak zorundadır. Kullanıcı ödeme yapmak amacıyla gönderdiği kısa mesajlarda (SMS) büyük ve küçük harflerin, rakamların yazılması ve yazma dilinin doğru olmasının sorumluluğundadır. Kullanıcının ödeme talimatlarını ve koşullarını uygulaması halinde oyun parası kullanıcının oyun hesabına tahsil edilecektir. Ödeme sunucunun kontrol altında bulunmadığından dolayı sunucu kullanıcının ek servisleri ödemesinin doğru olmasından mesul değildir. Kullanıcı, oyun parasını satın almak için kullanılan ödeme sistemlerinin kuralları ve koşulları ile ilgili sorularını Ortak tarafa yönlendirecektir. Oyun sunucusu, kullanıcılara Kerizim ödeme sistemlerinin talimatları ile ilgili açıklamaları vermiyor ve çalışma yöntemlerini açıklamıyor. Kullanıcının oyun hizmetlerini satın aldığında Kerizim ödeme talimatlarını ihlal etmesi sonucunda ve para tutarları Ortak tarafa gelmemesi halinde kullanıcının gördüğü zarar oyun sunucusu tarafından iade edilmez.</p>

                        <p className="p !text-xs sm:!text-sm md:!text-base">2.6.7. Teknik hata, oyundaki kusurlar veya kullanıcının amaçlı eylemleri sonucunda kullanıcı ödeme yapmadan ve/veya kısmen ödemeyi yapıp, ve/veya kullanıcının oyun hesabından para çekilmeden kullanıcının ek servisleri elde ettiği takdirde Ortak tarafın kullanıcının yapacağı sonraki herhangi amaçlı ödemelerinden gerekli tutarı çekme hakkı saklıdır.</p>

                        <p className="p !text-xs sm:!text-sm md:!text-base">2.6.8. Kullanıcı, oyun süresiyle yaptığı ödemeleri ispatlayan belgeleri saklamakla yükümlüdür. Sunucu, gerekli görülmesi halinde kullanıcıdan söz konusu belgeleri talep edebilir.</p>

                        <p className="p !text-xs sm:!text-sm md:!text-base">2.6.9. Kullanıcı hesabının durumunu kendi kontrol eder.</p>

                        <p className="p !text-xs sm:!text-sm md:!text-base">2.6.10. Ücretli hizmetler yalnızca oyun sunucusu tarafından sağlanmaktadır. Oyun hizmetlerinin ve ek servislerinin ödenmesi ile ilgili tartışılan, karışık durumlar ortaya çıktığı ya da üçüncü taraf kullanıcıya oyun ile ilgili teklifleri gönderdiği takdirde; kullanıcı bu olaylarla karşılaşınca bunları bir an önce oyun sunucusuna bildirmelidir. Oyun kullanıcısı, bu kurallara uymadan söz konusu teklifte belirtilen verileri (adres detayları ve/veya şifreler dahil) kullanıp ödeme yaptığı takdirde oyun sunucusu ve/veya Ortak tarafından yapılan tüm ödemeleri reddetme hakkı saklıdır.</p>

                        <p className="p !text-xs sm:!text-sm md:!text-base">2.6.11. Oyun kullanıcısı ücretli hizmetleri kullanmayı reddederse, kullanıcının oyun içi hizmetlerine erişimi sınırlanabilir veya tamamen engellenebilir.</p>

                        <p className="p !text-xs sm:!text-sm md:!text-base">2.6.12. Kullanıcı, Kıbrıs Cumhuriyetinin mevzuatına ve /veya kullanıcının ikamet yerinin yasalarını ihlal etmeden oyun ek servislerini ödemek için seçtiği ödeme sistemlerini kullanma hakkı olduğunu oyun sunucusuna garanti eder. Sunucu, kullanıcının kendine ait olmayan ödeme yöntemlerini kullandığı sonucunda üçüncü şahıslara ve/veya diğer Oyun kullanıcılarına verilebilen zarardan sorumlu veya yükümlü tutulmaz.
                        </p>
                        <p className="p !text-xs sm:!text-sm md:!text-base">2.6.13. Oyun sunucusu, kullanıcının yasadışı ya da dolandırıcılık eylemlerini yapmasından şüphelendiği takdirde; söz konusu eylemleri açıklığa kavuşana ve/veya kullanıcının ek servisleri ödemesinin cep telefonu dolandırıcılığı (sonra Fraud olarak anılacak) veya kredi kartı ile ilgili dolandırıcılık olması halinde oyun hizmetlerini tamamen ya da kısmen kısatlayabilir veya durdurabilir.
                        </p>
                        <p className="p !text-xs sm:!text-sm md:!text-base">2.6.14. Oyun sunucusu, kullanıcının oyunun ek servislerini ödemesi ile ilgili yasadışı veya dolandırıcılık eylemlerini yaptığından şüphelendiği takdirde oyun sunucusunun bu olayı araştırması amacıyla kullanıcı ile ilgili kişisel bilgileri yetkili makamlara verme hakkına sahiptir.
                        </p>
                        <p className="p !text-xs sm:!text-sm md:!text-base">2.6.15. Kullanıcı, oyun parasını kredi kartı aracılığıyla ödediğinde Kıbrıs Cumhuriyetinin mevzuatına ve/veya kullanıcının ikamet yerinin yasalarına uymak zorundadır.
                        </p>
                        <p className="p !text-xs sm:!text-sm md:!text-base">2.6.16. Oyun kullanıcısı, MyLands oyununun kumar, para amaçlı oyun, kampanya, bahis olmadığını, ek servisleri satın alması oyuna katılmak için gerekli şart olmadan kullanıcının niyeti ve isteği olduğunu anlayıp kabul eder.
                        </p>
                        <h5>2.7. Son hükümler
                        </h5>
                        <p className="p !text-xs sm:!text-sm md:!text-base">2.7.1. Kullanıcı, ikametli/uyruklu olduğu ve/veya bulunduğu mevzuatına göre oyuna katılması için sorumludur.
                        </p>
                        <p className="p !text-xs sm:!text-sm md:!text-base">2.7.2. Sunucu, kullanıcıyı önceden bilgilendirmeden işbu sözleşmeyi değiştirebilir. Oyun Kullanıcısı Sözleşmesinde Oyun sunucusu tarafından yapılan değişiklikler, oyunda ve/veya Kerizim web sitesinde yayınlandıktan bir gün sonra yürürlüğe girer. Oyun kullanıcısı işbu sözleşmede yapılan değişiklikleri kendi takip etmekle yükümlüdür. Kullanıcının işbu sözleşmenin talimatlarını ve/veya değişikliklerini öğrenmemesi, kullanıcının kendi yükümlülüklerini ve işbu sözleşmenin kısatlamalarını yerine getirmemesine sebep olamaz.
                        </p>
                        <p className="p !text-xs sm:!text-sm md:!text-base">2.7.3. İşbu Sözleşmenin konusu olduğu bir dava neticesinde yetkili mahkeme tarafından, işbu sözleşmenin herhangi bir hüküm veya kısmen hükümsüzlüğüne karar verilmesi halinde diğer hüküm ve kısımlar geçerli olur ve yürürlükte kalır. Mahkeme tarafından verilen karara göre işbu sözleşmenin birkaç maddelerinin geçersiz olması halinde Taraflar, kabul etmiş olduğu işbu sözleşmenin maddelerine ve/veya anlaşılan değişikliklerine yakın yükümlüklülerini gerçekleştirecekler.
                        </p>
                        <p className="p !text-xs sm:!text-sm md:!text-base">2.7.4. Oyun sunucusu, Kıbrıs Cumhuriyetinin mevzuatına göre kullanıcıların kişisel bilgileri ve verileri saklama hakkını saklı tutar. Kullanıcı, IP adresi, bağlantı şekilleri, bağlantı süreleri gibi bilgilerin ve kullanıcının kimliliğini tesbit eden diğer verilerin bilgisayar aracılığıyla sunucuya otomatik aktarılacağını ve sunucunun serverında saklayacağını kabul eder.
                        </p>
                        <p className="p !text-xs sm:!text-sm md:!text-base">2.7.5. İşbu sözleşmenin kurallarını kabul eden kullanıcı kendinin hukuki ehliyete ve fiil ehliyetine sahip olduğunu tasdik eder. Kullanıcı, oyuna katılması için kullandığı araçların yeterli ve kullanışlı olduğunu garanti eder.
                        </p>
                        <p className="p !text-xs sm:!text-sm md:!text-base">2.7.6. İş bu Sözleşmenin temelinde oyun sunucusunun ikametli olduğu ülkenin (Kıbrıs Cumhuriyeti) mevzuat ile hukuki normları bulunmaktadır. İş bu kamu sözleşmesi ve ek sözleşmeler bunlara göre düzenlenmiş olup koordine edilmektedir. Kullanıcı, ikamet ülkesinin mevzuatını ihlal edemez. Kullanıcı, söz konusu yasaları ihlal ettiği için sorumludur.
                        </p>
                        <p className="p !text-xs sm:!text-sm md:!text-base">2.7.7. Bu sözleşmede belirtmeyen kurallar ve koşullar oyun sunucusunun ikametli olduğu ülkenin geçerli mevzuatına göre düzenlenmektedir.
                        </p>
                        <p className="p !text-xs sm:!text-sm md:!text-base">2.7.8. İki tarafın işbu sözleşme üzerindeki tüm tartışmalar dava açılmadan yazışmalar ve görüşmeler yoluyla anlaşılır. Her iki tarafın görüşme yoluyla anlaşılmaya ulaşamadığı takdirde tarafların biri oyun sunucusu bulunduğu ülkede yer alan mahkemede dava açabilir.
                        </p>
                        <p className="p !text-xs sm:!text-sm md:!text-base">2.7.8. İki tarafın işbu sözleşme üzerindeki tüm tartışmalar dava açılmadan yazışmalar ve görüşmeler yoluyla anlaşılır. Her iki tarafın görüşme yoluyla anlaşılmaya ulaşamadığı takdirde tarafların biri oyun sunucusu bulunduğu ülkede yer alan mahkemede dava açabilir.
                            <br /><br />
                            Oyun sunucusu ile oyun kullanıcısı arasında imzalanmış Kullanıcı Sözleşmesinin 'Oyun Kuralları' isimli Ek Sözleşmesi
                            <br /><br />
                            İş bu Ek Sözleşme Kullanıcı Sözleşmesinin ayrılmaz parçası olup kullanıcının oyunda hareketleri ve davranmasını koordine ediyor, kullancının oyunda davranışını ve oyun kurallarının bozulması için sorumluluğunu ve oyun sunucusunun ceza verme hakkını ve ceza yaptırımlarını belirtiyor.Kullanıcının oyun oynayabilmesi ve oyun sunucusundan hizmet alabilmesi için oyun kurallarını takip etmek zorunlu bir şarttır. Oyun kuralları gerek oyun ve Kerizim web kaynaklarında için gerekse forumlar için geçerlidir.
                            <br /><br />
                            Oyun kuralları her oyuncunun oyundan maksimum zevk alabilmesi için düzenlenmiştir. Bir oyuncu oyun kurallarını bozduğu takdirde bunun hesabı kendisine bir telafi sağlanmadan süreli veya süresiz engellenebilir.
                            <br /><br />
                            1. Oyun hesabının açılması
                            <br /><br />
                            1. Kullanıcının oyuna katılması ve sunucu tarafından verilen diğer hizmetlerden faydalanması için kullanıcının oyun hesabını açması şarttır.
                            <br /><br />
                            2. Oyunda yalnızca özel kişi kaydolabilir. Oyunun resmi kullanıcıları olarak yalnızca özel kişi kabul edilir (grup, aile, çift v.s. oyun resmi kullanıcısı olamaz). Reşit olmayan kullanıcı yalnızca kayıttan önce yasal temsilcisinin oyunu oynaması için onay vermesi halinde katılma hakkına sahiptir.
                            <br /><br />
                            3. Kullanıcı kaydolurken oyunda kullanacağı adını ve geçerli bir e-posta adresini kullaması gerekir. Kullanıcının oyun adı üçüncü şahısların haklarını ihlal etmemeli ve genel ahlaki değerlere uygun olmalıdır. E-posta adresi ve/veya web sitesi kaynağın adı kullanıcının oyun adı olarak kullanılmaz. Kullanıcı Oyunun her serverinde yalnızca bir aktif hesaba sahip olabilir. Kullanıcı, Oyunda hesabı açtığı zaman dil alanına göre dili kullanmalı.
                            <br /><br />
                            4. Kullanıcı oyunda başarılı kayıtlı olduktan sonra kendi profilini istediği gibi kullanabilir.
                            <br /><br />
                            5. Oyun kurallarına göre kullanıcılar için bir istisna yapılmadığı takdirde kullanıcı oyuna girmek için diğer kullanıcının verilerini kullanma hakkına sahip değildir.
                            <br /><br />
                            6. Kullanıcı yalnızca bir oyun hesabını oluşturabilir. Birden fazla hesaba sahip olan kullanıcının hesabı sunucunun kararına bağlı olarak anında dondurulabilir ya da silinebilir.
                            <br /><br />
                            2. Genel Hükümler
                            <br /><br />
                            1. Oyun profilinin (hesap) sahibi olan kullanıcı oyuna katılması amacıyla kullandığı şifreyi diğer kullanıcılara devredemez ve kullandıramaz. Oyun kullanıcısı olmayan gerçek kişi hariçtir. Şifresini diğer kişiye bildiren kullanıcı işbu sözleşmenin ve bunun eklerinin koşullarının yerine getirilmesi için sorumludur.
                            <br /><br />
                            2. Bir bilgisayar başında oyuna katılabilen kullanıcının maksimum sayısı 1'dir (birdir).
                            <br /><br />
                            3. Kullanıcının hesabının devredilmesi: kullanıcı oyun hesabını yalnızca kendi başarısına ulaşmak amacıyla kullanmalıdır. kullanıcının yabancı oyun hesabını kullanma hakkına sahip değildir. Bir bilgisayarı ortakça kullanılan oyun kullanıcıları, temsilci ve temsil edilen kullanıcılar arasında oyundaki kaynaklarının paylaşılması yasaktır.
                            <br /><br />
                            4. Kullanıcı, sunucunun sunduğu çeşitli imkanlar ve hizmetler aracılığıyla sunucunun web sitelerinde kendi bilgelerini yayınlayabilir. Bu hizmetlere tartışma forumları, e-posta, içi sohbet, bloglar, misafir kitapları, yorumları dahil ancak bunlara sınırlı değildir. Bu durumda sunucu bilgilerin paylaşılması amaçlı teknik araçları sağlamaktadır. Kullanıcı söz konusu web sitelerinde yayınladığı bilgiler için sorumludur ve üçüncü şahısların sunucuya karşı açabileceği davadan sunucuyu korumakla yükümlüdür. Sunucu, kullanıcının yayınladığı bilgilerden sorumlu tutulmaz ve söz konusu bilgileri kullanma hakkına sahiptir. Sunucu, yayınlanan bilgileri kontrol etmek için etkili tarama sistemini kullanmadığına rağmen seçkin bilgileri kontrol ettiğini bildirir. Her kullanıcı yayınlanan bilgilerin yasadışı olduğuna dair sağlam temelli bir şüphesi olması halinde bunu sunucuya bildirebilir.
                            <br /><br />
                            3. Yasaklanan Hareketler
                            <br /><br />
                            1. Kullanıcı, işbu Kuralların, Lisans Sözleşmesinin, Oyun Kullanıcısı Sözleşmesinin kurallarına ve kısatlamalarına uymak zorundadır.
                            <br /><br />
                            2. Kullanıcı, diğer kullanıcıların oyuna katılma haklarına saygı göstererek diğer kullanıcıların haklarını ihlal edebilen ve/veya kısıtlayabilen durumları yaratma hakkına sahip değildir.
                            <br /><br />
                            3. Kullacının diğer kullanıcıların oyuna katılmasını, Oyun Yönetiminin kendi görevlerini yerine getirmesini zorlaştıran ya da engelleyen amaçlı eylemleri, diğer kullanıcılar için oyun öngörmediği engelleri yaratması kesinlikle yasaktır. Kullanıcının oyun programını çözmesi veya çözmeye çalışması; ve/veya servera ya da serverdan aktarılan verileri çalması; üçüncü şahıslar tarafından sunulan, oyun sonuçlarını değiştirebilen yardımcı araçları ve programları kullanması; diğer kullanıcıların verilerini çalan ya da oyun ile ilgili verilerin toparlanmasını engelleyen programları kullanması yasaktır. Söz konusu amaçlara ulaşılmaya yönlendirilen diğer eylemler de kullanım koşullarına aykırıdır. Diğer kullanıcıların oyun hesaplarına girmeyi sağlayan veriler amaçlı elde edilmesi ve yaylanması yasaktır. Kullanıcının söz konusu verileri içeren kaynakları yayınlaması; oyunda ve oyunun web sitesindeki diğer kullanıcıların hesaplarına girmesini sağlayan verileri kullanması yasaktır. Kullanıcının değiştirilmiş oyunun kısmen ve tamamen tadili, değiştirmesi, geri derlemesi, satışı, yayınlaması; programlama hatalarını kullanması, program yazılım kodlamasını değiştirmesi; oyun serverına ve oyun veri deposuna izinsiz ulaşımı yasaktır. Ayrıca, sunucu kullanıcının oyuna girmesini anında engelleme ve Kıbr/ıs Cumhuriyetinin yürürlükte olan Ceza kanununa veya kullanıcının ikamet yerinde yürürlükte olan benzer yasalara göre işbu koşulları ihlal eden kullanıcının eylemlerinde suç unsurunun olup olmadığını tesbit etmek amacıyla yetkili adli makamlara başvurma hakkı saklıdır.
                            <br /><br />
                            4. Kullanıcı, oyun sunucusunun iznini almadan oyunun herhangi iç unsuru, iç değeri, hizmeti, oyun parası ile aşağıdaki eylemleri yapmayacağını taahhüt eder: Oyunun hizmetlerini oyun dışında veya oyun dışı değeri, nakit para dahil, için satmayacağını; Oyun hizmetlerini oyun dışı değeri, nakit para dahil, için satın almayacağını. Kullanıcı veya herhangi bir üçüncü şahıs oyun kullanıcının yukarıdaki eylemleri yapmak niyetinde olduğunu ifşa etme hakkına sahip değildir.
                            <br /><br />
                            5. Kullanıcının, web arayüzünü kendiliğinden harekete geçirebilen “bot” adı verilen programların, oyun kullanıcısının eylemlerini emüle eden diğer programların, herhangi teknik ve/veya diğer araçların kullanımı yasaktır.
                            <br /><br />
                            6. Kullanıcının oyun iç ve programlama hatalarını kullanması yasaktır. Söz konusu hatalarla karşılaşan kullanıcı bunu oyun sunucusuna veya Ortak tarafa hemen bildirmek zorundadır.
                            <br /><br />
                            7. Aşağıdaki mesajları ve bilgileri dahil ancak bunlara sınırlı olamamak üzere sunucunun web sitesinde yayınlamak yasaktır:
                            <br /><br />
                            Üçüncü şahsın haklarına veya malvarlığına tecavüz teşkil eden;
                            <br /><br />
                            Zincir mesajları veya kartopu sistemini içeren;
                            <br /><br />
                            Oyun sunucusunun mesajını emüle eden;
                            <br /><br />
                            Diğer kullanıcıların ve/veya üçüncü şahısların kişisel ve gizli bilgilerini içeren;
                            <br /><br />
                            Reklam özelliğini taşıyan, kullanıcının hak sahibinin iznini almadan oyunda yayınlandığı diğer web sitelerin adreslerini içeren mesajlar ve bilgiler.
                            <br /><br />
                            Kullanıcının oyun sunucusu, diğer kullanıcılar, oyun hakkındaki iftira ve söylentileri, sunucunun iş ününe leke süren bilgileri yaymasını yasaktır.
                            <br /><br />
                            Oyun iletişim platforumunu spam (oyun ile ilgili olmayan ilanlar ve mesajlar) ve kartopu sistemleri ile (bilgilerin defalarca tekrarlanması, kopyalanması, yeniden üretilmesi v.s.) işgal etmek yasaktır. Kullanıcının küfür, taciz, tahrik edici veya şiddet içeren sözleri ve sembolleri veya oyun öngörmediği içerikleri (buna oyun içindeki tüm isimler dahildir, örn. kahramanın adı ve tasviri, oyunda kayıtlı olan kullanıcıların birliklerin vb. adları) kullanması yasaktır.
                            <br /><br />
                            Kullanıcının oyunda küfür, tacizi, tahrik veya şiddet içeren sözler veya şiddeti öven içerikleri, uyuşturucu reklamını, pornografik içerikler ya da ırk, din, cinsiyet, cinsel yönelim, köken veya sosyal statüsüyle ilgili uydurma ithamlarda bulunması, milliyetçilik, faşizm veya terör propagandasında bulunması yasaktır (noktalı ve/veya boşluklu yazılar ve latin harflar dahil).
                            <br /><br />
                            8. Kullanıcının oyuna veya diğer kullanıcıların bilgisayarlarına zarar verebilen virüsler, Trojan atları, solucanlar, time-bomb, cancelbot veya bozulmuş dosyaları, verileri veya diğer programları yüklemesi işbu oyun koşullarına aykırıdır.
                            <br /><br />
                            9. Kullanıcı, kendisini sunucunun veya bir sunucunun ortağının çalışanıymış gibi gösteren kahramanların adlarını, kullanıcıların birliklerinin adlarını ve diğer özel farkları yaratma hakkına sahip değildir.
                            <br /><br />
                            10. Kullanıcı, kendi ikamet yerinin yasalarına yumak zorundadır. Kullanıcı, söz konusu yasalarını ihlal ettiğinden sorumlu tutulur.
                            <br /><br />
                            4. Son hükümler:
                            <br /><br />
                            1. Oyun sunucusu, kullanıcının eylemlerinin iş bu oyun koşullarına aykırı olup olmadığına karar vermektedir.
                            <br /><br />
                            2. Kullanıcı iş bu oyun koşullarını ihlal ederse Oyun sunucusunun kullanıcıya karşı aşağıdaki tedbir alma hakkı saklıdır:
                            <br /><br />
                            Kullanıcının oyun postasını kullanması sınırlanması;
                            <br /><br />
                            Kahramanın, kullanıcıların birliğinin, oyun unsurlarının adını değiştirme zorlanması;
                            <br /><br />
                            Oyun unsurları, oyun iç ödeme yöntemleri ve diğer oyun iç değerleri gerialınması;
                            <br /><br />
                            Oyun hesabının sınırlanması, dondurulması veya tamamen kilitlenmesi;
                            <br /><br />
                            Kahramanların veya oyun hesabının silinmesi.
                            <br /><br />
                            Kullanıcı Sözleşmesini fethetmek;
                            <br /><br />
                            Ayrıca, bu konuda Ortak tarafından belirtilmiş tedbirler de uygulanabilir.
                            <br /><br />
                            3. Ortak taraf başka bir durumu tespit etmediği takdirde Kullanıcının kendi sucunu ve iş bu koşullara sonraki kesinlikle uymasını kabul etmesi halinde Oyun sunucu, oyun hesabının kısa sürede yeniden açılması için ceza kesme hakkına veya sebepleri belirtmeden oyun hesabının kısa sürede yeniden açılmasını kabul etmeme hakkına sahiptir.
                        </p>
                        <h5>KULLANICININ OYUNDA HESABINI AÇMASI, İŞBU OYUN KULLANICISI SÖZLEŞMESİ'Nİ KABUL ETTİĞİNİ VE SUNUCUNUN İŞBU SÖZLEŞMENİN HÜKÜMLERİNİ GARANTİ ETTİĞİNİ BEYAN EDER.
                        </h5>
                    </Box>
                </DialogContent>
            </Dialog>
        );
    }
}

TermsOfUse.propTypes = {
    classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
    return {
    }
};

export default withStyles(styles)(connect(mapStateToProps, {
})(TermsOfUse));
