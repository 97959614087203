import axios, { AxiosInstance } from "axios";
import { generateRequestId } from "./session";

/**
 * 
 * @returns {AxiosInstance}
 */
export const AxiosClient = () => {
    const rememberMe = sessionStorage.getItem('rememberMe') === 'true';
    const headers = {
        "Content-Type": "application/json",
        "Accept": "application/json",
        "K-Request-Id": generateRequestId(),
    };

    if (rememberMe) {
        headers['Authorization'] = 'Bearer ' + localStorage.getItem('sid');
    }
    else {
        headers['Authorization'] = 'Bearer ' + sessionStorage.getItem('sid');
    }

    return axios.create({
        headers: headers,
    })
}
