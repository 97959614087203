export const perms = {
    PermNickOps: 1 << 0,
    PermNickColorOps: 1 << 1,
    PermServerCmdOps: 1 << 2,
    PermRoomEdit: 1 << 3,
    PermUserPermOps: 1 << 4,
    PermRegexOps: 1 << 5,
    PermSpamOps: 1 << 6,
    PermForbidOps: 1 << 7,
    PermEditBanOps: 1 << 8,
    PermGoldUserOps: 1 << 9,
    PermKillOps: 1 << 10,
    PermBanOps: 1 << 11,
    PermPermanentBanOps: 1 << 12,
    PermRoomSettingsOps: 1 << 13,
    PermGeneralMessageOps: 1 << 14,
    PermGeneralOpenCloseOps: 1 << 15,
    PermGeneralClearOps: 1 << 16,
    PermPts1000Ops: 1 << 17,
    PermSittingTimeOps: 1 << 18,
    PermChatBanOps: 1 << 19,
    PermUserApproveOps: 1 << 20,
    PermUserAgentOps: 1 << 21,
    PermIPOps: 1 << 22,
    PermOpStaleOps: 1 << 23,
    PermEventOps: 1 << 24,
    PermPrivateImmunityOps: 1 << 25,
    PermGeneralImmunityOps: 1 << 26,
    PermCmdLogsOps: 1 << 27,
    PermRemoteLogsOps: 1 << 28,
    PermSpamLogsOps: 1 << 29,
    PermRegisterAndAvatarLogsOps: 1 << 30,
    PermRemoteLoginLogsOps: 1 << 31
};