import React from "react";
import { connect } from "react-redux";
import { withStyles } from "@mui/styles";
import PropTypes from "prop-types";
import { Box, Tab, Tabs, Typography } from "@mui/material";
import MailForbids from "./MailForbids";
import UsernameForbids from "./UsernameForbids";
import { setAdminTab } from "../../../actions/global";

const styles = theme => ({
});

const tabs = {
    MAIL_FORBIDS: 0,
    NICK_FORBIDS: 1,
}

class ServerSettings extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            tab: tabs.MAIL_FORBIDS,
        }

    }

    componentDidMount() {
        this.setState({ tab: this.props.adminTab });
    }

    componentDidUpdate(prevProps) {
    }

    onTabChange = (e, value) => {
        this.props.setAdminTab(value);
        this.setState({ tab: value });
    }

    content = () => {
        switch (this.state.tab) {
            case tabs.MAIL_FORBIDS:
                return <MailForbids />;
            case tabs.NICK_FORBIDS:
                return <UsernameForbids />;
        }
    }

    hasPermission = (perm) => (this.props.permission & Number(perm)) !== 0

    render() {
        const { classes } = this.props;

        return (
            <Box className="w-full overflow-auto">
                <Tabs
                    sx={{
                        width: 'fit-content', maxWidth: '100%',
                        '& .MuiTabs-flexContainer': { columnGap: 0 },
                        '& .MuiTabs-indicator': { backgroundColor: "#FF9559", height: '4px' },
                        '& .MuiTab-root': { color: '#505050 !important', borderBottom: '1px solid #E3E3E3', padding: 0 },
                        '& .Mui-selected': { color: '#505050 !important', backgroundColor: '#FFF3EC' },
                        '& .MuiTabs-scrollButtons.Mui-disabled': { opacity: 0.3 },
                    }}
                    variant="scrollable"
                    scrollButtons
                    allowScrollButtonsMobile
                    value={this.state.tab}
                    textColor="primary"
                    className={classes.tabs}
                    onChange={this.onTabChange}>

                    {this.hasPermission(1n << 5n) && this.hasPermission(1n << 7n) &&
                        <Tab value={tabs.MAIL_FORBIDS}
                            label={
                                <Box display={"flex"} columnGap={2} flex={1} p={2} justifyContent={"center"}>
                                    <Typography sx={{ whiteSpace: 'nowrap', px: 1 }} className="!text-xs md:!text-sm lg:!text-base">Mail Yasaklama</Typography>
                                </Box>
                            }
                            sx={{ textTransform: 'none', padding: 3, flexDirection: 'row', borderRight: '1px solid #E3E3E3' }} />
                    }

                    {this.hasPermission(1n << 5n) && this.hasPermission(1n << 7n) &&
                        <Tab value={tabs.NICK_FORBIDS}
                            label={
                                <Box display={"flex"} columnGap={2} flex={1} p={2} justifyContent={"center"}>
                                    <Typography sx={{ whiteSpace: 'nowrap', px: 1 }} className="!text-xs md:!text-sm lg:!text-base">Nick Yasaklama</Typography>
                                </Box>
                            }
                            sx={{ textTransform: 'none', padding: 3, flexDirection: 'row', borderRight: '1px solid #E3E3E3' }} />
                    }
                </Tabs>

                {this.content()}
            </Box>
        );
    }
}

ServerSettings.propTypes = {
    classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
    return {
        user: state.app.global.user,
        adminTab: state.app.global.adminTab,
    }
};

export default withStyles(styles)(connect(mapStateToProps, {
    setAdminTab,
})(ServerSettings));
