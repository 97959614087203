import React from "react";
import { connect } from "react-redux";
import { withStyles } from "@mui/styles";
import PropTypes from "prop-types";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, SvgIcon, Table, TableCell, TableRow, TextField, Tooltip, Typography } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { Add, AllInclusive, Remove } from "@mui/icons-material";
import dayjs from "dayjs";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import clsx from "clsx";
import axios from "axios";
import { GlobalTypes } from "../../../action_types";
import { setNotification } from "../../../actions/global";
import { AxiosClient } from "../../../global/axios";


const styles = theme => ({
});

class EditChatBan extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            username: props.username || props.chatBan?.username || '',
            date: props.unlimited ? null : (props.date || props.chatBan?.until ? dayjs(props.chatBan.until) : dayjs(new Date()).add(1, 'day')),
            reason: props.chatBan?.reason || '',
            saving: false,
        }

    }

    componentDidMount() {
    }

    componentDidUpdate(prevProps) {
        if (!prevProps.open && this.props.open) {
            this.setState({
                username: this.props.username || this.props.chatBan?.username || '',
                date: this.props.unlimited ? null : (this.props.date || this.props.chatBan?.until ? dayjs(this.props.chatBan.until) : dayjs(new Date()).add(1, 'day')),
                reason: this.props.chatBan?.reason || '',
                saving: false,
            });
        }
    }

    onSave = async () => {
        this.setState({ saving: true });

        const chatBan = {
            username: this.state.username,
            until: this.state.date ? this.state.date.unix() : null,
            reason: this.state.reason,
        };

        if (this.props.chatBan) {
            chatBan.id = this.props.chatBan.id;
        }

        try {
            await AxiosClient().post(`${process.env.REACT_APP_API_URL}/v1/admin/users/chat.ban`, chatBan);

            this.props.setNotification({ message: `Sustur ${this.props.chatBan ? 'düzenlendi.' : 'eklendi.'}`, severity: GlobalTypes.NOTIFY_SUCC });
            this.props.onClose(true);

        } catch (error) {
            console.error(error);
            this.props.setNotification({ message: 'Bir hata oluştu. Lütfen tekrar deneyin.', severity: GlobalTypes.NOTIFY_ERR });
        } finally {
            this.setState({ saving: false, username: '', date: dayjs(new Date()), reason: '' });
        }
    }

    render() {
        const { classes } = this.props;

        return (
            <Dialog open={this.props.open} onClose={() => this.props.onClose()}>
                <DialogTitle>
                    <Typography className={classes.title} sx={{ color: '#413067' }}>
                        {`Sustur ${this.props.chatBan ? 'Düzenle' : 'Ekle'}`}
                    </Typography>
                </DialogTitle>
                <DialogContent>
                    <Table>
                        <TableRow>
                            <TableCell className="!border-0 !p-2 min-w-32">
                                <Typography className={classes.title} sx={{ color: '#413067' }}>
                                    Kullanıcı Adı
                                </Typography>
                            </TableCell>
                            <TableCell className="!border-0 !p-2">
                                <TextField margin="dense" fullWidth
                                    value={this.state.username}
                                    sx={{
                                        borderTopRightRadius: '0 !important',
                                        borderBottomRightRadius: '0 !important',
                                        margin: 0,
                                    }}
                                    InputProps={{
                                        classes: {
                                            input: "h-8 sm:h-12",
                                            root: "h-8 sm:h-12",
                                        }
                                    }}
                                    disabled={Boolean(this.props.chatBan)}
                                    onChange={(e) => this.setState({ username: e.target.value })}
                                />
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className="!border-0 !p-2">
                                <Typography className={classes.title} sx={{ color: '#413067' }}>
                                    Tarih
                                </Typography>
                            </TableCell>
                            <TableCell className="!border-0 !p-2">
                                <Box className="flex gap-x-1 items-center">

                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DatePicker format="DD/MM/YYYY" onChange={date => this.setState({ date })} value={this.state.date}
                                            disabled={!Boolean(this.state.date)} disablePast
                                        />
                                    </LocalizationProvider>

                                    <Tooltip title="1 gün azalt" placement="top">
                                        <IconButton onClick={() => this.setState({ date: this.state.date?.subtract?.(1, 'day') ?? dayjs(new Date()) })}
                                            className="w-9 h-9"
                                        >
                                            <SvgIcon>
                                                <Remove />
                                            </SvgIcon>
                                        </IconButton>
                                    </Tooltip>

                                    <Tooltip title="1 gün arttır" placement="top">
                                        <IconButton onClick={() => this.setState({ date: this.state.date?.add?.(1, 'day') ?? dayjs(new Date()) })}
                                            className="w-9 h-9"
                                        >
                                            <SvgIcon>
                                                <Add />
                                            </SvgIcon>
                                        </IconButton>
                                    </Tooltip>

                                    <Tooltip title="Süresiz" placement="top">
                                        <IconButton onClick={() => this.setState({ date: this.state.date ? null : dayjs(new Date()) })}
                                            className={clsx(this.state.date ? null : "!bg-blue-400", "w-9 h-9")}
                                        >
                                            <SvgIcon>
                                                <AllInclusive htmlColor={this.state.date ? null : 'white'} />
                                            </SvgIcon>
                                        </IconButton>
                                    </Tooltip>
                                </Box>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className="!border-0 !p-2">
                                <Typography sx={{ color: '#413067' }}>
                                    Neden
                                </Typography>
                            </TableCell>
                            <TableCell className="!border-0 !p-2">
                                <TextField margin="dense" fullWidth
                                    value={this.state.reason}
                                    sx={{
                                        borderTopRightRadius: '0 !important',
                                        borderBottomRightRadius: '0 !important',
                                        margin: 0,
                                    }}
                                    InputProps={{
                                        classes: {
                                            input: "h-8 sm:h-12",
                                            root: "h-8 sm:h-12",
                                        }
                                    }}
                                    onChange={(e) => this.setState({ reason: e.target.value })}
                                />
                            </TableCell>
                        </TableRow>
                    </Table>
                </DialogContent>

                <DialogActions>
                    <Button onClick={() => this.props.onClose()} variant="contained" color="error">
                        İptal
                    </Button>
                    <Button onClick={this.onSave} variant="contained" color="primary"
                        className="!text-white"
                        disabled={!this.state.username || this.state.saving}
                    >
                        Kaydet
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

EditChatBan.propTypes = {
    classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
    return {
    }
};

export default withStyles(styles)(connect(mapStateToProps, {
    setNotification,
})(EditChatBan));
